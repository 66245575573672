import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfiguracionGeneral } from '../models/configuracionGeneral.model';
import { Embalaje } from '../models/embalaje.model';
import { Horario } from '../models/horario.model';
import { LogModel } from '../models/log-model.model';
import { TipoEmbalaje } from '../models/tipo-embalaje.model';
import { AuthenticationService } from './authentication.service';
import { Configuracion } from '../models/configuracion.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  readonly apiUrlService = environment.apiUrl;
  horario$: Subject<Horario> = new Subject();
  general$: Subject<ConfiguracionGeneral> = new Subject();
  configuracion$: Subject<Configuracion> = new Subject();
  predeterminado: boolean;

  constructor(private http: HttpClient, private authenticacion: AuthenticationService) {
  }

  getHorarios$(): Observable<Horario> {
    return this.horario$.asObservable();
  }

  getConfiguracion$(): Observable<Configuracion> {
    return this.configuracion$.asObservable();
  }

  getHorarios() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cli_config/horarios"
    this.http.get<Horario>(url_api, { headers: headersToken }).pipe(map(data => data as Horario))
      .subscribe(data => {
        this.horario$.next(data);
      });
  }

  putHorario(horarioUpdate: Horario, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "cli_config/horarios";
    return this.http.put(url_api, JSON.stringify({ "Log": logModel, "Body": JSON.stringify(horarioUpdate).toString() }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  getGeneral$(): Observable<ConfiguracionGeneral> {
    return this.general$.asObservable();
  }

  getGeneral() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cli_config/general"
    this.http.get<ConfiguracionGeneral>(url_api, { headers: headersToken }).pipe(map(data => data as ConfiguracionGeneral))
      .subscribe(data => {
        this.general$.next(data);
      });
  }

  putGeneral(configuracionGeneralUpdate: ConfiguracionGeneral, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "cli_config/general";
    return this.http.put(url_api, JSON.stringify({ "Log": logModel, "Body": JSON.stringify(configuracionGeneralUpdate).toString() }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  getConfiguracion() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cli_config"
    this.http.get<ConfiguracionGeneral>(url_api, { headers: headersToken }).pipe(map(data => data as Configuracion))
      .subscribe(data => {
        this.configuracion$.next(data);
      });
  }

}
