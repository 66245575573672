<div class="container">
    <div class="row">
        <div class="col-sm-12 col-12">
            <p>{{texto}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-12"></div>
        <div class="col-sm-4 col-12 margen-top">
            <button type="button" id="Si" class="btn btn-block" (click)="enviarRespuesta(true)">{{'Si'|translate}}</button>
        </div>
        <div class="col-sm-4 col-12 margen-top">
            <button type="button" id="No" class="btn buttonCancelar btn-block" (click)="enviarRespuesta(false)">{{'No'|translate}}</button>
        </div>
    </div>
</div>