import { Component, Input, LOCALE_ID, OnInit, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { Shipment } from 'src/app/models/shipment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Tracking } from 'src/app/models/tracking.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { FileShipService } from 'src/app/services/file-ship.service';
import { EnvioAereo } from 'src/app/models/envio-aereo.model';
import { EnvioMaritimo } from 'src/app/models/envio-maritimo.model';
import { EnvioTerrestre } from 'src/app/models/envio-terrestre.model';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { DatePipe, registerLocaleData } from '@angular/common';
import { ExcelServiceService } from 'src/app/services/excel-service.service';
import { ShipmentExport } from 'src/app/models/shipment-export.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import localeEs from '@angular/common/locales/es';
import { Archivo } from 'src/app/models/archivo.model';
import * as moment from 'moment';
import { MatTabGroup } from '@angular/material/tabs';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ChartType } from 'chart.js';

registerLocaleData(localeEs, 'es-ES')

@Component({
  selector: 'app-list-shipment',
  templateUrl: './list-shipment.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }, DatePipe],
  styleUrls: ['./list-shipment.component.scss']
})
export class ListShipmentComponent implements OnInit {
  @Input() listadoCotizaciones: [] = [];
  @ViewChild('fcl', { read: MatTabGroup }) fclGroup: MatTabGroup;
  @ViewChild('lcl', { read: MatTabGroup }) lclGroup: MatTabGroup;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  clase: string = "app-list-shipment";
  title = 'angular-9-i18n';
  pipe = new DatePipe("es-ES")
  langs = ['es', 'en'];
  listaEnvios: Tracking[] = [];
  RefCliente: string;
  listaArchivos: Archivo[] = [];
  form = new FormControl({
    searchRemitente: '',
    searchDestinatario: '',
    searchPartida: '',
    fechaRecogidaInicio: null,
    fechaRecogidaFinal: null,
    tipoEnvio: ''
  });
  showButtonsOption1 = true;
  showButtonsOption2 = false;
  index = 0;
  idEnvio;
  partida;
  dataExport: ShipmentExport[] = [];
  dataExportLCL: ShipmentExport[] = [];
  dataExportFCL: ShipmentExport[] = [];
  expedicion;
  referencia: string = "";
  key: string = 'IdShipment';
  reverse: boolean = false;
  pageActual: number = 1;
  total: number;
  sizePage: number = 10;
  // total: number;
  totalLCL: number;
  totalFCL: number;
  // collectionSize: number;
  pages: number;
  groupsModo: MatTabGroup[] = [];
  formDataAereo: EnvioAereo;
  formDataMaritimo: EnvioMaritimo;
  formDataTerrestre: EnvioTerrestre;
  list: Shipment[] = [];
  listFCL: Shipment[] = [];
  listLCL: Shipment[] = [];
  listFCLAux: Shipment[] = [];
  listLCLAux: Shipment[] = [];
  listAux: Shipment[] = [];
  listAuxFCL: Shipment[] = [];
  listAuxLCL: Shipment[] = [];
  searchText: string = "";
  idArea: number = 0;
  idModoEnvio: string = "0";
  modalRef: BsModalRef | null;
  template: TemplateRef<any>;
  today: Date = new Date();
  modoEnvioSelectedFCL: number = 0;
  modoEnvioSelectedAereo: number = 3;
  modoEnvioSelectedLCL: number = 1;
  validezSelected: number = 0;
  areaSelected: number;
  selectedIndex = 0;
  estados: string[] = [];


  ListTipoEnvio = [{
    value: 3,
    text: "AEREO"
  },
  {
    value: 2,
    text: "MARITIMO"
  },
  {
    value: 1,
    text: "TERRESTRE"
  }];

  busquedaAvanzada: boolean = false;

  hechoGetEnvios: boolean = false;
  hechoGetExcelEnvios: boolean = false;
  hechoGetEnviosFCL: boolean = false;
  hechoGetEnviosLCL: boolean = false;
  hechoGetExcelEnviosFCL: boolean = false;
  hechoGetExcelEnviosLCL: boolean = false;

  constructor(private router: Router, private toastr: ToastrService, public shipmentService: ShipmentService, private docsService: FileShipService, public dialog: MatDialog,
    private translateService: TranslateService, private route: ActivatedRoute, private excelService: ExcelServiceService, private modalService: BsModalService,
    private usuarioService: UsuarioService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.index === 0
    this.idArea = Number(this.route.snapshot.paramMap.get('idArea'));
    this.modoEnvioSelectedFCL = this.shipmentService.posicionModoEnvioFCL;
    this.modoEnvioSelectedLCL = this.shipmentService.posicionModoEnvioLCL;
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });


    dialogRef.afterOpened().subscribe(result => { });
    var todayMoment = moment(this.today);
    var day = todayMoment.date();
    var month = todayMoment.month();
    var year = todayMoment.year();
    var hour = todayMoment.hour();
    var minute = todayMoment.minute();
    this.today = new Date(year, month, day, hour, minute);

    if (!this.isMaritimo()) {
      this.shipmentService.getEnvios(this.idArea.toString(), this.modoEnvioSelectedLCL.toString()).subscribe(data => {
        if (this.hechoGetExcelEnvios)
          dialogRef.close();
        this.hechoGetEnvios = true;
        this.list = data;
        this.listAux = data;
        this.total = this.list.length;
      });

      this.shipmentService.getListaEnvioExcel(Number(this.idArea), 0).subscribe(data => {
        if (this.hechoGetEnvios)
          dialogRef.close();
        this.hechoGetExcelEnvios = true;
        this.dataExport = data;
      });
    }
    else {
      this.shipmentService.getEnvios(this.idArea.toString(), this.modoEnvioSelectedFCL.toString()).subscribe(dataFcl => {
        if (this.hechoGetExcelEnviosFCL && this.hechoGetExcelEnviosLCL && this.hechoGetEnviosLCL)
          dialogRef.close();
        this.hechoGetEnviosFCL = true;
        this.listFCL = dataFcl;
        this.listAuxFCL = dataFcl;
        this.listFCLAux = dataFcl;
        this.totalFCL = this.listFCL.length;
      });
      this.shipmentService.getEnvios(this.idArea.toString(), this.modoEnvioSelectedLCL.toString()).subscribe(dataLcl => {
        if (this.hechoGetExcelEnviosFCL && this.hechoGetExcelEnviosLCL && this.hechoGetEnviosFCL)
          dialogRef.close();
        this.hechoGetEnviosLCL = true;
        this.listLCL = dataLcl;
        this.listAuxLCL = dataLcl;
        this.listLCLAux = dataLcl;
        this.totalLCL = this.listLCL.length;
      });

      this.shipmentService.getListaEnvioExcel(Number(this.idArea), this.modoEnvioSelectedFCL).subscribe(data => {
        if (this.hechoGetEnviosFCL && this.hechoGetExcelEnviosLCL && this.hechoGetEnviosLCL)
          dialogRef.close();
        this.hechoGetExcelEnviosFCL = true;
        this.dataExportFCL = data;
      });

      this.shipmentService.getListaEnvioExcel(Number(this.idArea), this.modoEnvioSelectedLCL).subscribe(data => {
        if (this.hechoGetExcelEnviosFCL && this.hechoGetEnviosFCL && this.hechoGetEnviosLCL)
          dialogRef.close();
        this.hechoGetExcelEnviosLCL = true;
        this.dataExportLCL = data;
      });
    }

  }

  tabChangedModo1(event) {
    this.groupsModo = [this.fclGroup, this.lclGroup];
    this.modoEnvioSelectedLCL = event.index;
    this.groupsModo[this.modoEnvioSelectedLCL].selectedIndex = this.validezSelected;
  }

  tabChangedValidez(event) {
    this.validezSelected = event.index;
  }

  isTerrestre() {
    return this.idArea == 1;
  }
  isMaritimo() {
    return this.idArea == 2;
  }
  isAereo() {
    return this.idArea == 3;
  }



  puedeSubirDocumentacion(envio: Shipment): boolean {
    return moment(envio.FechaLimiteSubidaDocumentacion).isSameOrAfter(moment(this.today));
  }

  comprobarFechaFinal(): boolean {
    return this.form.value.fechaRecogidaInicio != null && this.form.value.fechaRecogidaFinal != null
      && this.form.value.fechaRecogidaFinal < this.form.value.fechaRecogidaInicio;
  }

  getIdTipoEnvio(tipoEnvio: string) {
    this.ListTipoEnvio.forEach(te => {
      if (te.text == tipoEnvio)
        return te.value
    })
    return null;
  }


  setForm(form: NgForm) {
    this.form.value.fechaRecogidaInicio = form.value.FechaRecogidaInicio;
    this.form.value.fechaRecogidaFinal = form.value.FechaRecogidaFinal;
    if (form.value.TipoEnvio == null) {
      this.form.value.tipoEnvio = '';
    } else {
      this.form.value.tipoEnvio = form.value.TipoEnvio;
    }
    if (form.value.SearchRemitente == null) {
      this.form.value.searchRemitente = '';
    } else {
      this.form.value.searchRemitente = form.value.SearchRemitente;
    }
    if (form.value.SearchDestinatario == null) {
      this.form.value.searchDestinatario = '';
    } else {
      this.form.value.searchDestinatario = form.value.SearchDestinatario;
    }
    if (form.value.SearchPartida == null) {
      this.form.value.searchPartida = '';
    } else {
      this.form.value.searchPartida = form.value.SearchPartida;
    }
  }

  submitBusquedaAvanzada(form: NgForm) {
    this.setForm(form);
    var jsonBusquedaAvanzada = {
      "FechaRecogidaInicio": this.form.value.fechaRecogidaInicio,
      "FechaRecogidaFinal": this.form.value.fechaRecogidaFinal,
      "TipoEnvio": this.form.value.tipoEnvio,
      "Remitente": this.form.value.searchRemitente,
      "Destinatario": this.form.value.searchDestinatario,
      "PartidaExpedicion": this.form.value.searchPartida
    }
    if (!this.comprobarFechaFinal()) {
      this.list = [];
      const dialogRef = this.dialog.open(WaitingComponent, {
        width: '250px',
        panelClass: 'my-panel',
        disableClose: true
      });
      dialogRef.afterOpened().subscribe(result => { });
      var log: LogModel = new LogModel();
      log.Click = "filtroEnvio"
      log.Pantalla = this.clase
      this.shipmentService.getEnviosConFiltro(jsonBusquedaAvanzada, this.idArea, log).subscribe(data => {
        this.list = data;
        this.listAux = data;
        this.total = this.list.length;
        form.reset();
        dialogRef.close();
      }, err => {
        var errorBody = this.translateService.instant('Ha existido algún error en la carga de tus envíos, recarga la página.');
        var errorTitulo = this.translateService.instant('Error al cargar envíos');
        this.toastr.error(errorBody, errorTitulo)
        dialogRef.close()
      })
    }

  }

  changeBusquedaAvanzanda() {
    this.busquedaAvanzada = !this.busquedaAvanzada;
  }

  getArea(idArea: number) {
    if (idArea == 1)
      return "TERRESTRE";
    else if (idArea == 2)
      return "MARITIMO";
    else if (idArea == 3)
      return "AEREO";
    else
      return "";
  }

  getDiasRetraso(diasRetraso: number) {
    if (diasRetraso < 0)
      return "ATRASADO";
    else if (diasRetraso > 0)
      return "ADELANTADO";
    else
      return "SEGUN LO PREVISTO"
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  verEnvio(id: number, tipoEnvio: string): void {
    this.shipmentService.setIdEnvio(id);

    switch (tipoEnvio) {
      case 'AEREO':
        this.shipmentService.setVisor(true);
        this.shipmentService.setDuplicar(false);
        this.shipmentService.setEditar(false);
        this.router.navigate(['/customer/shipment/aereo']);
        break;
      case 'FCL':
      case 'LCL':
      case 'MARITIMO':
        this.shipmentService.setVisor(true);
        this.shipmentService.setDuplicar(false);
        this.shipmentService.setEditar(false);
        this.router.navigate(['/customer/shipment/maritimo'])
        break;
      case 'TERRESTRE':
        this.shipmentService.setVisor(true);
        this.shipmentService.setDuplicar(false);
        this.shipmentService.setEditar(false);
        this.router.navigate(['/customer/shipment/terrestre'])
        break;
    }
  }

  listadoDocumentos(shipment: Shipment): void {
    this.shipmentService.envio = shipment;
    this.router.navigate(['/customer/shipment/documents']);
  }


  changeSearch() {
    if (this.isMaritimo()) {
      this.listLCLAux = this.listAuxLCL;
      //if (this.searchText != ""){
      this.listLCL = this.listLCLAux.filter(dir => this.existeCadenaEnvio(dir));
      //}
      this.total = this.listLCLAux.length;
    }
    if (this.isMaritimo()) {
      this.listFCLAux = this.listAuxFCL;
      //if (this.searchText != ""){
      this.listFCL = this.listFCLAux.filter(dir => this.existeCadenaEnvio(dir));
      //}
      this.total = this.listFCLAux.length;
    }
    if (!this.isMaritimo()) {
      this.list = this.listAux;
      //if (this.searchText != "")
      this.list = this.list.filter(dir => this.existeCadenaEnvio(dir));
      this.total = this.list.length;


    }
  }

  existeCadenaEnvio(shipment: Shipment): boolean {
    return ((shipment.FechaRecogida != null && shipment.FechaRecogida.toString().includes(this.searchText.toUpperCase()))
      || (shipment.Partida != null && shipment.Partida.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.Viaje != null && shipment.Viaje.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.Expedicion != null && shipment.Expedicion.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.RefCliente != null && shipment.RefCliente.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.IdArea != null && this.getArea(shipment.IdArea).includes(this.searchText.toUpperCase()))
      || (shipment.Remitente != null && shipment.Remitente.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.Destinatario != null && shipment.Destinatario.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.Estado != null && shipment.Estado.toUpperCase().includes(this.searchText.toUpperCase()))
      || (shipment.DiasRetraso != null && this.getDiasRetraso(shipment.DiasRetraso).toUpperCase() == this.searchText.toUpperCase()))
  }

  isPartida(shipment: Shipment) {
    return shipment.Partida != null && shipment.Partida != undefined && (shipment.Expedicion == null || shipment.Expedicion == undefined);
  }

  isExpedicion(shipment: Shipment) {
    return shipment.Expedicion != null && shipment.Expedicion != undefined && (shipment.Partida == null || shipment.Partida == undefined);
  }

  openModal(template: TemplateRef<any>, shipment: Shipment) {
    this.shipmentService.envio = shipment;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    if (shipment.Partida != undefined && shipment.Partida != null)
      this.referencia = shipment.Partida;
    else
      this.referencia = shipment.Expedicion;
    this.modalRef = this.modalService.show(template, config);
  }

  cerrar(event) {
    if (event)
      this.modalRef.hide();
  }

  copiarEnvio(partida: string, expedicion: string, tipoEnvio: string) {
    switch (tipoEnvio) {
      case 'AEREO':
        this.shipmentService.setDuplicar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setEditar(false);
        this.shipmentService.setPartida(partida);
        this.router.navigate(['/customer/shipment/aereo']);
        break;
      case 'FCL':
      case 'LCL':
      case 'MARITIMO':
        this.shipmentService.setDuplicar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setEditar(false);
        this.shipmentService.setPartida(partida);
        this.router.navigate(['/customer/shipment/maritimo']);
        break;
      case 'TERRESTRE':
        this.shipmentService.setDuplicar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setEditar(false);
        this.shipmentService.setExpedicion(expedicion.split('/')[0]);
        this.router.navigate(['/customer/shipment/terrestre']);
        break;
    }
  }

  buscarEnvio(envio: Shipment) {
    if (envio.IdEnvio != undefined && envio.IdEnvio != null && envio.IdEnvio != 0) {
      this.idEnvio = envio.IdEnvio;
    } else if (envio.Partida != undefined && envio.Partida != null) {
      this.partida = envio.Partida;
      this.shipmentService.getIdShipment(envio.Partida).subscribe(data => {
        this.idEnvio = data;
      }, err => { });
    } else if (envio.Expedicion != undefined && envio.Expedicion != null) {
      if (this.expedicion.includes("/"))
        this.expedicion = envio.Expedicion.split("/")[0];
      this.shipmentService.getIdShipment(this.expedicion).subscribe(data => {
        this.idEnvio = data;
      }, err => { });
    } else {
      this.idEnvio = 0;
    }
  }


  //Funcion que recibe un listado de archivos
  receiveFiles(files) {
    this.listaArchivos = files;
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    var log: LogModel = new LogModel();
    log.Click = "subirDocumentación";
    log.Pantalla = this.clase;
    dialogRef.afterOpened().subscribe(result => { });
    var total = 0;
    var enviarArchivos = [];
    this.listaArchivos.forEach(a => {
      a.Partida = this.partida;
      a.Expedicion = this.expedicion;
      a.IdEnvio = this.idEnvio;
      if (total + a.Tamanyo <= 25000000) { // 25 MB
        total += a.Tamanyo;
        enviarArchivos.push(a);
      } else {
        this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
          // REINTENTAMOS PARA VOLVER A SUBIRLOS.
          this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
            var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con nosotros.');
            var Titulo = this.translateService.instant('Error en los documentos');
            this.toastr.error(Body, Titulo);
          })
        });
        enviarArchivos = [];
        enviarArchivos.push(a);
        total = 0;
        total += a.Tamanyo;
      }
    });
    if (enviarArchivos.length > 0) {
      this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
        dialogRef.close();
        enviarArchivos = [];
        total = 0;
        var Body = this.translateService.instant('BodyArchivosOk');
        var Titulo = this.translateService.instant('TitleArchivosOk');
        this.toastr.success(Body, Titulo);
      }, err => {
        this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
          dialogRef.close();
          enviarArchivos = [];
          total = 0;
          var Body = this.translateService.instant('BodyArchivosOk');
          var Titulo = this.translateService.instant('TitleArchivosOk');
          this.toastr.success(Body, Titulo);
        }, err => {
          dialogRef.close();
          enviarArchivos = [];
          total = 0;
          var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con XGL.');
          var Titulo = this.translateService.instant('Error en los documentos');
          this.toastr.error(Body, Titulo);
        })
      })
    }
  }

  onTabSelectionChanged(event) {
    this.index = event.index;
    this.showButtonsOption1 = this.index === 0;
    this.showButtonsOption2 = this.index === 1;
  }

  //Petición que solicita un envio por la id pasada
  editarEnvio(id: number, tipoEnvio: string) {
    this.shipmentService.setIdEnvio(id);
    switch (tipoEnvio) {
      case 'AEREO':
        this.shipmentService.setEditar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setDuplicar(false);
        this.router.navigate(['/customer/shipment/aereo']);
        break;
      case 'FCL':
      case 'LCL':
      case 'MARITIMO':
        this.shipmentService.setEditar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setDuplicar(false);
        this.router.navigate(['/customer/shipment/maritimo']);
        break;
      case 'TERRESTRE':
        this.shipmentService.setEditar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setDuplicar(false);
        this.router.navigate(['/customer/shipment/terrestre']);
        break;
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if (this.langs.includes(lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

  exportExcel() {
    var envio: string = this.getArea(Number(this.idArea));
    this.excelService.exportAsExcelFile(this.dataExport, envio);
  }
  exportExcelLCL() {
    var enviolcl: string = this.getArea(Number(this.idArea));
    this.excelService.exportAsExcelFile(this.dataExportLCL, enviolcl);
  }
  exportExcelFCL() {
    var enviofcl: string = this.getArea(Number(this.idArea));
    this.excelService.exportAsExcelFile(this.dataExportFCL, enviofcl);
  }

}
