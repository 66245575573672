import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grados',
  templateUrl: './grados.component.html',
  styleUrls: ['./grados.component.scss']
})
export class GradosComponent implements OnInit {
  @Input() gradosMax: number;
  @Input() gradosMin: number;
  @Input() visor: boolean;
  @Input() isCotizacion: boolean;
  errorGradosMinMax: boolean = false;
  errorGradosRequired: boolean = false;
  @Output() emitGradosMax = new EventEmitter<number>();
  @Output() emitGradosMin = new EventEmitter<number>();
  @Output() close = new EventEmitter<boolean>();
  opcion = 1;
  grados: number;


  constructor(private toastr: ToastrService, public translateService: TranslateService) { }

  ngOnInit(): void {
    if(this.gradosMax != this.gradosMin)
      this.opcion = 2;
    else
      this.grados = this.gradosMax;

    if(this.opcion == 1)
    {
      window.setTimeout(function ()
      {
        document.getElementById('gradosUnico').focus();
      }, 0);
    }else{
      window.setTimeout(function ()
      {
        document.getElementById('gradosMinimos').focus();
      }, 0);
    }
    //this.gradosMin = this.gradosMax = this.grados = 0;
  }

  cambioGrados(event)
  {
    this.gradosMax = this.gradosMin = this.grados;
  }

  ngOnChanges(changes: SimpleChanges){
  }

  cerrar(close: boolean){
    if(close){
      // this.emitGradosMax.emit(this.gradosMax);
      // this.emitGradosMin.emit(this.gradosMin);
      this.close.emit(true);
    }else{
      if(this.opcion == 1)
        this.gradosMax = this.gradosMin = this.grados;
      if(this.gradosMax >= this.gradosMin)
      {
        this.emitGradosMax.emit(this.gradosMax);
        this.emitGradosMin.emit(this.gradosMin);
        this.close.emit(true);
      }else{
        var body = this.translateService.instant("Los grados máximos no puede ser inferior a los grados mínimos");
        var title = this.translateService.instant("Los grados máximos no puede ser inferior a los grados mínimos");
        this.toastr.error(body, title);
      }
    }
  }

}
