import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, TemplateRef } from '@angular/core';
import { Mercancia } from 'src/app/models/mercancia.model';
import { MercanciaService } from 'src/app/services/mercancia.service';
import { Embalaje } from 'src/app/models/embalaje.model';
import { ToastrService } from 'ngx-toastr';
import { Codigosun } from 'src/app/models/codigosun.model';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';
import { Contenedor } from 'src/app/models/contenedor.model';
import { Entity } from 'src/app/models/entity.model';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContenedorMercancia } from 'src/app/models/contenedorMercancia.model';
import { ClasificacionMercancia } from 'src/app/models/clasificacionMercancia.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { HscodesService } from 'src/app/services/hscodes.service';
import { HSCodes } from 'src/app/models/hscodes.model';
import { EntityService } from 'src/app/services/entity.service';
import { ConfigEntidad } from 'src/app/models/config-entidad.model';


@Component({
  selector: 'app-tarifa-cargo',
  templateUrl: './tarifa-cargo.component.html',
  styleUrls: ['./tarifa-cargo.component.scss']
})
export class TarifaCargoComponent implements OnInit {

  clasificacion: ClasificacionMercancia[] /*= [{
    value: 0,
    name: "Peligrosa"
  },{
    value: 1,
    name: "AlimentaciónClasificacion"
  },{
    value: 2,
    name: "GDP"
  },{
    value: 3,
    name: "General"
  },{
    value: 4,
    name: "Remontable"
  }];*/



  modalRef: BsModalRef | null;
  listaCargoMercanciaContenedor: Mercancia[][] = [];
  listaCargo: Mercancia[] = [];
  indiceEdit: number;
  listaNum: string[];
  nombreMercancia: string[] = [];
  hscodesMercancia: string[] = [];
  inicializadoFCL: boolean = false;
  inicializadoMercancia: boolean = false;
  @Input() visor: Boolean;
  @Output() listaAux = new EventEmitter<Mercancia[]>();
  @Output() pb = new EventEmitter<string>();
  @Output() pn = new EventEmitter<string>();
  @Input() area: number;
  @Input() listaMercancia: Mercancia[];
  @Input() isCotizacion: Boolean;
  @Input() mercanciaPeligrosa: Boolean;
  @Input() fcl: Boolean;
  @Input() tarifa: Boolean;
  //@Input() noRemontable: Boolean;
  @Input() cliente: Entity;
  usuarioLenguajeSuscription;

  // clasificacionContenedor: ClasificacionMercancia[] = [{
  //   value: 0,
  //   name: "Peligrosa"
  // },{
  //   value: 1,
  //   name: "AlimentaciónClasificacion"
  // },{
  //   value: 2,
  //   name: "GDP"
  // },{
  //   value: 3,
  //   name: "General"
  // }];

  listaCargoContenedor: ContenedorMercancia[] = [];
  @Input() listaMercanciaContenedor: ContenedorMercancia[];
  @Input() mercanciaPeligrosaContenedor: Boolean;
  @Output() listaAuxContenedor = new EventEmitter<ContenedorMercancia[]>();


  hscodes: HSCodes[] = [];
  // @Output() pbContenedor = new EventEmitter<string>();
  // @Output() pnContenedor = new EventEmitter<string>();
  camposVaciosContenedor: boolean = false;
  // NAContenedor: boolean[];
  // MPContenedor: boolean[];
  // pesoBrutoPContenedor: number = 0;
  // pesoNetoPContenedor: number = 0;
  pesoBrutoPMercanciaContenedor: number = 0;
  pesoNetoPMercanciaContenedor: number = 0;
  // bultosContenedor: string[] = [];
  // pesoBrutoContenedor: string[] = [];
  // peligrosaContenedor: boolean[] = [];
  // generalContenedor: boolean[] = [];
  // alimentacionContenedor: boolean[] = [];
  // gdpContenedor: boolean[] = [];
  public primeraVezContenedor: boolean = true;
  // clasContenedor: number[][] = [];
  selectedContenedor = [];
  selectedContenedorIndex: number = 0;

  codigosUn: Codigosun[] = [];
  cargo: Mercancia;
  tipoEmbalaje: Embalaje[];
  tipoEmbalajeRemontable: Embalaje[];
  //tipoEmbalajeNoRemontable: Embalaje[];
  embalaje: String;
  contenedorMercancia: ContenedorMercancia;
  mercancia: Mercancia;
  camposVacios: boolean = false;


  errorBruto: boolean[] = [];
  errorTipoEmbalaje: boolean[] = [];
  errorNeto: boolean[] = [];
  errorLargo: boolean[] = [];
  errorAncho: boolean[] = [];
  errorAlto: boolean[] = [];
  errorBultos: boolean[] = [];
  errorGrados: boolean[] = [];
  BrutoPrimeraVez: boolean[] = [];
  nombreDescripcionMercanciaContenedor: string[] = [];

  errorBrutoMercanciaContenedor: boolean[][] = [];
  errorTipoEmbalajeMercanciaContenedor: boolean[][] = [];
  errorNetoMercanciaContenedor: boolean[][] = [];
  errorLargoMercanciaContenedor: boolean[][] = [];
  errorAnchoMercanciaContenedor: boolean[][] = [];
  errorAltoMercanciaContenedor: boolean[][] = [];
  errorBultosMercanciaContenedor: boolean[][] = [];
  errorGradosMercanciaContenedor: boolean[][] = [];
  BrutoPrimeraVezMercanciaContenedor: boolean[][] = [];
  NAMercanciaContenedor: boolean[][] = [];
  MPMercanciaContenedor: boolean[][] = [];
  bultosMercanciaContenedor: string[][] = [];
  tEmbalajeMercanciaContenedor: number[][] = [];
  pesoNetoMercanciaContenedor: string[][] = [];
  pesoBrutoMercanciaContenedor: string[][] = [];
  altoMercanciaContenedor: string[][] = [];
  largoMercanciaContenedor: string[][] = [];
  anchoMercanciaContenedor: string[][] = [];
  volumenMercanciaContenedor: number[][] = [];
  peligrosaMercanciaContenedor: boolean[][] = [];
  alimentacionMercanciaContenedor: boolean[][] = [];
  gdpMercanciaContenedor: boolean[][] = [];
  remontableMercanciaContenedor: boolean[][] = [];
  noremontableMercanciaContenedor: boolean[][] = [];
  controlTemperaturaMercanciaContenedor: boolean[][] = [];
  generalMercanciaContenedor: boolean[][] = [];
  clasMercanciaContenedor: number[][][] = [[]];
  contenedorMercanciaContenedor: number[][] = [];
  nombreMercanciaContenedor: string[][] = [];
  hscodesMercanciaContenedor: string[][] = [];
  gradosMinMercanciaContenedor: number[][] = [];
  gradosMaxMercanciaContenedor: number[][] = [];

  NA: boolean[];
  MP: boolean[];
  pesoBrutoP: number = 0;
  pesoNetoP: number = 0;
  bultos: string[] = [];
  tEmbalaje: number[] = [];
  clas: number[][] = [];
  pesoNeto: string[] = [];
  pesoBruto: string[] = [];
  alto: string[] = [];
  largo: string[] = [];
  contenedor: number[] = [];
  ancho: string[] = [];
  volumen: number[] = [];
  peligrosa: boolean[] = [];
  alimentacion: boolean[] = [];
  gradosMin: number[] = [];
  gradosMax: number[] = [];
  gdp: boolean[] = [];
  remontable: boolean[] = [];
  noremontable: boolean[] = [];
  general: boolean[] = [];
  controlTemperatura: boolean[] = [];
  public primeraVez: boolean = true;
  listContenedor: Contenedor[];
  configEntidad: ConfigEntidad;

  lenguaje: string = "es";

  constructor(private mercanciaService: MercanciaService, private formBuilder: FormBuilder,
    private toastr: ToastrService, private usuarioService: UsuarioService, private hscodeService: HscodesService,
    private modalService: BsModalService, private entidadService: EntityService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.listaMercancia = [];
    this.MP = [];
    this.NA = [];
    this.clasificacion = [{
      value: 3,
      disabled: false,
      name: this.translateService.instant("General")
    }, {
      value: 0,
      disabled: false,
      name: this.translateService.instant("Peligrosa")
    }, {
      value: 6,
      disabled: false,
      name: this.translateService.instant("No remontable")
    }, {
      value: 4,
      disabled: false,
      name: this.translateService.instant("Remontable")
    }];
    this.translateService.onDefaultLangChange.subscribe(e => {
      this.clasificacion = [{
        value: 3,
        disabled: false,
        name: this.translateService.instant("General")
      }, {
        value: 0,
        disabled: false,
        name: this.translateService.instant("Peligrosa")
      }, {
        value: 6,
        disabled: false,
        name: this.translateService.instant("No remontable")
      }, {
        value: 4,
        disabled: false,
        name: this.translateService.instant("Remontable")
      }];
    });
    // this.usuarioService.getLenguaje$().subscribe(l => {
    //   console.log(l);
    //   this.translateService.setDefaultLang(l);
    //   console.log(this.translateService.instant("Peligrosa"));
    //   this.clasificacion = [{
    //     value: 0,
    //     name: this.translateService.instant("Peligrosa")
    //     },{
    //     value: 1,
    //     name: this.translateService.instant("AlimentaciónClasificacion")
    //   },{
    //     value: 2,
    //     name: this.translateService.instant("GDP")
    //   },{
    //     value: 3,
    //     name: this.translateService.instant("General")
    //   },{
    //     value: 4,
    //     name: this.translateService.instant("Remontable")
    //   }];
    //   console.log(this.clasificacion);
    // });

    // this.NAContenedor = [];
    // this.MPContenedor = [];
    this.cargarConfigEntidad();
    this.listaNum = [];
    this.indiceEdit = -1;
    this.listaCargo = [];
    this.listaCargoMercanciaContenedor = [];
    this.listaCargoContenedor = []
    this.obtenerHSCodes();
    this.obtenerTipoEmbalajes();
    // if(this.area == 1)
    //   this.obtenerTipoEmbalajesNoRemontable();
    this.obtenerTipoContenedor();
    this.obtenerCodigosUN();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.clasificacion = [{
    //   value: 0,
    //   name: this.translateService.instant("Peligrosa")
    // },{
    //   value: 1,
    //   name: this.translateService.instant("AlimentaciónClasificacion")
    // },{
    //   value: 2,
    //   name: this.translateService.instant("GDP")
    // },{
    //   value: 3,
    //   name: this.translateService.instant("General")
    // },{
    //   value: 4,
    //   name: this.translateService.instant("Remontable")
    // }];
    if (!this.fcl) {
      this.cargarConfigEntidad();
      this.recalculoVolumenes();
      // this.recalculoLineas();
      this.ponerListaTipoEmbalaje();
      this.listaCargo = this.listaMercancia;
      this.inicializar();
      if (this.MP == undefined)
        this.MP = [];
      if (this.NA == undefined)
        this.NA = [];
      // if(this.listaCargo != undefined && this.listaCargo != [])
      this.obtenerMedidas();
      // else
      //   this.inicializar();

      if (this.MP != undefined && this.MP.length > 0)
        if (!this.mercanciaPeligrosa)
          this.MP.forEach(element => element = false)

      if (this.indiceEdit != undefined && this.indiceEdit != -1)
        this.recalculo(this.indiceEdit);
    } else {
      this.cargarConfigEntidad();
      if (!this.inicializadoFCL)
        this.inicializarFCL();
      this.ponerListaTipoEmbalaje();
      this.obtenerTipoContenedor();
      if (this.listaMercanciaContenedor != undefined && this.listaMercanciaContenedor != null)
        this.listaCargoContenedor = this.listaMercanciaContenedor;
      if (this.listaCargoContenedor != undefined && this.listaCargoContenedor != null && this.listaCargoContenedor.length > 0) {
        var i = 0;
        this.listaCargoContenedor.forEach(c => {
          this.listaCargoMercanciaContenedor[i] = c.Mercancia;
          i++;
        });
      }
      this.obtenerInformacion();
    }

  }

  inicializar() {
    if (this.listaMercancia != undefined)
      if (this.listaMercancia.length == 0) {
        this.listaCargo = [];
        this.bultos = [];
        this.gradosMin = [];
        this.gradosMax = [];
        this.pesoBruto = [];
        this.largo = [];
        this.ancho = [];
        this.alto = [];
        this.pesoNeto = [];
        this.volumen = [];
        this.listContenedor = [];
        this.indiceEdit = -1;
        this.listaNum = [];
        this.cargo = null;
        this.embalaje = '';
        this.mercancia = null;
        this.camposVacios = false;
        this.errorBruto = []
        this.NA = [];
        this.MP = [];
        this.clas = [];
        this.peligrosa = [];
        this.controlTemperatura = [];
        this.gdp = [];
        this.remontable = [];
        this.noremontable = [];
        this.alimentacion = [];
        this.nombreMercancia = [];
        this.hscodesMercancia = [];
      }
  }

  customSearchFn(term: string, item: Codigosun) {

  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

  openAlert(template: TemplateRef<any>, i: number) {
    this.indiceEdit = i;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

  getIndex(index: number) {
    this.indiceEdit = index;
  }

  //Funcion que cierra el modal
  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
  }
  //#region OBTENER DATOS
  obtenerCodigosUN() {
    this.mercanciaService.getCodigosUN().subscribe(data => this.codigosUn = data);
  }

  ponerListaTipoEmbalaje() {
    // if(this.noRemontable != undefined && this.noRemontable != null && this.noRemontable && this.area == 1)
    //   this.tipoEmbalaje = this.tipoEmbalajeNoRemontable;
    // else
    this.tipoEmbalaje = this.tipoEmbalajeRemontable;
  }

  obtenerTipoEmbalajes() {
    this.mercanciaService.getTipoEmbalajes().subscribe(data => {
      this.tipoEmbalajeRemontable = data;
      if (this.listaMercanciaContenedor != undefined && this.listaMercanciaContenedor != null && this.listaMercanciaContenedor.length > 0)
        this.obtenerInformacion();
    });
  }

  obtenerHSCodes() {
    this.hscodeService.getAllHSCodes().subscribe(data => {
      this.hscodes = data;
    });
  }

  // obtenerTipoEmbalajesNoRemontable() {
  //   this.mercanciaService.getTipoEmbalajesNoRemontable().subscribe(data => this.tipoEmbalajeNoRemontable = data);
  // }

  obtenerTipoContenedor() {
    this.mercanciaService.getTipoContenedor().subscribe(data => {
      this.listContenedor = data;
      this.obtenerInformacion();
    })
  }

  validarPesoBruto(valor: number, contenedor: number, indice: number) {
    if (valor > 30000) {
      this.errorBrutoMercanciaContenedor[contenedor][indice] = true;
      this.toastr.error(this.translateService.instant("BodyMaxPesoBruto"), this.translateService.instant("TitleMaxPesoBruto"))
    } else
      this.errorBrutoMercanciaContenedor[contenedor][indice] = false;
  }

  introducirHSCode(hscode: HSCodes, i, l) {
    if (l == 'es') {
      this.nombreMercancia[i] = hscode.descriptionES;
      this.listaCargo[i].NombreMercancia = hscode.descriptionES;
    } else {
      this.nombreMercancia[i] = hscode.descriptionEN;
      this.listaCargo[i].NombreMercancia = hscode.descriptionEN;
    }
    this.listaCargo[i].HSCode = hscode.goodsCode;
  }

  cargarConfigEntidad() {
    this.entidadService.getConfigEntidad().subscribe(data => {
      this.configEntidad = data;
      //this.formModelAereo.value.MercanciaPeligrosa = this.configEntidad.MercanciaPeligrosa;
      if (this.fcl) {
        this.configEntidad.Remontable = false;
      }
      else
        this.configEntidad.Remontable = true;
    },
      error => {

      })
  }

  introducirHSCodeMercanciaContenedor(hscode: HSCodes, i, l) {
    if (l == 'es') {
      this.nombreMercanciaContenedor[this.selectedContenedorIndex][i] = hscode.descriptionES;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].NombreMercancia = hscode.descriptionES;
    } else {
      this.nombreMercanciaContenedor[this.selectedContenedorIndex][i] = hscode.descriptionEN;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].NombreMercancia = hscode.descriptionEN;
    }
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].HSCode = hscode.goodsCode;
  }

  salidaHSCodes(i) {
    var hscode = this.hscodes.find(hs => hs.goodsCode.startsWith(this.hscodesMercancia[i]));
    if (hscode != undefined && hscode != null) {
      let lang: string = this.translateService.getDefaultLang();
      if (this.usuarioLenguajeSuscription != null && this.usuarioLenguajeSuscription != undefined)
        this.usuarioLenguajeSuscription.unsubscribe();
      this.usuarioLenguajeSuscription = this.usuarioService.getLenguaje$().subscribe(l => {
        this.introducirHSCode(hscode, i, l);
      });
      this.introducirHSCode(hscode, i, lang);
    }
  }

  /* salidaHSCodesMercanciaContenedor(i) {
     var hscode = this.hscodes.find(hs => hs.goodsCode.startsWith(this.hscodesMercanciaContenedor[this.selectedContenedorIndex][i]));
     if (hscode != undefined && hscode != null) {
       let lang: string = this.translateService.getDefaultLang();
       if (this.usuarioLenguajeSuscription != null && this.usuarioLenguajeSuscription != undefined)
         this.usuarioLenguajeSuscription.unsubscribe();
       this.usuarioLenguajeSuscription = this.usuarioService.getLenguaje$().subscribe(l => {
         this.introducirHSCodeMercanciaContenedor(hscode, i, l);
       });
       this.introducirHSCodeMercanciaContenedor(hscode, i, lang);
     }
   }*/
  //#endregion

  //#region SOLO MERCANCIA

  setNombreMercancia(nombreMercancia, i) {
    this.listaCargo[i].NombreMercancia = nombreMercancia;
    this.nombreMercancia[i] = nombreMercancia;
    this.hscodesMercancia[i] = null;
  }

  setHSCodes(HSCode, i) {
    this.listaCargo[i].HSCode = HSCode;
    this.hscodesMercancia[i] = HSCode;
  }

  recargarSeleccion(i) {
    this.clas[i] = [];
    if (this.peligrosa[i])
      this.clas[i].push(0);
    if (this.alimentacion[i])
      this.clas[i].push(1);
    if (this.gdp[i])
      this.clas[i].push(2);
    if (this.general[i])
      this.clas[i].push(3);
    if (this.remontable[i])
      this.clas[i].push(4);
    if (this.controlTemperatura[i])
      this.clas[i].push(5);
    if (this.noremontable[i])
      this.clas[i].push(6);
  }

  changeClasificacion(c, i) {
    // Si es remove
    if (c.value.value != undefined && c.value.value != null)
      c = c.value;

    if (c.name == this.translateService.instant("Peligrosa")) {
      if (this.hayTemperaturaControlada()) // this.controlTemperatura[i]
      {
        this.setMP(false, i);
        this.peligrosa[i] = this.listaCargo[i].Peligrosa = false;
        this.listaCargo[i].MercanciaPeligrosa = [];
        this.recargarSeleccion(i);
        this.toastr.info("Si desea una mercancía peligrosa y controlar la temperatura debes de contratar con tu comercial", "Contacta con tu comercial");
      } else {
        this.peligrosa[i] = !this.peligrosa[i];
        this.listaCargo[i].Peligrosa = this.peligrosa[i];
        if (this.peligrosa[i]) {
          this.setMP(true, i);
          if (this.clas[i].find(c => c == 3) != undefined) {
            this.clas[i].push(3);
            this.general[i] = this.listaCargo[i].General = false;
            this.clas[i] = [0];
          }
        } else {
          this.setMP(false, i);
          this.listaCargo[i].MercanciaPeligrosa = [];
        }

      }
    }
    else if (c.name == this.translateService.instant("TemperaturaControlada")) {
      if (this.hayMercanciaPeligrosa()) // this.peligrosa[i]
      {
        this.controlTemperatura[i] = this.listaCargo[i].TemperaturaControlada = false;
        this.recargarSeleccion(i);
        this.toastr.info("Si desea una mercancía peligrosa y controlar la temperatura debes de contratar con tu comercial", "Contacta con tu comercial");
      } else {
        this.controlTemperatura[i] = !this.controlTemperatura[i];
        this.listaCargo[i].TemperaturaControlada = this.controlTemperatura[i];
        if (this.controlTemperatura[i] && this.clas[i].find(c => c == 3) != undefined) {
          this.clas[i].push(3);
          this.general[i] = this.listaCargo[i].General = false;
          this.clas[i] = [5];
        }
      }
    }
    else if (c.name == this.translateService.instant("AlimentaciónClasificacion")) {
      this.alimentacion[i] = !this.alimentacion[i];
      this.listaCargo[i].Alimentacion = this.alimentacion[i];
      if (this.alimentacion[i] && this.clas[i].find(c => c == 3) != undefined) {
        this.clas[i].push(3);
        this.general[i] = this.listaCargo[i].General = false;
        this.clas[i] = [1];
      }
    }
    else if (c.name == this.translateService.instant("GDP")) {
      this.gdp[i] = !this.gdp[i];
      this.listaCargo[i].GDP = this.gdp[i];
      if (this.gdp[i] && this.clas[i].find(c => c == 3) != undefined) {
        this.clas[i].push(3);
        this.general[i] = this.listaCargo[i].General = false;
        this.clas[i] = [2];
      }
    }
    else if (c.name == this.translateService.instant("General")) {
      this.general[i] = !this.general[i];
      this.listaCargo[i].General = this.general[i];
      if (this.general[i]) {
        this.setMP(false, i);
        this.listaCargo[i].MercanciaPeligrosa = [];
        this.clas[i] = [3];
        this.peligrosa[i] = this.controlTemperatura[i] = this.alimentacion[i] = this.gdp[i] = this.remontable[i] = this.noremontable[i] =
          this.listaCargo[i].Peligrosa = this.listaCargo[i].Alimentacion = this.listaCargo[i].GDP = this.listaCargo[i].NoRemontable = this.listaCargo[i].Remontable = this.listaCargo[i].TemperaturaControlada = false;
      }
    }
    else if (c.name == this.translateService.instant("Remontable")) {
      this.remontable[i] = !this.remontable[i];
      this.listaCargo[i].Remontable = this.remontable[i];
      this.listaCargo[i].NoRemontable = this.noremontable[i] = false;
      this.recargarSeleccion(i)
      if (this.general[i] && this.clas[i].find(c => c == 3) != undefined) {
        this.clas[i].push(3);
        this.general[i] = this.listaCargo[i].General = false;
        this.clas[i] = [4];
      }
      this.calculaVolumen(i);
    }
    else if (c.name == this.translateService.instant("No remontable")) {
      this.noremontable[i] = !this.noremontable[i];
      this.listaCargo[i].NoRemontable = this.noremontable[i];
      this.listaCargo[i].Remontable = this.remontable[i] = false;
      this.recargarSeleccion(i)
      if (this.general[i] && this.clas[i].find(c => c == 3) != undefined) {
        this.clas[i].push(3);
        this.general[i] = this.listaCargo[i].General = false;
        this.clas[i] = [6];
      }
      this.calculaVolumen(i);
    }
    this.recalculo(i);
  }

  clearClasificacion(i) {
    this.peligrosa[i] = this.alimentacion[i] = this.general[i] = this.gdp[i] = this.remontable[i] = this.noremontable[i] = this.controlTemperatura[i] = false;
    this.listaCargo[i].Peligrosa = this.listaCargo[i].Alimentacion = this.listaCargo[i].General = this.listaCargo[i].GDP = this.listaCargo[i].TemperaturaControlada = this.listaCargo[i].Remontable = this.listaCargo[i].NoRemontable = false;
    this.recalculoVolumenes();
    this.recalculo(i);
  }

  hayMercanciaPeligrosa() {
    return this.peligrosa.find(p => p);
  }

  hayTemperaturaControlada() {
    return this.controlTemperatura.find(t => t);
  }

  obtenerMedidas() {
    let i = 0;
    if (this.listaCargo != undefined && this.listaCargo != null) {
      this.listaCargo.forEach(element => {
        this.nombreMercancia[i] = element.NombreMercancia;
        this.hscodesMercancia[i] = element.HSCode;
        var tipoEmbalaje = this.tipoEmbalaje?.find(t => t.TipoEmbalaje == element.NombreEmbalaje || t.IdEmbalaje == element.TipoEmbalaje);
        if (element.Bultos != null)
          this.bultos[i] = element.Bultos.toString();

        this.gradosMin[i] = element.GradosMin;
        this.gradosMax[i] = element.GradosMax;

        if (tipoEmbalaje != null && tipoEmbalaje != undefined)
          this.tEmbalaje[i] = tipoEmbalaje.IdTipoEmbalaje;
        else
          this.tEmbalaje[i] = null;

        this.contenedor[i] = element.IdContenedor;
        if (element.Alto != undefined && element.Ancho != undefined && element.Largo != undefined) {
          if ((this.alto[i] == null || this.alto[i] == undefined || this.alto[i] == "0")
            && tipoEmbalaje?.Alto != 0 && tipoEmbalaje?.Alto != null && tipoEmbalaje?.Alto != undefined
            && (element.Alto == 0 || element.Alto == null || element.Alto == undefined))
            this.alto[i] = tipoEmbalaje.Alto.toString();
          else
            this.alto[i] = element.Alto.toString();

          if ((this.ancho[i] == null || this.ancho[i] == undefined || this.ancho[i] == "0")
            && tipoEmbalaje?.Ancho != 0 && tipoEmbalaje?.Ancho != null && tipoEmbalaje?.Ancho != undefined
            && (element.Ancho == 0 || element.Ancho == null || element.Ancho == undefined))
            this.ancho[i] = tipoEmbalaje.Ancho.toString();
          else
            this.ancho[i] = element.Ancho.toString();

          if ((this.largo[i] == null || this.largo[i] == undefined || this.largo[i] == "0")
            && tipoEmbalaje?.Largo != 0 && tipoEmbalaje?.Largo != null && tipoEmbalaje?.Largo != undefined
            && (element.Largo == 0 || element.Largo == null || element.Largo == undefined))
            this.largo[i] = tipoEmbalaje.Largo.toString();
          else
            this.largo[i] = element.Largo.toString();
        }
        if (element.PesoNeto != null) {
          element.PesoNeto = Number(element.PesoNeto.toString().replace(',', '.'));
          this.pesoNeto[i] = element.PesoNeto.toString().replace(',', '.');
        }
        if (element.PesoBruto != null)
          this.pesoBruto[i] = element.PesoBruto.toString().replace(',', '.');

        this.volumen[i] = Number(element.Volumen?.toString().replace(',', '.'));

        if (element.NA == 1)
          this.NA[i] = true;
        else
          this.NA[i] = false;
        if (element.MP == 1)
          this.MP[i] = true;
        else
          this.MP[i] = false;

        if (this.clas[i] == undefined || this.clas[i] == null)
          this.clas[i] = [];

        if (element.Alimentacion)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("AlimentaciónClasificacion")).value);
        if (element.Peligrosa)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("Peligrosa")).value);
        if (element.Remontable)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("Remontable")).value);
        if (element.NoRemontable)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("No remontable")).value);
        if (element.GDP)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("GDP")).value);
        if (element.General)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("General")).value);
        if (element.TemperaturaControlada)
          this.clas[i].push(this.clasificacion.find(c => c.name == this.translateService.instant("TemperaturaControlada")).value)

        this.listaCargo[i].Peligrosa = this.peligrosa[i] = element.Peligrosa;
        if (element.Peligrosa)
          this.listaCargo[i].MP = 1;
        else
          this.listaCargo[i].MP = 0;
        this.listaCargo[i].TemperaturaControlada = this.controlTemperatura[i] = element.TemperaturaControlada;
        this.listaCargo[i].Alimentacion = this.alimentacion[i] = element.Alimentacion;
        this.listaCargo[i].GDP = this.gdp[i] = element.GDP;
        this.listaCargo[i].Remontable = this.remontable[i] = element.Remontable;
        this.listaCargo[i].NoRemontable = this.noremontable[i] = element.NoRemontable;
        this.listaCargo[i].General = this.general[i] = element.General;
        this.nombreMercancia[i] = element.NombreMercancia;
        this.hscodesMercancia[i] = element.HSCode;

        i++;
      });
    }
  }

  recalculoVolumenes() {
    if (this.area == 2 && !this.fcl)
      for (var i = 0; i < this.listaCargo.length; i++)
        this.calculaVolumen(i);
    else if (this.area == 1)
      for (var i = 0; i < this.listaCargo.length; i++)
        this.calculaVolumen(i);
  }

  recalculoLineas() {
    let length = this.listaCargo.length;
    for (let i = 0; i < length; i++)
      this.recalculo(i);
  }

  //Funcion que añade una linea vacía a la mercancía
  addEmptyLine() {
    if (!this.visor)
      if (!this.mostrarErrores()) {
        this.mercancia = new Mercancia();
        this.mercancia.Bultos = 0;
        this.mercancia.GradosMin = null;
        this.mercancia.GradosMax = null;
        this.mercancia.PesoBruto = 0;
        this.mercancia.PesoNeto = 0;
        this.mercancia.TipoEmbalaje = null;
        this.mercancia.Volumen = 0;
        this.mercancia.MP = Number(this.configEntidad.MercanciaPeligrosa);
        this.mercancia.Peligrosa = this.configEntidad.MercanciaPeligrosa;
        this.mercancia.Remontable = this.configEntidad.Remontable;
        this.mercancia.NA = Number(!this.configEntidad.MercanciaPeligrosa);
        if (this.mercancia.MP == 1)
          this.mercancia.MercanciaPeligrosa = [];
        this.listaCargo.push(this.mercancia);
        this.BrutoPrimeraVez.push(true);
        this.errorBruto.push(false);
        this.errorNeto.push(false);
        this.errorBultos.push(false);
        this.errorGrados.push(false);
        this.errorTipoEmbalaje.push(false);
        this.errorAlto.push(false);
        this.errorAncho.push(false);
        this.errorAlto.push(false);
        this.pesoNeto.push('0');
        this.pesoBruto.push('0');
        this.alimentacion.push(false);
        this.MP.push(this.configEntidad.MercanciaPeligrosa);
        this.NA.push(!this.configEntidad.MercanciaPeligrosa);
        this.peligrosa.push(this.configEntidad.MercanciaPeligrosa);
        this.controlTemperatura.push(false);
        this.gdp.push(false);
        this.remontable.push(this.configEntidad.Remontable);
        this.noremontable.push(false);
        this.general.push(false);
        this.camposVacios = true;
        this.primeraVez = false;
        this.recargarSeleccion(this.listaCargo.length - 1);
        if (!this.fcl)
          this.listaAux.emit(this.listaCargo); // Se emite
        else
          this.listaAuxContenedor.emit(this.listaCargoContenedor);
      }
  }

  //Funcion que elimina la linea de la mercancia seleccionada
  deleteMercancia(linea: number) {
    if (!this.visor) {
      this.listaCargo.splice(linea, 1);
      this.errorBruto.splice(linea, 1);
      this.pesoBruto.splice(linea, 1);
      this.tEmbalaje.splice(linea, 1);
      this.pesoNeto.splice(linea, 1);
      this.bultos.splice(linea, 1);
      this.largo.splice(linea, 1);
      this.ancho.splice(linea, 1);
      this.alto.splice(linea, 1);
      this.peligrosa.splice(linea, 1);
      this.alimentacion.splice(linea, 1);
      this.gdp.splice(linea, 1);
      this.remontable.splice(linea, 1);
      this.general.splice(linea, 1);
      this.controlTemperatura.splice(linea, 1);
      this.hscodesMercancia.splice(linea, 1);
      this.nombreMercancia.splice(linea, 1);
      //this.gradosMin.splice(linea, 1);
      this.volumen.splice(linea, 1);
      if (!this.fcl)
        this.listaAux.emit(this.listaCargo); // Se emite
      else
        this.listaAuxContenedor.emit(this.listaCargoContenedor);
      var index = 0;
      this.listaCargo.forEach(m => {
        this.camposVacios = this.comprobarVacio(m, 1)
        if (this.camposVacios) {
          index++;
          return
        }
        //this.errorBruto[index] = this.comprobarPesos(m, 1)
      })
      if (index == 0)
        this.camposVacios = false;
    }
  }

  //Funcion que muestra si nos falta algun campo requerido para añadir la siguiente linea de mercancia
  mostrarErrores() {
    this.listaCargo.forEach(m => {
      this.camposVacios = this.comprobarVacio(m, 1)
    })
    var mostrar = false;
    if (this.camposVacios) {
      var Body = this.translateService.instant("Es necesario rellenar como mínimo peso bruto o volumen.");
      var Titulo = this.translateService.instant("Campos vacíos");
      this.toastr.error(Body, Titulo);
      mostrar = true;
    }
    if (this.errorBruto.includes(true)) {
      var Body = this.translateService.instant("No puedes añadir un peso bruto menor que peso neto.");
      var Titulo = this.translateService.instant("Error Pesos");
      this.toastr.error(Body, Titulo);
      mostrar = true;
    }
    // if(this.fcl && this.selectedContenedorIndex < 0){
    //   var Body = this.translateService.instant("No puedes añadir mercancía sin indicar a que contenedor tiene que ir.");
    //   var Titulo = this.translateService.instant("Error Añadir Mercancía");
    //   this.toastr.error(Body, Titulo);
    //   mostrar = true;
    // }
    return mostrar;
  }

  //Funcion que añade a la lista el peso neto
  getPesoNeto(pesoNeto: number, index) {
    this.pesoNeto[index] = pesoNeto.toString().replace(',', '.');
    this.listaCargo[index].PesoNeto = Number(pesoNeto.toString().replace(',', '.'));
    if (!this.BrutoPrimeraVez[index] && pesoNeto > Number(this.pesoBruto[index])) {
      this.errorNeto[index] = this.errorBruto[index] = true;
      var Body = this.translateService.instant("No puedes añadir un peso bruto menor que peso neto.");
      var Titulo = this.translateService.instant("Error Pesos");
      this.toastr.error(Body, Titulo);
    } else
      this.errorNeto[index] = this.errorBruto[index] = false;
  }

  //Funcion que añade a la lista el peso bruto
  getPesoBruto(pesoBruto: number, index) {
    this.BrutoPrimeraVez[index] = false;
    this.pesoBruto[index] = pesoBruto.toString().replace(',', '.');
    this.listaCargo[index].PesoBruto = Number(pesoBruto.toString().replace(',', '.'));
    if (!this.BrutoPrimeraVez[index] && Number(this.pesoNeto[index]) > Number(pesoBruto)) {
      this.errorNeto[index] = this.errorBruto[index] = true;
      var Body = this.translateService.instant("No puedes añadir un peso bruto menor que peso neto.");
      var Titulo = this.translateService.instant("Error Pesos");
      this.toastr.error(Body, Titulo);
    } else
      this.errorNeto[index] = this.errorBruto[index] = false;
  }

  //Funcion que añade a la lista el tipo de embalaje
  setEmbalaje(tipoEmbalaje: any, index) {
    this.setMedidas(tipoEmbalaje, index);
    if (tipoEmbalaje != undefined) {
      this.listaCargo[index].TipoEmbalaje = tipoEmbalaje.IdEmbalaje;
      this.listaCargo[index].NombreEmbalaje = tipoEmbalaje.TipoEmbalaje;
    }
    else {
      this.listaCargo[index].TipoEmbalaje = null;
      this.listaCargo[index].NombreEmbalaje = null;
    }
  }

  //Funcion en el que si las medidas vienen por defecto en el embalaje seleccionado se setearan automaticamente
  setMedidas(tipoEmbalaje: any, index: number) {
    if (tipoEmbalaje.Alto != undefined && tipoEmbalaje.Alto != null)
      this.getAlto(tipoEmbalaje.Alto, index);
    if (tipoEmbalaje.Largo != undefined && tipoEmbalaje.Largo != null)
      this.getLargo(tipoEmbalaje.Largo, index);
    if (tipoEmbalaje.Ancho != undefined && tipoEmbalaje.Ancho != null)
      this.getAncho(tipoEmbalaje.Ancho, index);
  }

  //Funcion que añade a la lista los bultos
  getBultos(bultos: number, index) {
    this.listaCargo[index].Bultos = bultos;
    if (bultos <= 0)
      this.errorBultos[index] = true;
    else
      this.errorBultos[index] = false;
    this.calculaVolumen(index);
  }

  //Funcion que añade a la lista los grados
  // getGrados(grados: number, index) {
  //   this.listaCargo[index].Grados = grados;
  //   if(grados <= 0)
  //     this.errorGrados[index] = true;
  //   else
  //     this.errorGrados[index] = false;
  //   this.calculaVolumen(index);
  // }

  //Funcion que añade a la lista las medidas
  getAlto(alto, index) {
    this.listaCargo[index].Alto = Number(alto.toString().replace(',', '.'));
    this.alto[index] = alto.toString().replace(',', '.');
    if (alto <= 0)
      this.errorAlto[index] = true;
    else
      this.errorAlto[index] = false;
    this.calculaVolumen(index);
  }

  //Funcion que añade a la lista las medidas
  getAncho(ancho, index) {
    this.listaCargo[index].Ancho = Number(ancho.toString().replace(',', '.'));
    this.ancho[index] = ancho.toString().replace(',', '.');
    if (ancho <= 0)
      this.errorAncho[index] = true;
    else
      this.errorAncho[index] = false;
    this.calculaVolumen(index);
  }

  //Funcion que añade a la lista las medidas
  getLargo(largo, index) {
    this.listaCargo[index].Largo = Number(largo.toString().replace(',', '.'));
    this.largo[index] = largo.toString().replace(',', '.');
    if (largo <= 0)
      this.errorLargo[index] = true;
    else
      this.errorLargo[index] = false;
    this.calculaVolumen(index);
  }

  //Funcion que añade a la lista el volumen
  calculaVolumen(index) {
    if (this.listaCargo[index].Alto != undefined && this.listaCargo[index].Alto != null && this.listaCargo[index].Alto != 0 && this.listaCargo[index].Ancho != undefined && this.listaCargo[index].Ancho != null && this.listaCargo[index].Ancho != 0 && this.listaCargo[index].Largo != undefined && this.listaCargo[index].Largo != null && this.listaCargo[index].Largo != 0) {
      if (this.area == 2) {
        if (!this.fcl) {
          if (!this.comprobarCalculoVolumen(this.listaCargo[index])) {
            if (!this.listaCargo[index].Remontable)
              this.listaCargo[index].Volumen = Number((190 * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
            else
              this.listaCargo[index].Volumen = Number((this.listaCargo[index].Alto * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3

            this.listaCargo[index].Volumen = Number((Number(this.listaCargo[index].Volumen.toFixed(3).toString().replace(',', '.')) * 0.000001)) * this.listaCargo[index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
            this.volumen[index] = Number(this.listaCargo[index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
          }
          else {
            this.listaCargo[index].Volumen = null
            var Body = this.translateService.instant("Error al indicar Alto x Ancho x Largo");
            var Titulo = this.translateService.instant("Falta informacion");
            this.toastr.error(Body, Titulo)
          }
        }
        else {
          if (this.listaCargo[index].Alto != undefined && this.listaCargo[index].Alto != null
            && this.listaCargo[index].Ancho != undefined && this.listaCargo[index].Ancho != null
            && this.listaCargo[index].Largo != undefined && this.listaCargo[index].Largo != null) {
            if (!this.comprobarCalculoVolumen(this.listaCargo[index])) {
              this.listaCargo[index].Volumen = Number((this.listaCargo[index].Alto * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
              this.listaCargo[index].Volumen = Number((Number(this.listaCargo[index].Volumen) * 0.000001).toString().replace(',', '.')) * this.listaCargo[index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
              this.volumen[index] = Number((Number(this.listaCargo[index].Volumen.toFixed(3))).toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
            }
            else {
              this.listaCargo[index].Volumen = null
              var Body = this.translateService.instant("Error al indicar Alto x Ancho x Largo");
              var Titulo = this.translateService.instant("Falta informacion");
              this.toastr.error(Body, Titulo)
            }
          }
          else
            this.listaCargo[index].Volumen = null;
        }
      }
      else if (this.area == 3) {
        if (!this.listaCargo[index].Remontable && this.listaCargo[index].Alto < 160) {
          this.listaCargo[index].Volumen = Number((160 * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
          this.listaCargo[index].Volumen = Number((Number(this.listaCargo[index].Volumen.toString().replace(',', '.')) * 0.000001)) * this.listaCargo[index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
          this.volumen[index] = Number(this.listaCargo[index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
        } else {
          this.listaCargo[index].Volumen = Number((this.listaCargo[index].Alto * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
          this.listaCargo[index].Volumen = Number((Number(this.listaCargo[index].Volumen.toString().replace(',', '.')) * 0.000001)) * this.listaCargo[index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
          this.volumen[index] = Number(this.listaCargo[index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
        }
      }
      else if (this.area == 1) {
        if (!this.listaCargo[index].Remontable)
          this.listaCargo[index].Volumen = Number((220 * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
        else if (!this.listaCargo[index].Remontable && this.listaCargo[index].Alto > 110)
          this.listaCargo[index].Volumen = Number((220 * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
        else
          this.listaCargo[index].Volumen = Number((this.listaCargo[index].Alto * this.listaCargo[index].Ancho * this.listaCargo[index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3

        this.listaCargo[index].Volumen = Number((Number(this.listaCargo[index].Volumen.toString().replace(',', '.')) * 0.000001)) * this.listaCargo[index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
        this.volumen[index] = Number(this.listaCargo[index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
      }
    }
    else
      this.listaCargo[index].Volumen = 0;
  }

  //Funcion que comprueba los pesos y los campos vacíos
  recalculo(index) {
    if (!this.fcl) {
      this.camposVacios = this.comprobarVacio(this.listaCargo[index]);
      if (!this.isCotizacion)
        if (this.listaCargo[index] != undefined)
          this.listaCargo[index].IdMercancia = 0;

      //this.errorBruto[index] = this.comprobarPesos(this.listaCargo[index]);
      this.listaAux.emit(this.listaCargo); // Se emite
    } else {
      this.camposVacios = this.comprobarVacioMercanciaContenedor(this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[index]);
      if (!this.isCotizacion)
        if (this.listaCargo[index] != undefined)
          this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[index].IdMercancia = 0;

      this.listaAuxContenedor.emit(this.listaCargoContenedor);
    }
  }

  //Funcion que comprueba si la medida Alto está vacía o es nula
  isVacioAlto(elem: Mercancia) {
    return (elem.Alto === null || elem.Alto === undefined)
  }

  //Funcion que comprueba si la medida Ancho está vacía o es nula
  isVacioAncho(elem: Mercancia) {
    return (elem.Ancho === null || elem.Ancho === undefined)
  }

  //Funcion que comprueba si la medida Largo está vacía o es nula
  isVacioLargo(elem: Mercancia) {
    return (elem.Largo === null || elem.Largo === undefined)
  }

  //Funcion que comprueba si el peso bruto está vacía o es nula
  isVacioPesoBruto(elem: Mercancia) {
    return (elem.PesoBruto === undefined || elem.PesoBruto === null || elem.PesoBruto === 0)
  }

  //Funcion que comprueba si el peso bruto está vacía o es nula
  isVacioPesoNeto(elem: Mercancia) {
    return (elem.PesoNeto === undefined || elem.PesoNeto === null)
  }

  //Funcion que comprueba si la cantidad de bultos es vacía o es nula
  isVacioBultos(elem: Mercancia) {
    return (elem.Bultos === null || elem.Bultos === undefined)
  }

  //Funcion que comprueba si el tipo de embalaje está vacío o es nulo
  isVacioTipoEmbalaje(elem: Mercancia) {
    if (elem != undefined)
      if (elem.TipoEmbalaje == undefined || elem.TipoEmbalaje == null)
        return true;
      else
        return false;
    else
      return true;
  }

  isVacioGradosMin(elem: Mercancia) {
    return (elem.TemperaturaControlada && (elem.GradosMin === undefined || elem.GradosMin === null));
  }

  isVacioGradosMax(elem: Mercancia) {
    return (elem.TemperaturaControlada && (elem.GradosMax === undefined || elem.GradosMax === null));
  }

  GradosMinMayorQueGradosMax(elem: Mercancia) {
    return (elem.TemperaturaControlada && !this.isVacioGradosMin(elem) && !this.isVacioGradosMax(elem) && elem.GradosMin > elem.GradosMax);
  }

  //Funcion que comprueba si el volumen está vacío o es nulo
  isVacioVolumen(elem: Mercancia) {
    if (!this.fcl)
      return (elem.Volumen === null || elem.Volumen === undefined || elem.Volumen === 0)
  }

  //Funcion que comprueba si el alto, ancho o largo están vacios o son nulos
  comprobarCalculoVolumen(elem: Mercancia) {
    if (this.cliente.Cliente == 20)
      return false;
    else
      return (this.isVacioAlto(elem) || this.isVacioAncho(elem) || this.isVacioLargo(elem) && this.isVacioVolumen(elem))
  }

  //Funcion que comprueba si los pesos están vacíos o son nulos
  comprobarPesos(elem: Mercancia) { // false está bien -- true está mal/
    if (elem.PesoBruto != undefined && elem.PesoBruto != null && elem.PesoNeto != undefined && elem.PesoNeto != null && elem.PesoBruto >= 0 && elem.PesoNeto >= 0)
      return elem.PesoBruto < elem.PesoNeto
    else
      return false;
  }

  //Funcion que comprueba si están vacíos todos los cambios requeridos (bultos, volumen, medidas y tipo embalaje)
  comprobarVacio(merc: Mercancia, del?): boolean {
    if (this.isVacioVolumen(merc) && this.isVacioPesoBruto(merc)) {
      this.camposVacios = true
      return true;
    }
    else {
      this.camposVacios = false
      return false
    }
  }

  obtenerGradosMax(event) {
    this.gradosMax[this.indiceEdit] = this.listaCargo[this.indiceEdit].GradosMax = event;
  }

  obtenerGradosMin(event) {
    this.gradosMin[this.indiceEdit] = this.listaCargo[this.indiceEdit].GradosMin = event;
  }


  obtenerGradosMaxMercanciaContenedor(event) {
    this.gradosMaxMercanciaContenedor[this.selectedContenedorIndex][this.indiceEdit] = this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[this.indiceEdit].GradosMax = event;
  }

  obtenerGradosMinMercanciaContenedor(event) {
    this.gradosMinMercanciaContenedor[this.selectedContenedorIndex][this.indiceEdit] = this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[this.indiceEdit].GradosMin = event;
  }
  setVolumen(volumen, index: number) {
    if (volumen != null && volumen != undefined && volumen.valueAsNumber > 0)
      this.listaCargo[index].Volumen = Number(volumen.value.toString().replace(',', '.'));
  }

  setContenedor(contenedor: Contenedor, index: number) {
    if (contenedor != null || contenedor != undefined) {
      this.listaCargoContenedor[index].IdTipoContenedor = contenedor.codigo;
      this.listaCargoContenedor[index].NombreContenedor = contenedor.nombre;
    }
    else {
      //  this.listaCargoContenedor[index].IdContenedor = null;
      this.listaCargoContenedor[index].NombreContenedor = null;
    }
    this.camposVaciosContenedor = this.comprobarVacioContenedor(this.listaCargoContenedor[index]);
    if (this.listaCargoContenedor[index].Mercancia.length == 0 && this.listaCargoContenedor[index].NombreContenedor != null)
      this.addEmptyLineMercanciaCargo();
    if (this.listaCargoContenedor[index].NombreContenedor == null && this.listaCargoContenedor[index].Mercancia.length > 0) {
      this.deleteMercanciaContenedor(index);
    }

  }

  getContenedor(index: number) {
    return this.listaCargoContenedor[index];
  }

  getMP(index: number) {
    return this.MP[index];
  }

  setNA(na: boolean, index: number) {
    if (this.area == 1) { // Es terrestre
      this.NA[index] = na;
      this.listaCargo[index].MP = na == true ? 0 : 1;
      this.MP[index] = !na;
    } else { // Demás áreas
      this.NA[index] = na;
      if (na == true)
        this.listaCargo[index].NA = 1;
      else
        this.listaCargo[index].NA = 0;
    }
  }

  setMP(mp: boolean, index: number) {
    if (this.area == 1) { // Es terrestre
      this.MP[index] = mp;
      this.listaCargo[index].MP = mp == true ? 1 : 0;
      this.NA[index] = !mp;
    } else { // Demás áreas
      this.MP[index] = mp;
      if (mp == true)
        this.listaCargo[index].MP = 1;
      else
        this.listaCargo[index].MP = 0;
    }
  }
  //#endregion

  //#region CONTENEDOR
  // comprobarClasificacionContenedor(array: boolean[], name: string, i: number){
  //   var value = this.clasificacionContenedor.find(c => c.name == name).value;
  //   if(array[i] && this.clasContenedor[i].find(cc => cc == value) == undefined)
  //     this.clasContenedor[i] = [...this.clasContenedor[this.selectedContenedorIndex], value];
  //   else if(array.find(g => g) == undefined){
  //     var index = this.clasContenedor[this.selectedContenedorIndex].findIndex(cc => cc == value);
  //     this.clasContenedor[this.selectedContenedorIndex].splice(index, 1);
  //     this.clasContenedor[this.selectedContenedorIndex] = [...this.clasContenedor[this.selectedContenedorIndex]];
  //   }
  // }

  // changeClasificacionContenedor(c, i){
  //   // Si es remove
  //   if(c.value.value != undefined && c.value.value != null)
  //     c = c.value;

  //   if(c.name == "Peligrosa")
  //   {
  //     this.peligrosaContenedor[i] = !this.peligrosaContenedor[i];
  //     this.listaCargoContenedor[i].Peligrosa = this.peligrosaContenedor[i];
  //     if(this.listaCargoContenedor[i].MercanciaPeligrosa == undefined || this.listaCargoContenedor[i].MercanciaPeligrosa == null)
  //       this.listaCargoContenedor[i].MercanciaPeligrosa = [];
  //   }
  //   else if(c.name == "AlimentaciónClasificacion")
  //   {
  //     this.alimentacionContenedor[i] = !this.alimentacionContenedor[i];
  //     this.listaCargoContenedor[i].Alimentacion = this.alimentacionContenedor[i];
  //   }
  //   else if(c.name == "GDP")
  //   {
  //     this.gdpContenedor[i] = !this.gdpContenedor[i];
  //     this.listaCargoContenedor[i].GDP = this.gdpContenedor[i];
  //   }
  //   else if(c.name == "General")
  //   {
  //     this.generalContenedor[i] = !this.generalContenedor[i];
  //     this.listaCargoContenedor[i].General = this.generalContenedor[i];
  //   }
  //   this.recalculoContenedor(i);
  // }

  // clearClasificacionContenedor(i){
  //   this.peligrosaContenedor[i] = false;
  //   this.alimentacionContenedor[i] = false;
  //   this.gdpContenedor[i] = false;
  //   this.recalculoContenedor(i);
  // }

  // hayMercanciaPeligrosaContenedor(){
  //   return this.peligrosaContenedor.find(p => p);
  // }

  obtenerInformacion() {
    let i = 0;
    this.listaCargoContenedor?.forEach(element => {
      // this.nombreDescripcionMercanciaContenedor[i] = element.NombreTipoMercanciaContenedor;
      var tipoContenedor = null;
      if (this.listContenedor != undefined && this.listContenedor != null)
        tipoContenedor = this.listContenedor.find(c => c.codigo == element.IdTipoContenedor);
      // this.contenedor[i] = element.IdTipoContenedor;
      // if(element.Bultos != null)
      //   this.bultosContenedor[i] = element.Bultos.toString();

      if (tipoContenedor != null && tipoContenedor != undefined)
        this.contenedor[i] = tipoContenedor.codigo;
      else
        this.contenedor[i] = null;

      // if(element.PesoBruto != null)
      //   this.pesoBrutoContenedor[i] = element.PesoBruto.toString().replace(',', '.');

      // if(this.clas[i] == undefined || this.clas[i] == null)
      //   this.clasContenedor[i] = [];

      // if(element.Alimentacion)
      //   this.clasContenedor[i].push(this.clasificacionContenedor.find(c => c.name == "AlimentaciónClasificacion").value);
      // if(element.Peligrosa)
      //   this.clasContenedor[i].push(this.clasificacionContenedor.find(c => c.name == "Peligrosa").value);
      // if(element.GDP)
      //   this.clasContenedor[i].push(this.clasificacionContenedor.find(c => c.name == "GDP").value);
      // if(element.General)
      //   this.clasContenedor[i].push(this.clasificacionContenedor.find(c => c.name == "General").value);

      // this.MPContenedor[i] = element.Peligrosa;
      // this.NAContenedor[i] = element.NA;

      // this.listaCargoContenedor[i].Peligrosa = this.peligrosaContenedor[i] = element.Peligrosa;
      // this.listaCargoContenedor[i].Alimentacion = this.alimentacionContenedor[i] = element.Alimentacion;
      // this.listaCargoContenedor[i].GDP = this.gdpContenedor[i] = element.GDP;
      // this.listaCargoContenedor[i].General = this.generalContenedor[i] = element.General;
      this.listaCargoContenedor[i].Mercancia = element.Mercancia;
      this.listaCargoMercanciaContenedor[i] = element.Mercancia;
      this.obtenerMedidasMercanciaContenedor();
      i++;
    })
  }

  //Funcion que elimina la linea de la mercancia seleccionada
  deleteContenedor(linea: number) {
    if (!this.visor) {
      var lineaMercancia = 1;
      this.listaCargoContenedor.forEach(m => {
        this.deleteMercanciaMercanciaContenedor(lineaMercancia);
        this.camposVaciosContenedor = this.comprobarVacioContenedor(m);
        if (this.camposVaciosContenedor) {
          index++;
          return
        }
        lineaMercancia++;
      });
      this.listaCargoContenedor.splice(linea, 1);
      // this.errorBrutoContenedor.splice(linea,1);
      // this.pesoBrutoContenedor.splice(linea,1);
      // this.bultosContenedor.splice(linea, 1);
      this.listaAuxContenedor.emit(this.listaCargoContenedor);
      var index = 0;
      if (index == 0)
        this.camposVaciosContenedor = false;
    }
  }

  getBultosTotalesMercanciaContenedor(indexContenedor: number) {
    var bultosTotales: number = 0;
    this.listaCargoMercanciaContenedor[indexContenedor].forEach(m => {
      bultosTotales += Number(m.Bultos);
    });
    return bultosTotales;
  }

  getNetoTotalesMercanciaContenedor(indexContenedor: number) {
    var netoTotales: number = 0;
    this.listaCargoMercanciaContenedor[indexContenedor].forEach(m => {
      netoTotales += Number(m.PesoNeto.toFixed(3));
    });
    return netoTotales;
  }

  getBrutoTotalesMercanciaContenedor(indexContenedor: number) {
    var brutoTotales: number = 0;
    this.listaCargoMercanciaContenedor[indexContenedor].forEach(m => {
      brutoTotales += Number(m.PesoBruto.toFixed(3));
    });
    return brutoTotales;
  }

  getVolumenTotalesMercanciaContenedor(indexContenedor: number) {
    var volumenTotales: number = 0;
    this.listaCargoMercanciaContenedor[indexContenedor].forEach(m => {
      volumenTotales += Number(m.Volumen.toFixed(3));
    });
    return volumenTotales;
  }

  //Funcion que muestra si nos falta algun campo requerido para añadir la siguiente linea de mercancia
  mostrarErroresContenedor() {

    var mostrar = false;
    if (this.camposVaciosContenedor) {
      var Body = this.translateService.instant("Es necesario rellenar tipo de contenedor.");
      var Titulo = this.translateService.instant("Campos vacíos");
      this.toastr.error(Body, Titulo);
      mostrar = true;
    }
    return mostrar;
  }

  selectedContenedorIndexClick(i) {
    this.selectedContenedorIndex = i;
  }

  //Funcion que añade una linea vacía a la mercancía
  addEmptyLineFCL() {
    if (!this.visor)
      // if (!this.mostrarErroresContenedor()) {
      //  if(!this.primeraVezContenedor && this.selectedContenedorIndex >= 0 && 
      //   this.listaCargoContenedor[this.selectedContenedorIndex] != null &&
      //   this.listaCargoContenedor[this.selectedContenedorIndex] != undefined &&
      //   this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia != null && 
      //   this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia != undefined)
      //    this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia = this.contenedorMercancia.Mercancia;
      this.contenedorMercancia = new ContenedorMercancia();
    // this.contenedorMercancia.Bultos = 0;
    // this.contenedorMercancia.PesoBruto = 0;
    this.contenedorMercancia.Mercancia = [];
    this.listaCargoContenedor.push(this.contenedorMercancia);
    this.listaCargoMercanciaContenedor.push([]);
    this.selectedContenedor.push(false);
    // this.pesoBrutoContenedor.push('0');
    // this.bultosContenedor.push('0');
    // this.alimentacionContenedor.push(false);
    // this.peligrosaContenedor.push(false);
    // this.gdpContenedor.push(false);
    // this.generalContenedor.push(false);
    this.camposVaciosContenedor = true;
    this.primeraVezContenedor = false;
    this.selectedContenedorIndex = this.listaCargoContenedor.length - 1;
    // this.listaCargoContenedor[this.selectedContenedorIndex].MercanciaPeligrosa = []
    //this.addEmptyLineMercanciaCargo();
    //this.deleteMercanciaMercanciaContenedor(0);
    //this.listaCargo = [];
    this.listaAuxContenedor.emit(this.listaCargoContenedor);

    //  }
  }

  inicializarFCL() {
    if (!this.isCotizacion && (this.listaCargoContenedor == undefined || this.listaCargoContenedor == null || this.listaCargoContenedor.length == 0)) {
      this.listaCargoContenedor = [];
      this.nombreDescripcionMercanciaContenedor = [];
      this.listaCargoMercanciaContenedor = [];
      this.errorBultosMercanciaContenedor = [[false]];
      this.bultosMercanciaContenedor = [[]];
      this.nombreMercanciaContenedor = [[]];
      this.hscodesMercanciaContenedor = [[]];
      // this.bultosContenedor = [];
      // this.pesoBrutoContenedor = [];
      // this.clasContenedor = [[]];
      // this.peligrosaContenedor = [];
      // this.NAContenedor = [];
      // this.MPContenedor = [];
      this.tEmbalajeMercanciaContenedor = [[]];
      this.errorLargoMercanciaContenedor = [[false]];
      this.largoMercanciaContenedor = [[]];
      this.errorAnchoMercanciaContenedor = [[false]];
      this.anchoMercanciaContenedor = [[]];
      this.errorAltoMercanciaContenedor = [[false]];
      this.altoMercanciaContenedor = [[]];
      this.errorNetoMercanciaContenedor = [[false]];
      this.pesoNetoMercanciaContenedor = [[]];
      this.errorBrutoMercanciaContenedor = [[false]];
      this.pesoBrutoMercanciaContenedor = [[]];
      this.clasMercanciaContenedor = [[]];
      this.contenedorMercanciaContenedor = [[]];
      this.volumenMercanciaContenedor = [[]];
      this.peligrosaMercanciaContenedor = [[]];
      this.controlTemperaturaMercanciaContenedor = [[]];
      this.NAMercanciaContenedor = [[]];
      this.MPMercanciaContenedor = [[]];
      this.gradosMaxMercanciaContenedor = [[]];
      this.gradosMinMercanciaContenedor = [[]];
      this.errorGradosMercanciaContenedor = [[]];
    }
  }

  changeSelectedContenedor(index: number) {
    var todosCancelados = this.selectedContenedor[index];
    if (this.selectedContenedorIndex >= 0)
      this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia = this.listaCargo;

    for (var i = 0; i < this.selectedContenedor.length; i++)
      if (index != i)
        this.selectedContenedor[i] = false;
    this.listaCargo = this.listaCargoContenedor[index].Mercancia;
    this.obtenerMedidas();
    this.selectedContenedorIndex = !todosCancelados ? -1 : index;
  }

  //Funcion que añade a la lista el peso bruto
  // getPesoBrutoContenedor(pesoBruto: number, index) {
  //   this.pesoBrutoContenedor[index] = pesoBruto.toString().replace(',', '.');
  //   this.listaCargoContenedor[index].PesoBruto = Number(pesoBruto.toString().replace(',', '.'));
  // }

  // //Funcion que añade a la lista los bultos
  // getBultosContenedor(bultos: number, index) {
  //   this.listaCargoContenedor[index].Bultos = bultos;
  // }

  //Funcion que comprueba los pesos y los campos vacíos
  recalculoContenedor(index) {
    this.camposVaciosContenedor = this.comprobarVacioContenedor(this.listaCargoContenedor[index])
    if (!this.isCotizacion)
      if (this.listaCargoContenedor[index] != undefined)
        this.listaCargoContenedor[index].IdContenedor = 0;

    this.listaAuxContenedor.emit(this.listaCargoContenedor); // Se emite*/
  }

  //Funcion que comprueba si están vacíos todos los cambios requeridos (bultos, volumen, medidas y tipo embalaje)
  comprobarVacioContenedor(merc: ContenedorMercancia): boolean {
    return this.isVacioTipoContenedor(merc);
    // || this.isVacioBultosContenedor(merc) || this.isVacioPesoBrutoContenedor(merc);
  }

  isVacioTipoContenedor(merc: ContenedorMercancia) {
    return merc.IdTipoContenedor == null || merc.IdTipoContenedor == undefined;
  }

  // isVacioBultosContenedor(merc: ContenedorMercancia){
  //   return merc.Bultos == null || merc.Bultos == undefined || merc.Bultos < 0;
  // }

  // isVacioPesoBrutoContenedor(merc: ContenedorMercancia){
  //   return merc.PesoBruto == null || merc.PesoBruto == undefined || merc.PesoBruto < 0;
  // }


  //#endregion

  //#region MERCANCIA DEL CONTENEDOR

  //Funcion que añade una linea vacía a la mercancía
  addEmptyLineMercanciaCargo() {
    if (!this.visor)
      if (!this.mostrarErroresMercanciaContenedor()) {
        this.mercancia = new Mercancia();
        this.mercancia.Bultos = 0;
        this.mercancia.PesoBruto = 0;
        this.mercancia.PesoNeto = 0;
        this.mercancia.TipoEmbalaje = null;
        this.mercancia.Volumen = 0;
        this.mercancia.HSCode = ''


        this.BrutoPrimeraVezMercanciaContenedor.push([]);

        this.errorBrutoMercanciaContenedor.push([]);
        this.errorNetoMercanciaContenedor.push([]);
        this.errorBultosMercanciaContenedor.push([]);
        this.errorTipoEmbalajeMercanciaContenedor.push([]);
        this.errorAltoMercanciaContenedor.push([]);
        this.errorAnchoMercanciaContenedor.push([]);
        this.errorLargoMercanciaContenedor.push([]);
        this.errorGradosMercanciaContenedor.push([]);
        this.bultosMercanciaContenedor.push([]);
        this.tEmbalajeMercanciaContenedor.push([]);
        this.altoMercanciaContenedor.push([]);
        this.anchoMercanciaContenedor.push([]);
        this.largoMercanciaContenedor.push([]);
        this.pesoNetoMercanciaContenedor.push([]);
        this.pesoBrutoMercanciaContenedor.push([]);
        this.volumenMercanciaContenedor.push([]);
        this.nombreMercanciaContenedor.push([]);
        this.hscodesMercanciaContenedor.push([]);

        this.clasMercanciaContenedor.push([]);
        this.alimentacionMercanciaContenedor.push([]);
        this.peligrosaMercanciaContenedor.push([]);
        this.controlTemperaturaMercanciaContenedor.push([]);
        this.gdpMercanciaContenedor.push([]);
        this.remontableMercanciaContenedor.push([]);
        this.noremontableMercanciaContenedor.push([]);
        this.generalMercanciaContenedor.push([]);
        this.gradosMaxMercanciaContenedor.push([]);
        this.gradosMinMercanciaContenedor.push([]);
        this.mercancia.MP = Number(this.configEntidad.MercanciaPeligrosa);
        this.mercancia.Peligrosa = this.configEntidad.MercanciaPeligrosa;
        this.mercancia.Remontable = this.configEntidad.Remontable;
        this.mercancia.NA = Number(!this.configEntidad.MercanciaPeligrosa);
        if (this.mercancia.MP == 1)
          this.mercancia.MercanciaPeligrosa = [];
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].push(this.mercancia);
        this.BrutoPrimeraVezMercanciaContenedor[this.selectedContenedorIndex].push(true);
        if (this.MPMercanciaContenedor[this.selectedContenedorIndex] === undefined) {
          this.MPMercanciaContenedor[this.selectedContenedorIndex] = [];
        }
        this.MPMercanciaContenedor[this.selectedContenedorIndex].push(this.configEntidad.MercanciaPeligrosa);

        this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorNetoMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorBultosMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorTipoEmbalajeMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorAltoMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorAnchoMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorLargoMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.errorGradosMercanciaContenedor[this.selectedContenedorIndex].push(false);

        this.bultosMercanciaContenedor[this.selectedContenedorIndex].push('0');
        this.tEmbalajeMercanciaContenedor[this.selectedContenedorIndex].push(null);
        this.altoMercanciaContenedor[this.selectedContenedorIndex].push(null);
        this.largoMercanciaContenedor[this.selectedContenedorIndex].push(null);
        this.anchoMercanciaContenedor[this.selectedContenedorIndex].push(null);
        this.pesoNetoMercanciaContenedor[this.selectedContenedorIndex].push('0');
        this.pesoBrutoMercanciaContenedor[this.selectedContenedorIndex].push('0');
        this.volumenMercanciaContenedor[this.selectedContenedorIndex].push(null);
        this.nombreMercanciaContenedor[this.selectedContenedorIndex].push(null);
        this.hscodesMercanciaContenedor[this.selectedContenedorIndex].push(null);

        //this.clasMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.alimentacionMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.peligrosaMercanciaContenedor[this.selectedContenedorIndex].push(this.configEntidad.MercanciaPeligrosa);
        this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.gdpMercanciaContenedor[this.selectedContenedorIndex].push(false);
        this.remontableMercanciaContenedor[this.selectedContenedorIndex].push(this.configEntidad.Remontable);
        this.camposVacios = true;
        this.primeraVez = false;
        this.recargarSeleccionMercanciaContenedor(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].length - 1);
        this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex];

        if (!this.fcl)
          this.listaAux.emit(this.listaCargo); // Se emite
        else
          this.listaAuxContenedor.emit(this.listaCargoContenedor);
      }
  }

  tieneMercancia(indexContenedor: number) {
    return this.listaCargoContenedor[indexContenedor].Mercancia.length > 0;
  }

  //Funcion que añade a la lista el peso neto
  getPesoNetoMercanciaContenedor(pesoNeto: number, index) {
    this.pesoNetoMercanciaContenedor[this.selectedContenedorIndex][index] = pesoNeto.toString().replace(',', '.');
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].PesoNeto = Number(pesoNeto.toString().replace(',', '.'));
    if (!this.BrutoPrimeraVezMercanciaContenedor[this.selectedContenedorIndex][index] && pesoNeto > Number(this.pesoBrutoMercanciaContenedor[this.selectedContenedorIndex][index])) {
      this.errorNetoMercanciaContenedor[this.selectedContenedorIndex][index] = this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex][index] = true;
      var Body = this.translateService.instant("No puedes añadir un peso bruto menor que peso neto.");
      var Titulo = this.translateService.instant("Error Pesos");
      this.toastr.error(Body, Titulo);
    } else {
      this.errorNetoMercanciaContenedor[this.selectedContenedorIndex][index] = this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    }
  }

  //Funcion que añade a la lista el peso bruto
  getPesoBrutoMercanciaContenedor(pesoBruto: number, index) {
    this.BrutoPrimeraVezMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    this.pesoBrutoMercanciaContenedor[this.selectedContenedorIndex][index] = pesoBruto.toString().replace(',', '.');
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].PesoBruto = Number(pesoBruto.toString().replace(',', '.'));
    // if (!this.BrutoPrimeraVezMercanciaContenedor[this.selectedContenedorIndex][index] && 
    //   Number(this.pesoNetoMercanciaContenedor[this.selectedContenedorIndex][index]) > Number(pesoBruto)) {
    //   this.errorNetoMercanciaContenedor[this.selectedContenedorIndex][index] = this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex][index] = true;
    //   console.log("Paso 3!!")
    //   var Body = this.translateService.instant("No puedes añadir un peso bruto menor que peso neto.");
    //   var Titulo = this.translateService.instant("Error Pesos");
    //   this.toastr.error(Body, Titulo);
    // } else {
    //   this.errorNetoMercanciaContenedor[this.selectedContenedorIndex][index] = this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    //   console.log("Paso 4!!")
    // }
  }

  //Funcion que añade a la lista el tipo de embalaje
  setEmbalajeMercanciaContenedor(tipoEmbalaje: any, index) {
    this.setMedidasMercanciaContenedor(tipoEmbalaje, index);
    if (tipoEmbalaje != undefined) {
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].TipoEmbalaje = tipoEmbalaje.IdEmbalaje;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].NombreEmbalaje = tipoEmbalaje.TipoEmbalaje;
    }
    else {
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].TipoEmbalaje = null;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].NombreEmbalaje = null;
    }
  }

  //Funcion en el que si las medidas vienen por defecto en el embalaje seleccionado se setearan automaticamente
  setMedidasMercanciaContenedor(tipoEmbalaje: any, index: number) {
    if (tipoEmbalaje.Alto != undefined && tipoEmbalaje.Alto != null)
      this.getAltoMercanciaContenedor(tipoEmbalaje.Alto, index);
    if (tipoEmbalaje.Largo != undefined && tipoEmbalaje.Largo != null)
      this.getLargoMercanciaContenedor(tipoEmbalaje.Largo, index);
    if (tipoEmbalaje.Ancho != undefined && tipoEmbalaje.Ancho != null)
      this.getAnchoMercanciaContenedor(tipoEmbalaje.Ancho, index);

    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].forEach(m => {
      this.camposVacios = this.comprobarVacioMercanciaContenedor(m, 1)
    });
  }

  //Funcion que añade a la lista los bultos
  getBultosMercanciaContenedor(bultos: number, index) {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Bultos = bultos;
    if (bultos <= 0)
      this.errorBultosMercanciaContenedor[this.selectedContenedorIndex][index] = true;
    else
      this.errorBultosMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    this.calculaVolumenMercanciaContenedor(index);
  }

  recargarSeleccionMercanciaContenedor(i) {
    this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [];
    if (this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(0);
    if (this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(1);
    if (this.gdpMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(2);
    if (this.generalMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
    if (this.remontableMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(4);
    if (this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(5);
    if (this.noremontableMercanciaContenedor[this.selectedContenedorIndex][i])
      this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(6);
  }

  hayTemperaturaControladaEnCualquierContenedor() {
    var hayTemperaturaControlada = false;
    this.controlTemperaturaMercanciaContenedor.forEach(mc => {
      mc.forEach(tc => {
        if (!hayTemperaturaControlada && tc)
          hayTemperaturaControlada = true;
      });
    });
    return hayTemperaturaControlada;
  }

  hayMercanciaPeligrosaEnCualquierContenedor() {
    var hayMercanciaPeligrosa = false;
    this.peligrosaMercanciaContenedor.forEach(mc => {
      mc.forEach(mp => {
        if (!hayMercanciaPeligrosa && mp)
          hayMercanciaPeligrosa = true;
      });
    });
    return hayMercanciaPeligrosa;
  }


  changeClasificacionMercanciaContenedor(c, i, indexContenedor) {
    // Si es remove
    this.selectedContenedorIndex = indexContenedor;
    if (c.value.value != undefined && c.value.value != null)
      c = c.value;

    if (c.name == this.translateService.instant("Peligrosa")) {
      if (this.hayTemperaturaControladaEnCualquierContenedor()) {
        this.setMPMercanciaContenedor(false, i);
        this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Peligrosa = false;
        this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[i].MercanciaPeligrosa = [];
        this.recargarSeleccionMercanciaContenedor(i);
        this.toastr.info(this.translateService.instant("Si desea una mercancía peligrosa y controlar la temperatura debes de contratar con tu comercial"), this.translateService.instant("Contacta con tu comercial"));
      } else {
        this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i] = !this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i];
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Peligrosa = this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i];
        if (this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i]) {
          if (this.MPMercanciaContenedor[this.selectedContenedorIndex] == undefined || this.MPMercanciaContenedor[this.selectedContenedorIndex] == null)
            this.MPMercanciaContenedor[this.selectedContenedorIndex] = [this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i]];
          this.setMPMercanciaContenedor(true, i);
          this.recargarSeleccionMercanciaContenedor(i);
          if (this.clasMercanciaContenedor[this.selectedContenedorIndex][i].find(c => c == 3) != undefined) {
            this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
            this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = false;
            this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [0];
          }
        } else {
          this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[i].MercanciaPeligrosa = [];
          this.setMPMercanciaContenedor(false, i);
        }
      }
    }
    else if (c.name == this.translateService.instant("TemperaturaControlada")) {
      if (this.hayMercanciaPeligrosaEnCualquierContenedor()) {
        this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].TemperaturaControlada = false;
        this.recargarSeleccionMercanciaContenedor(i);
        this.toastr.info(this.translateService.instant("Si desea una mercancía peligrosa y controlar la temperatura debes de contratar con tu comercial"), this.translateService.instant("Contacta con tu comercial"));
      } else {
        this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i] = !this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i];
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].TemperaturaControlada = this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i];
        this.recargarSeleccionMercanciaContenedor(i);
        if (this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i] && this.clasMercanciaContenedor[this.selectedContenedorIndex][i].find(c => c == 3) != undefined) {
          this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
          this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = false;
          this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [5];
        }
      }
    }
    else if (c.name == this.translateService.instant("AlimentaciónClasificacion")) {
      this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i] = !this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i];
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Alimentacion = this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i];
      this.recargarSeleccionMercanciaContenedor(i);
      if (this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i] && this.clasMercanciaContenedor[this.selectedContenedorIndex][i].find(c => c == 3) != undefined) {
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
        this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = false;
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [1];
      }
    }
    else if (c.name == this.translateService.instant("GDP")) {
      this.gdpMercanciaContenedor[this.selectedContenedorIndex][i] = !this.gdpMercanciaContenedor[this.selectedContenedorIndex][i];
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].GDP = this.gdpMercanciaContenedor[this.selectedContenedorIndex][i];
      this.recargarSeleccionMercanciaContenedor(i);
      if (this.gdpMercanciaContenedor[this.selectedContenedorIndex][i] && this.clasMercanciaContenedor[this.selectedContenedorIndex][i].find(c => c == 3) != undefined) {
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
        this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = false;
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [2];
      }
    }
    else if (c.name == this.translateService.instant("General")) {
      this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = !this.generalMercanciaContenedor[this.selectedContenedorIndex][i];
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = this.generalMercanciaContenedor[this.selectedContenedorIndex][i];
      this.recargarSeleccionMercanciaContenedor(i);
      if (this.generalMercanciaContenedor[this.selectedContenedorIndex][i]) {
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [3];
        this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i] = this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i] = this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i] = this.gdpMercanciaContenedor[this.selectedContenedorIndex][i] = this.remontableMercanciaContenedor[this.selectedContenedorIndex][i] =
          this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Peligrosa = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Alimentacion = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].GDP = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Remontable = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].TemperaturaControlada = false;
      }
    }
    else if (c.name == this.translateService.instant("Remontable")) {
      this.remontableMercanciaContenedor[this.selectedContenedorIndex][i] = !this.remontableMercanciaContenedor[this.selectedContenedorIndex][i];
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Remontable = this.remontableMercanciaContenedor[this.selectedContenedorIndex][i];
      this.noremontableMercanciaContenedor[this.selectedContenedorIndex][i] = false
      this.recargarSeleccionMercanciaContenedor(i);
      if (this.generalMercanciaContenedor[this.selectedContenedorIndex][i] && this.clasMercanciaContenedor[this.selectedContenedorIndex][i].find(c => c == 3) != undefined) {
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
        this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = false;
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [4];
      }
      this.recalculoVolumenesMercanciaContenedor();
    }
    else if (c.name == this.translateService.instant("No remontable")) {
      this.noremontableMercanciaContenedor[this.selectedContenedorIndex][i] = !this.noremontableMercanciaContenedor[this.selectedContenedorIndex][i];
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].NoRemontable = this.noremontableMercanciaContenedor[this.selectedContenedorIndex][i];
      this.remontableMercanciaContenedor[this.selectedContenedorIndex][i] = false
      this.recargarSeleccionMercanciaContenedor(i);
      if (this.generalMercanciaContenedor[this.selectedContenedorIndex][i] && this.clasMercanciaContenedor[this.selectedContenedorIndex][i].find(c => c == 3) != undefined) {
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i].push(3);
        this.generalMercanciaContenedor[this.selectedContenedorIndex][i] = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General = false;
        this.clasMercanciaContenedor[this.selectedContenedorIndex][i] = [4];
      }
      this.recalculoVolumenesMercanciaContenedor();
    }
    this.recalculoMercanciaContenedor(i);
  }

  clearClasificacionMercanciaContenedor(i) {
    this.peligrosaMercanciaContenedor[this.selectedContenedorIndex][i] =
      this.alimentacionMercanciaContenedor[this.selectedContenedorIndex][i] =
      this.gdpMercanciaContenedor[this.selectedContenedorIndex][i] =
      this.remontableMercanciaContenedor[this.selectedContenedorIndex][i] =
      this.noremontableMercanciaContenedor[this.selectedContenedorIndex][i] =
      this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex][i] = false;
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Peligrosa =
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Alimentacion =
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].General =
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].GDP =
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].Remontable =
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][i].TemperaturaControlada = false;
    this.recalculoVolumenesMercanciaContenedor();
    this.recalculoMercanciaContenedor(i);
  }

  hayMercanciaPeligrosaMercanciaContenedor(index: number) {
    if (this.peligrosaMercanciaContenedor.length > 0)
      if (this.peligrosaMercanciaContenedor[index] != undefined && this.peligrosaMercanciaContenedor[index] != null
        && this.peligrosaMercanciaContenedor[index].length > 0)
        return this.peligrosaMercanciaContenedor[index].find(p => p);
    return false;
  }

  hayTemperaturaControladaMercanciaContenedor(index: number) {
    if (this.controlTemperaturaMercanciaContenedor.length > 0)
      if (this.controlTemperaturaMercanciaContenedor[index] != undefined && this.controlTemperaturaMercanciaContenedor[index] != null
        && this.controlTemperaturaMercanciaContenedor[index].length > 0)
        return this.controlTemperaturaMercanciaContenedor[index].find(p => p);
    return false;
  }

  setNombreMercanciaContenedor(nombreMercancia, j) {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][j].NombreMercancia = nombreMercancia;
    this.nombreMercanciaContenedor[this.selectedContenedorIndex][j] = nombreMercancia;
    this.hscodesMercanciaContenedor[this.selectedContenedorIndex][j] = null;
  }

  setHSCodesMercanciaContenedor(HSCode, j) {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][j].HSCode = HSCode;
    this.hscodesMercanciaContenedor[this.selectedContenedorIndex][j] = HSCode;
  }

  setNombreTipoMercanciaContenedor(nombreMercancia, j) {
    this.nombreDescripcionMercanciaContenedor[j] = nombreMercancia;
  }

  inicializarMercanciaFCL() {
    this.inicializadoFCL = true;
    if (this.listaCargoContenedor == undefined || this.listaCargoContenedor == null || this.listaCargoContenedor.length == 0)
      this.inicializarFCL();
    else {
      this.listaCargoContenedor.forEach(c => {
        this.nombreDescripcionMercanciaContenedor.push('');
        //this.listaCargoMercanciaContenedor.push([]);
        this.errorBultosMercanciaContenedor.push([false]);
        this.bultosMercanciaContenedor.push([]);
        this.nombreMercanciaContenedor.push([]);
        this.hscodesMercanciaContenedor.push([]);
        // this.bultosContenedor.push('');
        // this.pesoBrutoContenedor.push('');
        // this.clasContenedor.push([]);
        // this.peligrosaContenedor.push(false);
        // this.NAContenedor.push(false);
        // this.MPContenedor.push(false);
        this.tEmbalajeMercanciaContenedor.push([]);
        this.errorLargoMercanciaContenedor.push([false]);
        this.largoMercanciaContenedor.push([]);
        this.errorAnchoMercanciaContenedor.push([false]);
        this.anchoMercanciaContenedor.push([]);
        this.errorAltoMercanciaContenedor.push([false]);
        this.altoMercanciaContenedor.push([]);
        this.errorNetoMercanciaContenedor.push([false]);
        this.pesoNetoMercanciaContenedor.push([]);
        this.errorBrutoMercanciaContenedor.push([false]);
        this.pesoBrutoMercanciaContenedor.push([]);
        this.clasMercanciaContenedor.push([]);
        this.contenedorMercanciaContenedor.push([]);
        this.volumenMercanciaContenedor.push([]);
        this.peligrosaMercanciaContenedor.push([]);
        this.controlTemperaturaMercanciaContenedor.push([]);
        this.NAMercanciaContenedor.push([]);
        this.MPMercanciaContenedor.push([]);
        this.gradosMaxMercanciaContenedor.push([]);
        this.gradosMinMercanciaContenedor.push([]);
        this.errorGradosMercanciaContenedor.push([false]);
      });
    }
  }

  obtenerMedidasMercanciaContenedor() {
    let i = 0;
    let j = 0;
    if (!this.inicializadoFCL)
      this.inicializarMercanciaFCL();
    this.listaCargoMercanciaContenedor.forEach(contenedor => {
      contenedor.forEach(element => {
        if (element.NombreMercancia != null)
          this.nombreMercanciaContenedor[i][j] = element.NombreMercancia;

        if (element.HSCode != null)
          this.hscodesMercanciaContenedor[i][j] = element.HSCode;

        if (this.tipoEmbalaje != undefined && this.tipoEmbalaje != null && this.tipoEmbalaje.length > 0)
          var tipoEmbalaje = this.tipoEmbalaje.find(t => t.TipoEmbalaje == element.NombreEmbalaje || t.IdEmbalaje == element.TipoEmbalaje);

        if (element.Bultos != null)
          this.bultosMercanciaContenedor[i][j] = element.Bultos.toString();

        if (tipoEmbalaje != null && tipoEmbalaje != undefined)
          this.tEmbalajeMercanciaContenedor[i][j] = tipoEmbalaje.IdTipoEmbalaje;
        else
          this.tEmbalajeMercanciaContenedor[i][j] = null;

        if (element.TemperaturaControlada) {
          this.gradosMaxMercanciaContenedor[i][j] = element.GradosMax;
          this.gradosMinMercanciaContenedor[i][j] = element.GradosMin;
          this.controlTemperaturaMercanciaContenedor[i][j] = element.TemperaturaControlada;
        }

        if (element.MP || element.Peligrosa || !element.NA) {
          this.peligrosaMercanciaContenedor[i][j] = Boolean(element.Peligrosa);
          if (this.MP == undefined || this.MP == null)
            this.MP = [Boolean(element.MP)];
          else if (this.MP.length >= i - 1)
            this.MP[i] = Boolean(element.MP);
          else
            this.MP.push(Boolean(element.MP));

          if (this.NA == undefined || this.NA == null)
            this.NA = [Boolean(element.NA)];
          else if (this.NA.length >= i - 1)
            this.NA[i] = Boolean(element.NA);
          else
            this.NA.push(Boolean(element.NA));
        }

        this.contenedorMercanciaContenedor[i][j] = element.IdContenedor;
        if (element.Alto != undefined && element.Ancho != undefined && element.Largo != undefined) {
          if ((this.altoMercanciaContenedor[i][j] == null || this.altoMercanciaContenedor[i][j] == undefined || this.altoMercanciaContenedor[i][j] == "0")
            && tipoEmbalaje != undefined && tipoEmbalaje != null
            && tipoEmbalaje.Alto != 0 && tipoEmbalaje.Alto != null && tipoEmbalaje.Alto != undefined
            && (element.Alto == 0 || element.Alto == null || element.Alto == undefined))
            this.altoMercanciaContenedor[i][j] = tipoEmbalaje.Alto.toString();
          else
            this.altoMercanciaContenedor[i][j] = element.Alto.toString();

          if ((this.anchoMercanciaContenedor[i][j] == null || this.anchoMercanciaContenedor[i][j] == undefined || this.anchoMercanciaContenedor[i][j] == "0")
            && tipoEmbalaje != undefined && tipoEmbalaje != null
            && tipoEmbalaje.Ancho != 0 && tipoEmbalaje.Ancho != null && tipoEmbalaje.Ancho != undefined
            && (element.Ancho == 0 || element.Ancho == null || element.Ancho == undefined))
            this.anchoMercanciaContenedor[i][j] = tipoEmbalaje.Ancho.toString();
          else
            this.anchoMercanciaContenedor[i][j] = element.Ancho.toString();

          if ((this.largoMercanciaContenedor[i][j] == null || this.largoMercanciaContenedor[i][j] == undefined || this.largoMercanciaContenedor[i][j] == "0")
            && tipoEmbalaje != undefined && tipoEmbalaje != null
            && tipoEmbalaje.Largo != 0 && tipoEmbalaje.Largo != null && tipoEmbalaje.Largo != undefined
            && (element.Largo == 0 || element.Largo == null || element.Largo == undefined))
            this.largoMercanciaContenedor[i][j] = tipoEmbalaje.Largo.toString();
          else
            this.largoMercanciaContenedor[i][j] = element.Largo.toString();
        }
        if (element.PesoNeto != null) {
          element.PesoNeto = Number(element.PesoNeto.toString().replace(',', '.'));
          this.pesoNetoMercanciaContenedor[i][j] = element.PesoNeto.toString().replace(',', '.');
        }
        if (element.PesoBruto != null)
          this.pesoBrutoMercanciaContenedor[i][j] = element.PesoBruto.toString().replace(',', '.');

        this.volumenMercanciaContenedor[i][j] = Number(element.Volumen?.toString().replace(',', '.'));

        if (element.NA == 1)
          this.NAMercanciaContenedor[i][j] = true;
        else
          this.NAMercanciaContenedor[i][j] = false;

        if (element.MP == 1)
          this.MPMercanciaContenedor[i][j] = true;
        else
          this.MPMercanciaContenedor[i][j] = false;

        this.clasMercanciaContenedor[i].push([]);

        if (element.Alimentacion)
          this.clasMercanciaContenedor[i][j].push(this.clasificacion.find(c => c.name == this.translateService.instant("AlimentaciónClasificacion")).value);
        if (element.Peligrosa)
          this.clasMercanciaContenedor[i][j].push(this.clasificacion.find(c => c.name == this.translateService.instant("Peligrosa")).value);
        if (element.Remontable)
          this.clasMercanciaContenedor[i][j].push(this.clasificacion.find(c => c.name == this.translateService.instant("Remontable")).value);
        if (element.NoRemontable)
          this.clasMercanciaContenedor[i][j].push(this.clasificacion.find(c => c.name == this.translateService.instant("No remontable")).value);
        if (element.GDP)
          this.clasMercanciaContenedor[i][j].push(this.clasificacion.find(c => c.name == this.translateService.instant("GDP")).value);
        if (element.TemperaturaControlada)
          this.clasMercanciaContenedor[i][j].push(this.clasificacion.find(c => c.name == this.translateService.instant("TemperaturaControlada")).value);

        this.listaCargoMercanciaContenedor[i][j].Peligrosa = element.Peligrosa;
        this.listaCargoMercanciaContenedor[i][j].TemperaturaControlada = element.TemperaturaControlada;
        this.listaCargoMercanciaContenedor[i][j].Alimentacion = element.Alimentacion;
        this.listaCargoMercanciaContenedor[i][j].GDP = element.GDP;
        this.listaCargoMercanciaContenedor[i][j].Remontable = element.Remontable;
        this.listaCargoMercanciaContenedor[i][j].TemperaturaControlada = element.TemperaturaControlada;
        this.listaCargoMercanciaContenedor[i][j].GradosMax = element.GradosMax;
        this.listaCargoMercanciaContenedor[i][j].GradosMin = element.GradosMin;

        j++;
      });
      //this.addEmptyLineFCL();
      i++;
      j = 0;
    })
  }

  recalculoVolumenesMercanciaContenedor() {
    if (this.area == 2 && this.fcl)
      for (var i = 0; i < this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].length; i++)
        this.calculaVolumenMercanciaContenedor(i);
    else if (this.area == 1)
      for (var i = 0; i < this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].length; i++)
        this.calculaVolumenMercanciaContenedor(i);
  }

  recalculoLineasMercanciaContenedor() {
    let length = this.listaCargo.length;
    for (let i = 0; i < length; i++)
      this.recalculoMercanciaContenedor(i);
  }

  //Funcion que elimina la linea de la mercancia seleccionada
  deleteMercanciaMercanciaContenedor(linea: number) {
    if (this.visor) {
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.pesoBrutoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.tEmbalajeMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.pesoNetoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.bultosMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.volumenMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.nombreMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.hscodesMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.errorGradosMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.gradosMaxMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.gradosMinMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.largoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.anchoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.altoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.peligrosaMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.generalMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.alimentacionMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.gdpMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.remontableMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.noremontableMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      if (!this.fcl)
        this.listaAux.emit(this.listaCargo); // Se emite
      else
        this.listaAuxContenedor.emit(this.listaCargoContenedor);
      var index = 0;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].forEach(m => {
        this.camposVacios = this.comprobarVacioMercanciaContenedor(m, 1)
        if (this.camposVacios) {
          index++;
          return
        }
        //this.errorBruto[index] = this.comprobarPesos(m, 1)
      })
      if (index == 0)
        this.camposVacios = false;
    }
  }

  //Funcion que muestra si nos falta algun campo requerido para añadir la siguiente linea de mercancia
  mostrarErroresMercanciaContenedor() {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].forEach(m => {
      this.camposVaciosContenedor = this.comprobarVacioMercanciaContenedor(m, 1)

    })

    var mostrar = false;
    if (this.camposVaciosContenedor) {
      var Body = this.translateService.instant("Es necesario rellenar como mínimo Tipo de Contenedor, peso bruto o volumen.");
      var Titulo = this.translateService.instant("Campos vacíos");
      this.toastr.error(Body, Titulo);
      mostrar = true;
    }
    if (this.errorBrutoMercanciaContenedor.length > 0 && this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex].includes(true)) {
      var Body = this.translateService.instant("No puedes añadir un peso bruto menor que peso neto.");
      var Titulo = this.translateService.instant("Error Pesos");
      this.toastr.error(Body, Titulo);
      mostrar = true;
    }
    if (this.fcl && this.selectedContenedorIndex < 0) {
      var Body = this.translateService.instant("No puedes añadir mercancía sin indicar a que contenedor tiene que ir.");
      var Titulo = this.translateService.instant("Error Añadir Mercancía");
      this.toastr.error(Body, Titulo);
      mostrar = true;
    }
    return mostrar;
  }

  //Funcion que añade a la lista las medidas
  getAltoMercanciaContenedor(alto, index) {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto = Number(alto.toString().replace(',', '.'));
    this.altoMercanciaContenedor[this.selectedContenedorIndex][index] = alto.toString().replace(',', '.');
    if (alto <= 0)
      this.errorAltoMercanciaContenedor[this.selectedContenedorIndex][index] = true;
    else
      this.errorAltoMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    this.calculaVolumenMercanciaContenedor(index);
  }

  //Funcion que añade a la lista las medidas
  getAnchoMercanciaContenedor(ancho, index) {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho = Number(ancho.toString().replace(',', '.'));
    this.anchoMercanciaContenedor[this.selectedContenedorIndex][index] = ancho.toString().replace(',', '.');
    if (ancho <= 0)
      this.errorAnchoMercanciaContenedor[this.selectedContenedorIndex][index] = true;
    else
      this.errorAnchoMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    this.calculaVolumenMercanciaContenedor(index);
  }

  //Funcion que añade a la lista las medidas
  getLargoMercanciaContenedor(largo, index) {
    this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo = Number(largo.toString().replace(',', '.'));
    this.largoMercanciaContenedor[this.selectedContenedorIndex][index] = largo.toString().replace(',', '.');
    if (largo <= 0)
      this.errorLargoMercanciaContenedor[this.selectedContenedorIndex][index] = true;
    else
      this.errorLargoMercanciaContenedor[this.selectedContenedorIndex][index] = false;
    this.calculaVolumenMercanciaContenedor(index);
  }

  //Funcion que añade a la lista el volumen
  calculaVolumenMercanciaContenedor(index) {
    if (this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto != undefined && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto != null && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto != 0
      && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho != undefined && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho != null && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho != 0 && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo != undefined
      && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo != null && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo != 0) {
      if (this.area == 2) {
        if (!this.fcl) {
          if (!this.comprobarCalculoVolumenMercanciaContenedor(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index])) {
            if (!this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Remontable)
              this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((180 * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
            else
              this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3

            this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toString().replace(',', '.')) * 0.000001)) * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
            this.volumenMercanciaContenedor[this.selectedContenedorIndex][index] = Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
          }
          else {
            this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = null
            var Body = this.translateService.instant("Error al indicar Alto x Ancho x Largo");
            var Titulo = this.translateService.instant("Falta informacion");
            this.toastr.error(Body, Titulo)
          }
        }
        else {
          if (this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto != undefined && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto != null
            && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho != undefined && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho != null
            && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo != undefined && this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo != null) {
            if (!this.comprobarCalculoVolumenMercanciaContenedor(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index])) {
              this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
              this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen) * 0.000001).toString().replace(',', '.')) * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
              this.volumenMercanciaContenedor[this.selectedContenedorIndex][index] = Number((Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toFixed(3))).toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
            }
            else {
              this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = null
              var Body = this.translateService.instant("Error al indicar Alto x Ancho x Largo");
              var Titulo = this.translateService.instant("Falta informacion");
              this.toastr.error(Body, Titulo)
            }
          }
          else
            this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = null;
        }
      }
      else if (this.area == 3) {
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toString().replace(',', '.')) * 0.000001)) * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
        this.volumenMercanciaContenedor[this.selectedContenedorIndex][index] = Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
      }
      else if (this.area == 1) {
        if (!this.listaCargo[index].Remontable)
          this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((220 * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
        else if (this.listaCargo[index].Remontable && this.listaCargo[index].Alto > 110)
          this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((220 * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3
        else
          this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Alto * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Ancho * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Largo).toString().replace(',', '.')); //Se calcula el volumen en cm3

        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number((Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toString().replace(',', '.')) * 0.000001)) * this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Bultos; //Se realiza la conversion de cm3 a m3 y además se calcula con el total de bultos
        this.volumenMercanciaContenedor[this.selectedContenedorIndex][index] = Number(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen.toFixed(3).toString().replace(',', '.')); //Se setea el volumen en el array
      }
    }
    else
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = 0;
  }

  //Funcion que comprueba los pesos y los campos vacíos
  recalculoMercanciaContenedor(index) {
    this.camposVacios = this.comprobarVacioMercanciaContenedor(this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index]);
    if (!this.isCotizacion)
      if (this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index] != undefined)
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].IdMercancia = 0;
    this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia = this.listaCargoMercanciaContenedor[this.selectedContenedorIndex];
    //this.errorBruto[index] = this.comprobarPesos(this.listaCargo[index]);
    if (!this.fcl)
      this.listaAux.emit(this.listaCargo); // Se emite
    else
      this.listaAuxContenedor.emit(this.listaCargoContenedor);
  }

  //Funcion que comprueba si la medida Alto está vacía o es nula
  isVacioAltoMercanciaContenedor(elem: Mercancia) {
    return (elem.Alto === null || elem.Alto === undefined)
  }

  //Funcion que comprueba si la medida Ancho está vacía o es nula
  isVacioAnchoMercanciaContenedor(elem: Mercancia) {
    return (elem.Ancho === null || elem.Ancho === undefined)
  }

  //Funcion que comprueba si la medida Largo está vacía o es nula
  isVacioLargoMercanciaContenedor(elem: Mercancia) {
    return (elem.Largo === null || elem.Largo === undefined)
  }

  //Funcion que comprueba si el peso bruto está vacía o es nula
  isVacioPesoBrutoMercanciaContenedor(elem: Mercancia) {
    return (elem.PesoBruto === undefined || elem.PesoBruto === null)
  }

  //Funcion que comprueba si el peso bruto está vacía o es nula
  isVacioPesoNetoMercanciaContenedor(elem: Mercancia) {
    return (elem.PesoNeto === undefined || elem.PesoNeto === null)
  }

  //Funcion que comprueba si la cantidad de bultos es vacía o es nula
  isVacioBultosMercanciaContenedor(elem: Mercancia) {
    return (elem.Bultos === null || elem.Bultos === undefined)
  }

  //Funcion que comprueba si el tipo de embalaje está vacío o es nulo
  isVacioTipoEmbalajeMercanciaContenedor(elem: Mercancia) {
    if (elem != undefined)
      if (elem.TipoEmbalaje == undefined || elem.TipoEmbalaje == null)
        return true;
      else
        return false;
    else
      return true;
  }

  //Funcion que comprueba si el volumen está vacío o es nulo
  isVacioVolumenMercanciaContenedor(elem: Mercancia) {
    return (elem.Volumen === null || elem.Volumen === undefined)
  }

  //Funcion que comprueba si el alto, ancho o largo están vacios o son nulos
  comprobarCalculoVolumenMercanciaContenedor(elem: Mercancia) {
    if (this.cliente != undefined && this.cliente != null && this.cliente.Cliente == 20)
      return false;
    else
      return (this.isVacioAltoMercanciaContenedor(elem) || this.isVacioAnchoMercanciaContenedor(elem) || this.isVacioLargoMercanciaContenedor(elem) && this.isVacioVolumenMercanciaContenedor(elem))
  }

  //Funcion que comprueba si los pesos están vacíos o son nulos
  comprobarPesosMercanciaContenedor(elem: Mercancia) { // false está bien -- true está mal/
    if (elem.PesoBruto != undefined && elem.PesoBruto != null && elem.PesoNeto != undefined && elem.PesoNeto != null && elem.PesoBruto >= 0 && elem.PesoNeto >= 0)
      return elem.PesoBruto < elem.PesoNeto
    else
      return false;
  }

  //Funcion que comprueba si están vacíos todos los cambios requeridos (bultos, volumen, medidas y tipo embalaje)
  comprobarVacioMercanciaContenedor(merc: Mercancia, del?): boolean {
    if (this.fcl) {
      if (this.isVacioVolumen(merc) && this.isVacioPesoBruto(merc)) {
        this.camposVaciosContenedor = true;
        return true;
      }
      else {
        this.camposVaciosContenedor = false;
        return false;
      }
    }
    else
      return (this.isVacioBultos(merc) || this.isVacioVolumen(merc) || this.isVacioTipoEmbalaje(merc) || this.isVacioPesoBruto(merc) || this.isVacioDescripcionMercancia(merc));
  }

  isVacioDescripcionMercancia(merc: Mercancia): boolean {
    return merc.NombreMercancia === null || merc.NombreMercancia === undefined || merc.NombreMercancia.toString() === '';
  }

  setVolumenMercanciaContenedor(volumen: number, index: number) {
    if (volumen != null && volumen != undefined && volumen > 0)
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].Volumen = Number(volumen.toString().replace(',', '.'));
  }

  getMPMercanciaContenedor(indexContenedor: number, index: number) {
    return this.MPMercanciaContenedor[indexContenedor][index];
  }

  setNAMercanciaContenedor(na: boolean, index: number) {
    if (this.area == 1) { // Es terrestre
      this.NAMercanciaContenedor[this.selectedContenedorIndex][index] = na;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].MP = na == true ? 0 : 1;
      this.MPMercanciaContenedor[this.selectedContenedorIndex][index] = !na;
    } else { // Demás áreas
      this.NAMercanciaContenedor[this.selectedContenedorIndex][index] = na;
      if (na == true)
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].NA = 1;
      else
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].NA = 0;
    }
  }

  setMPMercanciaContenedor(mp: boolean, index: number) {
    if (this.area == 1) { // Es terrestre
      this.MPMercanciaContenedor[this.selectedContenedorIndex][index] = mp;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].MP = mp == true ? 1 : 0;
      this.NAMercanciaContenedor[this.selectedContenedorIndex][index] = !mp;
    } else { // Demás áreas
      this.MPMercanciaContenedor[this.selectedContenedorIndex][index] = mp;
      if (mp == true)
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].MP = 1;
      else
        this.listaCargoMercanciaContenedor[this.selectedContenedorIndex][index].MP = 0;
    }
  }

  obtenerConfirmacionMercanciaContenedor(confirmacion: boolean) {
    if (confirmacion == true)
      this.deleteMercanciaContenedor(this.indiceEdit);
    this.modalRef.hide();
  }

  //Funcion que elimina la linea de la mercancia seleccionada
  deleteMercanciaContenedor(linea: number) {
    if (!this.visor) {
      // linea = 0;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.errorBrutoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.pesoBrutoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.tEmbalajeMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.pesoNetoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.bultosMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.nombreMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.hscodesMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.volumenMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.hscodesMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.largoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.anchoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.altoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.pesoBrutoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.pesoNetoMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.peligrosaMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.generalMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.alimentacionMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.gdpMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.remontableMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.noremontableMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.controlTemperaturaMercanciaContenedor[this.selectedContenedorIndex].splice(linea, 1);
      this.listaAuxContenedor.emit(this.listaCargoContenedor);
      var index = 0;
      this.listaCargoMercanciaContenedor[this.selectedContenedorIndex].forEach(m => {
        this.camposVacios = this.comprobarVacioMercanciaContenedor(m, 1)
        if (this.camposVacios) {
          index++;
          return
        }
      })
      if (index == 0)
        this.camposVacios = false;
    }
  }
  //#endregion

  //#region DEMÁS
  obtenerListaMP(mp: MercanciaPeligrosa[]) {
    if (mp != null) {
      this.listaCargo[this.indiceEdit].MercanciaPeligrosa = mp;
      this.recalculo(this.indiceEdit);
    }
    this.modalService.hide();
    window.setTimeout(function () {
      document.getElementById('AnyadirLinea').focus();
    }, 0);
  }

  // obtenerListaMPContenedor(mp: MercanciaPeligrosa[])
  // {
  //   if(mp != null)
  //   {
  //     this.listaCargoContenedor[this.indiceEdit].MercanciaPeligrosa = mp;
  //     this.recalculoContenedor(this.indiceEdit);
  //   }
  //   this.modalService.hide();
  //   window.setTimeout(function ()
  //   {
  //     document.getElementById('AnyadirLineaContenedor').focus();
  //   }, 0);
  // }

  obtenerListaMPMercanciaContenedor(mp: MercanciaPeligrosa[]) {
    if (mp != null) {
      this.listaCargoContenedor[this.selectedContenedorIndex].Mercancia[this.indiceEdit].MercanciaPeligrosa = mp;
      this.recalculo(this.indiceEdit);
    }
    this.modalService.hide();
    window.setTimeout(function () {
      document.getElementById('AnyadirLinea').focus();
    }, 0);
  }


  // getMPContenedor(index: number) {
  //   return this.MPContenedor[index];
  // }

  // setNAContenedor(na: boolean, index: number) {
  //   if(this.area == 1){ // Es terrestre
  //     this.NAContenedor[index] = na;
  //     this.listaCargoContenedor[index].Peligrosa = na == true ? false : true;
  //     this.MPContenedor[index] = !na;
  //   }else{ // Demás áreas
  //     this.NAContenedor[index] = na;
  //     if(na == true)
  //       this.listaCargoContenedor[index].NA = true;
  //     else
  //       this.listaCargoContenedor[index].NA = false;
  //   }
  // }

  // setMPContenedor(mp: boolean, index: number) {
  //   if(this.area == 1){ // Es terrestre
  //     this.MPContenedor[index] = mp;
  //     this.listaCargoContenedor[index].Peligrosa = mp == true ? true : false;
  //     this.NAContenedor[index] = !mp;
  //   }else{ // Demás áreas
  //     this.MPContenedor[index] = mp;
  //     if(mp == true)
  //       this.listaCargoContenedor[index].Peligrosa = true;
  //     else
  //       this.listaCargoContenedor[index].Peligrosa = false;
  //   }
  // }

  obtenerPesoBrutoT(pesoBruto: number) {
    if (pesoBruto != null)
      this.pesoBrutoP = Number(pesoBruto.toString().replace(',', '.'));
    this.modalRef.hide();
  }

  cerrar(event) {
    this.modalRef.hide();
  }

  // obtenerPesoBrutoTContenedor(pesoBruto: number)
  // {
  //   if(pesoBruto != null)
  //     this.pesoBrutoPContenedor = Number(pesoBruto.toString().replace(',', '.'));
  //   this.modalRef.hide();
  // }
  comprobarPesosBN(pesoBruto: number, pesoNeto: number) {
    this.pesoBrutoP = Number(pesoBruto.toString().replace(',', '.'));
    this.pesoNetoP = Number(pesoNeto.toString().replace(',', '.'));
    let i = 0;
    this.listaCargo.forEach(element => {
      if (this.pesoBrutoP > element.PesoBruto) {
        console.log('errorsito')
      }
      i++;
    })

  }


  obtenerPesoBrutoTMercanciaContenedor(pesoBruto: number) {
    if (pesoBruto != null)
      this.pesoBrutoPMercanciaContenedor = Number(pesoBruto.toString().replace(',', '.'));
    this.modalRef.hide();
  }

  obtenerPesoNetoT(pesoNeto: number) {
    if (pesoNeto != null)
      this.pesoNetoP = Number(pesoNeto.toString().replace(',', '.'));
    this.modalRef.hide();
  }

  obtenerPesoNetoTMercanciaContenedor(pesoNeto: number) {
    if (pesoNeto != null)
      this.pesoNetoPMercanciaContenedor = Number(pesoNeto.toString().replace(',', '.'));
    this.modalRef.hide();
  }

  obtenerConfirmacion(confirmacion: boolean) {
    if (confirmacion == true)
      this.deleteMercancia(this.indiceEdit);
    this.modalRef.hide();
  }

  obtenerConfirmacionContenedor(confirmacion: boolean) {
    if (confirmacion == true)
      this.deleteContenedor(this.indiceEdit);
    this.modalRef.hide();
  }

  clientePermitido() {
    if (!this.visor && this.cliente != undefined && this.cliente != null && this.cliente.Cliente == 20)
      return false;
    return true;
  }
}
