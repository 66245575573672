export class Embalaje {
    IdEmbalaje: number;
    TipoEmbalaje: String;
    Largo: number;
    Ancho: number;
    Alto: number;
    Activo: boolean;
    IdEntidad: number;
    IdTipoEmbalaje: number;
}
