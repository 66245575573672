<form [formGroup]="formAsignacionMultiple" (ngSubmit)="enviarSeleccion('enviarSeleccionMultiple');">
    <div class="row">
        <div class="col-sm-6">
            <mat-form-field appearance="fill">
                <mat-label>{{'LabelVehiculo' | translate}}</mat-label>
                <mat-select formControlName="vehiculoSelected">
                    <mat-option [value]="0">{{'LabelVehiculoSelected' | translate}}</mat-option>
                    <mat-option *ngFor="let v of vehiculos" [value]="v.IdVehiculo">
                        {{v.Matricula + ' ' + v.Tipo}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="submitted && vehiculoSelected.hasError('required')">
                    {{'ErrorVehiculoSelected' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-6">
            <mat-form-field appearance="fill">
                <mat-label>{{'LabelConductor' | translate}}</mat-label>
                <mat-select formControlName="conductorSelected">
                    <mat-option [value]="0">{{'LabelConductorSelected' | translate}}</mat-option>
                    <mat-option *ngFor="let c of conductores" [value]="c.IdConductor">
                        {{c.Nombre + ' ' + c.Apellidos}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="submitted && conductorSelected.hasError('required')">
                    {{'ErrorConductorSelected' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table table-hover mat-elevation-z3"
                style="margin-top: 5%;">
                <!-- Referencia Column -->
                <ng-container matColumnDef="referencia">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'Referencia'|translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" (click)="columSelected(element);"
                        [class.fondo-negro]="isFondoNegro(element)">
                        {{element.IdSerieReferencia}} </td>
                </ng-container>

                <!-- Expedicion Column -->
                <ng-container matColumnDef="expedicion">
                    <th mat-header-cell *matHeaderCellDef> {{'Expedicion'|translate}} </th>
                    <td mat-cell *matCellDef="let element" (click)="columSelected(element);"
                        [class.fondo-negro]="isFondoNegro(element)">
                        {{element.Expedicion}} </td>
                </ng-container>

                <!-- Fecha Column -->
                <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef> {{'Fecha'|translate}} </th>
                    <td mat-cell *matCellDef="let element" (click)="columSelected(element);"
                        [class.fondo-negro]="isFondoNegro(element)">
                        {{element.FechaSalida}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getColumnas()"></tr>
                <tr mat-row *matRowDef="let element; columns: getColumnas();" class="example-element-row">
                </tr>
                <!--<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-error *ngIf="expedicionesSelected.hasError('arrayValidator') && submitted">
                    {{ 'ErrorArrayValidator' | translate }}
                </mat-error>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <button mat-button class="margenDerecha" type="submit" id="enviarSeleccionMultiple">{{'Seleccionar' | translate}}</button>
        </div>
        <div class="col-4">
            <button mat-button class="margenDerecha" type="button"
                (click)="clear();">{{'Limpiar' | translate }}</button>
        </div>
        <div class="col-4">
            <button mat-button type="button buttonCancelar" (click)="cerrar();">{{'Cancelar' | translate }}</button>
        </div>
    </div>
</form>