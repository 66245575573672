import { ContenedorMercancia } from "./contenedorMercancia.model";
import { localidadGoogleMaps } from "./localidadGoogleMaps.model";
import { Mercancia } from "./mercancia.model";
import { TarifaEnvio } from "./tarifaEnvio";
import { TarifaPost } from "./tarifaPost";

export class CotizacionEnvio {
    Aerolinea: string;
    Bultos: number;
    FechaReadyForShipment: string;
    IdAeropuertoDestino: number;
    IdAeropuertoOrigen: number;
    IdArea: number;
    IdCliente: number;
    Cliente: string;
    IdModoEnvio: number;
    IdCotizacion: number;
    IdLocalidadDestino: number;
    IdLocalidadOrigen: number;
    LocalidadOrigen: localidadGoogleMaps;
    LocalidadDestino: localidadGoogleMaps;
    AeropuertoOrigen: localidadGoogleMaps;
    AeropuertoDestino: localidadGoogleMaps;
    PuertoOrigen: localidadGoogleMaps;
    PuertoDestino: localidadGoogleMaps;
    IdPuertoDestino: number;
    IdPuertoOrigen: number;
    IdTipoEnvio: number;
    Incoterm: string;
    Mercancia: Mercancia[];
    MercanciaContenedor: ContenedorMercancia[];
    MercanciaPeligrosa: boolean;
    Naviera: string;
    NoRemontable: boolean;
    Observaciones: string;
    PesoBrutoTotal: number;
    PesoBrutoTotalDGR: number;
    PesoNetoTotal: number;
    PesoNetoTotalDGR: number;
    PesoTasable: number;
    ReferenciaCliente: string;
    VolumenTotal: number;
    PesoBrutoTotalDGRContenedor: number;
    PesoNetoTotalDGRContenedor: number;
    PesoBrutoTotalContenedor: number;
    MercanciaPeligrosaContenedor: boolean;
    BultosContenedor: number;
    ValorMercancia: number;
    Asegurado: boolean;
    VGMTransmision: boolean;
    VGMPesaje: boolean;
    Tarifa: TarifaPost;
}
