import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Archivo } from 'src/app/models/archivo.model';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';

@Component({
  selector: 'app-modal-download',
  templateUrl: './modal-download.component.html',
  styleUrls: ['./modal-download.component.scss']
})
export class ModalDownloadComponent implements OnInit {

  //lista: Archivo[] = []
  itemWeb: number = 6
  itemMovil: number = 2
  filasMovil: number 
  filasWeb: number 
  isMovil: boolean = false
  @Input() lista: Archivo[];
  base64: string;

  constructor(private deviceService: DeviceDetectorService, private expeditionService: ExpeditionApiService) {
    
   }

  ngOnInit(): void {
    this.isMovil = this.deviceService.isMobile()
  }

  ngOnChanges(){
    if(this.lista){
      this.filasMovil = Math.round(this.lista.length/this.itemMovil)
      this.filasWeb = Math.trunc(this.lista.length/this.itemWeb)+1
    }
  }

  // Para montar bien las líneas del html
  Array(i: number){
    var foo = new Array(i)
    for(var i = 0; i < foo.length; i++){
      foo[i] = i
    }
    return foo
  }

  // Para montar bien las líneas bien del html
  ArrayFilas(){
    if(this.isMovil){
      return this.Array(this.filasMovil)
    }
    return this.Array(this.filasWeb)
  }

  // Para montar bien las líneas bien del html
  ArrayElementos(filaAhora: number){ // 2 movil 6 web
    var foo, numero
    if(this.isMovil){
      foo = new Array(this.itemMovil)
      numero = this.itemMovil*filaAhora; 
    }else{
      foo = new Array(this.itemWeb)
      numero = this.itemWeb*filaAhora; 
    }
    for(var i = 0; i < foo.length; i++){
      foo[i] = numero
      numero++
    }
    return foo
  }

}
