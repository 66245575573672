<div id="container" class="container">
    <div class="row">
        <div class="col">
            <mat-radio-group [(ngModel)]="opcion">
                <div class="row mb2">
                    <div class="col-1 mauto">
                        <mat-radio-button [disabled]="visor" class="mb0" [value]="1"></mat-radio-button>
                    </div>
                    <div class="col-2 pr0">
                        <input class="form-control" id="gradosUnico" (change)="cambioGrados($event)" [disabled]="opcion != 1 || visor" type="number" [(ngModel)]="grados"> 
                    </div>
                    <div class="col-1 mauto">
                        <p class="mb0" >ºC</p>
                    </div>
                    <div class="col-8"></div>
                </div>
                <div class="row">
                    <div class="col-1 mauto"><mat-radio-button class="mb0" [disabled]="visor" [value]="2"></mat-radio-button></div>
                    <div class="col-1 pr0 mauto"><p class="mb0">{{'De' | translate}}</p></div> <!--from-->
                    <div class="col-2 pl0 pr0">
                        <input class="form-control" [disabled]="opcion != 2 || visor" id="gradosMinimos" [(ngModel)]="gradosMin" type="number">
                    </div>
                    <div class="col-1 pr0 mauto"><p class="mb0 ml20">{{'a' | translate}}</p></div> <!--to-->
                    <div class="col-2 pl0 pr0">
                        <input class="form-control" [disabled]="opcion != 2 || visor" [(ngModel)]="gradosMax" type="number">
                    </div>
                    <div class="col-1 mauto"><p class="mb0">ºC</p></div>
                    <div class="col-4"></div>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div class="row"></div>
    <div class="row">
        <div class="col-sm-8"></div>
        <div class="col-sm-2">
            <button style="float: right;margin:10px" type="button" class="btn btn-block" (click)="cerrar(false)" [disabled]="visor || isCotizacion">
                {{'Aceptar' | translate}}
            </button>
        </div>
        <div class="col-sm-2">
            <button style="float: right;margin:10px;" type="button" class="btn btn-block" (click)="cerrar(true)">
                {{'Cerrar' | translate}}
            </button>
        </div>
    </div>
</div>