<ng-container class="mostaza custom-table mat-table">
    <mat-panel>
        <mat-panel-header>
            <h3 class="mt2" style="font-weight: bold;">{{tarifaSeleccionada.Nombre}}</h3>
            <mat-panel-title>

            </mat-panel-title>
        </mat-panel-header>
        <mat-table [dataSource]="tarifaSeleccionada.ListaLineas" class="mat-elevation-z8 demo-table">
            <ng-container matColumnDef="Concepto">
                <mat-header-cell *matHeaderCellDef class="pl20px adicional-header">{{ "Concepto" | translate
                    }}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="{ 'fila-oculta': element.Adicional == false }">{{
                    element.Concepto }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Gasto">
                <mat-header-cell class="ml13 adicional-header" *matHeaderCellDef>{{ "Importe" | translate
                    }}</mat-header-cell>
                <mat-cell class="pl15" *matCellDef="let element"
                    [ngClass]="{ 'fila-oculta': element.Adicional == false }">{{ element.Gasto }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Divisa">
                <mat-header-cell class="adicional-header" *matHeaderCellDef>{{ "Divisa" | translate
                    }}</mat-header-cell>
                <mat-cell class="ml1" *matCellDef="let element"
                    [ngClass]="{ 'fila-oculta': element.Adicional == false }">{{ element.Divisa }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['Concepto', 'Gasto', 'Divisa']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['Concepto', 'Gasto', 'Divisa']"
                [ngClass]="{ 'hide-row': row.Adicional === false }"></mat-row>
        </mat-table>
        <mat-divider></mat-divider>
        <mat-table [dataSource]="tarifaSeleccionada.TotalesTarifa" class="mat-elevation-z8 demo-table">
            <ng-container matColumnDef="Concepto">
                <mat-cell class="negrita" *matCellDef="let element">{{ element.Concepto}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Gasto">
                <mat-cell class="negrita pl15" *matCellDef="let element">{{ element.Gasto
                    }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="Divisa">
                <mat-cell class="negrita ml1" *matCellDef="let element">{{ element.Divisa
                    }}</mat-cell>
            </ng-container>
            <mat-row *matRowDef="let row; columns: ['Concepto', 'Gasto', 'Divisa']"></mat-row>
        </mat-table>
        <h3 class="mt2" style="font-weight: bold;" *ngIf="tarifaSeleccionada.Condiciones!=null">
            <input type="checkbox" [(ngModel)]="aceptaCondiciones" class="checkbox-input">
            <span class="checkbox-label">{{ tarifaSeleccionada.Condiciones }}</span>
        </h3>
        <h2 class="mt2" style="font-weight: bold;">
            <span>{{'mensajePruebasTarifa' | translate}}</span>
        </h2>

    </mat-panel>

    <br>
</ng-container>

<div id="undecimobloque" class="row">
    <div class="col-sm-8 col-12"> </div>

    <div class="col-sm-2 col-12 margen-top">
        <button type="button" class="btn buttonCancelar btn-block"
            (click)="cancelacion()">{{'Cancelar'|translate}}</button>
    </div>

    <div class="col-sm-2 col-12 margen-top">
        <button type="button" id="enviarShipment" (click)="ObtenerTarifaFiltrada(); setRow();"
            class="btn btn-block">{{'Aceptar
            y Realizar
            Booking'|translate}}</button>
    </div>
</div>