import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tarifa-terrestre',
  templateUrl: './tarifa-terrestre.component.html',
  styleUrls: ['./tarifa-terrestre.component.scss']
})
export class TarifaTerrestreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
