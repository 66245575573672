import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Codigosun } from 'src/app/models/codigosun.model';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';
import { MercanciaService } from 'src/app/services/mercancia.service';


@Component({
  selector: 'app-mercancia-peligrosa',
  templateUrl: './mercancia-peligrosa.component.html',
  styleUrls: ['./mercancia-peligrosa.component.scss']
})
export class MercanciaPeligrosaComponent implements OnInit {

  @Input() pesoNeto: number;
  @Input() pesoBruto: number;
  @Input() pesoB: number[];
  @Input() pesoN: number[];
  @Input() visor: boolean;
  @Input() area: number;
  @Input() isCotizacion: boolean;
  @Input() tarifa: Boolean;
  @Input() booking: Boolean;
  @Input() esTarifa: Boolean;
  @Input() esRellenoPn: Boolean;
  @Input() esRellenoUn: Boolean;

  errorUn: boolean[] = [];
  errorNeto: boolean[] = [];
  errorBruto: boolean[] = [];
  @Input() codigosUn: Codigosun[] = [];
  bultos: number;
  pnTotal: number;
  pbTotal: number;
  @Output() emitPNTotal = new EventEmitter<number>();
  @Output() emitPBTotal = new EventEmitter<number>();
  @Output() emitMP = new EventEmitter<MercanciaPeligrosa[]>();
  @Input() mp: MercanciaPeligrosa[];
  indiceEdit: number;
  modalRef: BsModalRef | null;
  primeraVez: boolean = true;
  listPackingGroup: string[] = ['|', '||', '|||'];


  constructor(private toastr: ToastrService,
    private modalService: BsModalService, public translateService: TranslateService) { }

  ngOnInit(): void {
    window.setTimeout(function () {
      document.getElementById('NumUn').focus();
    }, 0);
    this.pnTotal = 0;
    this.pbTotal = 0;
    if (this.mp.length == 0 || this.mp == undefined) {
      this.mp = [];
      this.addRow();
    }

    //this.obtenerCodigosUN();
    this.comprobarUN();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.mp != undefined && this.mp != null && this.mp.length == 0) || this.mp == undefined) {
      this.mp = [];
      this.addRow();
    }
  }

  comprobarUN() {
    var linea = 0;
    this.mp.forEach(merpel => {
      var codigoun = this.codigosUn.find(cun => cun.codigoUN.toString() == merpel.Un);
      if (codigoun == undefined || codigoun == null)
        this.errorUn[linea] = true;
      else
        this.errorUn[linea] = false;
      linea++;
    });
  }

  // comprobarUN(un: string){

  // }

  setUn(un: string, i: number) {
    if (un != "" && un != undefined && un != null) {
      this.mp[i].Un = un;
      var comprobarUN = this.codigosUn.find(cu => cu.codigoUN.toString() == un);
      if (comprobarUN == undefined || comprobarUN == null) {
        this.errorUn[i] = true;
        var bodyError: string = this.translateService.instant("BodyErrorCodigoUnNotExist") + (i + 1);
        var titleError: string = this.translateService.instant("TitleErrorCodigoUnNotExist") + (i + 1);
        this.toastr.error(bodyError, titleError);
      }
      else
        this.errorUn[i] = false;
    } else {
      this.mp[i].Un = "";
      this.errorUn[i] = true;
    }
  }

  setPn(pn: number, i: number) {
    if (pn != null && pn != undefined && pn.toString() != "") {
      this.mp[i].PesoNeto = Number(pn.toString().replace(',', '.'));
      this.pnTotal = Number(this.pnTotal) + Number(pn.toString().replace(',', '.'));
      this.errorNeto[i] = false;
    } else
      this.errorNeto[i] = true;
  }

  setPb(pb: number, i: number) {
    if (pb != null && pb != undefined && pb.toString() != "") {
      this.mp[i].PesoBruto = Number(pb.toString().replace(',', '.'));
      this.pbTotal = Number(this.pbTotal) + Number(pb.toString().replace(',', '.'));

    } else {
      this.mp[i].PesoBruto = 0;
      this.pbTotal = Number(this.pbTotal) + Number(pb.toString().replace(',', '.'));
    }
    this.errorBruto[i] = false;
  }

  setClase(clase: number, i: number) {
    if (clase != null && clase != undefined && clase.toString() != "") {
      this.mp[i].Clase = clase.toString();
    }
  }

  setPg(pg: number, i: number) {
    if (pg != null && pg != undefined && pg.toString() != "") {
      this.mp[i].Pg = pg.toString();
    }
  }

  openAlert(template: TemplateRef<any>, i: number) {
    this.indiceEdit = i;
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 2
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

  isExistErrorBruto() {
    return this.errorBruto.findIndex(e => e) != -1;
  }

  isExistErrorNeto() {
    return this.errorNeto.findIndex(e => e) != -1;
  }

  isExistErrorUn() {
    return this.errorUn.findIndex(e => e) != -1;
  }

  //Funcion que añade una linea nueva
  addRow() {
    if (this.area == 1 && (this.isExistErrorNeto() || this.isExistErrorUn()))
      this.toastr.error(this.translateService.instant("ErrorBodyNewRow"), this.translateService.instant("ErrorTitleNewRow"));
    else {
      let mp: MercanciaPeligrosa = new MercanciaPeligrosa();
      this.mp.push(mp);
      this.errorNeto.push(true);
      this.errorUn.push(false);
    }
  }

  enviarUn() {
    if (this.area == 1 && (this.isExistErrorNeto() || this.isExistErrorUn())) { // Terrestre y no se puede enviar
      this.toastr.error(this.translateService.instant("ErrorBodyNewRow"), this.translateService.instant("ErrorTitleNewRow"));
    } else { // Se puede enviar en cualquier área
      this.emitMP.emit(this.mp);
      this.emitPNTotal.emit(this.pnTotal);
      this.emitPBTotal.emit(this.pbTotal);
    }
  }

  cerrar() {
    this.emitMP.emit(null);
    this.emitPNTotal.emit(null);
    this.emitPBTotal.emit(null);
  }

  eliminarRow(i: number) {
    this.mp.splice(i, 1);
    this.errorBruto.splice(i, 1);
    this.errorNeto.splice(i, 1);
    this.errorUn.splice(i, 1);
  }

  obtenerConfirmacion(confirmacion: boolean) {
    if (confirmacion == true)
      this.eliminarRow(this.indiceEdit);
    this.modalRef.hide();
  }
}
