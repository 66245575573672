import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { IncidenciasService } from 'src/app/services/incidencias.service';
import { Archivo } from 'src/app/models/archivo.model';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-galeria-imagenes',
  templateUrl: './galeria-imagenes.component.html',
  styleUrls: ['./galeria-imagenes.component.scss']
})
export class GaleriaImagenesComponent implements OnInit {

  listaImagenes: String[];
  listaArchivos: Archivo[] = [];
  @Input() idIncidencia: number;
  @Input() photos: String[];
  index: number;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  imageAux: NgxGalleryImage[];
  
  constructor(private incidenciasServices: IncidenciasService) { }

  ngOnInit(): void {
    this.index = 0;
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    
    if(this.idIncidencia != undefined && this.idIncidencia != 0)
    {
      this.incidenciasServices.obtenerArchivos(this.idIncidencia).subscribe(data => {
        this.listaArchivos = data;
      })
    }else{
      this.imageAux = [];
      this.listaArchivos.forEach(element => {
        this.imageAux.push({
          'small': 'data:image/jpg;base64,' + element.Base64image.toString(),
          'medium': 'data:image/jpg;base64,' + element.Base64image.toString(),
          'big': 'data:image/jpg;base64,' + element.Base64image.toString()
        })
      })
      this.photos.forEach(element => {
        this.imageAux.push({
          'small': element,
          'medium': element,
          'big': element
        });
      })
      this.galleryImages = this.imageAux;
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(this.idIncidencia != undefined && this.idIncidencia != 0)
    {
      this.incidenciasServices.obtenerArchivos(this.idIncidencia).subscribe(data => {
          this.listaArchivos = data;
          this.imageAux = [];
          if(this.listaArchivos != undefined)
          {
            this.listaArchivos.forEach(element => {
              this.imageAux.push({
                'small': 'data:image/jpg;base64,' + element.Base64image.toString(),
                'medium': 'data:image/jpg;base64,' + element.Base64image.toString(),
                'big': 'data:image/jpg;base64,' + element.Base64image.toString()
              })
            })
          }

        if(this.photos != undefined)
        {
          this.photos.forEach(element => {
            this.imageAux.push({
              'small': element,
              'medium': element,
              'big': element
            });
          })
        }
        
        this.galleryImages = this.imageAux;
      })
    }else{
      this.imageAux = [];
      this.listaArchivos.forEach(element => {
        this.imageAux.push({
          'small': 'data:image/jpg;base64,' + element.Base64image.toString(),
          'medium': 'data:image/jpg;base64,' + element.Base64image.toString(),
          'big': 'data:image/jpg;base64,' + element.Base64image.toString()
        })
      })
      this.photos.forEach(element => {
        this.imageAux.push({
          'small': element,
          'medium': element,
          'big': element
        });
      })
      this.galleryImages = this.imageAux;
    }
  }

  resta(){
    this.index--;
  }

  getFoto(): String {
    if(this.photos != undefined)
    {
      return this.photos[this.index];
    }
    else
    {
      return "";
    }
  }

  suma() {
    this.index++;
  }

  //Funcion que une las imagenes solicitadas de la bbdd con las nuevas añadidas recientemente pero que aun no están en la bbdd
  unirSeleccionImagenes(): String[] {
    this.photos.forEach(element => {
      this.listaImagenes.push(element);
    });
    
    this.listaArchivos.forEach(element => {
      this.listaImagenes.push(element.Base64image.toString());
    });

    return this.listaImagenes;
  }

}
