<div class="container">
    <div id="General" class="contenedor">
        <h5>{{'General'|translate}}</h5>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Tipo Envio' | translate}}</mat-label>
                    <input matInput name="tipoEnvio" [(ngModel)]="tipoEnvio" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3" *ngIf="modoEnvio != null && modoEnvio != undefined && modoEnvio != ''">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Modo Envio' | translate}}</mat-label>
                    <input matInput name="modoEnvio" [(ngModel)]="modoEnvio" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Cliente' | translate}}</mat-label>
                    <input matInput name="cliente" [(ngModel)]="cliente" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Ref. Cliente' | translate }}</mat-label>
                    <input matInput name="referenciaCliente" [(ngModel)]="resumen.RefCliente" readonly>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Remitente' | translate}}</mat-label>
                    <input matInput name="remitente" [(ngModel)]="shipper" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'DireccionRemitente' | translate}}</mat-label>
                    <textarea matInput name="dirShipper" [(ngModel)]="direccionShipper" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Destinatario' | translate}}</mat-label>
                    <input matInput name="consignee" [(ngModel)]="consignee" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'DireccionDestinatario' | translate}}</mat-label>
                    <textarea matInput name="dirConsignee" [(ngModel)]="direccionConsignee" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Notificar' | translate}}</mat-label>
                    <input matInput name="notify" [(ngModel)]="notify" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Direccion Notificar</mat-label>
                    <textarea matInput name="dirNotify" [(ngModel)]="direccionNotify" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Incoterm'|translate}}</mat-label>
                    <input matInput name="incoterm" [(ngModel)]="resumen.Incoterm" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Valor Mercancia' | translate}}</mat-label>
                    <input matInput name="valorMercancia" [(ngModel)]="resumen.ValorMercancia" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Divisa'|translate}}</mat-label>
                    <input matInput name="tipoDivisa" [(ngModel)]="tipoDivisa" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="(this.resumen.IdArea == 1)">
                    <mat-label>{{'TipoMercancia'|translate}}</mat-label>
                    <input matInput name="tipoMercancia" [(ngModel)]="tipoMercancia" readonly>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field *ngIf="!esMexicoOPuebla()" class="example-full-width">
                    <mat-label>{{'Coste del Seguro'|translate}} €</mat-label>
                    <input matInput name="costeSeguro" [(ngModel)]="resumen.CosteSeguro" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="this.resumen.IdArea == 3">
                    <mat-label>{{'Aeropuerto Origen'|translate}}</mat-label>
                    <input matInput name="aeropuertoOrigen" [(ngModel)]="aeropuertoOrigen" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="this.resumen.IdArea == 2">
                    <mat-label>{{'Puerto Origen'|translate}}</mat-label>
                    <input matInput name="puertoOrigen" [(ngModel)]="puertoOrigen" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width" *ngIf="resumen.Asegurado && !esMexicoOPuebla()">
                    <mat-label>{{'Asegurado' | translate}}</mat-label>
                    <input matInput value="{{'Si'| translate}}" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="!resumen.Asegurado && !esMexicoOPuebla()">
                    <mat-label>{{'Asegurado' | translate}}</mat-label>
                    <input matInput value="{{'No'| translate}}" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="this.resumen.IdArea == 3">
                    <mat-label>{{'Aeropuerto Destino'|translate}}</mat-label>
                    <input matInput name="aeropuertoDestino" [(ngModel)]="aeropuertoDestino" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="this.resumen.IdArea == 2">
                    <mat-label>{{'Puerto Destino'|translate}}</mat-label>
                    <input matInput name="puertoDestino" [(ngModel)]="puertoDestino" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <!-- Puerto y Aeropuerto con *ngIf -->
                <mat-form-field class="example-full-width" *ngIf="this.resumen.IdArea == 3">
                    <mat-label>{{'Aerolinea'|translate}}</mat-label>
                    <input matInput name="aerolinea" [(ngModel)]="resumen.Aerolinea" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width" *ngIf="this.resumen.IdArea == 2">
                    <mat-label>{{'Naviera'|translate}}</mat-label>
                    <input matInput name="naviera" [(ngModel)]="resumen.Naviera" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
            </div>
        </div>
    </div>
    <div id="Recogida" class="contenedor">
        <h5>{{'Recogida'|translate}}</h5>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Entidad'|translate}}</mat-label>
                    <input matInput name="entidadCarga" [(ngModel)]="entidadRecogida">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Dirección Recogida'|translate}}</mat-label>
                    <textarea matInput name="dirCarga" [(ngModel)]="direccionRecogida" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Referencia de Carga' | translate}}</mat-label>
                    <input matInput name="referenciaCarga" [(ngModel)]="resumen.ReferenciaRecogida" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Instrucciones Recogida'|translate}}</mat-label>
                    <textarea matInput name="instruccionesRecogida" [(ngModel)]="resumen.InstruccionesRecogida" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Persona Contacto'|translate}}</mat-label>
                    <input matInput name="pContactoRecogida" [(ngModel)]="resumen.PersonaContactoRecogida" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Teléfono'|translate}}</mat-label>
                    <input matInput name="telefonoRecogida" [(ngModel)]="resumen.TelefonoRecogida" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Email'|translate}}</mat-label>
                    <input matInput name="emailRecogida" [(ngModel)]="resumen.EmailRecogida" readonly>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Mercancia disponible desde'|translate}}</mat-label>
                    <input matInput name="fechaRecogida" [(ngModel)]="resumen.FechaRecogida" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Desde'|translate}}</mat-label>
                    <input matInput name="desde1" [(ngModel)]="resumen.PrimeraHoraRecogidaInicio" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Hasta'|translate}}</mat-label>
                    <input matInput name="hasta1" [(ngModel)]="resumen.PrimeraHoraRecogidaFinal" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Desde'|translate}}</mat-label>
                    <input matInput name="desde2" [(ngModel)]="resumen.SegundaHoraRecogidaInicio" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Hasta'|translate}}</mat-label>
                    <input matInput name="hasta2" [(ngModel)]="resumen.SegundaHoraRecogidaFinal" readonly>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div id="DespachoAduanas" class="contenedor" *ngIf="resumen.DespachoOrigen != '' && resumen.DespachoOrigen != null && resumen.DespachoOrigen != undefined">
        <h5>{{'DespachoAduanas'|translate}}</h5>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'DespachoOrigen'|translate}}</mat-label>
                    <input matInput name="despachoOrigen" [(ngModel)]="resumen.DespachoOrigen" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3" *ngIf="resumen.DespachoDestino != null && resumen.DespachoDestino != '' && resumen.DespachoDestino != undefined">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'DespachoDestino'|translate}}</mat-label>
                    <input matInput name="despachoDestino" [(ngModel)]="resumen.DespachoDestino" readonly>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div id="Entrega" class="contenedor" *ngIf="resumen.Incoterm == 'DAP' || resumen.Incoterm == 'DDP' || resumen.Incoterm == 'DPU'">
        <h5>{{'Entrega'|translate}}</h5>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Entidad'|translate}}</mat-label>
                    <input matInput name="entidadEntrega" [(ngModel)]="entidadEntrega" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Dirección Entrega'|translate}}</mat-label>
                    <textarea matInput name="dirEntrega" [(ngModel)]="direccionEntrega" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Referencia Entrega'|translate}}</mat-label>
                    <input matInput name="referenciaEntrega" [(ngModel)]="resumen.ReferenciaEntrega" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Instrucciones Entrega'|translate}}</mat-label>
                    <textarea matInput name="instruccionesEntrega" [(ngModel)]="resumen.InstruccionesEntrega" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Persona Contacto'|translate}}</mat-label>
                    <input matInput name="pContactoEntrega" [(ngModel)]="resumen.PersonaContactoEntrega" readonly>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Teléfono'|translate}}</mat-label>
                    <input matInput name="telefonoEntrega" [(ngModel)]="resumen.TelefonoEntrega" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Email'|translate}}</mat-label>
                    <input matInput name="emailEntrega" [(ngModel)]="resumen.EmailEntrega" readonly>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div id="Mercancia" class="contenedor">
        <h5>{{'Mercancia'|translate}}</h5>
        <div class="row">
            <div class="col-12" *ngIf="resumen.IdArea == 2 && resumen.IdModoEnvio == 1">
                <app-detalles-contenedor [contenedoresCotizacion]="mercanciasContenedorDetalles"></app-detalles-contenedor>
            </div>
            <div class="col-12" *ngIf="!(resumen.IdArea == 2 && resumen.IdModoEnvio == 1)">
                <app-detalles-mercancia [showColums]="false" [mercanciasCotizacion]="mercanciaDetalles"></app-detalles-mercancia>
            </div>
        </div>
    </div>
        <!-- <div class="row" style="text-align: center;" *ngIf="resumen.IdModoEnvio != 1">
            <div class="col-sm-12">
                <table mat-table class="mat-elevation-z0" [dataSource]="listaMercancia">
                    <ng-container matColumnDef="Bultos">  
                        <th mat-header-cell *matHeaderCellDef>{{'Bultos'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.Bultos}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="TipoEmbalaje">  
                        <th mat-header-cell *matHeaderCellDef>{{'Embalaje'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.NombreEmbalaje}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="Dimensiones">  
                        <th mat-header-cell *matHeaderCellDef>{{'Dimensiones'|translate}} (cm)</th>   
                        <td mat-cell *matCellDef="let element">{{element.Largo}}x{{element.Ancho}}x{{element.Alto}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="Neto">  
                        <th mat-header-cell *matHeaderCellDef>{{'Neto'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.PesoNeto}}Kg</td>  
                    </ng-container>
                    <ng-container matColumnDef="Bruto">  
                        <th mat-header-cell *matHeaderCellDef>{{'Bruto'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.PesoBruto}}Kg</td>  
                    </ng-container>
                    <ng-container matColumnDef="Volumen">  
                        <th mat-header-cell *matHeaderCellDef>{{'Volumen'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.Volumen}}m<sup>3</sup></td>  
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="row" style="text-align: center;" *ngIf="resumen.IdArea == 2 && resumen.IdModoEnvio == 1">
            <div class="col-sm-12">
                <table mat-table class="mat-elevation-z0" [dataSource]="listaMercancia">
                    <ng-container matColumnDef="Bultos">  
                        <th mat-header-cell *matHeaderCellDef>{{'Bultos' | translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.Bultos}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="TipoEmbalaje">  
                        <th mat-header-cell *matHeaderCellDef>{{'Embalaje'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.NombreEmbalaje}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="NombreContenedor">  
                        <th mat-header-cell *matHeaderCellDef>{{'Contenedor'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.NombreContenedor}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="Neto">  
                        <th mat-header-cell *matHeaderCellDef>{{'Neto'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.PesoNeto}}Kg</td>  
                    </ng-container>
                    <ng-container matColumnDef="Bruto">  
                        <th mat-header-cell *matHeaderCellDef>{{'Bruto'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.PesoBruto}}Kg</td>  
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsFCL"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsFCL;"></tr>
                </table>
            </div>
        </div>
        <div class="row espaciadocolsm2">
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'BultosTotales'|translate}}</mat-label>
                    <input matInput name="BultosTotales" [(ngModel)]="resumen.BultosTotales" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Neto Total'|translate}}</mat-label>
                    <input matInput name="pesoNetoTotal" [(ngModel)]="resumen.PesoNetoTotal" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Bruto Total'|translate}}</mat-label>
                    <input matInput name="pesoBrutoTotal" [(ngModel)]="resumen.PesoBrutoTotal" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Volumen Total'|translate}}</mat-label>
                    <input matInput name="Volumen" [(ngModel)]="resumen.VolumenTotal" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Peso Tasable'|translate}}</mat-label>
                    <input matInput name="pesoTasable" [(ngModel)]="resumen.PesoTasable" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-2"></div>
        </div>
    </div>
    <div id="MercanciaPeligrosa" class="contenedor" *ngIf="resumen.MercanciaPeligrosa">
        <h5>{{'Mercancia Peligrosa' | translate}}</h5>
        <div class="row">
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Neto Total'|translate}} DGR (kg)</mat-label>
                    <input matInput name="pesoNetoTotalDGR" [(ngModel)]="resumen.PesoNetoTotalDGR" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Bruto Total'|translate}} DGR (kg)</mat-label>
                    <input matInput name="pesoBrutoTotalDGR" [(ngModel)]="resumen.PesoBrutoTotalDGR" readonly>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <table mat-table class="mat-elevation-z0" [dataSource]="listaMercanciaPeligrosa">
                    <ng-container matColumnDef="UN">  
                        <th mat-header-cell *matHeaderCellDef>UN</th>   
                        <td mat-cell *matCellDef="let element">{{element.Un}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="PesoNeto">  
                        <th mat-header-cell *matHeaderCellDef>{{'PesoNeto'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.PesoNeto}}</td>  
                    </ng-container>
                    <ng-container matColumnDef="PesoBruto">  
                        <th mat-header-cell *matHeaderCellDef>{{'PesoBruto'|translate}}</th>   
                        <td mat-cell *matCellDef="let element">{{element.PesoBruto}}</td>  
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMP"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMP;"></tr>
                </table>
            </div>
        </div>
    </div> -->
    <div id="InfoAdicional" class="contenedor">
        <h5>{{'Informacion Adicional' | translate}}</h5>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="example-full-width" *ngIf="(this.resumen.IdArea != 1)">
                    <mat-label>{{'InstruccionesBLAWB'|translate}}</mat-label>
                    <textarea matInput name="InstruccionesBLAWB" [(ngModel)]="resumen.InstruccionesBLAWB" readonly></textarea>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="example-full-width" *ngIf="(this.resumen.IdArea != 1)">
                    <mat-label>{{'Marcas'|translate}}</mat-label>
                    <textarea matInput name="Marcas" [(ngModel)]="resumen.Marcas" readonly></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row espaciadocolsm12">
            <div class="col-sm-12">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Observaciones'|translate}}</mat-label>
                    <textarea matInput name="Instrucciones" [(ngModel)]="resumen.Instrucciones" readonly></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>