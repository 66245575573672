import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Vehiculo } from 'src/app/models/vehiculo.model';
import { ConductorService } from 'src/app/services/conductor.service';
import { VehiculoService } from 'src/app/services/vehiculo.service';

@Component({
  selector: 'app-lista-vehiculos',
  templateUrl: './lista-vehiculos.component.html',
  styleUrls: ['./lista-vehiculos.component.scss']
})
export class ListaVehiculosComponent implements OnInit {

  clase: string = "app-asignar-conductor";
  vehiculos;
  vehiculo: Vehiculo;
  @Output() vehiculoSeleccionado = new EventEmitter<Vehiculo>();
  displayedColumns: string[] = ['id', 'matricula', 'tipo', 'especialidad', 'accion'];
  @Output() cerrarModal = new EventEmitter<number>();
  selection = new SelectionModel<Vehiculo>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRef: BsModalRef | null;
  itemsPorPage: number = 0;
  sizeOptions: number[] = [];
  lengthTable: number = 0;

  constructor(private vehiculoService: VehiculoService, private translate: TranslateService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.vehiculoService.getAllVehiculos();
    this.vehiculoService.getVehiculos$().subscribe(vehiculos => {
      this.vehiculos = null;
      this.vehiculos = new MatTableDataSource<Vehiculo>(vehiculos);
      
      this.vehiculos.paginator = null;
      this.vehiculos.paginator = this.paginator;
      this.lengthTable = vehiculos.length;
      this.vehiculos.paginator = this.paginator;
      for(var i = 1; i <= (this.lengthTable/10)+1; i++){
          this.sizeOptions = [...this.sizeOptions,i*10];
          this.itemsPorPage = i*10;
      }
    });
  }

  ngOnChanges(changes: SimpleChange){
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.vehiculos.filter = filterValue.trim().toLowerCase();
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      class: 'modal-sm',
      ignoreBackdropClick: true,
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cerrar(evento){
    this.modalRef.hide();
  }

  setDelete(element: Vehiculo){
    this.vehiculo = element;
  }

  setRow(row: Vehiculo){
    this.vehiculoService.setVehiculo$(row);
  }

  cancelar(){
    this.cerrarModal.emit(1);
  }

}
