import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Archivo } from 'src/app/models/archivo.model';
import { Shipment } from 'src/app/models/shipment.model';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {

  @Output() sendFiles = new EventEmitter<Archivo[]>();
  @Input() documentacion: Boolean;
  @Input() envio: Shipment;
  listFiles: Archivo[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    
  }

  receiveFiles(lista) {
    this.listFiles = lista;
    this.sendFiles.emit(this.listFiles);
    this.listFiles = [];
  }

}
