<div class="container">
    <form [formGroup]="formDireccion">
        <div class="row" *ngIf="!isEntity()">
            <div class="col-sm-3">
                <label>{{'Entidad'|translate}}</label>
                <ng-select name="Entidad" id="Entidad" class="select-small quitarMarginBot" 
                [items]="listaEntidades" bindLabel="NombreFiscal" bindValue="IdEntidad"
                style="margin-bottom: 20px;" [formControl]="IdEntidad"></ng-select>
                <mat-error *ngIf="IdEntidad.hasError('required') && (IdEntidad.touched || submitted)">{{'Es requerido'| translate}}</mat-error>
            </div>
            <div class="col-sm-9">
                <label>Direccion</label>
                <input type="text" class="form-control" name="Direccion" id="Direccion" [formControl]="Direccion">
                <mat-error *ngIf="Direccion.hasError('required') && (Direccion.touched || submitted)">{{'Es requerido'| translate}}</mat-error>
            </div>
        </div>
        <div class="row" *ngIf="isEntity()">
            <!--<div class="col-sm-3">
                <label>{{'Entidad'|translate}}</label>
                <ng-select name="Entidad" id="Entidad" class="select-small quitarMarginBot" 
                [items]="listaEntidades" bindLabel="NombreFiscal" bindValue="IdEntidad"
                style="margin-bottom: 20px;" [formControl]="IdEntidad"></ng-select>
                <mat-error *ngIf="IdEntidad.hasError('required') && (IdEntidad.touched || submitted)">{{'Es requerido'| translate}}</mat-error>
            </div>-->
            <div class="col-sm-12">
                <label>Direccion</label>
                <input type="text" class="form-control" name="Direccion" id="Direccion" [formControl]="Direccion">
                <mat-error *ngIf="Direccion.hasError('required') && (Direccion.touched || submitted)">{{'Es requerido'| translate}}</mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>{{'Pais'|translate}}</label>
                <ng-select name="Pais" id="Pais" class="select-small quitarMarginBot" [items]="listaPaises"
                bindLabel="Nombre" bindValue="IdPais" style="margin-bottom: 20px;"
                [formControl]="Pais"></ng-select>
                <mat-error *ngIf="Pais.hasError('required') && (Pais.touched || submitted)">{{'Es requerido'| translate}}</mat-error>
            </div>
            <div class="col-sm-3">
                <label>{{'Ciudad'|translate}}</label>
                <input name="Ciudad" title="Ciudad" id="Ciudad" type="text" autocomplete="off" class="form-control"
                [formControl]="Ciudad">
            </div>
            <div class="col-sm-3">
                <label>{{'Código Postal'|translate}}</label>
                <input name="Ciudad" title="Ciudad" id="Ciudad" type="text" autocomplete="off" class="form-control"
                [formControl]="CodigoPostal">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8"></div>
            <div class="col-sm-2">
                <button style="float: right;" type="submit" class="btn" (click)="anyadirDireccion()">{{'Añadir'|translate}}</button>
            </div>
            <div class="col-sm-2">
                <button type="button" style="float: right;" (click)="cerrarModal()" class="btn">{{'Cerrar'|translate}}</button>
            </div>
        </div>
    </form>
</div>
