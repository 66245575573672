import { DatePipe } from '@angular/common';
import { Component, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { ListadoCotizacion } from 'src/app/models/listadoCotizacion.model';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-list-cotizacion',
  templateUrl: './list-cotizacion.component.html',
  providers: [{provide: LOCALE_ID, useValue: 'es-ES'}, DatePipe],
  styleUrls: ['./list-cotizacion.component.scss']
})
export class ListCotizacionComponent implements OnInit {

  term;
  listadoCotizacionesActivas: ListadoCotizacion[];
  listadoCotizacionesCaducadas: ListadoCotizacion[];
  listadoCotizacionesActivasAereo: ListadoCotizacion[];
  listadoCotizacionesCaducadasAereo: ListadoCotizacion[];
  listadoCotizacionesActivasTerrestre: ListadoCotizacion[];
  listadoCotizacionesCaducadasTerrestre: ListadoCotizacion[];
  listadoCotizacionesActivasMaritimoFCL: ListadoCotizacion[];
  listadoCotizacionesCaducadasMaritimoFCL: ListadoCotizacion[];
  listadoCotizacionesActivasMaritimoLCL: ListadoCotizacion[];
  listadoCotizacionesCaducadasMaritimoLCL: ListadoCotizacion[];
  areaSelected: number = 0;
  modoEnvioSelected: number = 0;
  validezSelected: number = 0;
  // caducadasTodasSelected: number = true;
  // activasAereoSelected: number = false;
  // caducadasAereoSelected: number = false;
  // activasMaritimoFCLSelected: number = false;
  // caducadasMaritimoFCLSelected: number = false;
  // activasMaritimoLCLSelected: number = false;
  // caducadasMaritimoLCLSelected: number = false;
  // activasTerrestreSelected: number = false;
  // caducadasTerrestreSelected: number = false;
  @ViewChild('todos', {read: MatTabGroup}) todosGroup: MatTabGroup;
  @ViewChild('aereo', {read: MatTabGroup}) aereoGroup: MatTabGroup;
  @ViewChild('maritimo', {read: MatTabGroup}) maritimoGroup: MatTabGroup;
  @ViewChild('terrestre', {read: MatTabGroup}) terrestreGroup: MatTabGroup;
  @ViewChild('fcl', {read: MatTabGroup}) fclGroup: MatTabGroup;
  @ViewChild('lcl', {read: MatTabGroup}) lclGroup: MatTabGroup;
  groupsArea: MatTabGroup[] = [];
  groupsModo: MatTabGroup[] = [];
  referencia: string = "";
  pipe = new DatePipe("es-ES");
  reverse: boolean = false;
  pages : number;
  cotizacion: Cotizacion = null;
  modalRef: BsModalRef | null;
  @ViewChild('detailTemplate', {read: TemplateRef}) detallesCotizacion: TemplateRef<any>;

  getTitulo(){
    if(this.areaSelected == 1)
      return "TituloListadoCotizacionAereo";
    else if(this.areaSelected == 2)
      return "TituloListadoCotizacionMaritimo";
    else if(this.areaSelected == 3)
      return "TituloListadoCotizacionTerrestre";
  }

  constructor(private modalService: BsModalService, private cotizacionService: CotizacionService, private route: ActivatedRoute, private router: Router,
    private toastr: ToastrService, private translateService: TranslateService, public dialog: MatDialog, private envioService: ShipmentService) { }

  ngOnInit(): void {
    // FALTA GET ID DE LA URL
    // this.areaSelected = this.cotizacionService.posicionArea;
    this.areaSelected = Number(this.route.snapshot.paramMap.get("idArea"));
    if(this.areaSelected == null || this.areaSelected == undefined || this.areaSelected == 0)
      this.areaSelected = 1;
    this.modoEnvioSelected = this.cotizacionService.posicionModoEnvio;
    // setInterval(function(){
    //   this.inicializar();
    // }, 5000);
    this.inicializar();
  }

  inicializar(){
    var isCaducadasLoad = false, isValidasLoad = false;
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(_ => {});
    if(this.isAereo())
    {
      this.cotizacionService.getCotizacionesCaducadasAereo$().subscribe(listaCotizaciones => {
        isCaducadasLoad = true;
        if(isValidasLoad)
          dialogRef.close();
        this.listadoCotizacionesCaducadasAereo = listaCotizaciones;
        isCaducadasLoad = true;
      }, _ => {
        dialogRef.close();
        this.toastr.error("ERROR", "ERROR");
      });
      
      this.cotizacionService.getCotizacionesValidasAereo$().subscribe(listaCotizaciones => {
        isValidasLoad = true;
        if(isCaducadasLoad)
          dialogRef.close();
        this.listadoCotizacionesActivasAereo = listaCotizaciones;
        isValidasLoad = true;
      }, _ => {
        dialogRef.close();
        this.toastr.error("ERROR", "ERROR");
      });
      this.cotizacionService.getCotizacionesCaducadas('2');
      this.cotizacionService.getCotizacionesActivas('2');
    }else if(this.isMaritimo()){
      this.cotizacionService.getCotizacionesCaducadasMaritimo$().subscribe(listaCotizaciones => {
        isCaducadasLoad = true;
        if(isValidasLoad)
          dialogRef.close();
        this.listadoCotizacionesCaducadasMaritimoFCL = listaCotizaciones.filter(lc => lc.modoEnvio == "FCL");
        this.listadoCotizacionesCaducadasMaritimoLCL = listaCotizaciones.filter(lc => lc.modoEnvio == "LCL");
        isCaducadasLoad = true;
      }, _ => {
        dialogRef.close();
        this.toastr.error("ERROR", "ERROR");
      });
      this.cotizacionService.getCotizacionesValidasMaritimo$().subscribe(listaCotizaciones => {
        isValidasLoad = true;
        if(isCaducadasLoad)
          dialogRef.close();
        this.listadoCotizacionesActivasMaritimoFCL = listaCotizaciones.filter(lc => lc.modoEnvio == "FCL");
        this.listadoCotizacionesActivasMaritimoLCL = listaCotizaciones.filter(lc => lc.modoEnvio == "LCL");
        isValidasLoad = true;
      }, _ => {
        dialogRef.close();
        this.toastr.error("ERROR", "ERROR");
      });
      this.cotizacionService.getCotizacionesCaducadas('1');
      this.cotizacionService.getCotizacionesActivas('1');
    }else if(this.isTerrestre())
    {
      this.cotizacionService.getCotizacionesCaducadasTerrestre$().subscribe(listaCotizaciones => {
        isValidasLoad = true;
        if(isCaducadasLoad)
          dialogRef.close();
        this.listadoCotizacionesCaducadasTerrestre = listaCotizaciones;
        isCaducadasLoad = true;
      }, _ => {
        dialogRef.close();
        this.toastr.error("ERROR", "ERROR");
      });
      this.cotizacionService.getCotizacionesValidasTerrestre$().subscribe(listaCotizaciones => {
        isCaducadasLoad = true;
        if(isValidasLoad)
          dialogRef.close();
        this.listadoCotizacionesActivasTerrestre = listaCotizaciones;
        isValidasLoad = true;
      }, _ => {
        dialogRef.close();
        this.toastr.error("ERROR", "ERROR");
      });
      this.cotizacionService.getCotizacionesCaducadas('0');
      this.cotizacionService.getCotizacionesActivas('0');
    }
  }

  isAereo(){
    return this.areaSelected == 1;
  }

  isMaritimo(){
    return this.areaSelected == 2;
  }

  isTerrestre(){
    return this.areaSelected == 3;
  }
  
  getArea(){
    if(this.isAereo())
      return "aereo";
    else if(this.isMaritimo())
      return "maritimo";
    else if(this.isTerrestre())
      return "terrestre";
  }

  crearCotizacion(){
    this.router.navigateByUrl('/customer/cotizaciones/' + this.getArea()).then(() => {
    let url = this.router.url;

    if (url.startsWith('/customer/cotizaciones/' + this.getArea()))
      window.location.reload();
  })
  }

  cerrar(isClose){
    if(isClose){
      this.modalRef.hide();
      this.cotizacion = null;
    }
  }

  // openModal(template: TemplateRef<any>) {
  //   console.log("openModalListCotizacion")
  //   const config: ModalOptions = {
  //     id: 1,
  //     backdrop: 'static',
  //     keyboard: false,
  //     animated: true,
  //     ignoreBackdropClick: true,
  //     class: 'modal-xl',
  //     initialState: {
  //       id: 1
  //     } as Partial<Object>
  //   };
  //   this.modalRef = this.modalService.show(template, config);
  // }

  tabChangedArea(event){
    this.groupsArea = [this.todosGroup, this.aereoGroup, this.maritimoGroup, this.terrestreGroup];
    this.groupsModo = [this.fclGroup, this.lclGroup];
    this.areaSelected = event.index;
    if(this.areaSelected == 2)
    { // Es marítimo
      this.groupsArea[this.areaSelected].selectedIndex = this.modoEnvioSelected;
      this.groupsModo[this.modoEnvioSelected].selectedIndex = this.validezSelected;
      this.groupsModo[this.modoEnvioSelected].realignInkBar();
    }else{
      this.groupsArea[this.areaSelected].selectedIndex = this.validezSelected;
    }
    this.groupsArea[this.areaSelected].realignInkBar();
  }

  tabChangedModo(event){
    this.groupsModo = [this.fclGroup, this.lclGroup];
    this.modoEnvioSelected = event.index;
    this.groupsModo[this.modoEnvioSelected].selectedIndex = this.validezSelected;
  }

  tabChangedValidez(event){
    this.validezSelected = event.index;
  }

  isActivasTodos(){
    return this.validezSelected == 0 && this.areaSelected == 0;
  }

  isActivasAereo(){
    return this.validezSelected == 0 && this.areaSelected == 1;
  }

  isActivasMaritimoFCL(){
    return this.validezSelected == 0 && this.modoEnvioSelected == 0 && this.areaSelected == 2;
  }

  isActivasMaritimoLCL(){
    return this.validezSelected == 0 && this.modoEnvioSelected == 1 && this.areaSelected == 2;
  }

  isActivasTerrestre(){
    return this.validezSelected == 0 && this.areaSelected == 3;
  }

  isCaducadasTodos(){
    return this.validezSelected == 1 && this.areaSelected == 0;
  }

  isCaducadasAereo(){
    return this.validezSelected == 1 && this.areaSelected == 1;
  }

  isCaducadasMaritimoFCL(){
    return this.validezSelected == 1 && this.modoEnvioSelected == 0 && this.areaSelected == 2;
  }

  isCaducadasMaritimoLCL(){
    return this.validezSelected == 1 && this.modoEnvioSelected == 1 && this.areaSelected == 2;
  }

  isCaducadasTerrestre(){
    return this.validezSelected == 1 && this.areaSelected == 3;
  }

}
