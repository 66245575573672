import { Component, ElementRef, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { ContenedorMercancia } from 'src/app/models/contenedorMercancia.model';
import { ContenedorTarifa } from 'src/app/models/contenedorTarifa';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { Entity } from 'src/app/models/entity.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Mercancia } from 'src/app/models/mercancia.model';
import { Modoenvio } from 'src/app/models/modoenvio.model';
import { Puerto } from 'src/app/models/puerto.model';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';
import { TarifaPost } from 'src/app/models/tarifaPost';
import { Totales } from 'src/app/models/totales.model';
import { EntityService } from 'src/app/services/entity.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { TarifaService } from 'src/app/services/tarifaservice.service';
import { CargoComponent } from '../../cargo/cargo.component';
import { TarifaCargoComponent } from '../../tarifa-cargo/tarifa-cargo.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TotalTarifa } from 'src/app/models/totalTarifa';
import { Observable } from 'rxjs';
import { Naviera } from 'src/app/models/naviera.model';
import { TipoEnvio } from 'src/app/models/tipo-envio.model';
import { Incoterms } from 'src/app/models/incoterms.model';
import { Localidades } from 'src/app/models/localidades.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Configuracion } from 'src/app/models/configuracion.model';


@Component({
  selector: 'app-tarifa-maritimo',
  templateUrl: './tarifa-maritimo.component.html',
  styleUrls: ['./tarifa-maritimo.component.scss']
})
export class TarifaMaritimoComponent implements OnInit {

  @ViewChild(TarifaCargoComponent) appCargo: TarifaCargoComponent;
  @Input() mostarForm: boolean = false;


  clienteEntidad: Entity;
  formModelTarifa: FormGroup;
  listPuertoOrigen: Puerto[];
  listPuertoOrigenFiltrado: Puerto[];
  listPuertoDestino: Puerto[];
  listNavieras: Naviera[];
  listPuertoDestinoFiltrado: Puerto[];
  listaMercancia: Mercancia[] = []; //pasado por parametro a cargo
  listaMercanciaContenedor: ContenedorMercancia[] = []; //pasado por parametro a cargo
  listModoEnvio: Modoenvio[] = [];
  submit: boolean = false;
  visor: boolean;
  editar: boolean;
  duplicar: boolean;
  error2cont: boolean = false
  esFCL: boolean = false;
  hayMercanciaPeligrosaContenedor: boolean = false;
  cargaModoEnvio: boolean = true;
  cargaPuerto: boolean = true;
  cargaNaviera: boolean = true;
  texto: string = "";
  error: boolean = false;
  volumenTotalReadOnly: boolean = false;
  pesoBrutoTotalReadonly: boolean = false
  langs = ['es', 'en'];
  modalRef: BsModalRef | null;
  Tarifa: TarifaEnvio = new TarifaEnvio();
  esTarifa: boolean = true;
  listTipoTrasnsito: string[] = ['DIRECTO', 'NO DIRECTO'];
  showListTarifas: boolean = false;
  totales: ContenedorTarifa[] = [];
  totTarifa: TotalTarifa[] = [];
  idArea: number = 2;
  panelAbierto = false;
  options = ['SI', 'NO'];
  isAllAdicionalCompleted = false;
  selectedOptions: { [key: string]: boolean } = {};
  allOptionsSelected = false;
  listaTarifas: TarifaPost[];
  listTipoEnvio: TipoEnvio[] = [];
  cargaTipoEnvio: boolean = true;
  errorPesoNeto: boolean = false;
  errorPesoBruto: boolean = false;
  errorPesoNetoDGR: boolean = false;
  listIncoterm: Incoterms[] = [];
  cargaIncoterm: boolean = false;
  listLocalidades: Localidades[] = [];
  cargaLocalidades: boolean = true;
  mostrarLocalidadOrigen: boolean = true;
  mostrarTransporteACargoDe: boolean = true;
  configuracion: Configuracion;

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(private translateService: TranslateService, private toastr: ToastrService, private envioService: ShipmentService,
    private entityService: EntityService, private modalService: BsModalService, private router: Router, public dialog: MatDialog,
    private tarifaService: TarifaService, private configuracionService: ConfigurationService) {
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;

  }

  ngOnInit(): void {
    this.inicializar();
  }

  ngAfterViewInit() {
    this.inicializar();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarCotizacionTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
  }


  public get IdPuertoOrigen() { return this.formModelTarifa.get('IdPuertoOrigen'); }
  public get IdTipoEnvio() { return this.formModelTarifa.get('IdTipoEnvio'); }
  public get IdPuertoDestino() { return this.formModelTarifa.get('IdPuertoDestino'); }
  public get IdModoEnvio() { return this.formModelTarifa.get('IdModoEnvio'); }
  public get Naviera() { return this.formModelTarifa.get('Naviera'); }
  public get IdNaviera() { return this.formModelTarifa.get('IdNaviera'); }
  public get MercanciaPeligrosa() { return this.formModelTarifa.get('MercanciaPeligrosa'); }
  public get Cliente() { return this.formModelTarifa.get('Cliente'); }
  public get IdArea() { return this.formModelTarifa.get('IdArea'); }
  public get NoRemontable() { return this.formModelTarifa.get('NoRemontable'); }
  public get PesoNetoTotalDGR() { return this.formModelTarifa.get('PesoNetoTotalDGR'); }
  public get PesoBrutoTotalDGR() { return this.formModelTarifa.get('PesoBrutoTotalDGR'); }
  public get BultosTotales() { return this.formModelTarifa.get('BultosTotales'); }
  public get PesoNetoTotal() { return this.formModelTarifa.get('PesoNetoTotal'); }
  public get PesoBrutoTotal() { return this.formModelTarifa.get('PesoBrutoTotal'); }
  public get PesoTasable() { return this.formModelTarifa.get('PesoTasable'); }
  public get VolumenTotal() { return this.formModelTarifa.get('VolumenTotal'); }
  public get Mercancia() { return this.formModelTarifa.get('Mercancia'); }
  public get PesoNetoTotalDGRContenedor() { return this.formModelTarifa.get('PesoNetoTotalDGRContenedor'); }
  public get PesoBrutoTotalDGRContenedor() { return this.formModelTarifa.get('PesoBrutoTotalDGRContenedor'); }
  public get BultosTotalesContenedor() { return this.formModelTarifa.get('BultosTotalesContenedor'); }
  public get PesoBrutoTotalContenedor() { return this.formModelTarifa.get('PesoBrutoTotalContenedor'); }
  public get MercanciaPeligrosaContenedor() { return this.formModelTarifa.get('MercanciaPeligrosaContenedor'); }
  public get MercanciaContenedor() { return this.formModelTarifa.get('MercanciaContenedor'); }
  public get PuertoOrigen() { return this.formModelTarifa.get('PuertoOrigen'); }
  public get PuertoDestino() { return this.formModelTarifa.get('PuertoDestino'); }
  public get TipoTransito() { return this.formModelTarifa.get('TipoTransito'); }
  public get VolumenTasable() { return this.formModelTarifa.get('VolumenTasable'); }
  public get Incoterm() { return this.formModelTarifa.get('Incoterm'); }
  public get IdLocalidadOrigen() { return this.formModelTarifa.get('IdLocalidadOrigen'); }
  public get IncluirPortes() { return this.formModelTarifa.get('IncluirPortes'); }


  inicializar() {
    this.formModelTarifa = new FormGroup({
      Area: new FormControl('MARITIMO'),
      IdArea: new FormControl(2),
      IdTipoEnvio: new FormControl(1, Validators.required),
      IdModoEnvio: new FormControl(1, Validators.required),
      Naviera: new FormControl([]),
      IdNaviera: new FormControl(null),
      IdLocalidadOrigen: new FormControl(null),
      IdPuertoOrigen: new FormControl(null),
      IdPuertoDestino: new FormControl(null),
      Cliente: new FormControl(''),
      IdPaisDestino: new FormControl(null),
      NoRemontable: new FormControl(false),
      MercanciaPeligrosa: new FormControl(false, Validators.required),
      Mercancia: new FormControl([]), // Se comprueba a mano
      TipoTransito: new FormControl([]),
      PesoNetoTotalDGR: new FormControl(0), // Se comprueba a mano
      PesoBrutoTotalDGR: new FormControl(0), // Se comprueba a mano
      BultosTotales: new FormControl(0, Validators.required),
      PesoNetoTotal: new FormControl(0/*, Validators.required*/),
      PesoBrutoTotal: new FormControl(0, Validators.required),
      PesoTasable: new FormControl(0, Validators.required),
      VolumenTotal: new FormControl(0, Validators.required),
      PesoNetoTotalDGRContenedor: new FormControl(0),
      PesoBrutoTotalDGRContenedor: new FormControl(0),
      VolumenTasable: new FormControl(0),
      BultosTotalesContenedor: new FormControl(0),
      PesoBrutoTotalContenedor: new FormControl(0),
      MercanciaPeligrosaContenedor: new FormControl(false),
      MercanciaContenedor: new FormControl([]), // Se comprueba a mano
      Incoterm: new FormControl(null),
      IncluirPortes: new FormControl(true, Validators.required)
    });

    this.esFCL = true;
    this.visor = false;
    this.editar = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.totTarifa = [];
    this.cargarTipoEnvio();
    this.getEntidad();
    this.cargarNavieras();
    this.cargarModoEnvio();
    this.cargarPuertos();
    this.cargarLocalidades();
    this.cargarIncoterms();
    this.cargarConfiguracion();
    // this.listaMercancia.length = 0;
    if (this.appCargo != undefined && this.appCargo != null)
      this.appCargo.ngOnInit();

    // this.PesoBrutoTotal.enable();
    // this.VolumenTotal.enable();
  }

  onButtonClick(event: Event) {
    event.stopPropagation();
  }

  cargarConfiguracion() {
    this.configuracionService.getConfiguracion$().subscribe(conf => {
      this.configuracion = conf;
      if (this.esFCL) {
        this.mostrarTransporteACargoDe = conf.localidadTarifaFCL;
        this.mostrarLocalidadOrigen = conf.localidadTarifaFCL;
        this.IncluirPortes.setValue(conf.localidadTarifaFCL);
      } else {
        this.mostrarTransporteACargoDe = conf.localidadTarifaLCL;
        this.mostrarLocalidadOrigen = conf.localidadTarifaLCL;
        this.IncluirPortes.setValue(conf.localidadTarifaLCL);
      }
    });
    this.configuracionService.getConfiguracion();
  }

  cargarTipoEnvio() {
    this.envioService.getTiposEnvio(this.idArea).subscribe(data => {
      this.listTipoEnvio = data;
      this.cargaTipoEnvio = false;
    });
  }

  cargarLocalidades() {

    this.tarifaService.getLocalidades(2).subscribe(data => {
      this.listLocalidades = data;
      this.cargaLocalidades = false;
    });
  }

  cargarIncoterms() {
    this.envioService.getIncoterms(this.idArea).subscribe(lista => {
      this.cargaIncoterm = true;
      this.listIncoterm = lista;
      this.cargaIncoterm = false;
    });
  }


  setModoEnvio(modoEnvio: Modoenvio) {
    if (modoEnvio != undefined) {
      if (modoEnvio.ModoEnvio == 'FCL') {
        this.esFCL = true;
        this.PesoBrutoTotal.enable();
        this.VolumenTotal.enable();
        this.PesoNetoTotal.enable();
        this.listaMercancia.length = 0;
        this.listaMercanciaContenedor.length = 0;
        this.mostrarTransporteACargoDe = this.configuracion.localidadTarifaFCL;
        this.mostrarLocalidadOrigen = this.configuracion.localidadTarifaFCL;
        this.IncluirPortes.setValue(this.configuracion.localidadTarifaFCL);
      }
      else {
        this.esFCL = false;
        this.listaMercancia.length = 0;
        this.listaMercanciaContenedor.length = 0;
        this.mostrarTransporteACargoDe = this.configuracion.localidadTarifaLCL;
        this.mostrarLocalidadOrigen = this.configuracion.localidadTarifaLCL;
        this.IncluirPortes.setValue(this.configuracion.localidadTarifaLCL);
        if (this.listaMercancia.length > 0) {
          this.PesoBrutoTotal.disable();
          this.VolumenTotal.disable();
          this.PesoNetoTotal.disable();
        }
      }
    }
  }

  cargarModoEnvio() {
    this.envioService.getModoEnvioByArea(2).subscribe(data => {
      this.listModoEnvio = data;
      this.cargaModoEnvio = false;
    });
  }

  cargarPuertos() {
    this.envioService.getPuertos().subscribe(data => {
      this.cargaPuerto = true;
      this.listPuertoDestino = data;
      this.listPuertoOrigen = data;
      this.cargaPuerto = false;
    });
  }

  cargarNavieras() {
    this.tarifaService.getNavieras().subscribe(data => {
      this.cargaNaviera = true;
      this.listNavieras = data;
      this.cargaNaviera = false;
    });
  }

  getBandera(p: Puerto) {
    //return 'assets/banderas/' + p.IsoAlfa2.toLowerCase() + '.png';
    if (p && p.IsoAlfa2)
      return 'assets/banderas/' + p.IsoAlfa2.toLowerCase() + '.png';

    else
      return 'assets/banderas/' + p.IsoAlfa2 + '.png';

  }

  getEntidad() {
    this.envioService.getEntidadUsuario().subscribe(idEntidad => {
      this.entityService.getEntidadById(idEntidad).subscribe(cliente => {
        this.clienteEntidad = cliente;
      });
    });
  }

  puertoDestinoError() {
    return this.IdPuertoDestino.value == null && this.IdTipoEnvio.value == 1 && (this.IdPuertoDestino.touched || this.submit);
  }


  hayMercanciaPeligrosa(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercancia.forEach(element => {
      if ((element.Peligrosa || element.MP) && !yaHayPeligrosa) {
        yaHayPeligrosa = true;
        this.MercanciaPeligrosa.setValue(true)
      }
      else {
        yaHayPeligrosa = false;
        this.MercanciaPeligrosa.setValue(false)
      }
    });
    return yaHayPeligrosa;
  }

  //Funcion que calcula el peso tasable (formula particular de aereo)
  /* calcularPesoTasable1() {
     let valor: number = 0;
     if (this.PesoBrutoTotal.value != null && this.VolumenTotal.value != null) {
       valor = this.VolumenTotal.value * 167;
 
       if (this.PesoBrutoTotal.value > valor)
         this.PesoTasable.setValue(Number(this.PesoBrutoTotal.value.toString()).toFixed(3));
       else
         this.PesoTasable.setValue(Number(valor.toFixed(3)));
     }
   }*/

  //Funcion que calcula el peso tasable (formula particular de Maritimo)
  calcularPesoTasable() {
    let volumenCalculado: number = 0;
    this.formModelTarifa.value.PesoTasable = 0;
    if (this.PesoBrutoTotal.value != null && this.VolumenTotal.value != null) {
      volumenCalculado = (this.VolumenTotal.value * 1000);
      if (this.PesoBrutoTotal.value > volumenCalculado) {
        this.PesoTasable.setValue(Number(this.PesoBrutoTotal.value).toFixed(3))
      }
      else {
        this.PesoTasable.setValue(Number(volumenCalculado).toFixed(3))
      }
    }
  }


  asignarVolumenTotal() {
    let valor: number = 0;
    if (this.PesoBrutoTotal.value != null && this.VolumenTotal.value != null) {
      valor = this.VolumenTotal.value * 167;

      if (this.VolumenTotal.value > valor)
        this.VolumenTotal.setValue(Number(this.PesoBrutoTotal.value.toString()).toFixed(3));
      else
        this.VolumenTotal.setValue(Number(valor.toFixed(3)));
    }
  }

  //Funcion que comprueba si el peso neto total es menor que el bruto total
  comprobarPesosTotales() {
    if (this.PesoBrutoTotal.value !== null && this.PesoBrutoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoNetoTotal.value !== ""
      && this.PesoNetoTotal.value !== null && this.PesoNetoTotal.value !== undefined
      && parseInt(this.PesoBrutoTotal.value) < parseInt(this.PesoNetoTotal.value) && this.submit) {
      var Body = this.translateService.instant("Peso Bruto Total es menor que Peso Neto Total");
      var Titulo = this.translateService.instant("Peso Bruto Total menor que Peso Neto Total");
      this.toastr.error(Body, Titulo);
      return false
    }
    return true
  }

  PesoNetoTotalError() {
    return this.PesoNetoTotal.invalid && (this.PesoNetoTotal.touched || this.submit);
  }

  BultosTotalesError() {
    return this.BultosTotales.invalid && (this.BultosTotales.touched || this.submit);
  }

  PesoBrutoTotalError() {
    return (this.PesoBrutoTotal.value == 0 || this.PesoBrutoTotal.value == null || this.PesoBrutoTotal.value == "") && (this.PesoBrutoTotal.touched || this.submit);
  }

  NetoDgrMenorBrutoError() {
    return (this.PesoBrutoTotal.value < this.PesoNetoTotalDGR.value) && (this.PesoNetoTotalDGR.touched || this.submit);
  }

  VolumenTotalError() {
    return this.VolumenTotal.invalid && (this.VolumenTotal.touched || this.submit);
  }

  VolumenTasableError() {
    if (!this.esFCL) {
      return (this.VolumenTasable.touched || this.submit) && (this.VolumenTasable.value == 0 || this.VolumenTasable.value == null) && (this.PesoTasable.value == 0 || this.PesoTasable.value == null) && this.listaMercancia.length == 0;
    }
    else return false;
  }

  PesoTasableError() {
    if (!this.esFCL) {
      return (this.PesoTasable.touched || this.submit) && (this.PesoTasable.value == 0 || this.PesoTasable.value == null) && (this.VolumenTasable.value == 0 || this.VolumenTasable.value == null) && this.listaMercancia.length == 0;
    }
    else return false;
  }

  puertoOrigenError() {
    return this.IdPuertoOrigen.value == null && this.IdTipoEnvio.value == 2 && (this.IdPuertoOrigen.touched || this.submit);
  }

  /* PesoNetoTotalDGRError() {
     return this.MercanciaPeligrosa.value && this.PesoNetoTotalDGR.invalid && (this.PesoNetoTotalDGR.touched || this.submit);
   }
 
   PesoBrutoTotalDGRError() {
     return this.MercanciaPeligrosa.value && this.PesoBrutoTotalDGR.invalid && (this.PesoBrutoTotalDGR.touched || this.submit);
   }*/

  BultosTotalesContenedorError() {
    return this.BultosTotalesContenedor.invalid && (this.BultosTotalesContenedor.touched || this.submit);
  }

  PesoBrutoTotalContenedorError() {
    return this.PesoBrutoTotalContenedor.invalid && (this.PesoBrutoTotalContenedor.touched || this.submit);
  }

  compruebaMercYtotales(): boolean {
    return (((this.PesoBrutoTotal.value == 0 || this.VolumenTotal.value == 0) && this.listaMercancia.length == 0)) && this.submit
  }

  /*  compruebaMercVaciaYtotales(): boolean {
      return (((this.PesoBrutoTotal.value == 0 || this.VolumenTotal.value == 0) && this.listaMercancia.length == 0)) && this.submit
    }*/

  compruebaContenedorYtotales(): boolean {
    return (this.PesoBrutoTotal.value === 0 && this.listaMercanciaContenedor.length == 0) && this.submit
  }

  compruebaMercancia() {
    return ((this.Mercancia.value.PesoBruto == 0 || this.Mercancia.value.PesoBruto == null) && (this.Mercancia.value.Volumen == 0 || this.Mercancia.value.Volumen == null)) && this.submit
  }

  compruebaTipoContMercancia() {
    return ((this.listaMercanciaContenedor.length == 0 || this.Mercancia.value.PesoBruto == null) && (this.Mercancia.value.Volumen == 0 || this.Mercancia.value.ContenedorMercancia.IdTipoContenedor == null)) && this.submit
  }

  compruebaTotales() {
    return (this.PesoBrutoTotal.value == 0 && this.VolumenTotal.value == 0 && this.listaMercanciaContenedor.length != 0 && this.listaMercancia.length == 0) && this.submit
  }

  compruebaLocalidadOrigen() {
    return this.IncluirPortes.value && (this.IdLocalidadOrigen.value == null || this.IdLocalidadOrigen.value == undefined) && this.submit;
  }

  PesoNetoTotalDGRContenedorError() {
    return this.MercanciaPeligrosaContenedor.value && this.PesoNetoTotalDGRContenedor.invalid && (this.PesoNetoTotalDGRContenedor.touched || this.submit);
  }

  PesoBrutoTotalDGRContenedorError() {
    return this.MercanciaPeligrosaContenedor.value && this.PesoBrutoTotalDGRContenedor.invalid && (this.PesoBrutoTotalDGRContenedor.touched || this.submit);
  }

  //Funcion que comprueba que si se marca la casilla de peligrosa, tenga mercancia peligrosa
  /* compruebaMercanciaPeligrosa(): Boolean {
     let count = 0;
     if (this.MercanciaPeligrosa.value == true)
       this.Mercancia.value.forEach(mercancia => {
         if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length > 0)
           count++;
       });
     else
       count = 1;
 
     if (count == 0)
       return false;
 
     return true;
   }*/

  bloquearTotales() {
    if (this.listaMercanciaContenedor.length > 1) {
      this.volumenTotalReadOnly = true;
      this.pesoBrutoTotalReadonly = true;
    }
    else {
      this.volumenTotalReadOnly = false;
      this.pesoBrutoTotalReadonly = false;
    }

  }

  error2Contenedores() {
    this.listaMercanciaContenedor.forEach((mc => {
      if (mc.Mercancia.length < 2) {
        this.error2cont = true
      }
    }))
  }

  tieneErrorPesoBrutoTotalDGR() {
    return this.PesoBrutoTotal.value !== null && this.PesoBrutoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoBrutoTotalDGR.value !== ""
      && this.PesoBrutoTotalDGR.value !== null && this.PesoBrutoTotalDGR.value !== undefined
      && parseInt(this.PesoBrutoTotal.value) < parseInt(this.PesoBrutoTotalDGR.value);
  }

  tieneErrorPesoNetoTotalDGR() {
    return this.PesoNetoTotal.value !== null && this.PesoNetoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoNetoTotalDGR.value !== ""
      && this.PesoNetoTotalDGR.value !== null && this.PesoNetoTotalDGR.value !== undefined
      && parseInt(this.PesoNetoTotal.value) < parseInt(this.PesoNetoTotalDGR.value);
  }

  //Funcion que obtiene la lista de mercancias del componente Cargo y que recalcula los totales
  listaMerc(lista: Mercancia[]) {
    this.listaMercancia = lista;
    var anteriorPesoBrutoTotal = this.PesoBrutoTotal.value;
    this.BultosTotales.setValue(0);
    this.PesoBrutoTotal.setValue(0);
    this.PesoNetoTotal.setValue(0);
    this.VolumenTotal.setValue(0);
    this.PesoTasable.setValue(0);
    this.PesoNetoTotalDGR.setValue(0);
    this.PesoBrutoTotalDGR.setValue(0);
    if (this.listaMercancia != null) {
      // this.PesoBrutoTotal.disable();
      this.VolumenTotal.disable();
      this.PesoNetoTotal.disable();
      if (this.listaMercancia.length == 0) {
        this.PesoBrutoTotal.enable();
        this.VolumenTotal.enable();
        this.PesoNetoTotal.enable();
        this.BultosTotales.setValue(0);
        this.PesoBrutoTotal.setValue(0);
        this.PesoNetoTotal.setValue(0);
        this.VolumenTotal.setValue(0);
        this.PesoTasable.setValue(0);
        this.PesoNetoTotalDGR.setValue(0);
        this.PesoBrutoTotalDGR.setValue(0);
      }
      else {
        this.VolumenTotal.setValue(0);
        this.PesoTasable.setValue(0);
        if (this.listaMercancia.find(m => m.Peligrosa == true) != undefined)
          this.MercanciaPeligrosa.setValue(true);
        else
          this.MercanciaPeligrosa.setValue(false);

        if (this.listaMercancia != undefined && this.listaMercancia.length > 0) {
          this.listaMercancia.forEach(element => {
            this.BultosTotales.setValue(Number((Number(this.BultosTotales.value) + Number(element.Bultos ? element.Bultos : 0)).toFixed(3)));

            this.PesoBrutoTotal.setValue(Number((Number(this.PesoBrutoTotal.value ? this.PesoBrutoTotal.value : 0) +
              Number((element.PesoBruto ? element.PesoBruto : 0))).toFixed(3)));

            this.PesoNetoTotal.setValue(Number((Number(this.PesoNetoTotal.value ? this.PesoNetoTotal.value : 0) +
              Number(element.PesoNeto ? element.PesoNeto : 0)).toFixed(3)));

            this.VolumenTotal.setValue(Number((Number(this.VolumenTotal.value) + Number(element.Volumen ? element.Volumen : 0)).toFixed(3)));

            this.MercanciaPeligrosa.setValue(this.hayMercanciaPeligrosa());

            if (element.MercanciaPeligrosa != undefined && element.MercanciaPeligrosa.length >= 0 && element.Peligrosa) {
              element.MercanciaPeligrosa.forEach(element2 => {
                if (element2.PesoNeto != undefined)
                  this.PesoNetoTotalDGR.setValue(Number(this.PesoNetoTotalDGR.value.toString().replace(',', '.')) + Number(element2.PesoNeto.toString().replace(',', '.')));
                if (element2.PesoBruto != undefined)
                  this.PesoBrutoTotalDGR.setValue(Number(this.PesoBrutoTotalDGR.value.toString().replace(',', '.')) + Number(element2.PesoBruto.toString().replace(',', '.')));
              });
            }
          });
          this.calcularPesoTasable();
          this.Mercancia.setValue(this.listaMercancia);
        }

        if (anteriorPesoBrutoTotal !== this.PesoBrutoTotal)
          this.comprobarPesosTotales();
      }
    }

  }

  siHayMercanciaPeligrosaContenedor(): boolean {
    var hayMercanciaPeligrosa = false;
    this.listaMercanciaContenedor.forEach(mc => {
      mc.Mercancia.forEach(m => {
        if (!hayMercanciaPeligrosa && m.Peligrosa)
          hayMercanciaPeligrosa = m.Peligrosa;
      });
    });
    return hayMercanciaPeligrosa;
  }


  hayMercanciaPeligrosaMercanciaContenedor(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercanciaContenedor.forEach(c => {
      c.Mercancia.forEach(element => {
        if ((element.Peligrosa || element.MP) && !yaHayPeligrosa) {
          yaHayPeligrosa = true;
          this.MercanciaPeligrosa.setValue(true)
        }
        else {
          yaHayPeligrosa = false;
          this.MercanciaPeligrosa.setValue(false)
        }
      });
    });
    return yaHayPeligrosa;
  }

  mostrarError(bodyInstant: string, titleInstant: string) {
    var body: string = this.translateService.instant(bodyInstant);
    var title: string = this.translateService.instant(titleInstant);
    this.toastr.error(body, title);
  }

  //Funcion que comprueba los pesos y si el bruto es mayor que el neto
  comprobarPesos(elem: Mercancia) {
    if (elem.PesoBruto === undefined || elem.PesoBruto === null || elem.PesoBruto.toString() == '')
      return true
    if (elem.PesoNeto !== undefined && elem.PesoNeto !== null && elem.PesoNeto.toString() != '')
      return elem.PesoBruto !== undefined && elem.PesoNeto !== undefined && elem.PesoBruto !== null && elem.PesoNeto !== null && Number(elem.PesoNeto) <= Number(elem.PesoBruto)
    else
      return elem.PesoBruto !== undefined && elem.PesoBruto !== null && elem.PesoBruto.toString() != ''
  }

  //Comprueba si hay una mercancía vacía o algún campo de la mercancía vacía
  comprobarVacio(merc: Mercancia) {
    if (this.esFCL)
      return merc.PesoBruto === null || merc.PesoBruto === undefined || merc.PesoBruto.toString() === '';
    else
      return (merc.Bultos === null || merc.Bultos === undefined || merc.Bultos === 0
        || merc.Volumen === null || merc.Volumen === undefined || merc.Volumen === 0
        || merc.Largo === undefined || merc.Largo === null || merc.Ancho === undefined || merc.Ancho === null || merc.Alto === undefined || merc.Alto === null
        || merc.Volumen.toString() === ''
        || merc.Bultos.toString() === '')
  }

  //Comprueba si hay una mercancía vacía en tarifas o algún campo de la mercancía vacía
  comprobarVacioTarifa(merc: Mercancia) {
    return ((merc.PesoBruto === null || merc.PesoBruto === 0 || merc.PesoBruto.toString() === ''))
  }

  comprobarVacioTarifaCont(merc: Mercancia) {
    return ((merc.PesoBruto === null || merc.PesoBruto === 0 || merc.PesoBruto.toString() === ''))
  }

  comprobarVacioTipoContenedor(merc: ContenedorMercancia) {
    return ((merc.IdTipoContenedor === null || merc.NombreContenedor === undefined || merc.NombreContenedor === '' || merc.NombreContenedor === null))
  }

  comprobarGrados(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return merc.GradosMax === null || merc.GradosMax === undefined || merc.GradosMin === null || merc.GradosMin === undefined;
    return false;
  }

  PesoNetoTotalDGRError() {
    return this.MercanciaPeligrosa.value && this.PesoNetoTotalDGR.invalid && (this.PesoNetoTotalDGR.touched || this.submit);
  }

  comprobraGradosDispares(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return this.comprobarGrados(merc) || merc.GradosMax < merc.GradosMin;
    return false;
  }

  listaMercContenedor(lista: ContenedorMercancia[]) {
    this.listaMercanciaContenedor = lista;
    if (this.listaMercanciaContenedor != null) {
      this.bloquearTotales()
      this.BultosTotales.setValue(0);
      this.PesoBrutoTotal.setValue(0);
      this.PesoNetoTotal.setValue(0);
      this.VolumenTotal.setValue(0);
      this.PesoTasable.setValue(0);
      this.PesoNetoTotalDGR.setValue(0);
      this.PesoBrutoTotalDGR.setValue(0);
      this.listaMercanciaContenedor.forEach(cm => {
        if (cm.Mercancia != undefined && cm.Mercancia.length > 0) {
          cm.Mercancia.forEach(element => {
            this.BultosTotales.setValue(Number((Number(this.BultosTotales.value) + Number(element.Bultos ? element.Bultos : 0)).toFixed(3)));

            this.PesoBrutoTotal.setValue(Number((Number(this.PesoBrutoTotal.value ? this.PesoBrutoTotal.value : 0) +
              Number((element.PesoBruto ? element.PesoBruto : 0))).toFixed(3)));

            this.PesoNetoTotal.setValue(Number((Number(this.PesoNetoTotal.value ? this.PesoNetoTotal.value : 0) +
              Number(element.PesoNeto ? element.PesoNeto : 0)).toFixed(3)));

            this.VolumenTotal.setValue(Number((Number(this.VolumenTotal.value) + Number(element.Volumen ? element.Volumen : 0)).toFixed(3)));
            // this.hayMercanciaPeligrosaContenedor = this.siHayMercanciaPeligrosaContenedor();
            //  this.MercanciaPeligrosa.setValue(this.hayMercanciaPeligrosaMercanciaContenedor());
            if (element.Peligrosa != undefined && element.Peligrosa && element.MercanciaPeligrosa != undefined && element.MercanciaPeligrosa != null && element.MercanciaPeligrosa.length > 0) {
              element.MercanciaPeligrosa.forEach(element2 => {
                if (element2.PesoNeto != undefined)
                  this.PesoNetoTotalDGR.setValue(Number(this.PesoNetoTotalDGR.value) + Number(element2.PesoNeto));
                if (element2.PesoBruto != undefined)
                  this.PesoBrutoTotalDGR.setValue(Number(this.PesoBrutoTotalDGR.value) + Number(element2.PesoBruto));
              });
            }
          });
        }
      });
      if (this.PesoBrutoTotal.value / this.listaMercanciaContenedor.length > 30000)
        this.PesoBrutoTotal.setValue(0);
      this.calcularPesoTasable();
    }
  }

  //Funcion que comprueba si hay errores en una linea de mercancía
  comprobarErrores() {
    var camposVacios = false, pesos = false, index = 1, mercpeligrosaIndex = 1, unIndex = 1, grados = false, camposVaciosContenedor = false, errorContenedores = false, contador = 0, indicePesos = 0, indicePB = -1, indicePN = -1, pesosBrutosDispares = true, pesosNetosDispares = true;
    var indicesMP = [], indiceNoExisteMercanciaPeligrosa = [], indiceNoHayUn = [], indiceTC = [], tipoContArray = [], mercanciaTarifaArray = [], pesosDisparesArray = [], mercanciaspeligrosasAux = [], pesosGradosArray = [], pesosGradosDisparesArray = [], mercanciastemperaturacontroladaAux = [], pesosBrutosArray = [], pesosNetosArray = [];;
    if (this.esFCL) {
      if (this.PesoBrutoTotal.value > 0 && this.listaMercanciaContenedor.length == 0) {
        camposVaciosContenedor = true;
        this.mostrarError("BodyTipoContenedorTotalesError", "TitleTipoContenedorTotalesError");

      }
      else if (this.PesoBrutoTotal.value == 0 && this.listaMercanciaContenedor.length == 0) {
        //camposVaciosContenedor = true;
        //  this.mostrarError("BodyTipoContenedorTotalesError", "TitleTipoContenedorTotalesError");
      }
      else {
        contador++;
        this.listaMercanciaContenedor.forEach(e => {
          /*    if (this.listaMercanciaContenedor.length > 0 && e.Mercancia.length < 1 && e.IdTipoContenedor == undefined) {
                camposVaciosContenedor = true;
                this.mostrarError("BodyContenedoryTotalesError", "TitleContenedoryTotalesError");
              }*/
          if (this.listaMercanciaContenedor.length > 1 && e.Mercancia.length == 0) {
            camposVaciosContenedor = true;
            var Body = this.translateService.instant('Al contenedor ') + contador + this.translateService.instant(' le falta el peso bruto de la mercancia');
            var Titulo = this.translateService.instant('Error peso bruto mercancia');
            this.toastr.error(Body, Titulo);
          }
          if (this.listaMercanciaContenedor.length > 0 && e.Mercancia.length < 1 && e.IdTipoContenedor != undefined && this.PesoBrutoTotal.value == 0) {
            camposVaciosContenedor = true;
            this.mostrarError("BodyPbTotalError", "TitlePbTotalError");
          }
          if (e.IdTipoContenedor == undefined || e.IdTipoContenedor == null) {
            var Body = this.translateService.instant('El contenedor ') + contador + this.translateService.instant(' le falta el tipo de contenedor');
            var Titulo = this.translateService.instant('Error en contenedor');
            this.toastr.error(Body, Titulo);
            errorContenedores = true;
          }

          /// var compruebaTipoCont = this.comprobarVacioTipoContenedor(e);
          // if (compruebaTipoCont) {
          //   tipoContArray.push(index);
          // }

          e.Mercancia.forEach(merc => {
            if (merc.Peligrosa && (merc.MercanciaPeligrosa == undefined || merc.MercanciaPeligrosa == null || merc.MercanciaPeligrosa.length == 0)) {
              indiceNoExisteMercanciaPeligrosa.push(index);
              if (e.Mercancia.length == 1)
                mercpeligrosaIndex = 1;
              var Body = this.translateService.instant('Falta mercancía peligrosa en la línea ') + mercpeligrosaIndex + this.translateService.instant("delContenedor") + contador
                + this.translateService.instant(' de la tabla de mercancía');
              var Titulo = this.translateService.instant('Falta mercancía peligrosa');
              this.toastr.error(Body, Titulo);
              mercpeligrosaIndex++;
            }
            else
              mercpeligrosaIndex++;
            if (merc.MercanciaPeligrosa != undefined) {
              merc.MercanciaPeligrosa.forEach(merc2 => {
                if (merc2.Un == null || merc2.Un == undefined || merc2.Un == '') {
                  indiceNoHayUn.push(index);
                  if (e.Mercancia.length == 1)
                    unIndex = 1;
                  var Body = this.translateService.instant('Falta indicar el UN en la línea ') + unIndex + this.translateService.instant("delContenedor") + contador
                    + this.translateService.instant(' de la tabla de mercancía');
                  var Titulo = this.translateService.instant('Falta mercancía peligrosa');
                  this.toastr.error(Body, Titulo);
                  unIndex++;
                }
                else
                  unIndex++;
              });
            }
            if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
              mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
              indiceTC.push(index);
            }
            //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);
            //   var compruebaMP = this.comprobarVacio(merc);
            var compruebaMT = this.comprobarVacioTarifa(merc);
            var compruebaPesos = this.comprobarPesos(merc);
            var compruebaGrados = this.comprobarGrados(merc);
            var compruebaGradosDispares = this.comprobraGradosDispares(merc);
            // if (compruebaMP)
            //   indicesMP.push(index);
            // if (compruebaMT)
            //   mercanciaTarifaArray.push(index);
            if (!compruebaPesos)
              pesosDisparesArray.push(index);
            if (compruebaGrados)
              pesosGradosArray.push(index);
            if (!compruebaGrados && compruebaGradosDispares)
              pesosGradosDisparesArray.push(index);
            index++;
          });
          contador++;
          mercpeligrosaIndex = 1;
          unIndex = 1;
        });
      }
    } else {
      if (this.compruebaMercYtotales()) {
        camposVacios = true;
      }
      else {
        this.listaMercancia.forEach(merc => {
          if (merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0 && merc.MercanciaPeligrosa != null) {
            // mercanciaspeligrosasAux.push(merc.MercanciaPeligrosa);
          }
          if (merc.MercanciaPeligrosa != undefined) {
            merc.MercanciaPeligrosa.forEach(merc2 => {
              if (merc2.Un == null || merc2.Un == undefined || merc2.Un == '') {
                indiceNoHayUn.push(index);
              }
            });
          }
          if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
            mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
            indiceTC.push(index);
          }
          //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);

          var compruebaMP = this.comprobarVacio(merc);
          var compruebaMT = this.comprobarVacioTarifa(merc);
          var compruebaPesos = this.comprobarPesos(merc);
          var compruebaGrados = this.comprobarGrados(merc);
          var compruebaGradosDispares = this.comprobraGradosDispares(merc);
          if (merc.Peligrosa && merc.MercanciaPeligrosa.length == 0)
            indiceNoExisteMercanciaPeligrosa.push(index);
          if (compruebaMP)
            indicesMP.push(index);
          // if (compruebaMT)
          //  mercanciaTarifaArray.push(index);
          if (!compruebaPesos)
            pesosDisparesArray.push(index);
          if (compruebaGrados)
            pesosGradosArray.push(index);
          if (!compruebaGrados && compruebaGradosDispares)
            pesosGradosDisparesArray.push(index);
          index++;
        });
      }

    }
    if (this.esFCL) {
      for (let i = 1; i < contador; i++) {
        /*  if (this.MercanciaPeligrosa.value == true && mercanciaspeligrosasAux.length == 0) {
            camposVacios = true;
            var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
            var Titulo = this.translateService.instant('Error mercancia peligrosa');
            this.toastr.error(Body, Titulo)
          }*/

        index = 1;
        mercanciaTarifaArray.forEach(imp => {
          camposVacios = true
          var Body = this.translateService.instant('Necesitas rellenar como minimo el bruto de la línea ') + imp
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Campos vacíos en mercancía');
          this.toastr.error(Body, Titulo)
        });
        tipoContArray.forEach(imp => {
          camposVacios = true
          var Body = this.translateService.instant('Necesitas rellenar el tipo de contenedor ') + imp
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Campos vacíos en mercancía');
          this.toastr.error(Body, Titulo)
        })
        indiceNoExisteMercanciaPeligrosa.forEach(imp => {
          camposVacios = true
          /*  var Body = this.translateService.instant('Falta mercancía peligrosa en la línea ') + i + this.translateService.instant("delContenedor") + imp
              + this.translateService.instant(' de la tabla de mercancía');
            var Titulo = this.translateService.instant('Falta mercancía peligrosa');
            this.toastr.error(Body, Titulo);*/
        })
        indiceNoHayUn.forEach(imp => {
          camposVacios = true
          /*var Body = this.translateService.instant('Falta indicar el UN en la línea ') + i + this.translateService.instant("delContenedor") + imp
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Falta mercancía peligrosa');
          this.toastr.error(Body, Titulo);*/
        })
        indicesMP.forEach(imp => {
          camposVacios = true
          var Body = this.translateService.instant('Necesitas rellenar bultos y medidas en la línea ') + imp + this.translateService.instant("delContenedor") + i
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Campos vacíos en mercancía') + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo)
        });

        pesosDisparesArray.forEach(e => {
          var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e + this.translateService.instant("delContenedor") + i
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo)
          pesos = true
        })
        pesosGradosArray.forEach(tc => {
          var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc + this.translateService.instant("delContenedor") + i
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo)
          grados = true;
        });
        pesosBrutosArray.forEach(e => {
          var Body = this.translateService.instant('El peso bruto de la linea ') + e + this.translateService.instant("delContenedor") + i
            + (' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
          var Titulo = this.translateService.instant('Error peso bruto DGR linea ') + e + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo);
          pesos = true
        });
        pesosNetosArray.forEach(e => {
          var Body = this.translateService.instant('El peso neto de la linea ') + e + this.translateService.instant("delContenedor") + i + this.translateService.instant(' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
          var Titulo = this.translateService.instant('Error peso neto DGR linea ') + e + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo);
          pesos = true
        });
      }

    } else {
      /*  if (this.MercanciaPeligrosa.value == true && mercanciaspeligrosasAux.length == 0) {
          camposVacios = true
          var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
          var Titulo = this.translateService.instant('Error mercancia peligrosa');
          this.toastr.error(Body, Titulo)
        }*/
      index = 1;
      indiceNoExisteMercanciaPeligrosa.forEach(inem => {
        camposVacios = true
        var Body = this.translateService.instant('Falta indicar el UN en la línea ') + inem
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Falta mercancía peligrosa');
        this.toastr.error(Body, Titulo);
      })
      indicesMP.forEach(imp => {
        camposVacios = true
        var Body = this.translateService.instant('Necesitas rellenar bultos y medidas en la línea ') + imp
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Campos vacíos en mercancía');
        this.toastr.error(Body, Titulo)
      });
      mercanciaTarifaArray.forEach(imp => {
        camposVacios = true
        var Body = this.translateService.instant('Excepto los requerimientos especiales, Es necesario rellenar los demás campos de la linea ') + imp
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Campos vacíos en mercancía');
        this.toastr.error(Body, Titulo)
      });
      pesosDisparesArray.forEach(e => {
        var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e;
        this.toastr.error(Body, Titulo)
        pesos = true
      })
      pesosGradosArray.forEach(tc => {
        var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc;
        this.toastr.error(Body, Titulo)
        grados = true;
      });
      pesosBrutosArray.forEach(e => {
        var Body = this.translateService.instant('El peso bruto de la linea ') + e + (' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
        var Titulo = this.translateService.instant('Error peso bruto DGR linea ') + e;
        this.toastr.error(Body, Titulo);
        pesos = true
      });
      pesosNetosArray.forEach(e => {
        var Body = this.translateService.instant('El peso neto de la linea ') + e + (' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
        var Titulo = this.translateService.instant('Error peso neto DGR linea ') + e;
        this.toastr.error(Body, Titulo);
        pesos = true
      });
    }

    if (pesos || !this.comprobarPesoBrutoTotalDGR() || !this.comprobarVacioPesoNetoTotalDGR() || grados || camposVacios || !this.comprobarPesosTotales() || camposVaciosContenedor || errorContenedores || this.errorPesoNetoDGR) { // Existe algún error
      return true
    }
    return false
  }

  comprobarVacioPesoNetoTotalDGR() {
    if (this.esFCL) {
      if (this.hayMercanciaPeligrosaMercanciaContenedor() && (this.formModelTarifa.value.PesoNetoTotalDGR == 0 || this.formModelTarifa.value.PesoNetoTotalDGR == null || this.formModelTarifa.value.PesoNetoTotalDGR == "")) {
        var Body = this.translateService.instant("El peso neto total DGR es obligatorio.");
        var Titulo = this.translateService.instant("Error peso neto total DGR");
        this.toastr.error(Body, Titulo);
        this.errorPesoNetoDGR = true;
        return false
      }
      else
        this.errorPesoNetoDGR = false;
      return true;
    }
    else {
      if (this.hayMercanciaPeligrosa() && (this.formModelTarifa.value.PesoNetoTotalDGR == 0 || this.formModelTarifa.value.PesoNetoTotalDGR == null || this.formModelTarifa.value.PesoNetoTotalDGR == "")) {
        var Body = this.translateService.instant("El peso neto total DGR es obligatorio.");
        var Titulo = this.translateService.instant("Error peso neto total DGR");
        this.toastr.error(Body, Titulo);
        this.errorPesoNetoDGR = true;
        return false
      }
      else
        this.errorPesoNetoDGR = false;
      return true;
    }
  }

  comprobarVacioPesoBruto() {
    if ((this.formModelTarifa.value.PesoBrutoTotal == 0 || this.formModelTarifa.value.PesoBrutoTotal == null || this.formModelTarifa.value.PesoBrutoTotal == "")) {
      var Body = this.translateService.instant("El Peso Bruto Total es obligatorio.");
      var Titulo = this.translateService.instant("Error peso Bruto total");
      this.toastr.error(Body, Titulo);
      // this.errorPesoBrutoVacio = true;
      return false
    }
    else
      // this.errorPesoBrutoVacio = false;
      return true;
  }

  comprobarPesoBrutoTotalDGR() {
    if (this.PesoBrutoTotal.value !== null && this.PesoBrutoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoBrutoTotalDGR.value !== ""
      && this.PesoBrutoTotalDGR.value !== null && this.PesoBrutoTotalDGR.value !== undefined
      && parseInt(this.PesoBrutoTotal.value) < parseInt(this.PesoBrutoTotalDGR.value)) {
      var Body = this.translateService.instant("El Peso Bruto Total debe ser mayor o igual que el Peso Bruto Total DGR ");
      var Titulo = this.translateService.instant("Error peso bruto total DGR");
      this.toastr.error(Body, Titulo);
      return false;
    }
    return true;
  }


  //Funcion que comprueba si los pesos Netos totales son mayores que los pesos Netos totales DGR 
  comprobarPesoNetoTotalDGR() {
    /*  if (this.PesoNetoTotal.value !== null && this.PesoNetoTotal !== undefined
        && this.PesoBrutoTotal.value !== "" && this.PesoNetoTotalDGR.value !== ""
        && this.PesoNetoTotalDGR.value !== null && this.PesoNetoTotalDGR.value !== undefined
        && parseInt(this.PesoNetoTotal.value) < parseInt(this.PesoNetoTotalDGR.value)) {
        var Body = this.translateService.instant("El Peso Neto Total debe ser mayor o igual que el Peso Neto Total DGR.");
        var Titulo = this.translateService.instant("Error peso Neto total DGR");
        this.toastr.error(Body, Titulo);
        return false
      }*/
    return true
  }

  contadorErrores() {
    var contadorErrores = 0;
    if (this.puertoDestinoError())
      contadorErrores++;
    if (this.puertoOrigenError())
      contadorErrores++;
    if (this.BultosTotalesError())
      contadorErrores++;
    if (this.PesoNetoTotalError())
      contadorErrores++;
    if (this.PesoBrutoTotalError())
      contadorErrores++;
    if (this.VolumenTotalError())
      contadorErrores++;
    if (this.IdModoEnvio.value == 1) {
      if (this.compruebaContenedorYtotales())
        contadorErrores++;
    }
    if (this.IdModoEnvio.value == 2) {
      if (this.compruebaMercYtotales())
        contadorErrores++;
    }
    if (this.NetoDgrMenorBrutoError())
      contadorErrores++;
    if (this.compruebaLocalidadOrigen())
      contadorErrores++;

    /* if (this.VolumenTasableError() && this.PesoTasableError())
     contadorErrores++;*/
    /*  if (this.PesoNetoTotalDGRError())
        contadorErrores++;
      if (this.PesoBrutoTotalDGRError())
        contadorErrores++;*/
    /*if (this.compruebaMercYtotales())
      contadorErrores++;*/
    return contadorErrores;
  }

  mostrarErrores() {
    var contadorErrores: number = this.contadorErrores();
    if (contadorErrores > 1) {
      var body: string = this.translateService.instant("BodyMasDeUnError") + contadorErrores + " " + this.translateService.instant("Errores");
      var title: string = this.translateService.instant("TitleMasDeUnError") + contadorErrores + " " + this.translateService.instant("Errores");
      this.toastr.error(body, title);
    }
    else {
      if (this.puertoDestinoError())
        this.mostrarError("BodyPuertoDestinoError", "TitlePuertoDestinoError");
      else if (this.BultosTotalesError())
        this.mostrarError("BodyBultosTotalesError", "TitleBultosTotalesError");
      else if (this.PesoNetoTotalError())
        this.mostrarError("BodyPesoNetoTotalError", "TitlePesoNetoTotalError");
      else if (this.PesoBrutoTotalError())
        this.mostrarError("BodyPesoBrutoTotalError", "TitlePesoBrutoTotalError");
      else if (this.VolumenTotalError())
        this.mostrarError("BodyVolumenTotalError", "TitleVolumenTotalError");
      else if (this.compruebaContenedorYtotales() && this.IdModoEnvio.value == 1)
        this.mostrarError("BodyContenedorTotalesError", "TitleContenedorTotalesError");
      else if (this.compruebaMercYtotales() && this.IdModoEnvio.value == 2)
        this.mostrarError("BodyMercTotalesError", "TitleMercTotalesError");
      else if (this.NetoDgrMenorBrutoError())
        this.mostrarError("BodyNetoDgrMenorBrutoError", "TitleNetoDgrMenorBrutoError");
      else if (this.compruebaLocalidadOrigen())
        this.mostrarError("BodyLocalidadOrigenError", "TitleLocalidadOrigenError");
    }
  }

  crearTarifa() {
    this.submit = true;
    if (this.formModelTarifa.valid && (!this.VolumenTasableError() && !this.PesoTasableError() && !this.PesoBrutoTotalError() && !this.NetoDgrMenorBrutoError() && !this.compruebaLocalidadOrigen())) {
      if (/*this.compruebaMercanciaPeligrosa()*/  !this.comprobarErrores()) {
        this.convertFormToModel();
        this.postTarifa();
      } else
        this.mostrarErrores();
    } else
      this.mostrarErrores();
  }

  cambioPortes() {
    this.mostrarLocalidadOrigen = this.IncluirPortes.value;
  }


  postTarifa() {
    this.convertFormToModel();
    var logModel: LogModel = new LogModel();
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    this.tarifaService.postTarifa(this.Tarifa, logModel).subscribe(data => {
      dialogRef.close();
      this.submit = false;
      this.listaTarifas = data;
      this.tarifaService.listaTarifas = data;
      this.tarifaService.tarifa = this.Tarifa;
      this.showListTarifas = true;
      this.cambiarURL();
      var Body = this.translateService.instant('OkBodyTarifa');
      var Titulo = this.translateService.instant('OkTitleTarifa');
      this.toastr.success(Body, Titulo);
      this.Tarifa = new TarifaEnvio();
      this.formModelTarifa.reset();
      this.inicializar();
    }, err => {
      var Body = this.translateService.instant('ErrBodyTarifa');
      var Titulo = this.translateService.instant('ErrTitleTarifa');
      this.toastr.error(Body, Titulo);
      this.submit = false;
      dialogRef.close();
    });
  }

  convertFormToModel() {
    this.Tarifa.Bultos = this.BultosTotales.value;
    this.Tarifa.IdArea = this.IdArea.value;
    this.Tarifa.IdModoEnvio = this.IdModoEnvio.value;
    this.Tarifa.IdPuertoDestino = this.IdPuertoDestino.value;
    this.Tarifa.IdPuertoOrigen = this.IdPuertoOrigen.value;
    this.Tarifa.Mercancia = this.Mercancia.value;
    this.Tarifa.MercanciaPeligrosa = this.MercanciaPeligrosa.value;
    this.Tarifa.NoRemontable = this.NoRemontable.value;
    this.Tarifa.IdNaviera = this.IdNaviera.value;
    this.Tarifa.PesoBrutoTotal = this.PesoBrutoTotal.value;
    this.Tarifa.PesoBrutoTotalDGR = this.PesoBrutoTotalDGR.value;
    this.Tarifa.PesoNetoTotal = this.PesoNetoTotal.value;
    this.Tarifa.PesoNetoTotalDGR = this.PesoNetoTotalDGR.value;
    this.Tarifa.Cliente = this.Cliente.value;
    this.Tarifa.IdTipoEnvio = this.IdTipoEnvio.value;
    // this.Tarifa.TipoTransito = this.TipoTransito.value;
    this.Tarifa.PesoTasable = this.PesoTasable.value;
    this.Tarifa.VolumenTotal = this.VolumenTotal.value;
    this.Tarifa.VolumenTasable = this.VolumenTasable.value;
    this.Tarifa.MercanciaContenedor = this.MercanciaContenedor.value;
    this.Tarifa.IdLocalidadOrigen = this.IdLocalidadOrigen.value;
    this.Tarifa.Incoterm = this.Incoterm.value;
    //this.tarifaService.tarifaCotizacion = this.Tarifa;
    this.tarifaService.setTarifa$(this.Tarifa);
  }

  convertFormToTarifa() {
    this.Tarifa.Bultos = this.BultosTotales.value;
    this.Tarifa.IdArea = this.IdArea.value;
    this.Tarifa.IdModoEnvio = this.IdModoEnvio.value;
    this.Tarifa.IdPuertoDestino = this.IdPuertoDestino.value;
    this.Tarifa.IdPuertoOrigen = this.IdPuertoOrigen.value;
    this.Tarifa.Mercancia = this.Mercancia.value;
    this.Tarifa.MercanciaPeligrosa = this.MercanciaPeligrosa.value;
    this.Tarifa.NoRemontable = this.NoRemontable.value;
    this.Tarifa.IdNaviera = this.IdNaviera.value;
    this.Tarifa.PesoBrutoTotal = this.PesoBrutoTotal.value;
    this.Tarifa.PesoBrutoTotalDGR = this.PesoBrutoTotalDGR.value;
    this.Tarifa.PesoNetoTotal = this.PesoNetoTotal.value;
    this.Tarifa.PesoNetoTotalDGR = this.PesoNetoTotalDGR.value;
    this.Tarifa.Cliente = this.Cliente.value;
    this.Tarifa.IdTipoEnvio = this.IdTipoEnvio.value;
    // this.Tarifa.TipoTransito = this.TipoTransito.value;
    this.Tarifa.PesoTasable = this.PesoTasable.value;
    this.Tarifa.VolumenTotal = this.VolumenTotal.value;
    this.Tarifa.VolumenTasable = this.VolumenTasable.value;
    this.Tarifa.MercanciaContenedor = this.MercanciaContenedor.value;
    this.Tarifa.IdLocalidadOrigen = this.IdLocalidadOrigen.value;
    this.Tarifa.Incoterm = this.Incoterm.value;
    //this.tarifaService.tarifaCotizacion = this.Tarifa;
    this.tarifaService.setTarifa$(this.Tarifa);
  }

  mostrarForm(booleano: boolean) {
    this.showListTarifas = booleano
    if (booleano == false) {
      //this.listaMercanciaContenedor = [];
      //this.listaMercancia.length = 0;
      this.inicializar();
      this.volumenTotalReadOnly = false;
      this.pesoBrutoTotalReadonly = false;
    }
  }

  cambiarURL() {
    this.router.navigateByUrl('/customer/tarifas/maritimo/list');
  }

  openModalXL(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalSM(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cancelarTarifa() {
    this.inicializar();
    this.showListTarifas = false;
    this.PesoBrutoTotal.enable();
    this.VolumenTotal.enable();
    this.PesoNetoTotal.enable();
  }

  obtenerRespuesta(respuesta: boolean) {
    if (respuesta)
      this.router.navigateByUrl("/customer");
    this.modalRef.hide();
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if (this.langs.includes(lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

}
