import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Conductor } from 'src/app/models/conductor.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ConductorService } from 'src/app/services/conductor.service';

@Component({
  selector: 'app-borrar-conductor',
  templateUrl: './borrar-conductor.component.html',
  styleUrls: ['./borrar-conductor.component.scss']
})
export class BorrarConductorComponent implements OnInit {

  @Output() cerrarModal = new EventEmitter<number>();
  @Input() conductorSelected: Conductor;
  pantalla: string = "app-borrar-conductor";

  constructor(private conductorService: ConductorService, private toastr: ToastrService, private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  putConductor(click: string){
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.pantalla;
    this.conductorSelected.Estado = false;
    this.conductorService.putConductor(this.conductorSelected, log).subscribe(data => {
      var title = this.translateService.instant("BorradoTitleSuccess");
      var body = this.translateService.instant("BorradoBodySuccess");
      this.toastr.success(body,title);
      this.conductorService.getAllConductores();
      this.cerrarModal.emit(1);
    }, err => {
      var title = this.translateService.instant("BorradoTitleError");
      var body = this.translateService.instant("BorradoBodyError");
      this.toastr.error(body,title)
    })
  }

  cancelar(){
    this.cerrarModal.emit(1);
  }

  getNombreCompleto(){
    if(this.conductorSelected.NIF == null){
      return this.conductorSelected.Nombre + " " + this.conductorSelected.Apellidos + "?";
    }
    return this.conductorSelected.Nombre + " " + this.conductorSelected.Apellidos + " con el NIF: " + this.conductorSelected.NIF + "?";
  }

}
