<!-- [base64Src] para cuando lo reciba del servidor -->
<div *ngIf="base64ArchivoFactura != ''">
    <div *ngIf="totalPDF > 0" class="row marginBottom">
        <div class="col-6">
            <button type="button" class="button allDiv" (click)="anteriorPDF()">
                <mat-icon>navigate_before</mat-icon>
            </button>
        </div>
        <div class="col-6">
            <button type="button" class="button allDiv" (click)="siguientePDF()">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
    <ngx-extended-pdf-viewer [base64Src]="base64ArchivoFactura" useBrowserLocale="true" language="en-GB" [textLayer]="true" height="100vh" [filenameForDownload]="nombrePDF"></ngx-extended-pdf-viewer>
</div> 
