import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ContenedorTarifa } from 'src/app/models/contenedorTarifa';
import { TarifaPost } from 'src/app/models/tarifaPost';
import { TarifaCargoComponent } from '../../tarifa-cargo/tarifa-cargo.component';
import { LogModel } from 'src/app/models/log-model.model';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { MatDialog } from '@angular/material/dialog';
import { TarifaService } from 'src/app/services/tarifaservice.service';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { Monedas } from 'src/app/models/monedas';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';

@Component({
  selector: 'app-listado-tarifas',
  templateUrl: './listado-tarifas.component.html',
  styleUrls: ['./listado-tarifas.component.scss']
})
export class ListadoTarifasComponent implements OnInit {

  @Output() mostrarFormulario: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() listadoTarifas: TarifaPost[] = [];
  @ViewChild('templateResumenTarifa') templateResumenTarifa: TemplateRef<any>;
  @Input() area: number;

  options = ['SI', 'NO'];
  isChecked: boolean;
  suma = 0;
  sum = 0
  totalUSD = 0;
  totalQuebranto = 0;
  porcentajeUSD = 0;
  quebranto = 0;
  listaAux: TarifaPost[] = [];
  totalesUSD = 0;
  textoDivisa = '';
  textoDivisaFuel = '';
  total = 0
  porcentajeQuebranto = 0;
  porcentajeFuel = 0;
  tarifaElegida: TarifaPost;
  modalRef: BsModalRef | null;
  texto: string = "";
  totalInicial = 0;
  valoresIniciales: number[] = [];
  tot = 0;
  totQuebranto = 0;
  totFuel = 0;

  constructor(private route: Router, private translateService: TranslateService, private toastr: ToastrService, private router: Router, private modalService: BsModalService, public dialog: MatDialog,
    private tarifaService: TarifaService, private cotizacionService: CotizacionService) { }

  ngOnInit(): void {
    this.getTotalesGastos();
    console.log(this.listadoTarifas);
    this.listadoTarifas.forEach((tarifa: TarifaPost) => {
      const filteredLineas = tarifa.ListaLineas.filter(linea => linea.EsAdicional === true);
      const hayElementosCumplenCondicion = filteredLineas.length > 0;
      if (hayElementosCumplenCondicion) {
        tarifa.ShowErrorMessage = true;
      } else {
        tarifa.ShowErrorMessage = false
      }
    });
    this.listaAux = this.listadoTarifas;
    this.listaAux.forEach((la => {
      la.ListaLineas.forEach((t => {
        if (t.Gasto != 0 && t.Concepto.includes('QUEBRANTO')) {
          this.porcentajeQuebranto = t.Porcentaje;
          this.textoDivisa = t.Divisa
        }
        if (t.Gasto != 0 && t.Concepto.includes('FUEL')) {
          this.porcentajeFuel = t.Porcentaje;
          this.textoDivisaFuel = t.Divisa
        }
      }))
    }))
  }

  tieneOnRequest(item: TarifaPost) {
    var tieneLineaOnRequest: boolean = false;
    item.ListaLineas.forEach(l => {
      if (l.OnRequest)
        tieneLineaOnRequest = true;
    });
    return tieneLineaOnRequest;
  }

  botonNodespliegaTabla(event: Event) {
    event.stopPropagation();
  }

  getTotalesGastos() {
    this.listadoTarifas.forEach((tarifa: TarifaPost) => {
      tarifa.TotalesTarifa = [];
      tarifa.ListaLineas.forEach((li) => {
        const totalGasto = parseFloat(li.Gasto.toString());
        const divisa = li.Divisa;
        const totalIndex = tarifa.TotalesTarifa.findIndex(t => t.Divisa == divisa);

        if (totalIndex == -1) {
          const totalNuevo: ContenedorTarifa = new ContenedorTarifa();
          totalNuevo.Divisa = divisa;
          totalNuevo.Concepto = "TOTAL";
          totalNuevo.Gasto = totalGasto;
          if (li.EsAdicional == true && li.Adicional != true) {
            totalNuevo.Gasto = 0;
          }
          tarifa.TotalesTarifa.push(totalNuevo);
        } else {
          if (li.EsAdicional == false || li.Adicional == true) {
            tarifa.TotalesTarifa[totalIndex].Gasto += totalGasto;
            tarifa.TotalesTarifa[totalIndex].Gasto = Number((Math.ceil(tarifa.TotalesTarifa[totalIndex].Gasto * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(3));
          }
        }
      });
      this.calcularQuebranto(tarifa);
      this.calcularFuel(tarifa);
    });
  }

  calcularQuebranto(tarifa: TarifaPost) {
    const divisaTarifa = this.textoDivisa;

    if (tarifa.ListaLineas.some(li => li.Porcentaje > 0 && li.Divisa === divisaTarifa && li.Concepto.includes('QUEBRANTO'))) {
      tarifa.TotalesTarifa.forEach((tot) => {
        if (tot.Divisa === divisaTarifa) {
          tot.Gasto = 0;
          tarifa.ListaLineas.forEach((li) => {
            if ((li.Divisa === this.textoDivisa && li.EsAdicional == false) || (li.Divisa === this.textoDivisa && li.Adicional == true)) {
              if (li.Porcentaje == null) {
                var calculoLinea = this.porcentajeQuebranto * li.Gasto;
              }
              else {
                calculoLinea = 0
              }
              this.totQuebranto += calculoLinea;
              this.totQuebranto = Number(this.totQuebranto.toFixed(3));

              if (li.Divisa === this.textoDivisa && li.Porcentaje > 0) {
                li.Gasto = this.totQuebranto;
                this.totQuebranto = 0;
              }
              tot.Gasto += +li.Gasto;
            }
          });
          tot.Gasto = Number((Math.ceil(tot.Gasto * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(3));
        }
      });
    }
  }

  calcularFuel(tarifa: TarifaPost) {
    const divisaTarifa = this.textoDivisaFuel;

    if (tarifa.ListaLineas.some(li => li.Porcentaje > 0 && li.Divisa === divisaTarifa && li.Concepto.includes('FUEL'))) {
      tarifa.TotalesTarifa.forEach((tot) => {
        if (tot.Divisa === divisaTarifa) {
          tot.Gasto = 0;
          tarifa.ListaLineas.forEach((li) => {
            if ((li.Divisa === this.textoDivisaFuel && li.EsAdicional == false) || (li.Divisa === this.textoDivisaFuel && li.Adicional == true)) {
              if (li.Concepto.toLowerCase().includes("portes") || li.Concepto.toLowerCase().includes("recogida") || li.Concepto.toLowerCase().includes("transporte") || li.Concepto.toLowerCase().includes("pick up")
                || li.Concepto.toLowerCase().includes("pick-up") || li.Concepto.toLowerCase().includes("trasporto") || li.Concepto.toLowerCase().includes("transportation")) {
                var calculoLinea = this.porcentajeFuel * li.Gasto;
              }
              else {
                calculoLinea = 0
              }
              this.totFuel += calculoLinea;
              this.totFuel = Number(this.totFuel.toFixed(3));
              if (li.Divisa === this.textoDivisaFuel && li.Porcentaje > 0) {
                li.Gasto = this.totFuel;
                this.totFuel = 0;
              }
              tot.Gasto += +li.Gasto;
            }
          });
          tot.Gasto = Number((Math.ceil(tot.Gasto * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(3));
        }
      });
    }
  }

  postRenovarTarifa(tarifa) {
    //if (event) {
    var logModel: LogModel = new LogModel();
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    this.tarifaService.postRevovación(tarifa.Codigo, logModel).subscribe(data => {
      dialogRef.close();
      this.mostrarMensaje("BodyRenovarTarifa", "TitleRenovarTarifa");
    }, err => {
      dialogRef.close();
    });
  }


  getTotalString(monedas: Monedas[]) {
    var m = "";
    monedas.forEach(element => {
      if (m == "")
        m += element.Total + " " + element.Divisa;
      else
        m += " - " + element.Total + " " + element.Divisa;
    });
    return m;
  }

  mostrarError(bodyInstant: string, titleInstant: string) {
    var body: string = this.translateService.instant(bodyInstant);
    var title: string = this.translateService.instant(titleInstant);
    this.toastr.error(body, title);
  }

  mostrarMensaje(bodyInstant: string, titleInstant: string) {
    var body: string = this.translateService.instant(bodyInstant);
    var title: string = this.translateService.instant(titleInstant);
    this.toastr.success(body, title);
  }


  aceptarTarifa(tarifa: TarifaPost) {
    if (tarifa.ShowErrorMessage) {
      this.mostrarError("BodyRadioTarifaError", "TitleRadioTarifaError");
    }
    else {
      this.tarifaElegida = tarifa;
      this.openModalXL(this.templateResumenTarifa);
    }
  }

  solicitarRenovacion() {
    this.mostrarMensaje("BodyRenovarTarifa", "TitleRenovarTarifa");
  }

  convertirTarifaATarifaEnvio(tarifa: TarifaPost) {
    var t: TarifaEnvio = new TarifaEnvio();
    // PASAR EL FORMULARIO QUE SE LE PASA A LA API, ASÍ TENDREMOS TODOS LOS CAMPOS PARA PONERLO EN COTIZACIÓN
    // t.Aerolinea = tarifa.Aerolinea;
    // t.IdAeropuertoDestino = tarifa.IdAeropuertoDestino;
    // t.IdAeropuertoOrigen = tarifa.IdAeropuertoOrigen;
    // t.Bultos = tarifa.;
  }

  enviarACotizacion(tarifa: TarifaPost) {
    if (this.area == 2) {
      this.modalService.hide();
      this.router.navigate(['customer/cotizaciones/maritimo']);
      this.tarifaService.tarifaCotizacion = tarifa;
    }
    else if (this.area == 3) {
      this.modalService.hide();
      this.router.navigate(['customer/cotizaciones/aereo']);
      this.tarifaService.tarifaCotizacion = tarifa;
    }
  }

  checkAllOptionsSelected2(tarifa: TarifaPost) {
    const allOptionsSelected = tarifa.ListaLineas.every(linea => linea.Adicional);
    tarifa.ShowErrorMessage = !allOptionsSelected;
  }

  comprobarRadiosAdicionales(tarifa: TarifaPost) {
    const filteredLineas = tarifa.ListaLineas.filter(linea => linea.EsAdicional === true);
    const allOptionsSelected = filteredLineas.length === 0 || filteredLineas.every(linea => linea.Adicional != null);
    tarifa.ShowErrorMessage = !allOptionsSelected;
  }


  cancelarTarifa(mostrar: Boolean) {
    this.mostrarFormulario.emit(false)
  }

  openModalXL(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalSM(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

}
