<form [formGroup]="formEmbalaje">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{'TipoEmbalaje'|translate}}</mat-label>
                    <mat-select formControlName="idTipoEmbalaje">
                      <mat-option *ngFor="let tipoEmbalaje of listaTipoMercancias" [value]="tipoEmbalaje.IdTipoEmbalaje">{{tipoEmbalaje.Descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-8">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Embalaje'|translate}}</mat-label>
                    <input class="inputMaterial" formControlName="descripcion" matInput placeholder="Ex. Box" type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Largo'|translate}}(cm)</mat-label>
                    <input class="inputMaterial" formControlName="largo" matInput placeholder="Ex. Box" type="text">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Ancho'|translate}}(cm)</mat-label>
                    <input class="inputMaterial" formControlName="ancho" matInput placeholder="Ex. Box" type="text">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Alto'|translate}}(cm)</mat-label>
                    <input class="inputMaterial" formControlName="alto" matInput placeholder="Ex. Box" type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 margin-bot"></div>
            <div class="col-sm-6 margin-bot">
                <div class="row">
                    <div class="col-sm-6" style="text-align: start;">
                        <button type="button" (click)="limpiarForm()" mat-raised-button>{{'Limpiar' | translate}}</button>
                    </div>
                    <div class="col-sm-6" style="text-align: end;">
                        <button *ngIf="embalaje != null" type="submit" (click)="addOrUpdateEmbalaje()" mat-raised-button>{{'Modificar'|translate}}</button>
                        <button *ngIf="embalaje == null" type="submit" (click)="addOrUpdateEmbalaje()" mat-raised-button>{{'Añadir'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>