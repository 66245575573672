import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-share-tracking',
  templateUrl: './share-tracking.component.html',
  styleUrls: ['./share-tracking.component.scss']
})
export class ShareTrackingComponent implements OnInit {

  @Input() partida: string = "";
  @Input() expedicion: string = "";
  url: string = "";
  @Output() cerrarModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('URL') inputUrl: ElementRef;

  constructor(private shipmentService: ShipmentService, private toast: ToastrService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.shipmentService.getUrlTracking(this.partida, this.expedicion).subscribe(url => {
      this.url = url;
      this.inputUrl.nativeElement.value = this.url;
    }, err => {});
  }

  goBack(){
    this.cerrarModal.emit(true);
  }

  copyText(){
    this.inputUrl.nativeElement.select();
    navigator.clipboard.writeText(this.url);
    var text = this.translate.instant("MensajeCopiadoOk");
    this.toast.info("", text);
  }

}
