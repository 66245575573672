import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { CambiarPassword } from 'src/app/models/cambiarPassword.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Login } from 'src/app/models/login.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mi-perfil-ajustes',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss']
})
export class MiPerfilAjustesComponent implements OnInit {
  clase: string = "app-mi-perfil";
  public formCambiarPassword: FormGroup;
  public isConductor: boolean;
  public stringRepetirContraNueva: string = "password";
  public stringContraNueva: string = "password";
  public stringContraActual: string = "password";
  public get recaptcha() { return this.formCambiarPassword.get('recaptcha') }
  public get passwordActual() { return this.formCambiarPassword.get('passwordActual') }
  public get passwordNueva() { return this.formCambiarPassword.get('passwordNueva') }
  public get passwordRepetirNueva() { return this.formCambiarPassword.get('passwordRepetirNueva') }

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService
    ,private authService: AuthenticationService, private router: Router, private translateService : TranslateService) { }

  ngOnInit(): void {
    this.authService.getTipoUsuario().subscribe(data => {
      if(data == 7){
        this.isConductor = true;
      }else{
        this.isConductor = false;
      }
    });
    
    this.formCambiarPassword = this.formBuilder.group(
      {
        recaptcha: [null, Validators.required],
      passwordActual: ['', [Validators.required, Validators.minLength(6)]],
      passwordNueva: ['', [Validators.required, Validators.minLength(6)]],
      passwordRepetirNueva: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  changePasswordActual(){
    if(this.stringContraActual == "password")
      this.stringContraActual = "text";
    else
      this.stringContraActual = "password";
  }

  changePasswordNueva(){
    if(this.stringContraNueva == "password")
      this.stringContraNueva = "text";
    else
      this.stringContraNueva = "password";
  }

  changePasswordRepNueva(){
    if(this.stringRepetirContraNueva == "password")
      this.stringRepetirContraNueva = "text";
    else
      this.stringRepetirContraNueva = "password";
  }

  public resolved(captchaResponse: string): void {
    this.formCambiarPassword.value.recaptcha = captchaResponse; 
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.formCambiarPassword.value.recaptcha = null; 
  }

  /*public findInvalidControls() {
    const invalid = [];
    const controls = this.formCambiarPassword.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }*/

 /* public cambiarPassword(click: string){
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.clase;
    if(this.formCambiarPassword.valid){
      if(this.passwordNueva.value == this.passwordRepetirNueva.value){
        var cambiarP : CambiarPassword = new CambiarPassword();
        cambiarP.ActualPassword = this.passwordActual.value;
        cambiarP.NuevaPassword = this.passwordNueva.value;
        this.authService.cambioPassword(cambiarP, log).subscribe(data => {
          this.authService.logOut(log).subscribe(data => {
            localStorage.clear();
            this.router.navigate(['/login'])
          });
          this.toastr.success("La contraseña se ha cambiado correctamente, por favor, vuelve a iniciar sesión.", "Contraseña cambiada correctamente")
        }, err => {
          if(err.status == 415){
            this.toastr.error("La contraseña actual no coincide con la contraseña que tenemos en la base de datos.", "Las contaseñas no coinciden");
          }
        })
      }else{
        this.toastr.error("La contraseña nueva como la repetición de esta misma no coinciden.", "Las contaseñas no coinciden");
      }
    }else{
      this.toastr.error("Algún campo no ha sido rellenado correctamente.", "Algún campo ha faltado por rellenar");
    }
  }*/

  public cambiarPassword(click: string){
        var log: LogModel = new LogModel();
        log.Click = click;
        log.Pantalla = this.clase;
        if(this.formCambiarPassword.valid){
          if(this.passwordActual.value != this.passwordNueva.value){
            if(this.passwordNueva.value == this.passwordRepetirNueva.value){
              var cambiarP : CambiarPassword = new CambiarPassword();
              cambiarP.ActualPassword = this.passwordActual.value;
              cambiarP.NuevaPassword = this.passwordNueva.value;
              this.authService.cambioPassword(cambiarP, log).subscribe(data => {
                this.authService.logOut(log).subscribe(data => {
                  localStorage.clear();
                  this.router.navigate(['/login'])
                });
                var body = this.translateService.instant("CambioContraseñaCorrectoBody");
                var title = this.translateService.instant("CambioContraseñaCorrectoTitle");
                this.toastr.success(body, title);
              }, err => {
                if(err.status == 415){
                  var body = this.translateService.instant("CambioContraseñaErrorNoCoincideBody");
                  var title = this.translateService.instant("CambioContraseñaErrorNoCoincideTitle");
                  this.toastr.error(body, title);
                }
              })
            }
            else{
              var body = this.translateService.instant("CambioContraseñaErrorNoCoincideNuevaConAntiguaBody");
              var title = this.translateService.instant("CambioContraseñaErrorNoCoincideNuevaConAntiguaTitle");
              this.toastr.error(body, title);
            }
          }else{
            var body = this.translateService.instant("ContraseñaViejaCoincideConNuevaBody");
            var title = this.translateService.instant("ContraseñaViejaCoincideConNuevaTitle");
            this.toastr.error(body, title);
          }
          
        }else{
          var body = this.translateService.instant("CambioContraseñaErrorGenericoBody");
          var title = this.translateService.instant("CambioContraseñaErrorErrorGenericoTitle");
          this.toastr.error(body, title);
        }
      }

}
