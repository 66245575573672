import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Archivo } from '../models/archivo.model';
import { Expedition } from '../models/expedition.model';
import { FacturasListado } from '../models/facturasListado.model';
import { LogModel } from '../models/log-model.model';
import { Matricula } from '../models/matricula.model';
import { Perfil } from '../models/perfil.model';
import { VistaProveedor } from '../models/vistaProveedor.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  readonly apiUrlService = environment.apiUrl;
  base64: string = "";
  facturacionCliente: Subject<FacturasListado[]> = new Subject();

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getFacturasCliente$(){
    return this.facturacionCliente.asObservable();
  }

  getFacturasCliente(idArea){
    const url_api = this.apiUrlService + "facturacion/" + idArea;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as FacturasListado[]));
  }

  getFactura(numFactura: string){
    const url_api = this.apiUrlService + "facturacion/detalles/" + numFactura;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Archivo[]));
  }


  getFacturasClienteExcel(fechaInicio, fechaFinal, idioma, log: LogModel, areas: string[]){
    const url_api = this.apiUrlService + "facturacion/informe/excel";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    });
    return this.http.post(url_api, JSON.stringify({
      Log: log,
      Body: JSON.stringify({
        FechaInicio: fechaInicio,
        FechaFinal: fechaFinal,
        Idioma: idioma,
        Areas: areas}).toString()
    }), { headers: headersToken } )
    .pipe(map(data => data as Archivo));
  }

  getZipFacturas(listadoFacturas: string[], contador: number, log: LogModel){
    const url_api = this.apiUrlService + "facturacion/obtenerZip/" + contador;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    });
    return this.http.post(url_api, JSON.stringify({
      Log: log,
      Body: JSON.stringify({
        listadoFacturas: listadoFacturas}).toString()
    }), { headers: headersToken } )
    .pipe(map(data => data as Archivo));
  }
}
