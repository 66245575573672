<div class="container" *ngIf="lista != null">
    <div *ngFor="let i of ArrayFilas()" class="row align-items-center" style="margin-top: 5%;">
        <div *ngFor="let j of ArrayElementos(i)" class="col-sm-6 col-lg-2 col-xl-2">
            <span *ngIf="lista[j]">
                <div class="row d-flex justify-content-center">
                    <img [routerLink]="['../../conductor/historial/' + lista[j].IdDocumento +'/download']" src="assets/iconos/pdficono.ico" width="25%">
                </div>
                <div class="row d-flex justify-content-center" style="margin-top: 5%;">
                    <a target="_blank" [routerLink]="['../../conductor/historial/' + lista[j].IdDocumento +'/download']"
                    style="margin-bottom: 2%; margin-right: 2%;">{{lista[j].FileName}}</a>
                </div>
            </span>
        </div>
    </div>
</div>