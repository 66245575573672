import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DestinatarioRemitente } from 'src/app/models/destinatarioRemitente.model';
import { Expedition } from 'src/app/models/expedition.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';

@Component({
  selector: 'app-subir-pendientes',
  templateUrl: './subir-pendientes.component.html',
  styleUrls: ['./subir-pendientes.component.scss']
})
export class SubirPendientesComponent implements OnInit {

  clase: string = "app-subir-pendientes";
  texto: string = ""
  @Input() remitente : DestinatarioRemitente;
  @Input() expedicionesPendiente: Expedition[];
  expedicionesPendientesRemitete: Expedition[];
  selectedAll: boolean = false;
  @Output() verificacionConjunta = new EventEmitter<[boolean, string]>()
  seleccionados: Expedition[] = [];
  seleccionadosString: string[] = [];
  estados: string[] = ["Pendiente", "Recogida", "Entregado"];
  displayedColumns: string[] = ['check', 'SerieReferencia', 'Expedicion', 'FechaSalida'];

  constructor(private toastr: ToastrService, private expeditionService: ExpeditionApiService,
    private translateService: TranslateService ) { }

  ngOnInit(): void {
    this.expedicionesPendientesRemitete = this.remitente.Expediciones;
  }
  //
  seleccionarTodos(event){
    this.remitente.Expediciones.forEach(e => e.Checked = event.checked);
    if(event.checked){ // Todos seleccionados
      this.texto = "";
      this.remitente.Expediciones.forEach(exp => {
        if(exp.Checked){
          this.seleccionados.push(exp);
          this.texto += " " + exp.Expedicion;
        }else{
          this.seleccionados.splice(this.seleccionados.findIndex(e => e.Expedicion == exp.Expedicion), 1);
        }
      })
    }else{ // Todos deseleccionados
      this.texto = "";
    }
  }

  setSeleccionado(expedicion: Expedition){
    this.texto = "";
    this.remitente.Expediciones.forEach(exp => {
      if(exp.Expedicion == expedicion.Expedicion){
        if(!exp.Checked || exp.Checked == undefined){
          this.texto += " " + exp.Expedicion;
          this.seleccionados.push(exp);
        }
      }else{
        if(exp.Checked){
          this.texto += " " + exp.Expedicion;
          this.seleccionados.splice(this.seleccionados.findIndex(e => e.Expedicion == exp.Expedicion), 1);
        }
      }
    })
    this.selectedAll = this.comprobarTodos();
  }

/*comprobarTodosSelected(): boolean{
    var resultado : boolean = true;
    this.listaExpediciones.forEach(e => {
      if(e.checked == false || e.checked == undefined){
        resultado = false;
      }})
      return resultado;
  }*/

  comprobarTodos(): boolean{
    var todosCheck: boolean = true;
    this.remitente.Expediciones.forEach(e => {
      if(e.Checked == false || e.Checked == undefined){
        todosCheck = false;
      }
    })
    return todosCheck;
  }

  subirPendientes(click: string){
    this.getSeleccionados();
    if(!this.comprobarFechas()){
      var log: LogModel = new LogModel();
      log.Pantalla = this.clase;
      log.Click = click;
      this.seleccionados.forEach(s => this.seleccionadosString.push(s.Numero.toString()))
      this.expeditionService.cambiarEstadoConjunto(this.seleccionadosString, log).subscribe(data => {
        this.verificacionConjunta.emit([true, this.translateService.instant("Se ha cambiado el estado de las expeidiciones seleccionadas perfectamente.")]);
      }, _ => this.verificacionConjunta.emit([false, this.translateService.instant("Ha existido un error al cambiar el estado a las expediciones conjuntas.")]));//, "Error cambio estado"));
    }else{
      this.toastr.error(this.translateService.instant("Las expediciones seleccionadas no tienen la misma fecha de recogida. Debes de seleccionar expediciones con la misma fecha."), this.translateService.instant("Error fecha expediciones"))
    }
  }

    // Saber siguiente estado
    siguienteEstado(estado: string) {
      var nextEstado = ""
      if (estado === this.estados[1]) {
        nextEstado = this.estados[2]
      } else if (estado === this.estados[0] || !estado) {
        nextEstado = this.estados[1]
      }
      return nextEstado
    }

  getSeleccionados(){
    this.seleccionados = [];
    //this.texto = " ";
    this.expedicionesPendiente.forEach(e => {
      if(e.Checked){
        this.seleccionados.push(e);
        //this.texto += " " + this.expedicionesPendiente;
      }
        
    })
  }

  comprobarFechas() : boolean{
    var fecha: string = "";
    var error = false;
    this.seleccionados.forEach(e => {
      if(fecha == null || fecha == undefined || fecha == "")
        fecha = e.FechaSalida
      else{
        if(fecha != e.FechaSalida)
          error = true;
      }
    })
    return error;
  }

}
