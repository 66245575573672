import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { NavigationEnd, Router } from '@angular/router';
import { Expedition } from 'src/app/models/expedition.model';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Archivo } from 'src/app/models/archivo.model';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { ViewChild } from '@angular/core';
import { DestinatarioRemitente } from 'src/app/models/destinatarioRemitente.model';
import { HojaRuta } from 'src/app/models/hojaRuta.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-conductor',
  templateUrl: './conductor.component.html',
  styleUrls: ['./conductor.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class ConductorComponent implements OnInit, OnDestroy {

  @ViewChild('templateCambiarPasswordPrimeraVez', {read: TemplateRef}) cambiarPasswordPrimeraVez: TemplateRef<any>;
  @ViewChild('templateCambiarPasswordEsCaducada', {read: TemplateRef}) cambiarPasswordEsCaducada: TemplateRef<any>;
  listExpedition: Expedition[]
  hrSelected: HojaRuta;
  @Input() abrir: boolean;
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  documentos: Observable<Archivo[]>
  idExpedicion: number;
  expedicion: string = "";
  estadoString: string = ""
  listExp
  total: number[] = [0,0,0,0];
  pageActual: number[] = [1,1,1,1];
  sizePage: number[] = [7,7,7,7];
  estados: string[] = ["Pendiente", "Recogida", "Entregado", "Entregado a falta de albarán"];
  isMovil: Boolean;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  panelOpenState: boolean;
  pendientesRecogida: Expedition[] = []
  pendientesEntrega: Expedition[] = []
  pendientesAlbaran: Expedition[] = []
  remitenteSelected: DestinatarioRemitente;
  remitentes : string[] = []
  destinatarios : string[] = []
  destinatariosAlbaran : string[] = []
  destinatarioRemitentePendientesRecogida: DestinatarioRemitente[] = [];
  openStateRecogida: boolean[] = [];
  isProveedor: boolean = true;
  destinatarioRemitentePendientesEntrega: DestinatarioRemitente[] = [];
  destinatarioRemitentePendientesEntregaAlbaran: DestinatarioRemitente[] = [];
  openStateEntrega: boolean[] = [];
  hojasRuta: HojaRuta[] = [];
  title = 'angular-9-i18n';
  langs = ['es', 'en'];

  
  constructor(private expService: ExpeditionApiService,
     private route: Router, public dialog: MatDialog,
     private toastr: ToastrService, private authenticationService: AuthenticationService,
     private modalService: BsModalService, private translateService: TranslateService) {
      this.esPrimeraVez();
   }

   esPrimeraVez(){
    this.route.events.subscribe((r: NavigationEnd) => {
      if(r instanceof NavigationEnd)
        this.authenticationService.esPrimeraVez().subscribe(isPrimeraVez => {
          if(isPrimeraVez)
            this.openModalPrimeraVez(this.cambiarPasswordPrimeraVez);
          else
            this.esCaducada();
        });
    });
  }

  esCaducada(){
    this.authenticationService.esCaducada().subscribe(isCaducada => {
      if(isCaducada)
        this.openModalPrimeraVez(this.cambiarPasswordEsCaducada);
    });
  }

  cerrarModal(event){
    this.modalRef.hide();
  }

   cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if(this.langs.includes(lang))
    {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else
    {
     this.translateService.setDefaultLang('es');
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  openModalPrimeraVez(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    }
    openModal2(template: TemplateRef<any>) {
      this.modalRef2 = this.modalService.show(template, {id: 2, class: 'second' });
    }
    closeFirstModal() {
      if (!this.modalRef) {
        return;
      }
  
      this.modalRef.hide();
      this.modalRef = null;
    }

    cerrar(event){
      this.modalRef.hide();
    }

    closeModal(modalId?: number){
      this.modalRef.hide();
      //this.modalService.hide(modalId);
    }
  
  openToastConjunto(event){
    if(event[0]){
      this.toastr.success(event[1], this.translateService.instant("Subido correctamente"));
      this.panelOpenState = false;
      this.inicializar();
    }else{
      this.toastr.error(event[1], this.translateService.instant("Subido erróneamente"));
      this.panelOpenState = false;
      this.inicializar();
    }
    this.cerrar(event);
  }


  openToast(event){
    if(event[0]){
      this.panelOpenState = false;
      this.inicializar();
    }else{
      this.panelOpenState = false;
      this.inicializar();
    }
  }

  openToastOutput(event){
    if(event[0]){
      this.panelOpenState = false;
      this.inicializar();
    }else{
      this.panelOpenState = false;
      this.inicializar();
    }
  }

  getDestinatarios(){
    this.destinatarios = []
    this.destinatarioRemitentePendientesEntrega = []
    this.pendientesEntrega.forEach(exp => {
      if(this.destinatarios.length > 0){
        var destinatario = this.destinatarios.find(e => e == exp.Destinatario);
        if(destinatario == undefined){ // No existe ese remitente
          this.destinatarios.push(exp.Destinatario)
          var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Destinatario;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitentePendientesEntrega.push(dr);
          this.openStateEntrega.push(false);
        }else{ // Ya existe el remitente
          var dr = this.destinatarioRemitentePendientesEntrega.find(e => e.DestinatarioRemitente == exp.Destinatario);
          dr.Expediciones.push(exp);
          //this.destinatarioRemitentePendientesRecogida.push(dr);
        }
      }else{ // Es el primero que entra
        var dr: DestinatarioRemitente = new DestinatarioRemitente();
        dr.DestinatarioRemitente = exp.Destinatario;
        dr.Expediciones = [];
        dr.Expediciones.push(exp);
        this.destinatarioRemitentePendientesEntrega.push(dr);
        this.destinatarios.push(exp.Destinatario)
        this.openStateEntrega.push(false);
      }
    })
    if(this.destinatarioRemitentePendientesEntrega.length == 1){
      this.panelOpenState = true;
    }
    this.total[1] = this.destinatarioRemitentePendientesEntrega.length;
    if(this.openStateEntrega.length == 1){
      this.openStateEntrega[0] = true;
    }
  }

  getDestinatariosPendienteAlbaran(){
    this.destinatariosAlbaran = []
    this.destinatarioRemitentePendientesEntregaAlbaran = []
    this.pendientesAlbaran.forEach(exp => {
      if(this.destinatariosAlbaran.length > 0){
        var destinatario = this.destinatariosAlbaran.find(e => e == exp.Destinatario);
        if(destinatario == undefined){ // No existe ese remitente
          this.destinatariosAlbaran.push(exp.Destinatario)
          var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Destinatario;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitentePendientesEntregaAlbaran.push(dr);
          this.openStateEntrega.push(false);
        }else{ // Ya existe el remitente
          var dr = this.destinatarioRemitentePendientesEntregaAlbaran.find(e => e.DestinatarioRemitente == exp.Destinatario);
          dr.Expediciones.push(exp);
          //this.destinatarioRemitentePendientesRecogida.push(dr);
        }
      }else{ // Es el primero que entra
        var dr: DestinatarioRemitente = new DestinatarioRemitente();
        dr.DestinatarioRemitente = exp.Destinatario;
        dr.Expediciones = [];
        dr.Expediciones.push(exp);
        this.destinatarioRemitentePendientesEntregaAlbaran.push(dr);
        this.destinatariosAlbaran.push(exp.Destinatario)
        this.openStateEntrega.push(false);
      }
    })
    if(this.destinatarioRemitentePendientesEntregaAlbaran.length == 1){
      this.panelOpenState = true;
    }
    this.total[3] = this.destinatarioRemitentePendientesEntregaAlbaran.length;
    if(this.openStateEntrega.length == 1)
      this.openStateEntrega[0] = true;
    
  }

  getRemitentes(){
    this.remitentes = []
    this.destinatarioRemitentePendientesRecogida = []
    this.pendientesRecogida.forEach(exp => {
      if(this.remitentes.length > 0){
        var remitente = this.remitentes.find(e => e == exp.Remitente);
        if(remitente == undefined){ // No existe ese remitente
          this.remitentes.push(exp.Remitente)
          var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Remitente;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitentePendientesRecogida.push(dr);
          this.openStateRecogida.push(false);
        }else{ // Ya existe el remitente
          var dr = this.destinatarioRemitentePendientesRecogida.find(e => e.DestinatarioRemitente == exp.Remitente);
          dr.Expediciones.push(exp);
          //this.destinatarioRemitentePendientesRecogida.push(dr);
        }
      }else{ // Es el primero que entra
        var dr: DestinatarioRemitente = new DestinatarioRemitente();
        dr.DestinatarioRemitente = exp.Remitente;
        dr.Expediciones = [];
        dr.Expediciones.push(exp);
        this.destinatarioRemitentePendientesRecogida.push(dr);
        this.openStateRecogida.push(false);
        this.remitentes.push(exp.Remitente)
      }
    })
    this.total[0] = this.destinatarioRemitentePendientesRecogida.length
    if(this.openStateRecogida.length == 1){
      this.openStateRecogida[0] = true;
    }
  }

  getPendientesEntrega(){
    this.pendientesEntrega = []
    this.listExpedition.forEach(exp => {
      if(exp.Estado == "Recogida"){
        this.pendientesEntrega.push(exp);
      }
    })
  }

  getPendientesAlbaran(){
    this.pendientesAlbaran = [];
    this.listExpedition.forEach(exp => {
      if(exp.Estado == "Entregado a falta de albarán")
        this.pendientesAlbaran.push(exp);
    });
  }

  getPendientesRecogida(){
    this.pendientesRecogida = []
    this.listExpedition.forEach(exp => {
      if(exp.Estado == "Pendiente" || exp.Estado == null){
        this.pendientesRecogida.push(exp);
      }
    })
  }

  actualizarLista(){
    for(var i = 0; i < this.listExpedition.length; i++){
      var e = this.listExpedition[i];
      for(var j = 0; j < this.hojasRuta.length; j++){
        for(var k = 0; k < this.hojasRuta[j].Expediciones.length; k++){
          var exp = this.hojasRuta[j].Expediciones[k];
          if(e.Expedicion == exp.Expedicion){ // Se encuentra la expedición en alguna de las hojas de ruta
            this.listExpedition.splice(i, 1);
            i--;
          }
        }
      }
    }
    this.total[2] = this.hojasRuta.length;
  }

  suscripcion: Subscription[] = [];
  ngOnDestroy(){
    this.suscripcion.forEach(s => s.unsubscribe());
  }

  inicializar(){
    this.suscripcion.push(this.expService.getExpedicionesPedientes().subscribe(data => {
      this.listExpedition = data as Expedition[];
      this.suscripcion.push(this.expService.getHojasDeRuta().subscribe(data => {
        this.hojasRuta = data as HojaRuta[];
        this.actualizarLista();
          this.getPendientesEntrega();
          this.getPendientesRecogida();
          this.getPendientesAlbaran();
          this.getDestinatarios();
          this.getRemitentes();
          this.getDestinatariosPendienteAlbaran();
      }, err => {
        var errorLogin = this.translateService.instant('Fallo al cargar las hojas de ruta, contacte con el dep. terrestre.');
        var errorTitulo = this.translateService.instant('Error');
        this.toastr.error(errorLogin, errorTitulo)
      }));
    }, err => {
      var errorLogin = this.translateService.instant('Fallo al cargar las expediciones, contacte con el dep. terrestre.');
      var errorTitulo = this.translateService.instant('Error');
      this.toastr.error(errorLogin, errorTitulo)
    }));
    
  }

  ngOnInit() {
    this.inicializar();    
  } 

}
