import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-actualizar-factura',
  templateUrl: './actualizar-factura.component.html',
  styleUrls: ['./actualizar-factura.component.scss']
})
export class ActualizarFacturaComponent implements OnInit {

  @Output() confirmacionActualizarFactura = new EventEmitter<boolean>();
  @Input() texto: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  enviarConfirmacion(resultado: boolean){
    this.confirmacionActualizarFactura.emit(resultado);
  }

}
