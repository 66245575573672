<div class="modal-content fondo">
    <div class="modal-header fondo">
          <div class="col">
              <div class="row">
                  <div class="col-10">
                    <h2 class="modal-title pull-left" *ngIf="aceptar">
                        {{ "AceptarCotizacionTitle" | translate }}
                      </h2>
                    <h2 class="modal-title pull-left" *ngIf="!aceptar">
                        {{ "RechazarCotizacionTitle" | translate }}
                    </h2>
                  </div>
                  <div class="col-2">
                      <button type="button" class="close pull-right" aria-label="Close" (click)="cerrar(true)">
                          <span aria-hidden="true">&times;</span>
                        </button>
                  </div>
              </div>
          </div>
    </div>
    <div class="modal-body fondo" *ngIf="aceptar">
    <!-- Quiere aceptar la cotización :) -->
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <app-detalles-cotizacion (close)="cerrar($event)" [cotizacion]="cotizacion" [conModel]="false"></app-detalles-cotizacion>
                </div>
            </div>
            <div *ngIf="esCompleted()" class="row ml7coma3">
                <div class="col-12">
                    <mat-checkbox class="example-margin" [(ngModel)]="checked">{{"CheckedCondiciones" | translate}}</mat-checkbox>
                </div>
            </div>
            <div *ngIf="esCompleted()" class="row ml7coma3">
                <div class="col-4">
                    <button type="button" class="btn" id="aceptado-con-booking" [disabled]="!checked" (click)="hacerBooking(true);">{{"AceptarBooking" | translate}}</button>
                </div>
                <div class="col-4">
                    <button type="button" class="btn buttonCancelar" (click)="cerrar(true)">{{"Cancelar" | translate }}</button>
                </div>
                <!-- <div class="col-4">
                    <button type="button" class="btn" id="aceptado-sin-booking" [disabled]="!checked" (click)="cambiarEstadoAceppted()">Si y luego realizar booking</button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="modal-body fondo" *ngIf="!aceptar">
    <!-- Quiere rechazar la cotización :( -->
        <div class="col-12">
            <div class="row">
                <div class="col-12">{{"RechazarCotizacion" | translate}}</div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ng-select name="CausaLoss" id="CausaLoss" class="select-small" #Contenedor="ngModel" appendTo="body"
                    [items]="causasLossListado" bindLabel="causa" bindValue="codigo"
                    [(ngModel)]="causaLoss" style="width: 300px;">
                    </ng-select>
                </div>
            </div>
            <div class="row" *ngIf="causaLoss == 10">
                <div class="col-6" id="textarea">
                    <textarea class="form-control-custom" name="" id="textOthers" cols="30" rows="4" [(ngModel)]="textoOtros"></textarea>
                </div>
            </div>
            <div class="row" class="botones">
                <div class="col-12">
                    <button type="button" id="rechazar-cotizacion" class="btn" (click)="cambiarEstadoDeclined()">{{"Rechazar" | translate }}</button>
                    <button type="button" class="btn buttonCancelar" (click)="cerrar(true)">{{"Cancelar" | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>