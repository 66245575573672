<table class="table table-sm table-bordered table-hover table-editable table-row-action edit-action" >
    <thead>
        <th (click)="sort('Fecha')">{{'Fecha'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Fecha'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>
        <th (click)="sort('Resolucion')">{{'Resolucion'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Resolucion'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>
        <th (click)="sort('Descripcion')">{{'Descripcion'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Descripcion'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>
        <th (click)="sort('Resultado')">{{'Resultado'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Resultado'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>
        <th (click)="sort('Cerrada')">{{'Cerrada'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Cerrada'" [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
        </th>
    </thead>
    <tbody>
        <ng-container *ngFor="let incidencia of listIncidencias">
            <tr>
                <td style="cursor: pointer; vertical-align: middle;">{{incidencia.FechaRegistro}}</td>
                <td style="cursor: pointer; vertical-align: middle;">{{incidencia.FechaResolucion}}</td>
                <td style="cursor: pointer; vertical-align: middle;">{{incidencia.Descripcion}}</td>
                <td style="cursor: pointer; vertical-align: middle;">{{incidencia.Resultado}}</td>
                <td *ngIf="incidencia.Cerrada" style="vertical-align: middle;"><mat-icon class="green-icon">check_circle_outline</mat-icon></td>
                <td *ngIf="!incidencia.Cerrada" style="vertical-align: middle;"><mat-icon>minimize</mat-icon></td>
            </tr>
        </ng-container>
    </tbody>
</table>