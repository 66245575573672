export class Conductor {
    IdConductor?: number;
    Apellidos?: String;
    Nombre?: String;
    NIF?: String;
    Estado?: Boolean;
    Movil?: String;
    Extension?: String;
    Email?: String;
    Email1Tlf1?: String;
    Entidad?: String;
    Foto?: Blob;
    Propio?: Boolean;
    NumSocio: number;
    Contraseña?: String;
    IdOrigen?: number;
    IdDelegacion?: number;
}
