<div *ngIf="isHistorial" class="row">
    <div class="col" style="text-align: left;">
        <p>{{'Seleccione una Expedición si la Factura solo es de una Expedición.'|translate}}</p>
    </div>
</div>
<div *ngIf="isHistorial" class="row">
    <div class="col" style="text-align: left;">
        <p>{{'Seleccione varias Expediciones si quiere enviar una Factura Agrupada.'|translate}}</p>
    </div>
</div>
<div class="row">
<table mat-table [dataSource]="listaExpediciones" multiTemplateDataRows class="mat-elevation-z8">
    <div class="row">
        <div class="col-sm-12">
            <button class="btn" type="button" (click)="seleccionarTodos();">
                {{'Seleccionar todos'|translate}}
            </button>
        </div>
    </div>
    <!-- CheckBox Column -->
    <ng-container *ngIf="isHistorial && isProveedor" matColumnDef="checkBox">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox name="SelectAll" (click)="seleccionarTodos();" [(ngModel)]="selectedAll" #SelectAll></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-checkbox [(ngModel)]="element.checked"
            (click)="setExpedicionesToFactura(element.Expedicion)"></mat-checkbox>
        </td>
    </ng-container>

    <!-- Icon Column -->
    <ng-container matColumnDef=" ">
        <th mat-header-cell *matHeaderCellDef>
            
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="expandedElement != null && expandedElement.IdExpedicion == element.IdExpedicion">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement == null || expandedElement.IdExpedicion != element.IdExpedicion">keyboard_arrow_up</mat-icon>
        </td>
    </ng-container>

    <!-- Referencia Column -->
    <ng-container matColumnDef="referencia">
      <th mat-header-cell *matHeaderCellDef>
        {{'Referencia'|translate}} 
      </th>
      <td mat-cell *matCellDef="let element" 
      (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> {{element.IdSerieReferencia}} </td>
    </ng-container>
  
    <!-- Expedicion Column -->
    <ng-container matColumnDef="expedicion">
      <th mat-header-cell *matHeaderCellDef> {{'Expedicion'|translate}} </th>
      <td mat-cell *matCellDef="let element"
      (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> {{element.Expedicion}} </td>
    </ng-container>
  
    <!-- Fecha Column -->
    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef> {{'Fecha'|translate}} </th>
      <td mat-cell *matCellDef="let element"
      (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> {{element.FechaSalida}} </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="getColumnas().length">
            <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="container fondo mat-elevation-z2" >
                    <div class="row titulo">
                        <p class="h4">{{'Información remitente'|translate}}</p>
                    </div>
                    <div class="row">
                        <p>{{expedicion.Remitente}}</p>
                    </div>
                    <div class="row">
                        <p>{{montarDireccion(expedicion.DireccionRemitente, expedicion.CodigoPostalRemitente, expedicion.PoblacionRemitente)}}</p>
                    </div>
                    <div class="row">
                        <p>{{expedicion.TelefonoRemitente}}</p>
                    </div>
                    <div class="row">
                        <p>{{expedicion.Bultos}} {{'bultos'|translate}} / {{expedicion.Peso}} Kg / {{'Metros lineales'|translate}}</p> <!-- {{expedicion.MetrosLineales}}-->
                    </div>
                    <div class="row" *ngIf="expedicion.RecogidaFechaTracking == null">
                        <p><b>{{'Fecha recogida'|translate}}: {{toStringRecogida(expedicion.FechaSalida)}}</b></p>
                    </div>
                    <div *ngIf="expedicion.RecogidaFechaTracking != null" class="row">
                        <p><b>{{'Recogido el'|translate}} {{expedicion.RecogidaFechaTracking}}</b></p>
                    </div>
                    <div class="row titulo" *ngIf="muestraDestinatario(expedicion)">
                        <p class="h4">{{'Información destinatario'|translate}}</p>
                    </div>
                    <div class="row" *ngIf="muestraDestinatario(expedicion)">
                        <p>{{expedicion.Destinatario}}</p>
                    </div>
                    <div class="row" *ngIf="muestraDestinatario(expedicion)">
                        <p>{{montarDireccion(expedicion.DireccionDestinatario, expedicion.CodigoPostalDestinatario, element.PoblacionDestinatario)}}</p>
                    </div>
                    <div class="row" *ngIf="muestraDestinatario(expedicion)">
                        <p>{{expedicion.TelefonoDestinatario}}</p>
                    </div>
                    <div class="row" *ngIf="expedicion.EntregaFechaTracking == null && muestraDestinatario(expedicion)">
                        <p><b>{{'Fecha entrega'|translate}}: {{toStringEntrega(expedicion.FechaLlegada)}}</b></p>
                    </div>
                    <div *ngIf="expedicion.EntregaFechaTracking != null && muestraDestinatario(expedicion)" class="row">
                        <p><b>{{'Entregado el'|translate}} {{expedicion.EntregaFechaTracking}}</b></p>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <button *ngIf="expedicion.Estado == estados[0]" class="btn" type="button"
                                        (click)="openModal(templateCambiarEstado)"
                                        style="margin-bottom: 3%;">{{'Confirmar Recogida'|translate}}</button>
                            <button *ngIf="expedicion.Estado == estados[1]" class="btn" type="button"
                                        (click)="openModal(templateCambiarEstado)"
                                        style="margin-bottom: 3%;">{{'Confirmar Entrega'|translate}}</button>
                            <button *ngIf="expedicion.Estado == estados[3]" class="btn" type="button"
                                        (click)="openModal(templateCambiarEstado)"
                                        style="margin-bottom: 3%;">{{'EntregarAlbaranButton'|translate}}</button>
                        </div>
                        <div class="col-sm-2"></div>
                        <div class="col-sm-2"></div>
                        <div class="col-sm-2">
                            <button class="btn" type="button"
                                        (click)="documentosExpedition();openModal(templateDocumentos);"
                                        [ngStyle]="{'float:right;': !isMovil}"
                                        style="margin-bottom: 3%;">{{'Documentos'|translate}}</button>
                        </div>
                        <div class="col-sm-2">
                            <button class="btn" (click)="openModal(template)" type="button" [ngStyle]="{'float:right;': !isMovil}"
                                            style="margin-bottom: 3%;">{{'Incidencias'|translate}}</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="getColumnas()"></tr>
    <tr mat-row *matRowDef="let element; columns: getColumnas();"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

<!-- The Modal Listado Descargas -->
<ng-template #templateDocumentos>
    <!-- Modal header -->
    <div class="modal-header fondo" style="border-color: black;">
        <p class="h4 modal-title">{{'Documentos de'|translate}} {{expedicion.Expedicion}}</p>
        <button type="button" class="close" (click)="closeModal()" data-dismiss="modal">&times;</button>
    </div>
    <!-- Modal body -->
    <div class="modal-body fondo">
        <app-modal-download [lista]="documentos"></app-modal-download>
    </div>
</ng-template>
<!--<div class="modal fade" id="viewDownload">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
         <div class="modal-header fondo" style="border-color: black;">
            <p class="h4 modal-title">Documentos de {{expedicion.Expedicion}}</p>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body fondo">
            <app-modal-download [lista]="documentos"></app-modal-download>
        </div>   
        </div>
    </div>
</div>-->

<!-- The Modal Cambiar Estado -->
<!--  -->
<ng-template #templateCambiarEstado>
        <app-model-estado (cerrarModal)="cerrar($event)" (verificacion)="openToast($event)"  [errorDni]="false" [submited]="false" [error]="false" [showWebcam]="true" [fotoRealizada]="false" [estado]="expedicion.Estado"
                [idExpedicion]="expedicion.Expedicion" [numero]="expedicion.Numero" [errorFirma]="false"></app-model-estado>
</ng-template>
<!--
  <div class="row fondo">
    <div class="col-12 col-sm-12">
      <div class="modal-header">
        <p class="h4 modal-title">Cambiar estado de {{idExpedicion}}</p>
        <button type="button" class="close" (click)="inicializar()" data-dismiss="modal">&times;</button>
      </div>
    </div>
  </div>
    <div class="modal fade" id="cambiarEstado">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <app-model-estado (verificacion)="openToast($event)" [errorDni]="false" [submited]="false" [error]="false" [showWebcam]="true" [fotoRealizada]="false" [estado]="expedicion.Estado"
                [idExpedicion]="expedicion.Expedicion" [errorFirma]="false"></app-model-estado>
        </div>
    </div>
</div>-->

<!-- The Modal Incidencias-->
<ng-template #template>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Incidencias de'|translate}} {{expedicion.Expedicion}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-incidencias (cerrarModal)="cerrar($event)" [historico]="false" [expedicion]="expedicion.Expedicion" [idExpedicion]="expedicion.IdExpedicion"></app-incidencias>
    </div>
</ng-template>

<!-- The Modal Subir Factura-->
<ng-template #templateSubirFactura>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir facturas para'|translate}} {{expedicion.Expedicion}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-factura (cerrarModal)="cerrar($event)" [Expedicion]="expedicion.Expedicion" [idExpedicion]="expedicion.IdExpedicion"></app-subir-factura>
    </div>
</ng-template>

<!-- The Modal Subir Factura Conjunta-->
<ng-template #templateSubirFacturaConjunta>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir facturas conjuntas'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-factura (cerrarModal)="cerrar($event)" [idExpedicion]="null" [Expediciones]="expedicionesFactura"></app-subir-factura>
    </div>
</ng-template>