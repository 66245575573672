import { Component, OnInit, SimpleChange } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ShipmentService } from 'src/app/services/shipment.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LogModel } from 'src/app/models/log-model.model';
import { EntityService } from 'src/app/services/entity.service';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { CotizacionService } from 'src/app/services/cotizacion.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clase: string = "app-header";
  nomUser: string;
  token: string;
  tipoUser: string;
  path: string;
  imagePath: SafeResourceUrl;
  mostrar: boolean;
  privilegios;
  privilegioTodo;
  privilegioFacturacion;
  privilegioBooking;
  privilegioTracking;
  privilegioCotizacion;
  privilegioTarifa;
  corresponsal: Corresponsal;
  isLoad: boolean = false;
  logo: string = "";
  mostrarFacturacion: boolean = false;

  constructor(private envioService: ShipmentService, private router: Router, private entidadService: EntityService,
    private toastr: ToastrService, private authenticationService: AuthenticationService, private cotizacionService: CotizacionService,
    private _sanitizer: DomSanitizer, private translateService: TranslateService) { }

  async ngOnInit() {
    this.nomUser = localStorage.getItem("nombre");
    this.token = localStorage.getItem("userToken");
    this.tipoUser = localStorage.getItem("tipoUser");
    this.path = this.router.url;
    this.authenticationService.getLogo().subscribe(logo => {
      if (logo == undefined || logo == null || logo == "")
        this.mostrar = false;
      else
        this.mostrar = true;
      this.logo = logo as string;
    }, error => {
      this.mostrar = false;
    });
    this.entidadService.getCorresponsal().subscribe(corresponsal => {
      this.corresponsal = corresponsal;
      this.mostrarFacturacion = true;
    }, error => { this.mostrarFacturacion = false; });
    this.privilegios = await this.authenticationService.getPrivilegiosUsuario().toPromise();
    this.privilegioTodo = await (await this.authenticationService.getPrivilegio("Todo").toPromise()).toString();
    this.privilegioFacturacion = await (await this.authenticationService.getPrivilegio("Facturacion").toPromise()).toString();
    this.privilegioBooking = await (await this.authenticationService.getPrivilegio("Booking").toPromise()).toString();
    this.privilegioTracking = await (await this.authenticationService.getPrivilegio("Tracking").toPromise()).toString();
    this.privilegioCotizacion = await (await this.authenticationService.getPrivilegio("Cotizacion").toPromise()).toString();
    this.privilegioTarifa = await (await this.authenticationService.getPrivilegio("Tarifa").toPromise()).toString();
    this.isLoad = true;
  }

  esMexicoOPuebla(): boolean {
    return this.corresponsal != null && this.corresponsal != undefined && (this.corresponsal.IdDelegacion == 2 || this.corresponsal.IdDelegacion == 5);
  }

  tienePrivilegioFacturacion() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioFacturacion))
  }

  tienePrivilegioBooking() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioBooking))
  }

  tienePrivilegioTracking() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioTracking))
  }

  tienePrivilegioCotizacion() {
    return ((this.privilegios != null && this.privilegios != undefined) && (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioCotizacion)))
  }

  tienePrivilegioTarifa() {
    return ((this.privilegios != null && this.privilegios != undefined) && (/*this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || */this.privilegios.includes(this.privilegioTarifa)))
  }

  getLogoHTML() {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + this.logo)
  }


  onLogout(click: string) {
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.clase;
    //Añadir en la bbdd en el diario de conexiones, la desconexion
    this.authenticationService.logOut(log).subscribe((data: any) => {
      localStorage.clear();
      this.router.navigate(['login']).then(() => { });
    },
      error => {
        var errorBody = this.translateService.instant('Problemas con el servidor');
        var errorTitulo = this.translateService.instant('Error');
        this.toastr.error(errorBody, errorTitulo)
      });
  }

  resetInformation() {
    this.envioService.setVisor(false);
    this.envioService.setEditar(false);
    this.envioService.setDuplicar(false);
    this.envioService.setIdEnvio(-1);
  }

  irAEnvioAereo() {
    let url = this.router.url;
    this.router.navigate(['/customer/shipment/aereo']).then(() => {

      if (url == '/customer/shipment/aereo')
        window.location.reload();
      this.resetInformation();
    });
  }


  irAEnvioMaritimo() {
    let url = this.router.url;
    this.router.navigate(['/customer/shipment/maritimo']).then(() => {
      if (url == '/customer/shipment/maritimo')
        window.location.reload();
      this.resetInformation();
    });
  }

  irAEnvioTerrestre() {
    let url = this.router.url;
    this.router.navigate(['/customer/shipment/terrestre']).then(() => {

      if (url == '/customer/shipment/terrestre')
        window.location.reload();
      this.resetInformation();
    });
  }

  irAConsultarEnvios(idArea: number) {
    this.router.navigateByUrl('/customer/consulta/' + idArea).then(() => {
      let url = this.router.url;

      if (url.startsWith('/customer/consulta/' + idArea))
        window.location.reload();
      this.resetInformation();
    });

  }

  irAConsultarFacturacion(idArea: number) {
    this.router.navigateByUrl('/customer/facturacion/' + idArea).then(() => {
      let url = this.router.url;

      if (url.startsWith('/customer/facturacion/' + idArea))
        window.location.reload();
      this.resetInformation();
    });

  }

  /*
  irListadoCotizacion(idArea: number) {
      this.router.navigateByUrl('/customer/cotizaciones/' + idArea).then(() => {
        let url = this.router.url;
  
        if (url.startsWith('/customer/cotizaciones/' + idArea))
          window.location.reload();
        this.resetInformation();
      })
    }
  */

  irListadoCotizacion(posicionArea: number, posicionModoEnvio: number) {
    this.router.navigateByUrl('/customer/cotizaciones/consulta/' + posicionArea).then(() => {
      let url = this.router.url;
      // this.cotizacionService.posicionArea = posicionArea;
      // this.cotizacionService.posicionModoEnvio = posicionModoEnvio;

      if (url.startsWith('/customer/cotizaciones'))
        window.location.reload();
      this.resetInformation();
    })
  }

  irCreacionCotizacion(area: string) {
    this.router.navigateByUrl('/customer/cotizaciones/' + area).then(() => {
      let url = this.router.url;

      if (url.startsWith('/customer/cotizaciones/' + area))
        window.location.reload();
      this.resetInformation();
    })
  }

  irATarifaMaritimo() {
    let url = this.router.url;
    this.router.navigate(['/customer/tarifas/maritimo']).then(() => {

      if (url == '/customer/tarifas/maritimo')
        window.location.reload();
      this.resetInformation();
    });
  }

  irATarifaAereo() {
    let url = this.router.url;
    this.router.navigate(['/customer/tarifas/aereo']).then(() => {

      if (url == '/customer/tarifas/aereo')
        window.location.reload();
      this.resetInformation();
    });
  }

  irAInicio() {
    this.router.navigate(['/customer']).then(() => {
      let url = this.router.url;

      if (url == '/customer') {
        window.location.reload();
      }
      this.resetInformation();
    });
  }

}
