import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';
import { TarifaPost } from 'src/app/models/tarifaPost';
import { TarifaService } from 'src/app/services/tarifaservice.service';

@Component({
  selector: 'app-list-tarifa',
  templateUrl: './list-tarifa.component.html',
  styleUrls: ['./list-tarifa.component.scss']
})
export class ListTarifaComponent implements OnInit {

  @Output() mostrarFormulario: EventEmitter<boolean> = new EventEmitter<boolean>();
  // @Input() listadoTarifas: TarifaPost[] = [];
  showButtonsOption1 = true;
  showButtonsOption2 = false;
  index = 0;
  idArea: number = 0;
  tarifa: TarifaEnvio;
  listTarifaCaducada: TarifaPost[];
  listTarifa: TarifaPost[];
  showForm: boolean = false;
  listadoTarifas: TarifaPost[] = [];




  constructor(private tarifaService: TarifaService, private router: Router) { }

  ngOnInit(): void {
    this.listadoTarifas = this.tarifaService.listaTarifas;
    this.tarifa = this.tarifaService.tarifa
    this.obtenerTarifasCaducadas();
    if (this.tarifa.IdArea == 2)
      this.isMaritimo();
    else if (this.tarifa.IdArea == 3)
      this.isAereo();
    else
      this.isTerrestre();
  }

  onTabSelectionChanged(event) {
    this.index = event.index;
    this.showButtonsOption1 = this.index === 0;
    this.showButtonsOption2 = this.index === 1;
  }

  isTerrestre() {
    this.idArea = 1;
  }
  isMaritimo() {
    this.idArea = 2;
  }
  isAereo() {
    this.idArea = 3;
  }

  obtenerTarifasCaducadas() {
    this.listTarifaCaducada = this.listadoTarifas.filter(tc => tc.esCaducada == true)
    this.listTarifa = this.listadoTarifas.filter(tc => tc.esCaducada == false)
  }

  cancelarTarifa(mostrar: Boolean) {
    this.mostrarFormulario.emit(false)
  }


  cambiarURL() {
    if (this.tarifa == undefined)
      this.router.navigateByUrl('/customer/tarifas/maritimo');
    else if (this.tarifa.IdArea == 2)
      this.router.navigateByUrl('/customer/tarifas/maritimo');
    else if (this.tarifa.IdArea == 3)
      this.router.navigateByUrl('/customer/tarifas/aereo');
    else
      this.router.navigateByUrl('/customer/tarifas/terrestre');

  }


}
