import { ContenedorMercancia } from "./contenedorMercancia.model";
import { localidadGoogleMaps } from "./localidadGoogleMaps.model";
import { Mercancia } from "./mercancia.model";

export class TarifaEnvio {
    Aerolinea: string;
    Bultos: number;
    IdAeropuertoDestino: number;
    IdAeropuertoOrigen: number;
    IdArea: number;
    IdModoEnvio: number;
    IdLocalidadDestino: number;
    NoRemontable: boolean;
    TipoTransito: string[];
    Cliente: string;
    IdLocalidadOrigen: number;
    LocalidadOrigen: localidadGoogleMaps;
    LocalidadDestino: localidadGoogleMaps;
    AeropuertoOrigen: localidadGoogleMaps;
    AeropuertoDestino: localidadGoogleMaps;
    PuertoOrigen: localidadGoogleMaps;
    PuertoDestino: localidadGoogleMaps;
    IdPuertoDestino: number;
    IdPuertoOrigen: number;
    IdTipoEnvio: number;
    Mercancia: Mercancia[];
    MercanciaContenedor: ContenedorMercancia[];
    MercanciaPeligrosa: boolean;
    Naviera: string;
    IdNaviera: number;
    IdAerolinea: number;
    PesoBrutoTotal: number;
    PesoBrutoTotalDGR: number;
    PesoNetoTotal: number;
    PesoNetoTotalDGR: number;
    PesoTasable: number;
    VolumenTotal: number;
    PesoBrutoTotalDGRContenedor: number;
    PesoNetoTotalDGRContenedor: number;
    PesoBrutoTotalContenedor: number;
    MercanciaPeligrosaContenedor: boolean;
    BultosContenedor: number;
    ValorMercancia: number;
    VGMTransmision: boolean;
    VGMPesaje: boolean;
    VolumenTasable: number
    BultosTotales: number;
    Incoterm: string;
}
