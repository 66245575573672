import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalles-grados',
  templateUrl: './detalles-grados.component.html',
  styleUrls: ['./detalles-grados.component.scss']
})
export class DetallesGradosComponent implements OnInit {


  @Input() gradoMinimo: number;
  @Input() gradoMaximo: number;

  constructor() { }

  ngOnInit(): void {
  }

  esIgualGradoMaximoQueMinimo(){
    return this.gradoMaximo == this.gradoMinimo;
  }

}
