<div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="tipoEmbalaje">
            <th mat-header-cell *matHeaderCellDef> {{'TipoEmbalaje'|translate}}</th>
            <td mat-cell *matCellDef="let element" (click)="getElement(element)"> {{element.TipoEmbalaje}} </td>
        </ng-container>

        <!-- Largo Column -->
        <ng-container matColumnDef="largo">
            <th mat-header-cell *matHeaderCellDef> {{'Largo'|translate}} </th>
            <td mat-cell *matCellDef="let element" (click)="getElement(element)"> {{element.Largo}} </td>
        </ng-container>

        <!-- Ancho Column -->
        <ng-container matColumnDef="ancho">
            <th mat-header-cell *matHeaderCellDef> {{'Ancho'|translate}} </th>
            <td mat-cell *matCellDef="let element" (click)="getElement(element)"> {{element.Ancho}} </td>
        </ng-container>

        <!-- Alto Column -->
        <ng-container matColumnDef="alto">
            <th mat-header-cell *matHeaderCellDef> {{'Alto'|translate}} </th>
            <td mat-cell *matCellDef="let element" (click)="getElement(element)"> {{element.Alto}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="mat_delete_icon" (click)="eliminarElemento(element)"><mat-icon>delete</mat-icon> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>

<section class="example-section">
    <mat-slide-toggle
        class="example-margin"
        [(ngModel)]="checked"
        [disabled]="disabled"
        (click)="changeValue()">
      {{'Mostrar embalajes predeterminados'|translate}}
    </mat-slide-toggle>
</section>