<div>
  <app-header></app-header>
  <div class="container-fluid">
    <div class="container">
      <form *ngIf="!showListTarifas" [formGroup]="formModelTarifa">
        <div class="form-group">
          <!-- PRIMERA LÍNEA -->
          <div class="row margen-top mb-2">
            <!-- TIPO ENVIO -->
            <div class="col-sm-3">
              <div class="row">
                <div class="col-12">
                  <label>{{ "Tipo Envio" | translate }}* </label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="TipoEnvio"
                    id="TipoEnvio"
                    [ngClass]="{ error: IdTipoEnvio.invalid }"
                    [items]="listTipoEnvio"
                    bindLabel="TipoEnvio"
                    bindValue="IdTipoEnvio"
                    formControlName="IdTipoEnvio"
                    [ngModelOptions]="{ standalone: true }"
                    aria-required="true"
                  >
                  </ng-select>
                  <div
                    *ngIf="IdTipoEnvio.invalid"
                    class="text-danger"
                    style="font-size: small"
                  >
                    {{ "Tipo envio obligatorio" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- INCOTERM -->
            <div class="col-sm-2">
              <div class="row">
                <div class="col-12">
                  <label>{{ "Incoterm" | translate }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="Incoterm"
                    id="Incoterm"
                    [loading]="cargaIncoterm"
                    bindLabel="IdIncoterm"
                    bindValue="IdIncoterm"
                    formControlName="Incoterm"
                    [items]="listIncoterm"
                    [ngModelOptions]="{ standalone: true }"
                    aria-required="true"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- AEROLINEA -->
            <div class="col-sm-3">
              <div class="row">
                <div class="col-12">
                  <label>{{ "Aerolinea" | translate }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="Aerolinea"
                    id="Aerolinea"
                    [loading]="cargaAerolineas"
                    formControlName="IdAerolinea"
                    [ngModelOptions]="{ standalone: true }"
                    aria-required="true"
                  >
                    <ng-option
                      *ngFor="let n of listAerolineas"
                      [value]="n.Codigo"
                    >
                      {{ n.Nombre }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- ELECCIONES PORTES -->
            <div class="col-sm-2" *ngIf="mostrarTransporteACargoDe">
              <div class="row">
                <div class="col-12">
                  <label>{{
                    "Transporte terrestre a cargo de..." | translate
                  }}</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="Portes"
                    id="Portes"
                    formControlName="IncluirPortes"
                    [ngModelOptions]="{ standalone: true }"
                    aria-required="true"
                    (change)="cambioPortes()"
                  >
                    <ng-option [value]="true"> XGL </ng-option>
                    <ng-option [value]="false">
                      {{ "Propio" | translate }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <!-- SEGUNDA LÍNEA -->
          <div class="row">
            <!-- LOCALIDAD ORIGEN -->
            <div class="col-sm-3" *ngIf="mostrarLocalidadOrigen">
              <div class="row">
                <div class="col-12">
                  <label>{{ "LocalidadOrigen" | translate }}*</label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="LocalidadOrigen"
                    id="LocalidadOrigen"
                    [items]="listLocalidades"
                    bindLabel="Localidad"
                    bindValue="IdLocalidad"
                    formControlName="IdLocalidadOrigen"
                    [ngModelOptions]="{ standalone: true }"
                    aria-required="true"
                    [loading]="cargaLocalidades"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <!-- AEROPUERTO ORIGEN -->
            <div class="col-sm-3">
              <div class="row">
                <div class="col-12">
                  <label>
                    {{ "AeropuertoOrigen" | translate
                    }}<span *ngIf="IdTipoEnvio.value == 4">*</span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="AeropuertoOrigen"
                    id="AeropuertoOrigen"
                    class="select-small"
                    formControlName="IdAeropuertoOrigen"
                    [loading]="cargaAeropuerto"
                    loadingText="Cargando..."
                    [ngClass]="{ error: aeropuertoOrigenError() }"
                  >
                    <ng-option
                      *ngFor="let a of listAeropuertoOrigen"
                      [value]="a.IdAeropuerto"
                    >
                      <img
                        class="bandera"
                        alt="b"
                        src="{{ getBandera(a) }}"
                      />&nbsp;&nbsp;{{ a.Nombre }}
                    </ng-option>
                  </ng-select>
                  <div
                    *ngIf="aeropuertoOrigenError()"
                    class="text-danger"
                    style="font-size: small"
                  >
                    {{ "TitleAeropuertoOrigenError" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <!-- AEROPUERTO DESTINO -->
            <div class="col-sm-3">
              <div class="row">
                <div class="col-12">
                  <label>
                    {{ "AeropuertoDestino" | translate
                    }}<span *ngIf="IdTipoEnvio.value == 3">*</span>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ng-select
                    name="AeropuertoDestino"
                    id="AeropuertoDestino"
                    class="select-small"
                    formControlName="IdAeropuertoDestino"
                    [loading]="cargaAeropuerto"
                    loadingText="Cargando..."
                    [ngClass]="{ error: aeropuertoDestinoError() }"
                  >
                    <ng-option
                      *ngFor="let a of listAeropuertoDestino"
                      [value]="a.IdAeropuerto"
                    >
                      <img
                        class="bandera"
                        alt="b"
                        src="{{ getBandera(a) }}"
                      />&nbsp;&nbsp;{{ a.Nombre }}
                    </ng-option>
                  </ng-select>
                  <div
                    *ngIf="aeropuertoDestinoError()"
                    class="text-danger"
                    style="font-size: small"
                  >
                    {{ "TitleAeropuertoDestinoError" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <!-- LÍNEA MERCANCÍA -->
          <div class="row" id="octavobloque">
            <div
              class="col-sm-12"
              *ngIf="listaMercancia != [] && listaMercancia != undefined"
            >
              <app-tarifa-cargo
                [listaMercancia]="Mercancia.value"
                (listaAux)="listaMerc($event)"
                [visor]="false"
                [isCotizacion]="false"
                [editar]="false"
                [fcl]="false"
                [area]="IdArea.value"
                [noRemontable]="NoRemontable.value"
                [tarifa]="esTarifa"
                [mercanciaPeligrosa]="MercanciaPeligrosa.value"
                [cliente]="clienteEntidad"
              >
              </app-tarifa-cargo>
            </div>
          </div>
          <br />
          <!-- TOTALES -->
          <div id="Pesos" class="contenedor">
            <h5>{{ "Totales" | translate }}</h5>
            <div class="row" style="margin: 5px">
              <div class="col-sm-2">
                <label class="totalizadores">{{
                  "Bultos Totales" | translate
                }}</label>
                <input
                  autocomplete="off"
                  name="BultosTotales"
                  type="text"
                  formControlName="BultosTotales"
                  [ngClass]="{
                    'form-control-custom-error totalizadores':
                      BultosTotalesError(),
                    'form-control totalizadores': !BultosTotalesError()
                  }"
                  readonly
                  required
                />
                <div
                  *ngIf="BultosTotalesError()"
                  class="text-danger"
                  style="margin-bottom: 20px; font-size: small"
                >
                  {{ "Bultos Totales obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <label class="totalizadores"
                  >{{ "Bruto Total" | translate }} (Kg)*</label
                >
                <input
                  autocomplete="off"
                  (change)="comprobarPesosTotales()"
                  (focusout)="calcularPesoTasable()"
                  name="PesoBrutoTotal"
                  type="text"
                  [ngClass]="{
                    'form-control-custom-error totalizadores': errorPesoBruto,
                    'form-control totalizadores': !errorPesoBruto
                  }"
                  formControlName="PesoBrutoTotal"
                  [readOnly]="pesoBrutoTotalReadonly"
                  required
                />
                <div
                  *ngIf="PesoBrutoTotalError()"
                  class="text-danger"
                  style="margin-bottom: 20px; font-size: small"
                >
                  {{ "Peso bruto total obligatorio" | translate }}
                </div>
              </div>
              <!-- <div class="col-sm-2">
                                <label class="totalizadores">{{'Neto Total'|translate}} (Kg)</label>
                                <input autocomplete="off" name="PesoNetoTotal" type="text"
                                    [ngClass]="{'form-control-custom-error totalizadores':PesoNetoTotalError(), 'form-control totalizadores':!PesoNetoTotalError()}"
                                    formControlName="PesoNetoTotal"  required>
                                <div *ngIf="PesoNetoTotalError()" class="text-danger"
                                    style="margin-bottom: 20px;font-size: small;">
                                    {{'Peso Neto total obligatorio'|translate}}
                                </div>
                            </div>-->
              <div class="col-sm-2">
                <label class="totalizadores"
                  >{{ "Peso Tasable" | translate }} (Kg)</label
                >
                <input
                  autocomplete="off"
                  name="PesoTasable"
                  type="text"
                  class="form-control"
                  formControlName="PesoTasable"
                  readonly
                />
              </div>
              <div class="col-sm-2">
                <label class="totalizadores"
                  >{{
                    "Volumen
                                    Total" | translate
                  }}(m<sup>3</sup>)<span>*</span></label
                >
                <input
                  autocomplete="off"
                  name="VolumenTotal"
                  type="text"
                  (focusout)="calcularPesoTasable()"
                  [ngClass]="{
                    'form-control-custom-error totalizadores':
                      VolumenTotalError(),
                    'form-control totalizadores': !VolumenTotalError()
                  }"
                  formControlName="VolumenTotal"
                  [readOnly]="volumenTotalReadOnly"
                  required
                />
                <div
                  *ngIf="VolumenTotalError()"
                  class="text-danger"
                  style="
                    font-size: small;
                    margin-bottom: 20px;
                    margin-left: 10px;
                  "
                >
                  {{ "Volumen Total obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2" *ngIf="hayMercanciaPeligrosa()">
                <label
                  class="totalizadores"
                  style="margin-right: 0px; display: inline-block"
                  >{{
                    "Bruto
                                    Total" | translate
                  }}(Kg)(DGR)</label
                >
                <input
                  id="PesoBrutoTotalDGR"
                  autocomplete="off"
                  name="PesoBrutoTotalDGR"
                  type="number"
                  class="form-control totalizadores"
                  formControlName="PesoBrutoTotalDGR"
                />
                <div
                  *ngIf="tieneErrorPesoBrutoTotalDGR()"
                  class="text-danger"
                  style="margin-bottom: 20px; font-size: small"
                >
                  {{
                    "Peso Bruto total DGR mayor que peso bruto total"
                      | translate
                  }}
                </div>
              </div>
              <div class="col-sm-2" *ngIf="hayMercanciaPeligrosa()">
                <label class="totalizadores"
                  >{{ "Neto Total" | translate }}(Kg)(DGR)*</label
                >
                <input
                  id="PesoNetoTotalDGR"
                  autocomplete="off"
                  name="PesoNetoTotalDGR"
                  type="number"
                  [ngClass]="{
                    'form-control-custom-error totalizadores': errorPesoNeto,
                    'form-control totalizadores': !errorPesoNeto
                  }"
                  formControlName="PesoNetoTotalDGR"
                  required
                />
                <div
                  *ngIf="PesoNetoTotalDgrError()"
                  class="text-danger"
                  style="margin-bottom: 20px; font-size: small"
                >
                  {{ "Peso Neto total DGR obligatorio" | translate }}
                </div>
              </div>
            </div>
          </div>
          <!-- ACCIONES Y DEMÁS -->
          <div id="decimobloque" class="row">
            <div class="col-sm 8 col-12"></div>
            <br />
            <div class="col-sm-2 col-12 margen-top">
              * : {{ "indicador de campos obligatorios" | translate }}
            </div>
            <div class="col-sm-2 col-12 margen-top"></div>
          </div>
          <div id="undecimobloque" class="row">
            <div class="col-sm-8 col-12"></div>
            <div class="col-sm-2 col-12 margen-top">
              <button
                type="button"
                (click)="crearTarifa()"
                id="enviarShipment"
                class="btn btn-block"
              >
                {{ "Enviar" | translate }}
              </button>
            </div>
            <div class="col-sm-2 col-12 margen-top">
              <button
                type="button"
                id="cancelarShipment"
                class="btn buttonCancelar btn-block"
                (click)="openModalSM(templateAvisoSiNo)"
              >
                {{ "Cancelar" | translate }}
              </button>
            </div>
            <button
              hidden
              type="button"
              id="resumenTarifa"
              #resumenTarifa
              name="resumenTarifa"
            >
              {{ "Cancelar" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- TEMPLATES -->
  <ng-template #templateAvisoSiNo class="modal-dialog">
    <div class="modal-header fondo" style="border-color: black">
      <h2 class="modal-title">{{ "CancelarTitleShipment" | translate }}</h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-aviso-si-no
        [texto]="texto"
        (respuesta)="obtenerRespuesta($event)"
      ></app-aviso-si-no>
    </div>
  </ng-template>

  <ng-template #templateResumenTarifa class="modal-dialog">
    <div
      class="modal-header fondo"
      style="border-color: black; background-color: white"
    >
      <h2 class="modal-title">{{ "Resumen" | translate }}</h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo" style="background-color: white"></div>
  </ng-template>
</div>
