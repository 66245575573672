import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmacion-plantilla',
  templateUrl: './confirmacion-plantilla.component.html',
  styleUrls: ['./confirmacion-plantilla.component.scss']
})
export class ConfirmacionPlantillaComponent implements OnInit {

  @Output() confirmEmit = new EventEmitter<Boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  confirmacion(){
    this.confirmEmit.emit(true)
  }

  cancelacion() {
    this.confirmEmit.emit(false)
  }

}
