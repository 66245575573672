import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Archivo } from 'src/app/models/archivo.model';
import { Shipment } from 'src/app/models/shipment.model';
import { TipoArchivoProveedor } from 'src/app/models/tipo-archivo-proveedor.model';
import { EntityService } from 'src/app/services/entity.service';
import { saveAs } from 'file-saver';
import { TipoArchivoProveedorService } from 'src/app/services/tipoarchivoproveedor.service';
import { LogModel } from 'src/app/models/log-model.model';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'chart.js';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/es';
import moment from 'moment';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-documentacion-proveedor',
  templateUrl: './documentacion-proveedor.component.html',
  styleUrls: ['./documentacion-proveedor.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class DocumentacionProveedorComponent implements OnInit {

  clase: string = "app-documentacion-proveedor";
  tipoArchivoSelected: TipoArchivoProveedor;
  listaTipoArchivos: TipoArchivoProveedor[];
  files: any = [];
  nombreArchivo: String[] = [];
  dataFile: Archivo[] = [];
  @Output() listFiles = new EventEmitter<Archivo[]>();
  @Input() listaArchivos: Archivo[];  
  @Input() envio: Shipment;
  minDate: Date;
  indiceEdit: number;
  modalRef: BsModalRef | null;
  @Input() documentacion: Boolean;
  listaArchivosActuales: Archivo[];
  displayedColumns: string[] = ['Nombre', 'Estado', 'Obligatorio', 'DescargarSubir'];
  dataSource;
  estados: string[] = [];
  contadorEstados: number[] = [0, 0, 0, 0, 0];
  public doughnutChartType: ChartType = 'doughnut';
  public colorsEstados: any[] = [{
    backgroundColor: ['#44D059', '#009bcf', '#f45348', '#868686', '#ff9100'],
    borderColor: "black"
  }];



  //Declaracion del decorador para poder cerrar el modal si y solo sí estan todos los campos correctos.
  @ViewChild('modal') modal : ElementRef;

  constructor(private tipoArchivoProveedorService: TipoArchivoProveedorService, private toastr: ToastrService, 
    private entidadService: EntityService, private translate: TranslateService, private _adapter: DateAdapter<any>, 
    private modalService: BsModalService, private usuarioService: UsuarioService) {
      this._adapter.setLocale('es');
     }

  ngOnInit(): void {
    this.minDate = new Date();
    
    this.tipoArchivoProveedorService.getTipoArchivoProveedor().subscribe(tap =>{
      this.dataSource = new MatTableDataSource<TipoArchivoProveedor>(tap);
      this.listaTipoArchivos = tap;
    });
    this.usuarioService.getLenguaje$().subscribe(async l => {
      this.translate.use(l);
      await this.translate.get('VerificadoEstado').toPromise().then();
      var estados = [this.translate.instant("VerificadoEstado"),
                      this.translate.instant("EnProcesoEstado"),
                      this.translate.instant("NoValidoEstado"),
                      this.translate.instant("SinSubirEstado"),
                      this.translate.instant("CaducadoEstado")];
      this.estados = estados;
    });

    this.estados = [this.translate.instant("VerificadoEstado"),
                      this.translate.instant("EnProcesoEstado"),
                      this.translate.instant("NoValidoEstado"),
                      this.translate.instant("SinSubirEstado"),
                      this.translate.instant("CaducadoEstado")];
    
    this.entidadService.getArchivosEntity$().subscribe(data => {
      var contadorEstados: number[] = [0, 0, 0, 0, 0];
      this.listaArchivosActuales = data;
      this.listaTipoArchivos.forEach(ta => {
        if(this.isSubido(ta) && !this.isCaducadoTipoArchivo(ta))
          contadorEstados[0]++;
        else if(this.isVerificando(ta) && !this.isCaducadoTipoArchivo(ta))
          contadorEstados[1]++;
        else if(this.isError(ta))
          contadorEstados[2]++;
        else if(this.isSinSubir(ta))
          contadorEstados[3]++;
        else if(this.isCaducado(ta))
          contadorEstados[4]++;
      });
      this.contadorEstados = contadorEstados;
    });
    this.entidadService.getArchivosEntidad();
  }

  ngOnChanges(changes: SimpleChanges){
    this.estados = [this.translate.instant("VerificadoEstado"),
    this.translate.instant("EnProcesoEstado"),
    this.translate.instant("NoValidoEstado"),
    this.translate.instant("SinSubirEstado"),
    this.translate.instant("CaducadoEstado")];
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cerrarModal(evento){
    if(evento){
      this.modalRef.hide();
      this.entidadService.getArchivosEntidad();
    }
  }

  isError(tipoArchivoProveedor: TipoArchivoProveedor): boolean{
    var isErr = false;
    if(this.listaArchivosActuales != undefined){
      this.listaArchivosActuales.forEach(a => {
        if(a.IdTipoArchivo == tipoArchivoProveedor.codigo && a.Estado == "Error" && a.Verificado)
          isErr = true;
      });
    }
    return isErr;
  }

  isVerificando(tipoArchivoProveedor: TipoArchivoProveedor): boolean{
    var isVer = false;
    if(this.listaArchivosActuales != undefined){
      this.listaArchivosActuales.forEach(a => {
        if(a.IdTipoArchivo == tipoArchivoProveedor.codigo && !a.Verificado)
          isVer = true;
      });
    }
    return isVer;
  }

  isCaducadoTipoArchivo(tipoArchivoProveedor: TipoArchivoProveedor): boolean{
    var isCad = false;
    if(this.listaArchivosActuales != undefined){
      var hoy = new Date();
      this.listaArchivosActuales.forEach(a => {
        var FechaFinalValidez = moment(a.FechaFinalValidez, "DD/MM/YYYY").toDate();
        if(a.IdTipoArchivo == tipoArchivoProveedor.codigo && FechaFinalValidez.getTime() < hoy.getTime())
          isCad = true;
      });
    }
    return isCad;
  }

  isCaducado(ta: TipoArchivoProveedor){
    return (this.isVerificando(ta) && this.isCaducadoTipoArchivo(ta)) ||
    (this.isSubido(ta) && this.isCaducadoTipoArchivo(ta));
  }

  isSinSubir(tipoArchivoProveedor: TipoArchivoProveedor): boolean{
    var isSinSubir = true;
    if(this.listaArchivosActuales != undefined){
      this.listaArchivosActuales.forEach(a => {
        if(a.IdTipoArchivo == tipoArchivoProveedor.codigo)
          isSinSubir = false;
      });
    }
    return isSinSubir;
  }

  isSubido(tipoArchivoProveedor: TipoArchivoProveedor): boolean{
    var isSubido = false;
    if(this.listaArchivosActuales != undefined){
      this.listaArchivosActuales.forEach(a => {
        if(a.IdTipoArchivo == tipoArchivoProveedor.codigo && a.Verificado && a.Estado == "Ok")
          isSubido = true;
      });
    }
    return isSubido;
  }

  isUpload(tipoArchivoProveedor: TipoArchivoProveedor): boolean{
    var isUp = false;
    if(this.listaArchivosActuales != undefined){
      this.listaArchivosActuales.forEach(a => {
        if(a.IdTipoArchivo == tipoArchivoProveedor.codigo)
          isUp = true;
      });
    }
    return isUp;
  }

  descargarPDF(tipoArchivoProveedor: TipoArchivoProveedor) {
    var file: Archivo;
    this.listaArchivosActuales.forEach(ta => {
      if(tipoArchivoProveedor.codigo == ta.IdTipoArchivo)
        file = ta;
    });
    var data = atob(file.Base64image.toString())
    const bytenumbers = new Array(data.length);
    for (let i = 0; i < data.length; i++)
      bytenumbers[i]= data.charCodeAt(i);
    const byteArray = new Uint8Array(bytenumbers);
    const blob = new Blob([byteArray], {type: 'contentType'})
    
    if(file.FileName.includes("."))
      saveAs(blob, file.FileName);
    else{
      var extension: String = "";
      if(file.Extension.includes("."))
        extension = file.Extension;
      else
        extension = "." + file.Extension;
      saveAs(blob, file.FileName.concat(extension.toString()));
    }
  }

  getNombre(nombre: string){
    var strings = nombre.split('/');
    return strings[strings.length-1];
  }
}
