import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Archivo } from 'src/app/models/archivo.model';
import { FileShipService } from 'src/app/services/file-ship.service';
import { saveAs } from 'file-saver';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Shipment } from 'src/app/models/shipment.model';
import { EntityService } from 'src/app/services/entity.service';
import { Corresponsal } from 'src/app/models/corresponsal.model';


@Component({
  selector: 'app-shipping-files',
  templateUrl: './shipping-files.component.html',
  styleUrls: ['./shipping-files.component.scss']
})
export class ShippingFilesComponent implements OnInit {

  pdfSrc = "";
  referenciaEnvio: String;
  listaArchivos: Archivo[];
  envio: Shipment;
  entidad: Corresponsal;
  constructor(private fileService: FileShipService, private envioService: ShipmentService, private entityService: EntityService) {
  }

  getNombre(nombre: string){
    var strings = nombre.split('/');
    return strings[strings.length-1];
  }

  isClienteMX(){
    return this.entidad.IdCliente == 934 && this.entidad.IdDelegacion == 1;
  }

  isClientePU(){
    return this.entidad.IdCliente == 6380 && this.entidad.IdDelegacion == 1;
  }

  isClienteIT(){
    return this.entidad.IdCliente == 759 && this.entidad.IdDelegacion == 1;
  }

  isPdf(file){
    return file.Extension.includes('pdf');
  }

  isPowerPoint(file){
    return file.Extension.includes('pot') || file.Extension.includes('potm') || file.Extension.includes('potx') || file.Extension.includes('ppsx') || file.Extension.includes('ppt') || file.Extension.includes('pptx');
  }

  isWord(file){
    return file.Extension.includes('doc') || file.Extension.includes('docx') || file.Extension.includes('docm');
  }

  isExcel(file){
    return file.Extension.includes('xlsx') || file.Extension.includes('csv') || file.Extension.includes('xls');
  }

  isComprimido(file){
    return file.Extension.includes('rar') || file.Extension.includes('zip');
  }

  isOutlook(file){
    return file.Extension.includes('msg');
  }

  isImagen(file){
    return file.Extension.includes('png') || file.Extension.includes('jpg') || file.Extension.includes('jpeg') || file.Extension.includes('tif') || file.Extension.includes('gif');
  }

  isGenerico(file){
    return !this.isPdf(file) && !this.isPowerPoint(file) && !this.isWord(file) && !this.isExcel(file) && !this.isComprimido(file) && !this.isOutlook(file) && !this.isImagen(file);
  }

  ngOnInit(): void {
    this.entityService.getCorresponsal().subscribe(data => {
      this.entidad = data;
      // POR ANGELO QUE NO SALGA DOCUMENTACIÓN A CLIENTE DE MX EN ESPAÑA.
      if(this.isClienteMX() || this.isClienteIT() || this.isClientePU()){
        // No se hace nada
      }else{ // Se hace lo de siempre
        this.envio = this.envioService.envio;
        if(this.envio.Expedicion == null && this.envio.Partida != null){
          this.fileService.getDocumentosPartida(this.envio.Partida).subscribe(data => {
            this.listaArchivos = data;
          })
        }else if(this.envio.IdEnvio != 0){
          this.fileService.getDocumentos(this.envio.IdEnvio).subscribe(data => {
            this.listaArchivos = data;
          })
        }else if(this.envio.Expedicion != null && this.envio.Partida == null){
          this.fileService.getDocumentosExpedicion(this.envio.Expedicion).subscribe(data => {
            this.listaArchivos = data;
          })
        }

        if(this.fileService.listFiles != [] && this.fileService.listFiles != undefined)
        {
          this.listaArchivos = this.fileService.listFiles;
          this.referenciaEnvio = this.fileService.referenciaEnvio;
          this.listaArchivos.forEach(element => {
            
          })
        }
      }
    });
    
    
  }

  ngOnChanges(changes: SimpleChange){
      
  }

  descargarPDF(file: Archivo) {
    var data = atob(file.Base64image.toString())
    const bytenumbers = new Array(data.length);
    for (let i = 0; i < data.length; i++)
    {
      bytenumbers[i]= data.charCodeAt(i);
    }
    const byteArray = new Uint8Array(bytenumbers);
    const blob = new Blob([byteArray], {type: 'contentType'})
    
    if(file.FileName.includes(".")){
      saveAs(blob, file.FileName);
    }else{
      var extension: String = "";
      if(file.Extension.includes("."))
        extension = file.Extension;
      else
        extension = "." + file.Extension;
      saveAs(blob, file.FileName.concat(extension.toString()));
    }
    
    
  }
}
