import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EspecialidadVehiculo } from '../models/especialidadVehiculo.model';
import { LogModel } from '../models/log-model.model';
import { TipoVehiculo } from '../models/tipoVehiculo.model';
import { Vehiculo } from '../models/vehiculo.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class VehiculoService {

  readonly apiUrlService = environment.apiUrl;
  vehiculos$: Subject<Vehiculo[]> = new Subject();
  vehiculo$: Subject<Vehiculo> = new Subject();

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  getVehiculos$(): Observable<Vehiculo[]>{
    return this.vehiculos$.asObservable();
  }

  getVehiculo$(): Observable<Vehiculo>{
    return this.vehiculo$.asObservable();
  }

  setVehiculo$(vehiculo: Vehiculo){
    this.vehiculo$.next(vehiculo);
  }

  getAllVehiculos(){
    const url_api = this.apiUrlService + "matriculas";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticationService.getToken()
    })
    this.http.get(url_api, { headers: headersToken })
    .pipe(map(data => data as Vehiculo[]))
    .subscribe(vs => {
      this.vehiculos$.next(vs);
    });
  }

  getVehiculo(idVehiculo: string): Observable<Vehiculo>{
    const url_api = this.apiUrlService + "matriculas/" + idVehiculo;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticationService.getToken()
    })
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Vehiculo));
  }

  postVehiculo(vehiculo: Vehiculo, log: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "matriculas";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticationService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(vehiculo)}), { headers: headersToken })
    .pipe(map(data => data as boolean));
  }

  putVehiculo(vehiculo: Vehiculo, log: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "matriculas/" + vehiculo.IdVehiculo;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticationService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.put(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(vehiculo)}), { headers: headersToken })
    .pipe(map(data => data as boolean));
  }

  getTipoVehiculos(): Observable<TipoVehiculo[]>{
    const url_api = this.apiUrlService + "glb_tipovehiculo";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticationService.getToken()
    })
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as TipoVehiculo[]));
  }

  getEspecialidadVehiculos(): Observable<EspecialidadVehiculo[]>{
    const url_api = this.apiUrlService + "glb_especialidadvehiculo";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticationService.getToken()
    });
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as EspecialidadVehiculo[]));
  }

}
