import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { EnvioAereo } from 'src/app/models/envio-aereo.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Shipment } from 'src/app/models/shipment.model';
import { FileShipService } from 'src/app/services/file-ship.service';
import { Router } from '@angular/router';
import { EnvioMaritimo } from 'src/app/models/envio-maritimo.model';
import { EnvioTerrestre } from 'src/app/models/envio-terrestre.model';
import { Tracking } from 'src/app/models/tracking.model';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { EntityService } from 'src/app/services/entity.service';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LineasIngreso } from 'src/app/models/lineasIngreso.model';
import { Totales } from 'src/app/models/totales.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { Archivo } from 'src/app/models/archivo.model';
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { Aeropuerto } from 'src/app/models/aeropuerto.model';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-shipping-tracking',
  templateUrl: './shipping-tracking.component.html',
  styleUrls: ['./shipping-tracking.component.scss']
})
export class ShippingTrackingComponent implements OnInit {

  archivoBooking: Archivo = new Archivo();
  currentDate: Date;
  tracking: Tracking;
  envioAereo: EnvioAereo;
  envioMaritimo: EnvioMaritimo;
  envioTerrestre: EnvioTerrestre;
  envio: Shipment;
  showURL: boolean = false;
  aereo: boolean = false;
  maritimo: boolean = false;
  terrestre: boolean = false;
  lineasIngresos: LineasIngreso[] = [];
  totales: Totales[] = [];
  entidad: Corresponsal;
  modalRef: BsModalRef | null;
  selectedTab: number = 0;
  @Output() close = new EventEmitter<Boolean>();
  @Output() mostrar = new EventEmitter<Boolean>();
  privilegios;
  privilegioTodo;
  privilegioDocumentosTracking;

  constructor(private shipService: ShipmentService, private entityService: EntityService,
    private docsService: FileShipService, private router: Router, private sanitizer: DomSanitizer,
    private modalService: BsModalService, private fileService: FileShipService, private translateService: TranslateService,
    private toastr: ToastrService, public dialog: MatDialog, private authenticationService: AuthenticationService) {
  }

  getTotalesIngresos() {
    this.lineasIngresos.forEach(li => {
      var divisaTotalIndex = this.totales.findIndex(t => t.divisa == li.divisa);
      if (divisaTotalIndex == -1) { // No existe
        var totalNuevo = new Totales();
        totalNuevo.divisa = li.divisa;
        totalNuevo.descripcion = "Total " + li.divisa;
        totalNuevo.total = Number(Number(li.importe.replace(',', '.')).toFixed(3));
        this.totales.push(totalNuevo);
      } else {
        this.totales[divisaTotalIndex].total += Number(Number(li.importe.replace(',', '.')).toFixed(3));
        this.totales[divisaTotalIndex].total = Number(this.totales[divisaTotalIndex].total.toFixed(3));
      }
    });
  }

  selectedTabChange(event) {
    this.selectedTab = event.index;
  }

  async ngOnInit() {
    this.entityService.getCorresponsal().subscribe(data => {
      this.entidad = data;
    });
    this.envio = this.shipService.envio;
    this.shipService.getTracking().subscribe(data => {
      this.tracking = data;
      this.shipService.getLineasIngreso(this.tracking.Partida, this.tracking.Expedicion).subscribe(li => {
        this.lineasIngresos = li;
        this.getTotalesIngresos();
      });
      if (this.tracking.Url != undefined && this.tracking.Url != null)
        this.tracking.UrlSanitizer = this.sanitizer.bypassSecurityTrustResourceUrl(this.tracking.Url);
    },
      error => {
      });
    this.currentDate = new Date();

    this.privilegios = await this.authenticationService.getPrivilegiosUsuario().toPromise();
    this.privilegioTodo = await (await this.authenticationService.getPrivilegio("Todo").toPromise()).toString();
    this.privilegioDocumentosTracking = await (await this.authenticationService.getPrivilegio("DocumentosTracking").toPromise()).toString();
  }

  tienePrivilegioDocumentosTracking() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioDocumentosTracking))
  }

  isMaritimo() {
    return this.tracking.Area == 2;
  }

  isAereo() {
    return this.tracking.Area == 3;
  }

  tieneIncidencia() {
    return this.tracking.Incidencia;
  }

  getLocations() {
    return this.tracking.LineasTracking.filter(lt => lt.Location != null && lt.Location != undefined && lt.Location != "");
  }

  isPartida() {
    return this.tracking != null && this.tracking != undefined && this.tracking.Partida != null && this.tracking.Partida != undefined;
  }

  isExpedicion() {
    return this.tracking != null && this.tracking != undefined && this.tracking.Expedicion != null && this.tracking.Expedicion != undefined;
  }

  isUndefined() {
    return this.tracking != undefined && this.tracking.LineasTracking != undefined;
  }

  isClienteMX() {
    return this.entidad.IdCliente == 934 && this.entidad.IdDelegacion == 1;
  }

  isImport() {
    return this.tracking.Import;
  }

  getBandera(a: Aeropuerto) {
    return 'assets/banderas/' + a.IsoAlfa2.toLowerCase() + '.png';
  }

  verDocumentos(shipment: Shipment) {
    // POR ANGELO QUE NO SALGA DOCUMENTACIÓN A CLIENTE DE MX EN ESPAÑA.
    if (this.entidad.IdCliente == 934 && this.entidad.IdDelegacion == 1) {
      // No se hace nada
    } else { // Se hace lo de siempre
      if (shipment.IdEnvio != null && shipment.IdEnvio != undefined) {
        this.docsService.getDocumentos(shipment.IdEnvio).subscribe(data => {
        })
      }
      else if (shipment.Partida != null && shipment.Partida != undefined) {
        this.docsService.getDocumentosPartida(shipment.Partida).subscribe(data => {
        })
      }
      else if (shipment.Expedicion != null && shipment.Expedicion != undefined) {
        this.docsService.getDocumentosExpedicion(shipment.Expedicion).subscribe(data => {
        })
      }
      this.router.navigate(['/customer/shipment/documents']);
    }
  }

  listadoDocumentos(shipment: Shipment): void {
    this.shipService.envio = shipment;
    //this.router.navigate(['/customer/shipment/documents']);
  }

  cerrar() {
    this.close.emit(true);
  }

  subirDocumentos() {
    this.downloadPDF();
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    var enviarArchivos: Archivo[];
    var log: LogModel = new LogModel();
    dialogRef.close();
    this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
      if (this.archivoBooking.Base64image != null && this.archivoBooking.Base64image != undefined && this.archivoBooking.Base64image != '')
        enviarArchivos.push(this.archivoBooking);
      this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
        if (this.archivoBooking.Base64image != null && this.archivoBooking.Base64image != undefined && this.archivoBooking.Base64image != '')
          enviarArchivos.push(this.archivoBooking);
        this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
          var Body = this.translateService.instant('Ha producido un error al enviar los documentos, contacte con nosotros.');
          var Titulo = this.translateService.instant('Error en los documentos');
          this.toastr.error(Body, Titulo)
        })
      }, err => {
        var Body = this.translateService.instant('Ha producido un error al enviar los documentos, contacte con nosotros.');
        var Titulo = this.translateService.instant('Error en los documentos');
        this.toastr.error(Body, Titulo)
      })
    }, err => {
      var Body = this.translateService.instant('Ha producido un error al enviar los documentos, contacte con nosotros.');
      var Titulo = this.translateService.instant('Error en los documentos');
      this.toastr.error(Body, Titulo)


    })
  }

  // tslint:disable-next-line:typedef
  downloadPDF() {
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    // Asignamos en DATA el contenido del html que tiene como id 'tracking'
    const DATA = document.getElementById('trackingSpan');
    // Damos formato al documento: p (portrait = vertical), pt (la unidad de media), a4 (tamaño del pdf)
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
      //pagesplit: true
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('application/pdf');
      //const img = canvas.toDataURL('image/png');
      //Add image Canvas to PDF
      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      return doc;
    }).then((docResult) => {
      docResult.save(this.envio.RefCliente + '_tracking.pdf')
      var archivo = new Archivo();
      archivo.Base64image = docResult.output('datauristring');
      archivo.Extension = '.pdf';
      archivo.TipoArchivo = '19';
      this.archivoBooking = archivo;
      dialogRef.close();
      var body = this.translateService.instant("BodyInfoSubidaEbooking");
      var title = this.translateService.instant("TitleInfoSubirEbooking");
      this.toastr.info(body, title);
    })
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 2
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  closeModal(isCierre: boolean) {
    if (isCierre)
      this.modalRef.hide();
  }

  isViajeRetrasado(linea: Tracking): boolean {
    if (linea.DiasRetraso < 0)
      return true;
    return false;
  }

  isViajeAdelantado(linea: Tracking): boolean {
    if (linea.DiasRetraso > 0)
      return true;
    return false;
  }

}
