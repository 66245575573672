import { MercanciaPeligrosaCotizacion } from "./mercanciaPeligrosaCotizacion.model";

export class MercanciaCotizacion {
    Alimentacion: boolean;
    Alto: number;
    Ancho: number;
    Bultos: number;
    Codigo: number;
    Cotizacion: number;
    Gdp: boolean;
    IdTipoEmbalaje: number;
    IdTipoMercancia: number;
    Largo: number;
    MercanciaPeligrosa: MercanciaPeligrosaCotizacion[];
    Metros: number;
    Metros2: number;
    Metros3: number;
    Mp: boolean;
    PesoBruto: number;
    PesoNeto: number;
    PesoTasable: number;
    Remontable: boolean;
    TemperaturaControlada: boolean;
    TipoEmbalaje: string;
    TipoMercancia: string;
    GradosMin: number;
    GradosMax: number;
    NombreMercancia: string;
    Peligrosa: boolean;
}
