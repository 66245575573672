import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tipomercancia } from '../models/tipomercancia.model';
import { AuthenticationService } from './authentication.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})


export class TipomercanciaService {

  readonly apiUrlService = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  ip: string = ""; 
  localizacion: string = "";
  constructor(private http: HttpClient, private service: AuthenticationService, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
   }

  getListaTipoMercancia(): Observable<Tipomercancia[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.service.getToken()
    })
    const url_api = this.apiUrlService + "cfg_mercancias";
    this.logService.postLogCompleto("GET", "cfg_mercanciasController.cs", "Log obtener todos los tipos de mercancias.",
     "Get_cfg_mercancias", "cfg_mercancias", url_api);
    return this.http.get<Tipomercancia[]>(url_api, {headers: headersToken}).pipe(map(data => data));
  }

  getTipoMercanciaById(idMercancia: number): Observable<Tipomercancia> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.service.getToken()
    })
    const url_api = this.apiUrlService + "cfg_mercancias/" + idMercancia;
    this.logService.postLogCompleto("GET", "cfg_mercanciasController.cs", "Log obtener el tipo mercancia con el número " + idMercancia + ".",
     "Get_cfg_mercanciasById", "cfg_mercancias", url_api);
    return this.http.get<Tipomercancia>(url_api, {headers: headersToken}).pipe(map(data => data));
  }

}
