<div class="container" *ngIf="tracking != undefined && tracking != null">
    <div class="row">
        <div class="col-sm-12" id="tracking">
            <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="selectedTabChange($event)">
                <mat-tab>
                    <ng-template matTabLabel>
                        <span>{{'Tracking'|translate}}</span>
                    </ng-template>
                    <span id="trackingSpan">
                        <div class="block">
                            <h1>TRACK & TRACE</h1>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="table-responsive-sm scrollable-element">
                                    <table id="primeraTabla"
                                        class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                                        <thead>
                                            <!-- <th>{{'Departamento'|translate}}</th> -->
                                            <th>{{'Ref. Cliente'|translate}}</th>
                                            <th>{{'Remitente'|translate}}</th>
                                            <th>{{'Destinatario'|translate}}</th>
                                            <th>{{'Bultos'|translate}}</th>
                                            <th>{{'P.Bruto'|translate}}</th>
                                            <th>{{'Volumen'|translate}}</th>
                                            <th>{{'Estado'|translate}}</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <!-- <td>{{tracking.Departamento}}</td> -->
                                                <td>{{tracking.ReferenciaCliente}}</td>
                                                <td>{{tracking.Remitente}}</td>
                                                <td>{{tracking.Destinatario}}</td>
                                                <td>{{tracking.Bultos}}</td>
                                                <td>{{tracking.PesoBruto}}</td>
                                                <td>{{tracking.Volumen}}</td>
                                                <td>{{tracking.Status | translate}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                                        <thead>
                                            <th *ngIf="isPartida()">{{'Viaje'|translate}}</th>
                                            <th *ngIf="isPartida()">{{'Partida'|translate}}</th>
                                            <th *ngIf="isExpedicion()">{{'Expedicion'|translate}}</th>
                                            <th *ngIf="isPartida()">Ref/AWB/MBL</th>
                                            <th>{{'Origen'|translate}}</th>
                                            <th>{{'Destino'|translate}}</th>
                                            <th>{{'FechaLimiteSubidaDocumentacion'|translate}}</th>
                                            <th>ETD</th>
                                            <th>ETA</th>
                                            <th *ngIf="!isPartida()">{{'Transportista'|translate}}</th>
                                            <th *ngIf="isExpedicion()">{{'Matricula' | translate}}</th>
                                            <th *ngIf="isPartida() && isImport()">{{'Agente en origen'|translate}}</th>
                                            <th *ngIf="isPartida() && !isImport()">{{'Agente de destino'|translate}}</th>
                                        </thead>
                                        <tbody>
                                            <td *ngIf="isPartida()">{{tracking.Viaje}}</td>
                                            <td *ngIf="isPartida()">{{tracking.Partida}}</td>
                                            <td *ngIf="isExpedicion()">{{tracking.Expedicion}}</td>
                                            <td *ngIf="isPartida()">{{tracking.RefAwbMbl}}</td>
                                            <td>{{tracking.Origen}}</td>
                                            <td>{{tracking.Destino}}</td>
                                            <td>{{tracking.FechaLimiteSubidaDocumentacion}}</td>
                                            <td>{{tracking.ETD}}</td>
                                            <td>{{tracking.ETA}}</td>
                                            <td *ngIf="!isPartida()">{{tracking.Carrier}}</td>
                                            <td *ngIf="isExpedicion()">{{tracking.Matricula}}</td>
                                            <td *ngIf="isPartida()">{{tracking.DeliveryAgent}}</td>
                                        </tbody>
                                    </table>
                                    <table *ngIf="isPartida() && isMaritimo()"
                                        class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                                        <thead>
                                            <th>{{'Contenedores'|translate}}</th>
                                        </thead>
                                        <tbody>
                                            <td>{{tracking.Contenedores}}</td>
                                        </tbody>
                                    </table>
                                    <table *ngIf="isPartida() && isAereo()" class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                                        <thead>
                                            <th>{{'FechaSalida' | translate }}</th>
                                            <th>{{'FechaLlegada' | translate }}</th>
                                            <th>{{'Vuelo' | translate }}</th>
                                            <th>{{'AeropuertoOrigen' | translate }}</th>
                                            <th>{{'AeropuertoDestino' | translate }}</th>
                                            <th>{{'CompaniaAerea' | translate }}</th>
                                            <th>{{'Concluido' | translate }}</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let vuelo of tracking.Vuelos">
                                                <td>{{vuelo.FechaSalida}}</td>
                                                <td>{{vuelo.FechaLlegada}}</td>
                                                <td>{{vuelo.Vuelo}}</td>
                                                <td><img class="bandera" alt="b" src="{{getBandera(vuelo.AeropuertoOrigen)}}">&nbsp;&nbsp;{{vuelo.AeropuertoOrigen.Nombre}}</td>
                                                <td><img class="bandera" alt="b" src="{{getBandera(vuelo.AeropuertoDestino)}}">&nbsp;&nbsp;{{vuelo.AeropuertoDestino.Nombre}}</td>
                                                <td>{{vuelo.CompaniaAerea}}</td>
                                                <td class="centerDiv" *ngIf="vuelo.SeHaHechoTracking"><mat-icon class="green-icon">check_circle_outline</mat-icon></td>
                                                <td class="centerDiv" *ngIf="!vuelo.SeHaHechoTracking"><mat-icon>minimize</mat-icon></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                                        <thead>
                                            <th>{{'LUGAR'|translate}}</th>
                                            <th>{{'ESTIMADO'|translate}}</th>
                                            <th>{{'FECHA'|translate}}</th>
                                            <th>{{'ESTADO'|translate}}</th>
                                            <th *ngIf="!isMaritimo()">{{'DETALLES'|translate}}</th>
                                            <th *ngIf="isMaritimo()">{{'VESSEL'|translate}}</th>
                                            <th></th>
                                        </thead>
                                        <tbody *ngIf="isUndefined()">
                                            <tr *ngFor="let linea of tracking.LineasTracking">
                                                <td>{{linea.Location}}</td> 
                                                <td [ngClass]="{'fondoRojo': isViajeRetrasado(linea), 'fondoVerde': isViajeAdelantado(linea)}">{{linea.DateETA}}</td>
                                                <td [ngClass]="{'fondoRojo': isViajeRetrasado(linea), 'fondoVerde': isViajeAdelantado(linea)}">{{linea.Date}}</td>
                                                <td>{{linea.Status | translate}}</td>
                                                <td>{{linea.Details}}</td>
                                                <td *ngIf="linea.Concluido"><mat-icon class="green-icon">check_circle_outline</mat-icon></td>
                                                <td *ngIf="!linea.Concluido"><mat-icon>minimize</mat-icon></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </span>
                    <iframe *ngIf="tracking.Url != undefined && tracking.Url != null" [src]="tracking.UrlSanitizer"  id="IframeShipsgoLiveMap" style="height: 550px;width: 100%;"></iframe>
                </mat-tab>
                <mat-tab *ngIf="tienePrivilegioDocumentosTracking()">
                    <ng-template matTabLabel>
                        <span>{{'Documentos'|translate}}</span>
                    </ng-template>
                    <div class="row">
                        <div *ngIf="!isClienteMX()" class="col-sm-12">
                            <app-shipping-files></app-shipping-files>
                        </div>
                        <div *ngIf="isClienteMX()" class="col-sm-12">
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template matTabLabel>
                        <span>{{'Precios'|translate}}</span>
                    </ng-template>
                    <div class="row">
                        <div class="col-sm-12">
                            <app-costes-shipping [lineasIngreso]="lineasIngresos" [totales]="totales"></app-costes-shipping>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2">
            <button type="button" class="btn" style="margin: 5px; height: 36px;"
                (click)="showURL = !showURL">
                <mat-icon>share</mat-icon>
            </button>
        </div>
        <div class="col-sm-6"></div>
        <div class="col-sm-2" style="text-align: right;">
            <button *ngIf="selectedTab != 2" type="button" class="btn" style="margin: 5px;"
                (click)="subirDocumentos()">{{'ImprimirTracking'|translate}}</button>
            <button *ngIf="selectedTab == 2" type="button" class="btn" style="margin: 5px;"
                (click)="downloadPDF()">{{'ImprimirCostes'|translate}}</button>
        </div>
        <div class="col-sm-2" style="text-align: right;">
            <button type="button" class="btn" style="margin: 5px;" (click)="cerrar()">{{'Cerrar'|translate}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6" *ngIf="showURL" style="align-items:flex-end;">
            <app-share-tracking [partida]="tracking.Partida" [expedicion]="tracking.Expedicion"></app-share-tracking>
        </div>
        <div class="col-sm-6"></div>
    </div>
</div>