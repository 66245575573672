export class Direccion {
    IdDireccion?: number;
    IdEntidad?: number;
    Telefono1?: string;
    Extension?: string;
    Fax?: string;
    Telefono2?: string;
    Telefono3?: string;
    Email?: string;
    PaginaWeb?: string;
    Direccion?: string;
    Numero?: string;
    Escalera?: string;
    Piso?: string;
    Puesta?: string;
    ApartadoCorreos?: string;
    Observaciones?: string;
    IdTipoDireccion?: number;
    IdLocalidad?: number;
    CodigoPostal?: string;
    Horario?: string;
    Localidad?: string;
    Facturacion?: number;
    Activa?: number;
    IdPais?: number;
    EntregaRecogida?: number;
    NombrePais?: string;
    NombreLocalidad?: string;
    NombrePoblacion?: string;
    NombreEntidad?: string;
}
