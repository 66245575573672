<app-header-proveedor></app-header-proveedor>

<div class="container">
    <div class="row marginTop marginLeft marginRight">
        <div class=" col-8">
            <button mat-button class="btn" type="button"
                (click)="openToast(templateAsignacionMultipleMatricula)">{{'AsignarMultipleYExpedicionVehiculoButton' | translate}}</button>
            <sup><span mat-raised-button matTooltip="{{'inforMultiMatriculaYExpedicion' | translate }}"
                matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                    style="width: 12px;height: 12px;"></span></sup>
        </div>
        <!-- <div class="col-4 contenedor">
            <h5>Leyenda</h5>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-1">
                    <mat-icon>error_outline</mat-icon>
                </div>
                <div class="col-1">
                    <mat-icon>arrow_right_alt</mat-icon>
                </div>
                <div class="col-8">
                    {{'AntesDeTiempoLabel' | translate }}
                </div>
                <div class="col-1"></div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-1">
                    <mat-icon>clear</mat-icon>
                </div>
                <div class="col-1">
                    <mat-icon>arrow_right_alt</mat-icon>
                </div>
                <div class="col-8">
                    {{'FueraDelHorarioLabel' | translate }}
                </div>
                <div class="col-1"></div>
            </div>
            <div class="row">
                <div class="col-1"></div>
                <div class="col-1">
                    <mat-icon>check</mat-icon>
                </div>
                <div class="col-1">
                    <mat-icon>arrow_right_alt</mat-icon>
                </div>
                <div class="col-8">
                    {{'DentroDelHorarioLabel' | translate }}
                </div>
                <div class="col-1"></div>
            </div>
        </div> -->
    </div>
    <div class="row">
        <div class="col-sm">
        <table class="table table-hover text-center">
            <thead>
            <td class="font-medium"></td>
            </thead>
            <ng-container
            *ngFor="let c of listaVistaProveedor | slice: (pageActual[0]-1) * sizePage[0] : (pageActual[0]-1) * sizePage[0] + sizePage[0];  let i = index;">
            <tr>
                <td>
                <mat-accordion>
                    <mat-expansion-panel class="changeColor" (opened)="openStateRecogida[i] = true;"
                    (closed)="openStateRecogida[i] = false;" [expanded]="openStateRecogida[i]">
                    <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px" class="changeColor2">
                        <mat-panel-title style="align-items: center;">
                        <span
                            matBadge="{{getTotalLengthListasExpediciones(c)}}"
                            matBadgeOverlap="false">
                            <p style="font-size: small;">{{c.NombreConductor}}</p>
                        </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <mat-tab-group mat-align-tabs="center">
                        <mat-tab>
                            <ng-template matTabLabel>
                            <span matBadge="{{expedicionesPendienteEntConductores[i].filteredData.length}}"
                                matBadgeOverlap="false">{{'recogida'|translate}}</span>
                            </ng-template>
                            <app-table-pendientes [expedicionesPendientesEnt]="expedicionesPendienteEntConductores[i]">
                            </app-table-pendientes>
                        </mat-tab>
                        <mat-tab label="{{'entrega' | translate }}">
                            <ng-template matTabLabel>
                            <span matBadge="{{expedicionesPendienteRecConductores[i].filteredData.length}}"
                                matBadgeOverlap="false">{{'entrega'|translate}}</span>
                            </ng-template>
                            <app-table-recogidas [expedicionesPendientesRec]="expedicionesPendienteRecConductores[i]">
                            </app-table-recogidas>
                        </mat-tab>
                        <mat-tab label="{{'entregaSinAlbaran' | translate }}">
                            <ng-template matTabLabel>
                            <span matBadge="{{expedicionesPendientesEntregaAlbaranConductores[i].filteredData.length}}"
                                matBadgeOverlap="false">{{'entregaSinAlbaran'|translate}}</span>
                            </ng-template>
                            <app-table-entrega-sin-albaran [expedicionesPendientesEntregaAlbaran]="expedicionesPendientesEntregaAlbaranConductores[i]">
                            </app-table-entrega-sin-albaran>
                        </mat-tab>
                        <mat-tab>
                            <ng-template matTabLabel>
                            <span matBadge="{{expedicionesHojaDeRuta[i].filteredData.length}}"
                                matBadgeOverlap="false">{{'Hojas de ruta'|translate}}</span>
                            </ng-template>
                            <app-table-hojas-ruta [hojasRuta]="expedicionesHojaDeRuta[i]"></app-table-hojas-ruta>
                        </mat-tab>
                        </mat-tab-group>
                    </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                </td>
            </tr>
            </ng-container>
        </table>
        </div>
    </div>
</div>

<!-- #region: Templates -->
<ng-template #templateAsignacionMultipleConductor>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Asignar conductor'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-multiple-expedicion-conductor (close)="cerrarModal($event)"></app-multiple-expedicion-conductor>
    </div>
  </div>
</ng-template>

<ng-template #templateAsignacionMultipleMatricula>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Asignar matricula'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-multiple-expedicion-matricula (close)="cerrarModal($event)"></app-multiple-expedicion-matricula>
    </div>
  </div>
</ng-template>

<ng-template #templateCambiarPasswordPrimeraVez id="CambiarPasswordPrimeraVez">
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'PrimeraVezLabel' | translate}}</h2>
    </div>
    <div class="modal-body fondo">
      <app-cambiar-password-primera-vez (cerrarModal)="cerrarModal($event)"></app-cambiar-password-primera-vez>
    </div>
  </div>
</ng-template>

<ng-template #templateCambiarPasswordEsCaducada id="CambiarPasswordEsCaducada">
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'EsCaducadaLabel' | translate}}</h2>
    </div>
    <div class="modal-body fondo">
      <app-cambiar-password-primera-vez (cerrarModal)="cerrarModal($event)"></app-cambiar-password-primera-vez>
    </div>
  </div>
</ng-template>
<!-- #endregion -->
