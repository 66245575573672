<app-header-language></app-header-language>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#"> <img src="assets/logos/XGLLogistics-Logo2.png" style="height: 30px; width: 200px;" alt=""></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <ul *ngIf="isMovil">
    <li id="IsMovil" class="navbar-text">
      <label>
        <a>{{nomUser}}</a>
      </label>
      <br>
      <label style="cursor: pointer;">
        <a (click)="onLogout('CerrarSesion')">{{'Cerrar Sesion'|translate}}</a>
      </label>
    </li>
  </ul>
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <!--<li class="nav-item active">
        <a class="nav-link" href="#">Perfil <span class="sr-only">(current)</span></a>
      </li>-->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/conductor/exp-pendientes']">{{'Expediciones pendientes'|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/conductor/historial']">{{'Histórico'|translate}}</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="['/conductor/miPerfil']" class="nav-link">Perfil</a>
      </li>
    </ul>
    <app-header-fijo></app-header-fijo>
  </div>
  <ul *ngIf="!isMovil && token != null">
    <li id="NoEsMovil" class="navbar-text">
      <label>
        <a>{{nomUser}},&nbsp;</a>
      </label>
      <label style="cursor: pointer;">
        <a (click)="onLogout('CerrarSesion')">{{'Cerrar Sesion'|translate}}</a>
      </label>
    </li>
  </ul>
  
  
</nav>
