import { Component, ElementRef, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Mercancia } from 'src/app/models/mercancia.model';
import { FormControl, NgForm } from '@angular/forms';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Archivo } from 'src/app/models/archivo.model';
import { ToastrService } from 'ngx-toastr';
import { Incoterms } from 'src/app/models/incoterms.model';
import { Aeropuerto } from 'src/app/models/aeropuerto.model';
import { Aerolinea } from 'src/app/models/aerolinea.model';
import { Paises } from 'src/app/models/paises.model';
import { Localidades } from 'src/app/models/localidades.model';
import { Direccion } from 'src/app/models/direccion.model';
import { Router } from '@angular/router';
import { Shipment } from 'src/app/models/shipment.model';
import { TipoEnvio } from 'src/app/models/tipo-envio.model';
import { TipoDivisa } from 'src/app/models/tipo-divisa.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from 'src/app/models/entity.model';
import { LogModel } from 'src/app/models/log-model.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { DatosContacto } from 'src/app/models/datos-contacto.model';
import { EntityService } from 'src/app/services/entity.service';
import { ConfigEntidad } from 'src/app/models/config-entidad.model';
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { FileShipService } from 'src/app/services/file-ship.service';
import { Corresponsal } from '../../../../models/corresponsal.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Codigosun } from 'src/app/models/codigosun.model';
import { MercanciaService } from 'src/app/services/mercancia.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { CargoComponent } from '../cargo/cargo.component';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';
import { TarifaService } from 'src/app/services/tarifaservice.service';

@Component({
  selector: 'app-aereo-shipment',
  templateUrl: './aereo-shipment.component.html',
  styleUrls: ['./aereo-shipment.component.scss']
})
export class AereoShipmentComponent implements OnInit {

  @ViewChild('TipoEnvio') tipoEnvio: ElementRef;
  @ViewChild('Referencia') referencia: ElementRef;
  @ViewChild('ListShipper') listShipperElement: ElementRef;
  @ViewChild('ListConsignee') listConsigneeElement: ElementRef;
  @ViewChild('Incoterm') incoterm: ElementRef;
  @ViewChild('ValorMercancia') valorMercancia: ElementRef;
  @ViewChild('EntidadRecogida') entidadRecogida: ElementRef;
  @ViewChild('FechaRecogida') fechaRecogida: ElementRef;
  @ViewChild('HoraRecogidaInicio1') horaRecogidaInicio1: ElementRef;
  @ViewChild('HoraRecogidaFinal1') horaRecogidaFinal1: ElementRef;
  @ViewChild('PersonaContactoRecogida') personaContactoRecogida: ElementRef;
  @ViewChild('TelefonoContactoRecogida') telefonoContactoRecogida: ElementRef;
  @ViewChild('EmailRecogida') emailRecogida: ElementRef;
  @ViewChild('EntidadEntrega') entidadEntrega: ElementRef;
  @ViewChild(CargoComponent) appCargo: CargoComponent;

  @Input() esTarifa: boolean;

  isCotizacion: boolean = false;
  clase: string = "app-aereo-shipment";
  texto: string = "";
  visor: boolean;
  editar: boolean;
  duplicar: boolean;
  shipmentAereo: Shipment;
  form: NgForm;
  cotizacion: string;
  error: boolean = false;
  maxlengthInstrucctions: number = 255;
  maxlengthObservaciones: number = 500;
  maxlengthMarcas: number = 255;
  maxlengthInstruccionesBLAWB: number = 500;
  codigosUn: Codigosun[] = [];
  errorInstruccionesBLAWB: boolean = false;
  errorPesoBruto: boolean = false;
  errorPesoNeto: boolean = false;
  errorFechaRecogidaMenorActual: Boolean = false;
  today: Date = new Date();
  errorPesoNetoVacio: boolean = false;
  errorPesoBrutoVacio: boolean = false;
  errorPesoNetoDGR: boolean = false;
  tarifa: TarifaEnvio;
  errorPesoNetoTotalDGRdistinto: boolean = false
  totalNetoDgrLineas: number




  formModelAereo = new FormControl({
    IdEnvio: null,
    Envio: 'AEREO',
    TipoEnvio: 3,
    Cliente: '',
    IdArea: 3,
    Referencia: '',
    Shipper: '',
    DireccionShipper: '',
    Consignee: '',
    DireccionConsignee: '',
    Notify: '',
    DireccionNotify: '',
    Incoterm: '',
    ValorMercancia: '',
    TipoDivisa: '',
    SeguroMercancia: '',
    Asegurado: false,
    AeropuertoOrigen: '',
    AeropuertoDestino: '',
    Aerolinea: '',
    Refrigerada: '',
    Perecedero: '',
    //NoRemontable: false,
    Fumigado: '',
    EntidadRecogida: null, //Recogida
    ReferenciaRecogida: '',
    DireccionRecogida: null,
    FechaRecogida: '',
    InstruccionesRecogida: '',
    HoraRecogidaInicio1: '',
    HoraRecogidaFinal1: '',
    HoraRecogidaInicio2: '',
    HoraRecogidaFinal2: '',
    PersonaContactoRecogida: '',
    TelefonoRecogida: '',
    EmailRecogida: '',
    DespachoOrigen: '', //Despacho Aduanas
    DespachoDestino: '',
    EntidadEntrega: null, //Entrega
    DireccionEntrega: null,
    ReferenciaEntrega: '',
    InstruccionesEntrega: '',
    PersonaContactoEntrega: '',
    TelefonoEntrega: '',
    EmailEntrega: '',
    MercanciaPeligrosa: false,
    Mercancia: [],
    PesoNetoTotalDGR: null,
    PesoBrutoTotalDGR: null,
    BultosTotales: null,
    PesoNetoTotal: null,
    PesoBrutoTotal: null,
    PesoTasable: null,
    VolumenTotal: null,
    InstruccionesBLAWB: '',
    Marcas: '',
    Instrucciones: '',
    Archivo: [],
    Cotizacion: 0,
    Tarifa: null
    // VGMPesaje: null,
    // VGMTransmision: null
  })

  bultos: number = 0;
  pesoNeto: number = 0;
  pesoBruto: number = 0;
  volumen: number = 0;

  archivoBooking: Archivo = new Archivo();
  listTipoEnvio: TipoEnvio[] = [];
  listShipper: Entity[] = [];
  listConsignee: Entity[] = [];
  listNotify: Entity[] = [];
  readonlyDireccionShipper: boolean = false;
  readonlyDireccionConsignee: boolean = false;
  readonlyDireccionNotify: boolean = false;
  resumenDireccionRecogida: String = '';
  resumenDireccionEntrega: String = '';
  tipoDireccion: string = '';

  listIncoterm: Incoterms[];
  listAeropuertoOrigen: Aeropuerto[];
  listAeropuertoOrigenFiltrado: Aeropuerto[];
  listAeropuertoDestino: Aeropuerto[];
  listAeropuertoDestinoFiltrado: Aeropuerto[];
  listAerolinea: Aerolinea[];
  listaMercancia: Mercancia[] = []; //pasado por parametro a cargo
  listaArchivos: Archivo[] = []; //pasarlo en post
  cargo: Mercancia = new Mercancia(); //pasarlo en post
  auxVol: number;
  errorBrutoTotal = false
  paises: Paises[] = [];
  localidades: Localidades[] = [];
  idEnvio: number;
  partida: string;
  expedicion: string;
  envio: Shipment; //pasarlo en post
  aerolinea: String;
  direccionRecogida: Direccion;
  direccionEntrega: Direccion;
  EntidadRecogidaInformation: String;
  listDivisas: TipoDivisa[];
  mostrarEntrega: Boolean;
  modalRef: BsModalRef | null;
  referenciaClienteAnterior: string;
  searchText: string;
  tipoEntidad: string;
  esFCL: Boolean = false;
  errorTipoEnvio: Boolean = false;
  errorReferencia: Boolean = false;
  errorListShipper: Boolean = false;
  errorListConsignee: Boolean = false;
  errorIncoterm: Boolean = false;
  errorBultosTotales: Boolean = false;
  errorPersonaContactoRecogida: Boolean = false;
  errorEmailRecogida: Boolean = false;
  errorPesoNetoTotal: Boolean = false;
  errorPesoBrutoTotal: Boolean = false;
  errorPesoBrutoTotalDGR: Boolean = false;
  errorPesoNetoTotalDGR: Boolean = false;
  //errorDiferenciaPesosDGR: Boolean = false;
  errorVolumenTotal: Boolean = false;
  errorTelefonoRecogida: Boolean = false;
  errorFechaRecogida: Boolean = false;
  errorHoraRecogidaInicio1: Boolean = false;
  errorHoraRecogidaFinal1: Boolean = false;
  errorMercancia: Boolean = false;
  errorPersonaContactoEntrega: Boolean = false;
  errorEmailEntrega: Boolean = false;
  errorTelefonoEntrega: Boolean = false;
  errorEntidadRecogida: Boolean = false;
  errorEntidadEntrega: Boolean = false;
  errorValorMercancia: Boolean = false;
  errorReferenciaEntrega: Boolean = false;
  errorReferenciaRecogida: Boolean = false;
  muestraErrorBrutoDGR: Boolean = false;
  muestraErrorNetoDGR: Boolean = false;
  confirmSeguro: Boolean = false;
  cargaTipoEnvio: boolean = true;
  cargaRemitente: boolean = true;
  cargaDestinatario: boolean = true;
  cargaNotify: boolean = false;
  cargaIncoterm: boolean = true;
  cargaTipoDivisa: boolean = true;
  cargaAeropuerto = true;
  cargaEntidadRecogida = true;
  cargaEntidadEntrega = true;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  entidad: Entity;
  entidadCliente: number;
  datosContacto: DatosContacto;
  configEntidad: ConfigEntidad;
  refCliDuplicado: Boolean = false;
  chShipper: Boolean = false;
  chConsignee: Boolean = false;
  chNotify: Boolean = false;
  corresponsal: Corresponsal;
  hayAereopuertoOrigen: boolean;

  constructor(private envioService: ShipmentService, private toastr: ToastrService, private router: Router, private mercanciaService: MercanciaService,
    private modalService: BsModalService, private translateService: TranslateService, private usuarioService: UsuarioService, private cotizacionService: CotizacionService,
    private entidadService: EntityService, public dialog: MatDialog, private fileService: FileShipService, private configurationService: ConfigurationService,
    private tarifaService: TarifaService) {
    this.confirmSeguro = false;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.isCotizacion = this.envioService.getCotizacionBoolean();
  }

  //Funcion que se ejecuta al iniciar el componente cada vez que se abre
  ngOnInit(): void {
    this.inicializar();

  }

  ngOnDestroy() {
    this.tarifaService.tarifa = this.tarifaService.tarifaCotizacion = this.tarifaService.tarifaFiltrada = null;
  }

  inicializar() {
    this.formModelAereo = new FormControl({
      IdEnvio: null,
      Envio: 'AEREO',
      TipoEnvio: 3,
      Cliente: '',
      IdArea: 3,
      Referencia: '',
      Shipper: '',
      DireccionShipper: '',
      Consignee: '',
      DireccionConsignee: '',
      Notify: '',
      DireccionNotify: '',
      Incoterm: '',
      ValorMercancia: '',
      TipoDivisa: '',
      SeguroMercancia: '',
      Asegurado: false,
      AeropuertoOrigen: '',
      AeropuertoDestino: '',
      Aerolinea: '',
      Refrigerada: '',
      Perecedero: '',
      //NoRemontable: false,
      Fumigado: '',
      EntidadRecogida: null, //Recogida
      ReferenciaRecogida: '',
      DireccionRecogida: null,
      FechaRecogida: '',
      InstruccionesRecogida: '',
      HoraRecogidaInicio1: '',
      HoraRecogidaFinal1: '',
      HoraRecogidaInicio2: '',
      HoraRecogidaFinal2: '',
      PersonaContactoRecogida: '',
      TelefonoRecogida: '',
      EmailRecogida: '',
      DespachoOrigen: '', //Despacho Aduanas
      DespachoDestino: '',
      EntidadEntrega: null, //Entrega
      DireccionEntrega: null,
      ReferenciaEntrega: '',
      InstruccionesEntrega: '',
      PersonaContactoEntrega: '',
      TelefonoEntrega: '',
      EmailEntrega: '',
      MercanciaPeligrosa: false,
      Mercancia: [],
      PesoNetoTotalDGR: null,
      PesoBrutoTotalDGR: null,
      BultosTotales: null,
      PesoNetoTotal: null,
      PesoBrutoTotal: null,
      PesoTasable: null,
      VolumenTotal: null,
      InstruccionesBLAWB: '',
      Marcas: '',
      Instrucciones: '',
      Archivo: [],
      Cotizacion: 0,
      // VGMPesaje: null,
      // VGMTransmision: null,
      RefCotizacion: null
    });

    this.confirmSeguro = false;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.entidad = null;
    this.datosContacto = new DatosContacto();
    this.obtenerCodigosUN();
    document.getElementById("Referencia").focus();
    this.tipoEntidad = '';
    this.referenciaClienteAnterior = '';
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.getEntidad();
    this.getShipper();
    this.cargarTipoEnvio();
    this.cargarListaConsignee();
    this.cargarIdioma();
    this.cargarTipoDivisa();
    this.cargarPaises();
    this.cargarIncoterms();
    this.cargarAeropuertos();
    this.cargarDatosContactoUsuario();
    this.cargarConfigEntidad();
    this.getCorresponsal();
    this.cargarHorario();
    this.cargarEnvio();
    this.getTarifa();
    if (this.appCargo != undefined && this.appCargo != null)
      this.appCargo.ngOnInit();
  }

  cargarHorario() {
    this.configurationService.getHorarios$().subscribe(horarios => {
      this.formModelAereo.value.HoraRecogidaInicio1 = horarios.he1;
      this.formModelAereo.value.HoraRecogidaInicio2 = horarios.he2;
      this.formModelAereo.value.HoraRecogidaFinal1 = horarios.he3;
      this.formModelAereo.value.HoraRecogidaFinal2 = horarios.he4;
      this.formModelAereo.value.HoraEntregaInicio1 = horarios.hr1;
      this.formModelAereo.value.HoraEntregaInicio2 = horarios.hr2;
      this.formModelAereo.value.HoraEntregaFinal1 = horarios.hr3;
      this.formModelAereo.value.HoraEntregaFinal2 = horarios.hr4;
    });
    this.configurationService.getHorarios();
  }

  isUpdate() {
    return this.tarifa != null && this.tarifa != undefined && (this.tarifa.IdAeropuertoDestino != undefined || this.tarifa.IdAeropuertoOrigen != undefined);
  }

  setTarifa() {
    if (this.isUpdate()) {
      this.esTarifa = true;
      this.formModelAereo.value.TipoEnvio = this.tarifa.IdTipoEnvio;
      this.formModelAereo.value.ModoEnvio = this.tarifa.IdModoEnvio;
      this.formModelAereo.value.Naviera = this.tarifa.IdNaviera;
      if (this.formModelAereo.value.AeropuertoOrigen != '') {
        this.formModelAereo.value.AeropuertoOrigen = this.tarifa.IdAeropuertoOrigen;
        this.hayAereopuertoOrigen = true;

      }
      else
        this.hayAereopuertoOrigen = false;
      this.formModelAereo.value.AeropuertoDestino = this.tarifa.IdAeropuertoDestino;
      this.listaMerc(this.tarifa.Mercancia);
      this.formModelAereo.value.PesoBrutoTotal = this.tarifa.PesoBrutoTotal;
      this.formModelAereo.value.PesoNetoTotal = this.tarifa.PesoNetoTotal;
      this.formModelAereo.value.VolumenTotal = this.tarifa.VolumenTotal;
      this.formModelAereo.value.PesoTasable = this.tarifa.PesoTasable;
      this.formModelAereo.value.BultosTotales = this.tarifa.Bultos;
      this.formModelAereo.value.PesoBrutoTotalDGR = this.tarifa.PesoBrutoTotalDGR;
      this.formModelAereo.value.PesoNetoTotalDGR = this.tarifa.PesoNetoTotalDGR;
    }

  }

  getTarifa() {
    this.tarifa = this.tarifaService.tarifa
    if (this.tarifa != null && this.tarifa != undefined) {
      this.formModelAereo.value.Tarifa = this.tarifaService.tarifaFiltrada;
      this.setTarifa();
    }
    else
      this.formModelAereo.value.Tarifa = null;
  }

  getCorresponsal() {
    this.entidadService.getCorresponsal().subscribe(data => {
      this.corresponsal = data;
    });
  }

  obtenerCodigosUN() {
    this.mercanciaService.getCodigosUN().subscribe(data => this.codigosUn = data);
  }

  cargarConfigEntidad() {
    this.entidadService.getConfigEntidad().subscribe(data => {
      this.configEntidad = data;
      if (this.listIncoterm.find(i => i.IdIncoterm == this.configEntidad.IncotermAereo))
        this.formModelAereo.value.Incoterm = this.configEntidad.IncotermAereo;
      //this.formModelAereo.value.MercanciaPeligrosa = this.configEntidad.MercanciaPeligrosa;
    },
      error => {

      })
  }

  cargarDatosContactoUsuario() {
    this.usuarioService.getDatosContacto().subscribe(data => {
      this.datosContacto = data;
      this.formModelAereo.value.PersonaContactoRecogida = this.datosContacto.NombreUsuario;
      this.formModelAereo.value.TelefonoRecogida = this.datosContacto.Telefono;
      this.formModelAereo.value.EmailRecogida = this.datosContacto.Email;
    }, error => {
      //No tiene todos los datos de contacto
    })
  }

  getEntidad() {
    this.envioService.getEntidadUsuario().subscribe(entidad => {
      this.entidadCliente = entidad;
    })
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if (this.langs.includes(lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarEnvio() {
    this.partida = this.envioService.getPartida();
    this.expedicion = this.envioService.getExpedicion();
    this.cotizacion = this.envioService.getCotizacion();
    if (this.partida != null && this.partida != undefined && this.partida != "") {
      this.envioService.getShipmentByPartidaExpedicion(this.partida).subscribe(data => {
        this.envio = data;
        this.asignarValores(this.envio);
        this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    } else if (this.expedicion != null && this.expedicion != undefined && this.expedicion != "") {
      this.envioService.getShipmentByPartidaExpedicion(this.expedicion).subscribe(data => {
        this.envio = data;
        this.asignarValores(this.envio);
        this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    } else if (this.cotizacion != null && this.cotizacion != undefined && this.cotizacion != "") {
      this.cotizacionService.getBooking(this.cotizacion, null).subscribe(data => {
        this.envio = data;
        this.envio.IdCotizacion = this.cotizacion;
        this.envioService.setCotizacionBoolean(true);
        this.asignarValores(this.envio);
        //this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    }
  }

  cargarTipoDivisa() {
    this.envioService.getTiposDivisa().subscribe(data => {
      this.cargaTipoDivisa = true;
      this.listDivisas = data;
      this.formModelAereo.value.TipoDivisa = this.listDivisas[0].IdTipoDivisa;
      this.cargaTipoDivisa = false;
    })
  }

  cargarListaConsignee() {
    // this.envioService.getConsignee(this.esExport()).subscribe(data => {
    //   this.cargaDestinatario = true;
    //   this.cargaEntidadEntrega = true;
    //   this.listConsignee = data;
    //   this.cargaDestinatario = false;
    //   this.cargaEntidadEntrega = false;
    //   if (this.idEnvio != -1) {
    //     this.visor = this.envioService.getVisor();
    //     if (this.envio != undefined) {
    //       this.asignarValores(this.envio);
    //     }
    //   }
    // }, err => { });
  }

  cargarListaNotify() {
    if (this.formModelAereo.value.Consignee != undefined && this.formModelAereo.value.Consignee != null) {
      this.envioService.getNotify(this.formModelAereo.value.Consignee.IdEntidad).subscribe(data => {
        this.cargaNotify = true;
        this.listNotify = data;
        this.cargaNotify = false;
      });
    }
  }

  cargarPaises() {
    this.envioService.getPaises().subscribe(data => {
      this.paises = data;

      if (this.idEnvio != -1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    });
  }

  cargarLocalidades() {
    this.envioService.getLocalidades().subscribe(data => {
      this.localidades = data;
    });
  }

  cargarTipoEnvio() {
    this.envioService.getTiposEnvio(3).subscribe(data => {
      this.cargaTipoEnvio = true;
      this.listTipoEnvio = data;
      this.cargaTipoEnvio = false;
    });
  }

  cargarIncoterms() {
    this.envioService.getIncoterms(this.formModelAereo.value.IdArea).subscribe(data => {
      this.cargaIncoterm = true;
      this.listIncoterm = data;
      this.cargaIncoterm = false;

      if (this.idEnvio != -1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    })
  }

  cargarAeropuertos() {
    this.envioService.getAeropuertos().subscribe(data => {
      this.cargaAeropuerto = true;
      this.listAeropuertoDestino = data;
      this.listAeropuertoOrigen = data;
      this.cargaAeropuerto = false;

      if (this.idEnvio != -1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    })
  }

  getShipper() {
    this.envioService.getEntidadesAddressBook().subscribe(data => {
      this.entidadService.getEntidadById(this.entidadCliente).subscribe(entidadCliente => {
        this.listShipper = data;
        this.listConsignee = data;
        if (this.esExport()) {
          this.listShipper.push(entidadCliente);
          // this.listShipper.forEach(shipper => {
          this.formModelAereo.value.Shipper = entidadCliente;
          this.formModelAereo.value.EntidadRecogida = entidadCliente;
          this.formModelAereo.value.Cliente = entidadCliente;
          this.setShipperInformation();
          // });
        }
        else {
          this.listConsignee.push(entidadCliente);
          // this.listConsignee.forEach(consignee => {
          this.formModelAereo.value.Consignee = entidadCliente;
          this.formModelAereo.value.EntidadEntrega = entidadCliente;
          this.formModelAereo.value.Cliente = entidadCliente;
          this.setConsigneeInformation();
          // });
        }
        if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
          this.visor = this.envioService.getVisor();
          if (this.envio != undefined) {
            this.asignarValores(this.envio);
          }
        } else if (this.partida != null && this.partida != undefined) {
          this.asignarValores(this.envio);
        }
        this.cargaRemitente = this.cargaDestinatario = false;
        this.cargaEntidadRecogida = this.cargaEntidadEntrega = false;
        this.inicializarDatos();
      });
    });
    //Si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
    // this.envioService.getEntidadUsuario().subscribe(entidad => {
    //   this.envioService.getShipper(this.esExport()).subscribe(data => {
    //     this.cargaRemitente = true;
    //     this.cargaEntidadRecogida = true;
    //     this.listShipper = data;
    //     console.log(data);
    //     this.listShipper.forEach(shipper => {
    //       if (shipper.IdEntidad == entidad) {
    //         this.formModelAereo.value.Shipper = shipper;
    //         this.formModelAereo.value.EntidadRecogida = shipper;
    //         this.formModelAereo.value.Cliente = shipper;
    //         this.setShipperInformation();
    //         this.cargaRemitente = false;
    //         this.cargaEntidadRecogida = false;
    //       }

    //       if (this.idEnvio != -1) {
    //         this.visor = this.envioService.getVisor();
    //         if (this.envio != undefined) {
    //           this.asignarValores(this.envio);
    //         }
    //       }
    //      this.inicializarDatos();
    //     });
    //   });
    // });
  }

  //Funcion que comprueba que si se marca la casilla de peligrosa, tenga mercancia peligrosa
  compruebaMercanciaPeligrosa(): Boolean {
    let count = 0;
    if (this.shipmentAereo.MercanciaPeligrosa == 1) {
      this.shipmentAereo.Mercancia.forEach(mercancia => {
        if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length >= 0) {
          count++;
        }
      })
    } else {
      count = 1;
    }
    if (count == 0) {
      return false;
    }
    return true;
  }

  compruebaIncotermEntrega() {
    let correcto = true;
    if (this.formModelAereo.value.Incoterm.startsWith('D')) {
      if (this.formModelAereo.value.IdEntidadEntrega != null && this.formModelAereo.value.IdEntidadEntrega != undefined && this.formModelAereo.value.IdEntidadEntrega != ''
        && this.formModelAereo.value.IdDireccionEntrega != null && this.formModelAereo.value.IdDireccionEntrega != undefined && this.formModelAereo.value.IdDireccionEntrega != '') {
        correcto = false;
      }
    }
    return correcto;
  }

  compruebaErrores(form: NgForm) {
    this.compruebaTipoEnvio(form); this.compruebaReferencia(form); this.compruebaListShipper(form); this.compruebaListConsignee(form);
    this.compruebaIncoterm(form); this.compruebaBultosTotales(form); this.compruebaEmailEntrega(form);
    this.compruebaEmailRecogida(form); this.compruebaMercancia(); this.compruebaPersonaContactoEntrega(form);
    this.compruebaPersonaContactoRecogida(form); this.compruebaTelefonoEntrega(form); this.compruebaTelefonoRecogida(form);
    this.compruebaPesoBrutoTotal(form); this.compruebaPesoNetoTotalDGR(form); this.compruebaInstruccionesBLAWB(form);
    this.compruebaPesoNetoTotal(form); this.compruebaVolumenTotal(form); this.compruebaFechaRecogida(form); this.compruebaEntidadEntrega(form);
    this.compruebaEntidadRecogida(form); this.compruebaValorMercancia(form); this.compruebaReferenciaEntrega(form); this.compruebaReferenciaRecogida(form);
  }

  envioAereoOk(form: NgForm) {
    return (!this.compruebaTipoEnvio(form) && !this.compruebaReferencia(form) && !this.compruebaListShipper(form) && !this.compruebaListConsignee(form)
      && !this.compruebaIncoterm(form) && !this.compruebaBultosTotales(form) && !this.compruebaEmailRecogida(form) && !this.compruebaMercancia() && !this.compruebaInstruccionesBLAWB(form)
      && !this.compruebaPersonaContactoRecogida(form) && !this.compruebaTelefonoRecogida(form) && !this.errorPesoNetoTotalDGR
      && !this.compruebaEntidadRecogida(form) && !this.compruebaPesoNetoTotal(form) && !this.compruebaVolumenTotal(form)
      && !this.compruebaFechaRecogida(form) && !this.compruebaValorMercancia(form))
  }

  inicializarDatos() {
    //this.formModelAereo.value.TipoEnvio = 3;
    this.formModelAereo.value.TipoDivisa = this.listDivisas[0].IdTipoDivisa;
    this.formModelAereo.value.Asegurado = false;
    //this.formModelAereo.value.NoRemontable = false;
  }

  vaciarForm(form: NgForm) {
    this.formModelAereo.value.DireccionShipper = '';
    this.EntidadRecogidaInformation = '';
    this.formModelAereo.value.BultosTotales = '';
    this.formModelAereo.value.PesoNetoTotal = '';
    this.formModelAereo.value.PesoBrutoTotal = '';
    this.formModelAereo.value.VolumenTotal = '';
    this.formModelAereo.value.PesoNetoTotalDGR = '';
    this.formModelAereo.value.PesoBrutoTotalDGR = '';
    this.formModelAereo.value.DireccionConsignee = '';
    this.formModelAereo.value.InstruccionesRecogida = '';
    this.formModelAereo.value.InstruccionesEntrega = '';
    this.shipmentAereo = new Shipment();
    this.formModelAereo.value.DireccionNotify = '';
    this.listaMercancia = [];
    this.formModelAereo.value.Mercancia = [];
    this.formModelAereo.value.EntidadEntrega = null;
    this.formModelAereo.value.EntidadRecogida = null;
    this.formModelAereo.value.ReferenciaEntrega = '';
    this.formModelAereo.value.MercanciaPeligrosa = false;
    this.formModelAereo.value.chShipper = false;
    this.formModelAereo.value.chConsignee = false;
    this.formModelAereo.value.chNotify = false;
    this.chConsignee = false;
    this.chShipper = false;
    this.chNotify = false;
    this.formModelAereo = new FormControl({
      IdEnvio: null,
      Envio: 'AEREO',
      TipoEnvio: 3,
      Cliente: '',
      IdArea: 3,
      Referencia: '',
      Shipper: '',
      DireccionShipper: '',
      Consignee: '',
      DireccionConsignee: '',
      Notify: '',
      DireccionNotify: '',
      Incoterm: '',
      ValorMercancia: '',
      TipoDivisa: '',
      SeguroMercancia: '',
      Asegurado: false,
      AeropuertoOrigen: '',
      AeropuertoDestino: '',
      Aerolinea: '',
      Refrigerada: '',
      Perecedero: '',
      //NoRemontable: false,
      Fumigado: '',
      EntidadRecogida: null, //Recogida
      ReferenciaRecogida: '',
      DireccionRecogida: null,
      FechaRecogida: '',
      InstruccionesRecogida: '',
      HoraRecogidaInicio1: '',
      HoraRecogidaFinal1: '',
      HoraRecogidaInicio2: '',
      HoraRecogidaFinal2: '',
      PersonaContactoRecogida: '',
      TelefonoRecogida: '',
      EmailRecogida: '',
      DespachoOrigen: '', //Despacho Aduanas
      DespachoDestino: '',
      EntidadEntrega: null, //Entrega
      DireccionEntrega: null,
      ReferenciaEntrega: '',
      InstruccionesEntrega: '',
      PersonaContactoEntrega: '',
      TelefonoEntrega: '',
      EmailEntrega: '',
      MercanciaPeligrosa: false,
      Mercancia: [],
      PesoNetoTotalDGR: null,
      PesoBrutoTotalDGR: null,
      BultosTotales: null,
      PesoNetoTotal: null,
      PesoBrutoTotal: null,
      PesoTasable: null,
      VolumenTotal: null,
      InstruccionesBLAWB: '',
      Marcas: '',
      Instrucciones: '',
      Archivo: [],
      Cotizacion: 0,
      // VGMPesaje: null,
      // VGMTransmision: null,
      RefCotizacion: null
    });
    this.cargarListaConsignee();
    this.getShipper();
    this.cargarDatosContactoUsuario();
    this.cargarConfigEntidad();
  }

  peticionEnvio(form: NgForm) {
    if (!this.comprobarErrores()) {
      if (this.compruebaIncotermEntrega()) {
        this.convertFormToModel(form);
        if (this.compruebaMercanciaPeligrosa() && this.compruebaPesoNetoTotalDGRIgual()) {
          if (this.editar) {
            this.shipmentAereo.IdEnvio = this.idEnvio;
            var log: LogModel = new LogModel();
            log.Click = "modificarShipment";
            log.Pantalla = this.clase;
            const dialogRef = this.dialog.open(WaitingComponent, {
              width: '250px',
              panelClass: 'my-panel',
              disableClose: true
            });
            dialogRef.afterOpened().subscribe(result => { });
            //Petición en el cual se solicita el listado de envíos realizados
            this.envioService.putEnvioAereo(this.shipmentAereo, this.idEnvio, log).subscribe(data => {
              dialogRef.close();
              var Body = this.translateService.instant('El envío se ha modificado correctamente');
              var Titulo = this.translateService.instant('Envío correcto');
              this.toastr.success(Body, Titulo)
              this.envio = null;
              this.envioService.setIdEnvio(-1);
              this.idEnvio = -1;
              form.resetForm();
              this.inicializar();
            },
              error => {
                dialogRef.close();
                var Body = this.translateService.instant('Ha habido un problema al actualizar el envío.');
                var Titulo = this.translateService.instant('Error en el envío');
                this.toastr.error(Body, Titulo)
              });
          }
          else {
            var log: LogModel = new LogModel();
            log.Click = "enviarShipment";
            log.Pantalla = this.clase;
            const dialogRef = this.dialog.open(WaitingComponent, {
              width: '250px',
              panelClass: 'my-panel',
              disableClose: true
            });
            dialogRef.afterOpened().subscribe(result => { });
            var archivos = this.shipmentAereo.Archivos;
            if (this.archivoBooking.Base64image != null && this.archivoBooking.Base64image != undefined && this.archivoBooking.Base64image != '')
              archivos.push(this.archivoBooking);
            this.shipmentAereo.Archivos = [];
            this.envioService.postEnvioAereo(this.shipmentAereo, log, this.translateService.getDefaultLang().toString()).subscribe(data => {
              var total = 0;
              var enviarArchivos = [];
              archivos.forEach(a => {
                var envioDevuelto = data as Shipment;
                a.IdEnvio = envioDevuelto.IdEnvio;
                if (Number(total) + Number(a.Tamanyo) <= 25000000 && enviarArchivos.length <= 3) { // 25 MB
                  total += a.Tamanyo;
                  enviarArchivos.push(a);
                } else {
                  this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
                    // REINTENTAMOS PARA VOLVER A SUBIRLOS.
                    this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
                      var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con nosotros.');
                      var Titulo = this.translateService.instant('Error en los documentos');
                      this.toastr.error(Body, Titulo)
                    })
                  })
                  enviarArchivos = [];
                  enviarArchivos.push(a);
                  total = 0;
                  total += a.Tamanyo;
                }
              })
              if (enviarArchivos.length > 0) {
                this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
                  dialogRef.close();
                  var Body = this.translateService.instant('El envío se ha realizado correctamente');
                  var Titulo = this.translateService.instant('Envío correcto');
                  this.toastr.success(Body, Titulo)
                  this.envio = null;
                  this.envioService.setIdEnvio(-1);
                  this.idEnvio = -1;
                  form.resetForm();
                  this.inicializar();
                }, err => {
                  this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
                    dialogRef.close();
                    var Body = this.translateService.instant('El envío se ha realizado correctamente');
                    var Titulo = this.translateService.instant('Envío correcto');
                    this.toastr.success(Body, Titulo)
                    this.envio = null;
                    this.envioService.setIdEnvio(-1);
                    this.idEnvio = -1;
                    form.resetForm();
                    this.inicializar();
                  }, err => {
                    dialogRef.close();
                    var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con XGL.');
                    var Titulo = this.translateService.instant('Error en los documentos');
                    this.toastr.error(Body, Titulo)
                  })
                })
              } else {
                dialogRef.close();
                var Body = this.translateService.instant('El envío se ha realizado correctamente');
                var Titulo = this.translateService.instant('Envío correcto');
                this.toastr.success(Body, Titulo)
                this.envio = null;
                this.envioService.setIdEnvio(-1);
                this.idEnvio = -1;
                form.resetForm();
                this.inicializar();
              }

            },
              error => {
                dialogRef.close();
                var Body = this.translateService.instant('Ha habido un problema al crear el envío.');
                var Titulo = this.translateService.instant('Error en el envío');
                this.toastr.error(Body, Titulo)
              });
          }
        }
        else {
          var Body = this.translateService.instant('Se ha marcado la casilla de mercancia peligrosa y no hay mercancia peligrosa.');
          var Titulo = this.translateService.instant('Error mercancia peligrosa');
          this.toastr.error(Body, Titulo)
        }
      }
      else {
        var Body = this.translateService.instant('Falta la entidad de entrega y/o la direccion');
        var Titulo = this.translateService.instant('Error recogida');
        this.toastr.error(Body, Titulo)
      }
    }
  }

  getTituloRecogida() {
    if (this.corresponsal != null && this.corresponsal != undefined && (this.corresponsal.IdDelegacion == 2 || this.corresponsal.IdDelegacion == 5))
      return this.translateService.instant("RecogidaMexico");
    return this.translateService.instant("Recogida");
  }

  //Funcion que se usa para enviar el formulario al servidor
  envioAereo(form: NgForm) {
    if (this.envioAereoOk(form)) {
      if (!this.refCliDuplicado) {
        if (this.incotermStartWith()) {
          if (!this.compruebaPersonaContactoEntrega(form) && !this.compruebaTelefonoEntrega(form) && !this.compruebaEmailEntrega(form)) {
            this.peticionEnvio(form);
          }
          else {
            this.compruebaPersonaContactoEntrega(form);
            this.compruebaTelefonoEntrega(form);
            this.compruebaEmailEntrega(form);
            var Body = this.translateService.instant('Los datos de entrega son obligatorios.');
            var Titulo = this.translateService.instant('Error datos entrega');
            this.toastr.error(Body, Titulo)
          }
        }
        else {
          this.peticionEnvio(form);
        }
      }
      else {
        var Body = this.translateService.instant('La referencia ya ha sido introducida en el sistema');
        var Titulo = this.translateService.instant('Error duplicidad');
        this.toastr.error(Body, Titulo)
      }
    }
    else {
      this.compruebaErrores(form);
      this.error = true;
      var Body = this.translateService.instant('Faltan campos por completar.');
      var Titulo = this.translateService.instant('Error en el envío');
      this.toastr.error(Body, Titulo)
    }
  }

  //Funcion que pone la direccion y la localidad de donde se va a recoger
  setRecogidaInformation() {
    this.resumenDireccionRecogida = '';
    this.resumenDireccionRecogida = this.getFormatDireccion(this.formModelAereo.value.EntidadRecogida);
  }

  //Funcion que pone la direccion y la localidad de donde se va a entregar
  setEntregaInformation() {
    this.resumenDireccionEntrega = '';
    this.resumenDireccionEntrega = this.getFormatDireccion(this.formModelAereo.value.EntidadEntrega);
  }


  //Funcion que obtiene el tipo de envio
  setTipoEnvio(tipoEnvio: TipoEnvio) {
    this.envioService.getEntidadUsuario().subscribe(entidad => {
      if (tipoEnvio != undefined) {
        this.formModelAereo.value.TipoEnvio = tipoEnvio.IdTipoEnvio;
        this.formModelAereo.value.Shipper = null;
        this.formModelAereo.value.DireccionShipper = '';
        this.formModelAereo.value.EntidadRecogida = '';
        this.resumenDireccionRecogida = '';
        this.formModelAereo.value.EntidadRecogida = null;
        this.cargaRemitente = false;
        this.cargaEntidadRecogida = false;
        this.formModelAereo.value.PersonaContactoRecogida = '';
        this.formModelAereo.value.TelefonoRecogida = '';
        this.formModelAereo.value.EmailRecogida = '';
        if (this.esExport()) {
          var lista = this.listConsignee;
          this.listConsignee = this.listShipper;
          this.listShipper = lista;
          this.listShipper.forEach(shipper => {
            if (shipper.IdEntidad == entidad) {
              this.formModelAereo.value.Consignee = null;
              this.formModelAereo.value.EntidadEntrega = null;
              this.setConsigneeInformation();
              this.formModelAereo.value.Shipper = shipper;
              this.formModelAereo.value.EntidadRecogida = shipper;
              this.formModelAereo.value.Cliente = shipper;
              this.setShipperInformation();
            }
          });
        } else {
          var lista = this.listShipper;
          this.listShipper = this.listConsignee;
          this.listConsignee = lista;
          this.listConsignee.forEach(consignee => {
            if (consignee.IdEntidad == entidad) {
              this.formModelAereo.value.Shipper = null;
              this.formModelAereo.value.EntidadRecogida = null;
              this.setShipperInformation();
              this.formModelAereo.value.Consignee = consignee;
              this.formModelAereo.value.EntidadEntrega = consignee;
              this.formModelAereo.value.Cliente = consignee;
              this.setConsigneeInformation();
            }
          });
        }
        if (this.datosContacto != null && this.datosContacto != undefined && this.esExport()) {
          this.formModelAereo.value.PersonaContactoEntrega = this.datosContacto.NombreUsuario;
          this.formModelAereo.value.TelefonoEntrega = this.datosContacto.Telefono;
          this.formModelAereo.value.EmailEntrega = this.datosContacto.Email;
        }
      }
    })
  }

  //Funcion que obtiene el tipo de envio
  asignarTipoEnvio(envioAereo: Shipment) {
    this.formModelAereo.value.TipoEnvio = envioAereo.IdTipoEnvio;
  }

  asignarShipper(envioAereo: Shipment) {
    if (this.listShipper.length >= 0) {
      this.listShipper.forEach(shipper => {
        if (shipper.IdEntidad == envioAereo.IdRemitente) {
          this.formModelAereo.value.Shipper = shipper;
          this.setShipperInformation();
        }
      });
    }
  }

  //Funcion que asigna el consignee
  asignarConsignee(envioAereo: Shipment) {
    if (this.listConsignee.length >= 0) {
      this.listConsignee.forEach(consignee => {
        if (consignee.IdEntidad == envioAereo.IdDestinatario) {
          this.formModelAereo.value.Consignee = consignee;
          this.setConsigneeInformation();
          this.asignarNotify(envioAereo);
        }
      });
    }
  }

  //Funcion que asigna el notify
  asignarNotify(envioAereo: Shipment) {
    if (this.listNotify.length >= 0) {
      if (envioAereo.IdNotify == envioAereo.IdDestinatario || envioAereo.IdNotify == -1 || envioAereo.IdNotify == 0) {
        this.formModelAereo.value.Notify = "THE SAME";
      }
      else {
        this.listNotify.forEach(notify => {
          if (notify.IdEntidad == envioAereo.IdNotify) {
            this.formModelAereo.value.Notify = notify;
            this.setNotifyInformation();
          }
        })
      }
    }
  }

  //Funcion que asigna el incoterm
  asignarIncoterm(envioAereo: Shipment) {
    if (this.listIncoterm.length >= 0 && this.formModelAereo.value.Incoterm == '') {
      this.formModelAereo.value.Incoterm = envioAereo.Incoterm;
    }
  }

  //asignamos la fecha de recogida
  asignarFechaRecogida(envioAereo: Shipment) {
    if (envioAereo.FechaRecogida != undefined && envioAereo.FechaRecogida != null) {
      let fecha = envioAereo.FechaRecogida.toString().split('T')[0];
      this.formModelAereo.value.FechaRecogida = fecha;
    }
  }

  //Funcion que trae los valores de la clase al form
  asignarValores(envioAereo: Shipment) {
    if (envioAereo != undefined && this.formModelAereo.value != null && this.formModelAereo.value != undefined) {
      this.editar = this.envioService.getEditar();
      this.duplicar = this.envioService.getDuplicar();
      this.isCotizacion = this.envioService.getCotizacionBoolean();

      if (this.editar || this.visor)
        this.formModelAereo.value.IdEnvio = this.idEnvio;
      else
        this.formModelAereo.value.IdEnvio = 0;

      if (this.isCotizacion)
        this.formModelAereo.value.Cotizacion = this.envioService.getCotizacion();

      if (envioAereo.IdArea != undefined && envioAereo.IdArea != null)
        this.formModelAereo.value.Envio = envioAereo.IdArea;

      if (!this.tarifa)
        this.asignarTipoEnvio(envioAereo);
      if (this.visor || this.editar || this.isCotizacion) {
        this.asignarFechaRecogida(envioAereo);
        this.formModelAereo.value.Referencia = envioAereo.RefCliente;
      }

      this.asignarShipper(envioAereo);
      this.asignarConsignee(envioAereo);
      this.asignarNotify(envioAereo);

      //this.asignarIncoterm(envioAereo);
      this.formModelAereo.value.Incoterm = envioAereo.Incoterm;
      if (!this.tarifa) {
        this.formModelAereo.value.AeropuertoOrigen = envioAereo.IdAeropuertoOrigen;
        this.formModelAereo.value.AeropuertoDestino = envioAereo.IdAeropuertoDestino;
      }
      this.formModelAereo.value.RefCotizacion = envioAereo.RefCotizacion;
      this.formModelAereo.value.Aerolinea = envioAereo.Aerolinea;

      this.formModelAereo.value.ValorMercancia = envioAereo.ValorMercancia;
      this.formModelAereo.value.TipoDivisa = envioAereo.IdTipoDivisa;

      this.formModelAereo.value.SeguroMercancia = envioAereo.CosteSeguro;

      this.asignarTipoEnvio(envioAereo);
      // this.formModelAereo.value.VGMPesaje = envioAereo.VGMPesaje;
      // this.formModelAereo.value.VGMTransmision = envioAereo.VGMTransmision;

      if (envioAereo.Asegurado == 1)
        this.formModelAereo.value.Asegurado = true;
      else
        this.formModelAereo.value.Asegurado = false;

      if (!this.tarifa) {
        this.formModelAereo.value.PesoNetoTotalDGR = envioAereo.PesoNetoTotalDGR;
        this.formModelAereo.value.PesoBrutoTotalDGR = envioAereo.PesoBrutoTotalDGR;
        this.formModelAereo.value.BultosTotales = envioAereo.BultosTotales;
        this.formModelAereo.value.PesoNetoTotal = envioAereo.PesoNetoTotal;
        this.formModelAereo.value.PesoBrutoTotal = envioAereo.PesoBrutoTotal;
        this.formModelAereo.value.VolumenTotal = envioAereo.VolumenTotal;
        this.formModelAereo.value.PesoTasable = envioAereo.PesoTasable;
      }

      this.formModelAereo.value.Refrigerada = envioAereo.Refrigerada;
      this.formModelAereo.value.Perecedero = envioAereo.Perecedero;
      if (!this.tarifa) {
        this.formModelAereo.value.MercanciaPeligrosa = envioAereo.MercanciaPeligrosa;
      }
      //this.formModelAereo.value.NoRemontable = envioAereo.NoRemontable;
      this.formModelAereo.value.Fumigado = envioAereo.Fumigado;


      //Recogida
      if (envioAereo.FechaRecogida != null && (this.visor || this.editar))
        this.asignarFechaRecogida(envioAereo);
      if (this.listShipper.length > 0)
        this.listShipper.forEach(element => {
          if (element.IdEntidad == envioAereo.IdEntidadRecogida)
            this.formModelAereo.value.EntidadRecogida = element;
        });
      this.formModelAereo.value.DireccionRecogida = envioAereo.IdDireccionRecogida;
      if (envioAereo.InstruccionesRecogida != null && envioAereo.InstruccionesRecogida != undefined)
        this.formModelAereo.value.InstruccionesRecogida = envioAereo.InstruccionesRecogida;
      else
        this.formModelAereo.value.InstruccionesRecogida = "";
      if (envioAereo.ReferenciaRecogida == null || envioAereo.ReferenciaRecogida == undefined)
        this.formModelAereo.value.ReferenciaRecogida = '';
      else {
        if (this.visor || this.editar)
          this.formModelAereo.value.ReferenciaRecogida = envioAereo.ReferenciaRecogida;
        else
          this.formModelAereo.value.ReferenciaRecogida = "";
      }
      this.asignarTipoEnvio(envioAereo);

      this.formModelAereo.value.HoraRecogidaInicio1 = envioAereo.PrimeraHoraRecogidaInicio;
      this.formModelAereo.value.HoraRecogidaFinal1 = envioAereo.PrimeraHoraRecogidaFinal;
      this.formModelAereo.value.HoraRecogidaInicio2 = envioAereo.SegundaHoraRecogidaInicio;
      this.formModelAereo.value.HoraRecogidaFinal2 = envioAereo.SegundaHoraRecogidaFinal;

      if (envioAereo.TelefonoRecogida != undefined && envioAereo.TelefonoRecogida != null && envioAereo.TelefonoRecogida != "")
        this.formModelAereo.value.TelefonoRecogida = envioAereo.TelefonoRecogida;
      if (envioAereo.PersonaContactoRecogida != undefined && envioAereo.PersonaContactoRecogida != null && envioAereo.PersonaContactoRecogida != "")
        this.formModelAereo.value.PersonaContactoRecogida = envioAereo.PersonaContactoRecogida;
      if (envioAereo.EmailRecogida != undefined && envioAereo.EmailRecogida != null && envioAereo.EmailRecogida != "")
        this.formModelAereo.value.EmailRecogida = envioAereo.EmailRecogida;

      this.formModelAereo.value.DespachoOrigen = envioAereo.DespachoOrigen;
      this.formModelAereo.value.DespachoDestino = envioAereo.DespachoDestino;

      //Entrega
      if (this.incotermStartWith() || this.esImport()) {
        if (this.listConsignee.length > 0)
          this.listConsignee.forEach(element => {
            if (element.IdEntidad == envioAereo.IdEntidadEntrega)
              this.formModelAereo.value.EntidadEntrega = element;
          });
        this.formModelAereo.value.DireccionEntrega = envioAereo.IdDireccionEntrega;
        if (envioAereo.InstruccionesEntrega != null && envioAereo.InstruccionesEntrega != undefined)
          this.formModelAereo.value.InstruccionesEntrega = envioAereo.InstruccionesEntrega;
        else
          this.formModelAereo.value.InstruccionesEntrega = "";
        if (envioAereo.ReferenciaEntrega == null || envioAereo.ReferenciaEntrega == undefined)
          this.formModelAereo.value.ReferenciaEntrega = '';
        else
          this.formModelAereo.value.ReferenciaEntrega = envioAereo.ReferenciaEntrega;

        if (envioAereo.TelefonoEntrega != undefined && envioAereo.TelefonoEntrega != null && envioAereo.TelefonoEntrega != "")
          this.formModelAereo.value.TelefonoEntrega = envioAereo.TelefonoEntrega;
        if (envioAereo.PersonaContactoEntrega != undefined && envioAereo.PersonaContactoEntrega != null && envioAereo.PersonaContactoEntrega != "")
          this.formModelAereo.value.PersonaContactoEntrega = envioAereo.PersonaContactoEntrega;
        if (envioAereo.EmailEntrega != undefined && envioAereo.EmailEntrega != null && envioAereo.EmailEntrega != "")
          this.formModelAereo.value.EmailEntrega = envioAereo.EmailEntrega;
      }

      if (envioAereo.Instrucciones != null && envioAereo.Instrucciones != undefined)
        this.formModelAereo.value.Instrucciones = envioAereo.Instrucciones;
      else
        this.formModelAereo.value.Instrucciones = "";
      if (envioAereo.InstruccionesBLAWB != null && envioAereo.InstruccionesBLAWB != undefined)
        this.formModelAereo.value.InstruccionesBLAWB = envioAereo.InstruccionesBLAWB;
      else
        this.formModelAereo.value.InstruccionesBLAWB = "";
      if (envioAereo.Marcas != null && envioAereo.Marcas != undefined)
        this.formModelAereo.value.Marcas = envioAereo.Marcas;
      else
        this.formModelAereo.value.Marcas = "";

      if (!this.tarifa) {
        this.formModelAereo.value.Mercancia = envioAereo.Mercancia;
        this.asignarTipoEnvio(envioAereo);
        this.listaMerc(envioAereo.Mercancia);
      }
      this.envioService.setPartida(null);
      this.envioService.setExpedicion(null);
      this.envioService.setCotizacion(null);
    }
  }
  //Funcion que trae los valores de la clase al form
  asignarValoresPlantilla(envioAereo: Shipment) {
    if (envioAereo != undefined && this.formModelAereo.value != null && this.formModelAereo.value != undefined) {
      this.editar = this.envioService.getEditar();
      this.duplicar = this.envioService.getDuplicar();
      this.isCotizacion = this.envioService.getCotizacionBoolean();

      if (this.isCotizacion) { // También viene de una cotización
        // if (this.editar || this.visor)
        //   this.formModelAereo.value.IdEnvio = this.idEnvio;
        // else
        //   this.formModelAereo.value.IdEnvio = 0;

        // if (this.isCotizacion)
        //   this.formModelAereo.value.Cotizacion = this.envioService.getCotizacion();

        if (envioAereo.IdArea != undefined && envioAereo.IdArea != null)
          this.formModelAereo.value.Envio = envioAereo.IdArea;

        this.asignarTipoEnvio(envioAereo);

        // if (this.visor || this.editar || this.isCotizacion) {
        //   this.asignarFechaRecogida(envioAereo);
        //   this.formModelAereo.value.Referencia = envioAereo.RefCliente;
        // }

        this.asignarShipper(envioAereo);
        if (this.formModelAereo.value.DireccionShipper != envioAereo.DireccionShipper && envioAereo.DireccionShipper != null && envioAereo.DireccionShipper != '')
          this.formModelAereo.value.DireccionShipper = envioAereo.DireccionShipper

        this.asignarConsignee(envioAereo);
        if (this.formModelAereo.value.DireccionConsignee != envioAereo.DireccionConsignee && envioAereo.DireccionConsignee != null && envioAereo.DireccionConsignee != '')
          this.formModelAereo.value.DireccionConsignee = envioAereo.DireccionConsignee

        if (this.formModelAereo.value.DireccionNotify != envioAereo.DireccionNotify && envioAereo.DireccionNotify != null && envioAereo.DireccionNotify != '')
          this.formModelAereo.value.DireccionNotify = envioAereo.DireccionNotify

        //this.asignarIncoterm(envioAereo);
        // this.formModelAereo.value.Incoterm = envioAereo.Incoterm;
        // this.formModelAereo.value.AeropuertoOrigen = envioAereo.IdAeropuertoOrigen;
        // this.formModelAereo.value.AeropuertoDestino = envioAereo.IdAeropuertoDestino;
        // this.formModelAereo.value.RefCotizacion = envioAereo.RefCotizacion;
        this.formModelAereo.value.Aerolinea = envioAereo.Aerolinea;

        // this.formModelAereo.value.ValorMercancia = envioAereo.ValorMercancia;
        // this.formModelAereo.value.TipoDivisa = envioAereo.IdTipoDivisa;

        // this.formModelAereo.value.SeguroMercancia = envioAereo.CosteSeguro;

        // this.formModelAereo.value.VGMPesaje = envioAereo.VGMPesaje;
        // this.formModelAereo.value.VGMTransmision = envioAereo.VGMTransmision;

        // if (envioAereo.Asegurado == 1)
        //   this.formModelAereo.value.Asegurado = true;
        // else
        //   this.formModelAereo.value.Asegurado = false;

        // this.formModelAereo.value.PesoNetoTotalDGR = envioAereo.PesoNetoTotalDGR;
        // this.formModelAereo.value.PesoBrutoTotalDGR = envioAereo.PesoBrutoTotalDGR;
        // this.formModelAereo.value.BultosTotales = envioAereo.BultosTotales;
        // this.formModelAereo.value.PesoNetoTotal = envioAereo.PesoNetoTotal;
        // this.formModelAereo.value.PesoBrutoTotal = envioAereo.PesoBrutoTotal;
        // this.formModelAereo.value.VolumenTotal = envioAereo.VolumenTotal;
        // this.formModelAereo.value.PesoTasable = envioAereo.PesoTasable;

        // this.formModelAereo.value.Refrigerada = envioAereo.Refrigerada;
        // this.formModelAereo.value.Perecedero = envioAereo.Perecedero;
        // this.formModelAereo.value.MercanciaPeligrosa = envioAereo.MercanciaPeligrosa;
        // this.formModelAereo.value.NoRemontable = envioAereo.NoRemontable;
        // this.formModelAereo.value.Fumigado = envioAereo.Fumigado;


        //Recogida
        // if (envioAereo.FechaRecogida != null && (this.visor || this.editar))
        //   this.asignarFechaRecogida(envioAereo);
        if (this.listShipper.length > 0)
          this.listShipper.forEach(element => {
            if (element.IdEntidad == envioAereo.IdEntidadRecogida)
              this.formModelAereo.value.EntidadRecogida = element;
          });
        this.formModelAereo.value.DireccionRecogida = envioAereo.IdDireccionRecogida;
        if (envioAereo.InstruccionesRecogida != null && envioAereo.InstruccionesRecogida != undefined)
          this.formModelAereo.value.InstruccionesRecogida = envioAereo.InstruccionesRecogida;
        else
          this.formModelAereo.value.InstruccionesRecogida = "";
        if (envioAereo.ReferenciaRecogida == null || envioAereo.ReferenciaRecogida == undefined)
          this.formModelAereo.value.ReferenciaRecogida = '';
        else {
          if (this.visor || this.editar)
            this.formModelAereo.value.ReferenciaRecogida = envioAereo.ReferenciaRecogida;
          else
            this.formModelAereo.value.ReferenciaRecogida = "";
        }

        this.formModelAereo.value.HoraRecogidaInicio1 = envioAereo.PrimeraHoraRecogidaInicio;
        this.formModelAereo.value.HoraRecogidaFinal1 = envioAereo.PrimeraHoraRecogidaFinal;
        this.formModelAereo.value.HoraRecogidaInicio2 = envioAereo.SegundaHoraRecogidaInicio;
        this.formModelAereo.value.HoraRecogidaFinal2 = envioAereo.SegundaHoraRecogidaFinal;

        if (envioAereo.TelefonoRecogida != undefined && envioAereo.TelefonoRecogida != null && envioAereo.TelefonoRecogida != "")
          this.formModelAereo.value.TelefonoRecogida = envioAereo.TelefonoRecogida;
        if (envioAereo.PersonaContactoRecogida != undefined && envioAereo.PersonaContactoRecogida != null && envioAereo.PersonaContactoRecogida != "")
          this.formModelAereo.value.PersonaContactoRecogida = envioAereo.PersonaContactoRecogida;
        if (envioAereo.EmailRecogida != undefined && envioAereo.EmailRecogida != null && envioAereo.EmailRecogida != "")
          this.formModelAereo.value.EmailRecogida = envioAereo.EmailRecogida;

        this.formModelAereo.value.DespachoOrigen = envioAereo.DespachoOrigen;
        this.formModelAereo.value.DespachoDestino = envioAereo.DespachoDestino;

        //Entrega
        if (this.incotermStartWith()) {
          if (this.listConsignee.length > 0)
            this.listConsignee.forEach(element => {
              if (element.IdEntidad == envioAereo.IdEntidadEntrega)
                this.formModelAereo.value.EntidadEntrega = element;
            });
          this.formModelAereo.value.DireccionEntrega = envioAereo.IdDireccionEntrega;
          if (envioAereo.InstruccionesEntrega != null && envioAereo.InstruccionesEntrega != undefined)
            this.formModelAereo.value.InstruccionesEntrega = envioAereo.InstruccionesEntrega;
          else
            this.formModelAereo.value.InstruccionesEntrega = "";
          if (envioAereo.ReferenciaEntrega == null || envioAereo.ReferenciaEntrega == undefined)
            this.formModelAereo.value.ReferenciaEntrega = '';
          else
            this.formModelAereo.value.ReferenciaEntrega = envioAereo.ReferenciaEntrega;

          if (envioAereo.TelefonoEntrega != undefined && envioAereo.TelefonoEntrega != null && envioAereo.TelefonoEntrega != "")
            this.formModelAereo.value.TelefonoEntrega = envioAereo.TelefonoEntrega;
          if (envioAereo.PersonaContactoEntrega != undefined && envioAereo.PersonaContactoEntrega != null && envioAereo.PersonaContactoEntrega != "")
            this.formModelAereo.value.PersonaContactoEntrega = envioAereo.PersonaContactoEntrega;
          if (envioAereo.EmailEntrega != undefined && envioAereo.EmailEntrega != null && envioAereo.EmailEntrega != "")
            this.formModelAereo.value.EmailEntrega = envioAereo.EmailEntrega;
        }

        // if (envioAereo.Instrucciones != null && envioAereo.Instrucciones != undefined)
        //   this.formModelAereo.value.Instrucciones = envioAereo.Instrucciones;
        // else
        //   this.formModelAereo.value.Instrucciones = "";
        if (envioAereo.InstruccionesBLAWB != null && envioAereo.InstruccionesBLAWB != undefined)
          this.formModelAereo.value.InstruccionesBLAWB = envioAereo.InstruccionesBLAWB;
        else
          this.formModelAereo.value.InstruccionesBLAWB = "";
        if (envioAereo.Marcas != null && envioAereo.Marcas != undefined)
          this.formModelAereo.value.Marcas = envioAereo.Marcas;
        else
          this.formModelAereo.value.Marcas = "";

        //this.formModelAereo.value.Mercancia = envioAereo.Mercancia;
        this.asignarTipoEnvio(envioAereo);
        //this.listaMerc(envioAereo.Mercancia);
        this.envioService.setPartida(null);
        this.envioService.setExpedicion(null);
        this.envioService.setCotizacion(null);
      } else
        this.asignarValores(envioAereo);
    }
  }

  getFormatDireccion(entidad) {
    var formatDireccion: string = "";
    if (entidad != null && entidad != undefined) {
      // Tipo direccion
      if (entidad.TipoDireccion != undefined && entidad.TipoDireccion != null && entidad.TipoDireccion != ""
        && entidad.TipoDireccion != ".")
        formatDireccion += entidad.TipoDireccion + " ";

      // Direccion
      if (entidad.Direccion != undefined && entidad.Direccion != null && entidad.Direccion != ""
        && entidad.Direccion != ".")
        formatDireccion += entidad.Direccion + ", ";

      // Numero
      if (entidad.Numero != undefined && entidad.Numero != null && entidad.Numero != ""
        && entidad.Numero != ".")
        formatDireccion += entidad.Numero + "\n";

      // Localidad
      if (entidad.Poblacion != undefined && entidad.Poblacion != null && entidad.Poblacion != ""
        && entidad.Poblacion != ".")
        formatDireccion += entidad.Poblacion;
    }
    return formatDireccion;
  }

  //Funcion que recoge la informacion completa del shipper
  setShipperInformation() {
    this.formModelAereo.value.DireccionShipper = '';
    if (this.formModelAereo.value.Shipper != null && this.formModelAereo.value.Shipper != undefined)
      if (this.formModelAereo.value.Shipper.IdDireccion != null)
        this.formModelAereo.value.DireccionRecogida = this.formModelAereo.value.Shipper.IdDireccion;

    this.formModelAereo.value.EntidadRecogida = this.formModelAereo.value.Shipper;
    this.formModelAereo.value.DireccionShipper = this.getFormatDireccion(this.formModelAereo.value.Shipper);
    this.resumenDireccionRecogida = this.formModelAereo.value.DireccionShipper;
  }

  //Funcion que recoge la informacion completa del consignee
  setConsigneeInformation() {
    this.cargarListaNotify();
    this.formModelAereo.value.DireccionConsignee = '';
    if (this.formModelAereo.value.Consignee != null && this.formModelAereo.value.Consignee != undefined)
      if (this.formModelAereo.value.Consignee.IdDireccion != null)
        this.formModelAereo.value.IdDireccionEntrega = this.formModelAereo.value.Consignee.IdDireccion;

    this.formModelAereo.value.EntidadEntrega = this.formModelAereo.value.Consignee;
    this.formModelAereo.value.DireccionConsignee = this.getFormatDireccion(this.formModelAereo.value.Consignee);
    this.resumenDireccionEntrega = this.formModelAereo.value.DireccionConsignee;
  }

  //Funcion que recoge la informacion completa del notify
  setNotifyInformation() {
    this.formModelAereo.value.DireccionNotify = this.getFormatDireccion(this.formModelAereo.value.Notify);
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el shipper
  recibirShipper(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelAereo.value.Shipper = entidad;
      this.setShipperInformation();
    }
    this.modalRef.hide();
    document.getElementById("Shipper").focus();
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el consignee
  recibirConsignee(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelAereo.value.Consignee = entidad;
      this.setConsigneeInformation();
    }
    this.modalRef.hide();
    document.getElementById("Consignee").focus();
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirNotify(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelAereo.value.Notify = entidad;
      this.setNotifyInformation();
    }
    this.modalRef.hide();
    document.getElementById("Notify").focus()
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirEntidadRecogida(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelAereo.value.EntidadRecogida = entidad;
      this.setRecogidaInformation();
    }
    this.modalRef.hide();
    document.getElementById("EntidadRecogida").focus()
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirEntidadEntrega(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelAereo.value.EntidadEntrega = entidad;
      this.setEntregaInformation();
    }
    this.modalRef.hide();
    document.getElementById("EntidadEntrega").focus()
  }

  //Funcion que comprueba los pesos y si el bruto es mayor que el neto
  comprobarPesos(elem: Mercancia) {
    if (elem.PesoBruto === undefined || elem.PesoBruto === null || elem.PesoBruto.toString() == '')
      return true
    if (elem.PesoNeto !== undefined && elem.PesoNeto !== null && elem.PesoNeto.toString() != '')
      return elem.PesoBruto !== undefined && elem.PesoNeto !== undefined && elem.PesoBruto !== null && elem.PesoNeto !== null && Number(elem.PesoNeto) <= Number(elem.PesoBruto)
    else
      return elem.PesoBruto !== undefined && elem.PesoBruto !== null && elem.PesoBruto.toString() != ''
  }

  //Comprueba si hay una mercancía vacía o algún campo de la mercancía vacía
  comprobarVacio(merc: Mercancia) {
    return (merc.Bultos === null || merc.Bultos === undefined || merc.Volumen === null || merc.Volumen === undefined
      || merc.TipoEmbalaje === undefined || merc.TipoEmbalaje === null
      || merc.PesoBruto === null || merc.PesoBruto === undefined || merc.PesoBruto.toString() === '' || merc.Volumen.toString() === ''
      || merc.Bultos.toString() === '' || merc.TipoEmbalaje.toString() === '' || merc.NombreMercancia === null || merc.NombreMercancia === undefined || merc.NombreMercancia.toString() === '');
  }

  comprobarPesosBP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoBruto
      sum = sum + pb
    });
    return elem.PesoBruto >= sum
  }

  comprobarPesosNP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoNeto
      sum = sum + pb
    });
    return elem.PesoNeto >= sum;
  }

  comprobarGrados(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return merc.GradosMax === null || merc.GradosMax === undefined || merc.GradosMin === null || merc.GradosMin === undefined;
    return false;
  }

  comprobraGradosDispares(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return this.comprobarGrados(merc) || merc.GradosMax < merc.GradosMin;
    return false;
  }

  comprobarMercanciaPeligrosa(merc: Mercancia): boolean {
    return merc.Peligrosa && merc.MercanciaPeligrosa != null && merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0;
  }

  //Funcion que comprueba si hay errores en una linea de mercancía
  comprobarErrores() {
    var camposVacios = false, pesos = false, index = 1, grados = false, lineaMercancia = 1, indicePB = -1, indicePN = -1, pesosBrutosDispares = true, pesosNetosDispares = true, codigoUNNoExiste = false;
    var indicesMP = [], indiceNoExisteMercanciaPeligrosa = [], indiceTC = [], pesosDisparesArray = [], mercanciaspeligrosasAux = [], pesosGradosArray = [], pesosGradosDisparesArray = [], mercanciastemperaturacontroladaAux = [], pesosBrutosArray = [], pesosNetosArray = [];;
    this.listaMercancia.forEach(merc => {
      if (merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0 && merc.MercanciaPeligrosa != null) {
        // var linea = 1;
        // merc.MercanciaPeligrosa.forEach(mp => {
        //   var codigoUn = this.codigosUn.find(cun => cun.codigoUN.toString() == mp.Un);
        //   if(codigoUn == undefined || codigoUn == null){
        //     var bodyError: string = this.translateService.instant("BodyErrorCodigoUnNotExistFormFirstPart") + linea + this.translateService.instant("BodyErrorCodigoUnNotExistFormSecondPart") + lineaMercancia +
        //     this.translateService.instant("BodyErrorCodigoUnNotExistFormThirdPart");
        //     var titleError: string = this.translateService.instant("TitleErrorCodigoUnNotExist") + lineaMercancia;
        //     this.toastr.error(bodyError, titleError);
        //     codigoUNNoExiste = true;
        //   }
        //   linea++;
        // });
        mercanciaspeligrosasAux.push(merc.MercanciaPeligrosa);
        // var compruebaPesosBrutosMP = this.comprobarPesosBP(merc);
        // var compruebaPesosNetoMP = this.comprobarPesosNP(merc);
        // if (!compruebaPesosBrutosMP) {
        //   indicePB = index;
        //   pesosBrutosArray.push(index);
        // } else {
        //   pesosBrutosDispares = false;
        // }
        // if (!compruebaPesosNetoMP) {
        //   indicePN = index;
        //   pesosNetosArray.push(index);
        // } else {
        //   pesosNetosDispares = false;
        // }
      }
      lineaMercancia++;
      if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
        mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
        indiceTC.push(index);
      }
      //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);
      var compruebaMP = this.comprobarVacio(merc);
      var compruebaPesos = this.comprobarPesos(merc);
      var compruebaGrados = this.comprobarGrados(merc);
      var compruebaGradosDispares = this.comprobraGradosDispares(merc);
      if (merc.Peligrosa && merc.MercanciaPeligrosa.length == 0)
        indiceNoExisteMercanciaPeligrosa.push(index);
      if (compruebaMP)
        indicesMP.push(index);
      if (!compruebaPesos)
        pesosDisparesArray.push(index);
      if (compruebaGrados)
        pesosGradosArray.push(index);
      if (!compruebaGrados && compruebaGradosDispares)
        pesosGradosDisparesArray.push(index);
      index++;
    });
    /*if (this.shipmentAereo.MercanciaPeligrosa == 1 && mercanciaspeligrosasAux.length == 0) {
      camposVacios = true
      var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
      var Titulo = this.translateService.instant('Error mercancia peligrosa');
      this.toastr.error(Body, Titulo)
    }*/
    index = 1;
    indicesMP.forEach(imp => {
      camposVacios = true
      var Body = this.translateService.instant('Necesitas rellenar todos los campos de la línea ') + imp
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Campos vacíos en mercancía');
      this.toastr.error(Body, Titulo)
    });
    indiceNoExisteMercanciaPeligrosa.forEach(inem => {
      camposVacios = true
      var Body = this.translateService.instant('Falta Indicar UN en la línea ') + inem
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Falta mercancía peligrosa');
      this.toastr.error(Body, Titulo);
    })
    pesosDisparesArray.forEach(e => {
      var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e;
      this.toastr.error(Body, Titulo)
      pesos = true
    })
    pesosGradosArray.forEach(tc => {
      var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc;
      this.toastr.error(Body, Titulo)
      grados = true;
    });
    // pesosBrutosArray.forEach(e => {
    //   var Body = this.translateService.instant('El peso bruto de la linea ') + e + (' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
    //   var Titulo = this.translateService.instant('Error peso bruto DGR linea ') + e;
    //   this.toastr.error(Body, Titulo);
    //   pesos = true
    // });
    // pesosNetosArray.forEach(e => {
    //   var Body = this.translateService.instant('El peso neto de la linea ') + e + (' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
    //   var Titulo = this.translateService.instant('Error peso neto DGR linea ') + e;
    //   this.toastr.error(Body, Titulo);
    //   pesos = true
    // });
    if (/*!this.comprobarPesoBrutoTotalDGR() || */!this.comprobarPesoNetoTotalDGR() || !this.comprobarVacioPesoBruto() /*|| !this.comprobarVacioPesoNeto() */ || !this.comprobarVacioPesoNetoTotalDGR() || !this.comprobarPesoNetoTotalDGR() || pesos || camposVacios || !this.comprobarPesosTotales() || this.errorPesoNetoVacio || this.errorPesoBrutoVacio) { // Existe algún error
      return true
    }
    return false
  }

  //Funcion que comprueba si el peso neto total es menor que el bruto total
  comprobarPesosTotales() {
    if (this.formModelAereo.value.PesoBrutoTotal !== null && this.formModelAereo.value.PesoBrutoTotal !== undefined
      && this.formModelAereo.value.PesoBrutoTotal !== "" && this.formModelAereo.value.PesoNetoTotal !== ""
      && this.formModelAereo.value.PesoNetoTotal !== null && this.formModelAereo.value.PesoNetoTotal !== undefined
      && parseInt(this.formModelAereo.value.PesoBrutoTotal) < parseInt(this.formModelAereo.value.PesoNetoTotal)) {
      var Body = this.translateService.instant("Peso Bruto Total es menor que Peso Neto Total");
      var Titulo = this.translateService.instant("Peso Bruto Total menor que Peso Neto Total");
      this.toastr.error(Body, Titulo)
      this.errorBrutoTotal = true;
      return false
    }
    this.errorBrutoTotal = false;
    return true
  }
  //Funcion que comprueba si los pesos brutos totales son mayores que los pesos brutos totales DGR 
  comprobarPesoBrutoTotalDGR() {
    if (this.formModelAereo.value.PesoBrutoTotal !== null && this.formModelAereo.value.PesoBrutoTotal !== undefined
      && this.formModelAereo.value.PesoBrutoTotal !== "" && this.formModelAereo.value.PesoBrutoTotalDGR !== ""
      && this.formModelAereo.value.PesoBrutoTotalDGR !== null && this.formModelAereo.value.PesoBrutoTotalDGR !== undefined
      && parseInt(this.formModelAereo.value.PesoBrutoTotal) < parseInt(this.formModelAereo.value.PesoBrutoTotalDGR)) {
      var Body = this.translateService.instant("El Peso Bruto Total debe ser mayor o igual que el Peso Bruto Total DGR ");
      var Titulo = this.translateService.instant("Error peso bruto total DGR");
      this.errorPesoBruto = true;
      this.toastr.error(Body, Titulo);
      return false;
    }
    this.errorPesoBruto = false;
    return true;
  }


  compruebaPesoNetoTotalDGRIgual() {
    if (this.errorPesoNetoTotalDGRdistinto) {
      var Body = this.translateService.instant("El Peso Neto Total DGR debe ser igual a la suma de los netos peligrosos de las lineas de la tabla de mercancia");
      var Titulo = this.translateService.instant("Error peso Neto total DGR");
      this.toastr.error(Body, Titulo);
      return false;
    }
    else
      return true;

  }

  //Funcion que comprueba si los pesos Netos totales son mayores que los pesos Netos totales DGR 
  comprobarPesoNetoTotalDGR() {
    if (this.formModelAereo.value.PesoNetoTotal !== null && this.formModelAereo.value.PesoNetoTotal !== undefined
      && this.formModelAereo.value.PesoNetoTotal !== "" && this.formModelAereo.value.PesoNetoTotalDGR !== ""
      && this.formModelAereo.value.PesoNetoTotalDGR !== null && this.formModelAereo.value.PesoNetoTotalDGR !== undefined
      && parseInt(this.formModelAereo.value.PesoNetoTotal) < parseInt(this.formModelAereo.value.PesoNetoTotalDGR)) {
      var Body = this.translateService.instant("El Peso Neto Total debe ser mayor o igual que el Peso Neto Total DGR.");
      var Titulo = this.translateService.instant("Error peso Neto total DGR");
      this.toastr.error(Body, Titulo);
      this.errorPesoNeto = true;
      return false
    }
    this.errorPesoNeto = false;
    return true
  }

  /*comprobarVacioPesoNeto() {
    if (this.formModelAereo.value.PesoNetoTotal == 0 || this.formModelAereo.value.PesoNetoTotal == null || this.formModelAereo.value.PesoNetoTotal == "") {
      var Body = this.translateService.instant("El Peso Neto Total es obligatorio.");
      var Titulo = this.translateService.instant("Error peso Neto total");
      this.toastr.error(Body, Titulo);
      this.errorPesoNetoVacio = true;
      return false
    }
    else
      this.errorPesoNetoVacio = false;
    return true;
  }*/
  comprobarVacioPesoBruto() {
    if (this.formModelAereo.value.PesoBrutoTotal == 0 || this.formModelAereo.value.PesoBrutoTotal == null || this.formModelAereo.value.PesoBrutoTotal == "") {
      var Body = this.translateService.instant("El Peso Bruto Total es obligatorio.");
      var Titulo = this.translateService.instant("Error peso Bruto total");
      this.toastr.error(Body, Titulo);
      this.errorPesoBrutoVacio = true;
      return false
    }
    else
      this.errorPesoBrutoVacio = false;
    return true;
  }
  comprobarVacioPesoNetoTotalDGR() {
    if (this.hayMercanciaPeligrosa() && (this.formModelAereo.value.PesoNetoTotalDGR == 0 || this.formModelAereo.value.PesoNetoTotalDGR == null || this.formModelAereo.value.PesoNetoTotalDGR == "")) {
      var Body = this.translateService.instant("El Peso Neto Total DGR es obligatorio.");
      var Titulo = this.translateService.instant("Error Peso Neto Total DGR");
      this.toastr.error(Body, Titulo);
      this.errorPesoNetoDGR = true;
      return false
    }
    else
      this.errorPesoNetoDGR = false;
    return true;
  }

  //Funcion que obtiene la lista de mercancias del componente Cargo y que recalcula los totales
  listaMerc(lista: Mercancia[]) {
    this.totalNetoDgrLineas = 0;
    this.listaMercancia = lista;
    var anteriorPesoBrutoTotal = this.formModelAereo.value.PesoBrutoTotal;
    this.formModelAereo.value.BultosTotales = 0;
    this.formModelAereo.value.PesoBrutoTotal = 0;
    this.formModelAereo.value.PesoNetoTotal = 0;
    this.formModelAereo.value.VolumenTotal = 0;
    this.formModelAereo.value.PesoTasable = 0;
    if (!this.tarifa)
      this.formModelAereo.value.PesoNetoTotalDGR = 0;
    this.formModelAereo.value.PesoBrutoTotalDGR = 0;
    if (this.listaMercancia != null) {
      if (this.listaMercancia.length == 0) {
        this.formModelAereo.value.BultosTotales = 0;
        this.formModelAereo.value.PesoBrutoTotal = 0;
        this.formModelAereo.value.PesoNetoTotal = 0;
        this.formModelAereo.value.VolumenTotal = 0;
        this.formModelAereo.value.PesoTasable = 0;
        this.formModelAereo.value.PesoNetoTotalDGR = 0;
        this.formModelAereo.value.PesoBrutoTotalDGR = 0;
      }
      else {
        this.formModelAereo.value.VolumenTotal = 0;
        this.formModelAereo.value.PesoTasable = 0;
        if (this.listaMercancia != undefined && this.listaMercancia.length >= 0) {
          this.listaMercancia.forEach(element => {
            this.formModelAereo.value.BultosTotales = Number((Number(this.formModelAereo.value.BultosTotales) + Number(element.Bultos ? element.Bultos : 0)).toFixed(3));
            if (element.Bultos != 0 && element.Bultos != null && element.Bultos != undefined) {
              this.compruebaBultosTotales();
            }
            this.formModelAereo.value.PesoBrutoTotal = Number((Number(this.formModelAereo.value.PesoBrutoTotal ? this.formModelAereo.value.PesoBrutoTotal : 0) +
              Number((element.PesoBruto ? element.PesoBruto : 0))).toFixed(3));
            if (element.PesoBruto != 0 && element.PesoBruto != null && element.PesoBruto != undefined) {
              this.compruebaPesoBrutoTotal();
            }
            this.formModelAereo.value.PesoNetoTotal = Number((Number(this.formModelAereo.value.PesoNetoTotal ? this.formModelAereo.value.PesoNetoTotal : 0) +
              Number(element.PesoNeto ? element.PesoNeto : 0)).toFixed(3));
            if (element.PesoNeto != 0 && element.PesoNeto != null && element.PesoNeto != undefined) {
              this.compruebaPesoNetoTotal();
            }
            this.formModelAereo.value.VolumenTotal = Number((Number(this.formModelAereo.value.VolumenTotal) + Number(element.Volumen ? element.Volumen : 0)).toFixed(3));
            if (element.Volumen != 0 && element.Volumen != null && element.Volumen != undefined) {
              this.compruebaVolumenTotal();
            }
            this.formModelAereo.value.MercanciaPeligrosa = this.hayMercanciaPeligrosa();
            if (element.MercanciaPeligrosa != undefined && element.MercanciaPeligrosa.length >= 0 && element.Peligrosa) {
              if (element.MercanciaPeligrosa.length >= 0) {
                element.MercanciaPeligrosa.forEach(element2 => {
                  // Peso Neto
                  if (element2.PesoNeto != undefined && !this.tarifa) {
                    this.formModelAereo.value.PesoNetoTotalDGR = Number(this.formModelAereo.value.PesoNetoTotalDGR) + Number(element2.PesoNeto);
                    if (element2.PesoNeto != 0 && element2.PesoNeto != null && element2.PesoNeto != undefined) {
                      this.compruebaPesoNetoTotalDGR();
                    }
                    // Peso Bruto
                    if (element2.PesoBruto != undefined)
                      this.formModelAereo.value.PesoBrutoTotalDGR = Number(this.formModelAereo.value.PesoBrutoTotalDGR) + Number(element2.PesoBruto);
                  }
                  if (element2.PesoNeto != undefined && this.tarifa) {
                    this.totalNetoDgrLineas += element2.PesoNeto
                  }
                  // Peso Bruto
                  if (element2.PesoBruto != undefined)
                    this.formModelAereo.value.PesoBrutoTotalDGR = Number(this.formModelAereo.value.PesoBrutoTotalDGR) + Number(element2.PesoBruto);

                });
                if ((this.formModelAereo.value.PesoNetoTotalDGR != this.totalNetoDgrLineas) && this.tarifa && this.totalNetoDgrLineas != 0) {
                  this.errorPesoNetoTotalDGRdistinto = true;
                }
                else
                  this.errorPesoNetoTotalDGRdistinto = false;
              }

              if (this.formModelAereo.value.PesoBrutoTotalDGR == undefined || this.formModelAereo.value.PesoBrutoTotalDGR == null)
                this.formModelAereo.value.PesoBrutoTotalDGR = 0;

              if (this.formModelAereo.value.PesoNetoTotalDGR == undefined || this.formModelAereo.value.PesoNetoTotalDGR == null)
                this.formModelAereo.value.PesoNetoTotalDGR = 0;

            }
          });
          this.calcularPesoTasable();
        }
        if (anteriorPesoBrutoTotal !== this.formModelAereo.value.PesoBrutoTotal) {
          this.comprobarPesosTotales();
        }
      }
    }
  }

  hayMercanciaPeligrosa(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercancia.forEach(element => {
      if ((element.Peligrosa || element.MP) && !yaHayPeligrosa)
        yaHayPeligrosa = true;
    });
    return yaHayPeligrosa;
  }

  //Funcion que recibe los archivos
  receiveFiles(files) {
    this.listaArchivos = files;
  }

  //Funcion que asigna los valores del modelo a una clase
  convertFormToModel(form) {
    this.shipmentAereo = new Shipment();
    this.shipmentAereo.IdEnvio = 0;
    this.shipmentAereo.IdCotizacion = this.cotizacion;
    this.shipmentAereo.IdTipoEnvio = this.formModelAereo.value.TipoEnvio;
    this.shipmentAereo.IdArea = 3;
    this.shipmentAereo.IdCliente = this.entidadCliente;
    this.shipmentAereo.RefCliente = form.value.Referencia;
    this.shipmentAereo.IdRemitente = this.formModelAereo.value.Shipper.IdEntidad;
    this.shipmentAereo.DireccionShipper = this.formModelAereo.value.DireccionShipper;
    this.shipmentAereo.IdDestinatario = this.formModelAereo.value.Consignee.IdEntidad;
    this.shipmentAereo.DireccionConsignee = this.formModelAereo.value.DireccionConsignee;
    this.shipmentAereo.chShipper = this.chShipper;
    this.shipmentAereo.chConsignee = this.chConsignee;
    this.shipmentAereo.chNotify = this.chNotify;

    if (this.tarifa != undefined && this.tarifa != null)
      this.shipmentAereo.Tarifa = this.formModelAereo.value.Tarifa;
    else
      this.shipmentAereo.Tarifa = null;

    if (this.formModelAereo.value.Notify.IdEntidad == -1) {
      this.shipmentAereo.IdNotify = this.formModelAereo.value.Consignee.IdEntidad;
      this.shipmentAereo.DireccionNotify = this.formModelAereo.value.DireccionNotify;
    }
    else if (this.formModelAereo.value.Notify == "THE SAME") {
      this.shipmentAereo.IdNotify = -1;
      this.shipmentAereo.DireccionNotify = this.formModelAereo.value.DireccionNotify;
    }
    else {
      this.shipmentAereo.IdNotify = this.formModelAereo.value.Notify.IdEntidad;
      this.shipmentAereo.DireccionNotify = this.formModelAereo.value.DireccionNotify;
    }
    this.shipmentAereo.Incoterm = this.formModelAereo.value.Incoterm;
    this.shipmentAereo.ValorMercancia = form.value.ValorMercancia;
    this.shipmentAereo.IdTipoDivisa = this.formModelAereo.value.TipoDivisa;

    this.shipmentAereo.CosteSeguro = this.formModelAereo.value.SeguroMercancia;

    if (this.formModelAereo.value.Asegurado == true) {
      this.shipmentAereo.Asegurado = 1;
    }
    else {
      this.shipmentAereo.Asegurado = 0;
    }

    // this.shipmentAereo.VGMPesaje = this.formModelAereo.value.VGMPesaje;
    // this.shipmentAereo.VGMTransmision = this.formModelAereo.value.VGMTransmision;

    this.shipmentAereo.IdAeropuertoOrigen = this.formModelAereo.value.AeropuertoOrigen;
    this.shipmentAereo.IdAeropuertoDestino = this.formModelAereo.value.AeropuertoDestino
    this.shipmentAereo.Aerolinea = this.formModelAereo.value.Aerolinea;

    if (this.formModelAereo.value.MercanciaPeligrosa == true)
      this.shipmentAereo.MercanciaPeligrosa = 1;
    else
      this.shipmentAereo.MercanciaPeligrosa = 0;

    this.shipmentAereo.Refrigerada = form.value.Refrigerada;
    this.shipmentAereo.Perecedero = form.value.Perecedero;
    // if (form.value.NoRemontable == true) {
    //   this.shipmentAereo.NoRemontable = 1;
    // }
    // else {
    //   this.shipmentAereo.NoRemontable = 0;
    // }
    this.shipmentAereo.Fumigado = form.value.Fumigado;

    //Recogida
    this.shipmentAereo.IdEntidadRecogida = form.value.EntidadRecogida.IdEntidad;
    this.shipmentAereo.IdDireccionRecogida = form.value.EntidadRecogida.IdDireccion;
    this.shipmentAereo.FechaRecogida = form.value.FechaRecogida;
    this.shipmentAereo.ReferenciaRecogida = form.value.ReferenciaRecogida;
    this.shipmentAereo.InstruccionesRecogida = form.value.InstruccionesRecogida;
    this.shipmentAereo.PrimeraHoraRecogidaInicio = this.formModelAereo.value.HoraRecogidaInicio1;
    this.shipmentAereo.PrimeraHoraRecogidaFinal = this.formModelAereo.value.HoraRecogidaFinal1;
    this.shipmentAereo.SegundaHoraRecogidaInicio = this.formModelAereo.value.HoraRecogidaInicio2;
    this.shipmentAereo.SegundaHoraRecogidaFinal = this.formModelAereo.value.HoraRecogidaFinal2;
    this.shipmentAereo.PersonaContactoRecogida = form.value.PersonaContactoRecogida;
    this.shipmentAereo.TelefonoRecogida = form.value.TelefonoRecogida;
    this.shipmentAereo.EmailRecogida = this.formModelAereo.value.EmailRecogida;

    //DespachoAduanas
    this.shipmentAereo.DespachoOrigen = this.formModelAereo.value.DespachoOrigen != undefined ? this.formModelAereo.value.DespachoOrigen : null;
    this.shipmentAereo.DespachoDestino = this.formModelAereo.value.DespachoDestino != undefined ? this.formModelAereo.value.DespachoDestino : null;

    //Entrega
    if (this.incotermStartWith()) {
      this.shipmentAereo.IdEntidadEntrega = form.value.EntidadEntrega.IdEntidad;
      this.shipmentAereo.IdDireccionEntrega = form.value.EntidadEntrega.IdDireccion;
      this.shipmentAereo.ReferenciaEntrega = form.value.ReferenciaEntrega;
      this.shipmentAereo.InstruccionesEntrega = form.value.InstruccionesEntrega;
      this.shipmentAereo.PersonaContactoEntrega = form.value.PersonaContactoEntrega;
      this.shipmentAereo.TelefonoEntrega = form.value.TelefonoEntrega;
      this.shipmentAereo.EmailEntrega = this.formModelAereo.value.EmailEntrega;
    }
    else {

      this.shipmentAereo.IdEntidadEntrega = null;
      this.shipmentAereo.IdDireccionEntrega = null;
      this.shipmentAereo.ReferenciaEntrega = '';
      this.shipmentAereo.InstruccionesEntrega = '';
      this.shipmentAereo.PersonaContactoEntrega = '';
      this.shipmentAereo.TelefonoEntrega = '';
      this.shipmentAereo.EmailEntrega = '';

    }
    //Totales mercancia
    if (this.formModelAereo.value.PesoNetoTotalDGR == null || this.formModelAereo.value.PesoNetoTotalDGR == '') {
      this.shipmentAereo.PesoNetoTotalDGR = 0;
    }
    else {
      this.shipmentAereo.PesoNetoTotalDGR = Number(this.formModelAereo.value.PesoNetoTotalDGR.toString().replace(',', '.'));
    }
    //Totales mercancia
    if (this.formModelAereo.value.PesoBrutoTotalDGR == null || this.formModelAereo.value.PesoBrutoTotalDGR == '') {
      this.shipmentAereo.PesoBrutoTotalDGR = 0;
    }
    else {
      this.shipmentAereo.PesoBrutoTotalDGR = Number(this.formModelAereo.value.PesoBrutoTotalDGR.toString().replace(',', '.'));
    }

    if (this.formModelAereo.value.BultosTotales == null || this.formModelAereo.value.BultosTotales == '') {
      this.shipmentAereo.BultosTotales = 0;
    }
    else {
      this.shipmentAereo.BultosTotales = Number(this.formModelAereo.value.BultosTotales.toString().replace(',', '.'));
    }

    if (this.formModelAereo.value.PesoNetoTotal == null || this.formModelAereo.value.PesoNetoTotal == '') {
      this.shipmentAereo.PesoNetoTotal = 0;
    }
    else {
      this.shipmentAereo.PesoNetoTotal = Number(this.formModelAereo.value.PesoNetoTotal.toString().replace(',', '.'));
    }

    if (this.formModelAereo.value.PesoBrutoTotal == null || this.formModelAereo.value.PesoBrutoTotal == '') {
      this.shipmentAereo.PesoBrutoTotal = 0;
    }
    else {
      this.shipmentAereo.PesoBrutoTotal = Number(this.formModelAereo.value.PesoBrutoTotal.toString().replace(',', '.'));
    }

    if (form.value.VolumenTotal == '') {
      this.shipmentAereo.VolumenTotal = 0;
    }
    else {
      this.shipmentAereo.VolumenTotal = Number(form.value.VolumenTotal.toString().replace(',', '.'));
    }
    this.shipmentAereo.PesoTasable = Number(this.formModelAereo.value.PesoTasable.toString().replace(',', '.'));

    //Descripciones adicionales
    this.shipmentAereo.InstruccionesBLAWB = this.formModelAereo.value.InstruccionesBLAWB;
    this.shipmentAereo.Marcas = form.value.Marcas;
    this.shipmentAereo.Instrucciones = this.formModelAereo.value.Instrucciones;
    this.shipmentAereo.Archivos = this.listaArchivos;
    this.shipmentAereo.Mercancia = this.listaMercancia;
  }

  verDocumentos() {
    this.envioService.setIdEnvio(this.formModelAereo.value.Id);
    this.router.navigate(["/customer/shipment/documents"]);
  }

  setMercPeligrosa(form?) {
    if (this.formModelAereo.value.MercanciaPeligrosa == true || this.formModelAereo.value.Mercancia == 1) {
      this.formModelAereo.value.MercanciaPeligrosa = false;
      if (this.listaMercancia != undefined) {
        this.listaMercancia.forEach(element => {
          element.MercanciaPeligrosa = [];
        })
      }
    }
    else {
      this.formModelAereo.value.MercanciaPeligrosa = true;
    }
  }

  openModalSM(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalLG(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal2(template: TemplateRef<any>, tipoEntidad: string) {
    this.tipoEntidad = tipoEntidad;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openAlert(template: TemplateRef<any>, form: NgForm) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.form = form;
    this.modalRef = this.modalService.show(template, config);
  }

  openAlert2(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  setDireccion(remitenteDestinatario: string) {
    this.tipoDireccion = remitenteDestinatario;
  }

  getDireccion(direccion: Direccion) {
    if (direccion != null) {
      if (this.tipoDireccion == 'shipper') {
        this.listShipper.forEach(shipper => {
          if (shipper.IdEntidad == direccion.IdEntidad) {
            this.formModelAereo.value.EntidadRecogida = shipper;
            this.setDireccionRecogidaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaRecogida').focus();
            }, 0);
          }
        })
      }
      else if (this.tipoDireccion == 'consignee') {
        this.listConsignee.forEach(consignee => {
          if (consignee.IdEntidad == direccion.IdEntidad) {
            this.formModelAereo.value.EntidadEntrega = consignee;
            this.setDireccionEntregaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaEntrega').focus();
            }, 0);
          }
        })

      }
      else {
        this.modalRef.hide();
      }
    }
    else {
      this.modalRef.hide();
    }

  }

  setDireccionRecogidaInformation(direccion: Direccion) {
    this.resumenDireccionRecogida = '';
    this.formModelAereo.value.DireccionRecogida = direccion.IdDireccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion) {
        this.resumenDireccionRecogida = direccion.Direccion;
      }
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined) {
        this.resumenDireccionRecogida += "\r\n" + direccion.NombreLocalidad;
      }
      /*if(this.resumenDireccionRecogida != "")
      {
        this.resumenDireccionRecogida += ", ";
      }
      if(direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '')
      {
        this.resumenDireccionRecogida += direccion.NombrePoblacion;
      }*/
    }
    else {
      this.resumenDireccionRecogida = "";
    }
  }

  setDireccionEntregaInformation(direccion: Direccion) {
    this.resumenDireccionEntrega = '';
    this.formModelAereo.value.DireccionEntrega = direccion.IdDireccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion) {
        this.resumenDireccionEntrega = direccion.Direccion;
      }
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined) {
        this.resumenDireccionEntrega += "\r\n" + direccion.NombreLocalidad;
      }/*
      if(this.resumenDireccionEntrega != "")
      {
        this.resumenDireccionEntrega += ", ";
      }
      if(direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '')
      {
        this.resumenDireccionEntrega += direccion.NombrePoblacion;
      }*/
    }
    else {
      this.resumenDireccionEntrega = "";
    }
  }

  getPlantilla(envioPredeterminado: Shipment) {
    if (envioPredeterminado == null)
      this.modalRef.hide();
    else {
      envioPredeterminado.FechaRecogida = null;
      this.modalRef.hide();
      this.asignarValoresPlantilla(envioPredeterminado);
    }
  }



  incotermStartWith(): Boolean {
    if (this.formModelAereo.value.Incoterm != '' && this.formModelAereo.value.Incoterm != undefined)
      if (this.formModelAereo.value.Incoterm.startsWith('D') || this.formModelAereo.value.Incoterm.startsWith('FOB'))
        return true;
    return false;
  }

  calcularSeguro() {
    var value = 0;
    if (this.formModelAereo.value.ValorMercancia != '') {
      if (this.formModelAereo.value.MercanciaPeligrosa == true) {
        value = Number(this.formModelAereo.value.ValorMercancia.toString().replace(',', '.')) * 1.1 * 0.004;
      }
      else {
        value = Number(this.formModelAereo.value.ValorMercancia.toString().replace(',', '.')) * 1.1 * 0.003;
      }
      if (value > 30) {
        this.formModelAereo.value.SeguroMercancia = Number(value.toFixed(3).toString().replace(',', '.'));
      }
      else {
        this.formModelAereo.value.SeguroMercancia = 30;
      }
    }
  }

  //Funcion que calcula el peso tasable (formula particular de aereo)
  calcularPesoTasable() {
    let valor: number = 0;
    if (this.formModelAereo.value.PesoBrutoTotal != null && this.formModelAereo.value.VolumenTotal != null) {
      valor = (this.formModelAereo.value.VolumenTotal * 167);
      if (this.formModelAereo.value.PesoBrutoTotal > valor) {
        this.formModelAereo.value.PesoTasable = Number(this.formModelAereo.value.PesoBrutoTotal.toString()).toFixed(3);
      }
      else {
        this.formModelAereo.value.PesoTasable = Number(valor.toFixed(3));
      }
    }
  }

  //Funcion que replica el numero de referencia en las referencias de carga y entrega si no son iguales
  replicaReferencias(form: NgForm) {
    if (this.formModelAereo.value.ReferenciaRecogida == this.referenciaClienteAnterior || this.formModelAereo.value.ReferenciaRecogida == '' || this.formModelAereo.value.ReferenciaRecogida == null) {
      this.formModelAereo.value.ReferenciaRecogida = this.formModelAereo.value.Referencia;
      form.value.ReferenciaRecogida = this.formModelAereo.value.Referencia;
      if (this.formModelAereo.value.ReferenciaRecogida != this.referenciaClienteAnterior) {
        this.compruebaReferenciaRecogida(form);
      }
    }
    if (this.formModelAereo.value.ReferenciaEntrega == this.referenciaClienteAnterior || this.formModelAereo.value.ReferenciaEntrega == '' || this.formModelAereo.value.ReferenciaEntrega == null) {
      this.formModelAereo.value.ReferenciaEntrega = this.formModelAereo.value.Referencia;
      form.value.ReferenciaEntrega = this.formModelAereo.value.Referencia;
      if (this.formModelAereo.value.ReferenciaEntrega != this.referenciaClienteAnterior) {
        this.compruebaReferenciaEntrega(form);
      }
    }
    this.referenciaClienteAnterior = this.formModelAereo.value.Referencia;
  }

  //Funcion que se utiliza para obtener la respuesta del modal de confirmacion del seguro
  //esta respuesta si es afirmativa se realiza el envio aereo
  confirmacionSeguro(confirmacion: boolean) {
    this.modalRef.hide();
    if (confirmacion == true) {
      this.envioAereo(this.form);
    }
  }

  //Funcion que se utiliza para obtener la respuesta del modal de confirmacion de guardado de plantilla
  //si la respuesta es afirmativa guardamos el formulario como plantilla
  confirmacionPlantilla(confirmacion: boolean) {
    this.modalRef.hide();
    if (confirmacion == true) {
      //guardamos plantilla
      this.asignarFormAPlantilla();
      var log: LogModel = new LogModel();
      log.Click = "enviarPlantilla";
      log.Pantalla = this.clase;
      this.envioService.postPlantilla(this.shipmentAereo, log).subscribe(data => {
        var Body = this.translateService.instant("Se ha añadido la plantilla correctamente.");
        var Titulo = this.translateService.instant("Plantilla guardada");
        this.toastr.success(Body, Titulo)
      });
    }
    this.shipmentAereo = new Shipment();
  }

  asignarFormAPlantilla() {
    this.convertFormToModel(this.formModelAereo)
  }

  //Funciones que comprueban que los input sean validos para remarcarlos como error
  compruebaTipoEnvio(form: NgForm): Boolean {
    if (form.value.TipoEnvio == 3 || form.value.TipoEnvio == 4) {
      this.errorTipoEnvio = false;
    }
    else {
      this.errorTipoEnvio = true;
    }
    return this.errorTipoEnvio;
  }

  compruebaReferencia(form?: NgForm): Boolean {
    if (this.formModelAereo.value.Referencia == '') {
      this.errorReferencia = true;
    }
    else {
      this.errorReferencia = false;
    }
    return this.errorReferencia;
  }

  compruebaListShipper(form: NgForm): Boolean {
    if (form.value.ListShipper == '') {
      this.errorListShipper = true;
    }
    else {
      this.errorListShipper = false;
    }
    return this.errorListShipper;
  }

  compruebaListConsignee(form: NgForm): Boolean {
    if (form.value.ListConsignee == '') {
      this.errorListConsignee = true;
    }
    else {
      this.errorListConsignee = false;
    }
    return this.errorListConsignee;
  }

  compruebaIncoterm(form: NgForm): Boolean {
    if (form.value.Incoterm == '') {
      this.errorIncoterm = true;
    }
    else {
      this.errorIncoterm = false;
    }
    return this.errorIncoterm;
  }

  compruebaBultosTotales(form?: NgForm): Boolean {
    if (this.formModelAereo.value.BultosTotales == null || this.formModelAereo.value.BultosTotales == 0)
      this.errorBultosTotales = true;
    else
      this.errorBultosTotales = false;

    return this.errorBultosTotales;
  }

  compruebaPersonaContactoRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida != undefined) {
      if (form.value.EntidadRecogida.IdEntidad != 20) {
        if (form.value.PersonaContactoRecogida == '')
          this.errorPersonaContactoRecogida = true;
        else
          this.errorPersonaContactoRecogida = false;
      }
    }
    return this.errorPersonaContactoRecogida;

  }


  compruebaEmailRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida != undefined) {
      if (form.value.EntidadRecogida.IdEntidad != 20) {
        if (form.value.EmailRecogida == '')
          this.errorEmailRecogida = true;
        else
          this.errorEmailRecogida = false;

      }
    }
    return this.errorEmailRecogida;
  }

  compruebaPesoNetoTotal(form?: NgForm): Boolean {
    // this.formModelAereo.value.PesoNetoTotal = Number(this.formModelAereo.value.PesoNetoTotal.toString().replace(',', '.'));
    // if(this.formModelAereo.value.PesoNetoTotal == null || this.formModelAereo.value.PesoNetoTotal == 0)
    // {
    //   this.errorPesoNetoTotal = true;
    // }
    // else
    // {
    this.errorPesoNetoTotal = false;
    //}
    return this.errorPesoNetoTotal;
  }

  compruebaPesoBrutoTotal(form?: NgForm): Boolean {
    if (this.formModelAereo.value.PesoBrutoTotal != undefined && this.formModelAereo.value.PesoBrutoTotal != null) {
      this.formModelAereo.value.PesoBrutoTotal = Number(this.formModelAereo.value.PesoBrutoTotal.toString().replace(',', '.'));
      if (this.formModelAereo.value.PesoBrutoTotal == null || this.formModelAereo.value.PesoBrutoTotal == undefined || this.formModelAereo.value.PesoBrutoTotal == 0)
        this.errorPesoBrutoTotal = true;
      else
        this.errorPesoBrutoTotal = false;
    } else
      this.errorPesoBrutoTotal = true;


    return this.errorPesoBrutoTotal;
  }

  compruebaPesoNetoTotalDGR(form?: NgForm): Boolean {
    this.errorPesoNetoTotalDGR = false;
    this.muestraErrorNetoDGR = false;
    // if(this.formModelAereo.value.MercanciaPeligrosa == true)
    // {
    //   if(this.formModelAereo.value.PesoNetoTotalDGR == 0)
    //   {
    //     this.errorPesoNetoTotalDGR = true;
    //     this.muestraErrorNetoDGR = true;
    //   }
    //   else if (this.formModelAereo.value.PesoNetoTotalDGR == null)
    //   {
    //     this.errorPesoNetoTotalDGR = true;
    //     this.muestraErrorNetoDGR = true;
    //   }
    // }
    // else
    // {
    //   this.errorPesoNetoTotalDGR = false;
    //   this.muestraErrorNetoDGR = false;
    // }
    return this.errorPesoNetoTotalDGR;
  }

  compruebaVolumenTotal(form?: NgForm): Boolean {
    if (this.formModelAereo.value.VolumenTotal == null || this.formModelAereo.value.VolumenTotal == 0)
      this.errorVolumenTotal = true;
    else
      this.errorVolumenTotal = false;
    return this.errorVolumenTotal;
  }

  compruebaTelefonoRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida != undefined) {
      if (form.value.EntidadRecogida.IdEntidad != 20) {
        if (form.value.TelefonoRecogida == '' || form.value.TelefonoRecogida == null)
          this.errorTelefonoRecogida = true;
        else
          this.errorTelefonoRecogida = false;
      }
    } else {
      if (form.value.TelefonoRecogida == '' || form.value.TelefonoRecogida == null)
        this.errorTelefonoRecogida = true;
      else
        this.errorTelefonoRecogida = false;
    }
    return this.errorTelefonoRecogida;

  }

  compruebaFechaRecogida(form: NgForm): Boolean {
    if (form.value.FechaRecogida == '')
      this.errorFechaRecogida = true;
    else
      this.errorFechaRecogida = false;
    if (this.validarFechaRecogida()) {
      this.errorFechaRecogidaMenorActual = true;
      var Body = this.translateService.instant("El campo fecha disponibilidad debe ser igual o superior a la fecha de hoy");
      var Titulo = this.translateService.instant("Error en fecha disponibilidad");
      this.toastr.error(Body, Titulo)
    }
    else {
      this.errorFechaRecogidaMenorActual = false;
    }
    return this.errorFechaRecogida;
  }

  compruebaPersonaContactoEntrega(form: NgForm): Boolean {
    this.errorPersonaContactoEntrega = true;
    if (form.value.EntidadEntrega != undefined) {
      if (form.value.EntidadEntrega.IdEntidad != 20) {
        if (form.value.PersonaContactoEntrega == '')
          this.errorPersonaContactoEntrega = true;
        else
          this.errorPersonaContactoEntrega = false;
      }
    } else {
      if (form.value.PersonaContactoEntrega == '')
        this.errorPersonaContactoEntrega = true;
      else
        this.errorPersonaContactoEntrega = false;
    }
    return this.errorPersonaContactoEntrega;
  }

  compruebaTelefonoEntrega(form: NgForm): Boolean {
    this.errorTelefonoEntrega = true;
    if (form.value.EntidadEntrega != undefined) {
      if (form.value.EntidadEntrega.IdEntidad != 20) {
        if (form.value.TelefonoEntrega == '')
          this.errorTelefonoEntrega = true;
        else
          this.errorTelefonoEntrega = false;
      }
    } else {
      if (form.value.TelefonoEntrega == '')
        this.errorTelefonoEntrega = true;
      else
        this.errorTelefonoEntrega = false;
    }
    return this.errorTelefonoEntrega;
  }

  compruebaEmailEntrega(form: NgForm): Boolean {
    this.errorEmailEntrega = true;
    if (form.value.EntidadEntrega != undefined) {
      if (form.value.EntidadEntrega.IdEntidad != 20) {
        if (form.value.EmailEntrega == '')
          this.errorEmailEntrega = true;
        else
          this.errorEmailEntrega = false;
      }
    } else {
      if (form.value.EmailEntrega == '')
        this.errorEmailEntrega = true;
      else
        this.errorEmailEntrega = false;
    }
    return this.errorEmailEntrega;
  }

  compruebaMercancia(): Boolean {

    if (this.listaMercancia.length == 0 || this.listaMercancia == undefined)
      this.errorMercancia = true;
    else
      this.errorMercancia = false;

    return this.errorMercancia;
  }

  compruebaInstruccionesBLAWB(form: NgForm): Boolean {
    if (form.value.InstruccionesBLAWB == "" || form.value.InstruccionesBLAWB == undefined || form.value.InstruccionesBLAWB == null)
      this.errorInstruccionesBLAWB = true;
    else
      this.errorInstruccionesBLAWB = false;
    return this.errorInstruccionesBLAWB;
  }

  compruebaEntidadRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida == null) {
      this.errorEntidadRecogida = true;
    }
    else {
      this.errorEntidadRecogida = false;
    }
    return this.errorEntidadRecogida;
  }

  compruebaEntidadEntrega(form: NgForm): Boolean {
    if (form.value.EntidadEntrega == null) {
      this.errorEntidadEntrega = true;
    }
    else {
      this.errorEntidadEntrega = false;
    }
    return this.errorEntidadEntrega;
  }

  compruebaValorMercancia(form: NgForm) {
    this.formModelAereo.value.ValorMercancia = Number(this.formModelAereo.value.ValorMercancia.toString().replace(',', '.'));
    form.value.ValorMercancia = Number(this.formModelAereo.value.ValorMercancia.toString().replace(',', '.'));
    if (form.value.ValorMercancia == '' || form.value.ValorMercancia == null) {
      this.errorValorMercancia = true;
    }
    else {
      this.errorValorMercancia = false;
    }
    return this.errorValorMercancia;
  }

  compruebaReferenciaRecogida(form: NgForm) {
    if (form.value.ReferenciaRecogida == '') {
      this.errorReferenciaRecogida = true;
    }
    else {
      this.errorReferenciaRecogida = false;
    }
    return this.errorReferenciaRecogida;
  }

  compruebaReferenciaEntrega(form: NgForm) {

    if (form.value.ReferenciaEntrega == '') {
      this.errorReferenciaEntrega = true;
    }
    else {
      this.errorReferenciaEntrega = false;
    }
    return this.errorReferenciaEntrega;
  }

  recibirSearch(search) {
    this.searchText = search;
  }

  // setNoRemontable(noRemontable: boolean) {
  //   if (noRemontable == true) {
  //     this.formModelAereo.value.NoRemontable = true;
  //   }
  // }

  cerrar() {
    this.modalRef.hide();

    window.setTimeout(function () {
      document.getElementById('ValorMercancia').focus();
    }, 0);
  }

  obtenerDireccionEntidad(direccion: Direccion) {
    if (direccion == null) {
      if (this.tipoDireccion == 'shipper') {
        window.setTimeout(function () {
          document.getElementById('ReferenciaRecogida').focus();
        }, 0);
      }
      else if (this.tipoDireccion == 'consignee') {
        window.setTimeout(function () {
          document.getElementById('ReferenciaEntrega').focus();
        }, 0);
      }
      this.modalRef.hide();
    }
    else {
      if (this.tipoDireccion == 'shipper') {
        //TODO: no me acuerdo
        window.setTimeout(function () {
          document.getElementById('ReferenciaRecogida').focus();
        }, 0);
      }
      else if (this.tipoDireccion == 'consignee') {
        //TODO: no me acuerdo
        window.setTimeout(function () {
          document.getElementById('ReferenciaEntrega').focus();
        }, 0);
      }
      this.modalRef.hide();
    }
  }

  esImport() {
    return this.formModelAereo.value.IdTipoEnvio == 4;
  }

  recogidaRequired(form: NgForm): Boolean {
    if (form != undefined) {
      if (form.value.EntidadRecogida != undefined) {
        if (form.value.EntidadRecogida.IdEntidad == 20) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    return false;
  }

  entregaRequired(form: NgForm): Boolean {
    if (form != undefined) {
      if (form.value.EntidadEntrega != undefined) {
        if (form.value.EntidadEntrega.IdEntidad == 20) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    return false;
  }

  obtenerEntidad(entidad: Entity) {
    this.entidad = entidad;
    this.modalRef.hide();
  }

  esEspanyaItalia() {
    return ((this.corresponsal != undefined && this.corresponsal != null) && (this.corresponsal.IdDelegacion == 1 || this.corresponsal.IdDelegacion == 4));
  }

  refresEntidades(actualizado: boolean) {
    if (actualizado) {
      //Si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
      this.envioService.getEntidadesAddressBook().subscribe(data => {
        this.listShipper = data;
        this.listConsignee = data;
        //   this.entidadService.getEntidadById(this.entidadCliente).subscribe(entidadCliente => {
        //     this.listShipper = data;
        //     this.listConsignee = data;
        //     if (this.esExport()) {
        //       this.listShipper.push(entidadCliente);
        //       this.listShipper.forEach(shipper => {
        //         this.formModelAereo.value.Shipper = shipper;
        //         this.formModelAereo.value.EntidadRecogida = shipper;
        //         this.formModelAereo.value.Cliente = shipper;
        //         this.setShipperInformation();

        //         if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
        //           this.visor = this.envioService.getVisor();
        //           if (this.envio != undefined) {
        //             this.asignarValores(this.envio);
        //           }
        //         }
        //       });
        //     }
        //     else {
        //       this.listConsignee.push(entidadCliente);
        //       this.listConsignee.forEach(consignee => {
        //         this.formModelAereo.value.Shipper = consignee;
        //         this.formModelAereo.value.EntidadRecogida = consignee;
        //         this.formModelAereo.value.Cliente = consignee;
        //         this.setShipperInformation();

        //         if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
        //           this.visor = this.envioService.getVisor();
        //           if (this.envio != undefined) {
        //             this.asignarValores(this.envio);
        //           }
        //         }
        //       });
        //     }
        //     this.cargaRemitente = this.cargaDestinatario = false;
        //     this.cargaEntidadRecogida = this.cargaEntidadEntrega = false;
        //     // if (this.tipoEntidad == 'shipper') {
        //     //   this.listShipper.forEach(element => {
        //     //     if (element.NombreFiscal == this.entidad.NombreFiscal) {
        //     //       this.formModelAereo.value.Shipper = element;
        //     //     }
        //     //   })
        //     //   this.setShipperInformation();
        //     // }
        //   });
      });
      // this.envioService.getShipper(this.esExport()).subscribe(data => {
      //   this.listShipper = data;
      //   this.listShipper.forEach(shipper => {
      //     if (shipper.IdEntidad == 20 && this.corresponsal.IdCliente == 20 && this.corresponsal.IdDelegacion == 1 && this.corresponsal.IsCorresponsal == false) {
      //       this.formModelAereo.value.Shipper = shipper;
      //       this.formModelAereo.value.EntidadRecogida = shipper;
      //       this.formModelAereo.value.Cliente = shipper;
      //       this.setShipperInformation();
      //     }
      //     if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
      //       this.visor = this.envioService.getVisor();
      //       if (this.envio != undefined) {
      //         this.asignarValores(this.envio);
      //       }
      //     }
      //   });
      //   if (this.tipoEntidad == 'shipper') {
      //     this.listShipper.forEach(element => {
      //       if (element.NombreFiscal == this.entidad.NombreFiscal) {
      //         this.formModelAereo.value.Shipper = element;
      //       }
      //     })
      //     this.setShipperInformation();
      //   }
      // });

      //si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
      /*this.envioService.getConsignee(this.esExport()).subscribe(data => {
        this.listConsignee = data;
        if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
          this.visor = this.envioService.getVisor();
          if (this.envio != undefined) {
            this.asignarValores(this.envio);
          }
        }
        if (this.tipoEntidad == 'consignee') {
          this.listConsignee.forEach(element => {
            if (element.NombreFiscal == this.entidad.NombreFiscal) {
              this.formModelAereo.value.Consignee = element;
            }
          })
          this.setConsigneeInformation();
        }
      });*/
      this.cargarListaNotify();
    }
  }

  esExport() {
    return this.formModelAereo.value.TipoEnvio == 3;
  }

  compruebaDuplicadoReferenciaCliente() {
    if (this.formModelAereo.value.Referencia != null && this.formModelAereo.value.Referencia != '' && this.formModelAereo.value.Referencia != undefined) {
      this.envioService.compruebaRefCliDuplicados(this.formModelAereo.value.Referencia).subscribe(data => {
        this.refCliDuplicado = data;

        return this.refCliDuplicado;
      })
    }
  }

  cambiarEstadoDireccion(checked: boolean, quien: string) {
    if (quien == 'shipper') {
      this.readonlyDireccionShipper = checked;
    }
    else if (quien == 'consignee') {
      this.readonlyDireccionConsignee = checked;
    }
    else if (quien == 'notify') {
      this.readonlyDireccionNotify = checked;
    }
  }

  getBandera(a: Aeropuerto) {
    return 'assets/banderas/' + a.IsoAlfa2.toLowerCase() + '.png';
  }

  obtenerRespuesta(respuesta: boolean) {
    if (respuesta)
      this.router.navigateByUrl("/customer");
    this.modalRef.hide();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('-');
  }

  /* compruebaFechaRecogida(form: NgForm): Boolean {
     if(form.value.FechaRecogida == ''  )
     {
       this.errorFechaRecogida = true;  
       this.errorFechaRecogidaMenorActual = false;   
     }
     else
     {
       this.errorFechaRecogida = false;
       if(this.validarFechaRecogida()){
         this.errorFechaRecogidaMenorActual = true;     
         var Body = this.translateService.instant("El campo fecha disponibilidad debe ser igual o superior a la fecha de hoy");
         var Titulo = this.translateService.instant("Error en fecha disponibilidad");
         this.toastr.error(Body, Titulo)  
       } 
       else{
         this.errorFechaRecogidaMenorActual = false;     
 
       }    
     }
     return this.errorFechaRecogida;
     }*/

  validarFechaRecogida(): Boolean {
    var fechaForm: Date = new Date(this.formModelAereo.value.FechaRecogida)
    var fechaHoy: Date = new Date();
    if ((fechaForm.getTime() + 86400000) < fechaHoy.getTime()) {
      return true;
    }
    else {
      return false;
    }

  }

  // tslint:disable-next-line:typedef
  downloadPDF() {
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    // Asignamos en DATA el contenido del html que tiene como id 'tracking'
    const DATA = document.getElementById('resumen');
    // Damos formato al documento: p (portrait = vertical), pt (la unidad de media), a4 (tamaño del pdf)
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 2
      //pagesplit: true
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('application/pdf');
      //const img = canvas.toDataURL('image/png');
      //Add image Canvas to PDF
      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      return doc;
    }).then((docResult) => {
      docResult.save('ebooking_' + this.formModelAereo.value.Referencia + '_' + this.formatDate(new Date()) + '.pdf');
      var archivo = new Archivo();
      archivo.Base64image = docResult.output('datauristring');
      archivo.FileName = 'ebooking_' + this.formModelAereo.value.Referencia + '_' + this.formatDate(new Date());
      archivo.Extension = '.pdf';
      archivo.TipoArchivo = '19';
      this.archivoBooking = archivo;
      dialogRef.close();
      var body = this.translateService.instant("BodyInfoSubidaEbooking");
      var title = this.translateService.instant("TitleInfoSubirEbooking");
      this.toastr.info(body, title);
    });
  }
}