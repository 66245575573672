import { EventEmitter, Input, TemplateRef } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { LogModel } from 'src/app/models/log-model.model';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';

@Component({
  selector: 'app-subir-factura',
  templateUrl: './subir-factura.component.html',
  styleUrls: ['./subir-factura.component.scss']
})
export class SubirFacturaComponent implements OnInit {
  clase: string = "SubirFacturaComponent";
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  photo: string[] = [];
  pdfs: string[] = [];
  textoModal: string = "";
  @Input() Expedicion: string = "";
  @Output() cerrarModal = new EventEmitter<number>()
  @Output() limpiarHistorial = new EventEmitter<number>()
  @Input() idExpedicion: number = 0;
  @Input() Expediciones: string[] = [];

  constructor(public dialog: MatDialog, private toastr: ToastrService, private modalService: BsModalService, private expeditionService: ExpeditionApiService, private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 2
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }
  // AQUÍ!!
  postFactura(click: string){
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => {});
    var logmodel = new LogModel();
    logmodel.Click = click
    logmodel.Pantalla = this.clase
    this.expeditionService.postFacturas(this.photo, this.pdfs, this.idExpedicion, logmodel).subscribe(data => {
      var Body = this.translateService.instant('La factura se ha subido correctamente al sistema.');
      var Titulo = this.translateService.instant('Subida correcta');
      this.toastr.success(Body, Titulo)
      dialogRef.close();
      this.cerrarModal.emit(1);
    }, err => {
      var Body = this.translateService.instant('Error en subir la factura.');
      var Titulo = this.translateService.instant('Error');
      this.toastr.error(Body, Titulo)
      dialogRef.close();
    })
  }

  postFacturas(click: string){
    
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel'
    });
    dialogRef.afterOpened().subscribe(result => {});
    var expediciones = []
    this.Expediciones.forEach(ex => expediciones.push(ex));
    var logmodel = new LogModel();
    logmodel.Click = click
    logmodel.Pantalla = this.clase
    this.expeditionService.postFacturasConjuntas(this.photo, this.pdfs, expediciones, logmodel).subscribe(data => {
      var Body = this.translateService.instant('Las facturas se han subido correctamente al sistema.');
      var Titulo = this.translateService.instant('Subida correcta');
      this.toastr.success(Body, Titulo);
      dialogRef.close();
      this.cerrarModal.emit(1);
    }, err => {
      var Body = this.translateService.instant('No se puede subir la factura conjunta');
      var Titulo = this.translateService.instant('Error subir factura conjunta');
      this.toastr.error(Body, Titulo)
      dialogRef.close();
    })
  }

  enviarActualizarFactura(event){
    if(event){
      if(this.idExpedicion != null){ // Solo es una factura
        this.postFactura("Actualización de facturas");
        this.inicializar();
      }else{ // Muchas facturas
        this.postFacturas("Actualización de nuevas facturas");
        this.inicializar();
      }
    }else{
      this.modalRef.hide();
    } 
  }

  inicializar(){
    this.Expediciones = [];
    this.modalRef.hide();
    this.close();
  }

  enviarFactura(template: TemplateRef<any>){
    if(this.photo.length > 0 || this.pdfs.length > 0){
      if(this.idExpedicion != null){ // Solo una factura a una expedición
        this.expeditionService.getExisteFactura(this.idExpedicion).subscribe(expedicionConFactura => {
          if(expedicionConFactura){ // Ya existe factura para la expedición
            this.textoModal = this.translateService.instant("Vamos a sustituir la factura de la expedición ") + this.Expedicion;
            this.openModal(template)
          }else // Todavia no tiene factura esta expedición
            this.postFactura("Nueva factura");
        });
      }else{ // Muchas expediciones facturadas
        var logmodel = new LogModel();
        logmodel.Click = "Enviar facturas y saber si tiene facturas existentes"
        logmodel.Pantalla = this.clase
        this.expeditionService.getExisteFacturas(this.Expediciones, logmodel).subscribe(expedicionesConFactura => {
          var tamanyo : number = expedicionesConFactura.length;
          if(tamanyo > 0){ // Ya existía alguna expedición con alguna factura
            this.textoModal = this.translateService.instant("Vamos a sustituir la factura de las expediciones: ") +  "\n\r";
            var index: number = 0;
            expedicionesConFactura.forEach(e => {
              if(index == 0)
                this.textoModal += e;
              else if(tamanyo - 1 == index)
                this.textoModal += this.translateService.instant(" y ") + e;
              else
                this.textoModal += ", " + e;
              
              index++;
            })
            this.openModal(template)
          }else // Primera vez para introducir la factura
            this.postFacturas("Nuevas facturas");
        })
      }
    }else{
      var errorBody = this.translateService.instant('Debes de introducir alguna imagen o algún pdf para poder enviar la factura');
      var errorTitulo = this.translateService.instant('Error en subir factura');
      this.toastr.error(errorBody, errorTitulo)
    }
  }

  //Funcion que añade las fotos realizadas por movil o seleccionadas desde el pc a un array, solo se puede de 1 en 1
  fileChanges(fileList){
    if(fileList.length > 0){
      let me = this;
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if(reader.result.toString().includes("data:image/"))
          me.photo.push(reader.result.toString());
        else if(reader.result.toString().includes("data:application/pdf;"))
          me.pdfs.push(reader.result.toString());
        else
        {
          var errorBody = me.translateService.instant('El archivo seleccionado no es una imagen o un pdf.');
          var errorTitulo = me.translateService.instant('Error');
          me.toastr.error(errorBody, errorTitulo)
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }else
      this.photo = [];
  }
  
  close() {
    this.cerrarModal.emit(1);
  }

}
