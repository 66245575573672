<form [formGroup]="formConductor">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="example-full-width">
          <mat-label>{{'Apellidos'|translate}}</mat-label>
          <input type="text" matInput placeholder="Apellidos" [formControl]="apellidos">
          <mat-error *ngIf="!apellidos.valid">
            {{'Introduce los apellidos.'|translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="example-full-width">
          <mat-label>{{'Nombre'|translate}}</mat-label>
          <input type="text" matInput placeholder="Nombre" [formControl]="nombre">
          <mat-error *ngIf="!nombre.valid">
            {{'Introduce el nombre.'|translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="example-full-width">
          <mat-label>{{'NIF'|translate}}</mat-label>
          <input type="text" [readonly]="isUpdate()" matInput placeholder="NIF" min="9" max="9" [formControl]="nif">
          <mat-error *ngIf="!nif.valid && nif.hasError('required')">
            {{'Nif es requerido.'|translate}}
          </mat-error>
          <mat-error *ngIf="!nif.valid && nif.hasError('min') && nif.hasError('max')">
            {{'ErrorNifMinMax.'|translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-4">
        <mat-form-field class="example-full-width">
          <mat-label>{{'Móvil'|translate}}</mat-label>
          <input type="text" matInput placeholder="Movil" [formControl]="movil">
          <mat-error *ngIf="!movil.valid && movil.hasError('minlength')">
            {{'Es necesario añadir 9 digitos.'|translate}}
          </mat-error>
          <mat-error *ngIf="!movil.valid && movil.hasError('required')">
            {{'Es necesario añadir un número de teléfono.'|translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-2">
        <mat-form-field class="example-full-width">
          <mat-label>{{'Ext.'|translate}}</mat-label>
          <input type="text" matInput placeholder="Ext." [formControl]="ext">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field class="example-full-width">
          <mat-label>{{'Correo electrónico'|translate}}</mat-label>
          <input type="text" matInput placeholder="Correo electronico" [formControl]="email">
          <mat-error *ngIf="!email.valid && email.hasError('email')">
            {{'Formato email requerido.'|translate}}
          </mat-error>
          <mat-error *ngIf="!email.valid && email.hasError('required')">
            {{'El email es requerido.'|translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="example-full-width">
          <mat-label>{{'Contacto Alternativo'|translate}}</mat-label>
          <input type="text" matInput placeholder="Contacto Alternativo" [formControl]="contactoAlternativo">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <button *ngIf="!isUpdate()" type="button" class="btn margenBot" id="crear-conductor"
          (click)="postConductor('crear-conductor')" matButton>{{'Crear'|translate}}</button>
        <button *ngIf="isUpdate()" type="button" class="btn margenBot" id="actualizar-conductor"
          (click)="updateConductor('actualizar-conductor')" matButton>{{'Modificar'|translate}}</button>
      </div>
      <div class="col-sm-4">
        <button type="button" class="btn margenBot" (click)="resetear();" matButton>{{'Limpiar'|translate}}</button>
      </div>
    </div>
  </div>
</form>