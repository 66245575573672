import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { Expedition } from 'src/app/models/expedition.model';
import { EntityService } from 'src/app/services/entity.service';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { ProveedorService } from 'src/app/services/proveedor.service';

@Component({
  selector: 'app-table-entrega-sin-albaran',
  templateUrl: './table-entrega-sin-albaran.component.html',
  styleUrls: ['./table-entrega-sin-albaran.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({
        height: '0px',
        visibility: 'hidden'
      })),
      state('expanded', style({
        'min-height': '48px',
        height: '*',
        visibility: 'visible'
      })),
      transition(
        'expanded <=> collapsed, void <=> *',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ]
})
export class TableEntregaSinAlbaranComponent implements OnInit {

  @Input() expedicionesPendientesEntregaAlbaran: MatTableDataSource<Expedition> = null;
  expandedElement: Expedition | null;
  expedicion: Expedition = new Expedition();
  estados: string[] = ["Pendiente", "Recogida", "Entregado", "Entregado Falta Albarán"];
  modalRef: BsModalRef | null;
  corresponsal: Corresponsal;
  
  @Output() actualizar = new EventEmitter<number>();

  constructor(private proveedorService: ProveedorService, private expService: ExpeditionApiService, private entidadService: EntityService,
    private translateService: TranslateService, private toastr: ToastrService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.entidadService.getCorresponsal().subscribe(c => {
      this.corresponsal = c; 
    });
  }

  showDetails(exp: Expedition){
    if(this.warningEntrega(exp)){
      var bodyWarning = this.translateService.instant("BodyWarningEntregaSinAlbaran");
      var titleWarning = this.translateService.instant("TitleWarningEntregaSinAlbaran");
      this.toastr.warning(bodyWarning, titleWarning);
    }
    this.expedicion = new Expedition();
    this.expService.getExpedicion(exp.Numero).subscribe(data => {
      this.expedicion = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede obtener la información de la expedición.');
      var errorTitulo = this.translateService.instant('Error obtener información');
      this.toastr.error(errorBody, errorTitulo)
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    }

  actualizarAll(event){
    if(event == 2){
      this.inicializar();
    }
    //this.getExpedicion(this.codigoSelected);
  }

  inicializar(){
    this.proveedorService.getExpedicionesProveedor();
  }

  getColumnas(){
    return [/*'cuidado',*/ 'referencia', 'expedicion', 'fecha', ' '];
  }

  /* DETALLES */

  isHorarioCompletoConPausaIntermediaRecogida(){
    return this.expedicion.hr1 != "" && this.expedicion.hr2 != "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" 
    && this.expedicion.hr1 != null && this.expedicion.hr2 != null && this.expedicion.hr3 != null && this.expedicion.hr4 != null;
  }

  isHorarioFijoSinPausaIntermediaRecogida(){
    return this.expedicion.hr1 == "" && this.expedicion.hr2 == "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" && this.expedicion.hr3 != this.expedicion.hr4;
  }

  isHoraFijaRecogida(){
    return this.expedicion.hr1 == "" && this.expedicion.hr2 == "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" && this.expedicion.hr3 == this.expedicion.hr4;
  }

  isHorarioCompletoConPausaIntermediaEntrega(){
    return this.expedicion.he1 != "" && this.expedicion.he2 != "" && this.expedicion.he3 != "" && this.expedicion.he4 != ""
    && this.expedicion.he1 != null && this.expedicion.he2 != null && this.expedicion.he3 != null && this.expedicion.he4 != null;
  }

  isHorarioFijoSinPausaIntermediaEntrega(){
    return this.expedicion.he1 == "" && this.expedicion.he2 == "" && this.expedicion.he3 != "" && this.expedicion.he4 != "" && this.expedicion.he3 != this.expedicion.he4;
  }

  isHoraFijaEntrega(){
    return this.expedicion.he1 == "" && this.expedicion.he2 == "" && this.expedicion.he3 != "" && this.expedicion.he4 != "" && this.expedicion.he3 == this.expedicion.he4;
  }

  toStringRecogida(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fechaFinal: string = "";
      var fecha = fechaTotal.split(" ")[0]
      fechaFinal = fecha + ' ';
      if(this.isHorarioCompletoConPausaIntermediaRecogida()){ // HORARIO COMPLETO CON PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        var ydesdelas = this.translateService.instant(" y desde las ");
        var a = this.translateService.instant(" a ");
        fechaFinal += desdelas + this.expedicion.hr1 + hastalas + this.expedicion.hr2 + ydesdelas + this.expedicion.hr3 + a + this.expedicion.hr4;
      }else if(this.isHorarioFijoSinPausaIntermediaRecogida()){ // HORARIO FIJO SIN PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        fechaFinal += desdelas + this.expedicion.hr3 + hastalas + this.expedicion.hr4;
      }else if(this.isHoraFijaRecogida()){ // HORARIO DE UNA SOLA HORA
        var horarioFijo = this.translateService.instant("HORARIO FIJO R: ");
        fechaFinal += horarioFijo + this.expedicion.hr3;
      }
      return fechaFinal;
    }
  }

  toStringEntrega(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fechaFinal: string = "";
      var fecha = fechaTotal.split(" ")[0]
      fechaFinal = fecha + ' ';
      if(this.isHorarioCompletoConPausaIntermediaEntrega()){ // HORARIO COMPLETO CON PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        var ydesdelas = this.translateService.instant(" y desde las ");
        var a = this.translateService.instant(" a ");
        fechaFinal += desdelas + this.expedicion.he1 + hastalas + this.expedicion.he2 + ydesdelas + this.expedicion.he3 + a + this.expedicion.he4;
      }else if(this.isHorarioFijoSinPausaIntermediaEntrega()){ // HORARIO FIJO SIN PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        fechaFinal += desdelas + this.expedicion.he3 + hastalas + this.expedicion.he4;
      }else if(this.isHoraFijaEntrega()){ // HORARIO DE UNA SOLA HORA
        var horarioFijo = this.translateService.instant("HORARIO FIJO E: ");
        fechaFinal += horarioFijo + this.expedicion.he3;
      }
      return fechaFinal;
    }
  }

  toString(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fecha = fechaTotal.split(" ")[0]
      var dia = fecha.split("/")[0]
      var mes = fecha.split("/")[1]
      var anyo = fecha.split("/")[2]
      var hora = fechaTotal.split(" ")[1]
      return dia + '/' + mes + '/' + anyo + ' a las ' + hora;
    }
  }

  // Imprimir en un string la dirección completa
  montarDireccion(direccion: string, codPostal: string, poblacion: string){
    var direccionTotal: string = "";
    if(direccion != undefined){
      direccionTotal += direccion;
    }
    if(poblacion != undefined){
      if(direccionTotal != ""){
        direccionTotal += ', ' + poblacion;
      }else{
        direccionTotal += poblacion;
      }
    }
    if(codPostal != undefined){
      if(direccionTotal != ""){
        direccionTotal += ', ' + codPostal;
      }else{
        direccionTotal += codPostal;
      }
    }
    return direccionTotal
  }

  muestraDestinatario(expedicion: Expedition): Boolean{
    var mostrar: Boolean = false;
    if(expedicion.Estado != "Pendiente" && expedicion.Estado != null && expedicion.Estado != undefined)
    {
      mostrar = true;
    }
    return mostrar;
  }

  getEstado(estado: string){
    if(estado == null || estado == '' || estado == undefined){
      return "Pendiente";
    }
    return estado;
  }

  cerrarModal(event){
    this.modalRef.hide();
    this.actualizar.emit(1);
    /*if(event == 2){
      this.inicializar();
    }*/
  }

  isHoraOk(hora: string){
    return hora != null && hora != undefined && hora != "00:00:00";
  }

  okEntrega(expedicion: Expedition){
    var ahora: Date = new Date();
    if(this.isHoraOk(expedicion.he1) && this.isHoraOk(expedicion.he4)){
      var fechaRecogidaLimitePrimera: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0]) , Number(expedicion.he1.split(':')[0]), Number(expedicion.he1.split(':')[1]));
      var fechaRecogidaLimiteUltima: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0]) , Number(expedicion.he4.split(':')[0])+1 , 18, 0);
      return expedicion.FechaLlegada != null && fechaRecogidaLimitePrimera.getTime() < ahora.getTime() && fechaRecogidaLimiteUltima.getTime() > ahora.getTime();
    }else if(this.isHoraOk(expedicion.he3) && this.isHoraOk(expedicion.he4)){
      var fechaRecogidaLimitePrimera: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0]) , Number(expedicion.he3.split(':')[0]), Number(expedicion.he3.split(':')[1]));
      var fechaRecogidaLimiteUltima: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0]) , Number(expedicion.he4.split(':')[0])+1 , 18, 0);
      return expedicion.FechaLlegada != null && ahora.getTime() > fechaRecogidaLimitePrimera.getTime() && ahora.getTime() < fechaRecogidaLimiteUltima.getTime();
    }
    return false;
  }

  warningEntrega(expedicion: Expedition){
    var ahora: Date = new Date();
    if(this.isHoraOk(expedicion.he1)){
      var fechaRecogidaLimitePrimera: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0])+1 , 18, 0);
      return expedicion.FechaLlegada != null && ahora.getTime() < fechaRecogidaLimitePrimera.getTime();
    }else if(this.isHoraOk(expedicion.he3)){
      var fechaRecogidaLimitePrimera: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0])+1 , 18, 0);
      return expedicion.FechaLlegada != null && ahora.getTime() < fechaRecogidaLimitePrimera.getTime();
    }
    var fechaRecogidaLimite: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0])+1 , 18, 0);
    return expedicion.FechaLlegada != null && ahora.getTime() < fechaRecogidaLimite.getTime();
  }

  sinRecompensa(expedicion: Expedition){
    var ahora: Date = new Date();
    var hora = "0:0";
    if(this.isHoraOk(expedicion.he4))
      hora = expedicion.he4;
    else if(this.isHoraOk(expedicion.he2))
      hora = expedicion.he2;
    var fechaRecogidaLimite: Date = new Date(Number(expedicion.FechaLlegada.split("/")[2]), Number(expedicion.FechaLlegada.split("/")[1]) - 1, Number(expedicion.FechaLlegada.split("/")[0])+1 , 18, 0);
    return expedicion.FechaLlegada != null && ahora.getTime() > fechaRecogidaLimite.getTime();
  }

  comprobarCambiarEstadoConductor(expedicion: Expedition){
    return (expedicion.IdConductor != undefined && expedicion.IdConductor != null && expedicion.IdConductor != "");
  }

  comprobarCambiarEstadoVehiculo(expedicion: Expedition){
    return (expedicion.Matricula != "" && expedicion.Matricula != undefined && expedicion.Matricula != null);
  }

  openToast(template: TemplateRef<any>, templateString?: string, expedicion?: Expedition) {
    var isCambiarEstadoAndOkConductor = true, isCambiarEstadoAndOkVehiculo = true, classModal = "modal-xl";
    if(templateString == "templateCambiarEstado"){
      isCambiarEstadoAndOkConductor = this.comprobarCambiarEstadoConductor(expedicion);
      isCambiarEstadoAndOkVehiculo = this.comprobarCambiarEstadoVehiculo(expedicion);
      classModal = "modal-lg";
    }
    if(isCambiarEstadoAndOkConductor && isCambiarEstadoAndOkVehiculo){
      const config: ModalOptions = {
        id: 1,
        backdrop: 'static', 
        keyboard: false,
        animated: true,
        ignoreBackdropClick: true,
        class: classModal,
        initialState: {
          id: 1
        } as Partial<Object>
      };
      
      this.modalRef = this.modalService.show(template, config);
    }else{
      if(!isCambiarEstadoAndOkConductor && !isCambiarEstadoAndOkVehiculo){
        var body = this.translateService.instant("BodyErrorSinAsignarVehiculoYConductor");
        var title = this.translateService.instant("TitleErrorSinAsignarVehiculoYConductor");
        this.toastr.error(body, title);
      }else if(!isCambiarEstadoAndOkConductor && isCambiarEstadoAndOkVehiculo){
        var body = this.translateService.instant("BodyErrorSinAsignarConductor");
        var title = this.translateService.instant("TitleErrorSinAsignarConductor");
        this.toastr.error(body, title);
      }else if(isCambiarEstadoAndOkConductor && !isCambiarEstadoAndOkVehiculo){
        var body = this.translateService.instant("BodyErrorSinAsignarVehiculo");
        var title = this.translateService.instant("TitleErrorSinAsignarVehiculo");
        this.toastr.error(body, title);
      }
    }
  }

  openToastVerificacion(event){
    if(event[0]){
      this.toastr.success(event[1], this.translateService.instant("Subido correctamente"));
      this.modalRef.hide();
      this.inicializar();
    }else{
      this.toastr.error(event[1], this.translateService.instant("Subido erróneamente"));
      this.modalRef.hide();
      this.inicializar();
    }
  }

}
