<!-- TABLA CONTENEDORES -->
<div
  class="table-responsive-sm table-wrapper-scroll-y my-custom-scrollbar"
  *ngIf="fcl"
>
  <!--table-bordered-->
  <table
    class="table table-sm-table-hover table-editable table-row-action edit-action table-borderless sinBorde"
  >
    <thead class="sinBorde">
      <th class="text-center w5 border" style="vertical-align: middle">
        <button
          id="AnyadirLineaContenedor"
          type="button"
          class="btn"
          (click)="addEmptyLineFCL()"
          [disabled]="visor || isCotizacion"
        >
          <img
            src="assets/iconos/plus_48px.png"
            style="width: 16px; height: 16px"
            alt=""
          />
        </button>
      </th>
      <!-- <th class="text-center" style="vertical-align: middle;" scope="col">{{'Contenedores' | translate }}</th> -->
      <th class="text-center textMiddle w20 border" scope="col">
        {{ "Tipo Contenedor" | translate }}*
      </th>
      <!-- <th class="text-center" style="vertical-align: middle;" scope="col">{{'DescripcionMercancia' | translate}}</th>
            <th class="text-center" style="vertical-align: middle;" scope="col">{{'Peso Bruto' | translate }}</th>
            <th class="text-center" style="vertical-align: middle;" scope="col">{{'TipoMercancia' | translate}}</th>
            <th class="text-center" style="vertical-align: middle;" scope="col" *ngIf="hayMercanciaPeligrosaContenedor()">N/A UN<sup><span mat-raised-button matTooltip="{{'infoNAUN' | translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup></th> -->
      <th class="text-center textMiddle border" scope="col">
        {{ "Opc" | translate }}.
      </th>
      <th class="w65 sinBordeTopRight"></th>
    </thead>
    <tbody
      class="borderTop2px border2px"
      *ngFor="let m of listaCargoContenedor; let i = index"
    >
      <tr>
        <td>
          {{ i + 1 }}
        </td>
        <!-- <td class="text-center">
                    <input class="form-control input-custom-bultos" id="Bultos-Contenedor{{i}}" type="text" name="Bultos-Contenedor{{i}}" min="1" (focusout)="recalculoContenedor(i)" autocomplete="off"
                    (change)="getBultosContenedor(numBultosContenedor.value, i)" style="margin:auto;" #numBultosContenedor [ngModel]="bultosContenedor[i]" [readonly]="isCotizacion">
                </td> -->
        <td class="text-center">
          <ng-select
            name="Contenedor{{ i }}"
            id="Contenedor{{ i }}"
            class="custom"
            #Contenedor="ngModel"
            appendTo="body"
            [items]="listContenedor"
            bindLabel="nombre"
            bindValue="codigo"
            (change)="setContenedor($event, i)"
            groupBy="tipo"
            [ngModel]="contenedor[i]"
            style="margin: auto"
            [readonly]="visor || isCotizacion"
            (focus)="selectedContenedorIndex = i"
          >
            <ng-template ng-optgroup-tmp let-item="item" class="changeColor">
              <div class="changeColor">
                {{ item.tipo }}
              </div>
            </ng-template>
          </ng-select>
        </td>
        <td *ngIf="visor || isCotizacion">
          <!-- <img src="assets/iconos/trash_48px.png" style="width: 30px; height: 30px; cursor: pointer; padding: 5px;" alt=""> -->
        </td>
        <td *ngIf="!visor && !isCotizacion">
          <img
            src="assets/iconos/trash_48px.png"
            style="width: 30px; height: 30px; cursor: pointer; padding: 5px"
            alt=""
            (click)="
              openAlert(templateDeleteContenedor, i); recalculoContenedor(i)
            "
          />
        </td>
        <td class="sinBordeTopRight"></td>
      </tr>
      <!-- TABLE MERCANCIA CONTENEDOR -->
      <tr>
        <td><mat-icon class="mb100">subdirectory_arrow_right</mat-icon></td>
        <td colspan="3">
          <div
            class="table-responsive-sm table-wrapper-scroll-y my-custom-scrollbar"
          >
            <table
              id="mercanciaCargo"
              class="table table-sm table-hover table-editable table-row-action edit-action table-bordered"
              style="width: 99.9%"
            >
              <thead>
                <tr></tr>
                <th class="text-center w100px" style="vertical-align: middle">
                  <button
                    id="AnyadirLinea"
                    type="button"
                    class="btn"
                    (click)="
                      selectedContenedorIndex = i; addEmptyLineMercanciaCargo()
                    "
                    [disabled]="visor || isCotizacion"
                  >
                    <img
                      src="assets/iconos/plus_48px.png"
                      style="width: 16px; height: 16px"
                      alt=""
                    />
                  </button>
                </th>
                <!--  <th *ngIf="fcl" class="text-center w100px" style="vertical-align: middle;" scope="col">
                                    {{'Bultos' | translate}}</th>
                                <th *ngIf="!fcl" class="text-center w100px" style="vertical-align: middle;" scope="col">
                                    {{'Bultos' | translate}}*</th>
                                <th class="text-center w240px" scope="col">
                                    <div class="row w200px ml1" *ngIf="!fcl">
                                        <div class="col-sm-4 pl5px pr15px">{{'Largo'|translate}}*<sup><span
                                                    mat-raised-button matTooltip="{{'unidadesMedidas' | translate}}"
                                                    matTooltipClass="my-custom-tooltip"><img
                                                        src="assets/iconos/info_64.png" alt=""
                                                        style="width: 12px;height: 12px;"></span></sup></div>
                                        <div class="col-sm-4 pl15px pr5px">{{'Ancho'|translate}}*<sup><span
                                                    mat-raised-button matTooltip="{{'unidadesMedidas' | translate}}"
                                                    matTooltipClass="my-custom-tooltip"><img
                                                        src="assets/iconos/info_64.png" alt=""
                                                        style="width: 12px;height: 12px;"></span></sup></div>
                                        <div class="col-sm-4 pl35px pr5px">{{'Alto'|translate}}*<sup><span
                                                    mat-raised-button matTooltip="{{'unidadesMedidas' | translate}}"
                                                    matTooltipClass="my-custom-tooltip"><img
                                                        src="assets/iconos/info_64.png" alt=""
                                                        style="width: 12px;height: 12px;"></span></sup></div>
                                    </div>
                                    <div class="row w100 pl60px mb2" *ngIf="fcl">
                                        <div class="col-sm-4 ">{{'Largo'|translate}}<sup><span mat-raised-button
                                                    matTooltip="{{'unidadesMedidas' | translate}}"
                                                    matTooltipClass="my-custom-tooltip"><img
                                                        src="assets/iconos/info_64.png" alt=""
                                                        style="width: 12px;height: 12px;"></span></sup></div>
                                        <div class="col-sm-4 ">{{'Ancho'|translate}}<sup><span mat-raised-button
                                                    matTooltip="{{'unidadesMedidas' | translate}}"
                                                    matTooltipClass="my-custom-tooltip"><img
                                                        src="assets/iconos/info_64.png" alt=""
                                                        style="width: 12px;height: 12px;"></span></sup></div>
                                        <div class="col-sm-4 ">{{'Alto'|translate}}<sup><span mat-raised-button
                                                    matTooltip="{{'unidadesMedidas' | translate}}"
                                                    matTooltipClass="my-custom-tooltip"><img
                                                        src="assets/iconos/info_64.png" alt=""
                                                        style="width: 12px;height: 12px;"></span></sup></div>
                                    </div>
                                </th>-->
                <th
                  *ngIf="!tarifa"
                  class="text-center w150px"
                  style="vertical-align: middle"
                  scope="col"
                >
                  {{ "Bruto" | translate }}(Kg)*
                </th>
                <th
                  *ngIf="tarifa && fcl"
                  class="text-center w150px"
                  style="vertical-align: middle"
                  scope="col"
                >
                  {{ "Bruto" | translate }}(Kg)*
                </th>
                <th
                  class="text-center w150px"
                  style="vertical-align: middle"
                  scope="col"
                >
                  {{ "RequerimientosEspeciales" | translate }}
                </th>
                <th
                  *ngIf="tarifa && !fcl"
                  class="text-center w150px"
                  style="vertical-align: middle"
                  scope="col"
                >
                  {{ "Volumen" | translate }}(m<sup>3</sup>)*
                </th>
                <!--     <th *ngIf="tarifa && fcl" class="text-center w150px" style="vertical-align: middle;"
                                    scope="col">{{'Volumen' | translate}}(m<sup>3</sup>)</th>
                                <th *ngIf="!tarifa" class="text-center w150px" style="vertical-align: middle;"
                                    scope="col">{{'Volumen' | translate}}(m<sup>3</sup>)</th>-->
                <th
                  *ngIf="hayMercanciaPeligrosaMercanciaContenedor(i)"
                  class="text-center w40px"
                  style="vertical-align: middle"
                  scope="col"
                >
                  UN<sup
                    ><span
                      mat-raised-button
                      matTooltip="{{ 'infoNAUN' | translate }}"
                      matTooltipClass="my-custom-tooltip"
                      ><img
                        src="assets/iconos/info_64.png"
                        alt=""
                        style="width: 12px; height: 12px" /></span
                  ></sup>
                </th>
                <th
                  class="text-center w100px"
                  style="vertical-align: middle"
                  scope="col"
                >
                  {{ "Opc" | translate }}.
                </th>
              </thead>
              <tbody
                *ngIf="
                  listaCargoMercanciaContenedor[i] != undefined &&
                  listaCargoMercanciaContenedor[i] != null &&
                  listaCargoMercanciaContenedor[i].length > 0
                "
              >
                <tr
                  *ngFor="
                    let m of listaCargoMercanciaContenedor[i];
                    let j = index
                  "
                  (click)="selectedContenedorIndexClick(i)"
                >
                  <td>{{ j + 1 }}</td>
                  <!--  <td class="text-center">
                                        <input
                                            [ngClass]="{'form-control-custom-bultos-error': errorBultosMercanciaContenedor[i][j], 'form-control input-custom-bultos': !errorBultosMercanciaContenedor[i][j]}"
                                            id="Bultos{{j}}" type="text" name="Bultos{{j}}"
                                            (focusout)="recalculoMercanciaContenedor(j)" autocomplete="off"
                                            (change)="getBultosMercanciaContenedor(numBultos.value, j)"
                                            style="margin:auto;" #numBultos [ngModel]="bultosMercanciaContenedor[i][j]"
                                            [readonly]="visor || isCotizacion">
                                    </td>
                                    <td class="text-center w300px">
                                        <div class="row w100 pl50px">
                                            <div class="col-4 pl3px pr5px">
                                                <input type="text"
                                                    [ngClass]="{'form-control-custom-medidas-error': errorLargoMercanciaContenedor[i][j], 'form-control input-custom-medidas': !errorLargoMercanciaContenedor[i][j]}"
                                                    id="Largo{{j}}" type="text" name="Largo{{j}}"
                                                    (focusout)="recalculoMercanciaContenedor(j)" autocomplete="off"
                                                    (change)="getLargoMercanciaContenedor(Largo.value, j)" #Largo
                                                    [ngModel]="largoMercanciaContenedor[i][j]" style="margin: auto;"
                                                    [readonly]="visor || isCotizacion" min="0">
                                            </div>
                                            <div class="col-4 pl3px pr5px">
                                                <input type="text"
                                                    [ngClass]="{'form-control-custom-medidas-error': errorAnchoMercanciaContenedor[i][j], 'form-control input-custom-medidas': !errorAnchoMercanciaContenedor[i][j]}"
                                                    id="Ancho{{j}}" type="text" name="Ancho{{j}}"
                                                    (focusout)="recalculoMercanciaContenedor(j)" autocomplete="off"
                                                    (change)="getAnchoMercanciaContenedor(Ancho.value, j)" #Ancho
                                                    [ngModel]="anchoMercanciaContenedor[i][j]" style="margin: auto;"
                                                    [readonly]="visor || isCotizacion" min="0">
                                            </div>
                                            <div class="col-4 pl3px">
                                                <input type="text"
                                                    [ngClass]="{'form-control-custom-medidas-error': errorAltoMercanciaContenedor[i][j], 'form-control input-custom-medidas': !errorAltoMercanciaContenedor[i][j]}"
                                                    id="Alto{{j}}" type="text" name="Alto{{j}}"
                                                    (focusout)="recalculoMercanciaContenedor(j)" autocomplete="off"
                                                    (change)="getAltoMercanciaContenedor(Alto.value, j)" #Alto
                                                    [ngModel]="altoMercanciaContenedor[i][j]" style="margin: auto;"
                                                    [readonly]="visor || isCotizacion" min="0">
                                            </div>
                                        </div>
                                    </td>-->
                  <td class="text-center">
                    <input
                      type="number"
                      [ngClass]="{
                        'form-control-custom-pesos-error':
                          errorBrutoMercanciaContenedor[i][j],
                        'form-control input-custom-pesos text-center':
                          !errorBrutoMercanciaContenedor[i][j]
                      }"
                      style="margin: auto"
                      id="PesoBruto{{ j }}"
                      type="text"
                      name="PesoBruto{{ j }}"
                      (change)="validarPesoBruto(PesoBruto.value, i, j)"
                      (focusout)="recalculoMercanciaContenedor(j)"
                      min="0"
                      (change)="
                        getPesoBrutoMercanciaContenedor(PesoBruto.value, j)
                      "
                      #PesoBruto
                      [ngModel]="pesoBrutoMercanciaContenedor[i][j]"
                      [readonly]="visor || isCotizacion"
                    />
                  </td>
                  <td class="text-center pl10coma7" style="width: 100px">
                    <ng-select
                      id="Clasificacion{{ j }}"
                      name="Clasificacion{{ j }}"
                      [items]="clasificacion"
                      multiple="true"
                      bindLabel="name"
                      bindValue="value"
                      [readonly]="visor || isCotizacion"
                      appendTo="body"
                      [ngModel]="clasMercanciaContenedor[i][j]"
                      (clear)="clearClasificacionMercanciaContenedor(j)"
                      style="width: 200px"
                      (remove)="
                        changeClasificacionMercanciaContenedor($event, j, i)
                      "
                      (add)="
                        changeClasificacionMercanciaContenedor($event, j, i)
                      "
                      [closeOnSelect]="false"
                      [clearSearchOnAdd]="true"
                      #Clasificacion
                    >
                    </ng-select>
                  </td>
                  <td *ngIf="!tarifa" class="text-center">
                    <input
                      class="form-control input-custom-volumen"
                      id="Volumen{{ j }}"
                      type="number"
                      name="Volumen{{ j }}"
                      autocomplete="off"
                      min="0"
                      style="margin: auto"
                      #Volumen
                      [(ngModel)]="volumenMercanciaContenedor[i][j]"
                      (focusout)="
                        setVolumenMercanciaContenedor(
                          volumenMercanciaContenedor[i][j],
                          j
                        );
                        recalculoMercanciaContenedor(j)
                      "
                      [readonly]="
                        (clientePermitido() && !isCotizacion) || isCotizacion
                      "
                    />
                  </td>
                  <!--  <td *ngIf="tarifa" class="text-center">
                                        <input class="form-control input-custom-volumen" id="Volumen{{j}}" type="number"
                                            name="Volumen{{j}}" autocomplete="off" min="0" style="margin: auto;"
                                            #Volumen [(ngModel)]="volumenMercanciaContenedor[i][j]"
                                            (focusout)="setVolumenMercanciaContenedor(volumenMercanciaContenedor[i][j],j);recalculoMercanciaContenedor(j)">
                                    </td>-->
                  <td
                    class="text-center pl3"
                    *ngIf="peligrosaMercanciaContenedor[i][j]"
                  >
                    <div class="row w200">
                      <div class="col-12 col-xl-4">
                        <button
                          *ngIf="getMPMercanciaContenedor(i, j)"
                          style="margin: auto"
                          type="button"
                          class="btn"
                          (click)="
                            openModal(templateNestedMercanciaContenedor);
                            getIndex(j)
                          "
                        >
                          <img
                            *ngIf="!(visor || isCotizacion)"
                            src="assets/iconos/plus_48px.png"
                            style="width: 16px; height: 16px"
                            alt=""
                          />
                          <mat-icon
                            *ngIf="visor || isCotizacion"
                            style="margin-top: 10px"
                            >visibility</mat-icon
                          >
                        </button>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="!visor && !isCotizacion">
                    <img
                      src="assets/iconos/trash_48px.png"
                      style="
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        padding: 5px;
                      "
                      alt=""
                      (click)="
                        openAlert(templateDeleteMercanciaContenedor, j);
                        recalculoMercanciaContenedor(j)
                      "
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot *ngIf="tieneMercancia(i)" class="border1px">
                <!-- <th class="text-center" style="vertical-align: middle;"></th>
                                <th class="text-center" style="vertical-align: middle;" scope="col">
                                    {{getBultosTotalesMercanciaContenedor(i)}}</th>-->
                <th class="text-center" style="vertical-align: middle"></th>
                <th
                  class="text-center"
                  style="vertical-align: middle"
                  scope="col"
                >
                  {{ getBrutoTotalesMercanciaContenedor(i) }}(Kg)
                </th>
                <th
                  class="text-center"
                  style="vertical-align: middle"
                  scope="col"
                ></th>
                <th
                  class="text-center"
                  style="vertical-align: middle"
                  scope="col"
                ></th>
                <!--  <th class="text-center" style="vertical-align: middle;" scope="col">
                                    {{getVolumenTotalesMercanciaContenedor(i)}}(m<sup>3</sup>)</th>-->
              </tfoot>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- TABLA MERCANCÍA -->
<div
  *ngIf="!fcl"
  class="table-responsive-sm table-wrapper-scroll-y my-custom-scrollbar"
>
  <table
    id="mercanciaCargo"
    class="table table-sm table-hover table-editable table-row-action edit-action table-bordered"
    style="width: 99.9%"
  >
    <thead>
      <tr></tr>
      <th class="text-center w100px" style="vertical-align: middle">
        <button
          id="AnyadirLinea"
          type="button"
          class="btn"
          (click)="addEmptyLine()"
          [disabled]="visor || isCotizacion"
        >
          <img
            src="assets/iconos/plus_48px.png"
            style="width: 16px; height: 16px"
            alt=""
          />
        </button>
      </th>
      <th class="text-center w100px" style="vertical-align: middle" scope="col">
        {{ "Bultos" | translate }}*
      </th>
      <th class="text-center w240px" scope="col">
        <div class="row w100 pl35px mb2">
          <div class="col-sm-4 pr15px">
            {{ "Largo" | translate }}*<sup
              ><span
                mat-raised-button
                matTooltip="{{ 'unidadesMedidas' | translate }}"
                matTooltipClass="my-custom-tooltip"
                ><img
                  src="assets/iconos/info_64.png"
                  alt=""
                  style="width: 12px; height: 12px" /></span
            ></sup>
          </div>
          <div class="col-sm-4 pl50px pr15px">
            {{ "Ancho" | translate }}*<sup
              ><span
                mat-raised-button
                matTooltip="{{ 'unidadesMedidas' | translate }}"
                matTooltipClass="my-custom-tooltip"
                ><img
                  src="assets/iconos/info_64.png"
                  alt=""
                  style="width: 12px; height: 12px" /></span
            ></sup>
          </div>
          <div class="col-sm-4 pl35px">
            {{ "Alto" | translate }}*<sup
              ><span
                mat-raised-button
                matTooltip="{{ 'unidadesMedidas' | translate }}"
                matTooltipClass="my-custom-tooltip"
                ><img
                  src="assets/iconos/info_64.png"
                  alt=""
                  style="width: 12px; height: 12px" /></span
            ></sup>
          </div>
        </div>
      </th>
      <th
        *ngIf="!tarifa"
        class="text-center w100px"
        style="vertical-align: middle"
        scope="col"
      >
        {{ "Bruto" | translate }}(Kg)
      </th>
      <th
        *ngIf="tarifa"
        class="text-center w100px"
        style="vertical-align: middle"
        scope="col"
      >
        {{ "Bruto" | translate }}(Kg)*
      </th>
      <th class="text-center w100px" style="vertical-align: middle" scope="col">
        {{ "RequerimientosEspeciales" | translate }}
      </th>
      <th class="text-center w100px" style="vertical-align: middle" scope="col">
        {{ "Volumen" | translate }}(m<sup>3</sup>)*
      </th>
      <th
        *ngIf="hayMercanciaPeligrosa()"
        class="text-center w40px"
        style="vertical-align: middle"
        scope="col"
      >
        UN<sup
          ><span
            mat-raised-button
            matTooltip="{{ 'infoNAUN' | translate }}"
            matTooltipClass="my-custom-tooltip"
            ><img
              src="assets/iconos/info_64.png"
              alt=""
              style="width: 12px; height: 12px" /></span
        ></sup>
      </th>
      <th class="text-center w100px" style="vertical-align: middle" scope="col">
        {{ "Opc" | translate }}.
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let m of listaCargo; let i = index">
        <td>{{ i + 1 }}</td>
        <td class="text-center">
          <input
            [ngClass]="{
              'form-control-custom-bultos-error': errorBultos[i],
              'form-control input-custom-bultos': !errorBultos[i]
            }"
            id="Bultos{{ i }}"
            type="text"
            name="Bultos{{ i }}"
            (focusout)="recalculo(i)"
            autocomplete="off"
            (change)="getBultos(numBultos.value, i)"
            style="margin: auto"
            #numBultos
            [ngModel]="bultos[i]"
            [readonly]="visor || isCotizacion"
          />
        </td>
        <td class="text-center w100px">
          <div class="row w100 ml1">
            <div class="col-4 pl5px">
              <input
                type="text"
                [ngClass]="{
                  'form-control-custom-medidas-error': errorLargo[i],
                  'form-control input-custom-medidas': !errorLargo[i]
                }"
                id="Largo{{ i }}"
                type="text"
                name="Largo{{ i }}"
                (focusout)="recalculo(i)"
                autocomplete="off"
                (change)="getLargo(Largo.value, i)"
                #Largo
                [ngModel]="largo[i]"
                style="margin: auto"
                [readonly]="visor || isCotizacion"
                min="0"
              />
            </div>
            <div class="col-4 pl5px pr5px">
              <input
                type="text"
                [ngClass]="{
                  'form-control-custom-medidas-error': errorAncho[i],
                  'form-control input-custom-medidas': !errorAncho[i]
                }"
                id="Ancho{{ i }}"
                type="text"
                name="Ancho{{ i }}"
                (focusout)="recalculo(i)"
                autocomplete="off"
                (change)="getAncho(Ancho.value, i)"
                #Ancho
                [ngModel]="ancho[i]"
                style="margin: auto"
                [readonly]="visor || isCotizacion"
                min="0"
              />
            </div>
            <div class="col-4 pl5px pr5px">
              <input
                type="text"
                [ngClass]="{
                  'form-control-custom-medidas-error': errorAlto[i],
                  'form-control input-custom-medidas': !errorAlto[i]
                }"
                id="Alto{{ i }}"
                type="text"
                name="Alto{{ i }}"
                (focusout)="recalculo(i)"
                autocomplete="off"
                (change)="getAlto(Alto.value, i)"
                #Alto
                [ngModel]="alto[i]"
                style="margin: auto"
                [readonly]="visor || isCotizacion"
                min="0"
              />
            </div>
          </div>
        </td>
        <td class="text-center">
          <input
            [ngClass]="{
              'form-control-custom-pesos-error': errorBruto[i],
              'form-control input-custom-pesos text-center': !errorBruto[i]
            }"
            style="margin: auto"
            id="PesoBruto{{ i }}"
            type="text"
            name="PesoBruto{{ i }}"
            (focusout)="recalculo(i)"
            min="0"
            (change)="getPesoBruto(PesoBruto.value, i)"
            #PesoBruto
            [ngModel]="pesoBruto[i]"
            [readonly]="visor || isCotizacion"
          />
        </td>
        <td class="text-center pl5 w10px">
          <ng-select
            class="w200px"
            id="Clasificacion{{ i }}"
            name="Clasificacion{{ i }}"
            [items]="clasificacion"
            bindLabel="name"
            bindValue="value"
            multiple="true"
            [readonly]="visor || isCotizacion"
            appendTo="body"
            [ngModel]="clas[i]"
            (clear)="clearClasificacion(i)"
            (remove)="changeClasificacion($event, i)"
            (add)="changeClasificacion($event, i)"
            [closeOnSelect]="false"
            [clearSearchOnAdd]="true"
            #Clasificacion
          >
          </ng-select>
        </td>
        <td *ngIf="tarifa" class="text-center">
          <input
            class="form-control input-custom-volumen"
            id="Volumen{{ i }}"
            type="number"
            name="Volumen{{ i }}"
            autocomplete="off"
            min="0"
            style="margin: auto"
            #Volumen
            [(ngModel)]="volumen[i]"
            (focusout)="setVolumen(Volumen, i); recalculo(i)"
          />
        </td>
        <td *ngIf="!tarifa" class="text-center">
          <input
            class="form-control input-custom-volumen"
            id="Volumen{{ i }}"
            type="number"
            name="Volumen{{ i }}"
            autocomplete="off"
            min="0"
            style="margin: auto"
            #Volumen
            [(ngModel)]="volumen[i]"
            (focusout)="setVolumen(Volumen, i); recalculo(i)"
            [readonly]="clientePermitido() || isCotizacion"
          />
        </td>
        <td class="text-center pl1" *ngIf="peligrosa[i]">
          <div class="row">
            <div class="col-12 col-xl-4">
              <button
                *ngIf="getMP(i)"
                type="button"
                class="btn"
                (click)="openModal(templateNested); getIndex(i)"
              >
                <img
                  *ngIf="!(visor || isCotizacion)"
                  src="assets/iconos/plus_48px.png"
                  style="width: 16px; height: 16px"
                  alt=""
                />
                <mat-icon *ngIf="visor || isCotizacion" style="margin-top: 10px"
                  >visibility</mat-icon
                >
              </button>
            </div>
          </div>
        </td>
        <td *ngIf="!visor && !isCotizacion">
          <img
            src="assets/iconos/trash_48px.png"
            style="width: 30px; height: 30px; cursor: pointer; padding: 5px"
            alt=""
            (click)="openAlert(templateDelete, i); recalculo(i)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #templateNested id="MercanciaPeligrosa">
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Mercancia Peligrosa" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-mercancia-peligrosa
        [isCotizacion]="isCotizacion"
        [area]="area"
        (emitMP)="obtenerListaMP($event)"
        (emitPNTotal)="obtenerPesoNetoT($event)"
        (emitPBTotal)="obtenerPesoBrutoT($event)"
        [mp]="listaCargo[indiceEdit].MercanciaPeligrosa"
        [pesoNeto]="listaCargo[this.indiceEdit].PesoNeto"
        [tarifa]="true"
        [pesoBruto]="listaCargo[this.indiceEdit].PesoBruto"
        [visor]="visor || isCotizacion"
        [codigosUn]="codigosUn"
      ></app-mercancia-peligrosa>
    </div>
  </div>
</ng-template>

<ng-template #templateNestedMercanciaContenedor id="MercanciaPeligrosa">
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Mercancia Peligrosa" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-mercancia-peligrosa
        [isCotizacion]="isCotizacion"
        [area]="area"
        (emitMP)="obtenerListaMPMercanciaContenedor($event)"
        (emitPNTotal)="obtenerPesoNetoTMercanciaContenedor($event)"
        (emitPBTotal)="obtenerPesoBrutoTMercanciaContenedor($event)"
        [mp]="
          listaCargoContenedor[selectedContenedorIndex].Mercancia[indiceEdit]
            .MercanciaPeligrosa
        "
        [pesoNeto]="
          listaCargoContenedor[selectedContenedorIndex].Mercancia[indiceEdit]
            .PesoNeto
        "
        [pesoBruto]="
          listaCargoContenedor[selectedContenedorIndex].Mercancia[indiceEdit]
            .PesoBruto
        "
        [visor]="visor || isCotizacion"
        [codigosUn]="codigosUn"
        [tarifa]="true"
      ></app-mercancia-peligrosa>
    </div>
  </div>
</ng-template>

<ng-template #templateDelete>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Confirmación de borrado" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-confirmacion-borrado
        (confirmEmit)="obtenerConfirmacion($event)"
      ></app-confirmacion-borrado>
    </div>
  </div>
</ng-template>

<ng-template #templateDeleteContenedor>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Confirmación de borrado" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-confirmacion-borrado
        (confirmEmit)="obtenerConfirmacionContenedor($event)"
      ></app-confirmacion-borrado>
    </div>
  </div>
</ng-template>

<ng-template #templateDeleteMercanciaContenedor>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Confirmación de borrado" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-confirmacion-borrado
        (confirmEmit)="obtenerConfirmacionMercanciaContenedor($event)"
      ></app-confirmacion-borrado>
    </div>
  </div>
</ng-template>

<ng-template #templateGrados>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{ "Grados" | translate }}*</h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-grados
        (close)="cerrar($event)"
        (emitGradosMin)="obtenerGradosMin($event)"
        (emitGradosMax)="obtenerGradosMax($event)"
        [gradosMax]="gradosMax[indiceEdit]"
        [gradosMin]="gradosMin[indiceEdit]"
        [visor]="visor || isCotizacion"
      ></app-grados>
    </div>
  </div>
</ng-template>

<ng-template #templateGradosMercanciaContenedor>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{ "Grados" | translate }}*</h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-grados
        (close)="cerrar($event)"
        (emitGradosMin)="obtenerGradosMinMercanciaContenedor($event)"
        (emitGradosMax)="obtenerGradosMaxMercanciaContenedor($event)"
        [gradosMax]="
          gradosMaxMercanciaContenedor[selectedContenedorIndex][indiceEdit]
        "
        [gradosMin]="
          gradosMinMercanciaContenedor[selectedContenedorIndex][indiceEdit]
        "
        [visor]="visor || isCotizacion"
      ></app-grados>
    </div>
  </div>
</ng-template>
