import { Component, Input, OnInit } from '@angular/core';
import { ContenedorMercancia } from 'src/app/models/contenedorMercancia.model';

@Component({
  selector: 'app-detalles-contenedor',
  templateUrl: './detalles-contenedor.component.html',
  styleUrls: ['./detalles-contenedor.component.scss']
})
export class DetallesContenedorComponent implements OnInit {

  @Input() contenedoresCotizacion: ContenedorMercancia[];
  totalBultos: number = 0;
  totalPesoBruto: number = 0;
  contenedorSeleccionado: boolean[] = [];

  constructor() { }

  ngOnInit(): void {
    this.contenedoresCotizacion.forEach(cc => {
      this.contenedorSeleccionado.push(false);
      // this.totalBultos += cc.Bultos;
      // this.totalPesoBruto += cc.PesoBruto;
    });
  }

  // existeMercanciaPeligrosa(){
  //   var existe = false;
  //   this.contenedoresCotizacion.forEach(cc => {
  //     if(this.hayMercanciaPeligrosa(cc))
  //       existe = true;
  //   });
  //   return existe;
  // }

  existeMercancia(){
    var existe = false;
    this.contenedoresCotizacion.forEach(cc => {
      if(this.hayMercancia(cc))
        existe = true;
    });
    return existe;
  }

  // hayMercanciaPeligrosa(contenedor)
  // {
  //   return contenedor.MercanciaPeligrosa != null && contenedor.MercanciaPeligrosa != undefined && contenedor.MercanciaPeligrosa.length > 0;
  // }

  estaSeleccionada(index: number){
    return this.contenedorSeleccionado[index] == true;
  }

  hayMercancia(contenedor: ContenedorMercancia){
    return contenedor.Mercancia != null && contenedor.Mercancia != undefined && contenedor.Mercancia.length > 0;
  }

  marcarContenedor(index: number){
    if(this.contenedorSeleccionado[index])
      this.contenedorSeleccionado[index] = false;
    else
      this.contenedorSeleccionado[index] = true;
  }

}
