import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Entity } from '../models/entity.model';
import { TipoIdentificacion } from '../models/tipo-identificacion.model';
import { LogModel } from '../models/log-model.model';
import { ConfigEntidad } from '../models/config-entidad.model';
import { Observable, Subject } from 'rxjs';
import { LogService } from './log.service';
import { Corresponsal } from '../models/corresponsal.model';
import { Direccion } from '../models/direccion.model';
import { Archivo } from '../models/archivo.model';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  readonly apiUrlService = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  })
  public entity$: Subject<Entity> = new Subject();
  public archivosEntity$: Subject<Archivo[]> = new Subject();
  ip: string = ""; 
  localizacion: string = "";

  constructor(private http: HttpClient, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
   }

   getEntity$(){
     return this.entity$.asObservable();
   }

   getArchivosEntity$(){
     return this.archivosEntity$.asObservable();
   }

   setEntity$(ent: Entity){
     this.entity$.next(ent);
   }

  //Peticion en la que trata de insertar una entidad nueva
  postEntidad(Entity, log: LogModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "em_entidad";
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(Entity).toString()}), {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  cargarEntidadDelegacion(){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "em_entidad/empresa";
    return this.http.get<Entity>(url_api, {headers: headersToken}).pipe(map(data => data as Entity));
  }

  postArchivosEntidad(archivos, log: LogModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    log.Ip = this.ip;
    log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "em_entidad/archivos";
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(archivos).toString()}), {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  getDireccionById(idDireccion: number){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "em_direcciones/" + idDireccion;
    this.logService.postLogCompleto("GET", "em_direccionesController.cs", "Log de la obtención de la dirección " + idDireccion + ".", "Get_em_direccionesByIdDireccion", "em_direcciones", url_api);
    return this.http.get<Direccion>(url_api, {headers: headersToken}).pipe(map(data => data as Direccion));
  }
  
  getEntidad() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "em_entidad";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log de la obtención de la entidad.", "Get_em_entidad", "em_entidad", url_api);
    return this.http.get<Entity[]>(url_api, {headers: headersToken}).pipe(map(data => data as Entity[]));
  }

  getArchivosEntidad() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "em_entidad/archivos"
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log de la obtención de la entidad.", "Get_archivosEntidad", "empresa.archivosentidad", url_api);
    return this.http.get<Archivo[]>(url_api, {headers: headersToken}).pipe(map(data => data as Archivo[])).subscribe(data => this.archivosEntity$.next(data));
  }

  //Peticion en la que trata de insertar una entidad nueva
  postDireccion(Direccion, log: LogModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "em_direcciones";
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(Direccion).toString()}), {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  getTipoIdentificacion() {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "cfg_tipoidentificacion"
    this.logService.postLogCompleto("GET", "cfg_tipoidentificacionController.cs", "Log de la obtención de la tipo identficación.", "Getcfg_tipoidentificacion", "cfg_tipoidentificacion", url_api);
    return this.http.get<TipoIdentificacion[]>(url_api, {headers: headersToken});
  }

  //Obtenemos el token de la variable local
  getToken()
  {
    return localStorage.getItem("userToken");
  }

  getConfigEntidad(): Observable <ConfigEntidad>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "em_entidad/config";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log de la obtención de la configuración de la entidad.", "GetConfigEntidadByToken", "em_entidad", url_api);
    return this.http.get<ConfigEntidad>(url_api, {headers: headersToken}).pipe(map(data => data));
  }

  getCorresponsal(): Observable<Corresponsal>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "em_entidad/corresponsal";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log de la obtención de la configuración de la entidad.", "GetCorresponsalByToken", "em_entidad", url_api);
    return this.http.get<Corresponsal>(url_api, {headers: headersToken}).pipe(map(data => data));
  }

  getEntidadById(idEntidad: number): Observable<Entity>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "em_entidad/" + idEntidad;
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log de la obtención de la entidad " + idEntidad + ".", "Get_em_entidadPorId", "em_entidad", url_api);
    return this.http.get<Entity>(url_api, {headers: headersToken}).pipe(map(data => data));
  }

  getDireccionEntidad(): Observable<Direccion>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "em_entidad/direccion";
    this.logService.postLogCompleto("GET", "em_entidadController.cs", "Log de la obtención de la dirección del usuario logueado.", "Get_DireccionEntidad", "em_entidad", url_api);
    return this.http.get<Direccion>(url_api, {headers: headersToken}).pipe(map(data => data));
  }
}
