<div class="container">
    
    <app-resumen-envio [resumen]="shipment" [direccionRecogida]="direccionRecogida" [direccionEntrega]="direccionEntrega"></app-resumen-envio>
<!--    
    <div class="row" style="text-align: center;">
        <div class="col-sm-12">
            <p>{{'ReservaDerechosSeguro'|translate}}</p>
        </div>
    </div>
-->
    <div class="row" style="text-align: right;">
        <div class="col-sm-12">
            <button type="button" class="btn" style="margin: 5px;" (click)="confirmacion()">{{'Aceptar'|translate}}</button>
            <button type="button" class="btn" style="margin: 5px;" (click)="cancelacion()">{{'Cancelar'|translate}}</button>
        </div>
    </div>
</div>
