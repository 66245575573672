import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { ContenedorMercancia } from 'src/app/models/contenedorMercancia.model';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { Embalaje } from 'src/app/models/embalaje.model';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';
import { Mercancia } from 'src/app/models/mercancia.model';
import { MercanciaCotizacion } from 'src/app/models/MercanciaCotizacion.model';
import { MercanciaPeligrosaCotizacion } from 'src/app/models/mercanciaPeligrosaCotizacion.model';
import { Shipment } from 'src/app/models/shipment.model';
import { EntityService } from 'src/app/services/entity.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { TipomercanciaService } from 'src/app/services/tipomercancia.service';

@Component({
  selector: 'app-resumen-envio',
  templateUrl: './resumen-envio.component.html',
  styleUrls: ['./resumen-envio.component.scss']
})
export class ResumenEnvioComponent implements OnInit {

  @Input() resumen: Shipment;
  @Input() direccionRecogida: string;
  @Input() direccionEntrega: string;
  listaMercancia: Mercancia[];
  mercanciaDetalles: MercanciaCotizacion[] = [];
  mercanciasContenedorDetalles: ContenedorMercancia[] = [];
  listaEmbalaje: Embalaje[];
  mercancia: Mercancia;
  tipoEnvio: string;
  modoEnvio: String;
  aeropuertoOrigen: String;
  aeropuertoDestino: String;
  puertoOrigen: String;
  puertoDestino: String;
  cliente: String;
  shipper: String;
  direccionShipper: String;
  consignee: String;
  direccionConsignee: String;
  notify: String;
  direccionNotify: String;
  tipoDivisa: String;
  hscodesConcat: String;
  entidadRecogida: String;
  entidadEntrega: String;
  displayedColumns: String[] = ['Bultos', 'TipoEmbalaje','Dimensiones', 'Neto', 'Bruto', 'Volumen']
  displayedColumnsFCL: String[] = ['Bultos', 'TipoEmbalaje', 'NombreContenedor', 'Neto', 'Bruto']
  displayedColumnsMP: String[] =['UN', 'PesoNeto', 'PesoBruto']
  listaMercanciaPeligrosa: MercanciaPeligrosa[];
  tipoMercancia: string;
  corresponsal: Corresponsal;
  
  constructor(private envioService: ShipmentService, private tipomercanciaServices: TipomercanciaService, private entidadService: EntityService) { }

  ngOnInit(): void {
    this.listaMercancia = [];
    this.listaEmbalaje = [];
    this.listaMercanciaPeligrosa = [];
    this.obtenerDatosEstaticos();

  }

  getMercanciaPeligrosaDetalles(mpc: MercanciaPeligrosa[]) : MercanciaPeligrosaCotizacion[]
  {
    var listado: MercanciaPeligrosaCotizacion[] = [];
    if(mpc != undefined && mpc != null)
    {
      mpc.forEach(mp => {
        var mpnew = new MercanciaPeligrosaCotizacion();
        mpnew.Un = mp.Un;
        mpnew.PesoBruto = mp.PesoBruto;
        mpnew.PesoNeto = mp.PesoNeto;
        listado.push(mpnew);
      });
    }
    return listado;
  }

  ngOnChanges(simple: SimpleChange){
    this.listaMercancia = [];
    this.listaEmbalaje = [];
    this.listaMercanciaPeligrosa = [];
    this.obtenerDatosEstaticos();
  }

  esMexicoOPuebla(): boolean {
    if(this.corresponsal == null || this.corresponsal == undefined)
      return true;
    return this.corresponsal != null && this.corresponsal != undefined && (this.corresponsal.IdDelegacion == 2 || this.corresponsal.IdDelegacion == 5);
  }

  obtenerDatosEstaticos(){
    this.mercanciasContenedorDetalles = this.mercanciaDetalles = [];
    this.entidadService.getCorresponsal().subscribe(corresponsal => {
      this.corresponsal = corresponsal;
    });
   this.envioService.getTipoEnvio(this.resumen.IdTipoEnvio).subscribe(data => {
      this.tipoEnvio = data.TipoEnvio;
    },
    error => {
    });
    
    if(this.resumen.IdArea != 3)
      this.envioService.getModoEnvio(this.resumen.IdModoEnvio).subscribe(data => {
        this.modoEnvio = data.ModoEnvio;
      });
    if(this.resumen.IdArea == 3)
    {
      if(this.resumen.IdAeropuertoOrigen != undefined && this.resumen.IdAeropuertoOrigen != null)
        this.envioService.getAeropuertoById(this.resumen.IdAeropuertoOrigen).subscribe(data => {
          this.aeropuertoOrigen = data.Nombre;
        });
      if(this.resumen.IdAeropuertoDestino != undefined && this.resumen.IdAeropuertoDestino != null)
        this.envioService.getAeropuertoById(this.resumen.IdAeropuertoDestino).subscribe(data => {
          this.aeropuertoDestino = data.Nombre;
        });
    }
    
    if(this.resumen.IdArea == 2)
    {
      if(this.resumen.IdPuertoOrigen != undefined && this.resumen.IdPuertoOrigen != null)
        this.envioService.getPuertoById(this.resumen.IdPuertoOrigen).subscribe(data => {
          this.puertoOrigen = data.Nombre;
        });
      if(this.resumen.IdPuertoDestino != undefined && this.resumen.IdPuertoDestino != null)
        this.envioService.getPuertoById(this.resumen.IdPuertoDestino).subscribe(data => {
          this.puertoDestino = data.Nombre;
        });
    }
    //Cliente
    this.envioService.getEntidad(this.resumen.IdCliente).subscribe(data => {
      this.cliente = data.NombreFiscal;
    })
    //Remitente
    this.envioService.getEntidad(this.resumen.IdRemitente).subscribe(data => {
      this.shipper = data.NombreFiscal;
      this.direccionShipper = this.resumen.DireccionShipper;
    })
    //Destinatario
    this.envioService.getEntidad(this.resumen.IdDestinatario).subscribe(data => {
      this.consignee = data.NombreFiscal;
      this.direccionConsignee = this.resumen.DireccionConsignee;
      //Notify
      if(this.resumen.IdNotify != -1 && this.resumen.IdNotify != undefined && this.resumen.IdNotify != null)
        this.envioService.getEntidad(this.resumen.IdNotify).subscribe(data => {
          this.notify = data.NombreFiscal;
          this.direccionNotify = this.resumen.DireccionNotify;
        });
      else
      {
        this.notify = this.consignee;
        if(this.resumen.DireccionNotify == '' || this.resumen.DireccionNotify == null)
          this.direccionNotify = this.resumen.DireccionConsignee;
        else
          this.direccionNotify = this.resumen.DireccionNotify;
      }
    });

    //EntidadRecogida
    this.envioService.getEntidad(this.resumen.IdEntidadRecogida).subscribe(data => {
      this.entidadRecogida = data.NombreFiscal;
    });
    if(this.resumen.Incoterm == "DAP" || this.resumen.Incoterm == "DDP" || this.resumen.Incoterm == "DPU")
      //EntidadEntrega
      this.envioService.getEntidad(this.resumen.IdEntidadEntrega).subscribe(data => {
        this.entidadEntrega = data.NombreFiscal;
      });

    //TipoMercancia
    this.tipomercanciaServices.getTipoMercanciaById(this.resumen.TipoMercancia).subscribe(data => {
      this.tipoMercancia = data.Descripcion;
    });
    
    this.envioService.getTipoDivisa(this.resumen.IdTipoDivisa).subscribe(data => {
      this.tipoDivisa = data.TipoDivisa;
    });

    this.listaMercancia = this.resumen.Mercancia;
    if(this.resumen.Mercancia != undefined)
    {
      this.resumen.Mercancia.forEach(merc => {
        if(merc.MercanciaPeligrosa != undefined)
          merc.MercanciaPeligrosa.forEach(mercPel => {
            this.listaMercanciaPeligrosa.push(mercPel)
          });
      });
      this.resumen.Mercancia.forEach(m => {
        var mc = new MercanciaCotizacion();
        mc.Alimentacion = m.Alimentacion;
        mc.Alto = m.Alto;
        mc.Ancho = m.Ancho;
        mc.Bultos = m.Bultos;
        mc.Gdp = m.GDP;
        mc.Peligrosa = m.Peligrosa;
        mc.Largo = m.Largo;
        mc.MercanciaPeligrosa = this.getMercanciaPeligrosaDetalles(m.MercanciaPeligrosa);
        mc.Mp = m.Peligrosa;
        mc.PesoBruto = m.PesoBruto;
        mc.PesoNeto = m.PesoNeto;
        mc.Remontable = m.Remontable;
        mc.TemperaturaControlada = m.TemperaturaControlada;
        mc.TipoEmbalaje = m.NombreEmbalaje;
        mc.TipoMercancia = m.TipoMercancia;
        mc.GradosMin = m.GradosMin;
        mc.GradosMax = m.GradosMax;
        mc.NombreMercancia = m.NombreMercancia;
        this.mercanciaDetalles.push(mc);
      });
    }
    if(this.resumen.MercanciaContenedor != undefined && this.resumen.MercanciaContenedor.length > 0)
    {
      this.resumen.MercanciaContenedor.forEach(c => {
        var cm = new ContenedorMercancia();
        cm.NombreContenedor = c.NombreContenedor;
        cm.Mercancia = [];
        c.Mercancia.forEach(m => {
          // var mc = new MercanciaCotizacion();
          // mc.Alimentacion = m.Alimentacion;
          // mc.Alto = m.Alto;
          // mc.Ancho = m.Ancho;
          // mc.Bultos = m.Bultos;
          // mc.Gdp = m.GDP;
          // mc.Largo = m.Largo;
          // mc.MercanciaPeligrosa = this.getMercanciaPeligrosaDetalles(m.MercanciaPeligrosa);
          // mc.Mp = m.Peligrosa;
          // mc.PesoBruto = m.PesoBruto;
          // mc.PesoNeto = m.PesoNeto;
          // mc.Remontable = m.Remontable;
          // mc.TemperaturaControlada = m.TemperaturaControlada;
          // mc.TipoEmbalaje = m.NombreEmbalaje;
          // mc.TipoMercancia = m.TipoMercancia;
          // mc.GradosMin = m.GradosMin;
          // mc.GradosMax = m.GradosMax;
          cm.Mercancia.push(m);
        });
        this.mercanciasContenedorDetalles.push(cm);
      })
    }
    
    
  }



}
