<div class="col-1"></div>
<div class="col-10">
    <div class="row">
        <img style="width: 100%; height: 150%;" src="assets/imagenes/CabeceraFirma.jpg">
        <b [ngClass]="{'dni':deviceService.isMobile(), 'dniEscritorio':deviceService.isDesktop(), 'dniTablet': deviceService.isTablet()}" class="dni">DNI: {{dni}}</b>
        <signature-pad class="signaturePad" id="signaturePad" (onBeginEvent)="drawStart()" [options]="signaturePadOptions">
        </signature-pad>
    </div>
    <div class="row" style="margin-top: 3%;">
        <div class="col-4">
            <button class="btn" type="button" (click)="drawFinish();">{{'Aceptar'|translate}}</button>
        </div>
        <div class="col-4">
            <button class="btn" type="button" (click)="drawClear();">{{'Limpiar'|translate}}</button>
        </div>
        <div class="col-4">
            <button class="btn buttonCancelar" (click)="drawCancel();">{{'Cancelar'|translate}}</button>
        </div>
    </div>
</div>
<div class="col-1"></div>
