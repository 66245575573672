<div *ngIf="expedicionesPendiente != null">
    <table mat-table [dataSource]="expedicionesPendienteSinRecoger" class="mat-elevation-z8">
        <ng-container matColumnDef="check">
            <!--(change)="$event ? seleccionarTodos() : null"-->
          <th mat-header-cell *matHeaderCellDef><mat-checkbox  [checked]="comprobarTodos()"  (change)="$event ? seleccionarTodos($event) : null"></mat-checkbox></th>
          <td mat-cell *matCellDef="let element"> <mat-checkbox 
            [disabled]="element.Estado == 'Recogida'"
            [(ngModel)]="element.Checked"
            (click)="getSeleccionadosString(element.Expedicion)"></mat-checkbox> </td>
        </ng-container>
        <ng-container matColumnDef="SerieReferencia">
          <th mat-header-cell *matHeaderCellDef> {{'Referencia'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.IdSerieReferencia}} </td>
        </ng-container>
        <ng-container matColumnDef="Remitente">
          <th mat-header-cell *matHeaderCellDef> {{'Remitente'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.Remitente}} </td>
        </ng-container>
        <ng-container matColumnDef="FechaSalida">
          <th mat-header-cell *matHeaderCellDef> {{'Fecha'|translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.FechaSalida}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <!--<div class="row">
          <div class="col">
              <h2 *ngIf="texto != ''">{{'Las expediciones'|translate}} {{texto}} {{'pasarán su estado de'|translate}} <b>{{'pendiente'|translate}}</b> {{'a'|translate}} <b>{{'recogida'|translate}}</b></h2>
              <h2 *ngIf="texto == ''">{{'No has seleccionado ninguna expedición.'|translate}}</h2>
          </div>
      </div>-->
    <div class="row">
        <div class="col">
            <button style="margin-top: 2%;" class="btn tamanyo-boton" type="button" id="subirPendientesSeleccionadasHojaRuta" (click)="subirPendientes('subirPendientesSeleccionadasHojaRuta');">{{'Subir pendientes seleccionadas'|translate}}</button>
        </div>
    </div>
</div>
