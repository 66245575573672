import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { saveAs } from 'file-saver';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-customer-index',
  templateUrl: './customer-index.component.html',
  styleUrls: ['./customer-index.component.scss']
})
export class CustomerIndexComponent implements OnInit {

  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  @ViewChild('templateCambiarPasswordPrimeraVez', { read: TemplateRef }) cambiarPasswordPrimeraVez: TemplateRef<any>;
  @ViewChild('templateCambiarPasswordEsCaducada', { read: TemplateRef }) cambiarPasswordEsCaducada: TemplateRef<any>;

  modalRef: BsModalRef | null;
  mostrarAereo: boolean = false;
  mostrarMaritimo: boolean = false;
  mostrarTerrestre: boolean = false;
  privilegios;
  privilegioTodo;
  privilegioFacturacion;
  privilegioBooking;
  privilegioTracking;
  privilegioCotizacion;
  privilegioTarifa;
  isLoad: boolean = false;

  constructor(private route: Router, private translateService: TranslateService, private shipmentService: ShipmentService,
    private authenticationService: AuthenticationService, private modalService: BsModalService, private cotizacionService: CotizacionService) {
    this.esPrimeraVez();

  }

  async ngOnInit() {
    this.cargarIdioma();
    this.privilegios = await this.authenticationService.getPrivilegiosUsuario().toPromise();
    this.privilegioTodo = await (await this.authenticationService.getPrivilegio("Todo").toPromise()).toString();
    this.privilegioBooking = await (await this.authenticationService.getPrivilegio("Booking").toPromise()).toString();
    this.privilegioTracking = await (await this.authenticationService.getPrivilegio("Tracking").toPromise()).toString();
    this.privilegioCotizacion = await (await this.authenticationService.getPrivilegio("Cotizacion").toPromise()).toString();
    this.privilegioTarifa = await (await this.authenticationService.getPrivilegio("Tarifa").toPromise()).toString();
    this.isLoad = true;
    /*this.shipmentService.getEnvios2("1");
    this.shipmentService.getEnvios2("2");
    this.shipmentService.getEnvios2("3");*/
  }

  tienePrivilegioBooking() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioBooking))
  }

  tienePrivilegioTracking() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioTracking))
  }

  tienePrivilegioCotizacion() {
    return (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioCotizacion))
  }
  tienePrivilegioTarifa() {
    console.log(this.privilegios)
    return (/*this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) ||*/ this.privilegios.includes(this.privilegioTarifa))
  }

  esPrimeraVez() {
    this.route.events.subscribe((r: NavigationEnd) => {
      if (r instanceof NavigationEnd)
        this.authenticationService.esPrimeraVez().subscribe(isPrimeraVez => {
          if (isPrimeraVez)
            this.openModal(this.cambiarPasswordPrimeraVez);
          else
            this.esCaducada();
        });
    });
  }

  esCaducada() {
    this.authenticationService.esCaducada().subscribe(isCaducada => {
      if (isCaducada)
        this.openModal(this.cambiarPasswordEsCaducada);
    });
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

  cerrarModal(event) {
    this.modalRef.hide();
  }

  shipment() {
    this.route.navigate(['/customer/shipment']);
  }

  pendientes() {
    //this.route.navigate(['/customer/consulta']);
  }

  abrirBookingAereo() {
    this.route.navigate(['/customer/shipment/aereo']);
  }

  abrirBookingMaritimo() {
    this.route.navigate(['/customer/shipment/maritimo']);
  }

  abrirBookingTerrestre() {
    this.route.navigate(['/customer/shipment/terrestre']);
  }

  abrirTrackingAereo() {
    this.route.navigate(['/customer/consulta/3']);
  }

  abrirTrackingMaritimo() {
    this.route.navigate(['/customer/consulta/2']);
  }

  abrirTrackingTerrestre() {
    this.route.navigate(['/customer/consulta/1']);
  }

  abrirCotizacionAereo() {
    this.cotizacionService.posicionArea = 1;
    this.cotizacionService.posicionModoEnvio = 0;
    this.route.navigate(['/customer/cotizaciones/consulta/1']);
  }

  abrirTarifaAereo() {
    this.cotizacionService.posicionArea = 1;
    this.cotizacionService.posicionModoEnvio = 0;
    this.route.navigate(['/customer/tarifas/aereo']);
  }

  abrirTarifaMaritimo() {
    this.cotizacionService.posicionArea = 2;
    this.cotizacionService.posicionModoEnvio = 0;
    this.route.navigate(['/customer/tarifas/maritimo']);
  }

  abrirTarifaTerrestre() {
    this.cotizacionService.posicionArea = 3;
    this.cotizacionService.posicionModoEnvio = 0;
    this.route.navigate(['/customer/tarifas/terrestre']);
  }

  abrirCotizacionMaritimo() {
    this.cotizacionService.posicionArea = 2;
    this.cotizacionService.posicionModoEnvio = 0;
    this.route.navigate(['/customer/cotizaciones/consulta/2']);
  }

  abrirCotizacionTerrestre() {
    this.cotizacionService.posicionArea = 3;
    this.cotizacionService.posicionModoEnvio = 0;
    this.route.navigate(['/customer/cotizaciones/consulta/3']);
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if (this.langs.includes(lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

  mostrarImagenAereoNegro() {
    this.mostrarAereo = true;
  }

  mostrarImagenAereoBlanco() {
    this.mostrarAereo = false;
  }

  mostrarImagenMaritimoNegro() {
    this.mostrarMaritimo = true;
  }

  mostrarImagenMaritimoBlanco() {
    this.mostrarMaritimo = false;
  }

  mostrarImagenTerrestreNegro() {
    this.mostrarTerrestre = true;
  }

  mostrarImagenTerrestreBlanco() {
    this.mostrarTerrestre = false;
  }

}

