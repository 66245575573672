import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AereoShipmentComponent } from './components/users/customers/aereo-shipment/aereo-shipment.component';
import { MaritimoShipmentComponent } from './components/users/customers/maritimo-shipment/maritimo-shipment.component';
import { TerrestreShipmentComponent } from './components/users/customers/terrestre-shipment/terrestre-shipment.component';
import { CustomerIndexComponent } from './components/users/customers/customer-index/customer-index.component';
import { ListShipmentComponent } from './components/users/customers/list-shipment/list-shipment.component';
import { NotFound404Component } from './components/errors/not-found404/not-found404.component';
import { ShippingFilesComponent } from './components/users/customers/shipping-files/shipping-files.component';
import { ShippingTrackingComponent } from './components/users/customers/shipping-tracking/shipping-tracking.component';
import { LoginConductorComponent } from './components/login/login-conductor/login-conductor.component';
import { ConductorComponent } from './components/users/conductor/conductor.component';
import { HistorialComponent } from './components/users/conductor/historial/historial.component';
import { PreviewDownloadComponent } from './components/users/conductor/historial/preview-download/preview-download.component';
import { ConductorGuard } from './authguard/conductor.guard';
import { UserGuard } from './authguard/user.guard';
//import { TerminosYCondicionesComponent } from './components/terminos-y-condiciones/terminos-y-condiciones.component';
import { MiPerfilComponent } from './components/users/mi-perfil/mi-perfil.component';
import { HomeComponent } from './components/users/proveedor/home/home.component';
import { ExpedicionesComponent } from './components/users/proveedor/expediciones/expediciones.component';
import { MatriculasComponent } from './components/users/proveedor/matriculas/matriculas.component';
import { ConductoresComponent } from './components/users/proveedor/conductores/conductores.component';
import { ProveedorGuard } from './authguard/proveedor.guard';
import { AjustesComponent } from './components/users/ajustes/ajustes.component';
import { HistorialProveedorComponent } from './components/users/proveedor/historial-proveedor/historial-proveedor.component';
import { DocumentacionProveedorComponent } from './components/users/proveedor/documentacion-proveedor/documentacion-proveedor.component';
import { FacturacionComponent } from './components/users/customers/facturacion/facturacion.component';
import { PrivilegiosCustomer } from './authguard/privilegiosCustomer.guard';
import { ListCotizacionComponent } from './components/users/customers/cotizaciones/list-cotizacion/list-cotizacion.component';
import { TrackingDetailsComponent } from './components/users/customers/tracking-details/tracking-details.component';
import { CotizacionAereaComponent } from './components/users/customers/cotizaciones/cotizacion-aerea/cotizacion-aerea.component';
import { CotizacionMaritimoComponent } from './components/users/customers/cotizaciones/cotizacion-maritimo/cotizacion-maritimo.component';
import { CotizacionTerrestreComponent } from './components/users/customers/cotizaciones/cotizacion-terrestre/cotizacion-terrestre.component';
import { TarifaAereoComponent } from './components/users/customers/tarifas/tarifa-aereo/tarifa-aereo.component';
import { TarifaMaritimoComponent } from './components/users/customers/tarifas/tarifa-maritimo/tarifa-maritimo.component';
import { TarifaTerrestreComponent } from './components/users/customers/tarifas/tarifa-terrestre/tarifa-terrestre.component';
import { ListadoTarifasComponent } from './components/users/customers/tarifas/listado-tarifas/listado-tarifas.component';
import { ListTarifaComponent } from './components/users/customers/tarifas/list-tarifa/list-tarifa.component';

const routes: Routes = [
  //{ path: 'terminosycondiciones', component: TerminosYCondicionesComponent },
  {
    path: 'login', children: [
      { path: '', component: LoginComponent },
      { path: 'conductor', component: LoginComponent }
    ]
  },
  { path: 'customer/tracking/:codigoTracking', component: TrackingDetailsComponent },
  {
    path: 'customer', canActivate: [UserGuard], children: [
      { path: '', component: CustomerIndexComponent },
      {
        path: 'cotizaciones', canActivate: [PrivilegiosCustomer], data: { privilegio: "Cotizacion" }, children: [
          { path: 'aereo', component: CotizacionAereaComponent },
          { path: 'maritimo', component: CotizacionMaritimoComponent },
          { path: 'terrestre', component: CotizacionTerrestreComponent },
          { path: 'consulta/:idArea', component: ListCotizacionComponent },
        ]
      },
      {
        path: 'tarifas', canActivate: [PrivilegiosCustomer], data: { privilegio: "Tarifa" }, children: [
          {
            path: 'aereo', children: [
              { path: '', component: TarifaAereoComponent },
              // { path: 'listado', component: ListadoTarifasComponent },
              { path: 'list', component: ListTarifaComponent }
            ]
          },
          {
            path: 'maritimo', children: [
              { path: '', component: TarifaMaritimoComponent },
              // { path: 'listado', component: ListadoTarifasComponent },
              { path: 'list', component: ListTarifaComponent }
            ]
          },
          {
            path: 'terrestre', children: [
              { path: '', component: TarifaTerrestreComponent },
              // { path: 'listado', component: ListadoTarifasComponent },
              { path: 'list', component: ListTarifaComponent }
            ]
          }
        ]
      },
      { path: 'miPerfil', component: AjustesComponent },
      { path: 'consulta/:idArea', component: ListShipmentComponent },
      {
        path: 'shipment', canActivate: [PrivilegiosCustomer], data: { privilegio: "Booking" }, children: [
          { path: '', component: CustomerIndexComponent },
          { path: 'aereo', component: AereoShipmentComponent },
          { path: 'maritimo', component: MaritimoShipmentComponent },
          { path: 'terrestre', component: TerrestreShipmentComponent },
          { path: 'documents', component: ShippingFilesComponent },
          { path: 'tracking', canActivate: [PrivilegiosCustomer], data: { privilegio: "Tracking" }, component: ShippingTrackingComponent }
        ]
      },
      { path: 'facturacion', canActivate: [PrivilegiosCustomer], data: { privilegio: "Facturacion" }, component: FacturacionComponent }
    ]
  },
  {
    path: 'conductor', canActivate: [ConductorGuard], children: [
      { path: 'exp-pendientes', component: ConductorComponent },
      { path: 'historial', component: HistorialComponent },
      { path: 'historial/:file/download', component: PreviewDownloadComponent },
      { path: 'miPerfil', component: MiPerfilComponent }
    ]
  },
  {
    path: 'proveedor', canActivate: [ProveedorGuard], children: [
      { path: '', component: ExpedicionesComponent },
      { path: 'expedicionesActivas', component: ExpedicionesComponent },
      { path: 'conductores', component: ConductoresComponent },
      { path: 'vehiculos', component: MatriculasComponent },
      { path: 'historial', component: HistorialProveedorComponent },
      { path: 'miPerfil', component: MiPerfilComponent },
      { path: 'documentacion', component: DocumentacionProveedorComponent }
    ]
  },
  { path: '', component: LoginComponent },
  { path: '**', component: NotFound404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
