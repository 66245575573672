import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';

@Component({
  selector: 'app-detalles-mercancia-peligrosa',
  templateUrl: './detalles-mercancia-peligrosa.component.html',
  styleUrls: ['./detalles-mercancia-peligrosa.component.scss']
})
export class DetallesMercanciaPeligrosaComponent implements OnInit {

  @Input() mercanciasPeligrosas: MercanciaPeligrosa[];
  @Input() visible: boolean=false;
  totalBultos: number = 0;
  totalPesoNeto: number = 0;
  totalPesoBruto: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.mercanciasPeligrosas.forEach(mp => {
      if(mp.Bultos != undefined)
        this.totalBultos += Number(mp.Bultos);
      this.totalPesoNeto += Number(mp.PesoNeto);
      this.totalPesoBruto += Number(mp.PesoBruto); 
    });
  }
}
