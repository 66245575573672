import { Component, LOCALE_ID, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Expedition } from 'src/app/models/expedition.model';
import { Observable } from 'rxjs';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Archivo } from 'src/app/models/archivo.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { IncidenciasService } from 'src/app/services/incidencias.service';
import { Incidencia } from 'src/app/models/incidencia.model';
import { DestinatarioRemitente } from 'src/app/models/destinatarioRemitente.model';
import { ExpedicionesPorFecha } from 'src/app/models/expediciones-por-fecha.model';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-historial-proveedor',
  templateUrl: './historial-proveedor.component.html',
  styleUrls: ['./historial-proveedor.component.scss'],
  providers: [{provide: LOCALE_ID, useValue: 'es'}, DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({
        height: '0px',
        visibility: 'hidden'
      })),
      state('expanded', style({
        'min-height': '48px',
        height: '*',
        visibility: 'visible'
      })),
      transition(
        'expanded <=> collapsed, void <=> *',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ]
})
export class HistorialProveedorComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  listExpedition: Expedition[] = [];
  documentos: Observable<Archivo[]>
  isMovil: boolean = false;
  expedicionString: string = "";
  expedicionesFactura: string[] = [];
  idExpedicion: number;
  estadoString: string = "";
  expedicion: Expedition = new Expedition();
  listExp;
  isProveedor: boolean = true;
  expandedElement: Expedition | null;
  total: number;
  pageActual: number = 1;
  sizePage: number = 10;
  incidencias: Incidencia[];
  estados: string[] = ["Pendiente", "Recogida", "Entregado"]
  destinatarioRemitenteHistorico: DestinatarioRemitente[] = [];
  historicos: string[] = [];
  historico: Expedition[] = []
  histo: ExpedicionesPorFecha[] = [];
  panelOpenState: boolean[] = [];
  modalRef: BsModalRef | null;
  selectedAll: boolean = false;
  indice: number;
  dataSource = null;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  proveedor : boolean = true;

  constructor(private expService: ExpeditionApiService, private modalService: BsModalService,
    private router: Router, public dialog: MatDialog,
    private toastr: ToastrService, private deviceService: DeviceDetectorService,
    private incidenciasService: IncidenciasService, private translateService: TranslateService) {
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if(this.langs.includes(lang))
      this.translateService.setDefaultLang(lang);
    else if (this.langs.indexOf(browserlang) > -1)
      this.translateService.setDefaultLang(browserlang);
    else
     this.translateService.setDefaultLang('es');
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }
  
  closeModal(modalId?: number){
    this.modalService.hide();
  }

  toStringEntrega(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fechaFinal: string = "";
      var fecha = fechaTotal.split(" ")[0];
      fechaFinal = fecha;
      if(this.isHorarioCompletoConPausaIntermediaEntrega()){ // HORARIO COMPLETO CON PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        var ydesdelas = this.translateService.instant(" y desde las ");
        var a = this.translateService.instant(" a ");
        fechaFinal += desdelas + this.expedicion.he1 + hastalas + this.expedicion.he2 + ydesdelas + this.expedicion.he3 + a + this.expedicion.he4;
      }else if(this.isHorarioFijoSinPausaIntermediaEntrega()){ // HORARIO FIJO SIN PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        fechaFinal += desdelas + this.expedicion.he3 + hastalas + this.expedicion.he4;
      }else if(this.isHoraFijaEntrega()){ // HORARIO DE UNA SOLA HORA
        var horarioFijo = this.translateService.instant("HORARIO FIJO E: ");
        fechaFinal += horarioFijo + this.expedicion.he3;
      }
      return fechaFinal;
    }
  }

  setExpedicionesToFactura(expedicion: string){
    if(this.expedicionesFactura.includes(expedicion)){
      var index: number = this.expedicionesFactura.findIndex(e => e == expedicion);
      this.expedicionesFactura.splice(index, 1);
      this.selectedAll = false;
      this.idExpedicion = null;
    }else{
      this.idExpedicion = this.listExpedition.find(e => e.Expedicion == expedicion).IdExpedicion;
      this.expedicionesFactura.push(expedicion);
      this.listExpedition.find(e => e.Expedicion == expedicion).Checked = true;
      this.selectedAll = this.comprobarTodosSelected();
      this.listExpedition.find(e => e.Expedicion == expedicion).Checked = false;
    }
  }

  seleccionarTodos(){
    var cambio: boolean = !this.selectedAll;
    this.listExpedition.forEach(e => {
      if(e.Checked == undefined || e.Checked != cambio){
        e.Checked = cambio;
        if(this.expedicionesFactura.includes(e.Expedicion) && e.Checked == false){
          var index: number = this.expedicionesFactura.findIndex(ex => ex == e.Expedicion);
          this.expedicionesFactura.splice(index, 1);
        }else if(e.Checked == true)
          this.expedicionesFactura.push(e.Expedicion);
      }
    });
  }

  comprobarTodosSelected(): boolean{
    var resultado : boolean = true;
    this.listExpedition.forEach(e => {
      if(e.Checked == false || e.Checked == undefined){
        resultado = false;
      }})
      return resultado;
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  // Imprimir en un string la dirección completa
  montarDireccion(direccion: string, codPostal: string, poblacion: string){
    var direccionTotal: string = "";
    if(direccion != undefined)
      direccionTotal += direccion;
    if(poblacion != undefined){
      if(direccionTotal != "")
        direccionTotal += ', ' + poblacion;
      else
        direccionTotal += poblacion;
    }
    if(codPostal != undefined){
      if(direccionTotal != "")
        direccionTotal += ', ' + codPostal;
      else
        direccionTotal += codPostal;
    }
    return direccionTotal
  }

  showDetails(exp: Expedition){
    this.expedicion = new Expedition();
    this.expService.getExpedicion(exp.Numero).subscribe(data => {
      this.expedicion = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede obtener la información de la expedición.');
      var errorTitulo = this.translateService.instant('Error obtener información');
      this.toastr.error(errorBody, errorTitulo)
    });
  }

  // Saber siguiente estado
  siguienteEstado(estado) {
    var nextEstado = ""
    if (estado === this.estados[1]) {
      nextEstado = this.estados[2]
    } else if (estado === this.estados[0] || !estado) {
      nextEstado = this.estados[1]
    }
    return nextEstado
  }

  getColumnas(){
    return ['checkBox', 'referencia', 'expedicion', 'fecha', 'facturado', 'arrow'];
  }

 // Cuando pinchas en el botón detalles llamamos al servidor para coger la expedición en especifico que quieras ver los detalles.
  detallesExpedition(){
    this.expService.getExpedicion(this.expedicionString).subscribe(data => {
      this.listExp = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede obtener la información de la expedición.');
      var errorTitulo = this.translateService.instant('Error obtener información');
      this.toastr.error(errorBody, errorTitulo)
    })
  }

  // Cuando pinchas en el botón documentos llamamos al servidor para coger los documentos de la expedición en especifico.
  /*documentosExpedition(){
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => {});
    this.expService.getDocumentos(this.idExpedicion).subscribe(data => {
      this.documentos = data;
      dialogRef.close();
    }, err => {
      var errorBody = this.translateService.instant('No se puede cargar los documentos.');
      var errorTitulo = this.translateService.instant('Error carga documentos');
      this.toastr.error(errorBody, errorTitulo)
      dialogRef.close();
    })
  }*/

  setAtributtes(expedicion: string, estado: string, idExpedicion: number, listExp){
    this.expedicionString = expedicion
    this.idExpedicion = idExpedicion;
    this.estadoString = estado
    this.listExp = listExp
  }

  // Para indicar si ha sido correctamente o ha fallado de realizar las cosas.
  openToast(event){
    if(event[0]){
      this.toastr.success(event[1])
    }else{
      this.toastr.error(event[1])
    }
  }

 ngOnInit() {
    // Pendiente, Recogido, Entregado
    var index : number = 0;
    this.inicializar();
 }

  close(){
    window.document.getElementById("Incidencias").click();
  }

  incidenciasExpedition(){
    let idExpedicion: number = Number(this.expedicionString);
    this.incidenciasService.obtenerListadoIncidencias(idExpedicion).subscribe(data => {
      this.incidencias = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede cargar las incidencias.');
      var errorTitulo = this.translateService.instant('Error carga incidencias');
      this.toastr.error(errorBody, errorTitulo)
    })
  }

  getHistorico(){
    this.historicos = [];
    this.destinatarioRemitenteHistorico = [];
    this.historico.forEach(exp => {
      if(this.historicos.length > 0){
        var destinatario = this.historico.find(e => e.Destinatario == exp.Destinatario);
        if(destinatario == undefined){ // No existe ese remitente
          this.historicos.push(exp.Destinatario)
          var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Destinatario;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitenteHistorico.push(dr);
        }else{ // Ya existe el remitente
          var dr = this.destinatarioRemitenteHistorico.find(e => e.DestinatarioRemitente == exp.Destinatario);
          dr.Expediciones.push(exp);
          //this.destinatarioRemitentePendientesRecogida.push(dr);
        }
      }else{ // Es el primero que entra
        var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Destinatario;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitenteHistorico.push(dr);
        this.historicos.push(exp.Destinatario)
      }
    })
    this.total[1] = this.destinatarioRemitenteHistorico.length;
  }

  inicializar(){
    this.expedicionesFactura = [];
    this.expService.getExpedicionesHistoricoProveedor().subscribe(data => {
      this.listExpedition = data as Expedition[];
      this.dataSource = new MatTableDataSource<Expedition>(this.listExpedition);
      this.dataSource.paginator = this.paginator;
      this.total = this.listExpedition.length;
    }, err => {
      var errorBody = this.translateService.instant('Fallo al cargar las expediciones, contacte con el dep. terrestre.');
      var errorTitulo = this.translateService.instant('Error');
      this.toastr.error(errorBody, errorTitulo)
    });
  }

  muestraDestinatario(expedicion: Expedition): Boolean{
    var mostrar: Boolean = false;
    if(expedicion.Estado != "Pendiente" && expedicion.Estado != null && expedicion.Estado != undefined)
      mostrar = true;
    return mostrar;
  }

  isHorarioCompletoConPausaIntermediaRecogida(){
    return this.expedicion.hr1 != "" && this.expedicion.hr2 != "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "";
  }

  isHorarioFijoSinPausaIntermediaRecogida(){
    return this.expedicion.hr1 == "" && this.expedicion.hr2 == "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" && this.expedicion.hr3 != this.expedicion.hr4;
  }

  isHoraFijaRecogida(){
    return this.expedicion.hr1 == "" && this.expedicion.hr2 == "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" && this.expedicion.hr3 == this.expedicion.hr4;
  }

  isHorarioCompletoConPausaIntermediaEntrega(){
    return this.expedicion.he1 != "" && this.expedicion.he2 != "" && this.expedicion.he3 != "" && this.expedicion.he4 != "";
  }

  isHorarioFijoSinPausaIntermediaEntrega(){
    return this.expedicion.he1 == "" && this.expedicion.he2 == "" && this.expedicion.he3 != "" && this.expedicion.he4 != "" && this.expedicion.he3 != this.expedicion.he4;
  }

  isHoraFijaEntrega(){
    return this.expedicion.he1 == "" && this.expedicion.he2 == "" && this.expedicion.he3 != "" && this.expedicion.he4 != "" && this.expedicion.he3 == this.expedicion.he4;
  }

  toStringRecogida(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fechaFinal: string = "";
      var fecha = fechaTotal.split(" ")[0];
      fechaFinal = fecha;
      if(this.isHorarioCompletoConPausaIntermediaRecogida()){ // HORARIO COMPLETO CON PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        var ydesdelas = this.translateService.instant(" y desde las ");
        var a = this.translateService.instant(" a ");
        fechaFinal += desdelas + this.expedicion.hr1 + hastalas + this.expedicion.hr2 + ydesdelas + this.expedicion.hr3 + a + this.expedicion.hr4;
      }else if(this.isHorarioFijoSinPausaIntermediaRecogida()){ // HORARIO FIJO SIN PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        fechaFinal += desdelas + this.expedicion.hr3 + hastalas + this.expedicion.hr4;
      }else if(this.isHoraFijaRecogida()){ // HORARIO DE UNA SOLA HORA
        var horarioFijo = this.translateService.instant("HORARIO FIJO R: ");
        fechaFinal += horarioFijo + this.expedicion.hr3;
      }
      return fechaFinal;
    }
  }

  adjuntarExpediciones(event){
    var i: number = event[1];
    var array: string[] = event[0];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  limpiar(event){
    this.expedicionesFactura = [];
    this.listExpedition.forEach(e => e.Checked = false);
  }

  cerrar(event){
    this.modalService.hide();
    this.inicializar();
  }

  setIdExpedicion(event){
    this.idExpedicion = event;
  }

  setIndice(i: number){
    this.indice = i;
  }

}
