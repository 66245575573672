<app-header-proveedor></app-header-proveedor>
<div class="container">
    <div class="block"></div>
    <div class="row">
        <div class="col-sm-6" [ngClass]="{'borderRight': !deviceService.isMobile(), 'borderBottom': this.deviceService.isMobile()}">
            <app-lista-conductores #listaConductores></app-lista-conductores>
        </div>
        <div class="col-sm-6">
            <app-form-conductor></app-form-conductor>
        </div>
    </div>
</div>