import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Embalaje } from '../models/embalaje.model';
import { LogModel } from '../models/log-model.model';
import { TipoEmbalaje } from '../models/tipo-embalaje.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TipoembalajeService {
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  
  readonly apiUrlService = environment.apiUrl;
  embalajes$: Subject<TipoEmbalaje[]> = new Subject();
  predeterminado: boolean;

  constructor(private http: HttpClient, private authenticacion: AuthenticationService) {
  }

  getListaEmbalajes$(): Observable<TipoEmbalaje[]> {
    return this.embalajes$.asObservable();
  }

  getListaEmbalajes(predeterminados: boolean)
  {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cli_tipoembalaje/predeterminado/" + predeterminados
     this.http.get<TipoEmbalaje[]>(url_api, {headers: headersToken}).pipe(map(data => data as TipoEmbalaje[]))
     .subscribe(data => {
       this.embalajes$.next(data);
     });
  }

  postEmbalaje(tipoEmbalaje: TipoEmbalaje, logModel: LogModel)
  {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "cli_tipoembalaje";
    return this.http.post(url_api,JSON.stringify({"Log": logModel, "Body": JSON.stringify(tipoEmbalaje).toString()}), {headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  putEmbalaje(tipoEmbalaje: TipoEmbalaje, logModel: LogModel)
  {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "cli_tipoembalaje";
    return this.http.put(url_api,JSON.stringify({"Log": logModel, "Body": JSON.stringify(tipoEmbalaje).toString()}), {headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }


  deleteEmbalaje(embalaje: Embalaje, logModel: LogModel) {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "cli_tipoembalaje/" + embalaje.IdTipoEmbalaje;
    return this.http.delete(url_api, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  getTipoEmbalajes(logModel: LogModel):Observable<TipoEmbalaje[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "ma_tipoembalaje/listatipos";
    return this.http.get<TipoEmbalaje[]>(url_api, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

}
