import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Archivo } from 'src/app/models/archivo.model';
import { FacturacionService } from 'src/app/services/facturacion.service';

@Component({
  selector: 'app-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss']
})
export class PreviewPdfComponent implements OnInit {

  @Input() numFactura = "";
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  archivos: Archivo[] = [];
  base64ArchivoFactura: string = "";
  nombrePDF: String = "";
  numPDF: number = 0;
  totalPDF: number = 0;

  constructor(private facturacionService: FacturacionService) { }

  ngOnInit(): void {
    this.facturacionService.getFactura(this.numFactura).subscribe(archivos => {
      this.archivos = archivos;
      this.base64ArchivoFactura = archivos[this.numPDF].Base64image.toString();
      this.nombrePDF = archivos[this.numPDF].FileName;
      this.totalPDF = archivos.length - 1;
    });
  }

  siguientePDF() {
    if(this.numPDF == this.totalPDF)
      this.numPDF = 0;
    else
      this.numPDF++;
    
    this.base64ArchivoFactura = this.archivos[this.numPDF].Base64image.toString();
    this.nombrePDF = this.archivos[this.numPDF].FileName;
  }

  anteriorPDF() {
    if(this.numPDF == 0)
      this.numPDF = this.totalPDF;
    else
      this.numPDF--;
    
    this.base64ArchivoFactura = this.archivos[this.numPDF].Base64image.toString();
    this.nombrePDF = this.archivos[this.numPDF].FileName;
  }

}
