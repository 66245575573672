<div [ngClass]="{'modal-content fondo': conModel, 'fondo': !conModel}" *ngIf="cotizacion != undefined && cotizacion != null">
  <div *ngIf="conModel" class="modal-header fondo">
        <div class="col">
            <div class="row">
                <div class="col-10">
                    <h2 class="modal-title pull-left">
                        {{ "DetallesCotizacion" | translate }}: {{ cotizacion.referencia }} /
                        {{ cotizacion.departamento }}
                      </h2>
                </div>
                <div class="col-2">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="cerrar(true)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h3 class="modal-subtitle">{{ cotizacion.contacto }}</h3>
                </div>
            </div>
        </div>
  </div>
  <!--  class="modal-body fondo" -->
  <div [ngClass]="{'modal-body fondo': conModel, 'fondo': !conModel}" class="modalBodyFondo">
    <div class="container">
      <div class="row">
        <div class="col-sm-12" id="tracking">
          <div class="row">
            <div class="col-sm-12">
              <div class="table-responsive scrollable-element">
                <table class="table table-sm table-bordered table-editable table-row-action edit-action">
                  <thead>
                    <th>{{ "CodigoLabel" | translate }}</th>
                    <th>{{ "Fecha" | translate }}</th>
                    <th>{{ "Validez" | translate }}</th>
                    <th>{{ "MercanciaDisponibleDesde" | translate }}</th>
                  </thead>
                  <tbody>
                    <td>{{ cotizacion.codigo }}</td>
                    <td>{{ cotizacion.fecha }}</td>
                    <td>{{ cotizacion.validez }}</td>
                    <td>{{ cotizacion.mercanciaDisponibleDesde }}</td>
                  </tbody>
                </table>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action" *ngIf="cotizacion.area == 0"> <!-- ES TERRESTRE-->
                  <thead>
                    <th>{{ "LocalidadOrigen" | translate }}</th>
                    <th>{{ "LocalidadDestino" | translate }}</th>
                  </thead>
                  <tbody>
                    <td>{{ cotizacion.origen }}</td>
                    <td>{{ cotizacion.destino }}</td>
                  </tbody>
                </table>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action" *ngIf="cotizacion.area == 1"> <!-- ES MARÍTIMO-->
                  <thead>
                    <th>{{ "LocalidadOrigen" | translate }}</th>
                    <th>{{ "Puerto Origen" | translate }}</th>
                    <th>{{ "Puerto Destino" | translate }}</th>
                    <th>{{ "LocalidadDestino" | translate }}</th>
                  </thead>
                  <tbody>
                    <td>{{ cotizacion.origenLocalidad }}</td>
                    <td>{{ cotizacion.origen }}</td>
                    <td>{{ cotizacion.destino }}</td>
                    <td>{{ cotizacion.destinoLocalidad }}</td>
                  </tbody>
                </table>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action" *ngIf="cotizacion.area == 2"> <!-- ES AEREO-->
                  <thead>
                    <th>{{ "LocalidadOrigen" | translate }}</th>
                    <th>{{ "Aeropuerto Origen" | translate }}</th>
                    <th>{{ "Aeropuerto Destino" | translate }}</th>
                    <th>{{ "LocalidadDestino" | translate }}</th>
                  </thead>
                  <tbody>
                    <td>{{ cotizacion.origenLocalidad }}</td>
                    <td>{{ cotizacion.origen }}</td>
                    <td>{{ cotizacion.destino }}</td>
                    <td>{{ cotizacion.destinoLocalidad }}</td>
                  </tbody>
                </table>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action">
                  <thead>
                    <th>{{ "Entidad" | translate }}</th>
                    <th>{{ "Incoterm" | translate }}</th>
                    <th>{{ "CondicionPago" | translate }}</th>
                    <th>{{ "Carrier" | translate }}</th>
                  </thead>
                  <tbody>
                    <td>{{ cotizacion.nombreEntidad }}</td>
                    <td>{{ cotizacion.incoterm }}</td>
                    <td>{{ cotizacion.condicionPago }}</td>
                    <td>{{ cotizacion.carrier }}</td>
                  </tbody>
                </table>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action">
                  <thead>
                    <th>{{ "Frecuencia" | translate }}</th>
                    <th>{{ "MercanciaPeligrosa" | translate }}</th>
                    <th>{{ "TiempoTransito" | translate }}</th>
                  </thead>
                  <tbody>
                    <td>{{ cotizacion.frecuencia }}</td>
                    <td>{{ cotizacion.mercanciaPeligrosa }}</td>
                    <td>{{ cotizacion.tiempoTransito }}</td>
                  </tbody>
                </table>
                <table *ngIf="cotizacion.mercancias != undefined && cotizacion.mercancias != null && cotizacion.mercancias.length == 0" class="table table-sm table-bordered table-editable table-row-action edit-action">
                  <thead>
                    <th>{{ "Mercancia" | translate }}</th>
                  </thead>
                  <tbody>
                    <td class="saltoLinea">{{ cotizacion.mercancia }}</td>
                  </tbody>
                </table>
                <span *ngIf="cotizacion.mercancias != undefined && cotizacion.mercancias != null && cotizacion.mercancias?.length > 0">
                    <td class="saltoLinea">
                      <app-detalles-mercancia [showColums]="visible" [mercanciasCotizacion]="cotizacion.mercancias"></app-detalles-mercancia>
                    </td>
                </span>
                <span *ngIf="cotizacion.mercanciasContenedor != undefined && cotizacion.mercanciasContenedor != null && cotizacion.mercanciasContenedor.length > 0">
                    <td class="saltoLinea">
                      <app-detalles-contenedor [contenedoresCotizacion]="cotizacion.mercanciasContenedor"></app-detalles-contenedor>
                    </td>
                </span>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action">
                  <thead>
                    <th>{{ "Concepto" | translate }}</th>
                    <th>{{ "Importe" | translate }}</th>
                    <th>{{ "Divisa" | translate }}</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ic of cotizacion.lineasIngreso">
                      <td>{{ ic.concepto }}</td>
                      <td>{{ ic.importe }}</td>
                      <td>{{ ic.divisa }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr *ngFor="let t of totales" style="border: 2px solid black;">
                      <td>{{ t.descripcion }}</td>
                      <td>{{ t.total }}</td>
                      <td>{{ t.divisa }}</td>
                    </tr>
                  </tfoot>
                </table>
                <table class="table table-sm table-bordered table-editable table-row-action edit-action">
                  <thead>
                    <th>{{ "Observaciones" | translate }}</th>
                  </thead>
                  <!--{{getTexto(cotizacion.observaciones)}}-->
                  <tbody>
                    <td class="saltoLinea">
                      {{ cotizacion.observaciones }}
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
                <div class="col-sm-8"></div>
                <div class="col-sm-2" style="text-align: right;">
                    <button type="button" class="btn" style="margin: 5px;"
                        (click)="downloadPDF()">{{'ImprimirTracking'|translate}}</button>
                </div>
                <div class="col-sm-2" style="text-align: right;">
                    <button type="button" class="btn" style="margin: 5px;" (click)="cerrar()">{{'Cerrar'|translate}}</button>
                </div>
            </div> -->
    </div>
  </div>
</div>
