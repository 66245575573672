<div class="container">
  <div class="row">
    <div class="col-6 col-sm-6" style="margin-bottom: 5%;">
      <div class="row">
        <div class="col-5">
          <mat-form-field style="width: auto;" appearance="fill">
            <mat-label>{{'Matricula'|translate}}</mat-label>
            <input type="text" attr.placeholder="{{'EligeUno' | translate }}"
              attr.aria-label="{{'Matricula' | translate }}" matInput [formControl]="matriculaControl"
              [matAutocomplete]="autoMatricula" (blur)="onBlurMatricula($event)">
            <mat-autocomplete autoActiveFirstOption #autoMatricula="matAutocomplete" [displayWith]="displayVehiculo">
              <mat-option *ngFor="let matricula of filteredMatricula | async" [value]="matricula">
                {{matricula.Matricula}} - {{matricula.Tipo}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="matriculaControl.hasError('required')">
              {{'Debes de seleccionar alguna matricula.'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <button title="{{'Add Matricula'|translate}}" type="button" class="btn ms-2"
            (click)="openModal(AddMatricula)">
            <img src="assets/iconos/plusAdd_16.png" alt="">
          </button>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <button *ngIf="matriculaControl.value" title="{{'Update Matricula'|translate}}" type="button" class="btn ms-1 center" style="height: 36px;"
          (click)="openModal(UpdateMatricula, 'updateMatricula')">
          <mat-icon class="cursor">create</mat-icon>
        </button>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-6" style="margin-bottom: 5%;">
      <div class="row">
        <div class="col-5">
          <mat-form-field style="width: auto;" appearance="fill">
            <mat-label>{{'Conductor'|translate}}</mat-label>
            <input type="text" attr.placeholder="{{'EligeUno' | translate }}"
              attr.aria-label="{{'Conductor' | translate }}" matInput [formControl]="conductorControl"
              [matAutocomplete]="autoConductor" (blur)="onBlurConductor($event)">
            <mat-autocomplete autoActiveFirstOption #autoConductor="matAutocomplete" [displayWith]="displayConductor">
              <mat-option *ngFor="let conductor of filteredConductor | async" [value]="conductor">{{conductor.Nombre}}
                {{conductor.Apellidos}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="conductorControl.hasError('required')">
              {{'ErrorRequiredConductor'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <button title="{{'Add Conductor'|translate}}" type="button" class="btn ms-1"
            (click)="openModal(AddConductor)">
            <img src="assets/iconos/plusAdd_16.png" alt="">
          </button>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center">
          <button *ngIf="conductorControl.value" title="{{'Update Conductor'|translate}}" type="button" class="btn ms-1" style="height: 36px;"
            (click)="openModal(UpdateConductor, 'updateConductor')">
                <mat-icon class="cursor">create</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 2%;">
    <div class="col-4" style="margin-top: 2%;">
      <button class="btn" id="asignarMatricula" type="button"
        (click)="asignarAll('asignarMatricula-conductor');">{{'Asignar'|translate}}</button>
    </div>
    <div class="col-4" style="margin-top: 2%;">
      <button class="btn buttonCancelar" type="button" (click)="cancelar()">{{'Cancelar'|translate}}</button>
    </div>
    <div class="col-4" style="margin-top: 2%;">
      <button class="btn" style="float: left;" type="button" id="desasignar-vehiculo-conductor"
        (click)="desasignarAll('desasignar-vehiculo-conductor');">{{'Desasignar'|translate}}</button>
    </div>
  </div>
</div>


<ng-template #AddMatricula>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Añadir Matricula'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-form-vehiculo (emitCreateVehiculo)="refreshVehiculo($event)"></app-form-vehiculo>
    </div>
  </div>
</ng-template>

<ng-template #UpdateMatricula>
    <div class="modal-content fondo">
      <div class="modal-header fondo">
        <h2 class="modal-title pull-left">{{'Actualizar Matricula'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body fondo">
        <app-form-vehiculo (emitCreateVehiculo)="refreshVehiculo($event)"></app-form-vehiculo>
      </div>
    </div>
  </ng-template>

<ng-template #AddConductor>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Añadir Conductor'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-form-conductor (emitCreateConductor)="refreshConductor($event)"></app-form-conductor>
    </div>
  </div>
</ng-template>

<ng-template #UpdateConductor>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Update Conductor'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-form-conductor (emitCreateConductor)="refreshConductor($event)"></app-form-conductor>
    </div>
  </div>
</ng-template>
