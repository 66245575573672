<div class="row">
  <div class="col-sm-12 col-12 col-lg-12">
    <div class="table-responsive-sm table-responsive scrollable-element">
      <table
        class="table table-sm table-bordered table-hover table-editable table-row-action edit-action"
      >
        <thead>
          <th (click)="sort('Fecha')">
            {{ "Fecha" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'FechaRecogida'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th (click)="sort('TipoEnvio')">
            {{ "TipoEnvio" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Envio'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th *ngIf="!isTerrestre()" (click)="sort('Viaje')">
            {{ "Viaje" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Viaje'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th (click)="sort('Partida')">
            {{ "Partida/Expedición" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Partida'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th (click)="sort('Referencia')">
            {{ "Referencia" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Referencia'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th (click)="sort('Destino')">
            {{ "Remitente" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Remitente'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th (click)="sort('Shipper')">
            {{ "Destinatario" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Destinatario'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <th (click)="sort('Descripcion')">
            {{ "Estado" | translate }}
            <span
              class="glyphicon sort-icon"
              *ngIf="key == 'Estado'"
              [ngClass]="{
                'glyphicon-chevron-up': reverse,
                'glyphicon-chevron-down': !reverse
              }"
            ></span>
          </th>
          <!-- <th>{{'Retraso'|translate}}
              <span class="glyphicon sort-icon" *ngIf="key =='DiasRetraso'"
                [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
            </th> -->
          <th>{{ "Doc." | translate }}</th>
          <th>{{ "Acciones" | translate }}</th>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let envio of listado
                | slice
                  : (pageActual - 1) * sizePage
                  : (pageActual - 1) * sizePage + sizePage
            "
            #tableShipments
          >
            <tr>
              <td
                style="cursor: pointer"
                [ngClass]="{
                  adelantado: esAdelanto(envio.DiasRetraso),
                  atrasado: esAtraso(envio.DiasRetraso)
                }"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ pipe.transform(envio.FechaRecogida, "shortDate") }}
              </td>
              <td
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ getArea(envio.IdArea) | translate }}
              </td>
              <td
                *ngIf="!isTerrestre()"
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.Viaje }}
              </td>
              <td
                *ngIf="!isTerrestre()"
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.Partida }}
              </td>
              <td
                *ngIf="isTerrestre()"
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.Expedicion }}
              </td>
              <td
                *ngIf="envio.Expedicion == null && envio.Partida == null"
              ></td>
              <td
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.RefCliente }}
              </td>
              <td
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.Remitente }}
              </td>
              <td
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.Destinatario }}
              </td>
              <td
                style="cursor: pointer"
                (click)="openModal(detailTemplate, envio)"
              >
                {{ envio.Estado | translate }}
              </td>
              <td
                [ngStyle]="
                  envio.Archivos != []
                    ? { cursor: 'pointer' }
                    : { cursor: 'none' }
                "
                (click)="openModal(documentsTemplate, envio)"
              >
                <img
                  src="assets/iconos/pdficono.ico"
                  alt=""
                  style="width: 20px; height: 20px"
                  title="Documentos"
                />
              </td>
              <td>
                <div class="icon-text">
                  <img
                    src="assets/iconos/tracking_60px.png"
                    (click)="openModal(detailTemplate, envio)"
                    alt=""
                    style="width: 20px; height: 20px"
                    title="Tracking"
                  />
                  <mat-icon
                    *ngIf="puedeSubirDocumentacion(envio)"
                    src="assets/iconos/tracking_60px.png"
                    alt=""
                    (click)="openModal(subirDocumentacionModal, envio)"
                    title="{{ 'SubidaDocumentacion' | translate }}"
                    >file_upload</mat-icon
                  >
                  <img
                    src="assets/iconos/copy_52px.png"
                    style="
                      padding: auto;
                      margin: 5px;
                      width: 16px;
                      height: 16px;
                    "
                    alt=""
                    (click)="
                      copiarEnvio(
                        envio.Partida,
                        envio.Expedicion,
                        getArea(envio.IdArea)
                      )
                    "
                    title="Duplicar"
                  />
                  <mat-icon
                    *ngIf="envio.HayIncidencias"
                    class="orange"
                    (click)="openModal(incidenciasPartidaModal, envio)"
                    >warning</mat-icon
                  >
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-5">
    <div
      class="d-flex justify-content-between pt0punto5rem"
      style="text-align: center"
    >
      <ngb-pagination
        [(page)]="pageActual"
        [pageSize]="sizePage"
        [collectionSize]="total"
        [maxSize]="3"
        [rotate]="true"
      >
      </ngb-pagination>
    </div>
  </div>
  <div class="col-1"></div>
  <div class="col-6">
    <div class="row mb10px mt1 pr15px">
      <div class="col-12 col-sm-12 border1px mb20px contenedor">
        <h5>{{ "LeyendaTitle" | translate }}</h5>
        <div class="row">
          <div class="col-1">
            <div class="w35px h12px border1px mt4px fondoVerde"></div>
          </div>
          <div class="col-1">
            <i class="material-icons cursorNomal">arrow_right_alt</i>
          </div>
          <div class="col-2 pl0 mt35px">
            {{ "AdelantoLabel" | translate }}
          </div>
          <div class="col-1">
            <div class="w35px h12px border1px mt4px fondoRojo"></div>
          </div>
          <div class="col-1">
            <i class="material-icons cursorNomal">arrow_right_alt</i>
          </div>
          <div class="col-2 pl0 mt35px">
            {{ "AtrasoLabel" | translate }}
          </div>
          <div class="col-1">
            <div class="w35px h12px border1px mt4px"></div>
          </div>
          <div class="col-1">
            <i class="material-icons cursorNomal">arrow_right_alt</i>
          </div>
          <div class="col-2 pl0 mt35px">
            {{ "NoAtrasoLabel" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #detailTemplate>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 *ngIf="!isTerrestre()" class="modal-title pull-left">
        {{ "Detalles" | translate }}
        {{
          shipmentService.envio.RefCliente +
            " / " +
            shipmentService.envio.Partida +
            " - " +
            shipmentService.envio.Viaje
        }}
      </h2>
      <h2 *ngIf="isTerrestre()" class="modal-title pull-left">
        {{ "Detalles" | translate }}
        {{
          shipmentService.envio.RefCliente +
            " / " +
            shipmentService.envio.Expedicion
        }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-shipping-tracking (close)="cerrar($event)"></app-shipping-tracking>
    </div>
  </div>
</ng-template>

<ng-template #documentsTemplate>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{ "Documentos" | translate }}</h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-shipping-files (close)="cerrar($event)"></app-shipping-files>
    </div>
  </div>
</ng-template>

<ng-template #subirDocumentacionModal>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Adjuntar Archivo" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-upload-file
        [documentacion]="null"
        (listFiles)="receiveFiles($event)"
        [envio]="null"
      ></app-upload-file>
    </div>
  </div>
</ng-template>

<ng-template #incidenciasPartidaModal>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Incidencias " | translate }}{{ referencia }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-incidencias-partida
        (close)="cerrar($event)"
        [referencia]="referencia"
      ></app-incidencias-partida>
    </div>
  </div>
</ng-template>
