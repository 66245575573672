<div>
  <app-header></app-header>
  <div class="container-fluid">
    <div class="container">
      <form #formEnvioMaritimo="ngForm" (ngSubmit)="envioMaritimo(formEnvioMaritimo)">
        <div class="form-group">
          <!-- BLOQUE ESTATICO -->
          <div id="bloqueestatico" style="margin-top: 20px">
            <!-- SEGUNDO BLOQUE-->

            <div id="plantillas" class="row" style="margin-bottom: 20px">
              <div class="col-sm-3">
                <button type="button" class="btn" outofocus="on" (click)="openModal(templatePlantillas)"
                  [disabled]="visor">
                  {{ "Cargar Plantillas" | translate }}
                </button>
              </div>
            </div>
            <div id="segundobloque" class="row">
              <div class="col-sm-3">
                <label>{{ "Tipo Envio" | translate }}*</label>
                <ng-select name="TipoEnvio" id="TipoEnvio" (change)="setTipoEnvio($event)"
                  [ngClass]="{ error: errorTipoEnvio }" #TipoEnvio="ngModel" [items]="listTipoEnvio"
                  bindLabel="TipoEnvio" bindValue="IdTipoEnvio" [readonly]="visor || isCotizacion || esTarifa"
                  (focusout)="compruebaTipoEnvio(formEnvioMaritimo)" [(ngModel)]="formModelMaritimo.value.TipoEnvio"
                  aria-required="true">
                </ng-select>
                <div *ngIf="errorTipoEnvio" class="text-danger" style="font-size: small">
                  {{ "Tipo envio obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-3">
                <label>{{ "Modo Envio" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoModoEnvioMaritimo' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <ng-select name="ModoEnvio" id="ModoEnvio" (change)="setModoEnvio($event)"
                  [ngClass]="{ error: errorModoEnvio }" #ModoEnvio="ngModel" [items]="listModoEnvio"
                  bindLabel="ModoEnvio" bindValue="IdModoEnvio" [readonly]="visor || isCotizacion || esTarifa"
                  (focusout)="compruebaModoEnvio(formEnvioMaritimo)" [(ngModel)]="formModelMaritimo.value.ModoEnvio"
                  aria-required="true">
                </ng-select>
                <div *ngIf="errorModoEnvio" class="text-danger" style="font-size: small">
                  {{ "Modo envio obligatorio" | translate }}
                </div>
              </div>
            </div>
            <div id="identificativo" class="row" style="margin-top: 20px">
              <div class="col-sm-3">
                <label>{{ "Cliente" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoCliente' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input type="text" name="Cliente" style="margin-bottom: 20px" class="form-control" autocomplete="off"
                  [(ngModel)]="formModelMaritimo.value.Cliente.NombreFiscal" readonly />
              </div>
              <div class="col-sm-3">
                <label>{{ "Ref. Cliente" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoReferencia' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" #Referencia="ngModel" type="text" id="Referencia" name="Referencia" [ngClass]="{
                    'form-control-custom-error': errorReferencia,
                    'form-control': !errorReferencia
                  }" (focusout)="
                    compruebaReferencia(formEnvioMaritimo);
                    replicaReferencias(formEnvioMaritimo);
                    compruebaDuplicadoReferenciaCliente()
                  " [(ngModel)]="formModelMaritimo.value.Referencia" [readonly]="visor" required />
                <div *ngIf="
                    errorReferencia ||
                    (formEnvioMaritimo.submitted &&
                      (Referencia.value == null || Referencia.value == '')) ||
                    (Referencia.touched &&
                      (Referencia.value == null || Referencia.value == ''))
                  " class="text-danger" style="margin-bottom: 20px; font-size: small">
                  {{ "Referencia obligatorio" | translate }}
                </div>
              </div>
              <div *ngIf="isCotizacion" class="col-sm-3">
                <label>{{ "Ref. Cotizacion" | translate }}</label>
                <input type="text" name="refCotizacion" style="margin-bottom: 20px" class="form-control"
                  autocomplete="off" [(ngModel)]="formModelMaritimo.value.RefCotizacion" readonly />
              </div>
            </div>
            <!-- TERCER BLOQUE -->
            <div id="tercerbloque" class="row">
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-10">
                    <label>{{ "Remitente" | translate }}*</label><sup><span mat-raised-button
                        matTooltip="{{ 'infoRemitente' | translate }}" matTooltipClass="my-custom-tooltip"><img
                          src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                    <ng-select name="ListShipper" id="ListShipper" [ngClass]="{ error: errorListShipper }"
                      #ListShipper="ngModel" [items]="listShipper" bindLabel="NombreFiscal" [readonly]="visor"
                      [(ngModel)]="formModelMaritimo.value.Shipper" style="margin-bottom: 20px"
                      (change)="setShipperInformation()" (focusout)="compruebaListShipper(formEnvioMaritimo)">
                    </ng-select>
                    <textarea name="Shipper" id="Shipper" cols="30" rows="4" class="form-control-custom" [(ngModel)]="
                        this.formModelMaritimo.value.DireccionShipper
                      " [readonly]="!readonlyDireccionShipper"></textarea>
                    <div *ngIf="
                        errorListShipper ||
                        (formEnvioMaritimo.submitted &&
                          (ListShipper.value == null ||
                            ListShipper.value == '')) ||
                        (ListShipper.touched &&
                          (ListShipper.value == null ||
                            ListShipper.value == ''))
                      " class="text-danger" style="margin-bottom: 20px; font-size: small">
                      {{ "Remitente obligatorio" | translate }}
                    </div>
                    <mat-checkbox name="chShipper" class="example-margin" (change)="
                        cambiarEstadoDireccion($event.checked, 'shipper')
                      " [(ngModel)]="chShipper">
                      {{ "Modificar direccion" | translate }}</mat-checkbox>
                  </div>
                  <div class="col-sm-2">
                    <label style="margin-bottom: 26px"></label>
                    <button type="button" title="{{ 'Buscar entidad' | translate }}" class="btn float-right"
                      style="margin-bottom: 10px" (click)="openModal2(templateSearchEntity, 'shipper')"
                      [disabled]="visor">
                      <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px" alt="" />
                    </button>
                    <button type="button" title="{{ 'Añadir entidad' | translate }}" class="btn float-right"
                      (click)="openModal2(entityTemplate, 'shipper')" [disabled]="visor">
                      <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px" alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-10">
                    <label>{{ "Destinatario" | translate }}*</label><sup><span mat-raised-button
                        matTooltip="{{ 'infoDestinatario' | translate }}" matTooltipClass="my-custom-tooltip"><img
                          src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                    <ng-select name="ListConsignee" id="ListConsignee" [ngClass]="{ error: errorListConsignee }"
                      #ListConsignee="ngModel" [items]="listConsignee" bindLabel="NombreFiscal" [readonly]="visor"
                      [(ngModel)]="formModelMaritimo.value.Consignee" style="margin-bottom: 20px"
                      (change)="setConsigneeInformation()" (focusout)="compruebaListConsignee(formEnvioMaritimo)"
                      [loading]="cargaDestinatario" loadingText="Cargando...">
                    </ng-select>
                    <textarea name="Consignee" id="Consignee" cols="30" rows="4" class="form-control-custom"
                      [(ngModel)]="
                        this.formModelMaritimo.value.DireccionConsignee
                      " [readonly]="!readonlyDireccionConsignee"></textarea>
                    <div *ngIf="
                        errorListConsignee ||
                        (formEnvioMaritimo.submitted &&
                          (ListConsignee.value == null ||
                            ListConsignee.value == '')) ||
                        (ListConsignee.touched &&
                          (ListConsignee.value == null ||
                            ListConsignee.value == ''))
                      " class="text-danger" style="margin-bottom: 20px; font-size: small">
                      {{ "Destinatario obligatorio" | translate }}
                    </div>
                    <mat-checkbox name="chConsignee" class="example-margin" (change)="
                        cambiarEstadoDireccion($event.checked, 'consignee')
                      " [(ngModel)]="chConsignee">
                      {{ "Modificar direccion" | translate }}</mat-checkbox>
                  </div>
                  <div class="col-sm-2">
                    <!-- Modal de busqueda -->
                    <label style="margin-bottom: 26px"></label>
                    <button type="button" title="{{ 'Buscar entidad' | translate }}" class="btn float-right"
                      style="margin-bottom: 10px" (click)="openModal2(templateSearchEntity, 'consignee')"
                      [disabled]="visor">
                      <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px" alt="" />
                    </button>
                    <button type="button" title="{{ 'Añadir entidad' | translate }}" class="btn float-right"
                      (click)="openModal2(entityTemplate, 'consignee')" [disabled]="visor">
                      <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px" alt="" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-10">
                    <label>{{ "Notificar" | translate }}</label><sup><span mat-raised-button
                        matTooltip="{{ 'infoNotify' | translate }}" matTooltipClass="my-custom-tooltip"><img
                          src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                    <ng-select name="ListNotify" id="ListNotify" class="select-small" #ListNotify="ngModel"
                      [items]="listNotify" bindLabel="NombreFiscal" [readonly]="visor"
                      [(ngModel)]="formModelMaritimo.value.Notify" style="margin-bottom: 20px"
                      (change)="setNotifyInformation()">
                    </ng-select>
                    <textarea name="Notify" id="Notify" cols="30" rows="4" style="margin-right: 10px; resize: none"
                      [(ngModel)]="this.formModelMaritimo.value.DireccionNotify" class="form-control-custom"
                      [readonly]="!readonlyDireccionNotify"></textarea>
                    <mat-checkbox name="chNotify" class="example-margin" (change)="
                        cambiarEstadoDireccion($event.checked, 'notify')
                      " [(ngModel)]="chNotify">
                      {{ "Modificar direccion" | translate }}</mat-checkbox>
                  </div>
                  <div class="col-sm-2">
                    <!-- Modal de busqueda -->
                    <label style="margin-bottom: 26px"></label>
                    <button type="button" title="{{ 'Buscar entidad' | translate }}" class="btn float-right"
                      style="margin-bottom: 10px" (click)="openModal2(templateSearchEntity, 'notify')"
                      [disabled]="visor">
                      <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px" alt="" />
                    </button>
                    <button type="button" title="{{ 'Añadir entidad' | translate }}" class="btn float-right"
                      (click)="openModal2(entityTemplate, 'notify')" [disabled]="visor">
                      <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- CUARTO BLOQUE -->
            <div id="cuartobloque" class="row">
              <div class="col-sm-2">
                <label>Incoterm*</label><sup><span mat-raised-button matTooltip="{{ 'infoIncoterm' | translate }}"
                    matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                      style="width: 12px; height: 12px" /></span></sup>
                <ng-select name="Incoterm" id="Incoterm" class="select-small" #Incoterm="ngModel" [items]="listIncoterm"
                  bindLabel="IdIncoterm" bindValue="IdIncoterm" style="width: 70%" [readonly]="visor || isCotizacion"
                  [(ngModel)]="formModelMaritimo.value.Incoterm" (focusout)="compruebaIncoterm(formEnvioMaritimo)"
                  [ngClass]="{ error: errorIncoterm }">
                </ng-select>
                <div *ngIf="
                    errorIncoterm ||
                    (formEnvioMaritimo.submitted &&
                      (Incoterm.value == null || Incoterm.value == '')) ||
                    (Incoterm.touched &&
                      (Incoterm.value == null || Incoterm.value == ''))
                  " class="text-danger" style="margin-bottom: 20px; font-size: small">
                  Incoterm {{ "obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <label>{{ "Valor Mercancia" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoValorMercancia' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" id="ValorMercancia" name="ValorMercancia" type="text" [ngClass]="{
                    'form-control-custom-error': errorValorMercancia,
                    'form-control': !errorValorMercancia
                  }" #ValorMercancia="ngModel" (focusout)="compruebaValorMercancia(formEnvioMaritimo)"
                  (change)="calcularSeguro()" [(ngModel)]="formModelMaritimo.value.ValorMercancia" [readonly]="visor"
                  required />
                <div *ngIf="
                    errorValorMercancia ||
                    (formEnvioMaritimo.submitted &&
                      (ValorMercancia.value == null ||
                        ValorMercancia.value == '')) ||
                    (ValorMercancia.touched &&
                      (ValorMercancia.value == null ||
                        ValorMercancia.value == ''))
                  " class="text-danger" style="margin-bottom: 20px; font-size: small">
                  {{ "Valor de la mercancia obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <label>{{ "Divisa" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoDivisa' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <ng-select name="TipoDivisa" id="TipoDivisa" class="select-small" #TipoDivisa="ngModel"
                  [readonly]="visor" bindLabel="TipoDivisa" bindValue="IdTipoDivisa" [items]="listDivisas"
                  [(ngModel)]="formModelMaritimo.value.TipoDivisa">
                </ng-select>
              </div>
              <div class="col-sm-2" *ngIf="esEspanyaItalia()">
                <label>{{ "Coste del Seguro" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoSeguro' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input id="SeguroMercancia" name="SeguroMercancia" type="number" style="margin-bottom: 20px"
                  class="form-control-custom" #SeguroMercancia="ngModel"
                  [(ngModel)]="formModelMaritimo.value.SeguroMercancia" readonly />
              </div>
              <div class="col-sm-2" *ngIf="esEspanyaItalia()">
                <label>{{
                  "¿Quiere asegurar su mercancia?" | translate
                  }}</label>
                <mat-radio-group name="Asegurado" #Asegudaro="ngModel" [(ngModel)]="formModelMaritimo.value.Asegurado"
                  aria-label="¿Desea asegurar su carga?" [disabled]="visor">
                  <mat-radio-button [value]="true" [disabled]="visor">{{
                    "Si" | translate
                    }}</mat-radio-button>
                  <mat-radio-button [value]="false" [disabled]="visor">{{
                    "No" | translate
                    }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <!-- BLOQUE DINAMICO-->
          <div id="bloquedinamico">
            <!-- QUINTO BLOQUE -->
            <div id="quintobloque" class="row">
              <div class="col-sm-3">
                <label>{{ "Puerto Origen" | translate }}</label>
                <ng-select name="PuertoOrigen" id="PuertoOrigen" class="select-small" #PuertoOrigen="ngModel"
                  [readonly]="
                    visor || isCotizacion || (esTarifa && haypuertoOrigen)
                  " [(ngModel)]="formModelMaritimo.value.PuertoOrigen" style="margin-bottom: 20px">
                  <ng-option *ngFor="let p of listPuertoOrigen" [value]="p.IdPuerto">
                    <img class="bandera" alt="b" src="{{ getBandera(p) }}" />&nbsp;&nbsp;{{ p.Nombre }}
                    <!--&nbsp;-->
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-sm-3">
                <label>{{ "Puerto Destino" | translate }}</label>
                <ng-select name="PuertoDestino" id="PuertoDestino" class="select-small" #PuertoDestino="ngModel"
                  [readonly]="
                    visor || isCotizacion || (esTarifa && !haypuertoOrigen)
                  " [(ngModel)]="formModelMaritimo.value.PuertoDestino" style="margin-bottom: 20px">
                  <ng-option *ngFor="let p of listPuertoDestino" [value]="p.IdPuerto">
                    <img class="bandera" alt="b" src="{{ getBandera(p) }}" />&nbsp;&nbsp;{{ p.Nombre }}
                    <!--&nbsp;-->
                  </ng-option>
                </ng-select>
              </div>
              <div class="col-sm-3" *ngIf="esFCL">
                <label>{{ "Naviera" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoNaviera' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input type="text" name="Naviera" id="Naviera" [readonly]="visor || isCotizacion" class="form-control"
                  #Naviera [(ngModel)]="this.formModelMaritimo.value.Naviera" style="margin-bottom: 20px" />
              </div>
              <div class="col-sm-3" *ngIf="!esFCL">
                <label>{{ "NavieraLcl" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoNaviera' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input type="text" name="Naviera" id="Naviera" [readonly]="visor || isCotizacion" class="form-control"
                  #Naviera [(ngModel)]="this.formModelMaritimo.value.Naviera" style="margin-bottom: 20px" />
              </div>
            </div>
          </div>

          <!-- SEPTIMO BLOQUE -->
          <div id="Recogida" class="contenedor">
            <h5>{{ getTituloRecogida() }}</h5>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-3">
                <label>{{ "Entidad" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoEntidadCarga' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <ng-select name="EntidadRecogida" id="EntidadRecogida" [ngClass]="{ error: errorEntidadRecogida }"
                  #EntidadRecogida="ngModel" [items]="listShipper"
                  (focusout)="compruebaEntidadRecogida(formEnvioMaritimo)" bindLabel="NombreFiscal" [readonly]="visor"
                  [(ngModel)]="formModelMaritimo.value.EntidadRecogida" (change)="setRecogidaInformation()"
                  style="margin-bottom: 20px">
                </ng-select>
                <div *ngIf="
                    errorEntidadRecogida ||
                    (formEnvioMaritimo.submitted &&
                      (EntidadRecogida.value == null ||
                        EntidadRecogida.value == '')) ||
                    (EntidadRecogida.touched &&
                      (formEnvioMaritimo.value == null ||
                        EntidadRecogida.value == ''))
                  " class="text-danger" style="margin-bottom: 10px; font-size: small">
                  {{ "Entidad obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <!-- Al clicar abre modal para seleccionar entidad con direccion -->
                <button title="{{ 'Buscar entidad' | translate }}" type="button" class="btn"
                  style="margin-top: 28px; margin-right: 10px" (click)="openModal2(templateSearchEntity, 'recogida')"
                  [disabled]="visor">
                  <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px" alt="" />
                </button>
                <button title="{{ 'Añadir entidad' | translate }}" type="button" class="btn" style="margin-top: 28px"
                  (click)="openModal2(entityTemplate, 'recogida')" [disabled]="visor">
                  <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px" alt="" />
                </button>
              </div>
              <div class="col-sm-4">
                <label>{{ "Referencia de Carga" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoReferenciaCarga' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="text" id="ReferenciaRecogida" name="ReferenciaRecogida"
                  [readonly]="visor" [ngClass]="{
                    'form-control-custom-error': errorReferenciaRecogida,
                    'form-control': !errorReferenciaRecogida
                  }" #ReferenciaRecogida="ngModel" (focusout)="compruebaReferenciaRecogida(formEnvioMaritimo)"
                  style="margin-bottom: 5px" [(ngModel)]="formModelMaritimo.value.ReferenciaRecogida" required />
                <div style="height: 36px; margin-bottom: 5px; font-size: small" *ngIf="
                    errorReferenciaRecogida ||
                    (formEnvioMaritimo.submitted &&
                      (ReferenciaRecogida.value == null ||
                        ReferenciaRecogida.value == '')) ||
                    (ReferenciaRecogida.touched &&
                      (ReferenciaRecogida.value == null ||
                        ReferenciaRecogida.value == ''))
                  " class="text-danger">
                  {{ "Referencia de carga obligatoria" | translate }}
                </div>
              </div>
            </div>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-4">
                <label>{{ "Dirección Recogida" | translate }}</label>
                <textarea autocomplete="off" class="form-control-custom" name="ResumenDireccionRecogida"
                  id="ResumenDireccionRecogida" rows="4" readonly style="margin-right: 10px"
                  [(ngModel)]="resumenDireccionRecogida"></textarea>
              </div>
              <div class="col-sm-7">
                <label>{{ "Instrucciones Recogida" | translate }}</label>
                <textarea autocomplete="off" maxlength="{{ maxlengthInstrucctions }}" rows="4" type="text"
                  id="IntruccionesRecogida" name="InstruccionesRecogida" [readonly]="visor" class="form-control-custom"
                  #InstruccionesRecogida="ngModel" style="margin-bottom: 5px; width: 109%"
                  [(ngModel)]="formModelMaritimo.value.InstruccionesRecogida"></textarea>
                <span>{{ formModelMaritimo.value.InstruccionesRecogida.length }}/{{
                  maxlengthInstrucctions
                  }}</span>
              </div>
            </div>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-3">
                <label>{{ "Mercancia disponible desde" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoFechaRecogida' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" name="FechaRecogida" type="date" id="FechaRecogida" name="FechaRecogida"
                  min="{{ today | date : 'yyyy-MM-dd' }}" style="height: 36px; margin-bottom: 5px" [readonly]="visor"
                  [ngClass]="{
                    'form-control-custom-error':
                      errorFechaRecogida || errorFechaRecogidaMenorActual,
                    'form-control':
                      !errorFechaRecogida && !errorFechaRecogidaMenorActual
                  }" #FechaRecogida="ngModel" [(ngModel)]="formModelMaritimo.value.FechaRecogida"
                  (focusout)="compruebaFechaRecogida(formEnvioMaritimo)" required />
                <div style="height: 36px; margin-bottom: 5px; font-size: small" *ngIf="
                    errorFechaRecogida ||
                    (formEnvioMaritimo.submitted &&
                      (FechaRecogida.value == null ||
                        FechaRecogida.value == '')) ||
                    (FechaRecogida.touched &&
                      (FechaRecogida.value == null ||
                        FechaRecogida.value == ''))
                  " class="text-danger">
                  {{ "Fecha disponibilidad obligatoria" | translate }}
                </div>
                <div style="height: 36px; margin-bottom: 5px; font-size: small" min="{{ today | date : 'yyyy-MM-dd' }}"
                  *ngIf="
                    errorFechaRecogidaMenorActual ||
                    (formEnvioMaritimo.submitted &&
                      FechaRecogida.touched &&
                      validarFechaRecogida())
                  " class="text-danger">
                  {{ "Fecha disponibilidad inferior a la de hoy" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <label>{{ "Desde" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoDesde' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" name="HoraRecogidaInicio1" type="time" id="HoraRecogidaInicio1"
                  class="form-control" style="margin-bottom: 5px; width: 65%; height: 36px"
                  #HoraRecogidaInicio1="ngModel" [(ngModel)]="formModelMaritimo.value.HoraRecogidaInicio1"
                  [readonly]="visor" />
              </div>
              <div class="col-sm-2">
                <label>{{ "Hasta" | translate }}</label>
                <input autocomplete="off" name="HoraRecogidaFinal1" type="time" id="HoraRecogidaFinal1"
                  class="form-control" style="margin-bottom: 5px; width: 65%; height: 36px"
                  #HoraRecogidaFinal1="ngModel" [(ngModel)]="formModelMaritimo.value.HoraRecogidaFinal1"
                  [readonly]="visor" />
              </div>
              <div class="col-sm-2">
                <label>{{ "Desde" | translate }}</label>
                <input autocomplete="off" name="HoraRecogidaInicio2" type="time" id="HoraRecogidaInicio2"
                  class="form-control" style="
                    margin-bottom: 5px;
                    width: 65%;
                    height: 36px;
                    margin-left: 20px;
                  " #HoraRecogidaInicio2="ngModel" [(ngModel)]="formModelMaritimo.value.HoraRecogidaInicio2"
                  [readonly]="visor" />
              </div>
              <div class="col-sm-2">
                <label>{{ "Hasta" | translate }}</label>
                <input autocomplete="off" name="HoraRecogidaFinal2" type="time" id="HoraRecogidaFinal2"
                  class="form-control" style="margin-bottom: 5px; width: 65%; height: 36px"
                  #HoraRecogidaFinal2="ngModel" [(ngModel)]="formModelMaritimo.value.HoraRecogidaFinal2"
                  [readonly]="visor" />
              </div>
            </div>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-3">
                <label>{{ "Persona Contacto" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoPersonaContactoCarga' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="text" id="PersonaContactoRecogida" name="PersonaContactoRecogida"
                  [readonly]="visor" #PersonaContactoRecogida="ngModel" style="margin-bottom: 10px"
                  [(ngModel)]="formModelMaritimo.value.PersonaContactoRecogida" (focusout)="
                    compruebaPersonaContactoRecogida(formEnvioMaritimo)
                  " [ngClass]="{
                    'form-control-custom-error': errorPersonaContactoRecogida,
                    'form-control-custom': !errorPersonaContactoRecogida
                  }" [required]="recogidaRequired(formEnvioMaritimo)" />
                <div *ngIf="
                    errorPersonaContactoRecogida ||
                    (errorPersonaContactoRecogida &&
                      formEnvioMaritimo.submitted &&
                      (PersonaContactoRecogida.value == null ||
                        PersonaContactoRecogida.value == '')) ||
                    (errorPersonaContactoRecogida &&
                      PersonaContactoRecogida.touched &&
                      (formEnvioMaritimo.value == null ||
                        PersonaContactoRecogida.value == ''))
                  " class="text-danger" style="margin-bottom: 10px; font-size: small">
                  {{
                  "Persona de contacto de recogida obligatorio" | translate
                  }}
                </div>
              </div>
              <div class="col-sm-3">
                <label>{{ "Teléfono" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoTelefonoCarga' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="text" id="TelefonoRecogida" name="TelefonoRecogida" [readonly]="visor"
                  #TelefonoRecogida="ngModel" style="margin-bottom: 10px"
                  [(ngModel)]="formModelMaritimo.value.TelefonoRecogida"
                  (focusout)="compruebaTelefonoRecogida(formEnvioMaritimo)" [ngClass]="{
                    'form-control-custom-error': errorTelefonoRecogida,
                    'form-control-custom': !errorTelefonoRecogida
                  }" [required]="recogidaRequired(formEnvioMaritimo)" />
                <div *ngIf="
                    errorTelefonoRecogida ||
                    (errorTelefonoRecogida &&
                      formEnvioMaritimo.submitted &&
                      (TelefonoRecogida.value == null ||
                        TelefonoRecogida.value == '')) ||
                    (errorTelefonoRecogida &&
                      TelefonoRecogida.touched &&
                      (formEnvioMaritimo.value == null ||
                        TelefonoRecogida.value == ''))
                  " class="text-danger" style="margin-bottom: 10px; font-size: small">
                  {{
                  "Teléfono de contacto de recogida obligatorio" | translate
                  }}
                </div>
              </div>
              <div class="col-sm-3">
                <label>{{ "Email" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoEmailCarga' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="email" id="EmailRecogida" name="EmailRecogida" [readonly]="visor"
                  #EmailRecogida="ngModel" style="margin-bottom: 10px"
                  [(ngModel)]="formModelMaritimo.value.EmailRecogida"
                  (focusout)="compruebaEmailRecogida(formEnvioMaritimo)" [ngClass]="{
                    'form-control-custom-error': errorEmailRecogida,
                    'form-control': !errorEmailRecogida
                  }" [required]="recogidaRequired(formEnvioMaritimo)" />
                <div *ngIf="
                    errorEmailRecogida ||
                    (errorEmailRecogida &&
                      formEnvioMaritimo.submitted &&
                      (EmailRecogida.value == null ||
                        EmailRecogida.value == '')) ||
                    (errorEmailRecogida &&
                      EmailRecogida.touched &&
                      (formEnvioMaritimo.value == null ||
                        EmailRecogida.value == ''))
                  " class="text-danger" style="margin-bottom: 10px; font-size: small">
                  {{ "Email de contacto de recogida obligatorio" | translate }}
                </div>
              </div>
            </div>
          </div>

          <div id="DespachoAduanas" class="contenedor" hidden>
            <h5>{{ "DespachoAduanas" | translate }}</h5>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-3">
                <label>{{ "DespachoOrigen" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoDespachoOrigen' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input id="DespachoOrigen" name="DespachoOrigen" type="text" autocomplete="off" [readonly]="visor"
                  #DespachoOrigen style="margin-bottom: 10px" [(ngModel)]="formModelMaritimo.value.DespachoOrigen"
                  class="form-control" />
              </div>
              <div class="col-sm-3" *ngIf="
                  (formModelMaritimo.value.TipoEnvio == 1 &&
                    formModelMaritimo.value.Incoterm == 'DDP') ||
                  formModelMaritimo.value.TipoEnvio == 4
                ">
                <label>{{ "DespachoDestino" | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoDespachoDestino' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input id="DespachoDestino" name="DespachoDestino" type="text" autocomplete="off" [readonly]="visor"
                  #DespachoDestino style="margin-bottom: 10px" [(ngModel)]="formModelMaritimo.value.DespachoDestino"
                  class="form-control" />
              </div>
            </div>
          </div>

          <div id="Entrega" class="contenedor" *ngIf="
              incotermStartWith() || formModelMaritimo.value.TipoEnvio == 2
            ">
            <h5>{{ "Entrega" | translate }}</h5>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-3">
                <label>{{ "Entidad" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoEntidadEntrega' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <ng-select name="EntidadEntrega" id="EntidadEntrega"
                  (focusout)="compruebaEntidadEntrega(formEnvioMaritimo)" [ngClass]="{
                    error: errorEntidadEntrega && incotermStartWith()
                  }" #EntidadEntrega="ngModel" [items]="listShipper" bindLabel="NombreFiscal" [readonly]="visor"
                  [(ngModel)]="formModelMaritimo.value.EntidadEntrega" style="margin-bottom: 10px"
                  (change)="setEntregaInformation()">
                </ng-select>
                <div *ngIf="
                    errorEntidadEntrega ||
                    (formEnvioMaritimo.submitted &&
                      (EntidadEntrega.value == null ||
                        EntidadEntrega.value == '')) ||
                    (EntidadEntrega.touched &&
                      (formEnvioMaritimo.value == null ||
                        EntidadEntrega.value == ''))
                  " class="text-danger" style="margin-bottom: 10px; font-size: small">
                  {{ "Entidad de entrega obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <button title="{{ 'Buscar entidad' | translate }}" type="button" class="btn"
                  style="margin-top: 28px; margin-right: 10px" (click)="openModal2(templateSearchEntity, 'entrega')"
                  [disabled]="visor">
                  <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px" alt="" />
                </button>
                <button title="{{ 'Añadir entidad' | translate }}" type="button" class="btn" style="margin-top: 28px"
                  (click)="openModal2(entityTemplate, 'entrega')" [disabled]="visor">
                  <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px" alt="" />
                </button>
              </div>
              <div class="col-sm-4">
                <label>{{ "Referencia Entrega" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoReferenciaEntrega' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="text" id="ReferenciaEntrega" name="ReferenciaEntrega" [readonly]="visor"
                  [ngClass]="{
                    'form-control-custom-error': errorReferenciaEntrega,
                    'form-control': !errorReferenciaEntrega
                  }" #ReferenciaEntrega="ngModel" style="margin-bottom: 5px"
                  [(ngModel)]="formModelMaritimo.value.ReferenciaEntrega"
                  (focusout)="compruebaReferenciaEntrega(formEnvioMaritimo)" required />
                <div *ngIf="
                    errorReferenciaEntrega ||
                    (formEnvioMaritimo.submitted &&
                      (EntidadEntrega.value == null ||
                        EntidadEntrega.value == '')) ||
                    (EntidadEntrega.touched &&
                      (formEnvioMaritimo.value == null ||
                        EntidadEntrega.value == ''))
                  " class="text-danger" style="margin-bottom: 10px; font-size: small">
                  {{ "Referencia de entrega obligatorio" | translate }}
                </div>
              </div>
            </div>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-4">
                <label>{{ "Dirección Entrega" | translate }}</label>
                <textarea autocomplete="off" class="form-control-custom" name="ResumenDireccionEntrega"
                  id="ResumenDireccionEntrega" rows="4" style="margin-right: 10px" [(ngModel)]="resumenDireccionEntrega"
                  readonly></textarea>
              </div>
              <div class="col-sm-7">
                <label>{{ "Instrucciones Entrega" | translate }}</label>
                <textarea autocomplete="off" rows="4" type="text" id="IntruccionesEntrega" name="InstruccionesEntrega"
                  [readonly]="visor" class="form-control-custom" #InstruccionesEntrega="ngModel"
                  style="margin-bottom: 5px; width: 109%" maxlength="{{ maxlengthInstrucctions }}"
                  [(ngModel)]="formModelMaritimo.value.InstruccionesEntrega"></textarea>
                <span>{{ formModelMaritimo.value.InstruccionesEntrega.length }}/{{
                  maxlengthInstrucctions
                  }}</span>
              </div>
            </div>
            <div class="row" style="margin-left: 1px">
              <div class="col-sm-3">
                <label>{{ "Persona Contacto" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoPersonaContactoEntrega' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="text" id="PersonaContactoEntrega" name="PersonaContactoEntrega"
                  [readonly]="visor" [ngClass]="{
                    'form-control-custom-error': errorPersonaContactoEntrega,
                    'form-control-custom': !errorPersonaContactoEntrega
                  }" #PersonaContactoEntrega="ngModel" (focusout)="
                    compruebaPersonaContactoEntrega(formEnvioMaritimo)
                  " style="margin-bottom: 10px" [(ngModel)]="formModelMaritimo.value.PersonaContactoEntrega"
                  [required]="incotermStartWith()" />
                <div *ngIf="
                    formModelMaritimo.value.TipoEnvio == 1 &&
                    incotermStartWith()
                  ">
                  <div *ngIf="
                      errorPersonaContactoEntrega ||
                      (errorPersonaContactoEntrega &&
                        formEnvioMaritimo.submitted &&
                        (PersonaContactoEntrega.value == null ||
                          PersonaContactoEntrega.value == '')) ||
                      (errorPersonaContactoEntrega &&
                        PersonaContactoEntrega.touched &&
                        (formEnvioMaritimo.value == null ||
                          PersonaContactoEntrega.value == ''))
                    " class="text-danger" style="margin-bottom: 20px; font-size: small">
                    {{
                    "Persona de contacto de entrega obligatorio" | translate
                    }}
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <label>{{ "Teléfono" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoTelefonoEntrega' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="text" id="TelefonoEntrega" name="TelefonoEntrega" [readonly]="visor"
                  [ngClass]="{
                    'form-control-custom-error': errorTelefonoEntrega,
                    'form-control-custom': !errorTelefonoEntrega
                  }" #TelefonoEntrega="ngModel" style="margin-bottom: 10px"
                  [(ngModel)]="formModelMaritimo.value.TelefonoEntrega"
                  (focusout)="compruebaTelefonoEntrega(formEnvioMaritimo)" [required]="incotermStartWith()" />
                <div *ngIf="
                    formModelMaritimo.value.TipoEnvio == 1 &&
                    incotermStartWith()
                  ">
                  <div *ngIf="
                      errorTelefonoEntrega ||
                      (errorTelefonoEntrega &&
                        formEnvioMaritimo.submitted &&
                        (TelefonoEntrega.value == null ||
                          TelefonoEntrega.value == '')) ||
                      (errorTelefonoEntrega &&
                        TelefonoEntrega.touched &&
                        (formEnvioMaritimo.value == null ||
                          TelefonoEntrega.value == ''))
                    " class="text-danger" style="margin-bottom: 10px; font-size: small">
                    {{
                    "Teléfono de contacto de entrega obligatorio" | translate
                    }}
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <label>{{ "Email" | translate }}*</label><sup><span mat-raised-button
                    matTooltip="{{ 'infoEmailEntrega' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
                <input autocomplete="off" type="email" id="EmailEntrega" name="EmailEntrega" [readonly]="visor"
                  [ngClass]="{
                    'form-control-custom-error': errorEmailEntrega,
                    'form-control': !errorEmailEntrega
                  }" #EmailEntrega="ngModel" style="margin-bottom: 10px"
                  [(ngModel)]="formModelMaritimo.value.EmailEntrega"
                  (focusout)="compruebaEmailEntrega(formEnvioMaritimo)" [required]="incotermStartWith()" />
                <div *ngIf="
                    formModelMaritimo.value.TipoEnvio == 1 &&
                    incotermStartWith()
                  ">
                  <div *ngIf="
                      errorEmailEntrega ||
                      (errorEmailEntrega &&
                        formEnvioMaritimo.submitted &&
                        (EmailEntrega.value == null ||
                          EmailEntrega.value == '')) ||
                      (errorEmailEntrega &&
                        EmailEntrega.touched &&
                        (formEnvioMaritimo.value == null ||
                          EmailEntrega.value == ''))
                    " class="text-danger" style="margin-bottom: 10px; font-size: small">
                    {{ "Email de contacto de entrega obligatorio" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 
                <div id="mercanciaPeligrosa" class="row">
                    <div class="col-sm-2">
                        <div class="form-check" style="margin-top: 20px;">
                            <input name="MercanciaPeligrosa" type="checkbox" class="form-check-input" style="margin-bottom: 20px;" 
                            id="checkMercanciaPeligrosa" #MercanciaPeligrosa="ngModel" [(ngModel)]="formModelMaritimo.value.MercanciaPeligrosa" 
                            [disabled]="visor || isCotizacion" (click)="setMercPeligrosa(formEnvioMaritimo);" (change)="calcularSeguro()">
                            <label class="form-check-label" style="margin-bottom: 20px;" for="MercanciaPeligrosa">{{'Mercancia peligrosa'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check" style="margin-top: 20px;">
                            <input name="NoRemontable" type="checkbox" class="form-check-input" style="margin-bottom: 20px;" 
                                id="checkRemontable" #NoRemontable="ngModel" [(ngModel)]="formModelMaritimo.value.NoRemontable" 
                                [disabled]="visor || isCotizacion" (change)="setNoRemontable($event)">
                            <label class="form-check-label" style="margin-bottom: 20px;" for="MercanciaNoRemontable">{{'MercNoRemontable'|translate}}</label>
                        </div>
                    </div>
                </div> -->

          <div class="row">
            <div class="col-12">
              <div class="contenedor">
                <h5>{{ "VGM" | translate }}</h5>
                <div class="row mlauto mb1coma7 mr0coma1">
                  <div class="col-12 col-xl-6">
                    <div class="row">
                      <div class="col-12">
                        <label>{{ "VGMPesaje" | translate }}*</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 ml0 pl0">
                        <mat-radio-group name="VGMPesaje" #VGMPesaje [(ngModel)]="formModelMaritimo.value.VGMPesaje"
                          id="VGMPesaje" [disabled]="visor || isCotizacion" aria-label="VGMPesaje">
                          <mat-radio-button [value]="true" [disabled]="visor">{{
                            "Expedidor" | translate
                            }}</mat-radio-button>
                          <mat-radio-button [value]="false" [disabled]="visor">{{ "XGL" | translate
                            }}</mat-radio-button>
                        </mat-radio-group>
                        <div style="
                            height: 36px;
                            margin-bottom: 5px;
                            font-size: small;
                          " *ngIf="
                            (formEnvioMaritimo.submitted &&
                              (VGMPesaje.value == null ||
                                VGMPesaje.value == undefined)) ||
                            (VGMPesaje.touched &&
                              (VGMPesaje.value == null ||
                                VGMPesaje.value == undefined))
                          " class="text-danger">
                          {{ "VGMPesajeRequired" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="row">
                      <div class="col-12">
                        <label>{{ "VGMTransmision" | translate }}*</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <mat-radio-group name="VGMTransmision" #VGMTransmision
                          [(ngModel)]="formModelMaritimo.value.VGMTransmision" id="VGMTransmision"
                          [disabled]="visor || isCotizacion" aria-label="VGMTransmision">
                          <mat-radio-button [value]="true" [disabled]="visor">{{
                            "Expedidor" | translate
                            }}</mat-radio-button>
                          <mat-radio-button [value]="false" [disabled]="visor">{{ "XGL" | translate
                            }}</mat-radio-button>
                        </mat-radio-group>
                        <div style="
                            height: 36px;
                            margin-bottom: 5px;
                            font-size: small;
                          " *ngIf="
                            (formEnvioMaritimo.submitted &&
                              (VGMTransmision.value == null ||
                                VGMTransmision.value == undefined)) ||
                            (VGMTransmision.touched &&
                              (VGMTransmision.value == null ||
                                VGMTransmision.value == undefined))
                          " class="text-danger">
                          {{ "VGMTransmisionRequired" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- OCTAVO BLOQUE -->
          <div class="row" id="octavobloque">
            <div class="col-sm-12" *ngIf="listaMercancia != [] && listaMercancia != undefined">
              <app-cargo [listaMercancia]="listaMercancia" (listaAux)="listaMerc($event)"
                (listaAuxContenedor)="listaMercContenedor($event)" [visor]="visor" [isCotizacion]="isCotizacion"
                [fcl]="esFCL" [area]="formModelMaritimo.value.IdArea"
                [listaMercancia]="formModelMaritimo.value.Mercancia" [mercanciaPeligrosa]="
                  formModelMaritimo.value.MercanciaPeligrosa
                " [listaMercanciaContenedor]="
                  formModelMaritimo.value.MercanciaContenedor
                " [cliente]="formModelMaritimo.value.Cliente" [isTarifa]="esTarifa"></app-cargo>
            </div>
            <div class="col-sm-12" *ngIf="listaMercancia == [] || listaMercancia == undefined">
              <app-cargo (listaAux)="listaMerc($event)" (listaAuxContenedor)="listaMercContenedor($event)"
                [listaMercanciaContenedor]="
                  formModelMaritimo.value.MercanciaContenedor
                " [listaMercancia]="formModelMaritimo.value.Mercancia" [fcl]="esFCL" [isTarifa]="esTarifa"
                [cliente]="formModelMaritimo.value.Cliente" [visor]="visor" [isCotizacion]="isCotizacion">
              </app-cargo>
            </div>
          </div>
          <div id="Pesos" class="contenedor">
            <h5>{{ "Totales" | translate }}</h5>
            <!-- Totales N/A -->
            <div class="row" style="margin: 5px">
              <div class="col-sm-2">
                <label *ngIf="esLCL" class="totalizadores">{{ "Bultos Totales" | translate }}*</label>
                <label *ngIf="!esLCL" class="totalizadores">{{
                  "Bultos Totales" | translate
                  }}</label>
                <input autocomplete="off" name="BultosTotales" type="text"
                  [(ngModel)]="formModelMaritimo.value.BultosTotales" [ngClass]="{
                    'form-control-custom-error totalizadores':
                      errorBultosTotales,
                    'form-control totalizadores': !errorBultosTotales
                  }" #BultosTotales="ngModel" readonly required />
                <div *ngIf="
                    errorBultosTotales ||
                    (formEnvioMaritimo.submitted &&
                      (BultosTotales.value == null ||
                        BultosTotales.value == ''))
                  " class="text-danger" style="margin-bottom: 20px; font-size: small">
                  {{ "Bultos Totales obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <label class="totalizadores">{{ "Bruto Total" | translate }} (Kg)*</label>
                <input autocomplete="off" (change)="comprobarPesosTotales()" (focusout)="
                    compruebaPesoBrutoTotal(formEnvioMaritimo);
                    calcularPesoTasable()
                  " [ngStyle]="{ 'border-color': errorBrutoTotal ? 'red' : '' }" name="PesoBrutoTotal" type="text"
                  [ngClass]="{
                    'form-control-custom-error totalizadores':
                      errorPesoBrutoTotal,
                    'form-control totalizadores': !errorPesoBrutoTotal
                  }" [(ngModel)]="formModelMaritimo.value.PesoBrutoTotal" #PesoBrutoTotal="ngModel"
                  [readonly]="!esEditable()" [disabled]="esLCL && esTarifa" required />
                <!--(esTarifa && formModelMaritimo.value.PesoBrutoTotal>0)-->
                <div *ngIf="
                    errorPesoBrutoTotal ||
                    (formEnvioMaritimo.submitted &&
                      (PesoBrutoTotal.value == null ||
                        PesoBrutoTotal.value == ''))
                  " class="text-danger" style="margin-bottom: 20px; font-size: small">
                  {{ "Peso Bruto total obligatorio" | translate }}
                </div>
              </div>
              <div class="col-sm-2">
                <label class="totalizadores">{{ "Neto Total" | translate }} (Kg)</label>
                <input autocomplete="off" name="PesoNetoTotal" type="text" [ngClass]="{
                    'form-control-custom-error totalizadores':
                      errorPesoNetoTotal,
                    'form-control totalizadores': !errorPesoNetoTotal
                  }" (focusout)="compruebaPesoNetoTotal()" [(ngModel)]="formModelMaritimo.value.PesoNetoTotal"
                  #PesoNetoTotal="ngModel" [readonly]="visor || isCotizacion" required />
              </div>
              <div class="col-sm-2">
                <label class="totalizadores">{{ "Peso Tasable" | translate }} (Kg)</label>
                <input name="PesoTasable" type="text" class="form-control totalizadores"
                  [ngModel]="formModelMaritimo.value.PesoTasable" #PesoTasable="ngModel" readonly />
              </div>
              <div class="col-sm-2">
                <label *ngIf="comprobarModoEnvioLCL()" class="totalizadores">{{
                  "Volumen
                  Total" | translate
                  }}(m<sup>3</sup>)*</label>
                <label *ngIf="!comprobarModoEnvioLCL()" class="totalizadores">{{
                  "Volumen
                  Total" | translate
                  }}(m<sup>3</sup>)</label>
                <input autocomplete="off" name="VolumenTotal" type="text" (focusout)="calcularPesoTasable()" [ngClass]="{
                    'form-control-custom-error totalizadores':
                      errorVolumenTotal,
                    'form-control totalizadores': !errorVolumenTotal
                  }" [(ngModel)]="formModelMaritimo.value.VolumenTotal" #VolumenTotal="ngModel" readonly
                  [required]="!esFCL || clientePermitido()" />
                <div *ngIf="
                    errorVolumenTotal ||
                    (formEnvioMaritimo.submitted &&
                      (VolumenTotal.value == null || VolumenTotal.value == ''))
                  " class="text-danger" style="
                    font-size: small;
                    margin-bottom: 20px;
                    margin-left: 10px;
                  ">
                  {{ "Volumen Total obligatorio" | translate }}
                </div>
              </div>
            </div>
            <div class="row" style="margin: 5px">
              <div class="col-sm-2" *ngIf="formModelMaritimo.value.MercanciaPeligrosa == true">
                <label class="totalizadores">{{ "Bruto Total" | translate }}(Kg)(DGR)</label>
                <input id="PesoBrutoTotalDGR" autocomplete="off" name="PesoBrutoTotalDGR" type="text" [ngClass]="{
                    'form-control-custom-error totalizadores': errorPesoBruto,
                    'form-control totalizadores': !errorPesoBruto
                  }" class="form-control totalizadores" [(ngModel)]="formModelMaritimo.value.PesoBrutoTotalDGR"
                  #PesoBrutoTotalDGR="ngModel" readonly required />
              </div>
              <div class="col-sm-2" *ngIf="formModelMaritimo.value.MercanciaPeligrosa == true">

                <label class="totalizadores">{{ "Neto Total" | translate }}(Kg)(DGR)*</label>
                <input id="PesoNetoTotalDGR" autocomplete="off" name="PesoNetoTotalDGR" type="text" [ngClass]="{
                    'form-control-custom-error totalizadores': errorPesoNeto,
                    'form-control totalizadores': !errorPesoNeto
                  }" [(ngModel)]="formModelMaritimo.value.PesoNetoTotalDGR" #PesoNetoTotalDGR="ngModel" readonly
                  required />
                <div *ngIf="
                    errorPesoNetoTotalDGR ||
                    (formEnvioMaritimo.submitted &&
                      (PesoNetoTotalDGR.value == null ||
                        PesoNetoTotalDGR.value == ''))
                  " class="text-danger" style="margin-bottom: 20px; font-size: small">
                  {{ "Peso Neto total DGR obligatorio" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <label>{{ "Insutrcciones B/L" | translate }}*</label><sup><span mat-raised-button
                  matTooltip="{{ 'infoDescripcionMercancia' | translate }}" matTooltipClass="my-custom-tooltip"><img
                    src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
              <textarea id="InstruccionesBLAWB" name="InstruccionesBLAWB" style="margin-bottom: 20px; resize: none"
                class="form-control" [ngClass]="{
                  error: errorInstruccionesBLAWB,
                  '': !errorInstruccionesBLAWB
                }" rows="5" #InstruccionesBLAWB="ngModel" maxlength="{{ maxlengthInstruccionesBLAWB }}"
                style="margin-bottom: 5px" [(ngModel)]="formModelMaritimo.value.InstruccionesBLAWB" [readonly]="visor"
                required (focusout)="compruebaInstruccionesBLAWB(formEnvioMaritimo)"></textarea>
              <span>{{ formModelMaritimo.value.InstruccionesBLAWB.length }}/{{
                maxlengthInstruccionesBLAWB
                }}</span>
              <div *ngIf="
                  errorInstruccionesBLAWB ||
                  (formEnvioMaritimo.submitted &&
                    (InstruccionesBLAWB.value == null ||
                      InstruccionesBLAWB.value == ''))
                " class="text-danger" style="margin-bottom: 20px; font-size: small">
                {{ "InstruccionesBLObligatoria" | translate }}
              </div>
            </div>
            <div class="col-sm-6">
              <label>{{ "Marcas" | translate }}</label><sup><span mat-raised-button
                  matTooltip="{{ 'infoMarcas' | translate }}" matTooltipClass="my-custom-tooltip"><img
                    src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
              <textarea name="Marcas" style="resize: none; margin-bottom: 5px" class="form-control" rows="5"
                #Marcas="ngModel" maxlength="{{ maxlengthMarcas }}" [(ngModel)]="formModelMaritimo.value.Marcas"
                [readonly]="visor"></textarea>
              <span>{{ formModelMaritimo.value.Marcas.length }}/{{
                maxlengthMarcas
                }}</span>
            </div>
          </div>

          <!-- NOVENO BLOQUE -->
          <div id="novenobloque" class="row">
            <div class="col-sm-12">
              <label>{{ "Instrucciones/Observaciones" | translate }}</label><sup><span mat-raised-button
                  matTooltip="{{ 'infoInstrucciones' | translate }}" matTooltipClass="my-custom-tooltip"><img
                    src="assets/iconos/info_64.png" alt="" style="width: 12px; height: 12px" /></span></sup>
              <textarea name="Instrucciones" class="form-control" rows="5" style="margin-bottom: 5px"
                maxlength="{{ maxlengthObservaciones }}" #Instrucciones="ngModel"
                [(ngModel)]="formModelMaritimo.value.Instrucciones" [readonly]="visor"></textarea>
              <span>{{ formModelMaritimo.value.Instrucciones.length }}/{{
                maxlengthObservaciones
                }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8 col-12"></div>
            <div class="col-sm-4 col-12">
              <br />
              * : {{ "indicador de campos obligatorios" | translate }}
            </div>
          </div>
          <!-- DECIMO BLOQUE -->
          <div id="decimobloque" class="row">
            <div class="col-sm 8 col-12"></div>
            <div class="col-sm-2 col-12 margen-top">
              <app-upload-modal (sendFiles)="receiveFiles($event)"></app-upload-modal>

              <label *ngIf="listaArchivos.length > 0">{{ listaArchivos.length }}
                {{
                "archivos
                cargado/s" | translate
                }}</label>
            </div>
            <div class="col-sm-2 col-12 margen-top" *ngIf="!visor">
              <button type="button" id="enviarPlantilla" class="btn btn-block"
                (click)="openAlert2(templateConfirmacionPlantillas)">
                {{ "Guardar Plantilla" | translate }}
              </button>
            </div>
          </div>

          <!-- UNDECIMO BLOQUE -->
          <div id="undecimobloque" class="row">
            <div class="col-sm-8 col-12"></div>
            <div class="col-sm-2 col-12 margen-top" *ngIf="!visor || !editar || isCotizacion">
              <button type="button" id="enviarShipment" class="btn btn-block" (click)="
                  convertFormToModel(formEnvioMaritimo);
                  openAlert(template3, formEnvioMaritimo)
                ">
                {{ "Enviar" | translate }}
              </button>
            </div>
            <div class="col-sm-2 col-12 margen-top">
              <button type="button" id="cancelarShipment" class="btn buttonCancelar btn-block"
                (click)="openModalSM(templateAvisoSiNo)">
                {{ "Cancelar" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #templatePlantillas>
  <div class="modal-header fondo" style="border-color: black">
    <h2 class="modal-title">{{ "Plantillas" | translate }}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-plantillas [idArea]="formModelMaritimo.value.IdArea" (send)="getPlantilla($event)"></app-plantillas>
  </div>
</ng-template>

<ng-template #templateAvisoSiNo>
  <div class="modal-header fondo" style="border-color: black">
    <h2 class="modal-title">{{ "CancelarTitleShipment" | translate }}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-aviso-si-no [texto]="texto" (respuesta)="obtenerRespuesta($event)"></app-aviso-si-no>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header fondo" style="border-color: black">
    <h2 class="modal-title pull-left">
      {{ "Direcciones entidades" | translate }}
    </h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-direcciones-entidad (address)="getDireccion($event)" [tipoDireccion]="tipoDireccion"></app-direcciones-entidad>
  </div>
</ng-template>

<ng-template #template2>
  <div class="modal-header fondo" style="border-color: black">
    <h2 class="modal-title pull-left">{{ "Añadir Direccion" | translate }}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-anyadir-direccion (emitDireccionEntidad)="obtenerDireccionEntidad($event)" [tipoDireccion]="tipoDireccion"
      [area]="2"></app-anyadir-direccion>
  </div>
</ng-template>

<ng-template #template3>
  <div class="modal-header" style="border-color: black; background-color: white">
    <h2 class="modal-title pull-left">
      {{ "ConfirmacionResumen" | translate }}
    </h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="resumen" style="background-color: white">
    <app-confirmacion-seguro [asegurado]="formModelMaritimo.value.Asegurado" (confirmEmit)="confirmacionSeguro($event)"
      [shipment]="shipmentMaritimo" [direccionRecogida]="resumenDireccionRecogida"
      [direccionEntrega]="resumenDireccionEntrega"></app-confirmacion-seguro>
    <div class="row" style="justify-content: right">
      <div class="col-sm-3" class="margenBotones">
        <button type="button" class="btn" style="margin: 5px" (click)="downloadPDF()">
          {{ "ImprimirEBooking" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateConfirmacionPlantillas>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Confirmación plantilla" | translate }}
      </h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-confirmacion-plantilla (confirmEmit)="confirmacionPlantilla($event)"></app-confirmacion-plantilla>
    </div>
  </div>
</ng-template>

<!-- The Modal -->
<ng-template #templateSearchEntity>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Localizar Entidad" | translate }}
      </h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- Modal body -->
    <div class="modal-body fondo">
      <div class="row" style="margin-left: 15px">
        <h2>{{ "Entidades" | translate }}</h2>
      </div>
      <app-list-entities *ngIf="tipoEntidad == 'shipper'" [listEntities]="listShipper" [listEntitiesAux]="listShipper"
        [searchText]="searchText" (entity)="recibirShipper($event)" (search)="recibirSearch($event)">
      </app-list-entities>
      <app-list-entities *ngIf="tipoEntidad == 'consignee'" [listEntities]="listConsignee"
        [listEntitiesAux]="listConsignee" [searchText]="searchText" (entity)="recibirConsignee($event)"
        (search)="recibirSearch($event)"></app-list-entities>
      <app-list-entities *ngIf="tipoEntidad == 'notify'" [listEntities]="listNotify" [listEntitiesAux]="listNotify"
        [searchText]="searchText" (entity)="recibirNotify($event)" (search)="recibirSearch($event)"></app-list-entities>
      <app-list-entities *ngIf="tipoEntidad == 'recogida'" [listEntities]="listShipper" [listEntitiesAux]="listShipper"
        [searchText]="searchText" (entity)="recibirEntidadRecogida($event)"
        (search)="recibirSearch($event)"></app-list-entities>
      <app-list-entities *ngIf="tipoEntidad == 'entrega'" [listEntities]="listShipper" [listEntitiesAux]="listShipper"
        [searchText]="searchText" (entity)="recibirEntidadEntrega($event)"
        (search)="recibirSearch($event)"></app-list-entities>
    </div>
  </div>
</ng-template>

<ng-template #entityTemplate>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{ "Añadir Entidad" | translate }}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-formulario-address-book (emitCreateAddressBook)="refresEntidades($event)"></app-formulario-address-book>
    </div>
  </div>
</ng-template>