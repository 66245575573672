import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Horario } from 'src/app/models/horario.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.scss']
})
export class HorarioComponent implements OnInit {

  clase: string = "app-horario";
  horario: Horario;
  formHorario: FormGroup;

  constructor(private configurationService: ConfigurationService, private formBuilder: FormBuilder, private toastr: ToastrService,
    private translate: TranslateService) { }

  
  public get he1() { return this.formHorario.get('he1') }
  public get he2() { return this.formHorario.get('he2') }
  public get he3() { return this.formHorario.get('he3') }
  public get he4() { return this.formHorario.get('he4') }
  public get hr1() { return this.formHorario.get('hr1') }
  public get hr2() { return this.formHorario.get('hr2') }
  public get hr3() { return this.formHorario.get('hr3') }
  public get hr4() { return this.formHorario.get('hr4') }

  ngOnInit(): void {
    this.formHorario = this.formBuilder.group({
      he1: [null],
      he2: [null],
      he3: [null],
      he4: [null],
      hr1: [null],
      hr2: [null],
      hr3: [null],
      hr4: [null],
    });
    this.configurationService.getHorarios();
    this.configurationService.getHorarios$().subscribe(h => {
      if(h != null){
        this.horario = h;
        this.setHorario();
      }
    });
  }

  setHorario(){
    this.he1.setValue(this.horario.he1);
    this.he2.setValue(this.horario.he2);
    this.he3.setValue(this.horario.he3);
    this.he4.setValue(this.horario.he4);
    this.hr1.setValue(this.horario.hr1);
    this.hr2.setValue(this.horario.hr2);
    this.hr3.setValue(this.horario.hr3);
    this.hr4.setValue(this.horario.hr4);
  }

  getHorario(){
    this.horario.he1 = this.he1.value;
    this.horario.he2 = this.he2.value;
    this.horario.he3 = this.he3.value;
    this.horario.he4 = this.he4.value;
    this.horario.hr1 = this.hr1.value;
    this.horario.hr2 = this.hr2.value;
    this.horario.hr3 = this.hr3.value;
    this.horario.hr4 = this.hr4.value;
  }

  actualizarHorario(click: string){
    if(this.formHorario.valid){
      var log: LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      this.getHorario();
      this.configurationService.putHorario(this.horario, log).subscribe(data => {
        var body = this.translate.instant("HorarioActualizacionBody");
        var title = this.translate.instant("HorarioActualizacionTitulo");
        this.toastr.success(body, title);
      }, _ => {
        var body = this.translate.instant("HorarioActualizacionErrorBody");
        var title = this.translate.instant("HorarioActualizcionErrorTitulo");
        this.toastr.error(body, title);
      });
    }else{
      var body = this.translate.instant("HorarioErrorFormNoValidoBody");
      var title = this.translate.instant("HorarioErrorFormNoValidoTitulo");
      this.toastr.error(body, title);
    }
  }

}
