<app-header-proveedor></app-header-proveedor>

<div class="container fondo">
    <div class="row">
        <div class="col-sm">
            <div class="row">
                <div class="col" style="text-align: left;">
                    <p>{{'Seleccione una Expedición si la Factura solo es de una Expedición.'|translate}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col" style="text-align: left;">
                    <p>{{'Seleccione varias Expediciones si quiere enviar una Factura Agrupada.'|translate}}</p>
                </div>
            </div>
            <div class="row" style="margin-bottom: 2.5%;">
                <div class="col">
                    <button style="font-size: small;" *ngIf="expedicionesFactura.length == 0 " class="btn btn-block"
                    (click)="openModal(templateSubirFactura);"
                    [disabled]="true">{{'Selecciona una Expedición para Facturar'|translate}}</button>
                    <button style="font-size: small;" *ngIf="expedicionesFactura.length == 1" class="btn btn-block"
                    (click)="openModal(templateSubirFactura);">{{'Subir Factura de la Expedición seleccionada'|translate}}</button>
                    <button style="font-size: small;" *ngIf="expedicionesFactura.length > 1" class="btn btn-block" 
                    (click)="openModal(templateSubirFacturaConjunta);">{{'Subir Facturas agrupadas'|translate}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="standard" class="width-100">
                        <mat-label>{{'Búsqueda' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                        <button mat-button matPrefix mat-icon-button>
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
                <div class="row">
                    <div class="col-sm-12">
                        <button class="btn" type="button" (click)="seleccionarTodos();">
                            {{'Seleccionar todos'|translate}}
                        </button>
                    </div>
                </div>
                <!-- CheckBox Column -->
                <ng-container *ngIf="isProveedor" matColumnDef="checkBox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox name="SelectAll" (click)="seleccionarTodos();" [(ngModel)]="selectedAll" #SelectAll></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox [(ngModel)]="element.Checked"
                        (click)="setExpedicionesToFactura(element.Expedicion)"></mat-checkbox>
                    </td>
                </ng-container>
            
                <!-- Icon Column -->
                <ng-container matColumnDef="arrow">
                    <th mat-header-cell *matHeaderCellDef>
                        
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="expandedElement != null && expandedElement.IdExpedicion == element.IdExpedicion"
                        (click)="showDetails(element); expandedElement = expandedElement === element ? null : element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement == null || expandedElement.IdExpedicion != element.IdExpedicion"
                        (click)="showDetails(element); expandedElement = expandedElement === element ? null : element">keyboard_arrow_up</mat-icon>
                    </td>
                </ng-container>
            
                <!-- Referencia Column -->
                <ng-container matColumnDef="referencia">
                  <th mat-header-cell *matHeaderCellDef>
                    {{'Referencia'|translate}} 
                  </th>
                  <td mat-cell *matCellDef="let element" 
                  (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> {{element.IdSerieReferencia}} </td>
                </ng-container>
              
                <!-- Expedicion Column -->
                <ng-container matColumnDef="expedicion">
                  <th mat-header-cell *matHeaderCellDef> {{'Expedicion'|translate}} </th>
                  <td mat-cell *matCellDef="let element"
                  (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> {{element.Expedicion}} </td>
                </ng-container>
              
                <!-- Fecha Column -->
                <ng-container matColumnDef="fecha">
                  <th mat-header-cell *matHeaderCellDef> {{'Fecha'|translate}} </th>
                  <td mat-cell *matCellDef="let element"
                  (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> {{element.FechaSalida}} </td>
                </ng-container>
            
                <!-- Facturacion Column -->
                <ng-container matColumnDef="facturado">
                    <th mat-header-cell *matHeaderCellDef> {{'¿Facturado?'|translate}} </th>
                    <td mat-cell *matCellDef="let element"
                    (click)="showDetails(element); expandedElement = expandedElement === element ? null : element"> 
                        <mat-checkbox name="SelectAll" [checked]="element.isFacturado"
                        disabled></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="getColumnas().length">
                        <div class="example-element-detail"
                           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="container fondo mat-elevation-z2" >
                                <div class="row titulo">
                                    <p class="h4">{{'Información remitente'|translate}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.Remitente}}</p>
                                </div>
                                <div class="row">
                                    <p>{{montarDireccion(expedicion.DireccionRemitente, expedicion.CodigoPostalRemitente, expedicion.PoblacionRemitente)}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.TelefonoRemitente}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.Bultos}} {{'bultos'|translate}} / {{expedicion.Peso}} Kg / {{'Metros lineales'|translate}}</p> <!-- {{expedicion.MetrosLineales}}-->
                                </div>
                                <div class="row" *ngIf="expedicion.RecogidaFechaTracking == null">
                                    <p><b>{{'Fecha recogida'|translate}}: {{toStringRecogida(expedicion.FechaSalida)}}</b></p>
                                </div>
                                <div *ngIf="expedicion.RecogidaFechaTracking != null" class="row">
                                    <p><b>{{'Recogido el'|translate}} {{expedicion.RecogidaFechaTracking}}</b></p>
                                </div>
                                <div class="row titulo" *ngIf="muestraDestinatario(expedicion)">
                                    <p class="h4">{{'Información destinatario'|translate}}</p>
                                </div>
                                <div class="row" *ngIf="muestraDestinatario(expedicion)">
                                    <p>{{expedicion.Destinatario}}</p>
                                </div>
                                <div class="row" *ngIf="muestraDestinatario(expedicion)">
                                    <p>{{montarDireccion(expedicion.DireccionDestinatario, expedicion.CodigoPostalDestinatario, element.PoblacionDestinatario)}}</p>
                                </div>
                                <div class="row" *ngIf="muestraDestinatario(expedicion)">
                                    <p>{{expedicion.TelefonoDestinatario}}</p>
                                </div>
                                <div class="row" *ngIf="expedicion.EntregaFechaTracking == null && muestraDestinatario(expedicion)">
                                    <p><b>{{'Fecha entrega'|translate}}: {{toStringEntrega(expedicion.FechaLlegada)}}</b></p>
                                </div>
                                <div *ngIf="expedicion.EntregaFechaTracking != null && muestraDestinatario(expedicion)" class="row">
                                    <p><b>{{'Entregado el'|translate}} {{expedicion.EntregaFechaTracking}}</b></p>
                                </div>
                                <!--<div class="row">
                                    <div class="col-sm-4">
            
                                    </div>
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2">
                                        <button class="btn" type="button"
                                                    (click)="documentosExpedition();openModal(templateDocumentos);"
                                                    [ngStyle]="{'float:right;': !isMovil}"
                                                    style="margin-bottom: 3%;">{{'Documentos'|translate}}</button>
                                    </div>
                                </div>-->
                            </div>
                            
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getColumnas()"></tr>
                <tr mat-row *matRowDef="let element; columns: getColumnas();"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
            
            <div class="d-flex justify-content-between p-2" style="text-align: center; margin-top: 2.5%;">
                <mat-paginator [length]="total" [pageSize]="sizePage" arial-label="Select page" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
            <!-- The Modal Listado Descargas -->
            <ng-template #templateDocumentos>
                <!-- Modal header -->
                <div class="modal-header fondo" style="border-color: black;">
                    <p class="h4 modal-title">{{'Documentos de'|translate}} {{expedicion.Expedicion}}</p>
                    <button type="button" class="close" (click)="closeModal()" data-dismiss="modal">&times;</button>
                </div>
                <!-- Modal body -->
                <div class="modal-body fondo">
                    <app-modal-download [lista]="documentos"></app-modal-download>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-- The Modal Subir Factura-->
<ng-template #templateSubirFactura>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir facturas para'|translate}} {{expedicionesFactura[0]}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-factura (limpiarHistorial)="limpiar($event)" (cerrarModal)="cerrar($event)" [Expedicion]="expedicionesFactura[0]" [idExpedicion]="idExpedicion"></app-subir-factura>
    </div>
</ng-template>

<!-- The Modal Subir Factura Conjunta-->
<ng-template #templateSubirFacturaConjunta>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir facturas conjuntas'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-factura (limpiarHistorial)="limpiar($event)" (cerrarModal)="cerrar($event)" [idExpedicion]="null" [Expediciones]="expedicionesFactura"></app-subir-factura>
    </div>
</ng-template>

<!-- The Modal Incidencias-->
<ng-template #template>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Incidencias de'|translate}} {{expedicion.Expedicion}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-incidencias (cerrarModal)="cerrar($event)" [historico]="false" [expedicion]="expedicion.Expedicion" [idExpedicion]="expedicion.IdExpedicion"></app-incidencias>
    </div>
</ng-template>