<button type="button" class="btn btn-block"  data-toggle="modal" data-target="#myAttach">
    {{'Adjuntar Archivo'|translate}}
</button>

<!-- The Modal -->
<div class="modal fade" id="myAttach">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header fondo">
                <p class="h4 modal-title">{{'Adjuntar Archivo'|translate}}</p>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body fondo">
                <app-upload-file [documentacion]="documentacion" (listFiles)="receiveFiles($event)" [envio]="envio"></app-upload-file>
            </div>
        </div>
    </div>
</div>