import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TipoArchivoProveedor } from '../models/tipo-archivo-proveedor.model';
import { Tipomercancia } from '../models/tipomercancia.model';
import { AuthenticationService } from './authentication.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})


export class TipoArchivoProveedorService {

  readonly apiUrlService = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  ip: string = ""; 
  localizacion: string = "";
  constructor(private http: HttpClient, private service: AuthenticationService, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
   }

   getTipoArchivoProveedor(): Observable<TipoArchivoProveedor[]>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.service.getToken()
    });
    const url_api = this.apiUrlService + "em_tipoarchivo";
    this.logService.postLogCompleto("GET", "em_tipoarchivoController.cs", "Log obtener todos los tipos de archivo de los proveedores.",
     "Get_em_tipoarchivo", "empresa.tipoarchivo", url_api);
    return this.http.get<TipoArchivoProveedor[]>(url_api, {headers: headersToken}).pipe(map(data => data));
   }
}
