import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Direccion } from 'src/app/models/direccion.model';
import { Entity } from 'src/app/models/entity.model';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-list-entities',
  templateUrl: './list-entities.component.html',
  styleUrls: ['./list-entities.component.scss']
})
export class ListEntitiesComponent implements OnInit {

  @Input() listEntities : Entity[] = [];
  @Input() listEntitiesAux : Entity[] = [];
  @Output() entity = new EventEmitter<Entity>();
  @Output() search = new EventEmitter<string>();
  entidad: Entity;
  currentRow: any;
  @Input() searchText: string = "";
  total: number;
  pageActual: number = 1;
  sizePage: number = 20;

  constructor(private entityService: EntityService) { }

  ngOnInit(): void {
    this.total = this.listEntities.length;
  }

  ngOnChanges(changes: SimpleChange){
    this.total = this.listEntities.length;
  }

  safeEntity(entity) {
    this.entidad = entity;
    this.currentRow = null;
    this.search.emit(this.searchText);
  }

  selectRow(event: any, entity: Entity) {
    this.currentRow = entity.NombreFiscal;
  }

  enviarEntity(){
    this.entity.emit(this.entidad);
  }

  cerrar(){
    this.entity.emit(null);
  }

  existeCadenaDireccion(dir: Entity): boolean{
    return (dir.NombreFiscal.toUpperCase().includes(this.searchText.toUpperCase()) || dir.Direccion.toUpperCase().includes(this.searchText.toUpperCase()) 
    || dir.NombreComercial.toUpperCase().includes(this.searchText.toUpperCase()))
  }

  changeSearch(){
    this.listEntities = this.listEntitiesAux;
    if(this.searchText != "")
      this.listEntities = this.listEntities.filter(dir => this.existeCadenaDireccion(dir));
    this.total = this.listEntities.length;
  }

}
