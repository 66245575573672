<app-header></app-header>
<div class="block text-center pt3">
    <h1>{{'Listado de tarifas'|translate}}</h1>
</div>
<mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="onTabSelectionChanged($event)" class="pt5">
    <mat-tab label="{{'ACTIVAS' | translate}}">
        <div class="mt2 mr2 ml2">
            <app-listado-tarifas [listadoTarifas]="listTarifa" [area]="idArea"></app-listado-tarifas>
        </div>
    </mat-tab>
    <mat-tab label="{{'VENCIDAS' | translate}}">
        <div class="mt2 mr2 ml2">
            <app-listado-tarifas [listadoTarifas]="listTarifaCaducada" [area]="idArea"></app-listado-tarifas>
        </div>
    </mat-tab>
</mat-tab-group>

<div id="undecimobloque" class="row mb20px mr2">
    <div class="col-sm-8 col-12"></div>
    <div class="col-sm-2 col-12 margen-top">
    </div>
    <div class="col-sm-2 col-12 margen-top">
        <button type="button" id="cancelarShipment" class="btn buttonCancelar btn-block"
            (click)="cambiarURL()">{{'Cancelar'|translate}}</button>
    </div>
    <button hidden type="button" id="resumenTarifa" #resumenTarifa
        name="resumenTarifa">{{'Cancelar'|translate}}</button>
</div>