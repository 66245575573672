<div class="container">
    <div id="CambioPassword">
        <form [formGroup]="formCambiarPassword" >
            <div class="row izq">
                <div class="col">
                    <mat-form-field>
                        <mat-label class="label">{{'ContraseñaActualLabel' | translate}}</mat-label>
                        <input class="inputMaterial" [formControl]="passwordActual" required matInput type="{{stringContraActual}}" placeholder="{{'ContraseñaActualLabel' | translate}}">
                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: #FCCF61;" aria-label="ChangePassword" (click)="changePasswordActual();">
                            <mat-icon *ngIf="stringContraActual == 'password'">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="stringContraActual == 'text'">visibility_off</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordActual.hasError('required')">{{'RequeridoError' | translate}}</mat-error>
                        <mat-error *ngIf="passwordActual.hasError('minlength')">{{'Minimo6Error' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row izq">
                <div class="col col-sm">
                    <mat-form-field>
                        <mat-label class="label">{{'ContraseñaNuevaLabel' | translate}}</mat-label>
                        <input class="inputMaterial" [formControl]="passwordNueva" required matInput type="{{stringContraNueva}}" placeholder="Contraseña nueva">
                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: #FCCF61;" aria-label="ChangePassword" (click)="changePasswordNueva();">
                            <mat-icon *ngIf="stringContraNueva == 'password'">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="stringContraNueva == 'text'">visibility_off</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordActual.hasError('required')">{{'RequeridoError' | translate}}</mat-error>
                        <mat-error *ngIf="passwordActual.hasError('minlength')">{{'Minimo6Error' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row izq">
                <div class="col col-sm">
                    <mat-form-field>
                        <mat-label class="label">{{'RepetirContraseñaNuevaLabel' | translate}}</mat-label>
                        <input class="inputMaterial" [formControl]="passwordRepetirNueva" required matInput type="{{stringRepetirContraNueva}}" placeholder="Repetir contraseña nueva">
                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: #FCCF61;" aria-label="ChangePassword" (click)="changePasswordRepNueva();">
                            <mat-icon *ngIf="stringRepetirContraNueva == 'password'">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="stringRepetirContraNueva == 'text'">visibility_off</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordActual.hasError('required')">{{'RequeridoError' | translate}}</mat-error>
                        <mat-error *ngIf="passwordActual.hasError('minlength')">{{'Minimo6Error' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row izq">
                <div class="col">
                    <re-captcha (resolved)="resolved($event)"
                    (error)="onError($event)"
                    errorMode="handled"
                    required
                    [formControl]="recaptcha"
                    siteKey="6Lf_9BIaAAAAAEORUVniW_cpPUDSHBVl5R0se-9D"></re-captcha>
                </div>
            </div>
            <div class="row izq topbot">
                <div class="col">
                    <button mat-button type="submit" (click)="cambiarPassword('cambioPassword');">Cambiar contraseña</button>
                </div>
            </div>
        </form>
    </div>
</div>
