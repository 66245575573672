<div class="row">
  <div class="col-sm-12 col-12 col-lg-12">
    <div class="table-responsive-sm table-responsive scrollable-element">
      <table class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
        <thead>
          <th (click)="sort('RefXGL')">{{'RefXGL'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='RefXGL'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('RefCliente')">{{'RefCliente'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='RefCliente'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Fecha')">{{'Fecha'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='FechaRecogida'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Validez')">{{'Validez'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Validez'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Origen')">{{'Origen'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Origen'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Destino')">{{'Destino'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Destino'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Bultos')">{{'Bultos'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Bultos'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('PesoBruto')">{{'PesoBruto'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='PesoBruto'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Volumen')">{{'Volumen'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Volumen'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Incoterm')">{{'Incoterm'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Incoterm'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th *ngIf="esAereo()" (click)="sort('Aerolinea')">{{'Aerolinea'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Aerolinea'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th *ngIf="esMaritimo()" (click)="sort('Naviera')">{{'Naviera'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Naviera'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th *ngIf="esTodos()" (click)="sort('Naviera/Aerolinea')">{{'Naviera/Aerolinea'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Naviera/Aerolinea'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <!-- <th *ngIf="esMaritimo() || esTodos()" (click)="sort('ModoEnvio')">{{'Modo Envio'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='ModoEnvio'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th> -->
          <th (click)="sort('TT')">{{'TT'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='TT'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th (click)="sort('Estado')">{{'Estado'|translate}}
            <span class="glyphicon sort-icon" *ngIf="key =='Estado'"
              [ngClass]="{'glyphicon-chevron-up':reverse,'glyphicon-chevron-down':!reverse}"></span>
          </th>
          <th>{{'Acciones'|translate}}</th>
        </thead>
        <tbody >
          <ng-container
            *ngFor="let cot of listadoCotizaciones | filter: term | slice: (pageActual-1)*sizePage: (pageActual-1) * sizePage + sizePage">
            <tr>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.codigo}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.refCliente}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.fecha}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.validez}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.origen}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.destino}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.bultos}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.pesoBruto}} Kg</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.volumen}}</td>
              <td (click)="obtenerDetalles(cot)" style="cursor: pointer">{{cot.incoterm}}</td>
              <td *ngIf="esAereo() || esMaritimo() || esTodos()" (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.navieraAerolinea}}</td>
              <!-- <td *ngIf="esMaritimo() || esTodos()" (click)="obtenerDetalles(cot.identificador)" style="cursor: pointer">{{cot.modoEnvio}}</td> -->
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.tt}}</td>
              <td (click)="obtenerDetalles(cot);esAceptar();" style="cursor: pointer">{{cot.estado}}</td>
              <td *ngIf="activa" style="cursor: pointer">
                <i *ngIf="esAccepted(cot.estado)" class="material-icons" (click)="getBooking(cot)"
                  title="{{'BookNowPlaceholder' | translate}}">upload</i>
                <i *ngIf="esCompleted(cot.estado)" class="material-icons green material-icons--rounded"
                  (click)="esAceptar();obtenerDetalles(cot);asignarEstado(cot.estado);"
                  title="{{'AcceptedPlaceholder' | translate}}">check_circle</i>
                <i *ngIf="esCompleted(cot.estado)" class="material-icons red material-icons--rounded"
                  (click)="esRechazar();obtenerDetalles(cot);asignarEstado(cot.estado);openModal(aceptarRechazarTemplate)"
                  title="{{'CancelPlaceholder' | translate}}">cancel</i>
                <i *ngIf="contienePDF(cot.tienePDF)" class="material-icons"
                  (click)="obtenerCotizacionPDF(cot.identificador);asignarEstado(cot.estado);openModal(pdfTemplate);">picture_as_pdf</i>
              </td>
              <td *ngIf="!activa" style="cursor: pointer">
                <i title="{{'nuevaCotizacionIcon' | translate }}" class="material-icons"
                  (click)="duplicarCotizacion(cot)">refresh</i>
                <i *ngIf="cot.tienePDF >= 1" class="material-icons"
                  (click)="obtenerCotizacionPDF(cot.identificador);openModal(pdfTemplate);">picture_as_pdf</i>
              </td>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <div class="d-flex justify-content-between p-2" style="text-align: center;">
          <ngb-pagination [(page)]="pageActual" [pageSize]="sizePage" [collectionSize]="total" [maxSize]="3"
            [rotate]="true">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #detailTemplate>
  <app-detalles-cotizacion (close)="cerrar($event)" [cotizacion]="cotizacion" [conModel]="true">
  </app-detalles-cotizacion>
  <app-aceptar-rechazar (close)="cerrar($event)" (recargar)="recargarLista($event)"
  (realizarBooking)="realizarBooking($event)" [aceptar]="aceptar" [cotizacion]="cotizacion"></app-aceptar-rechazar>
</ng-template> -->

<ng-template #aceptarRechazarTemplate>
  <app-aceptar-rechazar (close)="cerrar($event)" (recargar)="recargarLista($event)" [estado]="estado"
    (realizarBooking)="realizarBooking($event)" [aceptar]="aceptar" [cotizacion]="cotizacion"></app-aceptar-rechazar>
</ng-template>

<ng-template #pdfTemplate>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <div class="col">
        <div class="row">
          <div class="col-10">
            <h2 class="modal-title pull-left">
              {{ "DetallesCotizacion" | translate }}
            </h2>
          </div>
          <div class="col-2">
            <button type="button" class="close pull-right" aria-label="Close" (click)="cerrar(true)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  class="modal-body fondo" -->
    <div class="modal-body fondo">
      <div class="container">
        <div class="row" *ngIf="base64Cotizacion != undefined && base64Cotizacion != null && base64Cotizacion != ''">
          <ngx-extended-pdf-viewer class="tamanyoPDF" [base64Src]="base64Cotizacion" useBrowserLocale="true" language="en-GB"
            [textLayer]="true" [filenameForDownload]="nombrePDF"></ngx-extended-pdf-viewer>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div *ngIf="esCompletedEstado()" class="row ml7coma3">
              <div class="col-12">
                  <mat-checkbox class="example-margin" [(ngModel)]="checked">{{"CheckedCondiciones" | translate}}</mat-checkbox>
              </div>
          </div>
          <div *ngIf="esCompletedEstado()" class="row ml7coma3">
              <div class="col-4">
                  <button type="button" class="btn" id="aceptado-con-booking" [disabled]="!checked" (click)="hacerBooking(true);">{{"AceptarBooking" | translate}}</button>
              </div>
              <div class="col-4">
                  <button type="button" class="btn buttonCancelar" (click)="cerrar(true)">{{"Cancelar" | translate }}</button>
              </div>
              <!-- <div class="col-4">
                  <button type="button" class="btn" id="aceptado-sin-booking" [disabled]="!checked" (click)="cambiarEstadoAceppted()">Si y luego realizar booking</button>
              </div> -->
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
