import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Expedition } from '../models/expedition.model';
import { LogModel } from '../models/log-model.model';
import { Matricula } from '../models/matricula.model';
import { Perfil } from '../models/perfil.model';
import { Vehiculo } from '../models/vehiculo.model';
import { VistaProveedor } from '../models/vistaProveedor.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {

  readonly apiUrlService = environment.apiUrl;
  base64: string = "";
  expedicionesProveedor: Subject<VistaProveedor[]> = new Subject();
  $vehiculos: Subject<Vehiculo[]> = new Subject();

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getExpediciones(){
    return this.expedicionesProveedor.asObservable();
  }

  getVehiculos(){
    return this.$vehiculos.asObservable();
  }

  getExpedicionesProveedor(){
    const url_api = this.apiUrlService + "pa_expedicion/proveedor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as VistaProveedor[])).subscribe(expediciones => {
      this.expedicionesProveedor.next(expediciones);
    });
  }

  getAllMatriculas(){
    const url_api = this.apiUrlService + "matriculas";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Vehiculo[])).subscribe(matriculas => {
      this.$vehiculos.next(matriculas);
    });
  }

  putMatricula(numeroExpedicion: string, codigoMatricula: string, log: LogModel): Observable<Matricula[]>{
    const url_api = this.apiUrlService + "pa_expedicion/actualizarMatricula";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.put(url_api, JSON.stringify({
      Log: log,
      Body: JSON.stringify({
        NumeroExpedicion: numeroExpedicion,
        CodigoMatricula: codigoMatricula})
    }), { headers: headersToken }).pipe(map(data => data as Matricula[]));
  }

  getPerfil(){
    const url_api = this.apiUrlService + "em_entidad/perfil";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    });
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Perfil));
  }
}
