import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogModel } from '../models/log-model.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  readonly apiUrlService = environment.apiUrl;
  embalajePredeterminado: boolean;


  constructor(private http: HttpClient, private authenticacion: AuthenticationService) { }
  
  putConfigEmbalaje(predeterminado: boolean, logModel: LogModel){
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })

    const url_api = this.apiUrlService + "cli_config/embalajepredeterminado/" + predeterminado;
    return this.http.put(url_api,JSON.stringify({"Log": logModel, "Body": JSON.stringify(predeterminado).toString()}), {headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  getEmbalajePredeterminado(): Observable<boolean> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cli_config/embalajepredeterminado"
    return this.http.get<boolean>(url_api, {headers: headersToken}).pipe(map(data => data as boolean))
  }

}
