import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CausaLoss } from 'src/app/models/causaLoss.model';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { LogModel } from 'src/app/models/log-model.model';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { ma_cotizacion_causalossService } from 'src/app/services/ma_cotizacion_causaloss.service';

@Component({
  selector: 'app-aceptar-rechazar',
  templateUrl: './aceptar-rechazar.component.html',
  styleUrls: ['./aceptar-rechazar.component.scss']
})
export class AceptarRechazarComponent implements OnInit {

  @Input() cotizacion: Cotizacion;
  @Input() estado: string;
  @Input() aceptar: boolean = false;
  @Output() close = new EventEmitter<boolean>();
  @Output() recargar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() realizarBooking = new EventEmitter<boolean>();
  causasLossListado: CausaLoss[] = [];
  causaLoss: number;
  textoOtros: string;
  checked = false;


  constructor(private cotizacionService: CotizacionService, private toastr: ToastrService, private causaLossService: ma_cotizacion_causalossService) { }

  ngOnInit(): void {
    if(!this.aceptar){
      this.causaLossService.getAllCausasLoss().subscribe(data => {
        this.causasLossListado = data;
      });
    }
  }
  
  esCompleted(){
    return this.estado == 'COMPLETED';
  }

  hacerBooking(realizarBooking){
    var logModel: LogModel = new LogModel();
    logModel.Click = "aceptado-con-booking";
    logModel.Pantalla = "app-aceptar-rechazar";
    this.cotizacionService.cambiarEstado(this.cotizacion.referencia, logModel).subscribe(data => {
      if(data)
      {
        this.toastr.success("Realizado correctamente", "Realizado correctamente");
        //this.close.emit(true);
        this.realizarBooking.emit(realizarBooking);
      }
    });
  }

  cerrar(close){
    if(close)
      this.close.emit(true);
  }

  cambiarEstadoAceppted(){
    var logModel: LogModel = new LogModel();
    logModel.Click = "aceptado-sin-booking";
    logModel.Pantalla = "app-aceptar-rechazar";
    this.cotizacionService.cambiarEstado(this.cotizacion.referencia, logModel).subscribe(data => {
      if(data)
      {
        this.toastr.success("Realizado correctamente", "Realizado correctamente");
        this.close.emit(true);
        this.recargar.emit(true);
      }
    });
  }

  cambiarEstadoDeclined(){
    var logModel: LogModel = new LogModel();
    logModel.Click = "rechazar-cotizacion";
    logModel.Pantalla = "app-aceptar-rechazar";
    this.cotizacionService.cambiarEstadoDeclined(this.cotizacion.referencia, this.causaLoss, this.textoOtros,  logModel).subscribe(data => {
      if(data)
      {
        this.toastr.success("Realizado correctamente", "Realizado correctamente");
        this.close.emit(true);
        this.recargar.emit(true);
      }
    });
  }

}
