<div>
    <app-header></app-header>
    <div class="container-fluid">
        <div class="container">
            <form #formEnvioAereo="ngForm" (ngSubmit)="envioAereo(formEnvioAereo);">
                <div class="form-group">
                    <!-- BLOQUE ESTATICO -->
                    <div id="bloqueestatico" style="margin-top: 20px;">
                        <!-- SEGUNDO BLOQUE-->

                        <div id="plantillas" class="row" style="margin-bottom: 20px;">
                            <div class="col-sm-3">
                                <button type="button" class="btn" autofocus="on" (click)="openModal(templatePlantillas)"
                                    [disabled]="visor">{{'Cargar Plantillas' | translate}}</button>
                            </div>
                        </div>

                        <div id="segundobloque" class="row">
                            <div class="col-sm-3">
                                <label>{{'Tipo Envio' | translate}}*</label>
                                <ng-select name="TipoEnvio" id="TipoEnvio" (change)="setTipoEnvio($event)"
                                    [ngClass]="{'error':errorTipoEnvio}" #TipoEnvio="ngModel" [items]="listTipoEnvio"
                                    bindLabel="TipoEnvio" bindValue="IdTipoEnvio"
                                    [readonly]="visor || isCotizacion || esTarifa"
                                    (focusout)="compruebaTipoEnvio(formEnvioAereo)"
                                    [(ngModel)]="formModelAereo.value.TipoEnvio" aria-required="true">
                                </ng-select>
                                <div *ngIf="errorTipoEnvio" class="text-danger" style="font-size: small">
                                    {{'Tipo envio obligatorio' | translate}}
                                </div>
                            </div>
                        </div>
                        <div id="identificativo" class="row" style="margin-top: 20px;">
                            <div class="col-sm-3">
                                <label>{{'Cliente' | translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoCliente' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input type="text" name="Cliente" style="margin-bottom: 20px;" class="form-control"
                                    autocomplete="off" [(ngModel)]="formModelAereo.value.Cliente.NombreFiscal"
                                    #Cliente="ngModel" readonly>
                            </div>
                            <div class="col-sm-3">
                                <label>{{'Ref. Cliente' | translate }}</label>*<sup><span mat-raised-button
                                        matTooltip="{{'infoReferencia' | translate }}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" #Referencia="ngModel" type="text" id="Referencia"
                                    name="Referencia"
                                    [ngClass]="{'form-control-custom-error':errorReferencia, 'form-control':!errorReferencia}"
                                    (focusout)="compruebaReferencia(formEnvioAereo); replicaReferencias(formEnvioAereo);compruebaDuplicadoReferenciaCliente()"
                                    [(ngModel)]="formModelAereo.value.Referencia" [readonly]="visor" required>
                                <div *ngIf="errorReferencia || (formEnvioAereo.submitted && (Referencia.value == null || Referencia.value == '')) 
                            || (Referencia.touched && (Referencia.value == null || Referencia.value == ''))"
                                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                    {{'Referencia obligatoria'|translate}}
                                </div>
                            </div>
                            <div *ngIf="isCotizacion" class="col-sm-3">
                                <label>{{'Ref. Cotizacion' | translate }}</label>
                                <input type="text" name="refCotizacion" style="margin-bottom: 20px;"
                                    class="form-control" autocomplete="off"
                                    [(ngModel)]="formModelAereo.value.RefCotizacion" readonly>
                            </div>
                        </div>
                        <!-- TERCER BLOQUE -->
                        <div id="tercerbloque" class="row">
                            <div class="col-sm-4">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <label>{{'Remitente' | translate}}*</label><sup><span mat-raised-button
                                                matTooltip="{{'infoRemitente' | translate}}"
                                                matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png"
                                                    alt="" style="width: 12px;height: 12px;"></span></sup>
                                        <ng-select name="ListShipper" id="ListShipper"
                                            [ngClass]="{'error':errorListShipper}" #ListShipper="ngModel"
                                            [items]="listShipper" bindLabel="NombreFiscal" [readonly]="visor"
                                            [(ngModel)]="formModelAereo.value.Shipper" style="margin-bottom: 20px;"
                                            (change)="setShipperInformation();"
                                            (focusout)="compruebaListShipper(formEnvioAereo)" [loading]="cargaRemitente"
                                            loadingText="Cargando...">
                                        </ng-select>
                                        <textarea name="Shipper" id="Shipper" cols="30" rows="4"
                                            class="form-control-custom"
                                            [(ngModel)]="this.formModelAereo.value.DireccionShipper"
                                            [readonly]="!readonlyDireccionShipper"></textarea>
                                        <div *ngIf="errorListShipper || (formEnvioAereo.submitted && (ListShipper.value == null || ListShipper.value == '')) 
                                    || (ListShipper.touched && (ListShipper.value == null || ListShipper.value == ''))"
                                            class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                            {{'Remitente obligatorio' | translate}}
                                        </div>
                                        <mat-checkbox name="chShipper" class="example-margin"
                                            (change)="cambiarEstadoDireccion($event.checked, 'shipper')"
                                            [(ngModel)]="chShipper">{{'Modificar direccion'|translate}}</mat-checkbox>
                                    </div>
                                    <div class="col-sm-2">
                                        <label style="margin-bottom: 26px;"></label>
                                        <button type="button" title="{{'Buscar entidad'|translate}}"
                                            class="btn float-right" style="margin-bottom: 10px;"
                                            (click)="openModal2(templateSearchEntity, 'shipper')" [disabled]="visor">
                                            <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;"
                                                alt="">
                                        </button>
                                        <button type="button" title="{{'Añadir entidad'|translate}}"
                                            class="btn float-right" (click)="openModal2(entityTemplate, 'shipper')"
                                            [disabled]="visor">
                                            <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;"
                                                alt="">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <label>{{'Destinatario' | translate}}</label>*<sup><span mat-raised-button
                                                matTooltip="{{'infoDestinatario' | translate}}"
                                                matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png"
                                                    alt="" style="width: 12px;height: 12px;"></span></sup>
                                        <ng-select name="ListConsignee" id="ListConsignee"
                                            [ngClass]="{'error':errorListConsignee}" #ListConsignee="ngModel"
                                            [items]="listConsignee" bindLabel="NombreFiscal" [readonly]="visor"
                                            [(ngModel)]="formModelAereo.value.Consignee" style="margin-bottom: 20px;"
                                            (change)="setConsigneeInformation()"
                                            (focusout)="compruebaListConsignee(formEnvioAereo)"
                                            [loading]="cargaDestinatario" loadingText="Cargando...">
                                        </ng-select>
                                        <textarea name="Consignee" id="Consignee" cols="30" rows="4"
                                            class="form-control-custom"
                                            [(ngModel)]="this.formModelAereo.value.DireccionConsignee"
                                            [readonly]="!readonlyDireccionConsignee"></textarea>
                                        <div *ngIf="errorListConsignee || (formEnvioAereo.submitted && (ListConsignee.value == null || ListConsignee.value == '')) 
                                    || (ListConsignee.touched && (ListConsignee.value == null || ListConsignee.value == ''))"
                                            class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                            {{'Destinatario obligatorio' | translate}}
                                        </div>
                                        <mat-checkbox name="chConsignee" class="example-margin"
                                            (change)="cambiarEstadoDireccion($event.checked, 'consignee')"
                                            [(ngModel)]="chConsignee">{{'Modificar direccion'|translate}}</mat-checkbox>
                                    </div>
                                    <div class="col-sm-2">
                                        <label style="margin-bottom: 26px;"></label>
                                        <button type="button" title="{{'Buscar entidad'|translate}}"
                                            class="btn float-right" style="margin-bottom: 10px;"
                                            (click)="openModal2(templateSearchEntity, 'consignee')" [disabled]="visor">
                                            <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;"
                                                alt="">
                                        </button>
                                        <button type="button" title="{{'Añadir entidad'|translate}}"
                                            class="btn float-right" (click)="openModal2(entityTemplate, 'consignee')"
                                            [disabled]="visor">
                                            <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;"
                                                alt="">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <label>{{'Notificar' | translate}}</label><sup><span mat-raised-button
                                                matTooltip="{{'infoNotify' | translate}}"
                                                matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png"
                                                    alt="" style="width: 12px;height: 12px;"></span></sup>
                                        <ng-select name="ListNotify" id="ListNotify" class="select-small"
                                            #ListNotify="ngModel" [items]="listNotify" bindLabel="NombreFiscal"
                                            [readonly]="visor" [(ngModel)]="formModelAereo.value.Notify"
                                            style="margin-bottom: 20px;" (change)="setNotifyInformation()"
                                            [loading]="cargaNotify" loadingText="Cargando...">
                                        </ng-select>
                                        <textarea name="Notify" id="Notify" cols="30" rows="4"
                                            style="margin-right: 10px;resize: none;"
                                            [(ngModel)]="this.formModelAereo.value.DireccionNotify"
                                            class="form-control-custom"
                                            [readonly]="!readonlyDireccionNotify"></textarea>
                                        <mat-checkbox name="chNotify" class="example-margin"
                                            (change)="cambiarEstadoDireccion($event.checked, 'notify')"
                                            [(ngModel)]="chNotify">{{'Modificar direccion'|translate}}</mat-checkbox>
                                    </div>
                                    <div class="col-sm-2">
                                        <!-- Modal de busqueda -->
                                        <label style="margin-bottom: 26px;"></label>
                                        <button type="button" title="{{'Buscar entidad'|translate}}"
                                            class="btn float-right" style="margin-bottom: 10px;"
                                            (click)="openModal2(templateSearchEntity, 'notify')" [disabled]="visor">
                                            <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;"
                                                alt="">
                                        </button>
                                        <button type="button" title="{{'Añadir entidad'|translate}}"
                                            class="btn float-right" (click)="openModal2(entityTemplate, 'notify')"
                                            [disabled]="visor">
                                            <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;"
                                                alt="">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CUARTO BLOQUE -->
                        <div id="cuartobloque" class="row">
                            <div class="col-sm-2">
                                <label>{{'Incoterm'|translate}}</label>*<sup><span mat-raised-button
                                        matTooltip="{{'infoIncoterm' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <ng-select name="Incoterm" id="Incoterm" class="select-small" #Incoterm="ngModel"
                                    [items]="listIncoterm" bindLabel="IdIncoterm" bindValue="IdIncoterm"
                                    style="width: 70%;" [readonly]="visor || isCotizacion"
                                    [(ngModel)]="formModelAereo.value.Incoterm"
                                    (focusout)="compruebaIncoterm(formEnvioAereo)" [ngClass]="{'error':errorIncoterm}"
                                    [loading]="cargaIncoterm" loadingText="Cargando...">
                                </ng-select>
                                <div *ngIf="errorIncoterm || (formEnvioAereo.submitted && (Incoterm.value == null || Incoterm.value == '')) || (Incoterm.touched && (Incoterm.value == null 
                            || Incoterm.value == ''))" class="text-danger"
                                    style="margin-bottom: 20px;font-size: small;">
                                    Incoterm {{'obligatorio' | translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <label *ngIf="formModelAereo.value.Incoterm == 'DDP'">{{'Valor Mercancia' |
                                    translate}}*</label><label *ngIf="formModelAereo.value.Incoterm != 'DDP'">{{'Valor
                                    Mercancia' | translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoValorMercancia' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" id="ValorMercancia" name="ValorMercancia" type="text"
                                    [ngClass]="{'form-control-custom-error':errorValorMercancia, 'form-control':!errorValorMercancia}"
                                    #ValorMercancia="ngModel" [(ngModel)]="formModelAereo.value.ValorMercancia"
                                    [readonly]="visor" required
                                    (focusout)="compruebaValorMercancia(formEnvioAereo);calcularSeguro();">
                                <div *ngIf="errorValorMercancia || (formEnvioAereo.submitted && (ValorMercancia.value == null || ValorMercancia.value == '')) || (ValorMercancia.touched && (ValorMercancia.value == null 
                            || ValorMercancia.value == ''))" class="text-danger"
                                    style="margin-bottom: 20px;font-size: small;">
                                    {{'Valor mercancia obligatorio' | translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <label>{{'Divisa'|translate}}</label><sup><span mat-raised-button
                                        matTooltip="{{'infoDivisa' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <ng-select name="TipoDivisa" id="TipoDivisa" class="select-small" #TipoDivisa="ngModel"
                                    [readonly]="visor || isCotizacion" bindLabel="TipoDivisa" bindValue="IdTipoDivisa"
                                    [items]="listDivisas" [(ngModel)]="formModelAereo.value.TipoDivisa"
                                    [loading]="cargaTipoDivisa" loadingText="Cargando...">
                                </ng-select>
                            </div>
                            <div class="col-sm-2" *ngIf="esEspanyaItalia()">
                                <label>{{'Coste del Seguro'|translate}}</label><sup><span mat-raised-button
                                        matTooltip="{{'infoSeguro' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input id="SeguroMercancia" name="SeguroMercancia" type="text"
                                    style="margin-bottom: 20px;" class="form-control-custom" #SeguroMercancia="ngModel"
                                    [(ngModel)]="formModelAereo.value.SeguroMercancia" readonly
                                    [disabled]="visor || isCotizacion">
                            </div>
                            <div class="col-sm-2" *ngIf="esEspanyaItalia()">
                                <label>{{'¿Quiere asegurar su mercancia?'|translate}}</label>
                                <mat-radio-group name="Asegurado" #Asegurado="ngModel"
                                    [(ngModel)]="formModelAereo.value.Asegurado" id="Asegurado"
                                    [disabled]="visor || isCotizacion" aria-label="¿Desea asegurar su carga?">
                                    <mat-radio-button [value]="true"
                                        [disabled]="visor">{{'Si'|translate}}</mat-radio-button>
                                    <mat-radio-button [value]="false"
                                        [disabled]="visor">{{'No'|translate}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <!-- BLOQUE DINAMICO-->
                    <div id="bloquedinamico">
                        <!-- QUINTO BLOQUE -->
                        <div id="quintobloque" class="row">
                            <div class="col-sm-4">
                                <label>{{'Aeropuerto Origen'|translate}}</label>
                                <ng-select name="AeropuestoOrigen" id="AeropuertoOrigen" class="select-small"
                                    #AeropuertoOrigen="ngModel"
                                    [readonly]="visor || isCotizacion || (esTarifa && hayAereopuertoOrigen)"
                                    [(ngModel)]="formModelAereo.value.AeropuertoOrigen" style="margin-bottom: 20px;"
                                    [loading]="cargaAeropuerto" loadingText="Cargando...">
                                    <ng-option *ngFor="let a of listAeropuertoOrigen" [value]="a.IdAeropuerto">
                                        <img class="bandera" alt="b" src="{{getBandera(a)}}">&nbsp;&nbsp;{{a.Nombre}}
                                        <!--&nbsp;-->
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-sm-4">
                                <label>{{'Aeropuerto Destino'|translate}}</label>
                                <ng-select name="AeropuestoDestino" id="AeropuertoDestino" class="select-small"
                                    #AeropuertoDestino="ngModel"
                                    [readonly]="visor || isCotizacion || (esTarifa && !hayAereopuertoOrigen)"
                                    [(ngModel)]="formModelAereo.value.AeropuertoDestino" style="margin-bottom: 20px;"
                                    [loading]="cargaAeropuerto" loadingText="Cargando...">
                                    <ng-option *ngFor="let a of listAeropuertoDestino" [value]="a.IdAeropuerto">
                                        <img class="bandera" alt="b" src="{{getBandera(a)}}">&nbsp;&nbsp;{{a.Nombre}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-sm-3">
                                <label>{{'Aerolinea'|translate}}</label>
                                <input type="text" name="Aerolinea" id="Aerolinea" class="form-control" #Aerolinea
                                    [(ngModel)]="formModelAereo.value.Aerolinea" style="margin-bottom: 20px;"
                                    [readonly]="visor || isCotizacion">
                            </div>
                        </div>
                    </div>

                    <!-- SEPTIMO BLOQUE -->
                    <div id="Recogida" class="contenedor">
                        <h5>{{getTituloRecogida()}}</h5>
                        <div class="row" style="margin: 1px;">
                            <div class="col-sm-3">
                                <label>{{'Entidad'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoEntidadCarga' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <ng-select name="EntidadRecogida" id="EntidadRecogida"
                                    [ngClass]="{'error':errorEntidadRecogida}" #EntidadRecogida="ngModel"
                                    [items]="listShipper" (focusout)="compruebaEntidadRecogida(formEnvioAereo)"
                                    bindLabel="NombreFiscal" [readonly]="visor"
                                    [(ngModel)]="formModelAereo.value.EntidadRecogida"
                                    (change)="setRecogidaInformation();" style="margin-bottom: 10px;"
                                    [loading]="cargaEntidadRecogida" loadingText="Cargando...">
                                </ng-select>
                                <div *ngIf="errorEntidadRecogida || (formEnvioAereo.submitted && (EntidadRecogida.value == null || EntidadRecogida.value == '')) 
                            || (EntidadRecogida.touched && (formEnvioAereo.value == null || EntidadRecogida.value == ''))"
                                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                    {{'Entidad obligatorio' | translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <!-- Al clicar abre modal para seleccionar entidad con direccion -->
                                <button title="{{'Buscar entidad'|translate}}" type="button" class="btn"
                                    style="margin-top: 28px; margin-right: 10px;"
                                    (click)="openModal2(templateSearchEntity, 'recogida')" [disabled]="visor">
                                    <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;" alt="">
                                </button>
                                <button title="{{'Añadir entidad'|translate}}" type="button" class="btn"
                                    style="margin-top: 28px;" (click)="openModal2(entityTemplate, 'recogida')"
                                    [disabled]="visor">
                                    <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;" alt="">
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <label>{{'Referencia de Carga' | translate}}</label>*<sup><span mat-raised-button
                                        matTooltip="{{'infoReferenciaCarga' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="text" id="ReferenciaRecogida" name="ReferenciaRecogida"
                                    [readonly]="visor"
                                    [ngClass]="{'form-control-custom-error':errorReferenciaRecogida, 'form-control':!errorReferenciaRecogida}"
                                    #ReferenciaRecogida="ngModel"
                                    (focusout)="compruebaReferenciaRecogida(formEnvioAereo)" style="margin-bottom: 5px;"
                                    [(ngModel)]="formModelAereo.value.ReferenciaRecogida" required>
                                <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="errorReferenciaRecogida || (formEnvioAereo.submitted && (FechaRecogida.value == null || FechaRecogida.value == '')) || (FechaRecogida.touched 
                            && (FechaRecogida.value == null || FechaRecogida.value == ''))" class="text-danger">
                                    {{'Referencia de carga obligatoria'|translate}}
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-4">
                                <label>{{'Dirección Recogida'|translate}}</label>
                                <textarea autocomplete="off" class="form-control-custom" name="ResumenDireccionRecogida"
                                    id="ResumenDireccionRecogida" rows="4" readonly style="margin-right: 10px;"
                                    [(ngModel)]="resumenDireccionRecogida"></textarea>
                            </div>
                            <div class="col-sm-7">
                                <label>{{'Instrucciones Recogida'|translate}}</label>
                                <textarea autocomplete="off" maxlength="{{maxlengthInstrucctions}}" rows="4" type="text"
                                    id="IntruccionesRecogida" name="InstruccionesRecogida" [readonly]="visor"
                                    class="form-control-custom" #InstruccionesRecogida="ngModel"
                                    style="margin-bottom: 5px;width: 109%;"
                                    [(ngModel)]="formModelAereo.value.InstruccionesRecogida"></textarea>
                                <span>{{formModelAereo.value.InstruccionesRecogida.length}}/{{maxlengthInstrucctions}}</span>
                            </div>
                        </div>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-3">
                                <label>{{'Mercancia disponible desde'|translate}}</label>*<sup><span mat-raised-button
                                        matTooltip="{{'infoFechaRecogida'|translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" name="FechaRecogida" type="date" id="FechaRecogida"
                                    name="FechaRecogida" min="{{today | date:'yyyy-MM-dd'}}"
                                    style="height: 36px;margin-bottom: 5px;" [readonly]="visor"
                                    [ngClass]="{'form-control-custom-error':errorFechaRecogida || errorFechaRecogidaMenorActual , 'form-control':!errorFechaRecogida && !errorFechaRecogidaMenorActual}"
                                    #FechaRecogida="ngModel" [(ngModel)]="formModelAereo.value.FechaRecogida"
                                    (focusout)="compruebaFechaRecogida(formEnvioAereo)" required>
                                <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="errorFechaRecogida || (formEnvioAereo.submitted && (FechaRecogida.value == null || FechaRecogida.value == '')) || (FechaRecogida.touched 
                            && (FechaRecogida.value == null || FechaRecogida.value == ''))" class="text-danger">
                                    {{'Fecha disponibilidad obligatoria'|translate}}
                                </div>
                                <div style="height: 36px;margin-bottom: 5px;font-size: small;"
                                    min="{{today | date:'yyyy-MM-dd'}}"
                                    *ngIf="errorFechaRecogidaMenorActual || (formEnvioAereo.submitted && (FechaRecogida.touched && validarFechaRecogida()))"
                                    class="text-danger">
                                    {{'Fecha disponibilidad inferior a la de hoy' | translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <label>{{'Desde'|translate}}</label><sup><span mat-raised-button
                                        matTooltip="{{'infoDesde' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" name="HoraRecogidaInicio1" type="time"
                                    id="HoraRecogidaInicio1" class="form-control"
                                    style="margin-bottom: 5px;width: 65%;height: 36px;" #HoraRecogidaInicio1="ngModel"
                                    [(ngModel)]="formModelAereo.value.HoraRecogidaInicio1" [readonly]="visor">
                            </div>
                            <div class="col-sm-2">
                                <label>{{'Hasta'|translate}}</label>
                                <input autocomplete="off" name="HoraRecogidaFinal1" type="time" id="HoraRecogidaFinal1"
                                    class="form-control" style="margin-bottom: 5px;width: 65%;height: 36px;"
                                    #HoraRecogidaFinal1="ngModel" [(ngModel)]="formModelAereo.value.HoraRecogidaFinal1"
                                    [readonly]="visor">
                            </div>
                            <div class="col-sm-2">
                                <label>{{'Desde'|translate}}</label>
                                <input autocomplete="off" name="HoraRecogidaInicio2" type="time"
                                    id="HoraRecogidaInicio2" class="form-control"
                                    style="margin-bottom: 5px;width: 65%;height: 36px;" #HoraRecogidaInicio2="ngModel"
                                    [(ngModel)]="formModelAereo.value.HoraRecogidaInicio2" [readonly]="visor">
                            </div>
                            <div class="col-sm-2">
                                <label>{{'Hasta'|translate}}</label>
                                <input autocomplete="off" name="HoraRecogidaFinal2" type="time" id="HoraRecogidaFinal2"
                                    class="form-control" style="margin-bottom: 5px;width: 65%;height: 36px;"
                                    #HoraRecogidaFinal2="ngModel" [(ngModel)]="formModelAereo.value.HoraRecogidaFinal2"
                                    [readonly]="visor">
                            </div>
                        </div>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-3">
                                <label>{{'Persona Contacto'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoPersonaContactoCarga' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="text" id="PersonaContactoRecogida"
                                    name="PersonaContactoRecogida" [readonly]="visor" #PersonaContactoRecogida="ngModel"
                                    style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.PersonaContactoRecogida"
                                    (focusout)="compruebaPersonaContactoRecogida(formEnvioAereo)"
                                    [ngClass]="{'form-control-custom-error':errorPersonaContactoRecogida, 'form-control-custom':!errorPersonaContactoRecogida}"
                                    [required]="recogidaRequired(formEnvioAereo)">
                                <div *ngIf="errorPersonaContactoRecogida || (errorPersonaContactoRecogida && (formEnvioAereo.submitted && (PersonaContactoRecogida.value == null || PersonaContactoRecogida.value == '')))
                            || (errorPersonaContactoRecogida && (PersonaContactoRecogida.touched && (formEnvioAereo.value == null || PersonaContactoRecogida.value == '')))"
                                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                    {{'Persona de contacto de recogida obligatorio'|translate}}
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>{{'Teléfono'|translate}}</label>*<sup><span mat-raised-button
                                        matTooltip="{{'infoTelefonoCarga' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="text" id="TelefonoRecogida" name="TelefonoRecogida"
                                    [readonly]="visor" #TelefonoRecogida="ngModel" style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.TelefonoRecogida"
                                    (focusout)="compruebaTelefonoRecogida(formEnvioAereo)"
                                    [ngClass]="{'form-control-custom-error':errorTelefonoRecogida, 'form-control-custom':!errorTelefonoRecogida}"
                                    [required]="recogidaRequired(formEnvioAereo)">
                                <div *ngIf="errorTelefonoRecogida || (errorTelefonoRecogida && (formEnvioAereo.submitted && (TelefonoRecogida.value == null || TelefonoRecogida.value == ''))) 
                            || (errorTelefonoRecogida && (TelefonoRecogida.touched && (formEnvioAereo.value == null || TelefonoRecogida.value == '')))"
                                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                    {{'Teléfono de contacto de recogida obligatorio'|translate}}
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>{{'Email'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoEmailCarga' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="email" id="EmailRecogida" name="EmailRecogida"
                                    [readonly]="visor" #EmailRecogida="ngModel" style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.EmailRecogida"
                                    (focusout)="compruebaEmailRecogida(formEnvioAereo)"
                                    [ngClass]="{'form-control-custom-error':errorEmailRecogida, 'form-control':!errorEmailRecogida}"
                                    [required]="recogidaRequired(formEnvioAereo)">
                                <div *ngIf="errorEmailRecogida || (errorEmailRecogida && (formEnvioAereo.submitted && (EmailRecogida.value == null || EmailRecogida.value == ''))) 
                            || (errorEmailRecogida && (EmailRecogida.touched && (formEnvioAereo.value == null || EmailRecogida.value == '')))"
                                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                    {{'Email de contacto de recogida obligatorio'|translate}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="DespachoAduanas" class="contenedor" hidden>
                        <h5>{{'DespachoAduanas'|translate}}</h5>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-3">
                                <label>{{'DespachoOrigen'|translate}}</label><sup><span mat-raised-button
                                        matTooltip="{{'infoDespachoOrigen' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input id="DespachoOrigen" name="DespachoOrigen" type="text" autocomplete="off"
                                    [readonly]="visor" #DespachoOrigen style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.DespachoOrigen" class="form-control">
                            </div>
                            <div class="col-sm-3"
                                *ngIf="(formModelAereo.value.TipoEnvio == 3 && formModelAereo.value.Incoterm == 'DDP') || formModelAereo.value.TipoEnvio == 4">
                                <label>{{'DespachoDestino'|translate}}</label><sup><span mat-raised-button
                                        matTooltip="{{'infoDespachoDestino' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input id="DespachoDestino" name="DespachoDestino" type="text" autocomplete="off"
                                    [readonly]="visor" #DespachoDestino style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.DespachoDestino" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div id="Entrega" class="contenedor"
                        *ngIf="incotermStartWith() || formModelAereo.value.TipoEnvio == 4">
                        <h5>{{'Entrega'|translate}}</h5>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-3">
                                <label>{{'Entidad'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoEntidadEntrega' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <ng-select name="EntidadEntrega" id="EntidadEntrega"
                                    (focusout)="compruebaEntidadEntrega(formEnvioAereo)"
                                    [ngClass]="{'error':errorEntidadEntrega && incotermStartWith()}"
                                    #EntidadEntrega="ngModel" [items]="listConsignee" bindLabel="NombreFiscal"
                                    [readonly]="visor" [(ngModel)]="formModelAereo.value.EntidadEntrega"
                                    style="margin-bottom: 10px;" (change)="setEntregaInformation();"
                                    [loading]="cargaEntidadEntrega" loadingText="Cargando...">
                                </ng-select>
                                <div *ngIf="errorEntidadEntrega || (formEnvioAereo.submitted && (EntidadEntrega.value == null || EntidadEntrega.value == '')) 
                            || (EntidadEntrega.touched && (formEnvioAereo.value == null || EntidadEntrega.value == ''))"
                                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                    {{'Entidad de entrega obligatorio'|translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <!-- Al clicar abre modal para seleccionar entidad con direccion -->
                                <button title="{{'Buscar entidad'|translate}}" type="button" class="btn"
                                    style="margin-top: 28px; margin-right: 10px;"
                                    (click)="openModal2(templateSearchEntity, 'entrega')" [disabled]="visor">
                                    <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;" alt="">
                                </button>
                                <button title="{{'Añadir entidad'|translate}}" type="button" class="btn"
                                    style="margin-top: 28px;" (click)="openModal2(entityTemplate, 'entrega')"
                                    [disabled]="visor">
                                    <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;" alt="">
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <label>{{'Referencia Entrega'|translate}}</label><sup><span mat-raised-button
                                        matTooltip="{{'infoReferenciaEntrega' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="text" id="ReferenciaEntrega" name="ReferenciaEntrega"
                                    [readonly]="visor"
                                    [ngClass]="{'form-control-custom-error':errorReferenciaEntrega, 'form-control':!errorReferenciaEntrega}"
                                    #ReferenciaEntrega="ngModel" style="margin-bottom: 5px;"
                                    [(ngModel)]="formModelAereo.value.ReferenciaEntrega"
                                    (focusout)="compruebaReferenciaEntrega(formEnvioAereo)" required>
                                <div *ngIf="errorReferenciaEntrega || (formEnvioAereo.submitted && (EntidadEntrega.value == null || EntidadEntrega.value == '')) 
                            || (EntidadEntrega.touched && (formEnvioAereo.value == null || EntidadEntrega.value == ''))"
                                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                    {{'Referencia de entrega obligatorio'|translate}}
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-4">
                                <label>{{'Dirección Entrega'|translate}}</label>
                                <textarea autocomplete="off" class="form-control-custom" name="ResumenDireccionEntrega"
                                    id="ResumenDireccionEntrega" rows="4" style="margin-right: 10px;"
                                    [(ngModel)]="resumenDireccionEntrega" readonly></textarea>
                            </div>
                            <div class="col-sm-7">
                                <label>{{'Instrucciones Entrega'|translate}}</label>
                                <textarea autocomplete="off" rows="4" type="text" id="IntruccionesEntrega"
                                    name="InstruccionesEntrega" [readonly]="visor" class="form-control-custom"
                                    #InstruccionesEntrega="ngModel" style="margin-bottom: 5px;width: 109%;"
                                    maxlength="{{maxlengthInstrucctions}}"
                                    [(ngModel)]="formModelAereo.value.InstruccionesEntrega"></textarea>
                                <span>{{formModelAereo.value.InstruccionesEntrega.length}}/{{maxlengthInstrucctions}}</span>
                            </div>
                        </div>
                        <div class="row" style="margin-left: 1px;">
                            <div class="col-sm-3">
                                <label>{{'Persona Contacto'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoPersonaContactoEntrega' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="text" id="PersonaContactoEntrega"
                                    name="PersonaContactoEntrega" [readonly]="visor"
                                    [ngClass]="{'form-control-custom-error':errorPersonaContactoEntrega, 'form-control-custom':!errorPersonaContactoEntrega}"
                                    #PersonaContactoEntrega="ngModel"
                                    (focusout)="compruebaPersonaContactoEntrega(formEnvioAereo)"
                                    style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.PersonaContactoEntrega"
                                    [required]="incotermStartWith() && entregaRequired(formEnvioAereo)">
                                <div *ngIf="formModelAereo.value.TipoEnvio == 3 && incotermStartWith()">
                                    <div *ngIf="errorPersonaContactoEntrega || (errorPersonaContactoEntrega && (formEnvioAereo.submitted && (PersonaContactoEntrega.value == null || PersonaContactoEntrega.value == '')))
                                || (errorPersonaContactoEntrega && (PersonaContactoEntrega.touched && (formEnvioAereo.value == null || PersonaContactoEntrega.value == '')))"
                                        class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                        {{'Persona de contacto de entrega obligatorio'|translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>{{'Teléfono'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoTelefonoEntrega' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="text" id="TelefonoEntrega" name="TelefonoEntrega"
                                    [readonly]="visor"
                                    [ngClass]="{'form-control-custom-error':errorTelefonoEntrega, 'form-control-custom':!errorTelefonoEntrega}"
                                    #TelefonoEntrega="ngModel" style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.TelefonoEntrega"
                                    (focusout)="compruebaTelefonoEntrega(formEnvioAereo)"
                                    [required]="incotermStartWith() && entregaRequired(formEnvioAereo)">
                                <div *ngIf="formModelAereo.value.TipoEnvio == 3 && incotermStartWith()">
                                    <div *ngIf="errorTelefonoEntrega || (errorTelefonoEntrega && (formEnvioAereo.submitted && (TelefonoEntrega.value == null || TelefonoEntrega.value == ''))) 
                                || (errorTelefonoEntrega && (TelefonoEntrega.touched && (formEnvioAereo.value == null || TelefonoEntrega.value == '')))"
                                        class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                        {{'Teléfono de contacto de entrega obligatorio'|translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>{{'Email'|translate}}*</label><sup><span mat-raised-button
                                        matTooltip="{{'infoEmailEntrega' | translate}}"
                                        matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                            style="width: 12px;height: 12px;"></span></sup>
                                <input autocomplete="off" type="email" id="EmailEntrega" name="EmailEntrega"
                                    [readonly]="visor"
                                    [ngClass]="{'form-control-custom-error':errorEmailEntrega, 'form-control':!errorEmailEntrega}"
                                    #EmailEntrega="ngModel" style="margin-bottom: 10px;"
                                    [(ngModel)]="formModelAereo.value.EmailEntrega"
                                    (focusout)="compruebaEmailEntrega(formEnvioAereo)"
                                    [required]="incotermStartWith() && entregaRequired(formEnvioAereo)">
                                <div *ngIf="formModelAereo.value.TipoEnvio == 3 && incotermStartWith()">
                                    <div *ngIf="errorEmailEntrega || (errorEmailEntrega && (formEnvioAereo.submitted && (EmailEntrega.value == null || EmailEntrega.value == ''))) 
                                || (errorEmailEntrega && (EmailEntrega.touched && (formEnvioAereo.value == null || EmailEntrega.value == '')))"
                                        class="text-danger" style="margin-bottom: 10px;font-size: small;">
                                        {{'Email de contacto de entrega obligatorio'|translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                    <div class="col-12">
                      <div class="contenedor">
                        <h5>{{'VGM'|translate}}</h5>
                      <div class="row mlauto mb1coma7 mr0coma1">
                        <div class="col-12 col-xl-6">
                          <div class="row">
                            <div class="col-12">
                              <label>{{"VGMPesaje" | translate}}</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 ml0 pl0">
                              <mat-radio-group name="VGMPesaje" #VGMPesaje [(ngModel)]="formModelAereo.value.VGMPesaje" id="VGMPesaje" [disabled]="visor || isCotizacion"
                              aria-label="VGMPesaje">
                                <mat-radio-button [value]="true" [disabled]="visor">{{'Cliente'|translate}}</mat-radio-button>
                                <mat-radio-button [value]="false" [disabled]="visor">{{'XGL'|translate}}</mat-radio-button>
                              </mat-radio-group>
                              <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="(formEnvioAereo.submitted && (VGMPesaje.value == null || VGMPesaje.value == undefined)) 
                              || (VGMPesaje.touched && (VGMPesaje.value == null || VGMPesaje.value == undefined))"
                                class="text-danger">
                                {{'VGMPesajeRequired'|translate}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-xl-6">
                          <div class="row">
                            <div class="col-12">
                              <label>{{"VGMTransmision" | translate}}</label>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <mat-radio-group name="VGMTransmision" #VGMTransmision [(ngModel)]="formModelAereo.value.VGMTransmision" id="VGMTransmision" [disabled]="visor || isCotizacion"
                              aria-label="VGMTransmision">
                                <mat-radio-button [value]="true" [disabled]="visor">{{'Cliente'|translate}}</mat-radio-button>
                                <mat-radio-button [value]="false" [disabled]="visor">{{'XGL'|translate}}</mat-radio-button>
                              </mat-radio-group>
                              <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="(formEnvioAereo.submitted && (VGMTransmision.value == null || VGMTransmision.value == undefined)) 
                              || (VGMTransmision.touched && (VGMTransmision.value == null || VGMTransmision.value == undefined))"
                                class="text-danger">
                                {{'VGMTransmisionRequired'|translate}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                    <!-- <div id="mercanciaPeligrosa" class="row">
                    <div class="col-sm-3">
                        <div class="form-check" style="margin-top: 20px;">
                            <input name="MercanciaPeligrosa" type="checkbox" class="form-check-input" style="margin-bottom: 20px;" 
                            id="checkMercanciaPeligrosa" #MercanciaPeligrosa="ngModel" [(ngModel)]="formModelAereo.value.MercanciaPeligrosa" 
                            [disabled]="visor || isCotizacion" (click)="setMercPeligrosa(formEnvioAereo);" (change)="calcularSeguro()">
                            <label class="form-check-label" style="margin-bottom: 20px;" for="MercanciaPeligrosa">{{'Mercancia peligrosa'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check" style="margin-top: 20px;">
                            <input name="NoRemontable" type="checkbox" class="form-check-input" style="margin-bottom: 20px;" 
                                id="checkRemontable" #NoRemontable="ngModel" [(ngModel)]="formModelAereo.value.NoRemontable" 
                                [disabled]="visor || isCotizacion" (change)="setNoRemontable($event)">
                            <label class="form-check-label" style="margin-bottom: 20px;" for="MercanciaNoRemontable">{{'MercNoRemontable'|translate}}</label>
                        </div>
                    </div>
                </div> -->
                    <!-- OCTAVO BLOQUE -->
                    <div class="row" id="octavobloque">
                        <div class="col-sm-12" *ngIf="listaMercancia != [] && listaMercancia != undefined">
                            <app-cargo [listaMercancia]="listaMercancia" (listaAux)="listaMerc($event)"
                                [visor]="visor || isCotizacion" [editar]="editar" [fcl]="esFCL"
                                [area]="formModelAereo.value.IdArea"
                                [mercanciaPeligrosa]="formModelAereo.value.MercanciaPeligrosa"
                                [cliente]="formModelAereo.value.Cliente" [isTarifa]="esTarifa"></app-cargo>
                        </div>
                        <div class="col-sm-12" *ngIf="listaMercancia == [] || listaMercancia == undefined">
                            <app-cargo (listaAux)="listaMerc($event)" [fcl]="esFCL"
                                [mercanciaPeligrosa]="formModelAereo.value.MercanciaPeligrosa"
                                [cliente]="formModelAereo.value.Cliente" [isTarifa]="esTarifa"></app-cargo>
                        </div>
                    </div>
                    <div id="Pesos" class="contenedor">
                        <h5>{{'Totales'|translate}}</h5>
                        <div class="row" style="margin: 5px;">
                            <div class="col-sm-2">
                                <label class="totalizadores">{{'Bultos Totales'|translate}}*</label>
                                <input autocomplete="off" name="BultosTotales" type="text"
                                    [(ngModel)]="formModelAereo.value.BultosTotales"
                                    [ngClass]="{'form-control-custom-error totalizadores':errorBultosTotales, 'form-control totalizadores':!errorBultosTotales}"
                                    #BultosTotales="ngModel" readonly required>
                                <div *ngIf="errorBultosTotales || (formEnvioAereo.submitted && (BultosTotales.value == null || BultosTotales.value == ''))"
                                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                    {{'Bultos Totales obligatorio'|translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <label class="totalizadores">{{'Bruto Total'|translate}} (Kg)*</label>
                                <input autocomplete="off" (change)="comprobarPesosTotales()"
                                    (focusout)="compruebaPesoBrutoTotal(formEnvioAereo); calcularPesoTasable();"
                                    class="form-control" name="PesoBrutoTotal" type="text"
                                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoBrutoTotal, 'form-control totalizadores':!errorPesoBrutoTotal}"
                                    [(ngModel)]="formModelAereo.value.PesoBrutoTotal" #PesoBrutoTotal="ngModel"
                                    [readonly]="visor || isCotizacion" required>
                                <div *ngIf="(errorPesoBrutoTotal || formEnvioAereo.submitted && (PesoBrutoTotal.value == null || PesoBrutoTotal.value == ''))"
                                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                    {{'Peso Bruto total obligatorio'|translate}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <label class="totalizadores">{{'Neto Total'|translate}} (Kg)</label>
                                <input autocomplete="off" name="PesoNetoTotal" type="text"
                                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoNetoTotal, 'form-control totalizadores':!errorPesoNetoTotal}"
                                    (focusout)="compruebaPesoNetoTotal()"
                                    [(ngModel)]="formModelAereo.value.PesoNetoTotal" #PesoNetoTotal="ngModel"
                                    [readonly]="visor || isCotizacion" required>
                                <!-- <div *ngIf="errorPesoNetoTotal || (formEnvioAereo.submitted && (PesoNetoTotal.value == null || PesoNetoTotal.value == ''))" class="text-danger" 
                            style="margin-bottom: 20px;font-size: small;">
                                {{'Peso Neto total obligatorio'|translate}}
                            </div> -->
                            </div>
                            <div class="col-sm-2">
                                <label class="totalizadores">{{'Peso Tasable'|translate}} (Kg)</label>
                                <input autocomplete="off" name="PesoTasable" type="text" class="form-control"
                                    [(ngModel)]="formModelAereo.value.PesoTasable" #PesoTasable="ngModel" readonly>
                            </div>
                            <div class="col-sm-2">
                                <label class="totalizadores">{{'Volumen Total'|translate}}(m<sup>3</sup>)*</label>
                                <input autocomplete="off" name="VolumenTotal" type="text"
                                    (focusout)="calcularPesoTasable();"
                                    [ngClass]="{'form-control-custom-error totalizadores':errorVolumenTotal, 'form-control totalizadores':!errorVolumenTotal}"
                                    [(ngModel)]="formModelAereo.value.VolumenTotal" #VolumenTotal="ngModel" readonly
                                    required>
                                <div *ngIf="errorVolumenTotal || (formEnvioAereo.submitted && (VolumenTotal.value == null || VolumenTotal.value == ''))"
                                    class="text-danger" style="font-size: small;margin-bottom: 20px;margin-left: 10px;">
                                    {{'Volumen Total obligatorio'|translate}}
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin: 5px;">
                            <div class="col-sm-2" *ngIf="formModelAereo.value.MercanciaPeligrosa == true">
                                <label class="totalizadores" style="margin-right: 0px;display:inline-block;">{{'Bruto
                                    Total'|translate}}(Kg)(DGR)</label>
                                <input id="PesoBrutoTotalDGR" autocomplete="off" name="PesoBrutoTotalDGR" type="text"
                                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoBruto, 'form-control totalizadores':!errorPesoBruto}"
                                    class="form-control totalizadores"
                                    [(ngModel)]="formModelAereo.value.PesoBrutoTotalDGR" #PesoBrutoTotalDGR="ngModel"
                                    readonly required>
                            </div>
                            <div class="col-sm-2" *ngIf="formModelAereo.value.MercanciaPeligrosa == true">
                                <label class="totalizadores">{{'Neto Total'|translate}}(Kg)(DGR)*</label>
                                <input id="PesoNetoTotalDGR" autocomplete="off" name="PesoNetoTotalDGR" type="text"
                                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoNeto, 'form-control totalizadores':!errorPesoNeto}"
                                    [(ngModel)]="formModelAereo.value.PesoNetoTotalDGR" #PesoNetoTotalDGR="ngModel"
                                    readonly required>
                                    {{'Peso Neto total DGR obligatorio'|translate}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                    <div class="col-sm-2">
                        <label matTooltip="{{hscodeConcat}}" matTooltipClass="my-custom-tooltip">{{'Previsualizar HS Code' |translate}}</label><sup><span mat-raised-button matTooltip="{{'infoHSCode' | translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                        <button class="btn" type="button" (click)="openModalLG(templateHSCodes)">{{'Añadir HSCodes' |translate}}</button>
                    </div>
                </div> -->
                    <div class="row">
                        <div class="col-sm-6">
                            <!--style="margin-bottom: 20px;"-->
                            <label>{{'Insutrcciones AWB'|translate}}*</label><sup><span mat-raised-button
                                    matTooltip="{{'infoInstruccionesBLAWB' | translate}}"
                                    matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                        style="width: 12px;height: 12px;"></span></sup>
                            <textarea id="InstruccionesBLAWB" autocomplete="off" class="form-control"
                                name="InstruccionesBLAWB" maxlength="{{maxlengthInstruccionesBLAWB}}"
                                style="margin-bottom: 5px;"
                                [ngClass]="{'error':errorInstruccionesBLAWB, '':!errorInstruccionesBLAWB}" rows="5"
                                #InstruccionesBLAWB="ngModel" [(ngModel)]="formModelAereo.value.InstruccionesBLAWB"
                                [readonly]="visor" required
                                (focusout)="compruebaInstruccionesBLAWB(formEnvioAereo)"></textarea>
                            <span>{{formModelAereo.value.InstruccionesBLAWB.length}}/{{maxlengthInstruccionesBLAWB}}</span>
                            <div *ngIf="errorInstruccionesBLAWB || (formEnvioAereo.submitted && (InstruccionesBLAWB.value == null || InstruccionesBLAWB.value == ''))"
                                class="text-danger" style="margin-bottom: 20px;font-size: small;">
                                {{'InstruccionesBLAWBObligatoria'|translate}}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>{{'Marcas'|translate}}</label><sup><span mat-raised-button
                                    matTooltip="{{'infoMarcas' | translate}}" matTooltipClass="my-custom-tooltip"><img
                                        src="assets/iconos/info_64.png" alt=""
                                        style="width: 12px;height: 12px;"></span></sup>
                            <!--style="margin-bottom: 20px;"-->
                            <textarea autocomplete="off" name="Marcas" class="form-control-custom" rows="5"
                                maxlength="{{maxlengthMarcas}}" #Marcas="ngModel"
                                [(ngModel)]="formModelAereo.value.Marcas" style="margin-bottom: 5px;"
                                [readonly]="visor"></textarea>
                            <span>{{formModelAereo.value.Marcas.length}}/{{maxlengthMarcas}}</span>
                        </div>
                    </div>

                    <!-- NOVENO BLOQUE -->
                    <div id="novenobloque" class="row">
                        <div class="col-sm-12">
                            <label>{{'Instrucciones/Observaciones'|translate}}</label><sup><span mat-raised-button
                                    matTooltip="{{'infoInstrucciones' | translate}}"
                                    matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                                        style="width: 12px;height: 12px;"></span></sup>
                            <textarea autocomplete="off" name="Instrucciones" class="form-control-custom" rows="5"
                                maxlength="{{maxlengthObservaciones}}" #Instrucciones="ngModel"
                                [(ngModel)]="formModelAereo.value.Instrucciones" [readonly]="visor"
                                style="margin-bottom: 5px;"></textarea>
                            <span>{{formModelAereo.value.Instrucciones.length}}/{{maxlengthObservaciones}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8 col-12"></div>
                        <div class="col-sm-4 col-12"><br>
                            * : {{'indicador de campos obligatorios' | translate}}
                        </div>
                    </div>
                    <!-- DECIMO BLOQUE -->
                    <div id="decimobloque" class="row">
                        <div class="col-sm-8 col-12"></div>
                        <div class="col-sm-2 col-12 margen-top">
                            <app-upload-modal *ngIf="!(!visor && editar && !duplicar) && !visor" [documentacion]="false"
                                (sendFiles)="receiveFiles($event)"></app-upload-modal>
                            <app-upload-modal *ngIf="editar && !visor" [documentacion]="false"
                                (sendFiles)="receiveFiles($event)"></app-upload-modal>
                            <label *ngIf="listaArchivos.length > 0">{{listaArchivos.length}} {{'archivos
                                cargado/s'|translate}}.</label>
                        </div>
                        <div class="col-sm-2 col-12 margen-top" *ngIf="!visor">
                            <button type="button" id="enviarPlantilla" class="btn btn-block"
                                (click)="openAlert2(templateConfirmacionPlantillas)">{{'Guardar
                                Plantilla'|translate}}</button>
                        </div>
                    </div>

                    <!-- UNDECIMO BLOQUE -->
                    <div id="undecimobloque" class="row">

                        <!--<div class="col-sm-5">
                    </div>
                    <div style="margin: 10px;" class="col-sm-2" *ngIf="!(!visor && editar && !duplicar) && !visor">
                        <button type="button" id="enviarShipment" class="btn btn-block" (click)="convertFormToModel(formEnvioAereo);openAlert(template3, formEnvioAereo)">{{'Enviar'|translate}}</button>
                    </div>
                    <div style="margin: 10px;" class="col-sm-2" *ngIf="!visor && editar && !duplicar">
                        <button type="button" id="modificarShipment" class="btn btn-block" (click)="convertFormToModel(formEnvioAereo);openAlert(template3, formEnvioAereo)">{{'Modificar'|translate}}</button>
                    </div>
                    <div class="col-sm-5">
                    </div>-->
                        <div class="col-sm-8 col-12"></div>
                        <div class="col-sm-2 col-12 margen-top" *ngIf="!(!visor && editar && !duplicar) && !visor">
                            <button type="button" id="enviarShipment" class="btn btn-block"
                                (click)="convertFormToModel(formEnvioAereo);openAlert(template3, formEnvioAereo)">{{'Enviar'|translate}}</button>

                        </div>
                        <div class="col-sm-2 col-12 margen-top">
                            <button type="button" id="cancelarShipment" class="btn buttonCancelar btn-block"
                                (click)="openModalSM(templateAvisoSiNo)">{{'Cancelar'|translate}}</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- TEMPLATES -->

<ng-template #templatePlantillas>
    <div class="modal-header fondo" style="border-color: black;">
        <h2 class="modal-title">{{'Plantillas'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body fondo">
        <app-plantillas [idArea]="formModelAereo.value.IdArea" (send)="getPlantilla($event);"></app-plantillas>
    </div>
</ng-template>

<ng-template #templateAvisoSiNo>
    <div class="modal-header fondo" style="border-color: black;">
        <h2 class="modal-title">{{'CancelarTitleShipment'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body fondo">
        <app-aviso-si-no [texto]="texto" (respuesta)="obtenerRespuesta($event);"></app-aviso-si-no>
    </div>
</ng-template>

<ng-template #template>
    <div class="modal-header fondo" style="border-color: black;">
        <h2 class="modal-title pull-left">{{'Direcciones entidades'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body fondo">
        <app-direcciones-entidad (address)="getDireccion($event)"
            [tipoDireccion]="tipoDireccion"></app-direcciones-entidad>
    </div>
</ng-template>

<ng-template #template2>
    <div class="modal-header fondo" style="border-color: black;">
        <h2 class="modal-title pull-left">{{'Añadir Direccion'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body fondo">
        <app-anyadir-direccion (emitDireccionEntidad)="obtenerDireccionEntidad($event)" [tipoDireccion]="tipoDireccion"
            [area]="3"></app-anyadir-direccion>
    </div>
</ng-template>

<ng-template #template3>
    <div class="modal-header" style="border-color: black; background-color: white;">
        <h2 class="modal-title pull-left">{{'ConfirmacionResumen'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" id="resumen" style="background-color: white;">
        <app-confirmacion-seguro [asegurado]="formModelAereo.value.Asegurado" (confirmEmit)="confirmacionSeguro($event)"
            [shipment]="shipmentAereo" [direccionRecogida]="resumenDireccionRecogida"
            [direccionEntrega]="resumenDireccionEntrega"></app-confirmacion-seguro>
        <div class="row" style="justify-content: right;">
            <div class="col-sm-3" class="margenBotones">
                <button type="button" class="btn" style="margin: 5px;"
                    (click)="downloadPDF()">{{'ImprimirEBooking'|translate}}</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #templateHSCodes>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
        <h2 class="modal-title pull-left">HS Codes</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body fondo">
            <app-hscodes (listHscodesEmit)="obtenerHSCodes($event)" [listaHSCodes]="formModelAereo.value.ListHSCode" [visor]="visor"></app-hscodes>
        </div>
    </div>
</ng-template> -->

<ng-template #templateConfirmacionPlantillas>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
            <h2 class="modal-title pull-left">{{'Confirmación plantilla'|translate}}</h2>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body fondo">
            <app-confirmacion-plantilla (confirmEmit)="confirmacionPlantilla($event)"></app-confirmacion-plantilla>
        </div>
    </div>
</ng-template>


<!-- The Modal -->
<ng-template #templateSearchEntity>
    <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header fondo">
            <h2 class="modal-title pull-left">{{'Localizar Entidad'|translate}}</h2>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body fondo">
            <div class="row" style="margin-left:15px;">
                <h2>{{'Entidades'|translate}}</h2>
            </div>
            <app-list-entities *ngIf="tipoEntidad == 'shipper'" [listEntities]="listShipper"
                [listEntitiesAux]="listShipper" [searchText]="searchText" (entity)="recibirShipper($event)"
                (search)="recibirSearch($event)"></app-list-entities>
            <app-list-entities *ngIf="tipoEntidad == 'consignee'" [listEntities]="listConsignee"
                [listEntitiesAux]="listConsignee" [searchText]="searchText" (entity)="recibirConsignee($event)"
                (search)="recibirSearch($event)"></app-list-entities>
            <app-list-entities *ngIf="tipoEntidad == 'notify'" [listEntities]="listNotify"
                [listEntitiesAux]="listNotify" [searchText]="searchText" (entity)="recibirNotify($event)"
                (search)="recibirSearch($event)"></app-list-entities>
            <app-list-entities *ngIf="tipoEntidad == 'recogida'" [listEntities]="listShipper"
                [listEntitiesAux]="listShipper" [searchText]="searchText" (entity)="recibirEntidadRecogida($event)"
                (search)="recibirSearch($event)"></app-list-entities>
            <app-list-entities *ngIf="tipoEntidad == 'entrega'" [listEntities]="listShipper"
                [listEntitiesAux]="listShipper" [searchText]="searchText" (entity)="recibirEntidadEntrega($event)"
                (search)="recibirSearch($event)"></app-list-entities>
        </div>
    </div>
</ng-template>

<!--
<ng-template #templateCosteMercancia>
    <div class="modal-content">

        <div class="modal-header fondo">
            <h2 class="modal-title pull-left">{{'Seguro Mercancía'|translate}}</h2>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body fondo">
            <div class="row">
                <app-aviso-seguro [precioSeguro]="formModelAereo.value.SeguroMercancia"></app-aviso-seguro>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <button id="CerrarSeguroMercancia" style="float: right;" type="button" class="btn" (click)="cerrar()">{{'Aceptar'|translate}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
-->

<ng-template #entityTemplate>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
            <h2 class="modal-title pull-left">{{'Añadir Entidad'|translate}}</h2>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body fondo">
            <app-formulario-address-book
                (emitCreateAddressBook)="refresEntidades($event);"></app-formulario-address-book>
        </div>
    </div>
</ng-template>