import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { CotizacionEnvio } from 'src/app/models/cotizacionEnvio.model';
import { Embalaje } from 'src/app/models/embalaje.model';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';
import { Mercancia } from 'src/app/models/mercancia.model';
import { EntityService } from 'src/app/services/entity.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { TipomercanciaService } from 'src/app/services/tipomercancia.service';

@Component({
  selector: 'app-resumen-cotizacion',
  templateUrl: './resumen-cotizacion.component.html',
  styleUrls: ['./resumen-cotizacion.component.scss']
})
export class ResumenCotizacionComponent implements OnInit {

  clasificacion = [{
    value: 0,
    name: "Peligrosa"
  },{
    value: 1,
    name: "Alimentación"
  },{
    value: 2,
    name: "GDP"
  },{
    value: 3,
    name: "Remontable"
  }];
  zoom: number = 10;
  selectedClaisificacion = [];
  @Input() resumen: CotizacionEnvio;
  @Output() confirmEmit = new EventEmitter<Boolean>();
  listaMercancia: Mercancia[];
  listaEmbalaje: Embalaje[];
  mercancia: Mercancia;
  tipoEnvio: string;
  modoEnvio: String;
  aeropuertoOrigen: String;
  aeropuertoDestino: String;
  puertoOrigen: String;
  puertoDestino: String;
  localidadOrigen;
  localidadDestino;
  cliente: String;
  paisDestino: string;
  paisOrigen: string;
  codigoPostalOrigen: string;
  codigoPostalDestino: string;
  displayedColumns: String[] = ['Mercancia', 'Bultos', 'TipoEmbalaje','Dimensiones', 'Neto', 'Bruto', 'Clasificacion', 'Volumen']
  displayedColumnsFCL: String[] = ['Mercancia', 'Bultos', 'TipoEmbalaje', 'NombreContenedor', 'Neto', 'Bruto', 'Clasificacion']
  displayedColumnsMP: String[] =['UN', 'PesoNeto', 'PesoBruto']
  listaMercanciaPeligrosa: MercanciaPeligrosa[];
  tipoMercancia: string;
  corresponsal: Corresponsal;
  aeropuertoPuertoOrigen;
  aeropuertoPuertoDestino;
  mapaLatitude: number;
  mapaLongitude: number;
  
  constructor(private envioService: ShipmentService, private tipomercanciaServices: TipomercanciaService, private entidadService: EntityService) { }

  ngOnInit(): void {
    this.listaMercancia = [];
    this.listaEmbalaje = [];
    this.listaMercanciaPeligrosa = [];
    this.obtenerDatosEstaticos();
  }

  ngOnChanges(simple: SimpleChange){
    this.listaMercancia = [];
    this.listaEmbalaje = [];
    this.listaMercanciaPeligrosa = [];
    this.obtenerDatosEstaticos();
  }

  esMexicoOPuebla(): boolean {
    if(this.corresponsal == null || this.corresponsal == undefined)
      return true;
    return this.corresponsal != null && this.corresponsal != undefined && (this.corresponsal.IdDelegacion == 2 || this.corresponsal.IdDelegacion == 5);
  }

  esAereo(){
    return this.resumen.IdArea == 3;
  }

  esMaritimo(){
    return this.resumen.IdArea == 2;
  }

  esTerrestre(){
    return this.resumen.IdArea == 1;
  }

  tieneLocalidadOrigen(){
    return this.resumen.LocalidadOrigen != null && this.resumen.LocalidadOrigen != undefined;
  }

  tieneLocalidadDestino(){
    return this.resumen.LocalidadDestino != null && this.resumen.LocalidadDestino != undefined;
  }
  
  confirmacion(){
    this.confirmEmit.emit(true)
  }

  cancelacion() {
    this.confirmEmit.emit(false)
  }

  // getBoundsZoomLevel(bounds, mapDim) {
  //   var WORLD_DIM = { height: 256, width: 256 };
  //   var ZOOM_MAX = 21;

  //   function latRad(lat) {
  //       var sin = Math.sin(lat * Math.PI / 180);
  //       var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  //       return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  //   }

  //   function zoom(mapPx, worldPx, fraction) {
  //       return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  //   }

  //   var ne = bounds.getNorthEast();
  //   var sw = bounds.getSouthWest();

  //   var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  //   var lngDiff = ne.lng() - sw.lng();
  //   var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  //   var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  //   var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    
  //   return Math.min(latZoom+1, lngZoom+1, ZOOM_MAX);
  // }

  obtenerDatosEstaticos(){
    this.selectedClaisificacion = [];
    this.entidadService.getCorresponsal().subscribe(corresponsal => {
      this.corresponsal = corresponsal;
    });
   this.envioService.getTipoEnvio(this.resumen.IdTipoEnvio).subscribe(data => {
      this.tipoEnvio = data.TipoEnvio;
    });
    // if(this.resumen.LocalidadOrigen != null && this.resumen.LocalidadOrigen != undefined)
    // {
    //   this.paisOrigen = this.resumen.LocalidadOrigen.Pais;
    //   this.localidadOrigen = this.resumen.LocalidadOrigen.Ciudad;
    //   this.codigoPostalOrigen = this.resumen.LocalidadOrigen.CodigoPostal;
    // }
    // if(this.resumen.LocalidadDestino != null && this.resumen.LocalidadDestino != undefined)
    // {
    //   console.log(this.resumen.LocalidadDestino);
    //   this.paisDestino = this.resumen.LocalidadDestino.Pais;
    //   this.localidadDestino = this.resumen.LocalidadDestino.Ciudad;
    //   this.codigoPostalDestino = this.resumen.LocalidadDestino.CodigoPostal;
    // }
      // this.envioService.getLocalidadById(this.resumen.IdLocalidadOrigen).subscribe(localidadOrigen => {
      //   this.localidadOrigen = localidadOrigen.Localidad;
      // });
    // if(this.resumen.IdLocalidadDestino != null)
    //   this.envioService.getLocalidadById(this.resumen.IdLocalidadDestino).subscribe(localidadDestino => {
    //     this.localidadDestino = localidadDestino.Localidad;
    //   });
    
    if(this.resumen.IdArea != 3)
      this.envioService.getModoEnvio(this.resumen.IdModoEnvio).subscribe(data => {
        this.modoEnvio = data.ModoEnvio;
      });
    if(this.resumen.IdArea == 3)
    {
      if(this.resumen.IdAeropuertoOrigen != undefined && this.resumen.IdAeropuertoOrigen != null)
        this.envioService.getAeropuertoById(this.resumen.IdAeropuertoOrigen).subscribe(data => {
          this.aeropuertoOrigen = data.Nombre;
        });
      if(this.resumen.IdAeropuertoDestino != undefined && this.resumen.IdAeropuertoDestino != null)
        this.envioService.getAeropuertoById(this.resumen.IdAeropuertoDestino).subscribe(data => {
          this.aeropuertoDestino = data.Nombre;
        });
    }
    
    if(this.resumen.IdArea == 2)
    {
      if(this.resumen.IdPuertoOrigen != undefined && this.resumen.IdPuertoOrigen != null)
        this.envioService.getPuertoById(this.resumen.IdPuertoOrigen).subscribe(data => {
          this.puertoOrigen = data.Nombre;
        });
      if(this.resumen.IdPuertoDestino != undefined && this.resumen.IdPuertoDestino != null)
        this.envioService.getPuertoById(this.resumen.IdPuertoDestino).subscribe(data => {
          this.puertoDestino = data.Nombre;
        });
    }
    //Cliente
    this.cliente = this.resumen.Cliente;
    
    this.listaMercancia = this.resumen.Mercancia;
    if(this.resumen.Mercancia != undefined)
      this.resumen.Mercancia.forEach(merc => {
        var selectedClasf = [];
        if(merc.Alimentacion)
          selectedClasf.push(this.clasificacion.find(c => c.name == "Alimentación"));
        if(merc.Peligrosa)
          selectedClasf.push(this.clasificacion.find(c => c.name == "Peligrosa"));
        if(merc.GDP)
          selectedClasf.push(this.clasificacion.find(c => c.name == "GDP"));
        if(merc.Remontable)
          selectedClasf.push(this.clasificacion.find(c => c.name == "Remontable"));
        this.selectedClaisificacion.push(selectedClasf);
        if(merc.MercanciaPeligrosa != undefined)
          merc.MercanciaPeligrosa.forEach(mercPel => {
            this.listaMercanciaPeligrosa.push(mercPel)
          });
      });

      // Localizaciones
      if(this.tieneLocalidadOrigen())
        this.localidadOrigen = {lat: this.resumen.LocalidadOrigen.Lat, lng: this.resumen.LocalidadOrigen.Lng};
      if(this.tieneLocalidadDestino())
        this.localidadDestino = {lat: this.resumen.LocalidadDestino.Lat, lng: this.resumen.LocalidadDestino.Lng};
      if(this.esAereo()){
        this.aeropuertoPuertoOrigen = {lat: this.resumen.AeropuertoOrigen.Lat, lng: this.resumen.AeropuertoOrigen.Lng};
        this.aeropuertoPuertoDestino = {lat: this.resumen.AeropuertoDestino.Lat, lng: this.resumen.AeropuertoDestino.Lng}
      }else if(this.esMaritimo()){
        this.aeropuertoPuertoOrigen = {lat: this.resumen.PuertoOrigen.Lat, lng: this.resumen.PuertoOrigen.Lng}; 
        this.aeropuertoPuertoDestino = {lat: this.resumen.PuertoDestino.Lat, lng: this.resumen.PuertoDestino.Lng}
      }
      if(this.esTerrestre() || (this.tieneLocalidadOrigen() && this.tieneLocalidadDestino()))
      {
        this.zoom = 6;
        this.mapaLatitude = (this.resumen.LocalidadOrigen.Lat + this.resumen.LocalidadDestino.Lat)/2;
        this.mapaLongitude = (this.resumen.LocalidadOrigen.Lng + this.resumen.LocalidadDestino.Lng)/2;
      }else if(this.esAereo()){
        this.zoom = 3;
        if(this.tieneLocalidadOrigen() && !this.tieneLocalidadDestino()){
          this.mapaLatitude = (this.resumen.LocalidadOrigen.Lat + this.resumen.AeropuertoDestino.Lat)/2;
          this.mapaLongitude = (this.resumen.LocalidadOrigen.Lng + this.resumen.AeropuertoDestino.Lng)/2;
        }else if(!this.tieneLocalidadOrigen() && this.tieneLocalidadDestino()){
          this.mapaLatitude = (this.resumen.LocalidadDestino.Lat + this.resumen.AeropuertoOrigen.Lat)/2;
          this.mapaLongitude = (this.resumen.LocalidadDestino.Lng + this.resumen.AeropuertoOrigen.Lng)/2;
        }else if(!this.tieneLocalidadOrigen() && !this.tieneLocalidadDestino()){
          this.mapaLatitude = (this.resumen.AeropuertoOrigen.Lat + this.resumen.AeropuertoDestino.Lat)/2;
          this.mapaLongitude = (this.resumen.AeropuertoOrigen.Lng + this.resumen.AeropuertoDestino.Lng)/2;
        }
      }else if(this.esMaritimo()){
        this.zoom = 3;
        if(this.tieneLocalidadOrigen() && !this.tieneLocalidadDestino()){
          this.mapaLatitude = (this.resumen.LocalidadOrigen.Lat + this.resumen.PuertoDestino.Lat)/2;
          this.mapaLongitude = (this.resumen.LocalidadOrigen.Lng + this.resumen.PuertoDestino.Lng)/2;
        }else if(!this.tieneLocalidadOrigen() && this.tieneLocalidadDestino()){
          this.mapaLatitude = (this.resumen.LocalidadDestino.Lat + this.resumen.PuertoOrigen.Lat)/2;
          this.mapaLongitude = (this.resumen.LocalidadDestino.Lng + this.resumen.PuertoOrigen.Lng)/2;
        }else if(!this.tieneLocalidadOrigen() && !this.tieneLocalidadDestino()){
          this.mapaLatitude = (this.resumen.PuertoOrigen.Lat + this.resumen.PuertoDestino.Lat)/2;
          this.mapaLongitude = (this.resumen.PuertoOrigen.Lng + this.resumen.PuertoDestino.Lng)/2;
        }
      }
  }

  getTextoOrigenAereoMaritimo(isAeropuertoOPuerto: boolean){
    if((this.esAereo() || this.esMaritimo()) && this.tieneLocalidadOrigen() && isAeropuertoOPuerto)
      return "2";
    return "1";
  }

  getTextoDestinoAereoMaritimo(isAeropuertoOPuerto: boolean){
    if((this.esAereo() || this.esMaritimo()) && this.tieneLocalidadOrigen() && isAeropuertoOPuerto)
      return "3";
    else if((this.esAereo() || this.esMaritimo()) && !this.tieneLocalidadOrigen() && isAeropuertoOPuerto)
      return "2";
    else if((this.esAereo() || this.esMaritimo()) && this.tieneLocalidadOrigen() && this.tieneLocalidadDestino() && !isAeropuertoOPuerto)
      return "4";
    return "3";
  }

  getStringClasificacion(index: number){
    var stringClasificacion = "";
    var contador = 0;
    this.selectedClaisificacion[index].forEach(c => {
      if(contador == 0)
        stringClasificacion += c.name;
      else
        stringClasificacion += ", " + c.name;
      contador++;
    });
    return stringClasificacion;
  }

}
