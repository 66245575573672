<!-- HEADER -->
<app-header-proveedor></app-header-proveedor>
<div class="container">
    <!-- INFORMACIÓN PERSONAL -->
    <div class="contenedor" *ngIf="perfil != null">
        <h5>{{'UsuarioInformacionLabel'| translate}}</h5>
        <!-- PRIMERA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 col-sm-4">
                        <b>{{'UsuarioLabel' | translate}}: </b>
                    </div>
                    <div class="col-8 col-sm-8">
                        <p>{{perfil.nombreUsuario}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-3 col-sm-3">
                        <b>{{'EmailLabel' | translate}}: </b>
                    </div>
                    <div class="col-9 col-sm-9">
                        <p>{{perfil.emailUsuario}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- SEGUNDA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 col-sm-4">
                        <b>{{'CaducidadContraseñaLabel' | translate}}: </b>
                    </div>
                    <div class="col-8 col-sm-8">
                        <p>{{perfil.fechaSiguienteCaducidad}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-3 col-sm-3">
                        <b>{{'TelefonoLabel' | translate}}: </b>
                    </div>
                    <div class="col-9 col-sm-9">
                        <p>{{perfil.telefonoUsuario}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- INFORMACIÓN ENTIDAD -->
    <div class="contenedor" *ngIf="perfil != null">
        <h5>{{'EmpresaInformacionLabel' | translate}}</h5>
        <!-- PRIMERA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-4 col-sm-4">
                        <b>{{'CodigoLabel' | translate }}: </b>
                    </div>
                    <div class="col-8 col-sm-8">
                        <p>{{perfil.codigo}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="row">
                    <div class="col-4 col-sm-4">
                        <b>{{'NombreLabel' | translate}}: </b>
                    </div>
                    <div class="col-8 col-sm-8">
                        <p>{{perfil.nombre}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-2">
                <div class="row">
                    <div class="col-4 col-sm-4">
                        <b>{{'CIF' | translate}}: </b>
                    </div>
                    <div class="col-8 col-sm-8">
                        <p>{{perfil.CIF}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- SEGUNDA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <b>{{'DireccionLabel' | translate}}: </b>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <p>{{getDireccion()}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- TERCERA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-6 col-sm-5">
                        <b>{{'ContactoPrincipalLabel' | translate}}: </b>
                    </div>
                    <div class="col-6 col-sm-7">
                        <p>{{perfil.contactoPrincipal}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-2 col-sm-2">
                        <b>{{'EmailLabel' | translate}}: </b>
                    </div>
                    <div class="col-10 col-sm-10">
                        <p>{{perfil.email}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-5 col-sm-5">
                        <b>{{'ApartadoCorreosLabel' | translate}}: </b>
                    </div>
                    <div class="col-7 col-sm-7">
                        <p>{{perfil.apartadoCorreos}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- CUARTA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-4" *ngFor="let telefono of perfil.telefonos; let i = index" [attr.data-index]="i">
                <div class="row">
                    <div class="col-4 col-sm-4">
                        <b>{{'TelefonoLabel' | translate}} {{i+1}}: </b>
                    </div>
                    <div class="col-8 col-sm-8">
                        <p>{{telefono}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- QUINTA LÍNEA -->
        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <b>{{'TiposVehiculosLabel' | translate}}:</b>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8">
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <p>{{getTiposVehiculos()}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- LINEAS DE TRAFICO -->
    <div class="contenedor" *ngIf="perfil != null">
        <h5>{{'LineasTraficoLabel' | translate}}</h5>
        <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-12 col-sm-6 tableCenter">
                <table mat-table [dataSource]="perfil.lineasTrafico" *ngIf="perfil.lineasTrafico.length > 0">          
                    <!-- Descripción Column -->
                    <ng-container matColumnDef="Descripcion">
                      <th mat-header-cell *matHeaderCellDef> {{'DescripcionLabel' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.nombre.toUpperCase()}} </td>
                    </ng-container>
                  
                    <!-- Origen Column -->
                    <ng-container matColumnDef="Origen">
                      <th mat-header-cell *matHeaderCellDef> {{'OrigenLabel' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.origen.toUpperCase()}} </td>
                    </ng-container>
                  
                    <!-- Destino Column -->
                    <ng-container matColumnDef="Destino">
                      <th mat-header-cell *matHeaderCellDef> {{'DestinoLabel' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.destino.toUpperCase()}} </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <p *ngIf="perfil.lineasTrafico.length == 0">{{'NoLineasTraficoText' | translate}}</p>
            </div>
            <div class="col-sm-3"></div>
        </div>
    </div>
    <!-- CAMBIO CONTRASEÑA -->
    <div id="CambioPassword" class="contenedor">
        <h5>{{'CambioContraseñaLabel' | translate}}</h5>
        <form [formGroup]="formCambiarPassword" >
            <div class="row izq">
                <div class="col">
                    <mat-form-field>
                        <mat-label class="label">{{'ContraseñaActualLabel' | translate}}</mat-label>
                        <input class="inputMaterial" [formControl]="passwordActual" required matInput type="{{stringContraActual}}" placeholder="{{'Contraseña actual' | translate}}">
                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: #FCCF61;" aria-label="ChangePassword" (click)="changePasswordActual();">
                            <mat-icon *ngIf="stringContraActual == 'password'">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="stringContraActual == 'text'">visibility_off</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordActual.hasError('required')">{{'RequeridoError' | translate}}</mat-error>
                        <mat-error *ngIf="passwordActual.hasError('minlength')">{{'Minimo6Error' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row izq">
                <div class="col col-sm">
                    <mat-form-field>
                        <mat-label class="label">{{'ContraseñaNuevaLabel' | translate}}</mat-label>
                        <input class="inputMaterial" [formControl]="passwordNueva" required matInput type="{{stringContraNueva}}" placeholder="{{'ContraseñaNuevaLabel' | translate}}">
                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: #FCCF61;" aria-label="ChangePassword" (click)="changePasswordNueva();">
                            <mat-icon *ngIf="stringContraNueva == 'password'">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="stringContraNueva == 'text'">visibility_off</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordActual.hasError('required')">{{'RequeridoError' | translate}}</mat-error>
                        <mat-error *ngIf="passwordActual.hasError('minlength')">{{'Minimo6Error' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row izq">
                <div class="col col-sm">
                    <mat-form-field>
                        <mat-label class="label">{{'RepetirContraseñaNuevaLabel' | translate}}</mat-label>
                        <input class="inputMaterial" [formControl]="passwordRepetirNueva" required matInput type="{{stringRepetirContraNueva}}" placeholder="{{'RepetirContraseñaNuevaLabel' | translate}}">
                        <button mat-button matSuffix mat-icon-button type="button" style="background-color: #FCCF61;" aria-label="ChangePassword" (click)="changePasswordRepNueva();">
                            <mat-icon *ngIf="stringRepetirContraNueva == 'password'">remove_red_eye</mat-icon>
                            <mat-icon *ngIf="stringRepetirContraNueva == 'text'">visibility_off</mat-icon>
                        </button>
                        <mat-error *ngIf="passwordActual.hasError('required')">{{'RequeridoError' | translate}}</mat-error>
                        <mat-error *ngIf="passwordActual.hasError('minlength')">{{'Minimo6Error' | translate}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row izq">
                <div class="col">
                    <re-captcha (resolved)="resolved($event)"
                    (error)="onError($event)"
                    errorMode="handled"
                    required
                    [formControl]="recaptcha"
                    siteKey="6Lf_9BIaAAAAAEORUVniW_cpPUDSHBVl5R0se-9D"></re-captcha>
                </div>
            </div>
            <div class="row izq topbot">
                <div class="col">
                    <button mat-button type="submit" (click)="cambiarPassword('cambioPassword');">{{'CambiarContraseñaLabel' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>

