import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AddressBookService } from 'src/app/services/addressBookService.service';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {

  constructor(public deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
  }

}
