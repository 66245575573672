import { Component, Input, OnInit } from '@angular/core';
import { ContenedorMercancia } from 'src/app/models/contenedorMercancia.model';
import { MercanciaCotizacion } from 'src/app/models/MercanciaCotizacion.model';
import { DetallesMercanciaPeligrosaComponent } from './detalles-mercancia-peligrosa/detalles-mercancia-peligrosa.component';

@Component({
  selector: 'app-detalles-mercancia',
  templateUrl: './detalles-mercancia.component.html',
  styleUrls: ['./detalles-mercancia.component.scss']
})
export class DetallesMercanciaComponent implements OnInit {

  @Input() mercanciasCotizacion: MercanciaCotizacion[];
  @Input() showColums: boolean;
  totalBultos: number = 0;
  totalPesoBruto: number = 0;
  mercanciaSeleccionada: boolean[] = [];
  isVisible:boolean=true;
 
  
  constructor() { }
  
  ngOnInit(): void {
    if(this.mercanciasCotizacion?.length > 0)
      this.mercanciasCotizacion.forEach(mp => {
        this.mercanciaSeleccionada.push(false);
        this.totalBultos += Number(mp.Bultos);
        this.totalPesoBruto += Number(mp.PesoBruto);
      });
  }

 

  marcarMercancia(index: number){
    this.mercanciaSeleccionada[index] = !this.mercanciaSeleccionada[index];
  }

 

  existeMercanciaPeligrosaLista(){
    var existe = false;
    this.mercanciasCotizacion.forEach(mc => {
      if(this.hayMercanciaPeligrosa(mc))
        existe = true;
    });
    return existe;
  }

  existeTemperaturaControladaLista(){
    var existe = false;
    this.mercanciasCotizacion.forEach(mc => {
      if(this.hayTemperaturaControlada(mc))
        existe = true;
    });
    return existe;
  }

  hayMercanciaPeligrosa(mercancia)
  {
    return mercancia.MercanciaPeligrosa != null && mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length > 0;
  }

  hayTemperaturaControlada(mercancia)
  {
    return mercancia.TemperaturaControlada != null && mercancia.TemperaturaControlada != undefined && mercancia.TemperaturaControlada;
  }

  estaSeleccionada(index: number){
    return this.mercanciaSeleccionada[index];
  }
}
