<div
  class="block text-center"
  *ngIf="
    listadoTarifas == undefined ||
    listadoTarifas == null ||
    listadoTarifas.length == 0
  "
>
  <h1>
    {{
      "No se ha encontrado ninguna tarifa disponible, se debe hacer spot"
        | translate
    }}
  </h1>
  <label
    class="enlace"
    for="cotizacionMaritimo"
    (click)="enviarACotizacion(null)"
    >{{ "Ir a cotizaciones" | translate }}</label
  >
</div>
<ng-container
  *ngFor="let item of listadoTarifas; let i = index"
  class="mostaza custom-table mat-table"
>
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z8 demo-table">
      <mat-expansion-panel-header class="title-container">
        <mat-panel-title class="title-container">
          <div class="col-12">
            <div class="row">
              <div class="col-8 mt1">
                <h3 class="font-weight-bold">{{ item.Nombre }}</h3>
              </div>
              <div class="col-2 mt1">
                <ng-container *ngFor="let valor of item.TotalesTarifa">
                  <h3 *ngIf="valor.Gasto > 0" class="font-weight-bold ml-5">
                    {{ "TOTAL " + valor.Divisa + ": " + valor.Gasto }}
                  </h3>
                </ng-container>
              </div>
              <div class="col-2 mt1">
                <button
                  *ngIf="!item.esCaducada && !tieneOnRequest(item)"
                  mat-raised-button
                  color="accent"
                  class="mr21px custom-button custom-button"
                  (click)="botonNodespliegaTabla($event); aceptarTarifa(item)"
                >
                  {{
                    "Aceptar
                    Tarifa" | translate
                  }}
                </button>
                <button
                  *ngIf="item.esCaducada && !tieneOnRequest(item)"
                  mat-raised-button
                  color="accent"
                  class="mr21px custom-button custom-button pt0punto5rem"
                  (click)="
                    botonNodespliegaTabla($event); postRenovarTarifa(item)
                  "
                >
                  {{ "Renovar Tarifa" | translate }}
                </button>
                <button
                  *ngIf="tieneOnRequest(item)"
                  mat-raised-button
                  color="accent"
                  class="mr21px custom-button custom-button pt0punto5rem"
                  (click)="enviarACotizacion(item)"
                >
                  {{ "Pedir cotización" | translate }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <table class="table" style="border: 1px solid black">
                  <thead>
                    <tr>
                      <ng-container *ngFor="let tt of item.TotalTipo">
                        <th
                          class="borderLeft1px borderBottom1px borderTop1px"
                          scope="col"
                        >
                          {{ tt.Tipo }} :
                        </th>
                        <th class="borderRigth1px borderBottom1px borderTop1px">
                          <span *ngIf="tt.OnRequest">On Request</span>
                          <span *ngIf="!tt.OnRequest">{{
                            getTotalString(tt.Monedas)
                          }}</span>
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <!-- <tbody>
                    <tr>
                      <td *ngFor="let tt of item.TotalTipo">
                        <span *ngIf="tt.OnRequest">On Request</span>
                        <span *ngIf="!tt.OnRequest">{{
                          getTotalString(tt.Monedas)
                        }}</span>
                      </td>
                    </tr>
                  </tbody> -->
                </table>
              </div>
            </div>
          </div>
        </mat-panel-title>

        <!-- <div class="row align-items-center">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                    </div>
                    <div class="d-flex mr8">
                    </div>
                  </div>
                </div>
              </div> -->
      </mat-expansion-panel-header>
      <mat-table
        [dataSource]="item.ListaLineas"
        class="mat-elevation-z8 demo-table"
      >
        <ng-container matColumnDef="Concepto">
          <mat-header-cell
            *matHeaderCellDef
            class="pl20px adicional-header movil"
            >{{ "Concepto" | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.Concepto }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Gasto">
          <mat-header-cell class="pl34px adicional-header" *matHeaderCellDef>{{
            "Importe" | translate
          }}</mat-header-cell>
          <mat-cell class="pl30px" *matCellDef="let element">
            <span *ngIf="!element.OnRequest">{{ element.Gasto }}</span>
            <span *ngIf="element.OnRequest">{{ "OnRequest" | translate }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Divisa">
          <mat-header-cell class="pl20px adicional-header" *matHeaderCellDef>{{
            "Divisa" | translate
          }}</mat-header-cell>
          <mat-cell class="ml1" *matCellDef="let element">{{
            element.Divisa
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Adicional">
          <mat-header-cell *matHeaderCellDef class="adicional-header">{{
            "Adicional" | translate
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.EsAdicional == true">
              <mat-radio-group
                [(ngModel)]="element.Adicional"
                (change)="comprobarRadiosAdicionales(item); getTotalesGastos()"
              >
                <mat-radio-button [value]="true">{{
                  "SI" | translate
                }}</mat-radio-button>
                <mat-radio-button [value]="false">NO</mat-radio-button>
              </mat-radio-group>
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="['Concepto', 'Gasto', 'Divisa', 'Adicional']"
        ></mat-header-row>
        <mat-row
          *matRowDef="
            let row;
            columns: ['Concepto', 'Gasto', 'Divisa', 'Adicional']
          "
        ></mat-row>
      </mat-table>

      <mat-divider></mat-divider>
      <mat-table
        [dataSource]="item.TotalesTarifa"
        class="mat-elevation-z8 demo-table"
      >
        <ng-container matColumnDef="Concepto">
          <mat-cell class="negrita" *matCellDef="let element">{{
            element.Concepto
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Gasto">
          <mat-cell class="negrita ml2" *matCellDef="let element">{{
            element.Gasto
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Divisa">
          <mat-cell class="negrita ml1" *matCellDef="let element">{{
            element.Divisa
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Adicional">
          <mat-cell *matCellDef="let element"></mat-cell>
        </ng-container>
        <mat-row
          *matRowDef="
            let row;
            columns: ['Concepto', 'Gasto', 'Divisa', 'Adicional']
          "
          [ngClass]="{ 'fila-oculta': row.Gasto <= 0 }"
        ></mat-row>
      </mat-table>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
</ng-container>

<ng-template #templateResumenTarifa class="modal-dialog">
  <div class="modal-header fondo modalResumen">
    <h2 class="modal-title">{{ "Resumen Tarifa" | translate }}</h2>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo bodyResumen">
    <app-resumen-tarifa
      [tarifaSeleccionada]="tarifaElegida"
    ></app-resumen-tarifa>
  </div>
</ng-template>
