import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CausaLoss } from '../models/causaLoss.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ma_cotizacion_causalossService {

  readonly apiUrlService = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getAllCausasLoss(){
    const url_api = this.apiUrlService + "ma_cotizacion_causaloss";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    });
    return this.http.get(url_api, { headers: headersToken })
    .pipe(map(data => data as CausaLoss[]));
  }
}
