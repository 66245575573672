export class MercanciaPeligrosaCotizacion {
    Codigo: number;
    IdMercancia: number;
    Bultos: number;
    PesoBruto: number;
    PesoNeto: number;
    Un: string;
    Clase: string;
    Pg: string;
}
