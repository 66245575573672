<div class="container fondo" >
    <div class="row" style="margin-bottom: 20px;">

    </div>
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-sm-12">
            <app-list-incidencias (incidenciaAux)="getIncidencia($event)" [listaIncidencias]="listaIncidencias"></app-list-incidencias>
        </div>
    </div>
    <div>
        <form #formIncidencias="ngForm" (ngSubmit)="enviarIncidencia(formIncidencias, 'NuevaIncidencia')">
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-sm-10"></div>
                <div class="col-sm-2">
                    <button class="btn" [hidden]="historico" style="float: right;" type="reset" (click)="resetFormIncidencias()">{{'Nueva'|translate}}</button>
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                    <label for="TipoIncidencia">{{'Tipo Incidencia'|translate}}</label>
                    <ng-select name="TipoIncidenciaSelect" id="TipoIncidenciaSelect" #TipoIncidencia="ngModel" (change)="setTipoIncidencia($event)"
                    [items]="listaTipoIncidencias" bindValue="IdTipoIncidenciaConductor" bindLabel="NombreTipoIncidenciaConductor" [(ngModel)]="tipoincidencia"
                     style="margin-bottom: 20px;" [disabled]="isHidden()">
                    </ng-select>
                    
                    <label [hidden]="isHidden()" *ngIf="!historico" class="btn" style="margin-bottom: 20px;margin-right: 20px;">{{'Añadir imagen/es'|translate}}
                        <input #foto type="file" required id="Foto" name="Foto" (change)="fileChanges($event.target.files)"
                        style="visibility: hidden;width: 0px; height: 0px;" accept="image/*;capture=camera" capture="camera"></label>
                    <label *ngIf="photo.length > 0" style="margin-left: 2%; margin-right: 2%;margin-bottom: 20px;">{{'Se ha cargado'|translate}} {{photo.length}} {{'fotos'|translate}}</label>

                    <button type="button" id="showFoto" [hidden]="hidden()" class="btn" 
                    style="margin-bottom: 20px;" (click)="UnirArrayImagenes(); openModal(templateNested)">{{'Mostrar imagen/es'|translate}}</button>
                </div>
                <div class="col-sm-4">
                    <label for="Descripcion">{{'Descripción'|translate}}</label>
                    <textarea name="DescripcionIncidencia" id="DescripcionIncidencia" #DescripcionIncidencia="ngModel" (change)="setDescripcion($event)"
                    [(ngModel)]="descripcion" cols="30" rows="10" class="form-control" style="resize: none;" 
                    [readonly]="isHidden()"></textarea>
                </div>
                <div class="col-sm-2">
                    
                </div>
            </div>
            <div class="row" style="margin-bottom: 20px;">
                <div class="col-4 col-sm-4">
                    <button [hidden]="isHidden()" id="NuevaIncidencia" style="margin-bottom: 10px;" class="btn" type="submit" >{{'Añadir'|translate}}</button>
                </div>
                
                <div class="col-4 col-sm-4">
                    <button class="btn buttonCancelar" type="button" (click)="cerrar();" data-dismiss="modal" #btnCloseNR mat-button
                    (click)="resetFormIncidencias(formIncidencias)">{{'Cerrar'|translate}}</button>
                </div>
                <div class="col-4 col-sm-4"></div>
            </div>
        </form>
    </div>
</div>

<ng-template #templateNested>
    <div class="modal-header fondo">
      <p class="h4 modal-title pull-left">{{'Galeria'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-galeria-imagenes [photos]="photoAux" [idIncidencia]="getIdIncidencia()"></app-galeria-imagenes>
    </div>
  </ng-template>