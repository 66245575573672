import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ActivationEnd, ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { Conductor } from 'src/app/models/conductor.model';
import { DestinatarioRemitente } from 'src/app/models/destinatarioRemitente.model';
import { Expedition } from 'src/app/models/expedition.model';
import { HojaRuta } from 'src/app/models/hojaRuta.model';
import { VistaProveedor } from 'src/app/models/vistaProveedor.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConductorService } from 'src/app/services/conductor.service';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { ProveedorService } from 'src/app/services/proveedor.service';

@Component({
  selector: 'app-expediciones',
  templateUrl: './expediciones.component.html',
  styleUrls: ['./expediciones.component.scss'],
  animations: [
    trigger('expandableRow', [
      state('collapsed, void', style({
        height: '0px',
        visibility: 'hidden'
      })),
      state('expanded', style({
        'min-height': '48px',
        height: '*',
        visibility: 'visible'
      })),
      transition(
        'expanded <=> collapsed, void <=> *',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ]
})
export class ExpedicionesComponent implements OnInit  {
  remitentes : string[] = []
  expedicionesPendienteRecConductores;
  expedicionesPendienteEntConductores;
  expedicionesPendientesEntregaAlbaranConductores;
  pendientesRecogida: Expedition[] = []
  openStateRecogida: boolean[] = [false, false, false];
  total: number[] = [0,0,0];
  pageActual: number[] = [1,1,1];
  remitenteSelected: DestinatarioRemitente;
  conductor: Conductor;
  sizePage: number[] = [7,7,7];
  codigoSelected: string = "";
  listaVistaProveedor: VistaProveedor[] = [];
  expedicionesHojaDeRuta;
  auxEsPrimeraVez: boolean = true;
  @ViewChild('templateCambiarPasswordPrimeraVez', {read: TemplateRef}) cambiarPasswordPrimeraVez: TemplateRef<any>;
  @ViewChild('templateCambiarPasswordEsCaducada', {read: TemplateRef}) cambiarPasswordEsCaducada: TemplateRef<any>;

  constructor(private proveedorService: ProveedorService,
    public deviceService: DeviceDetectorService, private modalService: BsModalService,
    private expeditionService: ExpeditionApiService, private toastr: ToastrService,
    private expService: ExpeditionApiService, public translate: TranslateService,
    private conductorService: ConductorService, private route: Router,
    private authenticationService: AuthenticationService){
      this.esPrimeraVez();
    }

    modalRef: BsModalRef | null;
  displayedColumns: string[] = ['Serie referencia', 'Conductor', 'Matricula', 'Expedicion', 'Estado', 'Fecha entrega', 'Fecha recogida', 'Remitente', 'Destinatario', ' '];
  dataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  expandedElement: Expedition | null;
  numeroSelected: string = "";
  expedicion: Expedition = new Expedition();
  sinAsignar: string = "Sin asignar";
  indiceEdit: number;
  
  setExpedicion(expedicion: Expedition){
    this.expedicion = expedicion;
    return this.expedicion;
  }

  openToast(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static', 
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openToastSM(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static', 
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

  esPrimeraVez(){
    this.route.events.subscribe((r: NavigationEnd) => {
      if(r instanceof NavigationEnd)
        this.authenticationService.esPrimeraVez().subscribe(isPrimeraVez => {
          if(isPrimeraVez)
            this.openModal(this.cambiarPasswordPrimeraVez);
          else
            this.esCaducada();
        });
    });
  }

  esCaducada(){
    this.authenticationService.esCaducada().subscribe(isCaducada => {
      if(isCaducada)
        this.openModal(this.cambiarPasswordEsCaducada);
    });
  }

  cerrarModal(event){
    this.modalRef.hide();
    if(event == 2){
      this.inicializar();
      this.getExpedicion(this.codigoSelected);
    }
  }

  actualizarAll(event){
    this.inicializar();
  }

  asignarNumero(numero: string){
    this.numeroSelected = numero;
  }

  getConductor(idConductor: string){
    if(idConductor != undefined){
      this.conductorService.getConductor(idConductor).subscribe(conductor => {
        this.conductor = conductor;
      })
    }
  }

  getTotalLengthListasExpediciones(c: VistaProveedor){
    return c.ExpedicionesPendientes.length + c.ExpedicionesEntregadoSinAlbaran.length + c.ExpedicionesRecogida.length + c.HojaDeRuta.length;
  }

  inicializar(){
    this.proveedorService.getExpedicionesProveedor();
    this.proveedorService.getExpediciones().subscribe(data => {
      this.listaVistaProveedor = data;
      this.expedicionesPendienteEntConductores = [];
      this.expedicionesPendienteRecConductores = [];
      this.expedicionesPendientesEntregaAlbaranConductores = [];
      this.expedicionesHojaDeRuta = [];
      this.listaVistaProveedor.forEach(c => {
        this.expedicionesPendienteEntConductores = this.expedicionesPendienteEntConductores.concat([new MatTableDataSource<Expedition>(c.ExpedicionesPendientes)]);
        this.expedicionesPendienteRecConductores = this.expedicionesPendienteRecConductores.concat([new MatTableDataSource<Expedition>(c.ExpedicionesRecogida)]);
        this.expedicionesPendientesEntregaAlbaranConductores = this.expedicionesPendientesEntregaAlbaranConductores.concat([new MatTableDataSource<Expedition>(c.ExpedicionesEntregadoSinAlbaran)])
        this.expedicionesHojaDeRuta = this.expedicionesHojaDeRuta.concat([new MatTableDataSource<HojaRuta>(c.HojaDeRuta)])     
      });
    }, err => {});
    
  }

  showDetails(exp: Expedition){
    this.expedicion = new Expedition();
    this.expService.getExpedicion(exp.Numero).subscribe(data => {
      this.expedicion = data;
    }, err => {
      var errorBody = this.translate.instant('No se puede obtener la información de la expedición.');
      var errorTitulo = this.translate.instant('Error obtener información');
      this.toastr.error(errorBody, errorTitulo)
    });
  }

  getColumnas(){
    return ['referencia', 'expedicion', 'fecha', ' '];
  }

  ngOnInit(){
    this.inicializar();
  }

  getExpedicion(codigoExpedicion){
    if(codigoExpedicion != "" && codigoExpedicion != null && codigoExpedicion != undefined){
      this.codigoSelected = codigoExpedicion;
      this.expedicion = new Expedition();
      this.expeditionService.getExpedicion(codigoExpedicion).subscribe(data => {
        this.expedicion = data as Expedition;
      });
    }
  }

  toString(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fecha = fechaTotal.split(" ")[0]
      var dia = fecha.split("/")[1]
      var mes = fecha.split("/")[0]
      var anyo = fecha.split("/")[2]
      var hora = fechaTotal.split(" ")[1]
      return dia + '/' + mes + '/' + anyo + ' a las ' + hora;
    }
  }

  // Imprimir en un string la dirección completa
  montarDireccion(direccion: string, codPostal: string, poblacion: string){
    var direccionTotal: string = "";
    if(direccion != undefined){
      direccionTotal += direccion;
    }
    if(poblacion != undefined){
      if(direccionTotal != ""){
        direccionTotal += ', ' + poblacion;
      }else{
        direccionTotal += poblacion;
      }
    }
    if(codPostal != undefined){
      if(direccionTotal != ""){
        direccionTotal += ', ' + codPostal;
      }else{
        direccionTotal += codPostal;
      }
    }
    return direccionTotal
  }

  muestraDestinatario(expedicion: Expedition): Boolean{
    var mostrar: Boolean = false;
    if(expedicion.Estado != "Pendiente" && expedicion.Estado != null && expedicion.Estado != undefined)
    {
      mostrar = true;
    }
    return mostrar;
  }

  getEstado(estado: string){
    if(estado == null || estado == '' || estado == undefined){
      return this.translate.instant("Pendiente");
    }
    return this.translate.instant(estado);
  }

  isEstadoPendiente(estado: string): boolean{
    return estado == 'Pendiente' || estado == null || estado == undefined;
  }

}