<div class="container">
    <form [formGroup]="formHorario" (ngSubmit)="actualizarHorario('ActualizarHorario')">
        <div class="row" *ngIf="horario != null">
            <div class="col-2">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HR1Label'|translate}}</label>
                            <input matInput type="time" formControlName="hr1">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HE1Label'|translate}}</label>
                            <input matInput type="time" formControlName="he1">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HR2Label'|translate}}</label>
                            <input matInput type="time" formControlName="hr2">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HE2Label'|translate}}</label>
                            <input matInput type="time" formControlName="he2">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HR3Label'|translate}}</label>
                            <input matInput type="time" formControlName="hr3">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HE3Label'|translate}}</label>
                            <input matInput type="time" formControlName="he3">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HR4Label'|translate}}</label>
                            <input matInput type="time" formControlName="hr4">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <label>{{'HE4Label'|translate}}</label>
                            <input matInput type="time" formControlName="he4">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- CENTRARLO :( -->
            <div class="col-4 align-self-center text-center" style="align-items: center;">
                <button class="btn" style="align-items: center;" type="submit">{{'Modificar' | translate}}</button>
            </div>
        </div>
    </form>
</div>