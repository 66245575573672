import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-ajustes',
  templateUrl: './ajustes.component.html',
  styleUrls: ['./ajustes.component.scss']
})
export class AjustesComponent implements OnInit {

  isExpanded = true;
  selection: number = 0;
  isConductor: boolean;
  tipoUser: number;
  constructor(private deviceService: DeviceDetectorService, private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isExpanded = !this.deviceService.isMobile();
    this.authService.getTipoUsuario().subscribe(data => {
      this.tipoUser = data;
    });
  }

  changeSelection(selection){
    this.selection = selection;
  }

  isSelection(selection){
    return this.selection == selection;
  }

}
