import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { User } from "../models/user.model";
// Para poner la url de la API sin tener que cambiar todos
import { environment } from '../../environments/environment';
import { Conductor } from '../models/conductor.model';
import { LogModel } from '../models/log-model.model';
import { CambiarPassword } from '../models/cambiarPassword.model';
import { LogService } from './log.service';
import { RegistroUsuario } from '../models/registroUsuario.model';
import { Archivo } from '../models/archivo.model';
import { AddressBook } from '../models/addressBook.model';
import { Subject } from 'rxjs';
import { localidadGoogleMaps } from '../models/localidadGoogleMaps.model';

@Injectable({
  providedIn: 'root'
})
export class LocalidadService {

  readonly apiUrlService = environment.apiUrl;
  ip: string = "";
  localizacion: string = "";
  localidadGoogleMaps$: Subject<localidadGoogleMaps> = new Subject();


  getLocalidadGoogleMaps$(): Observable<localidadGoogleMaps> {
    return this.localidadGoogleMaps$.asObservable();
  }

  setLocalidadGoogleMaps$(localidadGoogleMaps: localidadGoogleMaps) {
    this.localidadGoogleMaps$.next(localidadGoogleMaps);
  }

  constructor(private http: HttpClient, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
      this.ip = data['ip'];
      this.http.get('https://ipapi.co/' + this.ip + '/json').subscribe((d: any) => {
        this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude;
      })
    })
  }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  //Obtenemos el token de la variable local
  getToken() {
    return localStorage.getItem("userToken");
  }

  getLocalidadFormatGoogleMaps(idLocalidad) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "ma_localidades/" + idLocalidad + "/maps";
    this.logService.postLogCompleto("GET", "ma_localidadesController.cs", "Log del privilegio de obtención de todos los address book.", "Get_ma_localidadByIdFormatGoogleAsync", "ma_localidades", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as localidadGoogleMaps)).subscribe(lgm => {
      this.localidadGoogleMaps$.next(lgm);
    });
  }
}
