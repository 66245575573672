import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatosContacto } from '../models/datos-contacto.model';
import { AuthenticationService } from './authentication.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  readonly apiUrlService = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  
  public lenguaje$: Subject<string> = new Subject();
  ip: string = ""; 
  localizacion: string = "";
  constructor(private http: HttpClient, private service: AuthenticationService, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
      this.ip = data['ip'];
      this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
        this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
      })
    })
   }

   getLenguaje$(){
    return this.lenguaje$.asObservable();
  }

  setLenguaje$(lenguaje: string){
    this.lenguaje$.next(lenguaje);
  }

  getDatosContacto(): Observable<DatosContacto> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.service.getToken()
    })
    const url_api = this.apiUrlService + "web_usuarios/contacto";
    this.logService.postLogCompleto("GET", "web_usuariosController.cs", "Log obtener los datos de contacto.",
     "Get_contacto_Web_usuario", "web_usuarios", url_api);
    return this.http.get<DatosContacto>(url_api, {headers: headersToken}).pipe(map(data => data));
  }

}
