<div class="container">
    <div class="block"></div>
    <div class="row">
        <div class="col-sm-6" [ngClass]="{'borderRigth1px': !deviceService.isMobile(), 'borderBottom1px': deviceService.isMobile()}">
            <app-lista-address-book></app-lista-address-book>
        </div>
        <div class="col-sm-6">
            <app-formulario-address-book></app-formulario-address-book>
        </div>
    </div>
</div>