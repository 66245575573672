
<div class="container">
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-sm-12">
            <div class="input-group md-form form-sm form-1 pl-0">
                <div class="input-group-prepend">
                    <span class="input-group-text purple lighten-3" id="basic-text1">
                        <img src="assets/iconos/search_16.png" alt=""/>
                    </span>
                </div>
                <input title="Buscador" class="form-control my-0 py-1 search-input" type="search" placeholder="Búsqueda" aria-label="Search"  [(ngModel)]="searchText" (ngModelChange)="changeSearch()">
            </div>            
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive-sm scrollable-element">
                <table class="table table-sm table-hover table-editable table-row-action edit-action">
                    <thead>
                        <th class="fit" scope="col">{{'Nombre Empresa'|translate}}</th>
                        <th class="fit" scope="col">{{'Dirección'|translate}}</th>
                        <th class="fit" scope="col">{{'Pais'|translate}}</th>
                        <th class="fit" scope="col">{{'Ciudad'|translate}}</th>
                        <th class="fit" scope="col">{{'Código Postal'|translate}}</th>
                        <th class="fit" scope="col">{{'Calle'|translate}}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let direccion of listaDirecciones | slice: (pageActual-1)*sizePage: (pageActual-1) * sizePage + sizePage | filter:searchText"
                        style="cursor: pointer;" [class.highlighted]="direccion.IdDireccion === currentRow" (click)="safeEntity(direccion); selectRow($event, direccion)">
                            <td class="fit">
                                {{direccion.NombreEntidad}}
                            </td>
                            <td class="fit" *ngIf="direccion.Direccion != null && direccion.Direccion != undefined && direccion.Direccion != ''">
                                {{direccion.Direccion}} {{direccion.Numero}}, {{direccion.NombreLocalidad}},{{direccion.NombrePais}}, {{direccion.CodigoPostal}}
                            </td>
                            <td class="fit" *ngIf="direccion.Direccion == null || direccion.Direccion == undefined || direccion.Direccion == ''">
                                
                            </td>
                            <td class="fit">
                                {{direccion.NombrePais}}
                            </td>
                            <td class="fit">
                                {{direccion.NombreLocalidad}}
                            </td>
                            <td class="fit">
                                {{direccion.CodigoPostal}}
                            </td>
                            <td class="fit">
                                {{direccion.Direccion}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <div class="d-flex justify-content-between p-2" style="text-align: center;">
                <ngb-pagination
                    [(page)]="pageActual"
                    [pageSize]="sizePage"
                    [collectionSize]="total"
                    [maxSize]="3"
                    [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
        <div class="col-sm-4">
            <button class="btn" type="reset" (click)="enviarDireccion('cerrar')">{{'Cerrar'|translate}}</button>
            <button class="btn" type="button" (click)="enviarDireccion($event);">{{'Seleccionar'|translate}}</button>
        </div>
    </div>
</div>