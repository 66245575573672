import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { Vehiculo } from 'src/app/models/vehiculo.model';
import { VehiculoService } from 'src/app/services/vehiculo.service';

@Component({
  selector: 'app-borrar-vehiculo',
  templateUrl: './borrar-vehiculo.component.html',
  styleUrls: ['./borrar-vehiculo.component.scss']
})
export class BorrarVehiculoComponent implements OnInit {

  pantalla: string = "app-borrar-vehiculo";
  @Input() vehiculoSelected: Vehiculo;
  @Output() cerrarModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private vehiculoService: VehiculoService, public translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  putVehiculo(click: string){
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.pantalla;
    this.vehiculoSelected.Activo = false;
    this.vehiculoService.putVehiculo(this.vehiculoSelected, log).subscribe(data => {
      var title = this.translate.instant("BorradoTitleSuccess");
      var body = this.translate.instant("BorradoBodySuccess");
      this.toastr.success(body,title);
      this.vehiculoService.getAllVehiculos();
      this.cerrarModal.emit(true);
    }, err => {
      var title = this.translate.instant("BorradoTitleError");
      var body = this.translate.instant("BorradoBodyError");
      this.toastr.error(body,title)
    })
  }

  cancelar(){
    this.cerrarModal.emit(true);
  }

}
