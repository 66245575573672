import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Embalaje } from '../models/embalaje.model';
import { environment } from 'src/environments/environment';
import { Mercancia } from '../models/mercancia.model';
import { Codigosun } from '../models/codigosun.model';
import { Contenedor } from '../models/contenedor.model';
import { AuthenticationService } from './authentication.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class MercanciaService {

  readonly apiUrlService = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  mercancia: Mercancia;

  ip: string = ""; 
  localizacion: string = "";
  constructor(private http: HttpClient, private authService: AuthenticationService, private logService: LogService) { 
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
  }

  getTipoEmbalajes(): Observable<Embalaje[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "ma_tipoembalaje";
    this.logService.postLogCompleto("GET", "ma_tipoembalajeController.cs", "Log obtener listado los tipo embalajes.",
     "Get_ma_tipoembalaje", "ma_tipoembalaje", url_api);
    return this.http.get<Embalaje[]>(url_api, {headers: headersToken});
  }

  getTipoEmbalajesNoRemontable(): Observable<Embalaje[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "ma_tipoembalaje/noRemontable";
    this.logService.postLogCompleto("GET", "ma_tipoembalajeController.cs", "Log obtener listado los tipo embalajes.",
     "Get_ma_tipoembalaje", "ma_tipoembalaje", url_api);
    return this.http.get<Embalaje[]>(url_api, {headers: headersToken});
  }

  setMercancia(mercancia: Mercancia) {
    this.mercancia = mercancia;
  }

  getMercancia() {
    return this.mercancia;
  }

  getCodigosUN(): Observable<Codigosun[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "cfg_codigosun";
    this.logService.postLogCompleto("GET", "cfg_codigosunController.cs", "Log obtener todos los codigos un.",
     "Get_cfg_codigosun", "cfg_codigosun", url_api);
    return this.http.get<Codigosun[]>(url_api, {headers: headersToken});
  }

  getTipoContenedor(): Observable<Contenedor[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "cfg_tipocontenedor";
    this.logService.postLogCompleto("GET", "cfg_tipocontenedorController.cs", "Log obtener todos los tipos de contenedor.",
     "Get_cfg_tipocontenedor", "cfg_tipocontenedor", url_api);
    return this.http.get<Contenedor[]>(url_api, {headers: headersToken});
  }

}
