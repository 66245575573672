<div>
  <app-header></app-header>
  <div class="container-fluid">
    <div class="container">
      <form #formEnvioTerrestre="ngForm" (ngSubmit)="envioTerrestre(formEnvioTerrestre)">
        <div class="form-group">
          <!-- BLOQUE ESTATICO -->
          <div id="bloqueestatico" style="margin-top: 20px;">

            <!-- SEGUNDO BLOQUE-->
            <div id="plantillas" class="row" style="margin-bottom: 20px;">
              <div class="col-sm-3">
                <button type="button" class="btn" outofocus="on" (click)="openModal(templatePlantillas)"
                  [disabled]="visor">{{'Cargar Plantillas'|translate}}</button>
              </div>
            </div>
            <div id="segundobloque" class="row">
              <div class="col-sm-3">
                <label>{{'Tipo Envio'|translate}}*</label>
                <ng-select name="TipoEnvio" id="TipoEnvio" (change)="setTipoEnvio($event)"
                  [ngClass]="{'error':errorTipoEnvio}" #TipoEnvio="ngModel" [items]="listTipoEnvio"
                  bindLabel="TipoEnvio" bindValue="IdTipoEnvio" [readonly]="visor || isCotizacion"
                  (focusout)="compruebaTipoEnvio(formEnvioTerrestre)" [(ngModel)]="formModelTerrestre.value.TipoEnvio"
                  aria-required="true">
                </ng-select>
                <div *ngIf="errorTipoEnvio" class="text-danger" style="font-size: small;">
                  {{'Tipo envio obligatorio'|translate}}
                </div>
              </div>
              <div class="col-sm-3">
                <label>{{'Modo Envio'|translate}}*</label>
                <ng-select name="ModoEnvio" id="ModoEnvio" (change)="setModoEnvio($event)"
                  [ngClass]="{'error': errorModoEnvio}" #ModoEnvio="ngModel" [items]="listModoEnvio"
                  bindLabel="ModoEnvio" bindValue="IdModoEnvio" [readonly]="visor"
                  (focusout)="compruebaModoEnvio(formEnvioTerrestre)" [(ngModel)]="formModelTerrestre.value.ModoEnvio"
                  aria-required="true">
                </ng-select>
                <div *ngIf="errorModoEnvio" class="text-danger" style="font-size: small;">
                  {{'Modo envio obligatorio'|translate}}
                </div>
              </div>
            </div>
            <div id="identificativo" class="row" style="margin-top: 20px;">
              <div class="col-sm-3">
                <label>{{'Cliente'|translate}}*</label><sup><span mat-raised-button
                    matTooltip="{{'infoCliente'|translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <input type="text" name="Cliente" style="margin-bottom: 20px;" class="form-control"
                  [(ngModel)]="formModelTerrestre.value.Cliente.NombreFiscal" readonly>
              </div>
              <div class="col-sm-3">
                <label>{{'Ref. Cliente'|translate}}*</label><sup><span mat-raised-button
                    matTooltip="{{'infoReferencia'|translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <input autocomplete="off" #Referencia="ngModel" type="text" id="Referencia" name="Referencia"
                  [ngClass]="{'form-control-custom-error': errorReferencia, 'form-control-custom':!errorReferencia}"
                  (focusout)="compruebaReferencia(formEnvioTerrestre); replicaReferencias(formEnvioTerrestre); compruebaDuplicadoReferenciaCliente()"
                  [(ngModel)]="formModelTerrestre.value.Referencia" [readonly]="visor" required>
                <div *ngIf="errorReferencia || (formEnvioTerrestre.submitted && (Referencia.value == null || Referencia.value == '')) 
                            || (Referencia.touched && (Referencia.value == null || Referencia.value == ''))"
                  class="text-danger" style="margin-bottom: 20px;font-size: small;">
                  {{'Referencia obligatorio'|translate}}
                </div>
              </div>
              <div *ngIf="isCotizacion" class="col-sm-3">
                <label>{{'Ref. Cotizacion' | translate }}</label>
                <input type="text" name="refCotizacion" style="margin-bottom: 20px;" class="form-control-custom"
                  autocomplete="off" [(ngModel)]="formModelTerrestre.value.RefCotizacion" readonly>
              </div>
            </div>
            <!-- CUARTO BLOQUE -->
            <div id="cuartobloque" class="row" style="margin-top: 10px; margin-bottom: 10px;">
              <div class="col-sm-2">
                <label>{{'Incoterm'|translate}}*</label><sup><span mat-raised-button
                    matTooltip="{{'infoIncoterm' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <ng-select name="Incoterm" id="Incoterm" class="select-small" #Incoterm="ngModel" [items]="listIncoterm"
                  bindLabel="IdIncoterm" bindValue="IdIncoterm" style="width: 70%;" [readonly]="visor || isCotizacion"
                  [(ngModel)]="formModelTerrestre.value.Incoterm" (focusout)="compruebaIncoterm(formEnvioTerrestre)"
                  [ngClass]="{'error': errorIncoterm}">
                </ng-select>
                <div *ngIf="errorIncoterm || (formEnvioTerrestre.submitted && (Incoterm.value == null || Incoterm.value == '')) || (Incoterm.touched && (Incoterm.value == null 
                            || Incoterm.value == ''))" class="text-danger"
                  style="margin-bottom: 20px;font-size: small;">
                  {{'Incoterm obligatorio'|translate}}
                </div>
              </div>
              <div class="col-sm-2">
                <label *ngIf="formModelTerrestre.value.Asegurado == true">{{'Valor Mercancia' | translate}}*</label>
                <label *ngIf="formModelTerrestre.value.Asegurado == false">{{'Valor Mercancia' | translate}}</label>
                <sup><span mat-raised-button matTooltip="{{'infoValorMercancia' | translate}}"
                    matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt=""
                      style="width: 12px;height: 12px;"></span></sup>
                <input autocomplete="off" id="ValorMercancia" name="ValorMercancia" type="text"
                  [ngClass]="{'form-control-custom-error': errorValorMercancia, 'form-control-custom': !errorValorMercancia}"
                  #ValorMercancia="ngModel" (focusout)="compruebaValorMercancia(formEnvioTerrestre);"
                  (change)="calcularSeguro();" [(ngModel)]="formModelTerrestre.value.ValorMercancia" [readonly]="visor"
                  required>
                <div *ngIf="errorValorMercancia || (formEnvioTerrestre.submitted && (ValorMercancia.value == null || ValorMercancia.value == '') && formModelTerrestre.value.Asegurado == true) || (ValorMercancia.touched && formModelTerrestre.value.Asegurado == true && (ValorMercancia.value == null 
                            || ValorMercancia.value == ''))" class="text-danger"
                  style="margin-bottom: 20px;font-size: small;">
                  {{'Valor de la mercancia obligatorio'|translate}}
                </div>
              </div>
              <div class="col-sm-2" *ngIf="esEspanyaItalia()">
                <label>{{'¿Quiere asegurar su mercancia?'|translate}}</label>
                <mat-radio-group name="Asegurado" #Asegudaro="ngModel"
                  (change)="calcularSeguro();compruebaValorMercancia(formEnvioTerrestre);"
                  [(ngModel)]="formModelTerrestre.value.Asegurado" aria-label="¿Desea asegurar su carga?"
                  [disabled]="visor">
                  <mat-radio-button [value]="true" [disabled]="visor">{{'Si'|translate}}</mat-radio-button>
                  <mat-radio-button [value]="false" [disabled]="visor">{{'No'|translate}}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-sm-2" *ngIf="esEspanyaItalia()">
                <div class="row">
                  <label>{{'Coste del Seguro'|translate}} €</label><sup><span mat-raised-button
                      matTooltip="{{'infoSeguroTerrestre' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input id="SeguroMercancia" name="SeguroMercancia" type="text" class="form-control-custom"
                    #SeguroMercancia="ngModel" [(ngModel)]="formModelTerrestre.value.SeguroMercancia" readonly>
                </div>
              </div>
              <div class="col-sm-2">
                <label>{{'Divisa'|translate}}</label><sup><span mat-raised-button
                    matTooltip="{{'infoDivisa' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <ng-select name="TipoDivisa" id="TipoDivisa" class="select-small" #TipoDivisa="ngModel"
                  [readonly]="visor" bindLabel="TipoDivisa" bindValue="IdTipoDivisa" [items]="listDivisas"
                  [(ngModel)]="formModelTerrestre.value.TipoDivisa">
                </ng-select>
              </div>
            </div>
            <!-- SEPTIMO BLOQUE -->
            <div id="Recogida" class="contenedor">
              <h5>{{getTituloRecogida()}}</h5>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'Remitente'|translate}}*</label><sup><span mat-raised-button
                      matTooltip="{{'infoRemitente'|translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <ng-select name="ListShipper" id="ListShipper" [ngClass]="{'error': errorListShipper}"
                    #ListShipper="ngModel" [items]="listShipper" bindLabel="NombreFiscal" [readonly]="visor"
                    [(ngModel)]="formModelTerrestre.value.Shipper" style="margin-bottom: 20px;"
                    (change)="setShipperInformation();" (focusout)="compruebaListShipper(formEnvioTerrestre)">
                  </ng-select>

                </div>
                <div class="col-sm-2">
                  <label style="margin-bottom: 26px;"></label>
                  <button type="button" title="{{'Buscar entidad'|translate}}" class="btn"
                    style="margin-top: 28px; margin-right: 10px;" (click)="openModal2(templateSearchEntity, 'shipper')"
                    [disabled]="visor">
                    <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;" alt="">
                  </button>
                  <button type="button" title="{{'Añadir entidad'|translate}}" class="btn" style="margin-top: 28px;"
                    (click)="openModal2(entityTemplate, 'shipper')" [disabled]="visor">
                    <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;" alt="">
                  </button>
                </div>
                <div class="col-sm-4">
                  <label>{{'Referencia de Carga' | translate}}*</label><sup><span mat-raised-button
                      matTooltip="{{'infoReferenciaCarga' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="text" id="ReferenciaRecogida" name="ReferenciaRecogida"
                    [readonly]="visor"
                    [ngClass]="{'form-control-custom-error':errorReferenciaRecogida, 'form-control':!errorReferenciaRecogida}"
                    #ReferenciaRecogida="ngModel" (focusout)="compruebaReferenciaRecogida(formEnvioTerrestre)"
                    style="margin-bottom: 5px;" [(ngModel)]="formModelTerrestre.value.ReferenciaRecogida" required>
                  <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="errorReferenciaRecogida || (formEnvioTerrestre.submitted && (FechaRecogida.value == null || FechaRecogida.value == '')) || (FechaRecogida.touched 
                                && (FechaRecogida.value == null || FechaRecogida.value == ''))" class="text-danger">
                    {{'Referencia de carga obligatoria'|translate}}
                  </div>
                </div>
              </div>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-4">
                  <label>{{'Dirección Recogida'|translate}}*</label>
                  <textarea name="Shipper" id="Shipper" rows="4" class="form-control-custom"
                    [(ngModel)]="this.formModelTerrestre.value.DireccionShipper"
                    [readonly]="!readonlyDireccionShipper"></textarea>
                  <div *ngIf="errorListShipper || (formEnvioTerrestre.submitted && (ListShipper.value == null || ListShipper.value == '')) 
                                || (ListShipper.touched && (ListShipper.value == null || ListShipper.value == ''))"
                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                    {{'Remitente obligatorio'|translate}}
                  </div>
                </div>
                <div class="col-sm-1">
                  <!-- Al clicar abre modal para seleccionar entidad con direccion -->
                  <button title="{{'Buscar dirección'|translate}}" type="button" class="btn"
                    style="margin-top: 28px; margin-right: 10px;" [disabled]="visor"
                    (click)="openModal(template);setDireccion('shipperEntity');">
                    <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;" alt="">
                  </button>
                  <button title="{{'Añadir dirección'|translate}}" type="button" class="btn" style="margin-top: 28px;"
                    (click)="openModal(template2);setDireccion('shipperEntity');" [disabled]="visor">
                    <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;" alt="">
                  </button>
                </div>
                <div class="col-sm-5">
                  <label>{{'Instrucciones Recogida'|translate}}</label>
                  <textarea autocomplete="off" maxlength="{{maxlengthInstrucctions}}" rows="4" type="text"
                    id="IntruccionesRecogida" name="InstruccionesRecogida" [readonly]="visor"
                    class="form-control-custom" #InstruccionesRecogida="ngModel" style="margin-bottom: 5px;width: 109%;"
                    [(ngModel)]="formModelTerrestre.value.InstruccionesRecogida"></textarea>
                  <span>{{formModelTerrestre.value.InstruccionesRecogida.length}}/{{maxlengthInstrucctions}}</span>
                </div>
              </div>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'Mercancia disponible desde'|translate}}*</label>
                  <input autocomplete="off" name="FechaRecogida" type="date" id="FechaRecogida" name="FechaRecogida"
                    min="{{today | date:'yyyy-MM-dd'}}" style="height: 36px;margin-bottom: 5px;" [readonly]="visor"
                    [ngClass]="{'form-control-custom-error':errorFechaRecogida || errorFechaRecogidaMenorActual, 'form-control':!errorFechaRecogida && !errorFechaRecogidaMenorActual}"
                    #FechaRecogida="ngModel" [(ngModel)]="formModelTerrestre.value.FechaRecogida"
                    (focusout)="compruebaFechaRecogida(formEnvioTerrestre)" required>
                  <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="errorFechaRecogida || (formEnvioTerrestre.submitted && (FechaRecogida.value == null || FechaRecogida.value == '')) || (FechaRecogida.touched 
                            && (FechaRecogida.value == null || FechaRecogida.value == ''))" class="text-danger">
                    {{'Fecha disponibilidad obligatoria'|translate}}
                  </div>
                  <div style="height: 36px;margin-bottom: 5px;font-size: small;" min="{{today | date:'yyyy-MM-dd'}}"
                    *ngIf="errorFechaRecogidaMenorActual || (formEnvioTerrestre.submitted && (FechaRecogida.touched && validarFechaRecogida()))"
                    class="text-danger">
                    {{'Fecha disponibilidad inferior a la de hoy' | translate}}
                  </div>
                </div>
                <div class="col-sm-2">
                  <label>{{'Desde'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoDesde' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" name="HoraRecogidaInicio1" type="time" id="HoraRecogidaInicio1"
                    class="form-control-custom" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraRecogidaInicio1="ngModel" [(ngModel)]="formModelTerrestre.value.HoraRecogidaInicio1"
                    [readonly]="visor">
                </div>
                <div class="col-sm-2">
                  <label>{{'Hasta'|translate}}</label>
                  <input autocomplete="off" name="HoraRecogidaFinal1" type="time" id="HoraRecogidaFinal1"
                    class="form-control-custom" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraRecogidaFinal1="ngModel" [(ngModel)]="formModelTerrestre.value.HoraRecogidaFinal1"
                    [readonly]="visor">
                </div>
                <div class="col-sm-2">
                  <label>{{'Desde'|translate}}</label>
                  <input autocomplete="off" name="HoraRecogidaInicio2" type="time" id="HoraRecogidaInicio2"
                    class="form-control-custom" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraRecogidaInicio2="ngModel" [(ngModel)]="formModelTerrestre.value.HoraRecogidaInicio2"
                    [readonly]="visor">
                </div>
                <div class="col-sm-2">
                  <label>{{'Hasta'|translate}}</label>
                  <input autocomplete="off" name="HoraRecogidaFinal2" type="time" id="HoraRecogidaFinal2"
                    class="form-control-custom" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraRecogidaFinal2="ngModel" [(ngModel)]="formModelTerrestre.value.HoraRecogidaFinal2"
                    [readonly]="visor">
                </div>
              </div>

              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'Persona Contacto'|translate}}*</label><sup><span mat-raised-button
                      matTooltip="{{'infoPersonaContactoCarga' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="text" id="PersonaContactoRecogida" name="PersonaContactoRecogida"
                    [readonly]="visor" #PersonaContactoRecogida="ngModel" style="margin-bottom: 10px;"
                    [(ngModel)]="formModelTerrestre.value.PersonaContactoRecogida"
                    (focusout)="compruebaPersonaContactoRecogida(formEnvioTerrestre)"
                    [ngClass]="{'form-control-custom-error':errorPersonaContactoRecogida, 'form-control-custom':!errorPersonaContactoRecogida}">
                  <div
                    *ngIf="errorPersonaContactoRecogida || (errorPersonaContactoRecogida && (formEnvioTerrestre.submitted && (PersonaContactoRecogida.value == null || PersonaContactoRecogida.value == '')))
                              || (errorPersonaContactoRecogida && (PersonaContactoRecogida.touched && (formEnvioTerrestre.value == null || PersonaContactoRecogida.value == '')))"
                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                    {{'Persona de contacto de recogida obligatorio'|translate}}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label>{{'Teléfono'|translate}}*</label><sup><span mat-raised-button
                      matTooltip="{{'infoTelefonoCarga' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="text" id="TelefonoRecogida" name="TelefonoRecogida" [readonly]="visor"
                    #TelefonoRecogida="ngModel" style="margin-bottom: 10px;"
                    [(ngModel)]="formModelTerrestre.value.TelefonoRecogida"
                    (focusout)="compruebaTelefonoRecogida(formEnvioTerrestre)"
                    [ngClass]="{'form-control-custom-error':errorTelefonoRecogida, 'form-control-custom':!errorTelefonoRecogida}"
                    required>
                  <div
                    *ngIf="errorTelefonoRecogida || (errorTelefonoRecogida && (formEnvioTerrestre.submitted && (TelefonoRecogida.value == null || TelefonoRecogida.value == ''))) 
                              || (errorTelefonoRecogida && (TelefonoRecogida.touched && (formEnvioTerrestre.value == null || TelefonoRecogida.value == '')))"
                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                    {{'Teléfono de contacto de recogida obligatorio'|translate}}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label>{{'Email'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoEmailCarga' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="email" id="EmailRecogida" name="EmailRecogida" [readonly]="visor"
                    #EmailRecogida="ngModel" style="margin-bottom: 10px;"
                    [(ngModel)]="formModelTerrestre.value.EmailRecogida"
                    (focusout)="compruebaEmailRecogida(formEnvioTerrestre)"
                    [ngClass]="{'form-control-custom-error':errorEmailRecogida, 'form-control':!errorEmailRecogida}"
                    required>
                  <div
                    *ngIf="errorEmailRecogida || (errorEmailRecogida && (formEnvioTerrestre.submitted && (EmailRecogida.value == null || EmailRecogida.value == ''))) 
                              || (errorEmailRecogida && (EmailRecogida.touched && (formEnvioTerrestre.value == null || EmailRecogida.value == '')))"
                    class="text-danger" style="margin-bottom: 10px;font-size: small;">
                    {{'Email de contacto de recogida obligatorio'|translate}}
                  </div>
                </div>
              </div>
              <!--DESTINATARIO SIEMPRE TIENE QUE ESTAR -->
              <!--*ngIf="incotermStartWith() || formModelTerrestre.value.TipoEnvio == 8"-->
            </div>
            <div id="Entrega" class="contenedor">
              <h5>{{'Entrega'|translate}}</h5>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'Destinatario'|translate}}*</label><sup><span mat-raised-button
                      matTooltip="{{'infoDestinatario' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <ng-select name="ListConsignee" id="ListConsignee"
                    [ngClass]="{'error':errorListConsignee || (formEnvioTerrestre.submitted && (ListConsignee.value == null || ListConsignee.value == '')) 
                              || (ListConsignee.touched && (ListConsignee.value == null || ListConsignee.value == ''))}" #ListConsignee="ngModel"
                    [items]="listConsignee" bindLabel="NombreFiscal" [readonly]="visor"
                    [(ngModel)]="formModelTerrestre.value.Consignee" style="margin-bottom: 20px;"
                    (change)="setConsigneeInformation();" (focusout)="compruebaListConsignee(formEnvioTerrestre)"
                    [loading]="cargaDestinatario" loadingText="Cargando...">
                  </ng-select>
                  <div *ngIf="errorListConsignee || (formEnvioTerrestre.submitted && (ListConsignee.value == null || ListConsignee.value == '')) 
                              || (ListConsignee.touched && (ListConsignee.value == null || ListConsignee.value == ''))"
                    class="text-danger" readonly style="margin-bottom: 20px;font-size: small;">
                    {{'Destinatario obligatorio'|translate}}
                  </div>
                </div>
                <div class="col-sm-2">
                  <!-- Al clicar abre modal para seleccionar entidad con direccion -->
                  <label style="margin-bottom: 26px;"></label>
                  <button type="button" title="{{'Buscar entidad'|translate}}" class="btn"
                    style="margin-top: 28px; margin-right: 10px;"
                    (click)="openModal2(templateSearchEntity, 'consignee')" [disabled]="visor">
                    <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;" alt="">
                  </button>
                  <button type="button" title="{{'Añadir entidad'|translate}}" class="btn" style="margin-top: 28px;"
                    (click)="openModal2(entityTemplate, 'consignee')" [disabled]="visor">
                    <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;" alt="">
                  </button>
                </div>
                <div class="col-sm-5">
                  <label>{{'Referencia Entrega'|translate}}*</label><sup><span mat-raised-button
                      matTooltip="{{'infoReferenciaEntrega' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="text" id="ReferenciaEntrega" name="ReferenciaEntrega"
                    [readonly]="visor"
                    [ngClass]="{'form-control-custom-error':errorReferenciaEntrega, 'form-control':!errorReferenciaEntrega}"
                    #ReferenciaEntrega="ngModel" style="margin-bottom: 5px;"
                    [(ngModel)]="formModelTerrestre.value.ReferenciaEntrega"
                    (focusout)="compruebaReferenciaEntrega(formEnvioTerrestre)" required>
                  <div *ngIf="errorReferenciaEntrega " class="text-danger"
                    style="margin-bottom: 10px;font-size: small;">
                    {{'Referencia de entrega obligatorio'|translate}}
                  </div>
                </div>
              </div>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-5">
                  <label>{{'Dirección Entrega'|translate}}*</label>
                  <textarea name="Consignee" id="Consignee" rows="4" class="form-control-custom"
                    [(ngModel)]="this.formModelTerrestre.value.DireccionConsignee" style="margin-right: 10px;"
                    [readonly]="!readonlyDireccionConsignee"></textarea>
                </div>
                <div class="col-sm-1">
                  <button title="{{'Buscar dirección'|translate}}" type="button" class="btn"
                    style="margin-top: 28px; margin-right: 10px;" [disabled]="visor"
                    (click)="openModal(template);setDireccion('consigneeEntity');">
                    <img src="assets/iconos/search.png" alt="">
                  </button>
                  <button title="{{'Añadir dirección'|translate}}" type="button" class="btn" style="margin-top: 28px;"
                    (click)="openModal(template2);setDireccion('consigneeEntity');" [disabled]="visor">
                    <img src="assets/iconos/plusAdd_16.png" alt="">
                  </button>
                </div>
                <div class="col-sm-5">
                  <label>{{'Instrucciones Entrega'|translate}}</label>
                  <textarea autocomplete="off" rows="4" type="text" id="IntruccionesEntrega" name="InstruccionesEntrega"
                    [readonly]="visor" class="form-control-custom" #InstruccionesEntrega="ngModel"
                    style="margin-bottom: 5px;width: 109%;" maxlength="{{maxlengthInstrucctions}}"
                    [(ngModel)]="formModelTerrestre.value.InstruccionesEntrega"></textarea>
                  <span>{{formModelTerrestre.value.InstruccionesEntrega.length}}/{{maxlengthInstrucctions}}</span>
                </div>
              </div>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'Fecha entrega'|translate}}</label>
                  <input autocomplete="off" name="FechaEntrega" type="date" id="FechaEntrega" name="FechaEntrega"
                    min="{{today | date:'yyyy-MM-dd'}}" style="height: 36px;margin-bottom: 5px;" [readonly]="visor"
                    class="form-control" #FechaEntrega="ngModel" [(ngModel)]="formModelTerrestre.value.FechaEntrega">
                </div>
                <div class="col-sm-2">
                  <label>{{'Desde'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoDesde' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" name="HoraEntregaInicio1" type="time" id="HoraEntregaInicio1"
                    class="form-control" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraEntregaInicio1="ngModel" [(ngModel)]="formModelTerrestre.value.HoraEntregaInicio1"
                    [readonly]="visor">
                </div>
                <div class="col-sm-2">
                  <label>{{'Hasta'|translate}}</label>
                  <input autocomplete="off" name="HoraEntregaFinal1" type="time" id="HoraEntregaFinal1"
                    class="form-control" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraEntregaFinal1="ngModel" [(ngModel)]="formModelTerrestre.value.HoraEntregaFinal1"
                    [readonly]="visor">
                </div>
                <div class="col-sm-2">
                  <label>{{'Desde'|translate}}</label>
                  <input autocomplete="off" name="HoraEntregaInicio2" type="time" id="HoraEntregaInicio2"
                    class="form-control" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraEntregaInicio2="ngModel" [(ngModel)]="formModelTerrestre.value.HoraEntregaInicio2"
                    [readonly]="visor">
                </div>
                <div class="col-sm-2">
                  <label>{{'Hasta'|translate}}</label>
                  <input autocomplete="off" name="HoraEntregaFinal2" type="time" id="HoraEntregaFinal2"
                    class="form-control" style="margin-bottom: 5px;width: 65%;height: 36px;"
                    #HoraEntregaFinal2="ngModel" [(ngModel)]="formModelTerrestre.value.HoraEntregaFinal2"
                    [readonly]="visor">
                </div>
              </div>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'Persona Contacto'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoPersonaContactoEntrega' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="text" id="PersonaContactoEntrega" name="PersonaContactoEntrega"
                    [readonly]="visor"
                    [ngClass]="{'form-control-custom-error':errorPersonaContactoEntrega, 'form-control-custom':!errorPersonaContactoEntrega}"
                    #PersonaContactoEntrega="ngModel" style="margin-bottom: 10px;"
                    [(ngModel)]="formModelTerrestre.value.PersonaContactoEntrega">
                </div>
                <div class="col-sm-3">
                  <label>{{'Teléfono'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoTelefonoEntrega' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input autocomplete="off" type="text" id="TelefonoEntrega" name="TelefonoEntrega" [readonly]="visor"
                    [ngClass]="{'form-control-custom-error':errorTelefonoEntrega, 'form-control-custom':!errorTelefonoEntrega}"
                    #TelefonoEntrega="ngModel" style="margin-bottom: 10px;"
                    [(ngModel)]="formModelTerrestre.value.TelefonoEntrega">
                </div>
                <div class="col-sm-3">
                  <label>{{'Email'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoEmailEntrega' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                    <input autocomplete="off" type="email" id="EmailEntrega" name="EmailEntrega" [readonly]="visor" 
                        [ngClass]="{'form-control-custom-error':errorEmailEntrega, 'form-control-custom':!errorEmailEntrega}" #EmailEntrega="ngModel"
                        style="margin-bottom: 10px;" [(ngModel)]="formModelTerrestre.value.EmailEntrega">
                </div>
              </div>
            </div>

            <div id="DespachoAduanas" class="contenedor" hidden>
              <h5>{{'DespachoAduanas'|translate}}</h5>
              <div class="row" style="margin-left: 1px;">
                <div class="col-sm-3">
                  <label>{{'DespachoOrigen'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoDespachoOrigen' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input id="DespachoOrigen" name="DespachoOrigen" type="text" autocomplete="off" [readonly]="visor"
                    #DespachoOrigen style="margin-bottom: 10px;" [(ngModel)]="formModelTerrestre.value.DespachoOrigen"
                    class="form-control-custom">
                </div>
                <div class="col-sm-3"
                  *ngIf="(formModelTerrestre.value.TipoEnvio == 7 && formModelTerrestre.value.Incoterm == 'DDP') || formModelTerrestre.value.TipoEnvio == 4">
                  <label>{{'DespachoDestino'|translate}}</label><sup><span mat-raised-button
                      matTooltip="{{'infoDespachoDestino' | translate}}" matTooltipClass="my-custom-tooltip"><img
                        src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                  <input id="DespachoDestino" name="DespachoDestino" type="text" autocomplete="off" [readonly]="visor"
                    #DespachoDestino style="margin-bottom: 10px;" [(ngModel)]="formModelTerrestre.value.DespachoDestino"
                    class="form-control-custom">
                </div>
              </div>

              <!-- <div id="mercanciaPeligrosa" class="row">
                      <div class="col-sm-3">
                          <div class="form-check" style="margin-top: 20px;">
                              <input name="MercanciaPeligrosa" type="checkbox" class="form-check-input" style="margin-bottom: 20px;" 
                              id="checkMercanciaPeligrosa" #MercanciaPeligrosa="ngModel" [(ngModel)]="formModelTerrestre.value.MercanciaPeligrosa" 
                              [disabled]="visor || isCotizacion" (click)="setMercPeligrosa(formEnvioTerrestre);" (change)="calcularSeguro(); mostrarAvisoCambioSeguro();">
                              <label class="form-check-label" style="margin-bottom: 20px;" for="MercanciaPeligrosa">{{'Mercancia peligrosa'|translate}}</label>
                          </div>
                      </div>
                      <div class="col-sm-2">
                          <!-- Al clicar abre modal para seleccionar entidad con direccion -->
              <label style="margin-bottom: 26px;"></label>
              <button type="button" title="{{'Buscar entidad'|translate}}" class="btn"
                style="margin-top: 28px; margin-right: 10px;" (click)="openModal2(templateSearchEntity, 'consignee')"
                [disabled]="visor">
                <img src="assets/iconos/search_48px.png" style="width: 16px; height: 16px;" alt="">
              </button>
              <button type="button" title="{{'Añadir entidad'|translate}}" class="btn" style="margin-top: 28px;"
                (click)="openModal2(entityTemplate, 'consignee')" [disabled]="visor">
                <img src="assets/iconos/plus_48px.png" style="width: 16px; height: 16px;" alt="">
              </button>
            </div>

            <!-- OCTAVO BLOQUE -->
            <div class="row" id="octavobloque">
              <div class="col-sm-12" *ngIf="listaMercancia != [] && listaMercancia != undefined">
                <app-cargo [listaMercancia]="listaMercancia" (listaAux)="listaMerc($event)"
                  [visor]="visor || isCotizacion" [editar]="editar" [fcl]="false"
                  [area]="formModelTerrestre.value.IdArea"
                  [mercanciaPeligrosa]="formModelTerrestre.value.MercanciaPeligrosa"
                  [cliente]="formModelTerrestre.value.Cliente"></app-cargo>
              </div>
              <div class="col-sm-12" *ngIf="listaMercancia == [] || listaMercancia == undefined">
                <app-cargo (listaAux)="listaMerc($event)" [fcl]="false" [cliente]="formModelTerrestre.value.Cliente">
                </app-cargo>
              </div>
            </div>
            <div id="Pesos" class="contenedor">
              <h5>{{'Totales'|translate}}</h5>
              <!-- Totales N/A -->
              <div class="row" style="margin: 5px;">
                <div class="col-sm-2">
                  <label class="totalizadores">{{'Bultos Totales'|translate}}*</label>
                  <input autocomplete="off" name="BultosTotales" type="text"
                    [(ngModel)]="formModelTerrestre.value.BultosTotales"
                    [ngClass]="{'form-control-custom-error totalizadores':errorBultosTotales, 'form-control-custom totalizadores':!errorBultosTotales}"
                    #BultosTotales="ngModel" [readonly]="esEditableCotizacion()" required>
                  <div
                    *ngIf="errorBultosTotales || (formEnvioTerrestre.submitted && (BultosTotales.value == null || BultosTotales.value == ''))"
                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                    {{'Bultos Totales obligatorio'|translate}}
                  </div>
                </div>
                <div class="col-sm-2">
                  <label class="totalizadores">{{'Bruto Total'|translate}} (Kg)*</label>
                  <input autocomplete="off" (change)="comprobarPesosTotales()"
                    (focusout)="compruebaPesoBrutoTotal(formEnvioTerrestre); calcularPesoTasable();"
                    [ngStyle]="{'border-color': (errorBrutoTotal? 'red' : '')}" name="PesoBrutoTotal" type="text"
                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoBrutoTotal, 'form-control-custom totalizadores':!errorPesoBrutoTotal}"
                    [(ngModel)]="formModelTerrestre.value.PesoBrutoTotal" #PesoBrutoTotal="ngModel"
                    [readonly]="!esEditable()" required>
                  <div
                    *ngIf="(errorPesoBrutoTotal || formEnvioTerrestre.submitted && (PesoBrutoTotal.value == null || PesoBrutoTotal.value == ''))"
                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                    {{'Peso Bruto total obligatorio'|translate}}
                  </div>
                </div>
                <div class="col-sm-2">
                  <label class="totalizadores">{{'Neto Total'|translate}} (Kg)</label>
                  <input autocomplete="off" name="PesoNetoTotal" type="text"
                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoNetoTotal, 'form-control-custom totalizadores':!errorPesoNetoTotal}"
                    [(ngModel)]="formModelTerrestre.value.PesoNetoTotal" #PesoNetoTotal="ngModel" [readonly]="!esEditable()" required>
                  <!-- <div *ngIf="errorPesoNetoTotal || (formEnvioTerrestre.submitted && (PesoNetoTotal.value == null || PesoNetoTotal.value == ''))" class="text-danger" 
                          style="margin-bottom: 20px;font-size: small;">
                              {{'Peso Neto total obligatorio'|translate}}
                          </div> -->
                </div>
                <div class="col-sm-2">
                  <label class="totalizadores">{{'Peso Tasable'|translate}} (Kg)</label>
                  <input name="PesoTasable" type="text" class="form-control-custom totalizadores"
                    [(ngModel)]="formModelTerrestre.value.PesoTasable" #PesoTasable="ngModel" readonly>
                </div>
                <div class="col-sm-2">
                  <label class="totalizadores">{{'Volumen Total'|translate}}(m<sup>3</sup>)*</label>
                  <input autocomplete="off" name="VolumenTotal" type="text" (focusout)="calcularPesoTasable();"
                    [ngClass]="{'form-control-custom-error totalizadores':errorVolumenTotal, 'form-control-custom totalizadores':!errorVolumenTotal}"
                    [(ngModel)]="formModelTerrestre.value.VolumenTotal" #VolumenTotal="ngModel" [readonly]="!esEditable()" required>
                  <div
                    *ngIf="errorVolumenTotal || (formEnvioTerrestre.submitted && (VolumenTotal.value == null || VolumenTotal.value == ''))"
                    class="text-danger" style="font-size: small;margin-bottom: 20px;">
                    {{'Volumen Total obligatorio'|translate}}
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 5px;">
                <div class="col-sm-2" *ngIf="formModelTerrestre.value.MercanciaPeligrosa == true">
                  <label class="totalizadores">{{'Bruto Total'|translate}}(Kg)(DGR)*</label>
                  <input id="PesoBrutoTotalDGR" autocomplete="off" name="PesoBrutoTotalDGR" type="text"
                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoBruto, 'form-control-custom totalizadores':!errorPesoBruto}"
                    class="form-control-custom totalizadores" [(ngModel)]="formModelTerrestre.value.PesoBrutoTotalDGR"
                    #PesoBrutoTotalDGR="ngModel" [readonly]="esEditableCotizacion()" required>
                </div>
                <div class="col-sm-2" *ngIf="formModelTerrestre.value.MercanciaPeligrosa == true">
                  <label class="totalizadores">{{'Neto Total'|translate}}(Kg)(DGR)</label>
                  <input id="PesoNetoTotalDGR" autocomplete="off" name="PesoNetoTotalDGR" type="text"
                    [ngClass]="{'form-control-custom-error totalizadores':errorPesoNeto, 'form-control-custom totalizadores':!errorPesoNeto}"
                    [(ngModel)]="formModelTerrestre.value.PesoNetoTotalDGR" #PesoNetoTotalDGR="ngModel" [readonly]="esEditableCotizacion()"
                    required>
                  <div
                    *ngIf="(formEnvioTerrestre.submitted && (PesoNetoTotalDGR.value == null || PesoNetoTotalDGR.value == ''))"
                    class="text-danger" style="margin-bottom: 20px;font-size: small;">
                    {{'Peso Neto total DGR obligatorio'|translate}}
                  </div>
                </div>
              </div>
            </div>
            <!-- NOVENO BLOQUE -->
            <div id="novenobloque" class="row">
              <div class="col-sm-12">
                <label>{{'Comentarios/Observaciones'|translate}}</label><sup><span mat-raised-button
                    matTooltip="{{'infoInstrucciones' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <textarea name="Instrucciones" style="margin-bottom: 20px;resize: none;" class="form-control-custom"
                  rows="5" maxlength="{{maxlengthObservaciones}}" style="margin-bottom: 5px;" #Instrucciones="ngModel"
                  [(ngModel)]="formModelTerrestre.value.Instrucciones" [readonly]="visor"></textarea>
                <span>{{formModelTerrestre.value.Instrucciones.length}}/{{maxlengthObservaciones}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-10 col-12"></div>
              <div class="col-sm-2 col-12"><br>
                * : {{'indicador de campos obligatorios' | translate}}
              </div>
            </div>
            <!-- DECIMO BLOQUE -->
            <div id="decimobloque" class="row">
              <div class="col-sm 8"></div>
              <div class="col-sm-2 margen-top">
                <app-upload-modal (sendFiles)="receiveFiles($event)"></app-upload-modal>

                <label *ngIf="listaArchivos.length > 0">{{listaArchivos.length}}
                  {{'archivos cargado/s'|translate}}</label>
              </div>
              <div class="col-sm-2 margen-top" *ngIf="!visor">
                <button type="button" id="enviarPlantilla" class="btn btn-block"
                  (click)="openAlert2(templateConfirmacionPlantillas)">{{'Guardar Plantilla'|translate}}</button>
              </div>
            </div>
            <!-- UNDECIMO BLOQUE -->
            <div id="undecimobloque" class="row">
              <div class="col-sm-8 col-12"></div>
              <div class="col-sm-2 col-12 margen-top" *ngIf="!(!visor && editar && !duplicar) && !visor">
                <button type="button" id="enviarShipment" class="btn btn-block"
                  (click)="convertFormToModel(formEnvioTerrestre);openAlert(template3, formEnvioTerrestre)">{{'Enviar'|translate}}</button>

              </div>
              <div class="col-sm-2 col-12 margen-top">
                <button type="button" id="cancelarShipment" ngbAutofocus class="btn buttonCancelar btn-block"
                  (click)="openModalSM(templateAvisoSiNo)">{{'Cancelar'|translate}}</button>
              </div>
            </div>
          </div>
        </div>
    </form>
  </div>
</div>

</div>


<ng-template #templatePlantillas>
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title">{{'Plantillas'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-plantillas [idArea]="formModelTerrestre.value.IdArea" (send)="getPlantilla($event);"></app-plantillas>
  </div>
</ng-template>

<ng-template #templateAvisoSiNo>
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title">{{'CancelarTitleShipment'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-aviso-si-no [texto]="texto" (respuesta)="obtenerRespuesta($event);"></app-aviso-si-no>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title pull-left">{{'Direcciones entidades'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-direcciones-entidad (address)="getDireccion($event)" [tipoDireccion]="tipoDireccion"></app-direcciones-entidad>
  </div>
</ng-template>

<ng-template #templateConsignee>
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title pull-left">{{'Direcciones entidades'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-direcciones-entidad (address)="getDireccion($event)" [entity]="formModelTerrestre.value.Consignee"
      [tipoDireccion]="tipoDireccion"></app-direcciones-entidad>
  </div>
</ng-template>

<ng-template #template2>
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title pull-left">{{'Añadir Direccion'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-anyadir-direccion (emitDireccionEntidad)="obtenerDireccionEntidad($event)" [tipoDireccion]="tipoDireccion"
      [area]="1"></app-anyadir-direccion>
  </div>
</ng-template>

<ng-template #anyadirConsginee>
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title pull-left">{{'Añadir Direccion'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-anyadir-direccion (emitDireccionEntidad)="obtenerDireccionEntidad($event)" [tipoDireccion]="tipoDireccion"
      [area]="1"></app-anyadir-direccion>
  </div>
</ng-template>

<ng-template #template3>
  <div class="modal-header" style="border-color: black; background-color: white;">
    <h2 class="modal-title pull-left">{{'ConfirmacionResumen'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="resumen" style=" background-color: white;">
    <app-confirmacion-seguro [asegurado]="formModelTerrestre.value.Asegurado" (confirmEmit)="confirmacionSeguro($event)"
      [shipment]="shipmentTerrestre" [direccionRecogida]="resumenDireccionRecogida"
      [direccionEntrega]="resumenDireccionEntrega"></app-confirmacion-seguro>
    <div class="row" style="justify-content: right;">
      <div class="col-sm-3" class="margenBotones">
        <button type="button" class="btn" style="margin: 5px;"
          (click)="downloadPDF()">{{'ImprimirEBooking'|translate}}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateConfirmacionPlantillas>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Confirmación plantilla'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-confirmacion-plantilla (confirmEmit)="confirmacionPlantilla($event)"></app-confirmacion-plantilla>
    </div>
  </div>
</ng-template>


<!-- The Modal -->
<ng-template #templateSearchEntity>
  <div class="modal-content">

    <!-- Modal Header -->
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Localizar Entidad'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- Modal body -->
    <div class="modal-body fondo">
      <div class="row" style="margin-left:15px;">
        <h2>{{'Entidades'|translate}}</h2>
      </div>

      <app-list-entities *ngIf="tipoEntidad == 'shipper'" [listEntities]="listShipper" [listEntitiesAux]="listShipper"
        [searchText]="searchText" (entity)="recibirShipper($event)" (search)="recibirSearch($event)">
      </app-list-entities>
      <app-list-entities *ngIf="tipoEntidad == 'consignee'" [listEntities]="listConsignee"
        [listEntitiesAux]="listConsignee" [searchText]="searchText" (entity)="recibirConsignee($event)"
        (search)="recibirSearch($event)"></app-list-entities>
      <app-list-entities *ngIf="tipoEntidad == 'notify'" [listEntities]="listNotify" [listEntitiesAux]="listNotify"
        [searchText]="searchText" (entity)="recibirNotify($event)" (search)="recibirSearch($event)"></app-list-entities>
    </div>
  </div>
</ng-template>

<ng-template #entityTemplate>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{'Añadir Entidad'|translate}}</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-formulario-address-book (emitCreateAddressBook)="refresEntidades($event);"></app-formulario-address-book>
    </div>
  </div>
</ng-template>
