import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, Pipe } from '@angular/core';
import { IncidenciasService } from 'src/app/services/incidencias.service';
import { Incidencia } from 'src/app/models/incidencia.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-incidencias',
  templateUrl: './list-incidencias.component.html',
  styleUrls: ['./list-incidencias.component.scss']
})
export class ListIncidenciasComponent implements OnInit {

  @Output() listIncidenciasAux = new EventEmitter<Incidencia[]>();
  @Output() incidenciaAux = new EventEmitter<Incidencia>();
  @Input() listaIncidencias: Incidencia[];
  pipe = new DatePipe('es-ES')

  constructor(private incidenciasService:IncidenciasService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges)
  {
  }

  //Pasamos la incidencia seleccionada al componente padre para mostrar la información correspondiente.
  mostrarInformacion(incidencia: Incidencia) {
    /*if(this.idExpedicion != undefined)
    {
      this.incidenciasService.obtenerListadoIncidencias(this.idExpedicion).subscribe(data => {
        this.listaIncidencias = data;
      });
    }*/
    this.incidenciaAux.emit(incidencia);
  }

}
