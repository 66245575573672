<div class="wrapper">
  <app-header></app-header>
  <div class="container-fluid">
    <div class="container">
      <div class="row" class="block"></div>
      <div class="row">
        <img
          class="imagenPrincipal"
          src="assets/imagenes/MyXGL.png"
          alt="XGL-Principal"
        />
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="row img-align">
            <img
              *ngIf="mostrarAereo"
              id="aereo"
              src="assets/iconos/aereoNegro.png"
              alt=""
            />
            <img
              *ngIf="!mostrarAereo"
              id="aereo"
              src="assets/iconos/aereoBlanco.png"
              alt=""
            />
          </div>
          <div class="row img-align">
            <label
              *ngIf="isLoad && tienePrivilegioBooking()"
              class="enlace"
              for="bookingAereo"
              (click)="abrirBookingAereo()"
              (mouseenter)="mostrarImagenAereoNegro()"
              (mouseleave)="mostrarImagenAereoBlanco()"
              >{{ "Envío" | translate }}</label
            >
            <label
              *ngIf="
                isLoad && tienePrivilegioBooking() && tienePrivilegioTracking()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioTracking()"
              class="enlace"
              for="trackingAereo"
              (click)="abrirTrackingAereo()"
              (mouseenter)="mostrarImagenAereoNegro()"
              (mouseleave)="mostrarImagenAereoBlanco()"
              >{{ "Consultar Envíos" | translate }}</label
            >
            <label
              *ngIf="
                isLoad &&
                tienePrivilegioTracking() &&
                tienePrivilegioCotizacion()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioCotizacion()"
              class="enlace"
              for="cotizacionTerrestre"
              (click)="abrirCotizacionAereo()"
              (mouseenter)="mostrarImagenAereoNegro()"
              (mouseleave)="mostrarImagenAereoBlanco()"
              >{{ "Cotizaciones" | translate }}</label
            >
            <label
              *ngIf="
                isLoad && tienePrivilegioTarifa() && tienePrivilegioCotizacion()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioTarifa()"
              class="enlace"
              for="tarifaAereo"
              (click)="abrirTarifaAereo()"
              (mouseenter)="mostrarImagenAereoNegro()"
              (mouseleave)="mostrarImagenAereoBlanco()"
              >{{ "Tarifa" | translate }}</label
            >
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row img-align">
            <img
              *ngIf="mostrarMaritimo"
              id="maritimo"
              src="assets/iconos/maritimoNegro.png"
              alt=""
            />
            <img
              *ngIf="!mostrarMaritimo"
              id="maritimo"
              src="assets/iconos/maritimoBlanco.png"
              alt=""
            />
          </div>
          <div class="row img-align">
            <label
              *ngIf="isLoad && tienePrivilegioBooking()"
              class="enlace"
              for="bookingMaritimo"
              (click)="abrirBookingMaritimo()"
              (mouseenter)="mostrarImagenMaritimoNegro()"
              (mouseleave)="mostrarImagenMaritimoBlanco()"
              >{{ "Envío" | translate }}</label
            >
            <label
              *ngIf="
                isLoad && tienePrivilegioBooking() && tienePrivilegioTracking()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioTracking()"
              class="enlace"
              for="trackingMaritimo"
              (click)="abrirTrackingMaritimo()"
              (mouseenter)="mostrarImagenMaritimoNegro()"
              (mouseleave)="mostrarImagenMaritimoBlanco()"
              >{{ "Consultar Envíos" | translate }}</label
            >
            <label
              *ngIf="
                isLoad &&
                tienePrivilegioTracking() &&
                tienePrivilegioCotizacion()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioCotizacion()"
              class="enlace"
              for="cotizacionTerrestre"
              (click)="abrirCotizacionMaritimo()"
              (mouseenter)="mostrarImagenMaritimoNegro()"
              (mouseleave)="mostrarImagenMaritimoBlanco()"
              >{{ "Cotizaciones" | translate }}</label
            >
            <label
              *ngIf="
                isLoad && tienePrivilegioTarifa() && tienePrivilegioCotizacion()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioTarifa()"
              class="enlace"
              for="tarifaMaritimo"
              (click)="abrirTarifaMaritimo()"
              (mouseenter)="mostrarImagenMaritimoNegro()"
              (mouseleave)="mostrarImagenMaritimoBlanco()"
              >{{ "Tarifa" | translate }}</label
            >
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row img-align">
            <img
              *ngIf="mostrarTerrestre"
              id="terrestre"
              src="assets/iconos/terrestreNegro.png"
              alt=""
            />
            <img
              *ngIf="!mostrarTerrestre"
              id="terrestre"
              src="assets/iconos/terrestreBlanco.png"
              alt=""
            />
          </div>
          <div class="row img-align">
            <label
              *ngIf="isLoad && tienePrivilegioBooking()"
              class="enlace"
              for="bookingTerrestre"
              (click)="abrirBookingTerrestre()"
              (mouseenter)="mostrarImagenTerrestreNegro()"
              (mouseleave)="mostrarImagenTerrestreBlanco()"
              >{{ "Envío" | translate }}</label
            >
            <label
              *ngIf="
                isLoad && tienePrivilegioBooking() && tienePrivilegioTracking()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioTracking()"
              class="enlace"
              for="trackingTerrestre"
              (click)="abrirTrackingTerrestre()"
              (mouseenter)="mostrarImagenTerrestreNegro()"
              (mouseleave)="mostrarImagenTerrestreBlanco()"
              >{{ "Consultar Envíos" | translate }}</label
            >
            <label
              *ngIf="
                isLoad &&
                tienePrivilegioTracking() &&
                tienePrivilegioCotizacion()
              "
              for=""
              class="separacion"
              >/</label
            >
            <label
              *ngIf="isLoad && tienePrivilegioCotizacion()"
              class="enlace"
              for="cotizacionTerrestre"
              (click)="abrirCotizacionTerrestre()"
              (mouseenter)="mostrarImagenTerrestreNegro()"
              (mouseleave)="mostrarImagenTerrestreBlanco()"
              >{{ "Cotizaciones" | translate }}</label
            >
            <!-- <label *ngIf="isLoad && tienePrivilegioTarifa() && tienePrivilegioCotizacion()" for=""
                            class="separacion">/</label>
                        <label *ngIf="isLoad && tienePrivilegioTarifa()" class="enlace" for="tarifaTerrestre"
                            (click)="abrirTarifaTerrestre()" (mouseenter)="mostrarImagenTerrestreNegro()"
                            (mouseleave)="mostrarImagenTerrestreBlanco()">{{'Tarifa'|translate}}</label> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateCambiarPasswordPrimeraVez id="CambiarPasswordPrimeraVez">
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{ "PrimeraVezLabel" | translate }}</h2>
    </div>
    <div class="modal-body fondo">
      <app-cambiar-password-primera-vez
        (cerrarModal)="cerrarModal($event)"
      ></app-cambiar-password-primera-vez>
    </div>
  </div>
</ng-template>

<ng-template #templateCambiarPasswordEsCaducada id="CambiarPasswordEsCaducada">
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">{{ "EsCaducadaLabel" | translate }}</h2>
    </div>
    <div class="modal-body fondo">
      <app-cambiar-password-primera-vez
        (cerrarModal)="cerrarModal($event)"
      ></app-cambiar-password-primera-vez>
    </div>
  </div>
</ng-template>
