<div>
  <app-header></app-header>
<div class="container-fluid">
  <div class="container">
    <form [formGroup]="formModelCotizacion">
      <div class="form-group">
        <div id="tipoEnvioClienteRemitente" class="row margen-top mb-3">
          <div class="col-3">
            <div class="row">
              <div class="col">
                <label>{{'Tipo Envio' | translate}}* </label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <!--#TipoEnvio="ngModel" (focusout)="compruebaTipoEnvio(formEnvioAereo)" [(ngModel)]="formModelAereo.value.TipoEnvio"-->
                <ng-select name="TipoEnvio" id="TipoEnvio" [ngClass]="{'error':IdTipoEnvio.invalid}" (change)="cambioTipoEnvio()"
                  [items]="listTipoEnvio" bindLabel="TipoEnvio" bindValue="IdTipoEnvio" [readonly]="visor"
                  formControlName="IdTipoEnvio" [ngModelOptions]="{standalone: true}" aria-required="true">
                </ng-select>
              </div>
            </div>
            <div *ngIf="IdTipoEnvio.invalid" class="text-danger" style="font-size: small">
              {{'Tipo envio obligatorio' | translate}}
            </div>
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col">
                <label>{{'Modo Envio' | translate}}* </label>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <!--#TipoEnvio="ngModel" (focusout)="compruebaTipoEnvio(formEnvioAereo)" [(ngModel)]="formModelAereo.value.TipoEnvio"-->
                <ng-select name="ModoEnvio" id="ModoEnvio" [ngClass]="{'error':IdModoEnvio.invalid}"
                  [items]="listModoEnvio" bindLabel="ModoEnvio" bindValue="IdModoEnvio" [readonly]="visor"
                  formControlName="IdModoEnvio" [ngModelOptions]="{standalone: true}" aria-required="true">
                </ng-select>
              </div>
            </div>
            <div *ngIf="IdTipoEnvio.invalid" class="text-danger" style="font-size: small">
              {{'Modo envio obligatorio' | translate}}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="row">
              <div class="col">
                <label>{{'Cliente' | translate}}*</label><sup><span mat-raised-button
                    matTooltip="{{'infoCliente' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="text" name="Cliente" style="margin-bottom: 20px;" class="form-control"
                  [ngModelOptions]="{standalone: true}" autocomplete="off" formControlName="Cliente" readonly>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col">
                <label>{{'Ref. Cliente' | translate }}</label><sup><span mat-raised-button
                    matTooltip="{{'infoReferencia' | translate }}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <!--(focusout)="compruebaReferencia(formEnvioAereo); replicaReferencias(formEnvioAereo);compruebaDuplicadoReferenciaCliente()" (focusout)="compruebaDuplicadoReferenciaCliente()"    -->
                <input autocomplete="off" type="text" id="Referencia" name="Referencia"
                  [ngModelOptions]="{standalone: true}" class="form-control" formControlName="ReferenciaCliente"
                  [readonly]="visor">
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <label>{{'Incoterm'|translate}}*</label><sup><span mat-raised-button
                    matTooltip="{{'infoIncoterm' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ng-select name="Incoterm" id="Incoterm" class="select-small" (change)="changeIncoterm()"
                  [items]="listIncoterm" bindLabel="IdIncoterm" bindValue="IdIncoterm" style="width: 70%;"
                  [readonly]="visor" formControlName="Incoterm" [ngClass]="{'error':incotermError()}"
                  [loading]="cargaIncoterm" loadingText="Cargando...">
                </ng-select>
                <div *ngIf="incotermError()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                  Incoterm {{'obligatorio' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="cuartobloque" class="row mb2">
          <div class="col-12">
            <div class="contenedor">
              <h5>{{'OrigenLabel' | translate}}</h5>
              <div class="row mlauto mb1coma7 mr0coma1">
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <label>{{'PaisLabel'|translate}}*</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <!--[ngClass]="{'error':incotermError()}"-->
                      <ng-select name="IdPaisOrigen" id="IdPaisOrigen" class="select-small" [readonly]="visor"
                        formControlName="IdPaisOrigen" (change)="changePaisOrigen()" [loading]="cargaPaises"
                        loadingText="Cargando...">
                        <ng-option *ngFor="let p of listaPaises" [value]="p.IdPais">
                          <img class="bandera" src="{{getBandera(p)}}">&nbsp;&nbsp;{{p.Nombre}}
                          <!-- &nbsp;-->
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <label>{{'LocalidadLabel'|translate}}*</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input #SearchFieldOrigen autocomplete="off" type="search" id="LocalidadOrigen"
                      name="LocalidadOrigen"
                      (keyup.enter)="localidadOrigenSelected = true; localidadDestinoSelected = false;"
                      
                      [ngModelOptions]="{standalone: true}" class="form-control"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="row">
                    <div class="col-12">
                      <label>{{'CodigoPostalLabel'|translate}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input #codePostalField autocomplete="off" readonly type="text" id="PostalCodeOrigen"
                        name="PostalCodeOrigen" [ngModelOptions]="{standalone: true}" class="form-control"
                        formControlName="PostalCodeOrigen">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="localidadOrigenSelected" class="row">
          <div class="col-12 mb2">
            <app-mapa [paisOrigen]="paisOrigen" [search]="searchFieldOrigen" [latitude]="latitudeOrigen"
              [longitude]="longitudeOrigen" [existMarkerOrigen]="existMarkerOrigen"
              [existMarkerOrigen]="existMarkerDestino" [origen]="localidadOrigenSelected"
              [destino]="localidadDestinoSelected" [latitudeMapa]="latitudeMapaOrigen"
              [longitudeMapa]="longitudeMapaOrigen" [zoom]="zoomOrigen"
              [obtenerPosicionInicialOrigen]="obtenerPosicionInicialOrigen"
              [obtenerPosicionDestinoOrigen]="obtenerPosicionInicialDestino"
              (localidadOrigenEmit)="getLocalidadOrigenEmit($event)"
              (localidadDestinoEmit)="getLocalidadDestinoEmit($event)"></app-mapa>
          </div>
        </div>

        <div id="cuartobloque" class="row mb2">
          <div class="col-12">
            <div class="contenedor">
              <h5>{{'DestinoLabel' | translate }}</h5>
              <div class="row mlauto mb1coma7 mr0coma1">
                <div class="col-4">
                  <div class="row">
                    <div class="col-12">
                      <label>{{'PaisLabel'|translate}}*</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <!--[ngClass]="{'error':incotermError()}"-->
                      <ng-select name="IdPaisDestino" id="IdPaisDestino" class="select-small" [readonly]="visor"
                        formControlName="IdPaisDestino" (change)="changePaisDestino()" [loading]="cargaPaises"
                        loadingText="Cargando...">
                        <ng-option *ngFor="let p of listaPaises" [value]="p.IdPais">
                          <img class="bandera" src="{{getBandera(p)}}">&nbsp;&nbsp;{{p.Nombre}}
                          <!-- &nbsp;-->
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <label>{{'LocalidadLabel'|translate}}*</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input #SearchFieldDestino autocomplete="off" type="search" id="LocalidadDestino"
                      name="LocalidadDestino" (keyup.enter)="localidadOrigenSelected = false; localidadDestinoSelected = true;"
                      [ngModelOptions]="{standalone: true}" class="form-control" #mapaDestino>
                      <div *ngIf="localidadDestinoError()" class="text-danger" style="font-size: small">
                        {{'ErrorLocalidadDestinoLocalidad' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="row">
                    <div class="col-12">
                      <label>{{'CodigoPostalLabel'|translate}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <input autocomplete="off" readonly type="text" id="PostalCodeDestino" name="PostalCodeDestino"
                        [ngModelOptions]="{standalone: true}" class="form-control" formControlName="PostalCodeDestino">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="localidadDestinoSelected" class="row mb2">
          <div class="col-12">
            <app-mapa [paisOrigen]="paisDestino" [search]="searchFieldDestino" [latitude]="latitudeDestino"
              [longitude]="longitudeDestino" [existMarkerOrigen]="existMarkerOrigen"
              [existMarkerOrigen]="existMarkerDestino" [origen]="localidadOrigenSelected"
              [destino]="localidadDestinoSelected" [latitudeMapa]="latitudeMapaDestino"
              [longitudeMapa]="longitudeMapaDestino" [zoom]="zoomDestino" [localidadOrigen]="LocalidadOrigen.value"
              [localidadDestino]="LocalidadDestino.value" [obtenerPosicionInicialOrigen]="obtenerPosicionInicialOrigen"
              [obtenerPosicionDestinoOrigen]="obtenerPosicionInicialDestino"
              (localidadOrigenEmit)="getLocalidadOrigenEmit($event)"
              (localidadDestinoEmit)="getLocalidadDestinoEmit($event)"></app-mapa>
          </div>
        </div>

        <div id="segundobloque" class="row mb-3">
          <div class="col-sm-2">
            <div class="row">
              <div class="col-12">
                <label>{{'Valor Mercancia' | translate}}</label><sup><span mat-raised-button
                    matTooltip="{{'infoValorMercancia' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input autocomplete="off" class="form-control" id="ValorMercancia" (change)="formatoValorMercancia()"
                  name="ValorMercancia" type="number" [readonly]="visor" formControlName="ValorMercancia">
              </div>
              <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="ValorMercanciaError()"
                class="text-danger">
                {{'ValorMercanciaError'|translate}}
              </div>
            </div>
          </div>
          <div class="col-sm-2" *ngIf="esEspanyaItalia()">
            <label>{{'¿Quiere asegurar su mercancia?'|translate}}</label>
            <mat-radio-group name="Asegurado" formControlName="Asegurado" id="Asegurado" [disabled]="visor"
              aria-label="¿Desea asegurar su carga?">
              <mat-radio-button [value]="true" [disabled]="visor">{{'Si'|translate}}</mat-radio-button>
              <mat-radio-button [value]="false" [disabled]="visor">{{'No'|translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-4">
            <div class="row">
              <div class="col-12">
                <label>{{'Mercancia disponible desde'|translate}}*</label><sup><span mat-raised-button
                    matTooltip="{{'infoFechaRecogida' | translate}}" matTooltipClass="my-custom-tooltip"><img
                      src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <!--(focusout)="compruebaFechaRecogida(formEnvioAereo)" -->
                <input autocomplete="off" name="FechaRecogida" type="date" id="FechaRecogida"
                  min="{{today | date:'yyyy-MM-dd'}}" name="FechaRecogida" style="height: 36px;margin-bottom: 5px;"
                  [readonly]="visor"
                  [ngClass]="{'form-control-custom-error':fechaReadyForShipmentError() || fechaReadyForShipmentMenorActual(), 'form-control':!fechaReadyForShipmentError() && !fechaReadyForShipmentMenorActual()}"
                  formControlName="FechaReadyForShipment" required>
                <div style="height: 36px;margin-bottom: 5px;font-size: small;" *ngIf="fechaReadyForShipmentError()"
                  class="text-danger">
                  {{'Fecha disponibilidad obligatoria'|translate}}
                </div>
                <div style="height: 36px;margin-bottom: 5px;font-size: small;" min="{{today | date:'yyyy-MM-dd'}}"
                    *ngIf="fechaReadyForShipmentMenorActual()"  class="text-danger">
                    {{'Fecha disponibilidad inferior a la de hoy' | translate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div id="mercanciaPeligrosa" class="row">
                    <div class="col-sm-3"> -->
        <!--(click)="setMercPeligrosa(formEnvioAereo);" (change)="calcularSeguro()"-->
        <!-- <div class="form-check" style="margin-top: 20px;">
                            <input name="MercanciaPeligrosa" type="checkbox" class="form-check-input"
                                style="margin-bottom: 20px;" id="checkMercanciaPeligrosa"
                                formControlName="MercanciaPeligrosa" [disabled]="visor"
                                 >
                            <label class="form-check-label" style="margin-bottom: 20px;"
                                for="MercanciaPeligrosa">{{'Mercancia peligrosa'|translate}}</label>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-check" style="margin-top: 20px;">
                            <input name="NoRemontable" type="checkbox" class="form-check-input"
                                style="margin-bottom: 20px;" id="checkRemontable" formControlName="NoRemontable" [disabled]="visor">
                            <label class="form-check-label" style="margin-bottom: 20px;"
                                for="MercanciaNoRemontable">{{'MercNoRemontable'|translate}}</label>
                        </div>
                    </div>
                </div> -->

        <div class="row" id="octavobloque">
          <div class="col-sm-12" *ngIf="listaMercancia != [] && listaMercancia != undefined">
            <app-cargo #appCargoNoFCL [listaMercancia]="listaMercancia" (listaAux)="listaMerc($event)" [visor]="visor"
              [editar]="editar" [fcl]="esFCL" [area]="IdArea.value" [mercanciaPeligrosa]="MercanciaPeligrosa.value"
              [noRemontable]="NoRemontable.value" [cliente]="clienteEntidad">
            </app-cargo>
          </div>
          <div class="col-sm-12" *ngIf="listaMercancia == [] || listaMercancia == undefined">
            <app-cargo #appCargoFCL (listaAux)="listaMerc($event)" [noRemontable]="NoRemontable.value" [fcl]="esFCL"
              [cliente]="clienteEntidad"></app-cargo>
          </div>
        </div>

        <div id="Pesos" class="contenedor mb2">
          <h5>{{'Totales'|translate}}</h5>
          <div class="row" style="margin: 5px;">
            <div class="col-sm-2">
              <label class="totalizadores">{{'Bultos Totales'|translate}}*</label>
              <input autocomplete="off" name="BultosTotales" type="text" formControlName="BultosTotales"
                [ngClass]="{'form-control-custom-error totalizadores':BultosTotalesError(), 'form-control totalizadores':!BultosTotalesError()}"
                readonly required>
              <div *ngIf="BultosTotalesError()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                {{'Bultos Totales obligatorio'|translate}}
              </div>
            </div>
            <div class="col-sm-2">
              <label class="totalizadores">{{'Bruto Total'|translate}} (Kg)</label>
              <!--compruebaPesoBrutoTotal(formEnvioAereo);-->
              <input autocomplete="off" (change)="comprobarPesosTotales()" (focusout)=" calcularPesoTasable();"
                [ngStyle]="{'border-color': (PesoBrutoTotalError()? 'red' : '')}" name="PesoBrutoTotal" type="text"
                [ngClass]="{'form-control-custom-error totalizadores':PesoBrutoTotalError(), 'form-control totalizadores':!PesoBrutoTotalError()}"
                formControlName="PesoBrutoTotal" [readonly]="visor" required>
              <div *ngIf="PesoBrutoTotalError()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                {{'Peso Bruto total obligatorio'|translate}}
              </div>
            </div>
            <div class="col-sm-2">
              <label class="totalizadores">{{'Neto Total'|translate}} (Kg)</label>
              <input autocomplete="off" name="PesoNetoTotal" type="text"
                [ngClass]="{'form-control-custom-error totalizadores':PesoNetoTotalError(), 'form-control totalizadores':!PesoNetoTotalError()}"
                formControlName="PesoNetoTotal" [readonly]="visor" required>
              <!-- <div *ngIf="PesoNetoTotalError()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                {{'Peso Neto total obligatorio'|translate}}
              </div> -->
            </div>
            <div class="col-sm-2">
              <label class="totalizadores">{{'Peso Tasable'|translate}} (Kg)</label>
              <input autocomplete="off" name="PesoTasable" type="text" class="form-control"
                formControlName="PesoTasable" readonly>
            </div>
            <div class="col-sm-2">
              <label class="totalizadores">{{'Volumen Total'|translate}}(m<sup>3</sup>)*</label>
              <input autocomplete="off" name="VolumenTotal" type="text" (focusout)="calcularPesoTasable();"
                [ngClass]="{'form-control-custom-error totalizadores':VolumenTotalError(), 'form-control totalizadores':!VolumenTotalError()}"
                formControlName="VolumenTotal" readonly required>
              <div *ngIf="VolumenTotalError()" class="text-danger"
                style="font-size: small;margin-bottom: 20px;margin-left: 10px;">
                {{'Volumen Total obligatorio'|translate}}
              </div>
            </div>
          </div>
          <div class="row" style="margin: 5px;">
            <div class="col-sm-2" *ngIf="MercanciaPeligrosa.value == true">
              <label class="totalizadores"
                style="margin-right: 0px;display:inline-block;">{{'BrutoTotal'|translate}}(Kg)(DGR)*</label>
              <input id="PesoBrutoTotalDGR" autocomplete="off" name="PesoBrutoTotalDGR" type="text"
              [ngClass]="{'form-control-custom-error totalizadores':errorPesoBruto || tieneErrorPesoBrutoTotalDGR(), 'form-control totalizadores':!errorPesoBruto || !tieneErrorPesoBrutoTotalDGR()}" formControlName="PesoBrutoTotalDGR" readonly>
              <div *ngIf="tieneErrorPesoBrutoTotalDGR()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                {{'Peso Bruto total DGR mayor que peso bruto total'|translate}}
              </div>
            </div>
            <div class="col-sm-2" *ngIf="MercanciaPeligrosa.value == true">
              <label class="totalizadores">{{'Neto Total'|translate}}(Kg)(DGR)</label>
              <input id="PesoNetoTotalDGR" autocomplete="off" name="PesoNetoTotalDGR" type="text"
                [ngClass]="{'form-control-custom-error totalizadores':PesoNetoTotalDGRError() || tieneErrorPesoNetoTotalDGR(), 'form-control totalizadores':!PesoNetoTotalDGRError() || !tieneErrorPesoNetoTotalDGR()}"
                formControlName="PesoNetoTotalDGR" readonly required>
              <div *ngIf="PesoNetoTotalDGRError()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                {{'Peso Neto total DGR obligatorio'|translate}}
              </div>
              <div *ngIf="tieneErrorPesoNetoTotalDGR()" class="text-danger" style="margin-bottom: 20px;font-size: small;">
                {{'Peso neto total DGR mayor que peso neto total'|translate}}
              </div>
            </div>
          </div>
        </div>


        <div id="novenobloque" class="row">
          <div class="col-sm-12">
            <label>{{'Instrucciones/Observaciones'|translate}}</label><sup><span mat-raised-button
                matTooltip="{{'infoInstrucciones' | translate}}" matTooltipClass="my-custom-tooltip"><img
                  src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
            <textarea autocomplete="off" name="Instrucciones" class="form-control-custom" rows="5"
              maxlength="{{maxlengthObservaciones}}" formControlName="Observaciones" [readonly]="visor"
              style="margin-bottom: 5px;"></textarea>
            <span>{{Observaciones.value.length}}/{{maxlengthObservaciones}}</span>
          </div>
        </div>

        <div id="decimobloque" class="row">
          <div class="col-sm 8 col-12"></div>
          <br>
          <div class="col-sm-2 col-12 margen-top">
            * : {{'indicador de campos obligatorios' | translate}}
          </div>
          <div class="col-sm-2 col-12 margen-top">
            <app-upload-modal *ngIf="!(!visor && editar && !duplicar) && !visor" [documentacion]="false"
              (sendFiles)="receiveFiles($event)"></app-upload-modal>
            <app-upload-modal *ngIf="editar && !visor" [documentacion]="false" (sendFiles)="receiveFiles($event)">
            </app-upload-modal>
            <label *ngIf="listaArchivos.length > 0">{{listaArchivos.length}} {{'archivos cargado/s'|translate}}.</label>
          </div>
        </div>

        <div id="undecimobloque" class="row">
          <div class="col-sm-8 col-12"></div>
          <div class="col-sm-2 col-12 margen-top" *ngIf="!(!visor && editar && !duplicar) && !visor">
            <button type="button" id="enviarShipment" (click)="crearCotizacion()"
              class="btn btn-block">{{'Enviar'|translate}}</button>
          </div>
          <div class="col-sm-2 col-12 margen-top">
            <button type="button" id="cancelarShipment" class="btn buttonCancelar btn-block"
              (click)="openModalSM(templateAvisoSiNo)">{{'Cancelar'|translate}}</button>
          </div>
          <button hidden type="button" id="resumenCotizacion" #resumenCotizacion name="resumenCotizacion"
            (click)="openModalXL(templateResumenCotizacion)">{{'Cancelar'|translate}}</button>

          <!-- <button type="button" id="MercanciaCotizacion" class="btn btn-block"
                            (click)="openModalXL(templateMercancia)">{{'Mercancía'|translate}}</button> -->
        </div>
      </div>
    </form>
  </div>
</div>

</div>
<!-- TEMPLATES -->

<!-- <ng-template #templateMercancia id="templateMercancia">
    <div class="modal-header fondo" style="border-color: black; cursor: all-scroll;">
        <h2 class="modal-title">{{'CancelarTitleShipment'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body fondo">
        <app-cargo [listaMercancia]="listaMercancia" (listaAux)="listaMerc($event)" [visor]="visor"
                            [editar]="editar" [fcl]="esFCL" [area]="IdArea.value"
                            [mercanciaPeligrosa]="MercanciaPeligrosa.value"
                            [noRemontable]="NoRemontable.value" [cliente]="clienteEntidad">
                        </app-cargo>
    </div>
</ng-template> -->

<ng-template #templateAvisoSiNo class="modal-dialog">
  <div class="modal-header fondo" style="border-color: black;">
    <h2 class="modal-title">{{'CancelarTitleShipment'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-aviso-si-no [texto]="texto" (respuesta)="obtenerRespuesta($event);"></app-aviso-si-no>
  </div>
</ng-template>

<ng-template #templateResumenCotizacion class="modal-dialog">
  <div class="modal-header fondo " style="border-color: black; background-color: white;">
    <h2 class="modal-title">{{'Resumen'|translate}}</h2>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo" style="background-color: white;">
    <app-resumen-cotizacion (confirmEmit)="postCotizacion($event)" [resumen]="Cotizacion"></app-resumen-cotizacion>
    <!-- <div class="row" style="justify-content: right;">
      <div class="col-sm-3" style="text-align: right; margin-right: 1.3%;">
        <button type="button" class="btn" style="margin: 5px;"
          (click)="downloadPDF()">{{'ImprimirEBooking'|translate}}</button>
      </div>
    </div> -->
  </div>
</ng-template>
