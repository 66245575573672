<app-header-language></app-header-language>
<nav class="navbar navbar-expand-lg navbar-light colorBlanco">
    <img class="navbar-brand" href="/" routerLink="" src="assets/logos/MYXGL.png" style="height: 10%; width: 10%;"/>
    <span *ngIf="mostrar">
        <img [src]="getLogoHTML()" class="logo" alt="">
    </span>
    
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" 
    aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup" >
        <ul class="nav navbar-nav mr-auto">
            <li [routerLink]="['/proveedor/expedicionesActivas']" class="nav-item active">
                <label>{{'Expediciones activas' | translate }}</label>
            </li>
            <li class="nav-item active" [routerLink]="['/proveedor/conductores']">
                <label>{{'Conductores' | translate }}</label>
            </li>
            <li class="nav-item active" [routerLink]="['/proveedor/vehiculos']">
                <label>{{'Vehiculos' | translate }}</label>
            </li>
            <li [routerLink]="['/proveedor/historial']" class="nav-item active">
                <label>{{'Histórico' | translate }}</label>
            </li>
            <li class="nav-item active" [routerLink]="['/proveedor/documentacion']">
                <label>{{'Documentación' | translate }}</label>
            </li>
            
            <!--<li class="nav-item active">
                <label>{{'Rutas' | translate }}</label>
            </li>-->
            
            <li class="nav-item active">
                <label [routerLink]="['/proveedor/miPerfil']">{{'Perfil'|translate}}</label>
            </li>
        </ul>
        <app-header-fijo></app-header-fijo>
        <ul class="navbar-nav ml-auto" style="display:table-row;" style="padding-right: 12%; padding-top: 0px; font-size: medium;">
            <li class="navbar-text" style="vertical-align: middle;">
                <label>
                    <a style="cursor: pointer;" [routerLink]="['/proveedor/miPerfil']">{{nomUser}},</a>
                </label>
                <label>
                    <a (click)="onLogout('CerrarSesion')" style="cursor: pointer;">{{'Cerrar Sesion' | translate}}</a>
                </label>
            </li>
        </ul>
    </div>
</nav>
