<table *ngIf="mercanciasPeligrosas != undefined && mercanciasPeligrosas != null && mercanciasPeligrosas.length > 0" 
  style="border-top-width: 1px !important;"
  class="table table-sm table-bordered table-editable table-row-action edit-action">
 
    <thead>
      <th>{{ "UN" | translate }}</th>
      <th *ngIf="visible">{{ "Bultos" | translate }}</th>
      <th>{{ "Peso Bruto" | translate }}</th>
      <th>{{ "Peso Neto" | translate }}</th>
      <th *ngIf="visible">{{ "Clase" | translate }}</th>
      <th *ngIf="visible">{{ "PG" | translate }}</th>
    </thead>
    <tbody *ngFor="let mercanciaPeligrosa of mercanciasPeligrosas">
      <td *ngIf="mercanciaPeligrosa.Un != undefined && mercanciaPeligrosa.Un != null">{{ mercanciaPeligrosa.Un }}</td>
      <td *ngIf="mercanciaPeligrosa.UN != undefined && mercanciaPeligrosa.UN != null">{{ mercanciaPeligrosa.UN }}</td>
      <td *ngIf="visible">{{ mercanciaPeligrosa.Bultos }}</td>
      <td>{{ mercanciaPeligrosa.PesoBruto }}</td>
      <td>{{ mercanciaPeligrosa.PesoNeto }}</td>
      <td *ngIf="visible">{{ mercanciaPeligrosa.Clase }}</td>
      <td *ngIf="visible">{{ mercanciaPeligrosa.Pg }}</td>
    </tbody>
  </table>
  
