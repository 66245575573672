<div class="row">
    <div class="col-sm-12">
        <p>¿Estás seguro que deseas borrar el vehículo del tipo {{vehiculoSelected.Tipo}} y con la matrícula {{vehiculoSelected.Matricula}}?</p>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <button class="btn" type="button" (click)="putVehiculo('boton-borrado-vehiculo')">{{'Borrar'|translate}}</button>
    </div>
    <div class="col-sm-6">
        <button class="btn" type="button" (click)="cancelar();">{{'Cancelar'|translate}}</button>
    </div>
</div>
