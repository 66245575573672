<div class="container">
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
            <div class="input-group md-form form-sm form-1 pl-0">
                <div class="input-group-prepend">
                    <span class="input-group-text purple lighten-3" id="basic-text1">
                        <img src="assets/iconos/search_16.png" alt=""/>
                    </span>
                </div>
                <input title="Buscador" class="form-control my-0 py-1 search-input" type="search" placeholder="Búsqueda" aria-label="Search"  [(ngModel)]="searchText" (ngModelChange)="changeSearch()">
            </div>
        </div>
        <div class="col-sm-1"></div>
    </div>
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
            <div class="table-responsive-sm table-responsive scrollable-element" >
                <table class="table table-sm table-bordered table-hover table-editable table-row-action edit-action" >
                    <thead>
                        <th class="centrado">{{'Num'|translate}}</th>
                        <th class="centrado">{{'Destinatario'|translate}}</th>
                        <th class="centrado">{{'Tipo Envio'|translate}}</th>
                        <th *ngIf="(idArea != 3)" class="centrado">{{'Modo Envio'|translate}}</th>
                        <th class="centrado">{{'Acciones'|translate}}</th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let plantilla of listaPlantillasAux | slice: (pageActual-1)*sizePage: (pageActual-1) * sizePage + sizePage | filter:searchText; let i = index">
                            <tr (click)="selectRow($event, plantilla)" [class.highlighted]="plantilla.IdPlantilla === currentRow">
                                <td class="centrado">{{i+1}}</td>
                                <td class="centrado">
                                    {{plantilla.NombreDestinatario}}
                                </td>
                                <td class="centrado">{{plantilla.TipoEnvio}}</td>
                                <td *ngIf="(idArea != 3)" class="centrado">{{plantilla.ModoEnvio}}</td>
                                <td class="centrado"><img src="assets/iconos/trash_16.png" alt="" (click)="openAlert(templateDelete, i)"></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-sm-1"></div>
    </div>
    <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-8">
            <div class="d-flex justify-content-between p-2" style="text-align: center;">
                <ngb-pagination
                    [(page)]="pageActual"
                    [pageSize]="sizePage"
                    [collectionSize]="total"
                    [maxSize]="3"
                    [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-7"></div>
        <div class="col-sm-4">
            <button class="btn" (click)="close()">{{'Cerrar'|translate}}</button>
            <button class="btn" (click)="envioPlantilla()">{{'Seleccionar'|translate}}</button>
        </div>
        <div class="col-sm-1"></div>
    </div>
</div>

<ng-template #templateDelete>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
        <h2 class="modal-title pull-left">{{'Confirmación de borrado'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body fondo">
            <app-confirmacion-borrado (confirmEmit)="obtenerConfirmacion($event)"></app-confirmacion-borrado>
        </div>
    </div>
</ng-template>