import { Input, SimpleChange, ViewChildren } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/angular2-signaturepad';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-firma-albaran',
  templateUrl: './firma-albaran.component.html',
  styleUrls: ['./firma-albaran.component.scss']
})
export class FirmaAlbaranComponent implements OnInit {

  canvasWidth: number = 0;
  canvasHeight: number = 0;
  public signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    
  };
  private firmaBlanco = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACqCAYAAAAX43IEAAAE/UlEQVR4Xu3UwQkAIBADQa//Un37VrCLhbkKwuTI3LPvcgQIEAgIjMEKtCQiAQJfwGB5BAIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQebw6SNXCBKP4AAAAASUVORK5CYII="
  @Input() public firma: string = ""
  @Input() public dni: string = ""
  @Output() public emitirFirma = new EventEmitter<string>();
  @ViewChild(SignaturePad,{static:false}) signaturePad: SignaturePad;

  constructor(public deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    if(this.deviceService.isMobile()){
      this.canvasWidth = window.innerWidth * 0.5;
      if(window.innerHeight >= 810){ // Pantalla más alta que 810 px
        this.canvasHeight = window.innerHeight * 0.065;
      }else if(window.innerHeight >= 650){ // Pantalla más alta que 650 px pero menos que 810
        this.canvasHeight = window.innerHeight * 0.075;
      }else{ // Pantalla más baja de 650 px
        this.canvasHeight = window.innerHeight * 0.085;
      }
      
    }else if(this.deviceService.isDesktop()){
      this.canvasWidth = 630;
      this.canvasHeight = 200;
    }else if(this.deviceService.isTablet()){
      this.canvasWidth = 440;
      this.canvasHeight = 130;
    }
    this.signaturePadOptions = {
      'minWidth': 1,
      'canvasWidth': this.canvasWidth,
      'canvasHeight': this.canvasHeight,
      'backgroundColor': '#FFF4F1'
    }
  }

  ngOnChanges(changes: SimpleChange){}

  ngAfterViewInit(){
    if(this.firma != "" && this.firma != null){
      this.signaturePad.fromDataURL(this.firma);
    }
  }

  drawFinish(){
    if(this.signaturePad.isEmpty()){
      this.signaturePad.clear();
      this.firma = null;
    }else{
      this.firma = this.signaturePad.toDataURL();
    }
    this.emitirFirma.emit(this.firma);
  }

  drawCancel(){
    this.drawClear();
    this.emitirFirma.emit(this.firma);
  }

  drawStart() {}

  drawClear(){
    this.signaturePad.clear();
    this.firma = "";
  }

}
