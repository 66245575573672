export class localidadGoogleMaps {
    IdLocalidad?: number;
    Administrative_area_level_1: string;
    Administrative_area_level_2: string;
    Administrative_area_level_3: string;
    Administrative_area_level_4: string;
    Administrative_area_level_5: string;
    Administrative_area_level_6: string;
    Administrative_area_level_7: string;
    Sublocality_level_1: string;
    Sublocality_level_2: string;
    Sublocality_level_3: string;
    Sublocality_level_4: string;
    Sublocality_level_5: string;
    Plus_code: string;
    Ciudad: string;
    CodigoPostal: string;
    Pais: string;
    IdPais?: number;
    Lat?: number;
    Lng?: number;
    IsoAlfa2: string;
    NombreEmpresa: string;
}