import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-aviso-si-no',
  templateUrl: './aviso-si-no.component.html',
  styleUrls: ['./aviso-si-no.component.scss']
})
export class AvisoSiNoComponent implements OnInit {

  @Input() texto: string = "";
  @Output() respuesta: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  enviarRespuesta(respuesta: boolean){
    this.respuesta.emit(respuesta);
  }

}
