import { Component, OnInit,ViewChild, ElementRef,EventEmitter, Input, Output, TemplateRef, ViewChildren } from '@angular/core';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlbaranYFirmaViewModel } from 'src/app/models/albaranYFirmaViewModel';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { TranslateService } from '@ngx-translate/core';
import { LogModel } from 'src/app/models/log-model.model';
import { NgxPicaService } from '@digitalascetic/ngx-pica';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-model-estado',
  templateUrl: './model-estado.component.html',
  styleUrls: ['./model-estado.component.scss']
})
export class ModelEstadoComponent implements OnInit {

  /** Nuevo */
  clase: string = "app-model-estado"
  modalRef: BsModalRef | null;
  formCambiarEstado = new FormControl({
    dni: [null],
    photo: [[], [Validators.required]],
    pdfs: [[], [Validators.required]],
    firma: ['']
  })
  disabled: boolean = false;

  public get photo() { return this.formCambiarEstado.value.photo }
  public get pdfs() { return this.formCambiarEstado.value.pdfs }

  /** Firma */
  private firmaBlanco = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACqCAYAAAAX43IEAAAE/UlEQVR4Xu3UwQkAIBADQa//Un37VrCLhbkKwuTI3LPvcgQIEAgIjMEKtCQiAQJfwGB5BAIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQebw6SNXCBKP4AAAAASUVORK5CYII="
  
  ngAfterViewInit() {}

  openModalFirma(template: TemplateRef<any>){
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };
    this.modalRef = this.modalService.show(template, config);
    //this.signaturePad.nativeElement.fromDataURL(this.firmaBlanco);
  }

  obtenerFirma(event){
    if(event != null && event != ""){
      this.formCambiarEstado.value.firma = event;
      this.errorFirma = false;
    }else{
      this.formCambiarEstado.value.firma = "";
      this.errorFirma = true;
    }
    this.modalRef.hide();
  }

  isEntregaAlbaran(): boolean{
    return this.estado == this.estados[3];
  }

  isEntrega(): boolean{
    return this.estado == this.estados[1];
  }

  isRecogida(): boolean {
    return this.estado == this.estados[0];
  }

  isDisabled(): boolean {
    return this.disabled;
  }
  

  /** Fin firma */
  // Atributos
  @Output("verificacion") verificacion = new EventEmitter<[boolean, string]>()
  @Output("cerrarModal") cerrarModal = new EventEmitter<number>()
  /** WebCam */
  @Input() idExpedicionString
  @Input() public showWebcam = false;
  @Input() public fotoRealizada = false;
  @Input() public error = false;
  @Input() public errorFirma = false;
  @Input() public errorDni = false;
  @Input() public submited = false;
  @Input() public numero = "";
  public isMovil: boolean;
  public width: number;
  
  public patternNIF = new RegExp("[0-9]{8}[A-Za-z]{1}")
  public patternCIF = new RegExp("[a-zA-Z]{1}[0-9]{7}[a-zA-Z0-9]{1}")

  @ViewChild('btnClose', {static: false}) btnClose: ElementRef;
  @ViewChild('btnCloseNR', {static: false}) btnCloseNR: ElementRef;
  @ViewChild('foto', {static: false}) fotoInput: ElementRef;

  /** Cambio Estado */
  @Input() estado: string = ""
  @Input() idExpedicion: string = ""

  estados: string[] = ["Pendiente", "Recogida", "Entregado", "Entregado a falta de albarán"] 
  

  // ngoninit y constructor
  public ngOnInit(): void {
    this.isMovil = this.deviceService.isMobile()
    this.width = 30
    this.error = false;
    this.errorFirma = false;
    this.showWebcam = true;
    this.fotoRealizada = false;
    this.errorDni = false;
  }

  constructor(private toastr: ToastrService, private dialog: MatDialog,
    private service: ExpeditionApiService, private modalService: BsModalService, 
    private deviceService: DeviceDetectorService, private fb: FormBuilder,
    private translateService: TranslateService, private picaService: NgxPicaService,
    private imageCompress: NgxImageCompressService) { }

  // Para inicializar el form
  public inicializar(){
    this.formCambiarEstado.value.firma = null;
    this.photo.value = []
    this.fotoRealizada = false
    this.errorDni = false
    this.cerrarModal.emit(1);
  }

  /** Funciones cambio de estado */
  
  // Saber siguiente estado
  siguienteEstado() {
    var nextEstado = "";
    if(this.estado === this.estados[1] && !this.fotoRealizada){
      nextEstado = this.estados[3];
    }else if(this.estado === this.estados[3]){
      nextEstado = this.estados[2];
    }else if (this.estado === this.estados[1]) {
      nextEstado = this.estados[2]
    } else if (this.estado === this.estados[0] || !this.estado) {
      nextEstado = this.estados[1]
    }
    return nextEstado
  }

  // Coger los bits de la foto que se ha subido
  fileChange(fileList){
    if(fileList.length > 0){
      let me = this;
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if(reader.result.toString().startsWith("data:application/pdf;")){ // Es un PDF
          me.pdfs[0].push(reader.result.toString());
          me.fotoRealizada = true;
        }else{
          var imagen = new Image()
          imagen.crossOrigin = "Anonymous";
          imagen.src = reader.result.toString()
          imagen.onload = function(){
            me.imageCompress.compressFile(reader.result.toString(), -1, 75, 75).then(result => {
              me.photo[0].push(result);
              me.fotoRealizada = true;
            })
          }
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }else if(this.pdfs[0].length == 0 && this.photo[0].length == 0){
      this.formCambiarEstado.value.photo.value = null;
      this.fotoRealizada = false;
    }else{
      this.fotoRealizada = true;
    }
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      class: 'modal-xl',
      ignoreBackdropClick: true,
      initialState: {
        id: 2
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

    //Funcion que verifica que el cif introducido es válido
    verificacionCIF() {
      let cif: string = this.formCambiarEstado.value.dni;
      if (!cif || cif.length !== 9) {
        return false;
      }
    
      var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
      var digits = cif.substr(1, cif.length - 2);
      var letter = cif.substr(0, 1);
      var control = cif.substr(cif.length - 1);
      var sum = 0;
      var i;
      var digit;
    
      if (!letter.match(/[A-Z]/)) {
        return false;
      }
    
      for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);
    
        if (isNaN(digit)) {
          return false;
        }
    
        if (i % 2 === 0) {
          digit *= 2;
          if (digit > 9) {
            digit = parseInt((digit / 10).toString()) + (digit % 10);
          }
    
          sum += digit;
        } else {
          sum += digit;
        }
      }
    
      sum %= 10;
      if (sum !== 0) {
        digit = 10 - sum;
      } else {
        digit = sum;
      }
    
      if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
      }
      if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
      }
    
      return String(digit) === control || letters[digit] === control;
    }
  
    verificacionNIF(): Boolean {
      let correcto = true;
      let letrasNif: String[] = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']
      let nif: string = this.formCambiarEstado.value.dni;
      let letra: string = nif.substr(8, 1).toUpperCase();
      let numNif: number = Number(nif.substr(0, 8));
      let resto = numNif % 23;
      if(letrasNif[resto] != letra)
        correcto = false;

      return correcto;
    }
  
    verificacionNIE(): Boolean {
      let correcto = true;
      let letrasNie: String[] = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']
      let nie: string = this.formCambiarEstado.value.dni;
      let numNie: string = nie.substr(1, 7);
      let letra: string = nie.substr(8, 1).toUpperCase();
  
      if(nie.substr(0,1) == 'X')
        nie = '0' + numNie.substr(0,8);
      else if(nie.substr(0,1) == 'Y')
        nie = '1' + numNie.substr(0,8);
      else if(nie.substr(0,1) == 'Z')
        nie = '2' + numNie.substr(0,8);
  
      let numNif: number = Number(nie.substr(0, 8));
      let resto = numNif % 23;
      if(letrasNie[resto] != letra)
        correcto = false;

      return correcto;
    }

    /*comprobarDNI(){
      if((this.formCambiarEstado.value.dni == null || this.formCambiarEstado.value.dni == undefined || this.formCambiarEstado.value.dni == "") ||
      (!this.verificacionCIF() && !this.verificacionNIE() && !this.verificacionNIF()))
        this.errorDni = true;
      else
        this.errorDni = false;
      return this.errorDni;
    }*/

    estaVacioDNI(): boolean{
      return (this.formCambiarEstado.value.dni == null || this.formCambiarEstado.value.dni == undefined || this.formCambiarEstado.value.dni == "");
    }

    estaVacioFirma(): boolean{
      return (this.firmaBlanco == this.formCambiarEstado.value.firma || this.formCambiarEstado.value.firma == null || this.formCambiarEstado.value.firma == "");
    }

  mostrarMensajeError(title: string, body: string){
    var errorBody = this.translateService.instant(body);
    var errorTitulo = this.translateService.instant(title);
    this.toastr.error(errorBody, errorTitulo);
    this.disabled = false;
  }

  // Cambiar estado en la base de datos y debemos comprobar para enviar el albarán
  cambiarEstado(click: string){
    this.submited = true;
    this.disabled = true;
    var log: LogModel = new LogModel();
    log.Pantalla = this.clase;
    log.Click = click;
    this.comprobarErrores();
    if(this.isEntrega()){ // El estado que vamos a cambiar es entregado
      // Primero comprobamos los errores posibles del formulario con sus variantes
      // if(this.fotoRealizada && this.estaVacioFirma()) // Error, si sube albarán, hay que firmar
      //   this.mostrarMensajeError('TitleErrorFirmaVacia', 'BodyErrorFirmaVacia');
      // else{ // Está todo bien
      var aux = new AlbaranYFirmaViewModel();
      aux.AlbaranFoto = this.formCambiarEstado.value.photo[0];
      aux.AlbaranPDF = this.formCambiarEstado.value.pdfs[0];
      if(!this.estaVacioDNI())
        aux.DNI = this.formCambiarEstado.value.dni.toUpperCase();
      else 
        aux.DNI = "";
      if(this.estaVacioFirma())
        aux.Firma = null;
      else
        aux.Firma = this.formCambiarEstado.value.firma;
      aux.Visible = "1";
      aux.IdExpedicion = this.numero;
      this.service.cambiarEstado(this.siguienteEstado(), this.numero, aux, log).subscribe(data => {
        this.formCambiarEstado.value.firma = null;
        if(this.fotoRealizada)
          this.verificacion.emit([true, this.translateService.instant('La fotografía se ha subido correctamente y se ha cambiado el estado correctamente.')]);
        else
          this.verificacion.emit([true, this.translateService.instant('OkCambioEstadoSinFotografia')]);
        this.disabled = false;
      }, err => {
        var errorBody = this.translateService.instant('Ha habido algún error con la subida del albarán y/o de la firma.');
        var errorTitulo = this.translateService.instant('Error');
        this.toastr.error(errorBody, errorTitulo);
        this.disabled = false;
      });
      //}
    }else if(this.isEntregaAlbaran()){ // El estado que vamos a cambiar es Entregado a falta de albarán
      // Primero comprobamos los errores posibles del formulario
      if(!this.fotoRealizada){ // Solo se necesita comprobar la foto/pdf si se ha subido
        this.mostrarMensajeError('TitleErrorNecesidadFotografia', 'BodyErrorNecesidadFotografia');
      }else{
        var aux = new AlbaranYFirmaViewModel();
        aux.AlbaranFoto = this.formCambiarEstado.value.photo[0];
        aux.AlbaranPDF = this.formCambiarEstado.value.pdfs[0];
        aux.DNI = "";
        aux.Firma = null;
        aux.Visible = "1";
        aux.IdExpedicion = this.numero;
        this.service.cambiarEstado(this.siguienteEstado(), this.numero, aux, log).subscribe(data => {
          this.formCambiarEstado.value.firma = null;
          this.verificacion.emit([true, this.translateService.instant('La fotografía se ha subido correctamente y se ha cambiado el estado correctamente.')])
          this.disabled = false;
        }, err => {
          var errorBody = this.translateService.instant('Ha habido algún error con la subida del albarán y/o de la firma.');
          var errorTitulo = this.translateService.instant('Error');
          this.toastr.error(errorBody, errorTitulo);
          this.disabled = false;
        });
      }
    }else{ // Cambios a recogido
      // Cambio de estado
      this.service.cambiarEstado(this.siguienteEstado(), this.numero, null, log).subscribe(data => {
        this.disabled = false;
        this.verificacion.emit([true, this.translateService.instant("El estado de la expedición se ha modificado correctamente.")])
      }, 
      err => {
        var errorBody = this.translateService.instant('Error al cambiar el estado de ' + this.estados[0] + ' a ' + this.estados[1] + '.');
        var errorTitulo = this.translateService.instant('Error');
        this.toastr.error(errorBody, errorTitulo)
        this.disabled = false;
      });
    }
  }

  // Comprobación de errores
  comprobarErrores(){
    this.disabled = false;
    // if(this.estaVacioFirma() && this.fotoRealizada) // Firma está en blanco
    //   this.errorFirma = true;
    // else
    //   this.errorFirma = false;
    
    /*if(!this.estaVacioDNI() && this.comprobarDNI())
      this.errorDni = true;
    else
      this.errorDni = false;*/
  }
}
