<div class="container">
    <div id="General" class="contenedor">
        <h5>{{'General' | translate}}</h5>
        <div class="row">
            <div class="col-sm-12 col-12">
                <app-general></app-general>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div id="Embalajes" class="contenedor">
        <h5>{{'Embalajes' | translate}}</h5>
        <div class="row">
            <div class="col-sm-6">
                <app-embalajes [embalaje]="embalaje"></app-embalajes>
            </div>
            <div class="col-sm-6">
                <app-lista-embalajes (embalaje)="getEmbalaje($event)"></app-lista-embalajes>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div id="Horario" class="contenedor">
        <h5>{{'Horario' | translate}}</h5>
        <div class="row">
            <div class="col-12">
                <app-horario></app-horario>
            </div>
        </div>
    </div>
</div>