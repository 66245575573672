<table *ngIf="mercanciasCotizacion != undefined && mercanciasCotizacion != null && mercanciasCotizacion.length > 0"
  style="border-top-width: 1px !important;"
  class="table table-sm table-bordered table-editable table-row-action edit-action">
  <thead>
    <th>{{ "TipoMercancia" | translate }}</th>
    <th>{{ "TipoEmbalaje" | translate }}</th>
    <th>{{ "Bultos" | translate }}</th>
    <th>{{ "Largo" | translate }}</th>
    <th>{{ "Ancho" | translate }}</th>
    <th>{{ "Alto" | translate }}</th>
    <th>{{ "Volumen" | translate }}</th>
    <th>{{ "Peso Bruto" | translate }}</th>
    <th>{{ "Remontable" | translate }}</th>
    <th>{{ "D.G." | translate }}</th>
    <th>{{ "Alimentación" | translate }}</th>
    <th>{{ "GDP" | translate }}</th>
    <th>{{ "TemperaturaControladaAbreviatura" | translate }}</th>
    <th *ngIf="existeMercanciaPeligrosaLista()"></th>
    <th *ngIf="existeTemperaturaControladaLista()"></th>
  </thead>
  <tbody *ngFor="let mercancia of mercanciasCotizacion; let i = index;">
    <tr (click)="marcarMercancia(i)">
      <td>{{ mercancia.NombreMercancia }}</td>
      <td class="textCenter">{{ mercancia.NombreEmbalaje }}</td>
      <td class="textCenter">{{ mercancia.Bultos }}</td>
      <td class="textCenter">{{ mercancia.Largo }}</td>
      <td class="textCenter">{{ mercancia.Ancho }}</td>
      <td class="textCenter">{{ mercancia.Alto }}</td>
      <td class="textCenter">{{ mercancia.Volumen }}</td>
      <td class="textCenter">{{ mercancia.PesoBruto }}</td>
      <td class="textCenter" *ngIf="mercancia.Remontable">{{ 'Si' | translate }}</td>
      <td class="textCenter" *ngIf="!mercancia.Remontable">{{ 'No' | translate }}</td>
      <td class="textCenter" *ngIf="mercancia.Peligrosa">{{ 'Si' | translate }}</td>
      <td class="textCenter" *ngIf="!mercancia.Peligrosa">{{ 'No' | translate }}</td>
      <td class="textCenter" *ngIf="mercancia.Alimentacion">{{ 'Si' | translate }}</td>
      <td class="textCenter" *ngIf="!mercancia.Alimentacion">{{ 'No' | translate }}</td>
      <td class="textCenter" *ngIf="mercancia.GDP">{{ 'Si' | translate }}</td>
      <td class="textCenter" *ngIf="!mercancia.GDP">{{ 'No' | translate }}</td>
      <td class="textCenter" *ngIf="mercancia.TemperaturaControlada">{{ 'Si' | translate }}</td>
      <td class="textCenter" *ngIf="!mercancia.TemperaturaControlada">{{ 'No' | translate }}</td>
      <td class="textCenter" *ngIf="hayMercanciaPeligrosa(mercancia)">
        <mat-icon *ngIf="!estaSeleccionada(i)">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="estaSeleccionada(i)">keyboard_arrow_up</mat-icon>
      </td>
      <td class="textCenter" *ngIf="!hayMercanciaPeligrosa(mercancia) && existeMercanciaPeligrosaLista()"></td>
      <td class="textCenter" *ngIf="hayTemperaturaControlada(mercancia)">
        <mat-icon *ngIf="!estaSeleccionada(i)">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="estaSeleccionada(i)">keyboard_arrow_up</mat-icon>
      </td>
      <td class="textCenter" *ngIf="hayTemperaturaControlada(mercancia) && existeTemperaturaControladaLista()"></td>
    </tr>
    <!-- <div class="contenedor">
        <h5>Mercancía peligrosa</h5> -->
    <tr *ngIf="hayMercanciaPeligrosa(mercancia) && estaSeleccionada(i)">
      <td [ngClass]="{'animate__animated animate__bounceInDown': hayMercanciaPeligrosa(mercancia) && estaSeleccionada(i)}" [attr.colspan]="14">
        <app-detalles-mercancia-peligrosa style="margin-left: 30%;" [visible]="showColums" [mercanciasPeligrosas]="mercancia.MercanciaPeligrosa">
        </app-detalles-mercancia-peligrosa>
      </td>
    </tr>
    <tr *ngIf="hayTemperaturaControlada(mercancia) && estaSeleccionada(i)">
        <td [ngClass]="{'animate__animated animate__bounceInDown': hayTemperaturaControlada(mercancia) && estaSeleccionada(i)}" [attr.colspan]="14">
          <app-detalles-grados style="margin-left: 30%;" [gradoMinimo]="mercancia.GradosMin" [gradoMaximo]="mercancia.GradosMax">
          </app-detalles-grados>
        </td>
    </tr>
<!-- </div> -->
  </tbody>
  <tfoot>
    <tr>
      <td><strong>{{'Totales' | translate }}</strong></td>
      <td></td>
      <td class="textCenter" ><strong>{{totalBultos}}</strong></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td class="textCenter" ><strong>{{totalPesoBruto}}</strong></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td *ngIf="existeMercanciaPeligrosaLista()"></td>
      <td *ngIf="existeTemperaturaControladaLista()"></td>
    </tr>
  </tfoot>
</table>
