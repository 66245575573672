import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActualizarConductor } from 'src/app/models/actualizarConductor.model';
import { Conductor } from 'src/app/models/conductor.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Matricula } from 'src/app/models/matricula.model';
import { Vehiculo } from 'src/app/models/vehiculo.model';
import { ConductorService } from 'src/app/services/conductor.service';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { VehiculoService } from 'src/app/services/vehiculo.service';

@Component({
  selector: 'app-asignar-matricula',
  templateUrl: './asignar-matricula.component.html',
  styleUrls: ['./asignar-matricula.component.scss']
})
export class AsignarMatriculaComponent implements OnInit {

  matriculaControl: FormControl = new FormControl('', Validators.required);
  conductorControl: FormControl = new FormControl('', Validators.required);
  clase: string = "app-asignar-matricula";
  listaMatriculas : Vehiculo[] = [];
  listaConductores: Conductor[] = [];
  selectedConductor: boolean = false;
  selectedMatricula: boolean = false;
  modalRef: BsModalRef | null;
  @Input() numeroExp: string = "";
  @Output() cerrarModal = new EventEmitter<number>();
  filteredMatricula: Observable<Vehiculo[]>;
  filteredConductor: Observable<Conductor[]>;


  constructor(private proveedorService: ProveedorService, private conductorService: ConductorService, private toastr: ToastrService, private modalService: BsModalService,
    public translate: TranslateService, private expedicionService: ExpeditionApiService, private vehiculoService: VehiculoService) { }

  ngOnInit(): void {
    this.conductorService.getAllConductores();
    this.conductorService.getConductores$().subscribe(data => {
      this.listaConductores = data;
      if(this.selectedConductor){
        this.selectedConductor = false;
        this.conductorControl.setValue(this.listaConductores.sort((conductor1, conductor2) => {return conductor2.IdConductor - conductor1.IdConductor})[0]);
      }
      if(this.conductorControl.value)
        this.listaConductores.forEach(c => {
          if(c.IdConductor == this.conductorControl.value.IdConductor)
            this.conductorControl.setValue(c)
        });
      this.filteredConductor = this.conductorControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterConductor(value || '')),
      );
    }, err =>{});
    this.proveedorService.getAllMatriculas();
    this.proveedorService.getVehiculos().subscribe(data => {
      this.listaMatriculas = data;
      if(this.selectedMatricula){
        this.selectedMatricula = false;
        this.matriculaControl.setValue(this.listaMatriculas.sort((matricula1, matricula2) => { return Number(matricula2.IdVehiculo) - Number(matricula1.IdVehiculo)})[0]);
      }
      if(this.matriculaControl.value)
        this.listaMatriculas.forEach(m => {
          if(m.IdVehiculo == this.matriculaControl.value.IdVehiculo)
            this.matriculaControl.setValue(m);
        });
      this.filteredMatricula = this.matriculaControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterVehiculo(value || '')),
      );
    }, err => {});
  }

  refreshVehiculo(event){
    if(event)
      this.selectedMatricula = true;
    this.modalRef.hide();
    this.proveedorService.getAllMatriculas();
  }

  refreshConductor(event){
    if(event)
      this.selectedConductor = true;
    
    this.conductorService.getAllConductores();
    this.modalRef.hide();
  }

  desasignarAll(click: string){
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.clase;
    this.expedicionService.putExpedicionVehiculoYConductorConjunto([this.numeroExp], null, null, log).subscribe(data => {var title: string = this.translate.instant("Actualizado correcto");
      var body: string = this.translate.instant("Se ha actualizado correctamente el conductor.");
      this.cerrarModal.emit(2);
      this.toastr.success(body, title);
      this.proveedorService.getExpediciones();
    }, err => {
      var title: string = this.translate.instant("Error");
      var body: string = this.translate.instant("Error al actualizar el conductor");
      this.toastr.error(body, title);
    });
  }

  asignarAll(click: string){
    if(!this.conductorControl.valid && !this.matriculaControl.valid){
      var body: string = this.translate.instant("ConductorYVehiculoErrorBody");
      var title: string = this.translate.instant("ConductorYVehiculoErrorTitle");
      this.toastr.error(body, title);
    }else if(!this.conductorControl.valid){
      var body: string = this.translate.instant("ConductorErrorBody");
      var title: string = this.translate.instant("ConductorErrorTitle");
      this.toastr.error(body, title);
    }else if(!this.matriculaControl.valid){
      var body: string = this.translate.instant("VehiculoErrorBody");
      var title: string = this.translate.instant("VehiculoErrorTitle");
      this.toastr.error(body, title);
    }else{
      var log: LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      var expediciones: string[] = [this.numeroExp];
      var idConductor = this.conductorControl.value.IdConductor == 0 ? null : this.conductorControl.value.IdConductor;
      var idVehiculo = this.matriculaControl.value.IdVehiculo == 0 ? null : this.matriculaControl.value.IdVehiculo;
      this.expedicionService.putExpedicionVehiculoYConductorConjunto(expediciones, idConductor, idVehiculo, log).subscribe(data => {
        var title: string = this.translate.instant("ConductorYVehiculoSuccessTitle");
        var body: string = this.translate.instant("ConductorYVehiculoSuccessBody");
        this.cerrarModal.emit(2);
        this.toastr.success(body, title);
        this.proveedorService.getExpediciones();
      }, err => {
        var title: string = this.translate.instant("ConductorYVehiculoErrorTitleSubs");
        var body: string = this.translate.instant("ConductorYVehiculoErrorBodySubs");
        this.toastr.error(body, title);
      });
    }
  }

  private _filterVehiculo(value): Vehiculo[] {
    if(typeof value === 'string')
      return this.listaMatriculas.filter(option => option.Matricula?.toLowerCase().includes(value.toLowerCase()) || option.Tipo?.toLowerCase().includes(value.toLowerCase()));
    else if(value && value.IdVehiculo != null && value.IdVehiculo != undefined)
      return this.listaMatriculas.filter(option => option.Matricula?.toLowerCase().includes(value.Matricula?.toLowerCase()) || option.Tipo?.toLowerCase().includes(value.Tipo?.toLowerCase()));

    return this.listaMatriculas;
  }

  public displayVehiculo = (idVehiculo) => {
    if(idVehiculo && typeof idVehiculo === 'number')
      return this.listaMatriculas.filter(tv => tv.IdTipoVehiculo == idVehiculo.toString())[0].Matricula + " - " + this.listaMatriculas.filter(tv => tv.IdTipoVehiculo == idVehiculo.toString())[0].Tipo;
    else if(idVehiculo)
      return idVehiculo.Matricula + " - " + idVehiculo.Tipo;
  }

  private _filterConductor(value: string): Conductor[] {
    if(value && this.chartactersOnly(value))
      return this.listaConductores.filter(option => option.Nombre?.toLowerCase().includes(value.toLowerCase()) 
      || option.Apellidos?.toLowerCase().includes(value.toLowerCase()) 
      || option.Email?.toLowerCase().includes(value.toLowerCase()));

    return this.listaConductores;
  }

  public displayConductor = (idConductor) => {
    if(typeof idConductor === 'number')
      return this.listaConductores.filter(tv => tv.IdConductor == idConductor)[0].Nombre;
    else if(typeof idConductor === 'string')
      return idConductor;
    else if(idConductor)
      return idConductor.Nombre + " " + idConductor.Apellidos;
  }

  public onBlurConductor(event){
    if(event.target.value != undefined && event.target.value != null){
      if(event.target.value && this.chartactersOnly(event.target.value))
        this.conductorControl.setValue(this.listaConductores.filter(tv => tv.Nombre?.toLowerCase().startsWith(event.target.value.toLowerCase())
        ||tv.Apellidos?.toLowerCase().startsWith(event.target.value.toLowerCase()) || tv.Email?.toLowerCase().startsWith(event.target.value.toLowerCase()) || 
        event.target.value.toLowerCase().startsWith(tv.Nombre?.toLowerCase() + " " + tv.Apellidos?.toLowerCase()))[0]);
    }
  }

  openModal(template: TemplateRef<any>, accion: string = "") {
    if(accion == 'updateConductor')
      this.conductorService.getConductor(this.conductorControl.value.IdConductor.toString()).subscribe(conductor => {
        this.conductorService.setConductor$(conductor);
      });
    else if(accion == 'updateMatricula')
      this.vehiculoService.getVehiculo(this.matriculaControl.value.IdVehiculo.toString()).subscribe(vehiculo => {
        this.vehiculoService.setVehiculo$(vehiculo);
      });

    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 2
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }
  
  public onBlurMatricula(event){
    if(event.target.value != undefined && event.target.value != null)
      if(event.target.value)
        this.matriculaControl.setValue(this.listaMatriculas.filter(te => te.Matricula?.toLowerCase().startsWith(event.target.value.toLowerCase()) ||
        te.Tipo?.toLowerCase().startsWith(event.target.value.toLowerCase()) || event.target.value.toLowerCase().startsWith(te.Matricula?.toLowerCase() + " - " + te.Tipo?.toLowerCase()))[0]);
  }

  chartactersOnly (e) {
    var regex = new RegExp("^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ ]*$");
    if (regex.test(e))
        return true;
    
    return false;
  }

  cancelar(){
    this.cerrarModal.emit(1);
  }

}
