<form [formGroup]="formAddressBook">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'NombreCompañia'|translate}}*</mat-label>
                    <input class="inputMaterial" type="text" matInput placeholder="{{'NombreCompañia'|translate}}" [formControl]="nombreFiscal">
                    <mat-error *ngIf="!nombreFiscal.valid">
                      {{'Introduce el nombre'|translate}}
                    </mat-error>
                  </mat-form-field>
            </div>
        </div>
        <div class="row" style="margin-top: 2%;">
            <div class="col-sm-12">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Pais' | translate}}*</mat-label>
                    <mat-select name="pais" id="pais" class="select-small"
                        [formControl]="pais" (selectionChange)="changePais()" [loading]="cargaPaises"
                      loadingText="Cargando..." >
                      <mat-form-field appearance="standard" class="example-full-width">
                        <mat-label>{{'Búsqueda' | translate}}</mat-label>
                        <input matInput (keyup)="onKey($event.target.value)" #busqueda>
                        <button mat-button matPrefix mat-icon-button>
                            <mat-icon>search</mat-icon>
                        </button>
                        <button *ngIf="busqueda.value" matSuffix mat-icon-button aria-label="Clear" (click)="onKey(''); busqueda.value = '';">
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      <mat-option *ngFor="let p of listaPaises" [value]="p">
                        <img class="bandera" src="{{getBandera(p)}}">&nbsp;&nbsp;{{p.Nombre}}
                        <!-- &nbsp;-->
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Localidad'|translate}}*</mat-label>
                    <input matInput #SearchField autocomplete="off" type="search" id="Localidad"
                          name="Localidad" [ngModelOptions]="{standalone: true}" [readonly]="!pais.valid" (focus)="comprobarErrorPaisSeleccionado()">
                    <mat-error *ngIf="!localidad.valid">
                      {{'Introduce la localidad'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Direccion'|translate}}*</mat-label>
                    <textarea matInput placeholder="{{'Direccion'|translate}}" [formControl]="address"></textarea>
                    <mat-error *ngIf="!address.valid">
                      {{'Introduce la dirección'|translate}}
                    </mat-error>
                  </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="pais.valid">
            <div class="col-sm-12">
                <app-mapa [paisOrigen]="pais.value" [search]="searchField" [latitude]="latitude"
                  [longitude]="longitude" [existMarkerOrigen]="existMarker"
                  [existMarkerOrigen]="existMarker" [origen]="true"
                  [destino]="localidadSelected" [latitudeMapa]="latitudeMapa"
                  [longitudeMapa]="longitudeMapa" [zoom]="zoom"
                  [localidadOrigen]="localidad.value" [localidadDestino]="localidad.value"
                  [obtenerPosicionInicialOrigen]="obtenerPosicionInicial" [obtenerPosicionDestinoOrigen]="false"
                  (localidadOrigenEmit)="getLocalidadOrigenEmit($event)"></app-mapa>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'CIF'|translate}}</mat-label>
                    <input class="inputMaterial" type="text" matInput placeholder="{{'CIF'|translate}}" [formControl]="CIF">
                  </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Telefono'|translate}}</mat-label>
                    <input class="inputMaterial" type="text" matInput placeholder="{{'Telefono'|translate}}" [formControl]="telefono1">
                  </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Email'|translate}}</mat-label>
                    <input class="inputMaterial" type="text" matInput placeholder="{{'Email'|translate}}" [formControl]="email">
                    <mat-error *ngIf="!email.valid">
                      {{'Introduce correctamente la dirección de correo'|translate}}
                    </mat-error>
                  </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'NombreContacto'|translate}}</mat-label>
                    <input class="inputMaterial" type="text" matInput placeholder="{{'NombreContacto'|translate}}" [formControl]="nombreContacto">
                  </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <p>* : {{'indicador de campos obligatorios' | translate}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <button *ngIf="!isUpdate()" type="button" class="btn margenBot" id="crear-addressBook" (click)="postAddressBook('crear-addressBook')" matButton>{{'Crear'|translate}}</button>
                <button *ngIf="isUpdate()" type="button" class="btn margenBot" id="actualizar-addressBook" (click)="updateAddressBook('actualizar-addressBook')" matButton>{{'Modificar'|translate}}</button>
            </div>
            <div class="col-sm-4">
                <button type="button" class="btn margenBot" (click)="resetear();" matButton>{{'Limpiar'|translate}}</button>
            </div>
        </div>
    </div>
</form>