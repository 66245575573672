import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmacion-borrado',
  templateUrl: './confirmacion-borrado.component.html',
  styleUrls: ['./confirmacion-borrado.component.scss']
})
export class ConfirmacionBorradoComponent implements OnInit {

  @Output() confirmEmit = new EventEmitter<Boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  confirmacion(){
    this.confirmEmit.emit(true)
  }

  cancelacion() {
    this.confirmEmit.emit(false)
  }
  
}
