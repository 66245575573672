<mat-form-field appearance="standard" class="width-100">
    <mat-label>{{'Búsqueda' | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
    <button mat-button matPrefix mat-icon-button>
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
<table mat-table [dataSource]="listAddressBook" class="mat-elevation-z0">

    <ng-container matColumnDef="nombreFiscal">
        <th mat-header-cell *matHeaderCellDef>{{'Nombre Fiscal' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.nombreFiscal}} </td>
    </ng-container>

    <ng-container matColumnDef="direccion">
        <th mat-header-cell *matHeaderCellDef> {{'Direccion'| translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-icon class="cursor" (click)="setRow(element);">create</mat-icon>
            <mat-icon class="delete-icon cursor" (click)="addressBook = element; openModal(templateDesactivarAddressBook);">delete</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
</table>

<mat-paginator [length]="lengthTable" [pageSizeOptions]="sizeOptions" [pageSize]="itemsPorPage" showFirstLastButtons></mat-paginator>

<ng-template #templateDesactivarAddressBook>
    <div class="modal-header fondo">
      <p class="h4 modal-title pull-left">{{'BorrarAddressBookTitle'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-borrar-address-book [addressBookSelected]="addressBook" (cerrarModal)="cerrar($event)"></app-borrar-address-book>
    </div>
  </ng-template>