import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { User } from "../models/user.model";
// Para poner la url de la API sin tener que cambiar todos
import { environment } from '../../environments/environment';
import { Conductor } from '../models/conductor.model';
import { LogModel } from '../models/log-model.model';
import { CambiarPassword } from '../models/cambiarPassword.model';
import { LogService } from './log.service';
import { RegistroUsuario } from '../models/registroUsuario.model';
import { Archivo } from '../models/archivo.model';
import { AddressBook } from '../models/addressBook.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressBookService {

  readonly apiUrlService = environment.apiUrl;
  ip: string = ""; 
  localizacion: string = "";
  addressBook$: Subject<AddressBook> = new Subject();
  addressBooks$: Subject<AddressBook[]> = new Subject();


  getAddressBooks$(): Observable<AddressBook[]>{
    return this.addressBooks$.asObservable();
  }

  getAddressBook$(): Observable<AddressBook>{
    return this.addressBook$.asObservable();
  }

  setAddressBook$(addressBook: AddressBook){
    this.addressBook$.next(addressBook);
  }

  constructor(private http: HttpClient, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
   }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  //Obtenemos el token de la variable local
  getToken(){
    return localStorage.getItem("userToken");
  }

  getAllAddressBook(){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    });
    const url_api = this.apiUrlService + "myxgl_addressbook";
    this.logService.postLogCompleto("GET", "web_privilegioController.cs", "Log del privilegio de obtención de todos los address book.", "Get_privilegio", "web_privilegios", url_api);
    return this.http.get(url_api, {headers: headersToken}).pipe(map(data => data as AddressBook[])).subscribe(abs => {
      this.addressBooks$.next(abs);
    });;
  }

  postAddressBook(addressBook: AddressBook, log: LogModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken(),
      "Content-Type": "application/json"
    });
    const url_api = this.apiUrlService + "myxgl_addressbook";
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(addressBook)}), {headers: headersToken}).pipe(map(data => data as boolean));
  }

  putAddressBook(addressBook: AddressBook, log: LogModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken(),
      "Content-Type": "application/json"
    });
    const url_api = this.apiUrlService + "myxgl_addressbook";
    return this.http.put(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(addressBook)}), {headers: headersToken}).pipe(map(data => data as AddressBook[]));
  }
}
