<div class="container">
    <div class="table-responsive-sm table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-sm table-hover table-editable table-row-action edit-action table-bordered">
            <thead>
                    <th>{{'Tipo'|translate}}</th>
                    <th>{{'Fecha'|translate}}</th>
                    <th>{{'Estado'|translate}}</th>
            </thead>
            <tbody >
                <ng-container *ngFor="let list of listaIncidencias">
                    <tr style="cursor: pointer;" (click)="mostrarInformacion(list);">
                        <td>{{list.TipoIncidencia}}</td>
                        <td>{{pipe.transform(list.FechaCreacion, 'shortDate')}}</td>
                        <td *ngIf="list.FechaCierre == null">{{'Abierto'|translate}}</td>
                        <td *ngIf="list.FechaCierre != null">{{'Cerrada'|translate}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
