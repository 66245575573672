<app-header></app-header>
<div class="container-fluid">
    <div class="container">
        <div class="block">
            <h1>{{'LISTADO DE ENVIOS'|translate}}</h1>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col"><h3>{{'Búsqueda general'|translate}}</h3></div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <input  type="text" name="search" class="form-control" placeholder="{{'Búsqueda general'|translate}}" [(ngModel)]="searchText" (ngModelChange)="changeSearch()">
                    </div>
                    <div class="col-sm-4 ">
                        <p><!--{{'Solo se muestran los envíos de los últimos 30 días'|translate}}.<br>{{'Si quieres ver los envíos antiguos pincha'|translate}} -->
                            <a role="button" class="busquedaAvanzada" (click)="changeBusquedaAvanzanda()">{{'Búsqueda avanzada'|translate}}.</a>
                            <!--<p class="busquedaAvanzada" *ngIf="busquedaAvanzada">Ocultar búsqueda avanzada</p>-->
                        </p>
                    </div>
              
                    <br>
                    <div *ngIf="!isMaritimo()" class="col-sm-2 ">
                        <button mat-raised-button type="button" (click)="exportExcel()"><img src="assets/iconos/export_16.png" alt="">Export</button>
                    </div>
                    <div *ngIf="isMaritimo() && showButtonsOption2" class="col-sm-2 ">
                        <button mat-raised-button type="button" (click)="exportExcelLCL();"><img src="assets/iconos/export_16.png" alt="">Export</button>
                    </div>
                    <div *ngIf="isMaritimo() && showButtonsOption1" class="col-sm-2 ">
                        <button mat-raised-button type="button" (click)="exportExcelFCL()"> <img src="assets/iconos/export_16.png" alt="">Export</button>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- FILTROS COMIENZO -->
        <form #formFiltro="ngForm" (ngSubmit)="submitBusquedaAvanzada(formFiltro)">
            <div class="contenedor" *ngIf="busquedaAvanzada" style="margin-bottom: 20px;">
                <h5>{{'Filtros'|translate}}</h5>
                <div class="row" style="margin: 10px;">
                    <div class="col-10" >
                        <div class="row">
                            <div class="col-4">
                                <div class="row">
                                    <div class="col"><label>{{'Fecha recogida inicio'|translate}}</label></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input name="FechaRecogidaInicio" title="Fecha de recogida inicio" type="date" id="FechaRecogidaInicio" name="FechaRecogidaInicio"
                                        style="height: 29px;" #FechaRecogidaInicio="ngModel" class="form-control"
                                        [(ngModel)]="form.value.fechaRecogidaInicio">
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col"><label>{{'Fecha recogida final'|translate}}</label></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input name="FechaRecogidaFinal" title="Fecha de recogida final" type="date" id="FechaRecogidaFinal" name="FechaRecogidaFinal"
                                        style="height: 29px;" class="form-control" #FechaRecogidaFinal="ngModel"
                                        [(ngModel)]="form.value.fechaRecogidaFinal">
                                        <div *ngIf="(formFiltro.submitted && comprobarFechaFinal())" class="text-danger" style="font-size: small;margin-bottom: 20px;">
                                            {{'Fecha recogida final es más temprana que fecha recogida inicio'|translate}}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4"></div>
                        </div>
                        <div class="row" >
                            <div class="col-4">
                                <div class="row">
                                    <div class="col"><label>{{'Remitente'|translate}}</label></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input  type="text" name="SearchRemitente" class="form-control" placeholder="Remitente" style="height: 29px;"
                                        #SearchRemitente="ngModel" [(ngModel)]="form.value.searchRemitente">
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col"><label>{{'Destinatario'|translate}}</label></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input  type="text" name="SearchDestinatario" class="form-control" placeholder="Destinatario" style="height: 29px;"
                                        #SearchDestinatario="ngModel" [ngModel]="form.value.searchDestinatario">
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col"><label>{{'Partida/Expedición'|translate}}</label></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input  type="text" name="SearchPartida" class="form-control" style="height: 29px;"
                                        #SearchPartida="ngModel" placeholder="Partida/Expedicion" [ngModel]="form.value.searchPartida">
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>  
                     <div class="col-2 d-flex align-items-center">
                        <button class="btn btn-primary" id="filtroEnvios" style="margin: auto;" type="submit">{{'Filtrar'|translate}}</button>
                     </div>
                </div>
            </div>
        </form>
        <!-- FILTROS FINAL -->

        <mat-tab-group dynamicHeight #maritimo *ngIf="isMaritimo()" mat-align-tabs="center" (selectedTabChange)="onTabSelectionChanged($event)">
            <mat-tab #fcl label="{{'FCL' | translate}}" >
                <app-listado-shipment [listado]="listFCL" [modoEnvioSelected]="0" [total]="listFCLAux.length" [activa]="true" [idArea]="idArea"></app-listado-shipment>
            </mat-tab>          
            <mat-tab #lcl label="{{'LCL' | translate}}" >
                <app-listado-shipment [listado]="listLCL" [modoEnvioSelected]="1" [total]="listLCLAux.length" [activa]="true" [idArea]="idArea"></app-listado-shipment>
            </mat-tab>
        </mat-tab-group>

        <span *ngIf="!isMaritimo()">
            <app-listado-shipment [listado]="list" [modoEnvioSelected]="1" [total]="list.length" [activa]="true" [idArea]="idArea"></app-listado-shipment>
        </span>
        
        
        <ng-template #detailTemplate>
            <div class="modal-content fondo">
                <div class="modal-header fondo">
                    <h2 *ngIf="!isTerrestre()" class="modal-title pull-left">{{'Detalles'|translate}} {{shipmentService.envio.RefCliente + " / " + shipmentService.envio.Partida + " - " + shipmentService.envio.Viaje }}</h2>
                    <h2 *ngIf="isTerrestre()" class="modal-title pull-left">{{'Detalles'|translate}} {{shipmentService.envio.RefCliente + " / " + shipmentService.envio.Expedicion}}</h2>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body fondo">
                    <app-shipping-tracking (close)="cerrar($event)"></app-shipping-tracking>
                </div>
            </div>
        </ng-template>
        
        <ng-template #documentsTemplate>
            <div class="modal-content fondo">
                <div class="modal-header fondo">
                <h2 class="modal-title pull-left">{{'Documentos'|translate}}</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body fondo">
                    <app-shipping-files (close)="cerrar($event)"></app-shipping-files>
                </div>
            </div>
        </ng-template>
        
        <ng-template #subirArchivosTemplate style="border-color: black; background-color: white;">
            <div class="modal-content fondo" style="border-color: black; background-color: white;">
                <div class="modal-header fondo" style="border-color: black; background-color: white;">
                <h2 class="modal-title pull-left">{{'Adjuntar Archivo'|translate}}aaaaa</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body fondo" style="border-color: black; background-color: white;">
                    <app-upload-file [documentacion]="null" (listFiles)="receiveFiles($event)" [envio]="null"></app-upload-file>
                </div>
            </div>
        </ng-template>

        <!-- The Modal -->
        <div class="modal fade" id="myAttach">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
        
                    <!-- Modal Header -->
                    <div class="modal-header fondo">
                        <p class="h4 modal-title">{{'Adjuntar Archivo'|translate}}</p>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
        
                    <!-- Modal body -->
                    <div class="modal-body fondo">
                        <app-upload-file [documentacion]="null" (listFiles)="receiveFiles($event)" [envio]="null"></app-upload-file>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</div>
    

