import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacturacionService } from 'src/app/services/facturacion.service';
import { saveAs } from 'file-saver';
import { LogModel } from 'src/app/models/log-model.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { FacturasListado } from 'src/app/models/facturasListado.model';
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.scss']
})
export class FacturacionComponent implements OnInit {

  idArea: string = "";
  pantalla: string = "app-facturacion";
  listadoTodos: FacturasListado[] = [];
  listadoAereo: FacturasListado[] = [];
  listadoMaritimo: FacturasListado[] = [];
  listadoTerrestre: FacturasListado[] = [];
  totalTodos: number = 0;
  totalAereo: number = 0;
  totalMaritimo: number = 0;
  totalTerrestre: number = 0;
  areasListTodos: string[] = ['Aereo', 'Maritimo', 'Terrestre'];
  areasListAereo: string[] = ['Aereo'];
  areasListMaritimo: string[] = ['Maritimo'];
  areasListTerrestre: string[] = ['Terrestre'];


  constructor(private route: ActivatedRoute, private facturacionService: FacturacionService, private dialog: MatDialog) { }

  ngOnInit(): void {
    const dialogRef = this.dialog.open(WaitingComponent, {
    width: '250px',
    panelClass: 'my-panel',
    disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => {});
    // TODAS
    this.facturacionService.getFacturasCliente(0).subscribe(listadoFacturas => {
      this.listadoTodos = listadoFacturas;
      this.totalTodos = this.listadoTodos.length;
      // AEREO
      this.listadoAereo = this.listadoTodos.filter(lfc => lfc.Area == 2);
      this.totalAereo = this.listadoAereo.length;
      // MARITIMO
      this.listadoMaritimo = this.listadoTodos.filter(lfc => lfc.Area == 1);
      this.totalMaritimo = this.listadoMaritimo.length;
      // TERRESTRE
      this.listadoTerrestre = this.listadoTodos.filter(lfc => lfc.Area == 0);
      this.totalTerrestre = this.listadoTerrestre.length;
      dialogRef.close();
    });
  }
}
