<div class="row">
    <div class="col-sm-12">
        <p>{{'¿Estás seguro que desea borrar a'|translate}} {{getNombreCompleto()}}</p>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <button class="btn" type="button" (click)="putConductor('boton-borrado-conductor')">{{'Borrar'|translate}}</button>
    </div>
    <div class="col-sm-6">
        <button class="btn" type="button" (click)="cancelar();">{{'Cancelar'|translate}}</button>
    </div>
</div>
