import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Archivo } from 'src/app/models/archivo.model';
import { LogModel } from 'src/app/models/log-model.model';
import { TipoArchivoProveedor } from 'src/app/models/tipo-archivo-proveedor.model';
import { EntityService } from 'src/app/services/entity.service';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/es';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subir-fichero-entidad',
  templateUrl: './subir-fichero-entidad.component.html',
  styleUrls: ['./subir-fichero-entidad.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class SubirFicheroEntidadComponent implements OnInit {

  clase: string = "app-subir-fichero-entidad";
  formUploadFile: FormGroup;
  dataFile: Archivo;
  minDate: Date;
  @Input() TipoArchivo: TipoArchivoProveedor;
  @Output() close: EventEmitter<Boolean> = new EventEmitter<Boolean>();;
  modalRef: BsModalRef | null;
  public get FileName() { return this.formUploadFile.get('FileName') }
  public get IdTipoArchivo() { return this.formUploadFile.get('IdTipoArchivo') }
  public get FechaFinalArchivo() { return this.formUploadFile.get('FechaFinalArchivo') }
  public get Extension() { return this.formUploadFile.get('Extension') }
  public get Base64image() { return this.formUploadFile.get('Base64image') }
  public get Tamanyo() { return this.formUploadFile.get('Tamanyo') }
  public get Path() { return this.formUploadFile.get('Path') }

  constructor(private formBuilder: FormBuilder, public toastr: ToastrService, private _adapter: DateAdapter<any>, 
    private entidadService: EntityService, private modalService: BsModalService, public translate: TranslateService) {
      this._adapter.setLocale('es');
     }

  ngOnInit(): void {
    this.minDate = new Date();
    this.formUploadFile = this.formBuilder.group(
      {
        FileName: [null, Validators.required],
        IdTipoArchivo: [null, [Validators.required]],
        FechaFinalArchivo: ["", [Validators.required]]
    });
  }

  uploadFile(fileList){
    if(fileList.length > 0){
      let me = this;
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if(file.size > 0 && file.size < 26214400 ){
          var array = file.name.split('.');
          var extension = array[array.length-1];
          var nombre = file.name.split('.'+extension)[0].replaceAll('.', '');
          me.formUploadFile.value.Base64image = reader.result
          me.formUploadFile.value.Tamanyo = file.size;
          me.formUploadFile.value.FileName = me.TipoArchivo.tipo;
          me.formUploadFile.value.IdTipoArchivo = me.TipoArchivo.codigo;
          let archivo = new Archivo();
          archivo.FechaFinalValidez = me.FechaFinalArchivo.value;
          archivo.Base64image = reader.result;
          archivo.FileName = me.TipoArchivo.tipo;
          archivo.Tamanyo = file.size;
          archivo.Extension = extension;
          archivo.IdTipoArchivo = me.formUploadFile.value.IdTipoArchivo;
          
          me.dataFile = archivo;
          me.limpiarFormulario();
        }else{
          me.toastr.error("El peso del archivo subido no puede ser mayor a 25 MB.", "Peso mayor a 25 MB");
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    fileList = null;
  }

  formatBytes(bytes) {
    if(bytes === 0) 
      return ' 0 Bytes';
    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k,i)).toFixed(dm)) + ' ' + sizes[i];
  }

  limpiarFormulario(){
    this.formUploadFile.reset();
    this.FechaFinalArchivo.reset();
  }

  guardarArchivos(click: string) {
    if(this.dataFile != null){
      var log: LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      var dataFiles: Archivo[] = [this.dataFile];
      this.entidadService.postArchivosEntidad(dataFiles, log).subscribe(data => {
        this.dataFile = null;
        var title = this.translate.instant("SubidaFicheroSuccessTitle");
        var body = this.translate.instant("SubidaFicheroSuccessBody");
        this.toastr.success(body, title);
        this.entidadService.getArchivosEntidad();
        this.close.emit(true);
      }, err => {
        var title = this.translate.instant("SubidaFicheroErrorTitle");
        var body = this.translate.instant("SubidaFicheroErrorBody");
        this.toastr.error(body, title);
      });
    }else{
      var title = this.translate.instant("SubidaFicheroErrorTitle");
      var body = this.translate.instant("SubidaFicheroErrorBody");
      this.toastr.error(body, title);
    }
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 2
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  obtenerConfirmacion(confirmacion: boolean) {
    if(confirmacion == true)
      this.onDelete();
    
    this.modalRef.hide();
  }

  onDelete(){
    this.dataFile = null;
  }

}
