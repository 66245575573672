<app-header></app-header>
<div class="container-fluid">
  <div class="container">
    <div class="block">
      <h1>{{getTitulo()|translate}}</h1>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row block">
          <div class="col-sm-6">
            <input type="text" class="form-control" placeholder="{{'SearchPlaceholder' | translate }}"
              [(ngModel)]="term">
          </div>
          <div class="col-sm-6">
            <button class="btn" type="submit" (click)="crearCotizacion()" style="padding-bottom: 0px;">
              <div class="row" style="margin-bottom: 2%;">
                <div class="col-2"><mat-icon style="align-items: center; display: flex;">control_point</mat-icon></div>
                <div class="col-10"><p style="margin-bottom: 6px;">{{"NuevaCotización" | translate}}</p></div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<mat-tab-group #areas (selectedTabChange)="tabChangedArea($event)" mat-align-tabs="center">
       <mat-tab label="{{'Todos' | translate}}">
        <mat-tab-group #todos (selectedTabChange)="tabChangedValidez($event)" mat-align-tabs="center">
          <mat-tab label="{{'ActivasLabel' | translate}}">
            <app-listado-cotizaciones [isVisible]="isActivasTodos()" [listadoCotizaciones]="listadoCotizacionesActivas" [activa]="true" [idArea]="0" [term]="term"></app-listado-cotizaciones>
          </mat-tab>
          <mat-tab label="{{'CaducadasLabel' | translate}}">
            <app-listado-cotizaciones [isVisible]="isCaducadasTodos()" [listadoCotizaciones]="listadoCotizacionesCaducadas" [activa]="false" [idArea]="0" [term]="term"></app-listado-cotizaciones>
          </mat-tab>
        </mat-tab-group>
      </mat-tab> 
      <mat-tab *ngIf="areaSelected == 1" label="{{'Aereo' | translate}}">-->
        <mat-tab-group #aereo *ngIf="areaSelected == 1" (selectedTabChange)="tabChangedValidez($event)" mat-align-tabs="center">
          <mat-tab label="{{'ActivasLabel' | translate}}">
            <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesActivasAereo" [activa]="true" [idArea]="3" [term]="term"></app-listado-cotizaciones>
          </mat-tab>
          <mat-tab label="{{'CaducadasLabel' | translate}}">
            <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesCaducadasAereo" [activa]="false" [idArea]="3" [term]="term"></app-listado-cotizaciones>
          </mat-tab>
        </mat-tab-group>
      <!-- </mat-tab> -->
      <!-- <mat-tab *ngIf="areaSelected == 2" label="{{'Maritimo' | translate}}"> -->
        <mat-tab-group #maritimo *ngIf="areaSelected == 2" (selectedTabChange)="tabChangedModo($event)" mat-align-tabs="center">
          <mat-tab label="{{'FCL' | translate}}">
            <mat-tab-group #fcl (selectedTabChange)="tabChangedValidez($event)" mat-align-tabs="center">
              <mat-tab label="{{'ActivasLabel' | translate}}">
                <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesActivasMaritimoFCL" [activa]="true" [idArea]="2" [term]="term"></app-listado-cotizaciones>
              </mat-tab>
              <mat-tab label="{{'CaducadasLabel' | translate}}">
                <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesCaducadasMaritimoFCL" [activa]="false" [idArea]="2" [term]="term"></app-listado-cotizaciones>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
          <mat-tab label="{{'LCL' | translate}}">
            <mat-tab-group #lcl (selectedTabChange)="tabChangedValidez($event)" mat-align-tabs="center">
              <mat-tab label="{{'ActivasLabel' | translate}}">
                <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesActivasMaritimoLCL" [activa]="true" [idArea]="2" [term]="term"></app-listado-cotizaciones>
              </mat-tab>
              <mat-tab label="{{'CaducadasLabel' | translate}}">
                <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesCaducadasMaritimoLCL" [activa]="false" [idArea]="2" [term]="term"></app-listado-cotizaciones>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
        </mat-tab-group>
      <!-- </mat-tab> -->
      <!-- <mat-tab *ngIf="areaSelected == 3" label="{{'Terrestre' | translate}}"> -->
        <mat-tab-group #terrestre *ngIf="areaSelected == 3" (selectedTabChange)="tabChangedValidez($event)" mat-align-tabs="center">
          <mat-tab label="{{'ActivasLabel' | translate}}">
            <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesActivasTerrestre" [activa]="true" [idArea]="1" [term]="term"></app-listado-cotizaciones>
          </mat-tab>
          <mat-tab label="{{'CaducadasLabel' | translate}}">
            <app-listado-cotizaciones (recargarList)="inicializar()" [listadoCotizaciones]="listadoCotizacionesCaducadasTerrestre" [activa]="false" [idArea]="1" [term]="term"></app-listado-cotizaciones>
          </mat-tab>
        </mat-tab-group>
      <!-- </mat-tab>  -->
    <!-- </mat-tab-group> -->
  </div>
</div>