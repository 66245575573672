import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Expedition } from 'src/app/models/expedition.model';
import { Observable } from 'rxjs';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Archivo } from 'src/app/models/archivo.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { IncidenciasService } from 'src/app/services/incidencias.service';
import { Incidencia } from 'src/app/models/incidencia.model';
import { DestinatarioRemitente } from 'src/app/models/destinatarioRemitente.model';
import { HojaRuta } from 'src/app/models/hojaRuta.model';
import { ExpedicionesPorFecha } from 'src/app/models/expediciones-por-fecha.model';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {
  
  @ViewChild(MatAccordion) accordion: MatAccordion;
  listExpedition: Expedition[] = [];
  expedicionesFacturar: [string[]] = [[]];
  documentos: Observable<Archivo[]>
  isMovil: boolean = false;
  expedicion: string = ""
  idExpedicion: number;
  estadoString: string = ""
  listExp
  total: number;
  pageActual: number = 1;
  sizePage: number = 4;
  incidencias: Incidencia[];
  estados: string[] = ["Pendiente", "Recogida", "Entregado"]
  destinatarioRemitenteHistorico: DestinatarioRemitente[] = [];
  historicos: string[] = [];
  historico: Expedition[] = []
  histo: ExpedicionesPorFecha[] = [];
  panelOpenState: boolean[] = [];
  modalRef: BsModalRef | null;
  indice: number;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  proveedor : boolean;


  constructor(private expService: ExpeditionApiService, private modalService: BsModalService,
    private router: Router, public dialog: MatDialog,
    private toastr: ToastrService, private deviceService: DeviceDetectorService,
    private incidenciasService: IncidenciasService, private translateService: TranslateService) {
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if(this.langs.includes(lang))
    {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else
    {
     this.translateService.setDefaultLang('es');
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  // Saber siguiente estado
  siguienteEstado(estado) {
    var nextEstado = ""
    if (estado === this.estados[1]) {
      nextEstado = this.estados[2]
    } else if (estado === this.estados[0] || !estado) {
      nextEstado = this.estados[1]
    }
    return nextEstado
  }

 // Cuando pinchas en el botón detalles llamamos al servidor para coger la expedición en especifico que quieras ver los detalles.
  detallesExpedition(){
    this.expService.getExpedicion(this.expedicion).subscribe(data => {
      this.listExp = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede obtener la información de la expedición.');
      var errorTitulo = this.translateService.instant('Error obtener información');
      this.toastr.error(errorBody, errorTitulo)
    })
  }

  // Cuando pinchas en el botón documentos llamamos al servidor para coger los documentos de la expedición en especifico.
  documentosExpedition(){
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => {});
    this.expService.getDocumentos(this.idExpedicion).subscribe(data => {
      this.documentos = data;
      dialogRef.close();
    }, err => {
      var errorBody = this.translateService.instant('No se puede cargar los documentos.');
      var errorTitulo = this.translateService.instant('Error carga documentos');
      this.toastr.error(errorBody, errorTitulo)
      dialogRef.close();
    })
  }

  setAtributtes(expedicion: string, estado: string, idExpedicion: number, listExp){
    this.expedicion = expedicion
    this.idExpedicion = idExpedicion;
    this.estadoString = estado
    this.listExp = listExp
  }

  // Para indicar si ha sido correctamente o ha fallado de realizar las cosas.
  openToast(event){
    if(event[0]){
      this.toastr.success(event[1])
    }else{
      this.toastr.error(event[1])
    }
  }

 ngOnInit() {
    this.inicializar();
 }

  close(){
    window.document.getElementById("Incidencias").click();
  }

  incidenciasExpedition(){
    let idExpedicion: number = Number(this.expedicion);
    this.incidenciasService.obtenerListadoIncidencias(idExpedicion).subscribe(data => {
      this.incidencias = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede cargar las incidencias.');
      var errorTitulo = this.translateService.instant('Error carga incidencias');
      this.toastr.error(errorBody, errorTitulo)
    })
  }

  getHistorico(){
    this.historicos = [];
    this.destinatarioRemitenteHistorico = [];
    this.historico.forEach(exp => {
      if(this.historicos.length > 0){
        var destinatario = this.historico.find(e => e.Destinatario == exp.Destinatario);
        if(destinatario == undefined){ // No existe ese remitente
          this.historicos.push(exp.Destinatario)
          var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Destinatario;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitenteHistorico.push(dr);
        }else{ // Ya existe el remitente
          var dr = this.destinatarioRemitenteHistorico.find(e => e.DestinatarioRemitente == exp.Destinatario);
          dr.Expediciones.push(exp);
          //this.destinatarioRemitentePendientesRecogida.push(dr);
        }
      }else{ // Es el primero que entra
        var dr: DestinatarioRemitente = new DestinatarioRemitente();
          dr.DestinatarioRemitente = exp.Destinatario;
          dr.Expediciones = [];
          dr.Expediciones.push(exp);
          this.destinatarioRemitenteHistorico.push(dr);
        this.historicos.push(exp.Destinatario)
      }
    })
    this.total[1] = this.destinatarioRemitenteHistorico.length;
  }

  getExpeditionPorMes() {
    this.destinatarioRemitenteHistorico = [];
    this.listExpedition.forEach(exp => {
      let fecha: string[] = exp.FechaLlegada.split('/');
      let date;
      if(fecha[1].length == 1)
        date = '0' + fecha[1] + '/' + fecha[2];
      else
        date = fecha[1] + '/' + fecha[2];
      
      if(this.histo.length > 0)
      {
        var d = this.histo.find(e =>e.Fecha == date)
        if(d == undefined)
        {
          var expHisto: ExpedicionesPorFecha = new ExpedicionesPorFecha();
          expHisto.Fecha = date;
          expHisto.Expediciones = [];
          expHisto.Expediciones.push(exp)
          this.histo.push(expHisto);
          this.expedicionesFacturar.push([]); 
          this.panelOpenState.push(false);
        }
        else
        {
          var eh = this.histo.find(e => e.Fecha == date);
          eh.Expediciones.push(exp);
        }
      }
      else 
      {
        var expHisto: ExpedicionesPorFecha = new ExpedicionesPorFecha();
        expHisto.Fecha = date;
        expHisto.Expediciones = [];
        expHisto.Expediciones.push(exp)
        this.histo.push(expHisto);
        this.expedicionesFacturar.push([]); 
        this.panelOpenState.push(false);
      }
    })
    this.total = this.histo.length

  }

  inicializar(){
    this.expService.getExpedicionesHistorico().subscribe(data => {
      this.listExpedition = data as Expedition[];
      this.expService.getProveedor().subscribe(data =>{
        this.proveedor = data;
        this.getExpeditionPorMes();
      })
      
      
    }, err => {
      var errorBody = this.translateService.instant('Fallo al cargar las expediciones, contacte con el dep. terrestre.');
      var errorTitulo = this.translateService.instant('Error');
      this.toastr.error(errorBody, errorTitulo)
    });
  }

  adjuntarExpediciones(event){
    var i: number = event[1];
    var array: string[] = event[0];
    this.expedicionesFacturar[i] = array;
  }

  limpiar(event){
    this.expedicionesFacturar.forEach(exF => exF.splice(0, exF.length))
    this.panelOpenState.forEach(pos => pos = false)
    this.histo.forEach(h => h.Expediciones.forEach(e => e.Checked = false));
  }

  cerrar(event){
    this.modalService.hide();
  }

  setIdExpedicion(event){
    this.idExpedicion = event;
  }

  setIndice(i: number){
    this.indice = i;
  }

}
