import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HojaRuta } from 'src/app/models/hojaRuta.model';

@Component({
  selector: 'app-table-hojas-ruta',
  templateUrl: './table-hojas-ruta.component.html',
  styleUrls: ['./table-hojas-ruta.component.scss'],
  animations: [
    trigger('expandableRow', [
      state('collapsed, void', style({
        height: '0px',
        visibility: 'hidden'
      })),
      state('expanded', style({
        'min-height': '48px',
        height: '*',
        visibility: 'visible'
      })),
      transition(
        'expanded <=> collapsed, void <=> *',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ]
})
export class TableHojasRutaComponent implements OnInit {

  @Input() hojasRuta: MatTableDataSource<HojaRuta> = new MatTableDataSource<HojaRuta>();
  panelOpenState: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  openToast(event){
    if(event[0]){
      this.panelOpenState = false;
    }else{
      this.panelOpenState = false;
    }
  }

}
