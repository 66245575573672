import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Conductor } from 'src/app/models/conductor.model';
import { Expedition } from 'src/app/models/expedition.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Vehiculo } from 'src/app/models/vehiculo.model';
import { ConductorService } from 'src/app/services/conductor.service';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { VehiculoService } from 'src/app/services/vehiculo.service';
import { arrayValidator } from 'src/app/validators/array-validator.validator';

@Component({
  selector: 'app-multiple-expedicion-matricula',
  templateUrl: './multiple-expedicion-matricula.component.html',
  styleUrls: ['./multiple-expedicion-matricula.component.scss']
})
export class MultipleExpedicionMatriculaComponent implements OnInit {

  clase: string = "app-multiple-expedicion-matricula";
  public formAsignacionMultiple: FormGroup;
  dataSource;
  public submitted = false;
  vehiculos: Vehiculo[] = [];
  conductores: Conductor[] = [];
  @Output() close = new EventEmitter<number>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public get vehiculoSelected() { return this.formAsignacionMultiple.get('vehiculoSelected') }
  public get expedicionesSelected() { return this.formAsignacionMultiple.get('expedicionesSelected') }
  public get conductorSelected() { return this.formAsignacionMultiple.get('conductorSelected') }

  constructor(private proveedorService: ProveedorService, private vehiculoService: VehiculoService, private conductorService: ConductorService,
    private formBuilder: FormBuilder, private toastr: ToastrService, private translate: TranslateService, private expedicionService: ExpeditionApiService) { }

  ngOnInit(): void {
    this.formAsignacionMultiple = this.formBuilder.group({
      vehiculoSelected: [null, Validators.required],
      conductorSelected: [null, Validators.required],
      expedicionesSelected: [[], arrayValidator()]
    });
    this.proveedorService.getExpedicionesProveedor();
    this.proveedorService.getExpediciones().subscribe(data => {
      var expediciones = [];
      data.forEach(c => {
        expediciones = expediciones.concat(c.ExpedicionesPendientes);
      });
      this.dataSource = new MatTableDataSource<Expedition>(expediciones);
      this.dataSource.paginator = this.paginator;
    });
    this.vehiculoService.getVehiculos$().subscribe(data => {
      this.vehiculos = data;
    });
    this.conductorService.getConductores$().subscribe(data => {
      this.conductores = data;
    });
    this.vehiculoService.getAllVehiculos();
    this.conductorService.getAllConductores();
  }

  getColumnas(){
    return ['referencia', 'expedicion', 'fecha'];
  }

  cerrar(){
    this.close.emit(1);
  }

  columSelected(expedicion: Expedition){
    if(this.expedicionesSelected.value == null)
      this.expedicionesSelected.setValue([]);
    if(this.expedicionesSelected.value.includes(expedicion.Numero)){
      var i = this.expedicionesSelected.value.indexOf(expedicion.Numero);
      this.expedicionesSelected.value.splice(i, 1);
    }else
      this.expedicionesSelected.value.push(expedicion.Numero);
    
    this.expedicionesSelected.updateValueAndValidity();
  }

  clear(){
    this.submitted = false;
    this.formAsignacionMultiple.reset();
    this.vehiculoSelected.setValue(0);
  }

  isFondoNegro(element: Expedition){
    return this.expedicionesSelected.value != null && this.expedicionesSelected.value.includes(element.Numero);
  }

  enviarSeleccion(click: string){
    this.submitted = true;
    if(this.formAsignacionMultiple.valid/* && this.vehiculoSelected.valid && this.conductorSelected.valid*/){
      var log: LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      this.expedicionService.putExpedicionVehiculoYConductorConjunto(this.expedicionesSelected.value, this.conductorSelected.value, this.vehiculoSelected.value, log).subscribe(data => {
        this.proveedorService.getExpedicionesProveedor();
        this.clear();
        this.cerrar();
        var body: string = this.translate.instant("Las expediciones se han actualizado correctamente.");
        var title: string = this.translate.instant("Expediciones actualizadas");
        this.toastr.success(body, title);
      });
    }else{
      var body: string = this.translate.instant("Faltan campos por rellenar.");
      var title: string = this.translate.instant("Campos incompletos");
      this.toastr.error(body, title);
    }
  }

}
