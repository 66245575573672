import { Component, ElementRef, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Mercancia } from 'src/app/models/mercancia.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { FormControl, NgForm } from '@angular/forms';
import { Archivo } from 'src/app/models/archivo.model';
import { ToastrService } from 'ngx-toastr';
import { Incoterms } from 'src/app/models/incoterms.model';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';
import { Direccion } from 'src/app/models/direccion.model';
import { Paises } from 'src/app/models/paises.model';
import { Localidades } from 'src/app/models/localidades.model';
import { Shipment } from 'src/app/models/shipment.model';
import { TipoEnvio } from 'src/app/models/tipo-envio.model';
import { Router } from '@angular/router';
import { TipoDivisa } from 'src/app/models/tipo-divisa.model';
import { Modoenvio } from 'src/app/models/modoenvio.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from 'src/app/models/entity.model';
import { LogModel } from 'src/app/models/log-model.model';
import { DatosContacto } from 'src/app/models/datos-contacto.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { EntityService } from 'src/app/services/entity.service';
import { ConfigEntidad } from 'src/app/models/config-entidad.model';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ExcelServiceService } from 'src/app/services/excel-service.service';
import { DatePipe, formatDate } from '@angular/common';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { Codigosun } from 'src/app/models/codigosun.model';
import { MercanciaService } from 'src/app/services/mercancia.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { CargoComponent } from '../cargo/cargo.component';
import { TarifaService } from 'src/app/services/tarifaservice.service';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';

@Component({
  selector: 'app-terrestre-shipment',
  templateUrl: './terrestre-shipment.component.html',
  styleUrls: ['./terrestre-shipment.component.scss'],
  providers: [DatePipe]
})
export class TerrestreShipmentComponent implements OnInit {

  @ViewChild('TipoEnvio') tipoEnvio: ElementRef;
  @ViewChild('Referencia') referencia: ElementRef;
  @ViewChild('ListShipper') listShipperElement: ElementRef;
  @ViewChild('ListConsignee') listConsigneeElement: ElementRef;
  @ViewChild('Incoterm') incoterm: ElementRef;
  @ViewChild('ValorMercancia') valorMercancia: ElementRef;
  @ViewChild('EntidadRecogida') entidadRecogida: ElementRef;
  @ViewChild('FechaRecogida') fechaRecogida: ElementRef;
  @ViewChild('HoraRecogidaInicio1') horaRecogidaInicio1: ElementRef;
  @ViewChild('HoraRecogidaFinal1') horaRecogidaFinal1: ElementRef;
  @ViewChild('PersonaContactoRecogida') personaContactoRecogida: ElementRef;
  @ViewChild('TelefonoContactoRecogida') telefonoContactoRecogida: ElementRef;
  @ViewChild('EmailRecogida') emailRecogida: ElementRef;
  @ViewChild('EntidadEntrega') entidadEntrega: ElementRef;
  @ViewChild(CargoComponent) appCargo: CargoComponent;

  clase: string = "app-terrestre-shipment";
  texto: string = "";
  shipmentTerrestre: Shipment;
  visor: boolean = false;
  isCotizacion: boolean = false;
  editar: boolean;
  duplicar: boolean;
  form: NgForm;
  error: boolean = false;
  errorPesoBruto: boolean = false;
  errorPesoNeto: boolean = false;
  tipoMercanciaNuevo: string = "";
  today: Date = new Date();
  codigosUn: Codigosun[] = [];
  archivoBooking: Archivo = new Archivo();
  errorFechaRecogidaMenorActual: Boolean = false;

  formModelTerrestre = new FormControl({
    Id: null,
    Envio: 'TERRESTRE',
    ModoEnvio: 3,
    TipoEnvio: 7,
    Cliente: '',
    IdArea: 1,
    Referencia: '',
    Shipper: '',
    Consignee: '',
    Notify: '',
    Incoterm: '',
    ValorMercancia: '',
    TipoDivisa: '',
    SeguroMercancia: '',
    Asegurado: false,
    Refrigerada: '',
    Perecedero: '',
    //NoRemontable: false,
    Fumigado: '',
    EntidadRecogida: null, //Recogida
    ReferenciaRecogida: '',
    DireccionRecogida: null,
    FechaRecogida: '',
    InstruccionesRecogida: '',
    HoraRecogidaInicio1: '',
    HoraRecogidaFinal1: '',
    HoraRecogidaInicio2: '',
    HoraRecogidaFinal2: '',
    PersonaContactoRecogida: '',
    TelefonoRecogida: '',
    EmailRecogida: '',
    DespachoOrigen: '', //Despacho Aduanas
    DespachoDestino: '',
    EntidadEntrega: null, //Entrega
    FechaEntrega: null,
    ReferenciaEntrega: '',
    DireccionEntrega: null,
    InstruccionesEntrega: '',
    PersonaContactoEntrega: '',
    HoraEntregaInicio1: '',
    HoraEntregaFinal1: '',
    HoraEntregaInicio2: '',
    HoraEntregaFinal2: '',
    TelefonoEntrega: '',
    EmailEntrega: '',
    MercanciaPeligrosa: false,
    Mercancia: [],
    PesoNetoTotalDGR: null,
    PesoBrutoTotalDGR: null,
    BultosTotales: null,
    PesoNetoTotal: null,
    PesoBrutoTotal: null,
    VolumenTotal: null,
    PesoTasable: null,
    Marcas: '',
    Instrucciones: '',
    Archivo: [],
    Cotizacion: 0,
  })

  bultos: number = 0;
  pesoNeto: number = 0;
  pesoBruto: number = 0;
  volumen: number = 0;
  direccionShipperRecogida: string = "";
  direccionConsigneeEntrega: string = "";
  listTipoEnvio: TipoEnvio[] = [];
  listShipper: Entity[] = [];
  listConsignee: Entity[] = [];
  listNotify: Entity[] = [];
  readonlyDireccionShipper: boolean = false;
  readonlyDireccionConsignee: boolean = false;
  readonlyDireccionNotify: boolean = false;
  cotizacion: string;
  /*
  ShipperInformation: String = '';
  ConsigneeInformation: String = '';
  NotifyInformation: String = '';
  */
  resumenDireccionRecogida: String = '';
  resumenDireccionEntrega: String = '';
  tipoDireccion: string = '';

  listModoEnvio: Modoenvio[];
  listIncoterm: Incoterms[];
  listDestino: String[] = [];
  listDireccionRecogida: Direccion[] = [];
  listaMercancia: Mercancia[] = [];
  listaArchivos: Archivo[] = []; //pasarlo en post
  listaMercanciasPeligrosas: MercanciaPeligrosa[] = [];
  cargo: Mercancia = new Mercancia(); //pasarlo en post
  auxVol: number;
  errorBrutoTotal = false
  paises: Paises[] = [];
  localidades: Localidades[] = [];
  listaAuxiliarDireccion: Direccion[] = [];
  idEnvio: number;
  envio: Shipment; //pasarlo en post
  aerolinea: String;
  direccion: Direccion;
  envioPrueba: any;
  lengthMercancia: number;
  listDivisas: TipoDivisa[];
  direccionEntrega: Direccion;
  direccionRecogida: Direccion;
  EntidadRecogidaInformation: String;
  mostrarEntrega: Boolean;
  modalRef: BsModalRef | null;
  referenciaClienteAnterior: string;
  searchText: string;
  tipoEntidad: string;
  esFTL: Boolean = false;
  errorTipoEnvio: Boolean = false;
  errorReferencia: Boolean = false;
  errorListShipper: Boolean = false;
  errorListConsignee: Boolean = false;
  errorIncoterm: Boolean = false;
  errorBultosTotales: Boolean = false;
  errorPersonaContactoRecogida: Boolean = false;
  errorEmailRecogida: Boolean = false;
  errorPesoNetoTotal: Boolean = false;
  errorPesoBrutoTotal: Boolean = false;
  errorPesoBrutoTotalDGR: Boolean = false;
  errorPesoNetoTotalDGR: Boolean = false;
  //errorNoRemontable: Boolean = false;
  //errorDiferenciaPesosDGR: Boolean = false;
  muestraErrorBrutoDGR: Boolean = false;
  muestraErrorNetoDGR: Boolean = false;
  errorVolumenTotal: Boolean = false;
  errorTelefonoRecogida: Boolean = false;
  errorFechaRecogida: Boolean = false;
  errorHoraRecogidaInicio1: Boolean = false;
  errorHoraRecogidaFinal1: Boolean = false;
  errorMercancia: Boolean = false;
  errorPersonaContactoEntrega: Boolean = false;
  errorEmailEntrega: Boolean = false;
  errorTelefonoEntrega: Boolean = false;
  errorEntidadRecogida: Boolean = false;
  errorEntidadEntrega: Boolean = false;
  errorValorMercancia: Boolean = false;
  errorReferenciaEntrega: Boolean = false;
  errorReferenciaRecogida: Boolean = false;
  errorModoEnvio: Boolean = false;
  confirmSeguro: Boolean = false;
  cargaTipoEnvio: boolean = true;
  cargaRemitente: boolean = true;
  cargaDestinatario: boolean = true;
  cargaNotify: boolean = false;
  cargaIncoterm: boolean = true;
  cargaTipoDivisa: boolean = true;
  cargaAeropuerto: boolean = true;
  errorFechaEntrega: Boolean = false;
  cargaEntidadRecogida: boolean = true;
  errorFechaEntregaMenorActual: Boolean = false;
  errorFechaEntregaRecogidaMenor: Boolean = false;
  cargaEntidadEntrega: boolean = true;
  cargaModoEnvio: boolean = true;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  entidad: Entity;
  datosContacto: DatosContacto;
  configEntidad: ConfigEntidad;
  entidadCliente: number;
  refCliDuplicado: Boolean = false;
  chShipper: Boolean = false;
  chConsignee: Boolean = false;
  corresponsal: Corresponsal;
  maxlengthInstrucctions: number = 255;
  maxlengthObservaciones: number = 500;
  maxlengthMarcas: number = 255;
  partida: string;
  expedicion: string;
  tarifa: TarifaEnvio;


  constructor(private envioService: ShipmentService, private toastr: ToastrService, private router: Router, private datePipe: DatePipe, private cotizacionService: CotizacionService,
    private modalService: BsModalService, private translateService: TranslateService, private usuarioService: UsuarioService, private mercanciaService: MercanciaService,
    private entidadService: EntityService, public dialog: MatDialog, private configurationService: ConfigurationService, private tarifaService: TarifaService) {
    this.confirmSeguro = false;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
  }

  ngOnInit(): void {
    this.inicializar();
  }

  inicializar() {
    this.formModelTerrestre = new FormControl({
      Id: null,
      Envio: 'TERRESTRE',
      ModoEnvio: 3,
      TipoEnvio: 7,
      Cliente: '',
      IdArea: 1,
      Referencia: '',
      Shipper: '',
      Consignee: '',
      Notify: '',
      Incoterm: '',
      ValorMercancia: '',
      TipoDivisa: '',
      SeguroMercancia: '',
      Asegurado: false,
      Refrigerada: '',
      Perecedero: '',
      //NoRemontable: false,
      Fumigado: '',
      EntidadRecogida: null, //Recogida
      ReferenciaRecogida: '',
      DireccionRecogida: null,
      FechaRecogida: '',
      InstruccionesRecogida: '',
      HoraRecogidaInicio1: '',
      HoraRecogidaFinal1: '',
      HoraRecogidaInicio2: '',
      HoraRecogidaFinal2: '',
      PersonaContactoRecogida: '',
      TelefonoRecogida: '',
      EmailRecogida: '',
      DespachoOrigen: '', //Despacho Aduanas
      DespachoDestino: '',
      EntidadEntrega: null, //Entrega
      FechaEntrega: null,
      ReferenciaEntrega: '',
      DireccionEntrega: null,
      InstruccionesEntrega: '',
      PersonaContactoEntrega: '',
      HoraEntregaInicio1: '',
      HoraEntregaFinal1: '',
      HoraEntregaInicio2: '',
      HoraEntregaFinal2: '',
      TelefonoEntrega: '',
      EmailEntrega: '',
      MercanciaPeligrosa: false,
      Mercancia: [],
      PesoNetoTotalDGR: null,
      PesoBrutoTotalDGR: null,
      BultosTotales: null,
      PesoNetoTotal: null,
      PesoBrutoTotal: null,
      VolumenTotal: null,
      PesoTasable: null,
      Marcas: '',
      Instrucciones: '',
      Archivo: [],
      RefCotizacion: null,
      Cotizacion: 0,
      Tarifa: null

    });

    this.confirmSeguro = false;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.entidad = null;
    document.getElementById("Referencia").focus();
    this.tipoEntidad = '';
    this.referenciaClienteAnterior = '';
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.listaMercancia = [];
    this.cargarIdioma();
    this.cargarTipoDivisa();
    this.getEntidad();
    this.getShipper();
    this.cargarListaConsignee();
    this.cargarModoEnvio();
    this.cargarPaises();
    this.cargarTipoEnvio();
    this.cargarIncoterms();
    this.cargarDatosContactoUsuario();
    this.cargarConfigEntidad();
    this.cargarHorario();
    this.getCorresponsal();
    this.obtenerCodigosUN();
    this.cargarEnvio();
    this.getTarifa();
    if (this.appCargo != undefined && this.appCargo != null)
      this.appCargo.ngOnInit();
  }

  compruebaFtl() {
    if (this.formModelTerrestre.value.ModoEnvio == 1) {
      this.esFTL = true;
    }
    else {
      this.esFTL = false;
    }
  }

  getTarifa() {
    this.tarifa = this.tarifaService.tarifa
    if (this.tarifa != null && this.tarifa != undefined) {
      this.formModelTerrestre.value.Tarifa = this.tarifaService.tarifaFiltrada;
      // this.setTarifa();
    }
    else
      this.formModelTerrestre.value.Tarifa = null;
  }

  obtenerCodigosUN() {
    this.mercanciaService.getCodigosUN().subscribe(data => this.codigosUn = data);
  }

  getTituloRecogida() {
    if (this.corresponsal != undefined && this.corresponsal != null && (this.corresponsal.IdDelegacion == 2 || this.corresponsal.IdDelegacion == 5))
      return this.translateService.instant("RecogidaMexico");
    return this.translateService.instant("Recogida");
  }

  cargarHorario() {
    this.configurationService.getHorarios$().subscribe(horarios => {
      this.formModelTerrestre.value.HoraRecogidaInicio1 = horarios.hr1;
      this.formModelTerrestre.value.HoraRecogidaInicio2 = horarios.hr2;
      this.formModelTerrestre.value.HoraRecogidaFinal1 = horarios.hr3;
      this.formModelTerrestre.value.HoraRecogidaFinal2 = horarios.hr4;
      this.formModelTerrestre.value.HoraEntregaInicio1 = horarios.he1;
      this.formModelTerrestre.value.HoraEntregaInicio2 = horarios.he2;
      this.formModelTerrestre.value.HoraEntregaFinal1 = horarios.he3;
      this.formModelTerrestre.value.HoraEntregaFinal2 = horarios.he4;
    });
    this.configurationService.getHorarios();
  }

  getCorresponsal() {
    this.entidadService.getCorresponsal().subscribe(data => {
      this.corresponsal = data;
    });
  }

  cargarConfigEntidad() {
    this.entidadService.getConfigEntidad().subscribe(data => {
      this.configEntidad = data;
      if (this.listIncoterm.find(i => i.IdIncoterm == this.configEntidad.IncotermTerrestre))
        this.formModelTerrestre.value.Incoterm = this.configEntidad.IncotermTerrestre;

      //this.formModelTerrestre.value.MercanciaPeligrosa = this.configEntidad.MercanciaPeligrosa;
    },
      error => {

      })
  }

  getEntidad() {
    this.envioService.getEntidadUsuario().subscribe(entidad => {
      this.entidadCliente = entidad;
    })
  }

  cargarDatosContactoUsuario() {
    this.usuarioService.getDatosContacto().subscribe(data => {
      this.datosContacto = data;
      this.formModelTerrestre.value.PersonaContactoRecogida = this.datosContacto.NombreUsuario;
      this.formModelTerrestre.value.TelefonoRecogida = this.datosContacto.Telefono;
      this.formModelTerrestre.value.EmailRecogida = this.datosContacto.Email;
    }, error => {
      //No tiene todos los datos de contacto
    })
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if (this.langs.find(e => e == lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarEnvio() {
    this.partida = this.envioService.getPartida();
    this.expedicion = this.envioService.getExpedicion();
    this.cotizacion = this.envioService.getCotizacion();
    if (this.partida != null && this.partida != undefined && this.partida != "") {
      this.envioService.getShipmentByPartidaExpedicion(this.partida).subscribe(data => {
        this.envio = data;
        this.asignarValores(this.envio);
        this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    } else if (this.expedicion != null && this.expedicion != undefined && this.expedicion != "") {
      this.envioService.getShipmentByPartidaExpedicion(this.expedicion).subscribe(data => {
        this.envio = data;
        this.asignarValores(this.envio);
        this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    } else if (this.cotizacion != null && this.cotizacion != undefined && this.cotizacion != "") {
      this.cotizacionService.getBooking(this.cotizacion, null).subscribe(data => {
        this.envio = data;
        this.envio.IdCotizacion = this.cotizacion;
        this.envioService.setCotizacionBoolean(true);
        this.asignarValores(this.envio);
        //this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    }
    // this.idEnvio = this.envioService.getIdEnvio();
    // if(this.idEnvio != -1)
    // {
    //   this.envioService.getEnvio(this.idEnvio).subscribe(data => {
    //     this.envio = data;
    //     this.listaMercancia = this.envio.Mercancia;
    //     this.listaArchivos = [];
    //     this.asignarValores(this.envio)
    //   });
    // }
  }

  cargarTipoDivisa() {
    this.envioService.getTiposDivisa().subscribe(data => {
      this.cargaTipoDivisa = true;
      this.listDivisas = data;
      this.cargaTipoDivisa = false;
      this.formModelTerrestre.value.TipoDivisa = this.listDivisas[0].IdTipoDivisa;
    })
  }

  cargarListaConsignee() {
    // this.envioService.getConsigneeTerrestre().subscribe(data => {
    //   this.cargaDestinatario = true;
    //   this.cargaEntidadEntrega = true;
    //   this.listConsignee = data;
    //   this.cargaDestinatario = false;
    //   this.cargaEntidadEntrega = false;

    //   if(this.idEnvio != 1) {
    //     this.visor = this.envioService.getVisor();
    //     if(this.envio != undefined)
    //     {
    //       this.asignarValores(this.envio);
    //     }
    //   }
    // });
  }

  cargarListaNotify() {
    if (this.formModelTerrestre.value.Consignee != undefined && this.formModelTerrestre.value.Consignee != null)
      this.envioService.getNotify(this.formModelTerrestre.value.Consignee.IdEntidad).subscribe(data => {
        this.cargaNotify = true;
        this.listNotify = data;
        this.cargaNotify = false;
      });
    else
      this.errorListConsignee = true;
  }

  cargarModoEnvio() {
    this.envioService.getModoEnvioByArea(1).subscribe(data => {
      this.cargaModoEnvio = true;
      this.listModoEnvio = data;
      this.cargaModoEnvio = false;
      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio)
        }
      }
    });
  }

  cargarPaises() {
    this.envioService.getPaises().subscribe(data => {
      this.paises = data;
      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }
    });
  }

  cargarLocalidades() {
    this.envioService.getLocalidades().subscribe(data => {
      this.localidades = data;
    });
  }

  cargarTipoEnvio() {
    this.envioService.getTiposEnvio(1).subscribe(data => {
      this.cargaTipoEnvio = true;
      this.listTipoEnvio = data;
      this.cargaTipoEnvio = false;
    });
  }

  cargarIncoterms() {
    this.envioService.getIncoterms(this.formModelTerrestre.value.IdArea).subscribe(data => {
      this.cargaIncoterm = true;
      this.listIncoterm = data;
      this.cargaIncoterm = false;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    })
  }

  getShipper() {
    this.envioService.getEntidadesAddressBook().subscribe(data => {
      this.entidadService.getEntidadById(this.entidadCliente).subscribe(entidadCliente => {
        this.listShipper = data;
        this.listConsignee = data;
        this.listShipper.push(entidadCliente);
        // this.listShipper.forEach(shipper => {
        this.formModelTerrestre.value.Shipper = entidadCliente;
        this.formModelTerrestre.value.EntidadRecogida = entidadCliente;
        // this.formModelTerrestre.value.DireccionRecogida = entidadCliente.IdDireccion;
        this.formModelTerrestre.value.Cliente = entidadCliente;
        console.log(this.formModelTerrestre.value.EntidadRecogida);
        this.setShipperInformation();
        // });
        if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
          this.visor = this.envioService.getVisor();
          if (this.envio != undefined) {
            this.asignarValores(this.envio);
          }
        } else if (this.partida != null && this.partida != undefined) {
          this.asignarValores(this.envio);
        }
        this.cargaRemitente = this.cargaDestinatario = false;
        this.cargaEntidadRecogida = this.cargaEntidadEntrega = false;
        console.log(this.formModelTerrestre.value.EntidadRecogida);

        this.inicializarDatos();
      });
    });
    //Si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
  }

  //Funcion que comprueba que si se marca la casilla de peligrosa, tenga mercancia peligrosa
  compruebaMercanciaPeligrosa(): Boolean {
    let count = 0;
    if (this.shipmentTerrestre.MercanciaPeligrosa == 1) {
      this.shipmentTerrestre.Mercancia.forEach(mercancia => {
        if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length >= 0) {
          count++;
        }
      })
    } else {
      count = 1;
    }
    if (count == 0) {
      return false;
    }
    return true;
  }

  compruebaIncotermEntrega() {
    let correcto = true;
    if (this.incotermStartWith()) {
      if (this.formModelTerrestre.value.IdEntidadEntrega != null && this.formModelTerrestre.value.IdEntidadEntrega != undefined && this.formModelTerrestre.value.IdEntidadEntrega != ''
        && this.formModelTerrestre.value.IdDireccionEntrega != null && this.formModelTerrestre.value.IdDireccionEntrega != undefined && this.formModelTerrestre.value.IdDireccionEntrega != '') {
        correcto = false;
      }
    }
    return correcto;
  }

  compruebaErrores(form: NgForm) {
    this.compruebaTipoEnvio(form); this.compruebaReferencia(form); this.compruebaListShipper(form); this.compruebaListConsignee(form);
    this.compruebaIncoterm(form); this.compruebaBultosTotales(form);/*this.compruebaEmailEntrega(form);*/this.compruebaEmailRecogida(form);
    this.compruebaMercancia();/*this.compruebaPersonaContactoEntrega(form);*/this.compruebaPersonaContactoRecogida(form);
    /*this.compruebaTelefonoEntrega(form);*/this.compruebaTelefonoRecogida(form); this.compruebaPesoBrutoTotal(form); /*this.compruebaPesoBrutoTotalDGR(form);*/
    this.compruebaPesoNetoTotal(form); this.compruebaVolumenTotal(form); this.compruebaFechaRecogida(form); this.compruebaFechaEntrega(form); this.compruebaEntidadEntrega(form);
    this.compruebaEntidadRecogida(form); this.compruebaValorMercancia(form); this.compruebaReferenciaEntrega(form); this.compruebaReferenciaRecogida(form);
    this.compruebaPesoNetoTotalDGR(form);
  }

  envioTerrestreOk(form: NgForm) {
    return (!this.compruebaTipoEnvio(form) /*&& !this.compruebaNoRemontable(form)*/ && !this.compruebaModoEnvio(form) && !this.compruebaReferencia(form) && !this.compruebaListShipper(form) && !this.compruebaListConsignee(form)
      && !this.compruebaIncoterm(form) && !this.compruebaBultosTotales(form) && !this.compruebaEmailRecogida(form) && !this.compruebaMercancia()
      && !this.compruebaPersonaContactoRecogida(form) && !this.compruebaTelefonoRecogida(form) && !this.compruebaPesoBrutoTotal(form)
      && !this.compruebaEntidadRecogida(form) && !this.compruebaPesoNetoTotal(form) && !this.compruebaVolumenTotal(form) /*&& !this.compruebaPesoBrutoTotalDGR(form)*/
      && !this.compruebaFechaRecogida(form) && !this.compruebaFechaEntrega(form) && !this.compruebaValorMercancia(form) && !this.compruebaPesoNetoTotalDGR(form));
  }

  inicializarDatos() {
    this.formModelTerrestre.value.TipoEnvio = 5;
    this.formModelTerrestre.value.TipoDivisa = this.listDivisas[0].IdTipoDivisa;
    this.formModelTerrestre.value.Asegurado = false;
    //this.formModelTerrestre.value.NoRemontable = true;
  }

  vaciarForm(form: NgForm) {

    this.formModelTerrestre.value.DireccionShipper = '';
    this.EntidadRecogidaInformation = '';
    this.formModelTerrestre.value.BultosTotales = '';
    this.formModelTerrestre.value.PesoNetoTotal = '';
    this.formModelTerrestre.value.PesoBrutoTotal = '';
    this.formModelTerrestre.value.VolumenTotal = '';
    this.formModelTerrestre.value.PesoNetoTotalDGR = '';
    this.formModelTerrestre.value.PesoBrutoTotalDGR = '';
    this.formModelTerrestre.value.DireccionConsignee = '';
    this.shipmentTerrestre = new Shipment();
    this.formModelTerrestre.value.DireccionNotify = '';
    this.listaMercancia = [];
    this.formModelTerrestre.value.Mercancia = [];
    this.formModelTerrestre.value.EntidadEntrega = null;
    this.formModelTerrestre.value.EntidadRecogida = null;
    this.formModelTerrestre.value.ReferenciaEntrega = '';
    this.formModelTerrestre.value.MercanciaPeligrosa = true;
    this.formModelTerrestre = new FormControl({
      Id: null,
      Envio: 'TERRESTRE',
      ModoEnvio: 3,
      TipoEnvio: 7,
      Cliente: '',
      IdArea: 1,
      Referencia: '',
      Shipper: '',
      Consignee: '',
      Notify: '',
      Incoterm: '',
      ValorMercancia: '',
      TipoDivisa: '',
      SeguroMercancia: '',
      Asegurado: false,
      Refrigerada: '',
      Perecedero: '',
      //NoRemontable: null,
      Fumigado: '',
      EntidadRecogida: null, //Recogida
      ReferenciaRecogida: '',
      DireccionRecogida: null,
      FechaRecogida: '',
      InstruccionesRecogida: '',
      HoraRecogidaInicio1: '',
      HoraRecogidaFinal1: '',
      HoraRecogidaInicio2: '',
      HoraRecogidaFinal2: '',
      PersonaContactoRecogida: '',
      TelefonoRecogida: '',
      EmailRecogida: '',
      DespachoOrigen: '', //Despacho Aduanas
      DespachoDestino: '',
      EntidadEntrega: null, //Entrega
      FechaEntrega: null,
      ReferenciaEntrega: '',
      DireccionEntrega: null,
      InstruccionesEntrega: '',
      PersonaContactoEntrega: '',
      HoraEntregaInicio1: '',
      HoraEntregaFinal1: '',
      HoraEntregaInicio2: '',
      HoraEntregaFinal2: '',
      TelefonoEntrega: '',
      EmailEntrega: '',
      MercanciaPeligrosa: false,
      Mercancia: [],
      PesoNetoTotalDGR: null,
      PesoBrutoTotalDGR: null,
      BultosTotales: null,
      PesoNetoTotal: null,
      PesoBrutoTotal: null,
      VolumenTotal: null,
      PesoTasable: null,
      Marcas: '',
      Instrucciones: '',
      Archivo: [],
      RefCotizacion: null,
      Cotizacion: 0,
    });
    this.cargarListaConsignee();
    this.getShipper();
    this.cargarDatosContactoUsuario();
    this.cargarConfigEntidad();
  }

  peticionEnvio(form: NgForm) {
    if (!this.comprobarErrores()) {
      if (this.compruebaIncotermEntrega()) {
        this.convertFormToModel(form);
        if (this.compruebaMercanciaPeligrosa()) {
          if (this.editar) {
            this.shipmentTerrestre.IdEnvio = this.idEnvio;
            var log: LogModel = new LogModel();
            log.Click = "modificarShipment";
            log.Pantalla = this.clase;
            const dialogRef = this.dialog.open(WaitingComponent, {
              width: '250px',
              panelClass: 'my-panel',
              disableClose: true
            });
            dialogRef.afterOpened().subscribe(result => { });
            this.envioService.putEnvioTerrestre(this.shipmentTerrestre, this.idEnvio, log).subscribe(data => {
              dialogRef.close();
              var Body = this.translateService.instant('El envío se ha modificado correctamente');
              var Titulo = this.translateService.instant('Envío correcto');
              this.toastr.success(Body, Titulo);
              this.envio = null;
              this.envioService.setIdEnvio(-1);
              this.idEnvio = -1;
              form.resetForm();
              this.inicializar();
            },
              error => {
                dialogRef.close();
                var Body = this.translateService.instant('Ha habido un problema al actualizar el envío.');
                var Titulo = this.translateService.instant('Error en el envío');
                this.toastr.error(Body, Titulo)
              });
          }
          else {
            var log: LogModel = new LogModel();
            log.Click = "enviarShipment";
            log.Pantalla = this.clase;
            const dialogRef = this.dialog.open(WaitingComponent, {
              width: '250px',
              panelClass: 'my-panel',
              disableClose: true
            });
            dialogRef.afterOpened().subscribe(result => { });
            this.envioService.postEnvioTerrestre(this.shipmentTerrestre, log, this.translateService.getDefaultLang()).subscribe(data => {
              dialogRef.close();
              var Body = this.translateService.instant('El envío se ha realizado correctamente');
              var Titulo = this.translateService.instant('Envío correcto');
              this.toastr.success(Body, Titulo)
              this.envio = null;
              this.envioService.setIdEnvio(-1);
              this.idEnvio = -1;
              form.resetForm();
              this.inicializar();
            },
              error => {
                dialogRef.close();
                var Body = this.translateService.instant('Ha habido un problema al crear el envío.');
                var Titulo = this.translateService.instant('Error en el envío');
                this.toastr.error(Body, Titulo)
              });
          }
        }
        else {
          var Body = this.translateService.instant('Se ha marcado la casilla de mercancia peligrosa y no hay mercancia peligrosa.');
          var Titulo = this.translateService.instant('Error mercancia peligrosa');
          this.toastr.error(Body, Titulo)
        }
      }
      else {
        var Body = this.translateService.instant('Falta la entidad de entrega y/o la direccion');
        var Titulo = this.translateService.instant('Error recogida');
        this.toastr.error(Body, Titulo)
      }
    }
  }

  archivosAdjuntosMercanciaPeligrosa() {
    if (this.formModelTerrestre.value.MercanciaPeligrosa)
      return this.listaArchivos.findIndex(a => (a.TipoArchivo == "DGD" || a.TipoArchivo == "DGN" || a.TipoArchivo == "MSDS")) != -1;
    return true;
  }

  envioTerrestre(form: NgForm) {
    if (this.envioTerrestreOk(form)) {
      // QUITADO EL ARCHIVO DGD OBLIGATORIO (MANUEL Y PHILIP 08/09/2021)
      /*
      if(this.archivosAdjuntosMercanciaPeligrosa()){*/
      if (!this.refCliDuplicado) {
        if (this.incotermStartWith()) {
          // QUITAR LA COMPROBACIÓN DE PERSONA DE CONTACTO, TELEFONO DE CONTACTO Y EMAIL DE CONTACTO ENTREGA
          /*if(!this.compruebaPersonaContactoEntrega(form) && !this.compruebaTelefonoEntrega(form) && !this.compruebaEmailEntrega(form))
          {*/
          this.peticionEnvio(form);
          /*}
          else
          {
            this.compruebaPersonaContactoEntrega(form);
            this.compruebaTelefonoEntrega(form);
            this.compruebaEmailEntrega(form);
            var Body = this.translateService.instant('Los datos de entrega son obligatorios.');
            var Titulo = this.translateService.instant('Error datos entrega');
            this.toastr.error(Body, Titulo)
          }*/
        }
        else {
          this.peticionEnvio(form);
        }
      }
      else {
        var Body = this.translateService.instant('La referencia ya ha sido introducida en el sistema');
        var Titulo = this.translateService.instant('Error duplicidad');
        this.toastr.error(Body, Titulo);
      }
      /*}else{
        var Body = this.translateService.instant('BodyErrorFicheros');
        var Titulo = this.translateService.instant('TitleErrorFicheros');
        this.toastr.error(Body, Titulo);
      }*/

    }
    else {
      this.compruebaErrores(form);
      this.error = true;
      var Body = this.translateService.instant('Faltan campos por completar.');
      var Titulo = this.translateService.instant('Error en el envío');
      this.toastr.error(Body, Titulo)
    }
  }

  //Funcion que pone la direccion y la localidad de donde se va a recoger
  setRecogidaInformation() {
    this.resumenDireccionRecogida = '';
    this.resumenDireccionRecogida = this.getFormatDireccion(this.formModelTerrestre.value.EntidadRecogida);
  }

  //Funcion que pone la direccion y la localidad de donde se va a entregar
  setEntregaInformation() {
    this.resumenDireccionEntrega = '';
    this.resumenDireccionEntrega = this.getFormatDireccion(this.formModelTerrestre.value.EntidadEntrega);
  }

  setModoEnvio(modoEnvio: Modoenvio) {
    if (modoEnvio != undefined) {
      this.formModelTerrestre.value.ModoEnvio = modoEnvio.IdModoEnvio;
      if (modoEnvio.ModoEnvio == 'COMPLETO')
        this.esFTL = true;
      else
        this.esFTL = false;
    }
  }

  //Funcion que obtiene el tipo de envio
  setTipoEnvio(tipoEnvio: TipoEnvio) {
    this.envioService.getEntidadUsuario().subscribe(entidad => {
      if (tipoEnvio != undefined) {
        this.formModelTerrestre.value.TipoEnvio = tipoEnvio.IdTipoEnvio;
        this.formModelTerrestre.value.Shipper = null;
        this.formModelTerrestre.value.DireccionShipper = '';
        this.formModelTerrestre.value.EntidadRecogida = '';
        this.resumenDireccionRecogida = '';
        this.formModelTerrestre.value.EntidadRecogida = null;
        this.cargaRemitente = false;
        this.cargaEntidadRecogida = false;
        this.formModelTerrestre.value.PersonaContactoRecogida = '';
        this.formModelTerrestre.value.TelefonoRecogida = '';
        this.formModelTerrestre.value.EmailRecogida = '';
        if (this.esExport() || this.esNacional()) {
          var lista = this.listShipper;
          this.listShipper = this.listConsignee;
          this.listConsignee = lista;
          this.listShipper.forEach(shipper => {
            if (shipper.IdEntidad == entidad) {
              this.formModelTerrestre.value.Consignee = null;
              this.formModelTerrestre.value.EntidadEntrega = null;
              this.setConsigneeInformation();
              this.formModelTerrestre.value.Shipper = shipper;
              this.formModelTerrestre.value.EntidadRecogida = shipper;
              this.formModelTerrestre.value.Cliente = shipper;
              this.setShipperInformation();
            }
          });
        } else if (this.esImport()) {
          var lista = this.listConsignee;
          this.listConsignee = this.listShipper;
          this.listShipper = lista;
          this.listConsignee.forEach(consignee => {
            if (consignee.IdEntidad == entidad) {
              this.formModelTerrestre.value.Shipper = null;
              this.formModelTerrestre.value.EntidadRecogida = null;
              this.setShipperInformation();
              this.formModelTerrestre.value.Consignee = consignee;
              this.formModelTerrestre.value.EntidadEntrega = consignee;
              this.formModelTerrestre.value.Cliente = consignee;
              this.setConsigneeInformation();
            }
          });
        }
        if (this.datosContacto != null && this.datosContacto != undefined && this.esExport()) {
          this.formModelTerrestre.value.PersonaContactoEntrega = this.datosContacto.NombreUsuario;
          this.formModelTerrestre.value.TelefonoEntrega = this.datosContacto.Telefono;
          this.formModelTerrestre.value.EmailEntrega = this.datosContacto.Email;
        }
      }
    })
  }


  esExport() {
    return this.formModelTerrestre.value.TipoEnvio == 7;
  }

  esImport() {
    return this.formModelTerrestre.value.TipoEnvio == 8;
  }

  esNacional() {
    return this.formModelTerrestre.value.TipoEnvio == 5;
  }

  asignarModoEnvio(envioTerrestre: Shipment) {
    this.formModelTerrestre.value.ModoEnvio = envioTerrestre.IdModoEnvio;
  }

  asignarTipoEnvio(envioTerrestre: Shipment) {
    this.formModelTerrestre.value.TipoEnvio = envioTerrestre.IdTipoEnvio;
  }

  asignarShipper(envioTerrestre: Shipment) {
    if (this.listShipper.length >= 0) {
      this.listShipper.forEach(shipper => {
        if (shipper.IdEntidad == envioTerrestre.IdRemitente) {
          this.formModelTerrestre.value.Shipper = shipper;
          this.setShipperInformation();
        }
      });
    }
  }

  asignarConsignee(envioTerrestre: Shipment) {
    if (this.listConsignee.length >= 0) {
      this.listConsignee.forEach(consignee => {
        if (consignee.IdEntidad == envioTerrestre.IdDestinatario) {
          this.formModelTerrestre.value.Consignee = consignee;
          this.setConsigneeInformation();
        }
      })
    }
  }

  asignarNotify(envioTerrestre: Shipment) {
    if (this.listNotify.length >= 0) {
      this.listNotify.forEach(notify => {
        if (notify.IdEntidad == envioTerrestre.IdNotify) {
          this.formModelTerrestre.value.Notify = notify;
          this.setNotifyInformation();
        }
      })
    }
  }

  asignarIncoterm(envioTerrestre: Shipment) {
    if (this.listIncoterm.length >= 0 && this.formModelTerrestre.value.Incoterm == '') {
      this.formModelTerrestre.value.Incoterm = envioTerrestre.Incoterm;
    }
  }

  //asignamos la fecha de recogida
  asignarFechaRecogida(envioTerrestre: Shipment) {
    if (envioTerrestre.FechaRecogida != undefined && envioTerrestre.FechaRecogida != null) {
      let fecha = envioTerrestre.FechaRecogida.toString().split('T')[0];
      this.formModelTerrestre.value.FechaRecogida = fecha;
    }
  }

  //Funcion que trae los valores de la clase al form
  asignarValores(envioTerrestre: Shipment) {
    if (envioTerrestre != undefined) {
      this.editar = this.envioService.getEditar();
      this.duplicar = this.envioService.getDuplicar();
      this.isCotizacion = this.envioService.getCotizacionBoolean();

      if (this.editar || this.visor)
        this.formModelTerrestre.value.Id = this.idEnvio;
      else
        this.formModelTerrestre.value.Id = 0;

      if (this.isCotizacion)
        this.formModelTerrestre.value.Cotizacion = this.envioService.getCotizacion();

      if (envioTerrestre.IdArea != undefined && envioTerrestre.IdArea != null)
        this.formModelTerrestre.value.Envio = envioTerrestre.IdArea;

      this.asignarModoEnvio(envioTerrestre);

      this.asignarTipoEnvio(envioTerrestre);

      if (this.visor || this.editar || this.isCotizacion) {
        this.formModelTerrestre.value.Referencia = envioTerrestre.RefCliente;
        this.asignarFechaRecogida(envioTerrestre);
      }

      this.asignarShipper(envioTerrestre);
      if (this.formModelTerrestre.value.DireccionShipper != envioTerrestre.DireccionShipper && envioTerrestre.DireccionShipper != null && envioTerrestre.DireccionShipper != '')
        this.formModelTerrestre.value.DireccionShipper = envioTerrestre.DireccionShipper;

      this.asignarConsignee(envioTerrestre);
      if (this.formModelTerrestre.value.DireccionConsignee != envioTerrestre.DireccionConsignee && envioTerrestre.DireccionConsignee != null && envioTerrestre.DireccionConsignee != '')
        this.formModelTerrestre.value.DireccionConsignee = envioTerrestre.DireccionConsignee;


      this.formModelTerrestre.value.Incoterm = envioTerrestre.Incoterm;

      this.formModelTerrestre.value.ValorMercancia = envioTerrestre.ValorMercancia;
      this.formModelTerrestre.value.TipoDivisa = envioTerrestre.IdTipoDivisa;
      this.formModelTerrestre.value.SeguroMercancia = envioTerrestre.CosteSeguro;
      this.formModelTerrestre.value.RefCotizacion = envioTerrestre.RefCotizacion;

      if (envioTerrestre.Asegurado != undefined && envioTerrestre.Asegurado != null)
        this.formModelTerrestre.value.Asegurado = envioTerrestre.Asegurado;
      else
        this.formModelTerrestre.value.Asegurado = false;

      if (!this.tarifa) {
        this.formModelTerrestre.value.PesoNetoTotalDGR = envioTerrestre.PesoNetoTotalDGR;
        this.formModelTerrestre.value.PesoBrutoTotalDGR = envioTerrestre.PesoBrutoTotalDGR;
        this.formModelTerrestre.value.BultosTotales = envioTerrestre.BultosTotales;
        this.formModelTerrestre.value.PesoNetoTotal = envioTerrestre.PesoNetoTotal;
        this.formModelTerrestre.value.PesoBrutoTotal = envioTerrestre.PesoBrutoTotal;
        this.formModelTerrestre.value.VolumenTotal = envioTerrestre.VolumenTotal;
        this.formModelTerrestre.value.PesoTasable = envioTerrestre.PesoTasable;
      }


      this.formModelTerrestre.value.Refrigerada = envioTerrestre.Refrigerada;
      this.formModelTerrestre.value.Perecedero = envioTerrestre.Perecedero;
      this.formModelTerrestre.value.MercanciaPeligrosa = envioTerrestre.MercanciaPeligrosa;
      //this.formModelTerrestre.value.NoRemontable = envioTerrestre.NoRemontable;
      this.formModelTerrestre.value.Fumigado = envioTerrestre.Fumigado;

      //Recogida
      if (envioTerrestre.FechaRecogida != null && (this.editar || this.visor))
        this.asignarFechaRecogida(envioTerrestre);

      if (this.listShipper.length > 0)
        this.listShipper.forEach(element => {
          if (element.IdEntidad == envioTerrestre.IdEntidadRecogida)
            this.formModelTerrestre.value.EntidadRecogida = element;
        });
      if (envioTerrestre.InstruccionesRecogida != null && envioTerrestre.InstruccionesRecogida != undefined)
        this.formModelTerrestre.value.InstruccionesRecogida = envioTerrestre.InstruccionesRecogida;
      else
        this.formModelTerrestre.value.InstruccionesRecogida = "";
      this.formModelTerrestre.value.DireccionRecogida = envioTerrestre.IdDireccionRecogida;

      if (envioTerrestre.ReferenciaRecogida == null || envioTerrestre.ReferenciaRecogida == undefined)
        this.formModelTerrestre.value.ReferenciaRecogida = '';
      else if (this.editar || this.visor)
        this.formModelTerrestre.value.ReferenciaRecogida = envioTerrestre.ReferenciaRecogida;

      this.formModelTerrestre.value.HoraRecogidaInicio1 = envioTerrestre.PrimeraHoraRecogidaInicio;
      this.formModelTerrestre.value.HoraRecogidaFinal1 = envioTerrestre.PrimeraHoraRecogidaFinal;
      this.formModelTerrestre.value.HoraRecogidaInicio2 = envioTerrestre.SegundaHoraRecogidaInicio;
      this.formModelTerrestre.value.HoraRecogidaFinal2 = envioTerrestre.SegundaHoraRecogidaFinal;

      if (envioTerrestre.TelefonoRecogida != undefined && envioTerrestre.TelefonoRecogida != null && envioTerrestre.TelefonoRecogida != "")
        this.formModelTerrestre.value.TelefonoRecogida = envioTerrestre.TelefonoRecogida;
      if (envioTerrestre.PersonaContactoRecogida != undefined && envioTerrestre.PersonaContactoRecogida != null && envioTerrestre.PersonaContactoRecogida != "")
        this.formModelTerrestre.value.PersonaContactoRecogida = envioTerrestre.PersonaContactoRecogida;
      if (envioTerrestre.EmailRecogida != undefined && envioTerrestre.EmailRecogida != null && envioTerrestre.EmailRecogida != "")
        this.formModelTerrestre.value.EmailRecogida = envioTerrestre.EmailRecogida;

      // this.formModelTerrestre.value.DespachoOrigen = envioTerrestre.DespachoOrigen;
      // this.formModelTerrestre.value.DespachoDestino = envioTerrestre.DespachoDestino;

      //Entrega
      //if(this.incotermStartWith())
      //{
      if (this.listConsignee.length > 0)
        this.listConsignee.forEach(element => {
          if (element.IdEntidad == envioTerrestre.IdEntidadEntrega)
            this.formModelTerrestre.value.EntidadEntrega = element;
        });

      this.formModelTerrestre.value.DireccionEntrega = envioTerrestre.IdDireccionEntrega;
      if (envioTerrestre.InstruccionesEntrega != null && envioTerrestre.InstruccionesEntrega != undefined)
        this.formModelTerrestre.value.InstruccionesEntrega = envioTerrestre.InstruccionesEntrega;
      else
        this.formModelTerrestre.value.InstruccionesEntrega = "";

      if (envioTerrestre.ReferenciaEntrega == null || envioTerrestre.ReferenciaEntrega == undefined)
        this.formModelTerrestre.value.ReferenciaEntrega = '';
      else if (this.visor || this.editar)
        this.formModelTerrestre.value.ReferenciaEntrega = envioTerrestre.ReferenciaEntrega;

      if (envioTerrestre.TelefonoEntrega != undefined && envioTerrestre.TelefonoEntrega != null && envioTerrestre.TelefonoEntrega != "")
        this.formModelTerrestre.value.TelefonoEntrega = envioTerrestre.TelefonoEntrega;
      if (envioTerrestre.PersonaContactoEntrega != undefined && envioTerrestre.PersonaContactoEntrega != null && envioTerrestre.PersonaContactoEntrega != "")
        this.formModelTerrestre.value.PersonaContactoEntrega = envioTerrestre.PersonaContactoEntrega;
      if (envioTerrestre.EmailEntrega != undefined && envioTerrestre.EmailEntrega != null && envioTerrestre.EmailEntrega != "")
        this.formModelTerrestre.value.EmailEntrega = envioTerrestre.EmailEntrega;

      this.formModelTerrestre.value.HoraEntregaInicio1 = envioTerrestre.PrimeraHoraEntregaInicio;
      this.formModelTerrestre.value.HoraEntregaInicio2 = envioTerrestre.SegundaHoraEntregaInicio;
      this.formModelTerrestre.value.HoraEntregaFinal1 = envioTerrestre.PrimeraHoraEntregaFinal;
      this.formModelTerrestre.value.HoraEntregaFinal2 = envioTerrestre.SegundaHoraEntregaFinal;
      //}

      if (envioTerrestre.Instrucciones != null && envioTerrestre.Instrucciones != undefined)
        this.formModelTerrestre.value.Instrucciones = envioTerrestre.Instrucciones;
      else
        this.formModelTerrestre.value.Instrucciones = "";
      if (envioTerrestre.Marcas != null && envioTerrestre.Marcas != undefined)
        this.formModelTerrestre.value.Marcas = envioTerrestre.Marcas;
      else
        this.formModelTerrestre.value.Marcas = "";
      this.formModelTerrestre.value.Mercancia = envioTerrestre.Mercancia;
      this.asignarTipoEnvio(envioTerrestre);
      this.formModelTerrestre.value.ModoEnvio = envioTerrestre.IdModoEnvio;
      this.asignarModoEnvio(envioTerrestre);
      this.listaMerc(envioTerrestre.Mercancia);
      this.envioService.setPartida(null);
      this.envioService.setExpedicion(null);
      this.envioService.setCotizacion(null);
    }
  }


  //Funcion que trae los valores de la clase al form
  asignarValoresPlantilla(envioTerrestre: Shipment) {
    if (envioTerrestre != undefined && this.formModelTerrestre.value != null && this.formModelTerrestre.value != undefined) {
      this.editar = this.envioService.getEditar();
      this.duplicar = this.envioService.getDuplicar();
      this.isCotizacion = this.envioService.getCotizacionBoolean();

      if (this.isCotizacion) { // También viene de una cotización
        // if (this.editar || this.visor)
        //   this.formModelTerrestre.value.IdEnvio = this.idEnvio;
        // else
        //   this.formModelTerrestre.value.IdEnvio = 0;

        // if (this.isCotizacion)
        //   this.formModelTerrestre.value.Cotizacion = this.envioService.getCotizacion();

        if (envioTerrestre.IdArea != undefined && envioTerrestre.IdArea != null)
          this.formModelTerrestre.value.Envio = envioTerrestre.IdArea;

        this.asignarTipoEnvio(envioTerrestre);

        // if (this.visor || this.editar || this.isCotizacion) {
        //   this.asignarFechaRecogida(envioTerrestre);
        //   this.formModelTerrestre.value.Referencia = envioTerrestre.RefCliente;
        // }

        this.asignarShipper(envioTerrestre);
        if (this.formModelTerrestre.value.DireccionShipper != envioTerrestre.DireccionShipper && envioTerrestre.DireccionShipper != null && envioTerrestre.DireccionShipper != '')
          this.formModelTerrestre.value.DireccionShipper = envioTerrestre.DireccionShipper

        this.asignarConsignee(envioTerrestre);
        if (this.formModelTerrestre.value.DireccionConsignee != envioTerrestre.DireccionConsignee && envioTerrestre.DireccionConsignee != null && envioTerrestre.DireccionConsignee != '')
          this.formModelTerrestre.value.DireccionConsignee = envioTerrestre.DireccionConsignee

        if (this.formModelTerrestre.value.DireccionNotify != envioTerrestre.DireccionNotify && envioTerrestre.DireccionNotify != null && envioTerrestre.DireccionNotify != '')
          this.formModelTerrestre.value.DireccionNotify = envioTerrestre.DireccionNotify

        //this.asignarIncoterm(envioTerrestre);
        // this.formModelTerrestre.value.Incoterm = envioTerrestre.Incoterm;
        // this.formModelTerrestre.value.AeropuertoOrigen = envioTerrestre.IdAeropuertoOrigen;
        // this.formModelTerrestre.value.AeropuertoDestino = envioTerrestre.IdAeropuertoDestino;
        // this.formModelTerrestre.value.RefCotizacion = envioTerrestre.RefCotizacion;
        this.formModelTerrestre.value.Aerolinea = envioTerrestre.Aerolinea;

        // this.formModelTerrestre.value.ValorMercancia = envioTerrestre.ValorMercancia;
        // this.formModelTerrestre.value.TipoDivisa = envioTerrestre.IdTipoDivisa;

        // this.formModelTerrestre.value.SeguroMercancia = envioTerrestre.CosteSeguro;

        // this.formModelTerrestre.value.VGMPesaje = envioTerrestre.VGMPesaje;
        // this.formModelTerrestre.value.VGMTransmision = envioTerrestre.VGMTransmision;

        // if (envioTerrestre.Asegurado == 1)
        //   this.formModelTerrestre.value.Asegurado = true;
        // else
        //   this.formModelTerrestre.value.Asegurado = false;

        // this.formModelTerrestre.value.PesoNetoTotalDGR = envioTerrestre.PesoNetoTotalDGR;
        // this.formModelTerrestre.value.PesoBrutoTotalDGR = envioTerrestre.PesoBrutoTotalDGR;
        // this.formModelTerrestre.value.BultosTotales = envioTerrestre.BultosTotales;
        // this.formModelTerrestre.value.PesoNetoTotal = envioTerrestre.PesoNetoTotal;
        // this.formModelTerrestre.value.PesoBrutoTotal = envioTerrestre.PesoBrutoTotal;
        // this.formModelTerrestre.value.VolumenTotal = envioTerrestre.VolumenTotal;
        // this.formModelTerrestre.value.PesoTasable = envioTerrestre.PesoTasable;

        // this.formModelTerrestre.value.Refrigerada = envioTerrestre.Refrigerada;
        // this.formModelTerrestre.value.Perecedero = envioTerrestre.Perecedero;
        // this.formModelTerrestre.value.MercanciaPeligrosa = envioTerrestre.MercanciaPeligrosa;
        // this.formModelTerrestre.value.NoRemontable = envioTerrestre.NoRemontable;
        // this.formModelTerrestre.value.Fumigado = envioTerrestre.Fumigado;


        //Recogida
        // if (envioTerrestre.FechaRecogida != null && (this.visor || this.editar))
        //   this.asignarFechaRecogida(envioTerrestre);
        if (this.listShipper.length > 0)
          this.listShipper.forEach(element => {
            if (element.IdEntidad == envioTerrestre.IdEntidadRecogida)
              this.formModelTerrestre.value.EntidadRecogida = element;
          });
        this.formModelTerrestre.value.DireccionRecogida = envioTerrestre.IdDireccionRecogida;
        if (envioTerrestre.InstruccionesRecogida != null && envioTerrestre.InstruccionesRecogida != undefined)
          this.formModelTerrestre.value.InstruccionesRecogida = envioTerrestre.InstruccionesRecogida;
        else
          this.formModelTerrestre.value.InstruccionesRecogida = "";
        if (envioTerrestre.ReferenciaRecogida == null || envioTerrestre.ReferenciaRecogida == undefined)
          this.formModelTerrestre.value.ReferenciaRecogida = '';
        else {
          if (this.visor || this.editar)
            this.formModelTerrestre.value.ReferenciaRecogida = envioTerrestre.ReferenciaRecogida;
          else
            this.formModelTerrestre.value.ReferenciaRecogida = "";
        }

        this.formModelTerrestre.value.HoraRecogidaInicio1 = envioTerrestre.PrimeraHoraRecogidaInicio;
        this.formModelTerrestre.value.HoraRecogidaFinal1 = envioTerrestre.PrimeraHoraRecogidaFinal;
        this.formModelTerrestre.value.HoraRecogidaInicio2 = envioTerrestre.SegundaHoraRecogidaInicio;
        this.formModelTerrestre.value.HoraRecogidaFinal2 = envioTerrestre.SegundaHoraRecogidaFinal;

        if (envioTerrestre.TelefonoRecogida != undefined && envioTerrestre.TelefonoRecogida != null && envioTerrestre.TelefonoRecogida != "")
          this.formModelTerrestre.value.TelefonoRecogida = envioTerrestre.TelefonoRecogida;
        if (envioTerrestre.PersonaContactoRecogida != undefined && envioTerrestre.PersonaContactoRecogida != null && envioTerrestre.PersonaContactoRecogida != "")
          this.formModelTerrestre.value.PersonaContactoRecogida = envioTerrestre.PersonaContactoRecogida;
        if (envioTerrestre.EmailRecogida != undefined && envioTerrestre.EmailRecogida != null && envioTerrestre.EmailRecogida != "")
          this.formModelTerrestre.value.EmailRecogida = envioTerrestre.EmailRecogida;

        this.formModelTerrestre.value.DespachoOrigen = envioTerrestre.DespachoOrigen;
        this.formModelTerrestre.value.DespachoDestino = envioTerrestre.DespachoDestino;

        //Entrega
        if (this.incotermStartWith()) {
          if (this.listConsignee.length > 0)
            this.listConsignee.forEach(element => {
              if (element.IdEntidad == envioTerrestre.IdEntidadEntrega)
                this.formModelTerrestre.value.EntidadEntrega = element;
            });
          this.formModelTerrestre.value.DireccionEntrega = envioTerrestre.IdDireccionEntrega;
          if (envioTerrestre.InstruccionesEntrega != null && envioTerrestre.InstruccionesEntrega != undefined)
            this.formModelTerrestre.value.InstruccionesEntrega = envioTerrestre.InstruccionesEntrega;
          else
            this.formModelTerrestre.value.InstruccionesEntrega = "";
          if (envioTerrestre.ReferenciaEntrega == null || envioTerrestre.ReferenciaEntrega == undefined)
            this.formModelTerrestre.value.ReferenciaEntrega = '';
          else
            this.formModelTerrestre.value.ReferenciaEntrega = envioTerrestre.ReferenciaEntrega;

          if (envioTerrestre.TelefonoEntrega != undefined && envioTerrestre.TelefonoEntrega != null && envioTerrestre.TelefonoEntrega != "")
            this.formModelTerrestre.value.TelefonoEntrega = envioTerrestre.TelefonoEntrega;
          if (envioTerrestre.PersonaContactoEntrega != undefined && envioTerrestre.PersonaContactoEntrega != null && envioTerrestre.PersonaContactoEntrega != "")
            this.formModelTerrestre.value.PersonaContactoEntrega = envioTerrestre.PersonaContactoEntrega;
          if (envioTerrestre.EmailEntrega != undefined && envioTerrestre.EmailEntrega != null && envioTerrestre.EmailEntrega != "")
            this.formModelTerrestre.value.EmailEntrega = envioTerrestre.EmailEntrega;
        }

        // if (envioTerrestre.Instrucciones != null && envioTerrestre.Instrucciones != undefined)
        //   this.formModelTerrestre.value.Instrucciones = envioTerrestre.Instrucciones;
        // else
        //   this.formModelTerrestre.value.Instrucciones = "";
        if (envioTerrestre.InstruccionesBLAWB != null && envioTerrestre.InstruccionesBLAWB != undefined)
          this.formModelTerrestre.value.InstruccionesBLAWB = envioTerrestre.InstruccionesBLAWB;
        else
          this.formModelTerrestre.value.InstruccionesBLAWB = "";
        if (envioTerrestre.Marcas != null && envioTerrestre.Marcas != undefined)
          this.formModelTerrestre.value.Marcas = envioTerrestre.Marcas;
        else
          this.formModelTerrestre.value.Marcas = "";

        //this.formModelTerrestre.value.Mercancia = envioTerrestre.Mercancia;
        this.asignarTipoEnvio(envioTerrestre);
        //this.listaMerc(envioTerrestre.Mercancia);
        this.envioService.setPartida(null);
        this.envioService.setExpedicion(null);
        this.envioService.setCotizacion(null);
      } else
        this.asignarValores(envioTerrestre);

    }
  }

  //Funcion que recoge la informacion completa del shipper
  setShipperInformation() {
    this.formModelTerrestre.value.DireccionShipper = '';
    if (this.formModelTerrestre.value.Shipper != null && this.formModelTerrestre.value.Shipper != undefined)
      if (this.formModelTerrestre.value.Shipper.IdDireccion != null)
        this.formModelTerrestre.value.DireccionRecogida = this.formModelTerrestre.value.Shipper.IdDireccion;

    this.formModelTerrestre.value.DireccionShipper = '';

    this.formModelTerrestre.value.DireccionShipper = this.getFormatDireccion(this.formModelTerrestre.value.Shipper);
    this.resumenDireccionRecogida = this.formModelTerrestre.value.DireccionShipper;
  }


  //Funcion que recoge la informacion completa del consignee
  setConsigneeInformation() {
    this.cargarListaNotify();
    this.formModelTerrestre.value.DireccionConsignee = '';
    if (this.formModelTerrestre.value.Consignee != null && this.formModelTerrestre.value.Consignee != undefined)
      if (this.formModelTerrestre.value.Consignee.IdDireccion != null)
        this.formModelTerrestre.value.IdDireccionEntrega = this.formModelTerrestre.value.Consignee.IdDireccion;

    this.formModelTerrestre.value.EntidadEntrega = this.formModelTerrestre.value.Consignee;
    this.formModelTerrestre.value.DireccionConsignee = this.getFormatDireccion(this.formModelTerrestre.value.Consignee);
    this.resumenDireccionEntrega = this.formModelTerrestre.value.DireccionConsignee;
  }

  //Funcion que recoge la informacion completa del notify
  setNotifyInformation() {
    this.formModelTerrestre.value.DireccionNotify = this.getFormatDireccion(this.formModelTerrestre.value.Notify);
  }

  getFormatDireccion(entidad) {
    var formatDireccion: string = "";
    if (entidad != null && entidad != undefined) {
      // Tipo direccion
      if (entidad.TipoDireccion != undefined && entidad.TipoDireccion != null && entidad.TipoDireccion != ""
        && entidad.TipoDireccion != ".")
        formatDireccion += entidad.TipoDireccion + " ";

      // Direccion
      if (entidad.Direccion != undefined && entidad.Direccion != null && entidad.Direccion != ""
        && entidad.Direccion != ".")
        formatDireccion += entidad.Direccion + ", ";

      // Numero
      if (entidad.Numero != undefined && entidad.Numero != null && entidad.Numero != ""
        && entidad.Numero != ".")
        formatDireccion += entidad.Numero + "\n";

      // Localidad
      if (entidad.Poblacion != undefined && entidad.Poblacion != null && entidad.Poblacion != ""
        && entidad.Poblacion != ".")
        formatDireccion += entidad.Poblacion;
    }
    return formatDireccion;
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el shipper
  recibirShipper(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelTerrestre.value.Shipper = entidad;
      this.setShipperInformation();
    }
    this.modalRef.hide();
    document.getElementById("Shipper").focus();
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el consignee
  recibirConsignee(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelTerrestre.value.Consignee = entidad;
      this.setConsigneeInformation();
    }
    this.modalRef.hide();
    document.getElementById("Consignee").focus();
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirNotify(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelTerrestre.value.Notify = entidad;
      this.setNotifyInformation();
    }
    this.modalRef.hide();
    document.getElementById("Notify").focus();
  }



  //Funcion que comprueba los pesos y si el bruto es mayor que el neto
  comprobarPesos(elem: Mercancia) {
    if (elem.PesoBruto === undefined || elem.PesoBruto === null || elem.PesoBruto.toString() == '')
      return true
    if (elem.PesoNeto !== undefined && elem.PesoNeto !== null && elem.PesoNeto.toString() != '')
      return elem.PesoBruto !== undefined && elem.PesoNeto !== undefined && elem.PesoBruto !== null && elem.PesoNeto !== null && Number(elem.PesoNeto) <= Number(elem.PesoBruto)
    else
      return elem.PesoBruto !== undefined && elem.PesoBruto !== null && elem.PesoBruto.toString() != ''
  }

  //Comprueba si hay una mercancía vacía o algún campo de la mercancía vacía
  comprobarVacio(merc: Mercancia) {
    return (merc.Bultos === null || merc.Bultos === undefined || merc.Volumen === null || merc.Volumen === undefined
      || merc.TipoEmbalaje === undefined || merc.TipoEmbalaje === null
      || merc.PesoBruto === null || merc.PesoBruto === undefined || merc.PesoBruto.toString() === '' || merc.Volumen.toString() === ''
      || merc.Bultos.toString() === '' || merc.TipoEmbalaje.toString() === '' || merc.NombreMercancia === null || merc.NombreMercancia === undefined || merc.NombreMercancia.toString() === '');
  }

  comprobarPesosBP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoBruto
      sum = sum + pb
    });
    return elem.PesoBruto >= sum
  }

  comprobarPesosNP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoNeto
      sum = sum + pb
    });
    return elem.PesoNeto >= sum;
  }

  comprobarGrados(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return merc.GradosMax === null || merc.GradosMax === undefined || merc.GradosMin === null || merc.GradosMin === undefined;
    return false;
  }

  comprobraGradosDispares(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return this.comprobarGrados(merc) || merc.GradosMax < merc.GradosMin;
    return false;
  }

  comprobarMercanciaPeligrosa(merc: Mercancia): boolean {
    return merc.Peligrosa && merc.MercanciaPeligrosa != null && merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0;
  }

  //Funcion que comprueba si hay errores en una linea de mercancía
  comprobarErrores() {
    var camposVacios = false, pesos = false, index = 1, grados = false, indicePB = -1, indicePN = -1, pesosBrutosDispares = true, pesosNetosDispares = true;
    var indicesMP = [], indiceNoExisteMercanciaPeligrosa = [], indiceTC = [], pesosDisparesArray = [], mercanciaspeligrosasAux = [], pesosGradosArray = [], pesosGradosDisparesArray = [], mercanciastemperaturacontroladaAux = [], pesosBrutosArray = [], pesosNetosArray = [];;
    this.listaMercancia.forEach(merc => {
      if (merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0 && merc.MercanciaPeligrosa != null) {
        mercanciaspeligrosasAux.push(merc.MercanciaPeligrosa);
        // var compruebaPesosBrutosMP = this.comprobarPesosBP(merc);
        // var compruebaPesosNetoMP = this.comprobarPesosNP(merc);
        // if (!compruebaPesosBrutosMP) {
        //   indicePB = index;
        //   pesosBrutosArray.push(index);
        // } else {
        //   pesosBrutosDispares = false;
        // }
        // if (!compruebaPesosNetoMP) {
        //   indicePN = index;
        //   pesosNetosArray.push(index);
        // } else {
        //   pesosNetosDispares = false;
        // }
      }
      if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
        mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
        indiceTC.push(index);
      }
      //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);
      var compruebaMP = this.comprobarVacio(merc);
      var compruebaPesos = this.comprobarPesos(merc);
      var compruebaGrados = this.comprobarGrados(merc);
      var compruebaGradosDispares = this.comprobraGradosDispares(merc);
      if (merc.Peligrosa && merc.MercanciaPeligrosa.length == 0)
        indiceNoExisteMercanciaPeligrosa.push(index);
      if (compruebaMP)
        indicesMP.push(index);
      if (!compruebaPesos)
        pesosDisparesArray.push(index);
      if (compruebaGrados)
        pesosGradosArray.push(index);
      if (!compruebaGrados && compruebaGradosDispares)
        pesosGradosDisparesArray.push(index);
      index++;
    });
    if (this.shipmentTerrestre.MercanciaPeligrosa == 1 && mercanciaspeligrosasAux.length == 0) {
      camposVacios = true
      var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
      var Titulo = this.translateService.instant('Error mercancia peligrosa');
      this.toastr.error(Body, Titulo)
    }
    index = 1;
    indicesMP.forEach(imp => {
      camposVacios = true
      var Body = this.translateService.instant('Necesitas rellenar todos los campos de la línea ') + imp
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Campos vacíos en mercancía');
      this.toastr.error(Body, Titulo)
    });
    indiceNoExisteMercanciaPeligrosa.forEach(inem => {
      camposVacios = true
      var Body = this.translateService.instant('Falta mercancía peligrosa en la línea ') + inem
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Falta mercancía peligrosa');
      this.toastr.error(Body, Titulo);
    })
    pesosDisparesArray.forEach(e => {
      var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e;
      this.toastr.error(Body, Titulo)
      pesos = true
    })
    pesosGradosArray.forEach(tc => {
      var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc;
      this.toastr.error(Body, Titulo)
      grados = true;
    });
    // pesosBrutosArray.forEach(e => {
    //   var Body = this.translateService.instant('El peso bruto de la linea ') + e + (' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
    //   var Titulo = this.translateService.instant('Error peso bruto DGR linea ') + e;
    //   this.toastr.error(Body, Titulo);
    //   pesos = true
    // });
    // pesosNetosArray.forEach(e => {
    //   var Body = this.translateService.instant('El peso neto de la linea ') + e + (' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
    //   var Titulo = this.translateService.instant('Error peso neto DGR linea ') + e;
    //   this.toastr.error(Body, Titulo);
    //   pesos = true
    // });
    if (!this.comprobarPesoBrutoTotalDGR() || !this.comprobarPesoNetoTotalDGR() || pesos || camposVacios || !this.comprobarPesosTotales()) { // Existe algún error
      return true
    }
    return false
  }
  //Funcion que comprueba si el peso neto total es menor que el bruto total
  comprobarPesosTotales() {
    if (this.formModelTerrestre.value.PesoBrutoTotal !== null && this.formModelTerrestre.value.PesoBrutoTotal !== undefined
      && this.formModelTerrestre.value.PesoBrutoTotal !== "" && this.formModelTerrestre.value.PesoNetoTotal !== ""
      && this.formModelTerrestre.value.PesoNetoTotal !== null && this.formModelTerrestre.value.PesoNetoTotal !== undefined
      && parseInt(this.formModelTerrestre.value.PesoBrutoTotal) < parseInt(this.formModelTerrestre.value.PesoNetoTotal)) {
      var Body = this.translateService.instant("Peso Bruto Total es menor que Peso Neto Total");
      var Titulo = this.translateService.instant("Peso Bruto Total menor que Peso Neto Total");
      this.toastr.error(Body, Titulo)
      this.errorBrutoTotal = true;
      return false
    }
    this.errorBrutoTotal = false;
    return true
  }
  //Funcion que comprueba si los pesos brutos totales son mayores que los pesos brutos totales DGR 
  comprobarPesoBrutoTotalDGR() {
    if (this.formModelTerrestre.value.PesoBrutoTotal !== null && this.formModelTerrestre.value.PesoBrutoTotal !== undefined
      && this.formModelTerrestre.value.PesoBrutoTotal !== "" && this.formModelTerrestre.value.PesoBrutoTotalDGR !== ""
      && this.formModelTerrestre.value.PesoBrutoTotalDGR !== null && this.formModelTerrestre.value.PesoBrutoTotalDGR !== undefined
      && parseInt(this.formModelTerrestre.value.PesoBrutoTotal) < parseInt(this.formModelTerrestre.value.PesoBrutoTotalDGR)) {
      var Body = this.translateService.instant("El Peso Bruto Total debe ser mayor o igual que el Peso Bruto Total DGR ");
      var Titulo = this.translateService.instant("Error peso bruto total DGR");
      this.errorPesoBruto = true;
      this.toastr.error(Body, Titulo);
      return false;
    }
    this.errorPesoBruto = false;
    return true;
  }

  //Funcion que comprueba si los pesos Netos totales son mayores que los pesos Netos totales DGR 
  comprobarPesoNetoTotalDGR() {
    if (this.formModelTerrestre.value.PesoNetoTotal !== null && this.formModelTerrestre.value.PesoNetoTotal !== undefined
      && this.formModelTerrestre.value.PesoNetoTotal !== "" && this.formModelTerrestre.value.PesoNetoTotalDGR !== ""
      && this.formModelTerrestre.value.PesoNetoTotalDGR !== null && this.formModelTerrestre.value.PesoNetoTotalDGR !== undefined
      && parseInt(this.formModelTerrestre.value.PesoNetoTotal) < parseInt(this.formModelTerrestre.value.PesoNetoTotalDGR)) {
      var Body = this.translateService.instant("El Peso Neto Total debe ser mayor o igual que el Peso Neto Total DGR.");
      var Titulo = this.translateService.instant("Error peso Neto total DGR");
      this.toastr.error(Body, Titulo);
      this.errorPesoNeto = true;
      return false
    }
    this.errorPesoNeto = false;
    return true
  }


  //Funcion que obtiene la lista de mercancias del componente Cargo y que recalcula los totales
  listaMerc(lista: Mercancia[]) {
    this.listaMercancia = lista;
    var anteriorPesoBrutoTotal = this.formModelTerrestre.value.PesoBrutoTotal;
    this.formModelTerrestre.value.BultosTotales = 0;
    this.formModelTerrestre.value.PesoBrutoTotal = 0;
    this.formModelTerrestre.value.PesoNetoTotal = 0;
    this.formModelTerrestre.value.VolumenTotal = 0;
    this.formModelTerrestre.value.PesoTasable = 0;
    this.formModelTerrestre.value.PesoNetoTotalDGR = 0;
    this.formModelTerrestre.value.PesoBrutoTotalDGR = 0;
    if (this.listaMercancia.length == 0) {
      this.formModelTerrestre.value.BultosTotales = 0;
      this.formModelTerrestre.value.PesoBrutoTotal = 0;
      this.formModelTerrestre.value.PesoNetoTotal = 0;
      this.formModelTerrestre.value.VolumenTotal = 0;
      this.formModelTerrestre.value.PesoTasable = 0;
      this.formModelTerrestre.value.PesoNetoTotalDGR = 0;
      this.formModelTerrestre.value.PesoBrutoTotalDGR = 0;
    }
    else {
      this.formModelTerrestre.value.VolumenTotal = 0;
      this.formModelTerrestre.value.PesoTasable = 0;
      if (this.listaMercancia != undefined && this.listaMercancia.length >= 0) {
        this.listaMercancia.forEach(element => {
          this.formModelTerrestre.value.BultosTotales = Number((Number(this.formModelTerrestre.value.BultosTotales) + Number(element.Bultos ? element.Bultos : 0)).toFixed(3));
          this.formModelTerrestre.value.PesoBrutoTotal = Number((Number(this.formModelTerrestre.value.PesoBrutoTotal ? this.formModelTerrestre.value.PesoBrutoTotal : 0) +
            Number((element.PesoBruto ? element.PesoBruto : 0))).toFixed(3));
          this.formModelTerrestre.value.PesoNetoTotal = Number((Number(this.formModelTerrestre.value.PesoNetoTotal ? this.formModelTerrestre.value.PesoNetoTotal : 0) +
            Number(element.PesoNeto ? element.PesoNeto : 0)).toFixed(3));
          this.formModelTerrestre.value.VolumenTotal = Number((Number(this.formModelTerrestre.value.VolumenTotal) + Number(element.Volumen ? element.Volumen : 0)).toFixed(3));
          this.formModelTerrestre.value.MercanciaPeligrosa = this.hayMercanciaPeligrosa();
          if (element.MercanciaPeligrosa != undefined && element.MercanciaPeligrosa.length >= 0) {
            if (element.MercanciaPeligrosa.length >= 0) {
              element.MercanciaPeligrosa.forEach(element2 => {
                if (element2.PesoNeto != undefined) {
                  this.formModelTerrestre.value.PesoNetoTotalDGR = Number(this.formModelTerrestre.value.PesoNetoTotalDGR) + Number(element2.PesoNeto);
                }
                if (element2.PesoBruto != undefined) {
                  this.formModelTerrestre.value.PesoBrutoTotalDGR = Number(this.formModelTerrestre.value.PesoBrutoTotalDGR) + Number(element2.PesoBruto);
                }
              });
            }

            if (this.formModelTerrestre.value.PesoBrutoTotalDGR == undefined || this.formModelTerrestre.value.PesoBrutoTotalDGR == null)
              this.formModelTerrestre.value.PesoBrutoTotalDGR = 0;

            if (this.formModelTerrestre.value.PesoNetoTotalDGR == undefined || this.formModelTerrestre.value.PesoNetoTotalDGR == null)
              this.formModelTerrestre.value.PesoNetoTotalDGR = 0;
          }
        });
        this.calcularPesoTasable();
      }
      if (anteriorPesoBrutoTotal !== this.formModelTerrestre.value.PesoBrutoTotal) {
        this.comprobarPesosTotales();
      }
    }
  }

  hayMercanciaPeligrosa(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercancia.forEach(element => {
      if ((element.Peligrosa || element.MP) && !yaHayPeligrosa)
        yaHayPeligrosa = true;
    });
    return yaHayPeligrosa;
  }

  //Funcion que recibe los archivos
  receiveFiles(files) {
    this.listaArchivos = files;
  }

  convertFormToModel(form) {
    this.shipmentTerrestre = new Shipment();
    this.shipmentTerrestre.IdEnvio = 0;
    this.shipmentTerrestre.IdTipoEnvio = this.formModelTerrestre.value.TipoEnvio;
    this.shipmentTerrestre.IdCliente = this.entidadCliente;
    this.shipmentTerrestre.IdCotizacion = this.cotizacion;
    this.shipmentTerrestre.IdArea = 1;
    this.shipmentTerrestre.IdModoEnvio = this.formModelTerrestre.value.ModoEnvio;
    this.shipmentTerrestre.RefCliente = form.value.Referencia;
    this.shipmentTerrestre.IdRemitente = this.formModelTerrestre.value.Shipper.IdEntidad;
    this.shipmentTerrestre.DireccionShipper = this.formModelTerrestre.value.DireccionShipper;
    this.shipmentTerrestre.IdDestinatario = this.formModelTerrestre.value.Consignee?.IdEntidad;
    this.shipmentTerrestre.DireccionConsignee = this.formModelTerrestre.value.DireccionConsignee;
    this.shipmentTerrestre.chShipper = this.chShipper;
    this.shipmentTerrestre.chConsignee = this.chConsignee;
    if (this.formModelTerrestre.value.Notify != null && this.formModelTerrestre.value.Notify != undefined && this.formModelTerrestre.value.Notify.IdEntidad == -1)
      this.shipmentTerrestre.IdNotify = this.formModelTerrestre.value.Consignee.IdEntidad;
    else
      this.shipmentTerrestre.IdNotify = this.formModelTerrestre.value.Notify.IdEntidad;
    this.shipmentTerrestre.Incoterm = this.formModelTerrestre.value.Incoterm;

    this.shipmentTerrestre.ValorMercancia = form.value.ValorMercancia;
    this.shipmentTerrestre.IdTipoDivisa = form.value.TipoDivisa;

    this.shipmentTerrestre.CosteSeguro = this.formModelTerrestre.value.SeguroMercancia;

    if (this.formModelTerrestre.value.Asegurado == true)
      this.shipmentTerrestre.Asegurado = 1;
    else
      this.shipmentTerrestre.Asegurado = 0;

    if (this.formModelTerrestre.value.MercanciaPeligrosa == true)
      this.shipmentTerrestre.MercanciaPeligrosa = 1;
    else
      this.shipmentTerrestre.MercanciaPeligrosa = 0;

    this.shipmentTerrestre.Refrigerada = form.value.Refrigerada;
    this.shipmentTerrestre.Perecedero = form.value.Perecedero;
    // if(form.value.NoRemontable == true)
    //   this.shipmentTerrestre.NoRemontable = 1;
    // else
    //   this.shipmentTerrestre.NoRemontable = 0;
    this.shipmentTerrestre.Fumigado = form.value.Fumigado;
    this.shipmentTerrestre.Fumigado = form.value.Fumigado;

    //Recogida
    this.shipmentTerrestre.IdEntidadRecogida = this.formModelTerrestre.value.EntidadRecogida.IdEntidad;
    this.shipmentTerrestre.IdDireccionRecogida = this.formModelTerrestre.value.EntidadRecogida.IdDireccion;
    this.shipmentTerrestre.FechaRecogida = form.value.FechaRecogida;
    this.shipmentTerrestre.ReferenciaRecogida = form.value.ReferenciaRecogida;
    this.shipmentTerrestre.InstruccionesRecogida = form.value.InstruccionesRecogida;
    this.shipmentTerrestre.PrimeraHoraRecogidaInicio = this.formModelTerrestre.value.HoraRecogidaInicio1;
    this.shipmentTerrestre.PrimeraHoraRecogidaFinal = this.formModelTerrestre.value.HoraRecogidaFinal1;
    this.shipmentTerrestre.SegundaHoraRecogidaInicio = this.formModelTerrestre.value.HoraRecogidaInicio2;
    this.shipmentTerrestre.SegundaHoraRecogidaFinal = this.formModelTerrestre.value.HoraRecogidaFinal2;
    this.shipmentTerrestre.PersonaContactoRecogida = form.value.PersonaContactoRecogida;
    this.shipmentTerrestre.TelefonoRecogida = form.value.TelefonoRecogida;
    this.shipmentTerrestre.EmailRecogida = this.formModelTerrestre.value.EmailRecogida;
    //DespachoAduanas
    this.shipmentTerrestre.DespachoOrigen = this.formModelTerrestre.value.DespachoOrigen != undefined ? this.formModelTerrestre.value.DespachoOrigen : null;
    this.shipmentTerrestre.DespachoDestino = this.formModelTerrestre.value.DespachoDestino != undefined ? this.formModelTerrestre.value.DespachoDestino : null;

    //Entrega
    /*if(this.incotermStartWith())
    {*/
    this.shipmentTerrestre.IdEntidadEntrega = this.formModelTerrestre.value.Consignee?.IdEntidad;
    this.shipmentTerrestre.IdDireccionEntrega = this.formModelTerrestre.value.EntidadEntrega.IdDireccion;
    this.shipmentTerrestre.ReferenciaEntrega = form.value.ReferenciaEntrega;
    this.shipmentTerrestre.InstruccionesEntrega = form.value.InstruccionesEntrega;
    this.shipmentTerrestre.PersonaContactoEntrega = form.value.PersonaContactoEntrega;
    this.shipmentTerrestre.TelefonoEntrega = form.value.TelefonoEntrega;
    this.shipmentTerrestre.EmailEntrega = this.formModelTerrestre.value.EmailEntrega;
    this.shipmentTerrestre.PrimeraHoraEntregaInicio = this.formModelTerrestre.value.HoraEntregaInicio1;
    this.shipmentTerrestre.PrimeraHoraEntregaFinal = this.formModelTerrestre.value.HoraEntregaFinal1;
    this.shipmentTerrestre.SegundaHoraEntregaInicio = this.formModelTerrestre.value.HoraEntregaInicio2;
    this.shipmentTerrestre.SegundaHoraEntregaFinal = this.formModelTerrestre.value.HoraEntregaFinal2;
    this.shipmentTerrestre.FechaEntrega = this.formModelTerrestre.value.FechaEntrega;
    //}

    //Totales mercancia
    if (this.formModelTerrestre.value.PesoNetoTotalDGR == null || this.formModelTerrestre.value.PesoNetoTotalDGR == '') {
      this.shipmentTerrestre.PesoNetoTotalDGR = 0;
    }
    else {
      this.shipmentTerrestre.PesoNetoTotalDGR = Number(this.formModelTerrestre.value.PesoNetoTotalDGR.toString().replace(',', '.'));
    }
    if (this.formModelTerrestre.value.PesoBrutoTotalDGR == null || this.formModelTerrestre.value.PesoBrutoTotalDGR == '') {
      this.shipmentTerrestre.PesoBrutoTotalDGR = 0;
    }
    else {
      this.shipmentTerrestre.PesoBrutoTotalDGR = Number(this.formModelTerrestre.value.PesoBrutoTotalDGR.toString().replace(',', '.'));
    }

    if (this.formModelTerrestre.value.BultosTotales == null || this.formModelTerrestre.value.BultosTotales == '') {
      this.shipmentTerrestre.BultosTotales = 0;
    }
    else {
      this.shipmentTerrestre.BultosTotales = Number(this.formModelTerrestre.value.BultosTotales.toString().replace(',', '.'));
    }

    if (this.formModelTerrestre.value.PesoNetoTotal == null || this.formModelTerrestre.value.PesoNetoTotal == '') {
      this.shipmentTerrestre.PesoNetoTotal = 0;
    }
    else {
      this.shipmentTerrestre.PesoNetoTotal = Number(this.formModelTerrestre.value.PesoNetoTotal.toString().replace(',', '.'));
    }

    if (this.formModelTerrestre.value.PesoBrutoTotal == null || this.formModelTerrestre.value.PesoBrutoTotal == '') {
      this.shipmentTerrestre.PesoBrutoTotal = 0;
    }
    else {
      this.shipmentTerrestre.PesoBrutoTotal = Number(this.formModelTerrestre.value.PesoBrutoTotal.toString().replace(',', '.'));
    }

    if (form.value.VolumenTotal == '') {
      this.shipmentTerrestre.VolumenTotal = 0;
    }
    else {
      this.shipmentTerrestre.VolumenTotal = Number(form.value.VolumenTotal.toString().replace(',', '.'));
    }
    this.shipmentTerrestre.PesoTasable = Number(this.formModelTerrestre.value.PesoTasable.toString().replace(',', '.'));
    //Descripcion adicionales
    this.shipmentTerrestre.Marcas = form.value.Marcas;
    this.shipmentTerrestre.Instrucciones = this.formModelTerrestre.value.Instrucciones;
    if (this.archivoBooking.Base64image != null && this.archivoBooking.Base64image != undefined && this.archivoBooking.Base64image != '')
      this.listaArchivos.push(this.archivoBooking);
    this.shipmentTerrestre.Archivos = this.listaArchivos;
    this.shipmentTerrestre.Mercancia = this.listaMercancia;
  }

  verDocumentos() {
    this.envioService.setIdEnvio(this.formModelTerrestre.value.Id);
    this.router.navigate(["/customer/shipment/documents"]);
  }

  setMercPeligrosa(form) {
    if (this.formModelTerrestre.value.MercanciaPeligrosa == true || this.formModelTerrestre.value.Mercancia == 1) {
      this.formModelTerrestre.value.MercanciaPeligrosa = false;
      if (this.listaMercancia != undefined) {
        this.listaMercancia.forEach(element => {
          element.MercanciaPeligrosa = [];
        })
      }
    }
    else {
      this.formModelTerrestre.value.MercanciaPeligrosa = true;
    }
  }

  openModalSM(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalConsignee(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalLG(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal2(template: TemplateRef<any>, tipoEntidad: string) {
    this.tipoEntidad = tipoEntidad;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
    this.setDireccion(tipoEntidad);
  }

  openAlert(template: TemplateRef<any>, form: NgForm) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.form = form;
    this.modalRef = this.modalService.show(template, config);
  }

  openAlert2(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  setDireccion(remitenteDestinatario: string) {
    this.tipoDireccion = remitenteDestinatario;
    if (this.tipoDireccion == "consigneeEntity") {
      this.entidadService.setEntity$(this.formModelTerrestre.value.Consignee);
    }
    else if (this.tipoDireccion == "shipperEntity")
      this.entidadService.setEntity$(this.formModelTerrestre.value.Shipper);
  }

  getDireccion(direccion: Direccion) {
    if (direccion != null) {
      if (this.tipoDireccion == 'shipper') {
        this.listShipper.forEach(shipper => {
          if (shipper.IdEntidad == direccion.IdEntidad) {
            this.formModelTerrestre.value.EntidadRecogida = shipper;
            this.setDireccionRecogidaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaRecogida').focus();
            }, 0);
          }
        })
      }
      else if (this.tipoDireccion == 'consignee') {
        this.listConsignee.forEach(consignee => {
          if (consignee.IdEntidad == direccion.IdEntidad) {
            this.formModelTerrestre.value.EntidadEntrega = consignee;
            this.setDireccionEntregaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaEntrega').focus();
            }, 0);
          }
        })
      }
      else if (this.tipoDireccion == 'shipperEntity') {
        this.listShipper.forEach(shipper => {
          if (shipper.IdEntidad == direccion.IdEntidad) {
            this.formModelTerrestre.value.EntidadRecogida = shipper;
            this.formModelTerrestre.value.Shipper = shipper;
            this.setDireccionShipperRecogida(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaRecogida').focus();
            }, 0);
          }
        });
      } else if (this.tipoDireccion == 'consigneeEntity') {
        this.listConsignee.forEach(consignee => {
          if (consignee.IdEntidad == direccion.IdEntidad) {
            this.formModelTerrestre.value.EntidadEntrega = consignee;
            this.formModelTerrestre.value.Consignee = consignee;
            this.setDireccionEntregaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaEntrega').focus();
            }, 0);
          }
        });
      }
      else
        this.modalRef.hide();
    }
    else
      this.modalRef.hide();
  }



  setDireccionConsigneeEntrega(direccion: Direccion) {
    this.direccionConsigneeEntrega = '';
    this.formModelTerrestre.value.IdDireccionEntrega = direccion.IdDireccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion) {
        this.direccionConsigneeEntrega = direccion.Direccion;
      }
      if (direccion.CodigoPostal != null && direccion.CodigoPostal != undefined && direccion.CodigoPostal != '') {
        this.direccionConsigneeEntrega += "\r\n" + direccion.CodigoPostal
      }
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined) {
        this.direccionConsigneeEntrega += ", " + direccion.NombreLocalidad;
      }
      if (direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '') {
        this.direccionConsigneeEntrega += ", " + direccion.NombrePoblacion;
      }
      if (direccion.NombrePais != undefined && direccion.NombrePais != null && direccion.NombrePais != "") {
        this.direccionConsigneeEntrega += "\r\n" + direccion.NombrePais;
      }
    }
    else {
      this.direccionConsigneeEntrega = "";
    }
    this.formModelTerrestre.value.DireccionConsignee = this.direccionConsigneeEntrega;
  }

  setDireccionRecogidaInformation(direccion: Direccion) {
    this.resumenDireccionRecogida = '';
    this.formModelTerrestre.value.DireccionRecogida = direccion.IdDireccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion)
        this.resumenDireccionRecogida = direccion.Direccion;
      if (direccion.CodigoPostal != null && direccion.CodigoPostal != undefined && direccion.CodigoPostal != '')
        this.resumenDireccionRecogida += "\r\n" + direccion.CodigoPostal;
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined)
        this.resumenDireccionRecogida += ", " + direccion.NombreLocalidad;
      if (direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '')
        this.resumenDireccionRecogida += ", " + direccion.NombrePoblacion;
      if (direccion.NombrePais != undefined && direccion.NombrePais != null && direccion.NombrePais != "")
        this.resumenDireccionRecogida += "\r\n" + direccion.NombrePais;
    }
    else
      this.resumenDireccionRecogida = "";
  }

  //DireccionShipper
  setDireccionShipperRecogida(direccion: Direccion) {
    this.direccionShipperRecogida = '';
    this.formModelTerrestre.value.DireccionRecogida = direccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion)
        this.direccionShipperRecogida = direccion.Direccion;
      if (direccion.CodigoPostal != null && direccion.CodigoPostal != undefined && direccion.CodigoPostal != '')
        this.direccionShipperRecogida += "\r\n" + direccion.CodigoPostal;
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined)
        this.direccionShipperRecogida += ", " + direccion.NombreLocalidad;
      if (direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '')
        this.direccionShipperRecogida += ", " + direccion.NombrePoblacion;
      if (direccion.NombrePais != undefined && direccion.NombrePais != null && direccion.NombrePais != "")
        this.direccionShipperRecogida += "\r\n" + direccion.NombrePais;
    }
    else
      this.direccionShipperRecogida = "";
    this.formModelTerrestre.value.DireccionShipper = this.direccionShipperRecogida;
  }

  setDireccionEntregaInformation(direccion: Direccion) {
    this.resumenDireccionEntrega = '';
    this.formModelTerrestre.value.DireccionEntrega = direccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion)
        this.resumenDireccionEntrega = direccion.Direccion + ", " + direccion.Numero;
      if (direccion.CodigoPostal != null && direccion.CodigoPostal != undefined && direccion.CodigoPostal != '')
        this.resumenDireccionEntrega += "\r\n" + direccion.CodigoPostal;
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined)
        this.resumenDireccionEntrega += ", " + direccion.NombreLocalidad;
      if (this.resumenDireccionEntrega != "")
        this.resumenDireccionEntrega += ", ";
      if (direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '')
        this.resumenDireccionEntrega += direccion.NombrePoblacion;
      if (direccion.NombrePais != undefined && direccion.NombrePais != null && direccion.NombrePais != "")
        this.resumenDireccionEntrega += "\r\n" + direccion.NombrePais;
    }
    else
      this.resumenDireccionEntrega = "";
    this.formModelTerrestre.value.DireccionConsignee = this.resumenDireccionEntrega;
  }

  getPlantilla(envioPredeterminado: Shipment) {
    if (envioPredeterminado == null)
      this.modalRef.hide();
    else {
      envioPredeterminado.FechaRecogida = null;
      this.modalRef.hide();
      this.asignarValores(envioPredeterminado);
    }
  }

  incotermStartWith(): Boolean {
    if (this.formModelTerrestre.value.Incoterm != '' && this.formModelTerrestre.value.Incoterm != undefined)
      if (this.formModelTerrestre.value.Incoterm.startsWith('D') || this.formModelTerrestre.value.Incoterm == "FCA" || this.formModelTerrestre.value.Incoterm == "EXW")
        return true;
    return false;
  }

  mostrarAvisoCambioSeguro() {
    if (this.formModelTerrestre.value.Asegurado == true) {
      var body = this.translateService.instant("AvisoCambioSeguroBody");
      var title = this.translateService.instant("AvisoCambioSeguroTitle");
      this.toastr.info(body, title);
    }
  }

  calcularSeguro() {
    var value = 0;
    if (this.formModelTerrestre.value.ValorMercancia != '') {
      // Valor de la mercancia * 110% + Transporte (1600€)
      value = (Number(this.formModelTerrestre.value.ValorMercancia) * 1.1 + 1600);
      if (this.formModelTerrestre.value.MercanciaPeligrosa == true) // Si es mercancia peligrosa por 0.4%
        value = value * 0.004;
      else // Si no es mercancia peligrosa por 0.3%
        value = value * 0.003;
      if (value > 30) // Redondeamos a 2 decimales
        this.formModelTerrestre.value.SeguroMercancia = Number(value.toFixed(3));
      else // Si es menor, ponemos mínimo 30 (antes era 25 30/07/2021)
        this.formModelTerrestre.value.SeguroMercancia = 30;
    }
    else
      this.formModelTerrestre.value.SeguroMercancia = '';
  }

  getFactorConversionVolumen(): number {
    let factorConversion: number = 0;
    if (this.formModelTerrestre.value.ModoEnvio == 5) //COMPLETO
    {
      factorConversion = this.formModelTerrestre.value.VolumenTotal * 333;
    }
    else //GRUPAJE Y PARCIAL
    {
      factorConversion = this.formModelTerrestre.value.VolumenTotal * 250;
    }
    return factorConversion;
  }

  //Funcion que calcula el peso tasable (formula particular de Terrestre)
  calcularPesoTasable() {
    let volumenCalculado = this.getFactorConversionVolumen();

    if (this.formModelTerrestre.value.PesoBrutoTotal != null && this.formModelTerrestre.value.VolumenTotal != null) {
      if (this.formModelTerrestre.value.PesoBrutoTotal > volumenCalculado)
        this.formModelTerrestre.value.PesoTasable = Number(this.formModelTerrestre.value.PesoBrutoTotal.toFixed(3));
      else
        this.formModelTerrestre.value.PesoTasable = Number(volumenCalculado.toFixed(3));
    }
  }

  //Funcion que replica el numero de referencia en las referencias de carga y entrega si no son iguales
  replicaReferencias(form: NgForm) {
    if (this.formModelTerrestre.value.ReferenciaRecogida == this.referenciaClienteAnterior || this.formModelTerrestre.value.ReferenciaRecogida == '') {
      this.formModelTerrestre.value.ReferenciaRecogida = this.formModelTerrestre.value.Referencia;
      form.value.ReferenciaRecogida = this.formModelTerrestre.value.Referencia;
      if (this.formModelTerrestre.value.ReferenciaRecogida != this.referenciaClienteAnterior) {
        this.compruebaReferenciaRecogida(form);
      }
    }
    if (this.formModelTerrestre.value.ReferenciaEntrega == this.referenciaClienteAnterior || this.formModelTerrestre.value.ReferenciaEntrega == '') {
      this.formModelTerrestre.value.ReferenciaEntrega = this.formModelTerrestre.value.Referencia;
      form.value.ReferenciaEntrega = this.formModelTerrestre.value.Referencia;
      if (this.formModelTerrestre.value.ReferenciaEntrega != this.referenciaClienteAnterior) {
        this.compruebaReferenciaEntrega(form);
      }
    }
    this.referenciaClienteAnterior = this.formModelTerrestre.value.Referencia;
  }

  //Funcion que se utiliza para obtener la respuesta del modal de confirmacion del seguro
  //esta respuesta si es afirmativa se realiza el envio Terrestre
  confirmacionSeguro(confirmacion: boolean) {
    this.modalRef.hide();
    if (confirmacion == true) {
      this.envioTerrestre(this.form);
    }
  }

  //Funcion que se utiliza para obtener la respuesta del modal de confirmacion de guardado de plantilla
  //si la respuesta es afirmativa guardamos el formulario como plantilla
  confirmacionPlantilla(confirmacion: boolean) {
    this.modalRef.hide();
    if (confirmacion == true) {
      //guardamos plantilla
      this.asignarFormAPlantilla();
      var log: LogModel = new LogModel();
      log.Click = "enviarPlantilla";
      log.Pantalla = this.clase;
      this.envioService.postPlantilla(this.shipmentTerrestre, log).subscribe(data => {
        var Body = this.translateService.instant("Se ha añadido la plantilla correctamente.");
        var Titulo = this.translateService.instant("Plantilla guardada");
        this.toastr.success(Body, Titulo)
      });
    }
    this.shipmentTerrestre = new Shipment();
  }

  asignarFormAPlantilla() {
    this.convertFormToModel(this.formModelTerrestre)
  }

  //Funciones que comprueban que los input sean validos para remarcarlos como error
  compruebaTipoEnvio(form: NgForm): Boolean {
    if (form.value.TipoEnvio == 1 || form.value.TipoEnvio == null) {
      this.errorTipoEnvio = true;
    }
    else {
      this.errorTipoEnvio = false;
    }
    return this.errorTipoEnvio;
  }

  compruebaModoEnvio(form: NgForm): Boolean {
    if (form.value.ModoEnvio == null) {
      this.errorModoEnvio = true;
    }
    else {
      this.errorModoEnvio = false;
    }
    return this.errorModoEnvio;
  }

  compruebaReferencia(form?: NgForm): Boolean {
    if (this.formModelTerrestre.value.Referencia == '') {
      this.errorReferencia = true;
    }
    else {
      this.errorReferencia = false;
    }
    return this.errorReferencia;
  }

  compruebaListShipper(form: NgForm): Boolean {
    if (form.value.ListShipper == '') {
      this.errorListShipper = true;
    }
    else {
      this.errorListShipper = false;
    }
    return this.errorListShipper;
  }

  compruebaListConsignee(form: NgForm): Boolean {
    if (form.value.ListConsignee == '' || form.value.ListConsignee == null || form.value.ListConsignee == undefined)
      this.errorListConsignee = true;
    else
      this.errorListConsignee = false;

    return this.errorListConsignee;
  }

  compruebaIncoterm(form: NgForm): Boolean {
    if (form.value.Incoterm == '')
      this.errorIncoterm = true;
    else
      this.errorIncoterm = false;
    return this.errorIncoterm;
  }

  compruebaBultosTotales(form?: NgForm): Boolean {
    if (this.formModelTerrestre.value.BultosTotales == null || this.formModelTerrestre.value.BultosTotales == 0)
      this.errorBultosTotales = true;
    else
      this.errorBultosTotales = false;
    return this.errorBultosTotales;
  }

  compruebaTelefonoRecogida(form: NgForm): Boolean {
    this.errorTelefonoRecogida = true;
    if (form.value.Shipper != undefined) {
      if (form.value.Shipper.IdEntidad != 20) {
        if (form.value.TelefonoRecogida == '' || form.value.TelefonoRecogida == null)
          this.errorTelefonoRecogida = true;
        else
          this.errorTelefonoRecogida = false;
      } else {
        if (form.value.TelefonoRecogida == '' || form.value.TelefonoRecogida == null)
          this.errorTelefonoRecogida = true;
        else
          this.errorTelefonoRecogida = false;
      }
    }
    return this.errorTelefonoRecogida;
  }

  compruebaPersonaContactoRecogida(form: NgForm): Boolean {
    this.errorPersonaContactoRecogida = true;
    if (form.value.Shipper != undefined) {
      if (form.value.Shipper.IdEntidad != 20) {
        if (form.value.PersonaContactoRecogida == '')
          this.errorPersonaContactoRecogida = true;
        else
          this.errorPersonaContactoRecogida = false;
      } else {
        if (form.value.PersonaContactoRecogida == '')
          this.errorPersonaContactoRecogida = true;
        else
          this.errorPersonaContactoRecogida = false;
      }

    }
    return this.errorPersonaContactoRecogida;
  }

  esEditable() {
    return !(this.esFTL && (this.visor || this.isCotizacion)) || !(this.esFTL && !(this.visor || this.isCotizacion));
  }

  esEditableCotizacion() {
    return !(this.esFTL && (this.visor || this.isCotizacion));
  }

  compruebaEmailRecogida(form: NgForm): Boolean {
    this.errorEmailRecogida = true;
    if (form.value.Shipper != undefined) {
      if (form.value.Shipper.IdEntidad != 20) {
        if (form.value.EmailRecogida == '')
          this.errorEmailRecogida = true;
        else
          this.errorEmailRecogida = false;
      } else {
        if (form.value.EmailRecogida == '')
          this.errorEmailRecogida = true;
        else
          this.errorEmailRecogida = false;
      }
    }
    return this.errorEmailRecogida;
  }

  compruebaPesoNetoTotal(form?: NgForm): Boolean {
    // this.formModelTerrestre.value.PesoNetoTotal = Number(this.formModelTerrestre.value.PesoNetoTotal.toString().replace(',', '.'));
    // if(this.formModelTerrestre.value.PesoNetoTotal == null || this.formModelTerrestre.value.PesoNetoTotal == 0)
    // {
    //   this.errorPesoNetoTotal = true;
    // }
    // else
    // {
    this.errorPesoNetoTotal = false;
    // }
    return this.errorPesoNetoTotal;
  }

  compruebaPesoBrutoTotal(form?: NgForm): Boolean {
    this.errorPesoBrutoTotal = true;
    if (this.formModelTerrestre.value.PesoBrutoTotal != undefined && this.formModelTerrestre.value.PesoBrutoTotal != null) {
      this.formModelTerrestre.value.PesoBrutoTotal = Number(this.formModelTerrestre.value.PesoBrutoTotal.toString().replace(',', '.'));
      if (this.formModelTerrestre.value.PesoBrutoTotal == null || this.formModelTerrestre.value.PesoBrutoTotal == 0)
        this.errorPesoBrutoTotal = true;
      else
        this.errorPesoBrutoTotal = false;
    }
    return this.errorPesoBrutoTotal;
  }

  /*
  compruebaPesoBrutoTotalDGR(form: NgForm): Boolean {
    this.errorPesoBrutoTotalDGR = false;
    this.errorDiferenciaPesosDGR = false;
    this.muestraErrorBrutoDGR = false;

    if(this.formModelTerrestre.value.PesoBrutoTotalDGR == 0 && this.formModelTerrestre.value.MercanciaPeligrosa == true)
    {
      this.errorPesoBrutoTotalDGR = true;
      this.muestraErrorBrutoDGR = true;
    }
    else if(this.formModelTerrestre.value.PesoBrutoTotalDGR == null && this.formModelTerrestre.value.MercanciaPeligrosa == true)
    {
      this.errorPesoBrutoTotalDGR = true;
      this.errorDiferenciaPesosDGR = false;
      this.muestraErrorBrutoDGR = true;
    }
    else if(this.formModelTerrestre.value.PesoNetoTotalDGR != 0 && this.formModelTerrestre.value.PesoNetoTotalDGR != null)
    {
      if(this.formModelTerrestre.value.PesoNetoTotalDGR >= this.formModelTerrestre.value.PesoBrutoTotalDGR)
      {
        this.errorDiferenciaPesosDGR = true;
        this.errorPesoBrutoTotalDGR = true;
        this.muestraErrorBrutoDGR = true;
      }
      else if(this.formModelTerrestre.value.PesoBrutoTotalDGR > this.formModelTerrestre.value.PesoBrutoTotal)
      {
        this.errorPesoBrutoTotalDGR = true;
        this.errorDiferenciaPesosDGR = true;
        this.muestraErrorBrutoDGR = true;
        this.errorPesoNetoTotalDGR = true;
        this.muestraErrorNetoDGR = true;
      }
    }
    return this.errorPesoBrutoTotalDGR;
  }
  */

  compruebaPesoNetoTotalDGR(form?: NgForm): Boolean {
    this.errorPesoNetoTotalDGR = false;
    //this.errorDiferenciaPesosDGR = false;
    // this.muestraErrorNetoDGR = false;
    // if(this.formModelTerrestre.value.MercanciaPeligrosa == true)
    // {
    //   if(this.formModelTerrestre.value.PesoNetoTotalDGR == 0)
    //   {
    //     this.errorPesoNetoTotalDGR = true;
    //     this.muestraErrorNetoDGR = true;
    //   }
    //   else if (this.formModelTerrestre.value.PesoNetoTotalDGR == null)
    //   {
    //     this.errorPesoNetoTotalDGR = true;
    //     this.muestraErrorNetoDGR = true;
    //   }
    // }
    // else
    // {
    //   this.errorPesoNetoTotalDGR = false;
    //   this.muestraErrorNetoDGR = false;
    // }
    /*
    else if(this.formModelTerrestre.value.PesoBrutoTotalDGR != 0 && this.formModelTerrestre.value.PesoBrutoTotalDGR != null)
    {
      if(this.formModelTerrestre.value.PesoNetoTotalDGR > this.formModelTerrestre.value.PesoNetoTotal)
      {
        //this.errorDiferenciaPesosDGR = true;
        this.errorPesoNetoTotalDGR = true;
        this.muestraErrorNetoDGR = true;
      }
      else if(this.formModelTerrestre.value.PesoBrutoTotalDGR <= this.formModelTerrestre.value.PesoNetoTotalDGR)
      {
        this.errorPesoNetoTotalDGR = true;
        //this.errorDiferenciaPesosDGR = true;
        this.muestraErrorNetoDGR = true;
        this.errorPesoBrutoTotalDGR = true;
        this.muestraErrorBrutoDGR = true;
      }
    }
    */
    return this.errorPesoNetoTotalDGR;
  }

  // compruebaNoRemontable(form?: NgForm): Boolean {
  //   if(this.formModelTerrestre.value.NoRemontable == null || this.formModelTerrestre.value.NoRemontable == undefined)
  //     this.errorNoRemontable = true;
  //   else
  //     this.errorNoRemontable = false;
  //   return this.errorNoRemontable;
  // }

  compruebaVolumenTotal(form?: NgForm): Boolean {
    if (this.formModelTerrestre.value.VolumenTotal == null || this.formModelTerrestre.value.VolumenTotal == 0)
      this.errorVolumenTotal = true;
    else
      this.errorVolumenTotal = false;
    return this.errorVolumenTotal;
  }

  compruebaFechaRecogida(form: NgForm): Boolean {
    if (form.value.FechaRecogida == '') {
      this.errorFechaRecogida = true;
      this.errorFechaRecogidaMenorActual = false;
    }
    else
      this.errorFechaRecogida = false;
    if (this.validarFechaRecogida()) {
      this.errorFechaRecogidaMenorActual = true;
      var Body = this.translateService.instant("El campo fecha disponibilidad debe ser igual o superior a la fecha de hoy");
      var Titulo = this.translateService.instant("Error en fecha disponibilidad");
      this.toastr.error(Body, Titulo)
    }
    else {
      this.errorFechaRecogidaMenorActual = false;
    }
    return this.errorFechaRecogida;
  }

  compruebaFechaEntrega(form: NgForm): Boolean {
    if (form.value.FechaEntrega == '' || form.value.FechaEntrega == null) {
      this.errorFechaEntrega = false;
    }
    else {
      if (this.validarFechaEntrega()) {
        this.errorFechaEntrega = true;
        var Body = this.translateService.instant("El campo fecha Entrega debe ser igual o superior a la fecha de hoy");
        var Titulo = this.translateService.instant("Error en fecha Entrega");
        this.toastr.error(Body, Titulo);
      }
      else {
        this.errorFechaEntrega = false;
      }
    }

    return this.errorFechaEntrega;
  }

  compruebaFechasEntregaRecogida(form: NgForm) {
    if (this.formModelTerrestre.value.FechaEntrega == '' || this.formModelTerrestre.value.FechaEntrega == null) {
      this.errorFechaEntregaRecogidaMenor = false
      this.errorFechaEntrega = false;
    }
    else {
      if (this.compararFechasDisponEntrega()) {
        this.errorFechaEntregaRecogidaMenor = true;
        var Body = this.translateService.instant("El campo fecha entrega debe ser igual o superior a la fecha de recogida");
        var Titulo = this.translateService.instant("Error en fecha Entrega");
        this.toastr.error(Body, Titulo);
      }
      else {
        this.errorFechaEntregaRecogidaMenor = false;
      }
    }
  }
  compararFechasDisponEntrega() {
    var fechaRecogida: Date = new Date(this.formModelTerrestre.value.FechaRecogida);
    var fechaEntrega: Date = new Date(this.formModelTerrestre.value.FechaEntrega);
    if ((fechaEntrega.getTime()) < fechaRecogida.getTime()) {
      return true;
    }
    else {
      return false;
    }
  }

  validarFechaEntrega(): Boolean {
    var fechaForm: Date = new Date(this.formModelTerrestre.value.FechaEntrega)
    var fechaHoy: Date = new Date();
    if ((fechaForm.getTime() + 86400000) < fechaHoy.getTime()) {
      return true;
    }
    else {
      return false;
    }

  }

  validarFechaRecogida(): Boolean {
    var fechaForm: Date = new Date(this.formModelTerrestre.value.FechaRecogida)
    var fechaHoy: Date = new Date();
    if ((fechaForm.getTime() + 86400000) < fechaHoy.getTime()) {
      return true;
    }
    else {
      return false;
    }

  }

  compruebaPersonaContactoEntrega(form: NgForm): Boolean {
    this.errorPersonaContactoEntrega = true;
    if (form.value.Consignee != undefined) {
      if (form.value.Consignee.IdEntidad != 20) {
        if (form.value.PersonaContactoEntrega == '')
          this.errorPersonaContactoEntrega = true;
        else
          this.errorPersonaContactoEntrega = false;
      }
    } else {
      if (form.value.PersonaContactoEntrega == '')
        this.errorPersonaContactoEntrega = true;
      else
        this.errorPersonaContactoEntrega = false;
    }
    return this.errorPersonaContactoEntrega;
  }

  compruebaTelefonoEntrega(form: NgForm): Boolean {
    if (form.value.Consignee != undefined) {
      if (form.value.Consignee != null && form.value.Consignee != undefined && form.value.Consignee.IdEntidad != 20) {
        if (form.value.TelefonoEntrega == '')
          this.errorTelefonoEntrega = true;
        else
          this.errorTelefonoEntrega = false;
      }
    } else {
      if (form.value.TelefonoEntrega == '')
        this.errorTelefonoEntrega = true;
      else
        this.errorTelefonoEntrega = false;
    }
    return this.errorTelefonoEntrega;
  }

  compruebaEmailEntrega(form: NgForm): Boolean {
    if (form.value.Consignee != undefined) {
      if (form.value.Consignee.IdEntidad != 20) {
        if (form.value.EmailEntrega == '')
          this.errorEmailEntrega = true;
        else
          this.errorEmailEntrega = false;
      }
    } else {
      if (form.value.EmailEntrega == '')
        this.errorEmailEntrega = true;
      else
        this.errorEmailEntrega = false;
    }
    return this.errorEmailEntrega;
  }

  compruebaMercancia(): Boolean {

    if (this.listaMercancia.length == 0 || this.listaMercancia == undefined)
      this.errorMercancia = true;
    else
      this.errorMercancia = false;
    return this.errorMercancia;
  }

  compruebaEntidadRecogida(form: NgForm): Boolean {
    this.errorEntidadRecogida = false;

    /*if(form.value.EntidadRecogida == null)
    {
      this.errorEntidadRecogida = true;
    }
    else
    {
      this.errorEntidadRecogida = false;
    }*/
    return this.errorEntidadRecogida;
  }

  compruebaEntidadEntrega(form: NgForm): Boolean {
    if (form.value.EntidadEntrega == null) {
      this.errorEntidadEntrega = true;
    }
    else {
      this.errorEntidadEntrega = false;
    }
    return this.errorEntidadEntrega;
  }

  compruebaValorMercancia(form: NgForm) {
    this.formModelTerrestre.value.ValorMercancia = Number(this.formModelTerrestre.value.ValorMercancia.toString().replace(',', '.'));
    form.value.ValorMercancia = Number(this.formModelTerrestre.value.ValorMercancia.toString().replace(',', '.'));
    if (form.value.Asegurado == true) {
      if (form.value.ValorMercancia == '' || form.value.ValorMercancia == null)
        this.errorValorMercancia = true;
      else
        this.errorValorMercancia = false;
    } else
      this.errorValorMercancia = false;
    return this.errorValorMercancia;
  }

  compruebaReferenciaRecogida(form: NgForm) {
    if (form.value.ReferenciaRecogida == '') {
      this.errorReferenciaRecogida = true;
    }
    else {
      this.errorReferenciaRecogida = false;
    }
    return this.errorReferenciaRecogida;
  }

  compruebaReferenciaEntrega(form: NgForm) {

    if (form.value.ReferenciaEntrega == '') {
      this.errorReferenciaEntrega = true;
    }
    else {
      this.errorReferenciaEntrega = false;
    }
    return this.errorReferenciaEntrega;
  }

  recibirSearch(search) {
    this.searchText = search;
  }

  // setNoRemontable(noRemontable)
  // {
  //   this.formModelTerrestre.value.NoRemontable = noRemontable.value;
  //   this.errorNoRemontable = false;
  // }


  cerrar() {
    this.modalRef.hide();

    window.setTimeout(function () {
      document.getElementById('ValorMercancia').focus();
    }, 0);
  }

  obtenerDireccionEntidad(direccion: Direccion) {
    if (direccion == null) {
      if (this.tipoDireccion == 'shipper') {
        window.setTimeout(function () {
          document.getElementById('ReferenciaRecogida').focus();
        }, 0);
      }
      else if (this.tipoDireccion == 'consignee') {
        window.setTimeout(function () {
          document.getElementById('ReferenciaEntrega').focus();
        }, 0);
      }
      this.modalRef.hide();
    }
    else {
      if (this.tipoDireccion == 'shipper') {
        //TODO: no me acuerdo
        window.setTimeout(function () {
          document.getElementById('ReferenciaRecogida').focus();
        }, 0);
      }
      else if (this.tipoDireccion == 'consignee') {
        //TODO: no me acuerdo
        window.setTimeout(function () {
          document.getElementById('ReferenciaEntrega').focus();
        }, 0);
      }
      this.modalRef.hide();
    }
  }

  recogidaRequired(form: NgForm): Boolean {
    if (form != undefined) {
      if (form.value.EntidadRecogida != undefined) {
        if (form.value.EntidadRecogida.IdEntidad == 20) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    return false;
  }


  entregaRequired(form: NgForm): Boolean {
    if (form != undefined) {
      if (form.value.EntidadEntrega != undefined) {
        if (form.value.EntidadEntrega.IdEntidad == 20) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    return false;
  }

  obtenerEntidad(entidad: Entity) {
    this.entidad = entidad;
    this.modalRef.hide();
  }

  esEspanyaItalia() {
    return ((this.corresponsal != undefined && this.corresponsal != null) && (this.corresponsal.IdDelegacion == 1 || this.corresponsal.IdDelegacion == 4));
  }

  refresEntidades(actualizado: boolean) {
    if (actualizado) {
      //Si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
      this.envioService.getEntidadesAddressBook().subscribe(data => {
        this.listShipper = data;
        this.listConsignee = data;
        // this.entidadService.getEntidadById(this.entidadCliente).subscribe(entidadCliente => {
        //   this.listShipper = data;
        //   this.listConsignee = data;
        //   if (this.esExport()) {
        //     this.listShipper.push(entidadCliente);
        //     this.listShipper.forEach(shipper => {
        //       this.formModelTerrestre.value.Shipper = shipper;
        //       this.formModelTerrestre.value.EntidadRecogida = shipper;
        //       this.formModelTerrestre.value.Cliente = shipper;
        //       this.setShipperInformation();

        //       if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
        //         this.visor = this.envioService.getVisor();
        //         if (this.envio != undefined) {
        //           this.asignarValores(this.envio);
        //         }
        //       }
        //     });
        //   }
        //   else {
        //     this.listConsignee.push(entidadCliente);
        //     this.listConsignee.forEach(consignee => {
        //       this.formModelTerrestre.value.Shipper = consignee;
        //       this.formModelTerrestre.value.EntidadRecogida = consignee;
        //       this.formModelTerrestre.value.Cliente = consignee;
        //       this.setShipperInformation();

        //       if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
        //         this.visor = this.envioService.getVisor();
        //         if (this.envio != undefined) {
        //           this.asignarValores(this.envio);
        //         }
        //       }
        //     });
        //   }
        //   this.cargaRemitente = this.cargaDestinatario = false;
        //   this.cargaEntidadRecogida = this.cargaEntidadEntrega = false;
        // });
      });
      this.cargarListaNotify();
    }
  }

  compruebaDuplicadoReferenciaCliente() {
    if (this.formModelTerrestre.value.Referencia != null && this.formModelTerrestre.value.Referencia != '' && this.formModelTerrestre.value.Referencia != undefined) {
      this.envioService.compruebaRefCliDuplicadosTerrestre(this.formModelTerrestre.value.Referencia).subscribe(data => {
        this.refCliDuplicado = data;
        if (this.refCliDuplicado)
          this.toastr.info(this.translateService.instant("BodyRefDuplicadoTerrestre"), this.translateService.instant("TitleRefDuplicadoTerrestre"));
      })
    }
  }

  cambiarEstadoDireccion(checked: boolean, quien: string) {
    if (quien == 'shipper') {
      this.readonlyDireccionShipper = checked;
    }
    else if (quien == 'consignee') {
      this.readonlyDireccionConsignee = checked;
    }
    else if (quien == 'notify') {
      this.readonlyDireccionNotify = checked;
    }
  }

  getTodayString() {
    var mes = (this.today.getMonth() + 1).toString();
    if (mes.length == 1)
      mes = "0" + mes;
    return this.today.getFullYear() + "-" + mes + "-" + this.today.getDate();
  }

  obtenerRespuesta(respuesta: boolean) {
    if (respuesta)
      this.router.navigateByUrl("/customer");
    this.modalRef.hide();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('-');
  }

  // tslint:disable-next-line:typedef
  downloadPDF() {
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    // Asignamos en DATA el contenido del html que tiene como id 'tracking'
    const DATA = document.getElementById('resumen');
    // Damos formato al documento: p (portrait = vertical), pt (la unidad de media), a4 (tamaño del pdf)
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
      //pagesplit: true
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('application/pdf');
      //const img = canvas.toDataURL('image/png');
      //Add image Canvas to PDF
      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      return doc;
    }).then((docResult) => {
      docResult.save('ebooking_' + this.formModelTerrestre.value.Referencia + '_' + this.formatDate(new Date()) + '.pdf');
      var archivo = new Archivo();
      archivo.Base64image = docResult.output('datauristring');
      archivo.FileName = 'ebooking_' + this.formModelTerrestre.value.Referencia + '_' + this.formatDate(new Date()) + '.pdf';
      archivo.Extension = '.pdf';
      archivo.TipoArchivo = '19';
      this.archivoBooking = archivo;
      dialogRef.close();
      var body = this.translateService.instant("BodyInfoSubidaEbooking");
      var title = this.translateService.instant("TitleInfoSubirEbooking");
      this.toastr.info(body, title);
    });
  }
}
