import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HSCodes } from '../models/hscodes.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HscodesService {

  readonly apiUrlService = environment.apiUrl;
  
  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  getAllHSCodes(){
    const url_api = this.apiUrlService + "ma_hscodes";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    return this.http.get(url_api, { headers: headersToken })
    .pipe(map(data => data as HSCodes[]));
  }
}
