import { Component, OnInit, Input, LOCALE_ID, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DatePipe } from '@angular/common';
import { registerLocaleData } from '@angular/common'

import localeEs from '@angular/common/locales/es'
import { Expedition } from 'src/app/models/expedition.model';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { Archivo } from 'src/app/models/archivo.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import { HojaRuta } from 'src/app/models/hojaRuta.model';
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { AuthenticationService } from '../../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

registerLocaleData(localeEs, 'es')

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.scss'],
  providers: [{provide: LOCALE_ID, useValue: 'es'}, DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({
        height: '0px',
        visibility: 'hidden'
      })),
      state('expanded', style({
        'min-height': '48px',
        height: '*',
        visibility: 'visible'
      })),
      transition(
        'expanded <=> collapsed, void <=> *',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ])
  ]
})

export class DetallesComponent implements OnInit, OnDestroy {
  @Input() indice: number;
  @Output() pasarIdExpedicion = new EventEmitter<number>()
  @Output() limpiarHistorialDetalles = new EventEmitter<number>()
  @Output() verificacionConductor = new EventEmitter<[boolean, string]>()
  @Output() subirFacturas= new EventEmitter<[string[], number]>()
  @Input() listaExpediciones: Expedition[] = [];
  @Input() hr: HojaRuta = null;
  expedicion: Expedition = new Expedition();
  @Input() listExp: Expedition;
  @Input() isHistorial: boolean = false;
  documentos: Observable<Archivo[]>;
  expedicionesFactura: string[] = [];
  isMovil: boolean = false;
  isProveedor: boolean = false;
  expandedElement: Expedition | null;
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  panelOpenState: boolean;
  estados: string[] = ["Pendiente", "Recogida", "Entregado", "Entregado a falta de albarán"];
  selectedAll: boolean = false;

  constructor(private modalService: BsModalService, 
    private expService: ExpeditionApiService, private toastr: ToastrService, 
    private deviceService: DeviceDetectorService, private datePipe: DatePipe, 
    private servicioExpedition: ExpeditionApiService, public dialog: MatDialog,
    private translateService: TranslateService, private authenticationService: AuthenticationService) { 
  }

  getColumnas(){
    if(this.isHistorial && this.isProveedor){
      return ['checkBox', 'referencia', 'expedicion', 'fecha', ' '];
    }else{
      return ['referencia', 'expedicion', 'fecha', ' '];
    }
  }

  seleccionarTodos(){
    var cambio: boolean = !this.selectedAll;
    this.listaExpediciones.forEach(e => {
      if(e.Checked == undefined || e.Checked != cambio){
        e.Checked = cambio;
        if(this.expedicionesFactura.includes(e.Expedicion) && e.Checked == false){
          var index: number = this.expedicionesFactura.findIndex(ex => ex == e.Expedicion);
          this.expedicionesFactura.splice(index, 1);
        }else if(e.Checked == true){
          this.expedicionesFactura.push(e.Expedicion);
        }
      }
      this.subirFacturas.emit([this.expedicionesFactura, this.indice]);
    })
  }

  getSeleccionarTodos(){
    return this.selectedAll;
  }

  comprobarTodosSelected(): boolean{
    var resultado : boolean = true;
    this.listaExpediciones.forEach(e => {
      if(e.Checked == false || e.Checked == undefined){
        resultado = false;
      }})
      return resultado;
  }

  setExpedicionesToFactura(expedicion: string){
    if(this.expedicionesFactura.includes(expedicion)){
      var index: number = this.expedicionesFactura.findIndex(e => e == expedicion);
      this.expedicionesFactura.splice(index, 1);
      this.selectedAll = false;
    }else{
      this.expedicionesFactura.push(expedicion);
      this.listaExpediciones.find(e => e.Expedicion == expedicion).Checked = true;
      this.selectedAll = this.comprobarTodosSelected();
      this.listaExpediciones.find(e => e.Expedicion == expedicion).Checked = false;
    }
    this.subirFacturas.emit([this.expedicionesFactura, this.indice]);
    if(this.expedicionesFactura.length == 1){
      var IdExpedicion = this.listaExpediciones.find(e => e.Expedicion == this.expedicionesFactura[0]).IdExpedicion;
      this.pasarIdExpedicion.emit(IdExpedicion)
    }
  }

  documentosExpedition(){
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    this.suscripcions.push(dialogRef.afterOpened().subscribe(result => {}));
    this.suscripcions.push(this.expService.getDocumentos(this.expedicion.IdExpedicion).subscribe(data => {
      this.documentos = data;
      dialogRef.close();
    }, err => {
      var errorBody = this.translateService.instant('No se puede cargar los documentos.');
      var errorTitulo = this.translateService.instant('Error carga documentos');
      this.toastr.error(errorBody, errorTitulo)
      dialogRef.close();
    }));
    }

    openModal(template: TemplateRef<any>) {
      const config: ModalOptions = {
        id: 1,
        backdrop: 'static',
        keyboard: false,
        animated: true,
        ignoreBackdropClick: true,
        class: 'modal-xl',
        initialState: {
          id: 1
        } as Partial<Object>
      };
      this.modalRef = this.modalService.show(template, config);
    }

      openModal2(template: TemplateRef<any>) {
        const config: ModalOptions = {
          id: 1,
          backdrop: 'static',
          keyboard: false,
          animated: true,
          ignoreBackdropClick: true,
          class: 'modal-xl',
          initialState: {
            id: 1
          } as Partial<Object>
        };
        this.modalRef2 = this.modalService.show(template, config);
      }

        cerrar(event){
          this.listaExpediciones.forEach(e => {
            e.Checked = false;
            /*if(e.checked == true){
              e.checked = false; 
              this.setExpedicionesToFactura(e.Expedicion)
            }*/
          })
          this.modalService.hide();
        }

      openToast(event){
        if(event[0]){
          this.expandedElement = null;
          this.toastr.success(event[1])
          this.verificacionConductor.emit([true, event[1]])
          this.closeModal();
        }else{
          this.expandedElement = null;
          this.toastr.error(event[1])
          this.verificacionConductor.emit([false, event[1]])
        }
      }

      closeFirstModal() {
        if (!this.modalRef) {
          return;
        }
    
        this.modalRef.hide();
        this.modalRef = null;
      }
      closeModal(modalId?: number){
        this.modalService.hide();
      }
  
     // Saber siguiente estado
    siguienteEstado(estado) {
      var nextEstado = ""
      if (estado === this.estados[1]) {
        nextEstado = this.estados[2]
      } else if (estado === this.estados[0] || !estado) {
        nextEstado = this.estados[1]
      }
      return nextEstado
    }

  showDetails(exp: Expedition){
    this.expedicion = new Expedition();
    this.suscripcions.push(this.expService.getExpedicion(exp.Numero).subscribe(data => {
      this.expedicion = data;
    }, err => {
      var errorBody = this.translateService.instant('No se puede obtener la información de la expedición.');
      var errorTitulo = this.translateService.instant('Error obtener información');
      this.toastr.error(errorBody, errorTitulo)
    }));
  }
  
  suscripcions: Subscription[] = []
  ngOnDestroy(){
    this.suscripcions.forEach(s => {
      s.unsubscribe();
    });
  }

  isHorarioCompletoConPausaIntermediaRecogida(){
    return this.expedicion.hr1 != "" && this.expedicion.hr2 != "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "";
  }

  isHorarioFijoSinPausaIntermediaRecogida(){
    return this.expedicion.hr1 == "" && this.expedicion.hr2 == "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" && this.expedicion.hr3 != this.expedicion.hr4;
  }

  isHoraFijaRecogida(){
    return this.expedicion.hr1 == "" && this.expedicion.hr2 == "" && this.expedicion.hr3 != "" && this.expedicion.hr4 != "" && this.expedicion.hr3 == this.expedicion.hr4;
  }

  isHorarioCompletoConPausaIntermediaEntrega(){
    return this.expedicion.he1 != "" && this.expedicion.he2 != "" && this.expedicion.he3 != "" && this.expedicion.he4 != "";
  }

  isHorarioFijoSinPausaIntermediaEntrega(){
    return this.expedicion.he1 == "" && this.expedicion.he2 == "" && this.expedicion.he3 != "" && this.expedicion.he4 != "" && this.expedicion.he3 != this.expedicion.he4;
  }

  isHoraFijaEntrega(){
    return this.expedicion.he1 == "" && this.expedicion.he2 == "" && this.expedicion.he3 != "" && this.expedicion.he4 != "" && this.expedicion.he3 == this.expedicion.he4;
  }

  toStringRecogida(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fechaFinal: string = "";
      var fecha = fechaTotal.split(" ")[0];
      fechaFinal = fecha;
      if(this.isHorarioCompletoConPausaIntermediaRecogida()){ // HORARIO COMPLETO CON PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        var ydesdelas = this.translateService.instant(" y desde las ");
        var a = this.translateService.instant(" a ");
        fechaFinal += desdelas + this.expedicion.hr1 + hastalas + this.expedicion.hr2 + ydesdelas + this.expedicion.hr3 + a + this.expedicion.hr4;
      }else if(this.isHorarioFijoSinPausaIntermediaRecogida()){ // HORARIO FIJO SIN PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        fechaFinal += desdelas + this.expedicion.hr3 + hastalas + this.expedicion.hr4;
      }else if(this.isHoraFijaRecogida()){ // HORARIO DE UNA SOLA HORA
        var horarioFijo = this.translateService.instant("HORARIO FIJO R: ");
        fechaFinal += horarioFijo + this.expedicion.hr3;
      }
      return fechaFinal;
    }
  }

  toStringEntrega(fechaTotal: string){
    if(fechaTotal !== undefined){
      var fechaFinal: string = "";
      var fecha = fechaTotal.split(" ")[0]
      /*var dia = fecha.split("/")[0]
      var mes = fecha.split("/")[1]
      var anyo = fecha.split("/")[2]*/
      fechaFinal = fecha;
      if(this.isHorarioCompletoConPausaIntermediaEntrega()){ // HORARIO COMPLETO CON PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        var ydesdelas = this.translateService.instant(" y desde las ");
        var a = this.translateService.instant(" a ");
        fechaFinal += desdelas + this.expedicion.he1 + hastalas + this.expedicion.he2 + ydesdelas + this.expedicion.he3 + a + this.expedicion.he4;
      }else if(this.isHorarioFijoSinPausaIntermediaEntrega()){ // HORARIO FIJO SIN PAUSA INTERMEDIA
        var desdelas = this.translateService.instant("desde las ");
        var hastalas = this.translateService.instant(" hasta las ");
        fechaFinal += desdelas + this.expedicion.he3 + hastalas + this.expedicion.he4;
      }else if(this.isHoraFijaEntrega()){ // HORARIO DE UNA SOLA HORA
        var horarioFijo = this.translateService.instant("HORARIO FIJO E: ");
        fechaFinal += horarioFijo + this.expedicion.he3;
      }
      return fechaFinal;
    }
  }

  cambiarEstadoHojaRuta(exp: string): boolean{
    if(this.hr == null){
      return false;
    }
    var disabled : boolean = false;
    var indexExpedicion : number = this.hr.Expediciones.findIndex(expedicionhr => expedicionhr.Expedicion == exp);
    //var expedicionDetalles: Expedition = this.hr.Expediciones[indexExpedicion];
    if(indexExpedicion != 0){
      this.hr.Expediciones.forEach(e => {
        var posicionActual : number = this.hr.Expediciones.findIndex(expedicionhr => expedicionhr.Expedicion == e.Expedicion);
        if(posicionActual < indexExpedicion){ // Estoy por detrás
          if(e.Estado != "Entregado"){
            disabled = true;
          }
        }
      })
    }else{
      return disabled;
    }
    return disabled;
  }

  // Imprimir en un string la dirección completa
  montarDireccion(direccion: string, codPostal: string, poblacion: string){
    var direccionTotal: string = "";
    if(direccion != undefined)
      direccionTotal += direccion;
    if(poblacion != undefined){
      if(direccionTotal != "")
        direccionTotal += ', ' + poblacion;
      else
        direccionTotal += poblacion;
    }
    if(codPostal != undefined){
      if(direccionTotal != "")
        direccionTotal += ', ' + codPostal;
      else
        direccionTotal += codPostal;
    }
    return direccionTotal
  }

  ngOnInit() {
    this.isMovil = this.deviceService.isMobile();
    if(this.listaExpediciones.length == 1){
      this.expandedElement = this.listaExpediciones[0];
      this.showDetails(this.expandedElement);
    }
    this.authenticationService.getTipoUsuario().subscribe(data => {
      if(data == 10)
        this.isProveedor = true;
      else
        this.suscripcions.push(this.expService.getProveedor().subscribe(data => {
          this.isProveedor = data;
        }));
    })
    
  }

  muestraDestinatario(expedicion: Expedition): Boolean{
    var mostrar: Boolean = false;
    if(expedicion.Estado != "Pendiente" && expedicion.Estado != null && expedicion.Estado != undefined)
      mostrar = true;
    return mostrar;
  }

}
