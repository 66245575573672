import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DestinatarioRemitente } from 'src/app/models/destinatarioRemitente.model';
import { Expedition } from 'src/app/models/expedition.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';

@Component({
  selector: 'app-subir-pendientes-hojaruta',
  templateUrl: './subir-pendientes-hojaruta.component.html',
  styleUrls: ['./subir-pendientes-hojaruta.component.scss']
})
export class SubirPendientesHojarutaComponent implements OnInit {

  
  clase: string = "app-subir-pendientes";
  texto: string = ""
  @Input() expedicionesPendiente: Expedition[];
  expedicionesPendienteSinRecoger: Expedition[] = [];
  selectedAll: boolean = false;
  @Output() verificacionConjunta = new EventEmitter<[boolean, string]>()
  seleccionados: Expedition[] = [];
  seleccionadosString: string[] = [];
  estados: string[] = ["Pendiente", "Recogida", "Entregado"];
  displayedColumns: string[] = ['check', 'SerieReferencia', 'Remitente', 'FechaSalida'];

  constructor(private toastr: ToastrService, private expeditionService: ExpeditionApiService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.expedicionesPendiente.forEach(e => {
      if(e.Estado != 'Recogida' && e.Estado != 'Entregado')
        this.expedicionesPendienteSinRecoger.push(e);
    })
  }
  
  seleccionarTodos(event){
    this.expedicionesPendiente.forEach(e => {
      if(e.Estado != 'Recogida'){
        e.Checked = event.checked
      }
    });
    if(event.checked){ // Todos seleccionados
      this.texto = "";
      this.expedicionesPendiente.forEach(exp => {
        if(exp.Checked){
          this.seleccionados.push(exp);
          this.texto += " " + exp.Expedicion;
        }else{
          this.seleccionados.splice(this.seleccionados.findIndex(e => e.Expedicion == exp.Expedicion), 1);
        }
      })
    }else{ // Todos deseleccionados
      this.texto = "";
    }
  }

  getSeleccionadosString(expedicion: string){
    this.texto = "";
    this.expedicionesPendienteSinRecoger.forEach(e => {
      if(expedicion == e.Expedicion && !e.Checked)
        this.texto += " " + e.Expedicion;
      if(e.Checked)
        this.texto += " " + e.Expedicion;
    })
  }

  comprobarTodos(): boolean{
    var todosCheck: boolean = true;
    this.expedicionesPendiente.forEach(e => {
      if(e.Checked == false || e.Checked == undefined){
        todosCheck = false;
      }
    })
    return todosCheck;
  }

  subirPendientes(click: string){
    this.getSeleccionados();
    if(!this.comprobarFechas()){
      if(!this.comprobarRemitente()){
        var log: LogModel = new LogModel();
        log.Pantalla = this.clase;
        log.Click = click;
        
        this.expeditionService.cambiarEstadoConjunto(this.seleccionadosString, log).subscribe(data => {
          this.verificacionConjunta.emit([true, this.translateService.instant("Se ha cambiado el estado de las expeidiciones seleccionadas perfectamente.")]);
        }, _ => this.verificacionConjunta.emit([false, this.translateService.instant("Ha existido un error al cambiar el estado a las expediciones conjuntas.")]));//, "Error cambio estado"));
      }else{
        this.toastr.error(this.translateService.instant("Las expediciones seleccionadas no tienen el mismo remitente. Debes seleccionar expediciones con el mismo remitente."), this.translateService.instant("Error diferente remitente"))
      }
    }else{
      this.toastr.error(this.translateService.instant("Las expediciones seleccionadas no tienen la misma fecha de recogida. Debes seleccionar expediciones con la misma fecha."), this.translateService.instant("Error fecha expediciones"))
    }
  }

    // Saber siguiente estado
    siguienteEstado(estado: string) {
      var nextEstado = ""
      if (estado === this.estados[1]) {
        nextEstado = this.estados[2]
      } else if (estado === this.estados[0] || !estado) {
        nextEstado = this.estados[1]
      }
      return nextEstado
    }

  getSeleccionados(){
    this.seleccionadosString = [];
    //this.texto = " ";
    this.expedicionesPendiente.forEach(e => {
      if(e.Checked){
        this.seleccionadosString.push(e.Numero.toString());
        this.seleccionados.push(e);
        //this.texto += " " + this.expedicionesPendiente;
      }
        
    })
  }

  comprobarFechas() : boolean{
    var fecha: string = "";
    var error = false;
    this.seleccionados.forEach(e => {
      if(fecha == null || fecha == undefined || fecha == "")
        fecha = e.FechaSalida
      else{
        if(fecha != e.FechaSalida)
          error = true;
      }
    })
    return error;
  }

  comprobarRemitente() : boolean{
    var remitente: string = "";
    var error = false;
    this.seleccionados.forEach(e => {
      if(remitente == "")
        remitente = e.Remitente;
      else{
        if(e.Remitente != remitente)
          error = true;
      }
    })
    return error;
  }

}
