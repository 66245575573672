<div *ngIf="expedicionesPendientesRec != null">
    <table mat-table [dataSource]="expedicionesPendientesRec" multiTemplateDataRows
        class="table table-hover mat-elevation-z3" style="margin-top: 5%;">
        <!-- CUIDADO Column -->
        <!-- <ng-container matColumnDef="cuidado">
            <th mat-header-cell *matHeaderCellDef>
                
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="warningEntrega(element)">error_outline</mat-icon>
                <mat-icon *ngIf="sinRecompensa(element)">clear</mat-icon>
                <mat-icon *ngIf="okEntrega(element)">check</mat-icon>
            </td>
        </ng-container> -->
        <!-- Icon Column -->
        <ng-container matColumnDef=" ">
            <th mat-header-cell *matHeaderCellDef>

            </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="expandedElement != null && expandedElement.Numero == element.Numero">
                    keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="expandedElement == null || (expandedElement != null && expandedElement.Numero != element.Numero)">
                    keyboard_arrow_down</mat-icon>
            </td>
        </ng-container>

        <!-- Referencia Column -->
        <ng-container matColumnDef="referencia">
            <th mat-header-cell *matHeaderCellDef>
                {{'Referencia'|translate}}
            </th>
            <td mat-cell *matCellDef="let element"
                (click)="showDetails(element); expandedElement = expandedElement === element ? null : element">
                {{element.IdSerieReferencia}} </td>
        </ng-container>

        <!-- Expedicion Column -->
        <ng-container matColumnDef="expedicion">
            <th mat-header-cell *matHeaderCellDef>
                {{'Expedicion'|translate}} </th>
            <td mat-cell *matCellDef="let element"
                (click)="showDetails(element); expandedElement = expandedElement === element ? null : element">
                {{element.Numero}} </td>
        </ng-container>

        <!-- Fecha Column -->
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> {{'Fecha'|translate}}
            </th>
            <td mat-cell *matCellDef="let element"
                (click)="showDetails(element); expandedElement = expandedElement === element ? null : element">
                {{element.FechaLlegada}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="getColumnas().length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="container fondo">
                        <div *ngIf="expedicion != null && expedicion != undefined">
                            <div class="container fondo">
                                <div class="row">
                                    <div class="col-9 col-sm-9">
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <p class="h5">{{'Matricula'|translate}}:
                                                    <span *ngIf="expedicion.Matricula != undefined && expedicion.Matricula != null">
                                                        {{expedicion.Matricula}}
                                                    </span>
                                                    <span *ngIf="expedicion.Matricula == undefined || expedicion.Matricula == null">
                                                        Sin asignar
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row titulo">
                                    <div class="col">
                                        <p class="h4">{{'Información remitente'|translate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.Remitente}}</p>
                                </div>
                                <div class="row">
                                    <p>{{montarDireccion(expedicion.DireccionRemitente, expedicion.CodigoPostalRemitente,
                                        expedicion.PoblacionRemitente)}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.TelefonoRemitente}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.Bultos}} {{'bultos'|translate}} / {{expedicion.Peso}} Kg / 
                                        {{'Metroslineales'|translate}}</p> <!-- {{expedicion.MetrosLineales}}-->
                                </div>
                                <div class="row" *ngIf="expedicion.RecogidaFechaTracking == null || expedicion.RecogidaFechaTracking == ''">
                                    <p><b>{{'Fecha recogida'|translate}}: {{toStringRecogida(expedicion.FechaSalida)}}</b></p>
                                </div>
                                <div *ngIf="expedicion.RecogidaFechaTracking != null && expedicion.RecogidaFechaTracking != ''" class="row">
                                    <p><b>{{'Recogido el'|translate}} {{expedicion.RecogidaFechaTracking}}</b></p>
                                </div>
                                <div class="row titulo">
                                    <p class="h4">{{'Información destinatario'|translate}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.Destinatario}}</p>
                                </div>
                                <div class="row">
                                    <p>{{montarDireccion(expedicion.DireccionDestinatario, expedicion.CodigoPostalDestinatario,
                                        expedicion.PoblacionDestinatario)}}</p>
                                </div>
                                <div class="row">
                                    <p>{{expedicion.TelefonoDestinatario}}</p>
                                </div>
                                <div class="row"
                                    *ngIf="expedicion.EntregaFechaTracking == null || expedicion.EntregaFechaTracking == ''">
                                    <p><b>{{'Fecha entrega'|translate}}: {{toStringEntrega(expedicion.FechaLlegada)}}</b></p>
                                </div>
                                <div *ngIf="expedicion.EntregaFechaTracking != null && expedicion.EntregaFechaTracking != ''"
                                    class="row">
                                    <p><b>{{'Entregado el'|translate}} {{expedicion.EntregaFechaTracking}}</b></p>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <!-- [disabled]="cambiarEstadoHojaRuta(expedicion.Expedicion)" -->
                                        <button *ngIf="expedicion.Estado == estados[0]" class="btn" type="button"
                                        (click)="openToast(templateCambiarEstado, 'templateCambiarEstado', expedicion)"
                                                    
                                                    style="margin-bottom: 3%;">{{'Confirmar Recogida'|translate}}</button>
                                        <button *ngIf="expedicion.Estado == estados[1] || expedicion.Estado == 'En tránsito' || expedicion.Estado == 'EN TRANSITO' ||  expedicion.Estado == 'EN TRÁNSITO'" class="btn" type="button"
                                        (click)="openToast(templateCambiarEstado, 'templateCambiarEstado', expedicion)"
                                                    style="margin-bottom: 3%;">{{'Confirmar Entrega'|translate}}</button>
            
                                    </div>
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-2">
                                        <button class="btn" (click)="openModal(template)" type="button"
                                                        style="margin-bottom: 3%;">{{'Incidencias'|translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getColumnas()"></tr>
        <tr mat-row *matRowDef="let element; columns: getColumnas();" class="example-element-row"
            [class.example-expanded-row]="expandedElement != null && expandedElement.Numero == element.Numero">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>

<!-- INCIDENCIAS TEMPLATE -->
<ng-template #template>
    <div class="modal-header fondo" style="border-color: black;">
      <h4 class="modal-title pull-left">{{'Incidencias de'|translate}} {{expedicion.Expedicion}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-incidencias [historico]="false" [expedicion]="expedicion.Expedicion" [idExpedicion]="expedicion.IdExpedicion" (cerrarModal)="cerrarModal($event)"></app-incidencias>
    </div>
  </ng-template>

  <!-- The Modal Cambiar Estado -->
<!--  -->
<ng-template #templateCambiarEstado>
    <app-model-estado (cerrarModal)="cerrarModal($event)" (verificacion)="openToastVerificacion($event)"  [errorDni]="false" [submited]="false" [error]="false" [showWebcam]="true" [fotoRealizada]="false" [estado]="expedicion.Estado"
            [idExpedicion]="expedicion.Expedicion" [numero]="expedicion.Numero" [errorFirma]="false"></app-model-estado>
</ng-template>