<table class="table table-sm table-bordered table-editable table-row-action edit-action">
    <thead>
      <th>{{ "Concepto" | translate }}</th>
      <th>{{ "Importe" | translate }}</th>
      <th>{{ "Divisa" | translate }}</th>
    </thead>
    <tbody>
      <tr *ngFor="let ic of lineasIngreso">
        <td>{{ ic.concepto }}</td>
        <td>{{ ic.importe }}</td>
        <td>{{ ic.divisa }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr *ngFor="let t of totales" style="border: 2px solid black;">
        <td>{{ t.descripcion }}</td>
        <td>{{ t.total }}</td>
        <td>{{ t.divisa }}</td>
      </tr>
    </tfoot>
  </table>

  <p>{{"AvisoNoCostesDefinitivos"| translate }}</p>