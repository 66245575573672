<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <p>{{'¿Está seguro que desea guardar la plantilla?'|translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="margen">
            <div class="col-sm-6">
                <button type="button" (click)="confirmacion()" class="btn">{{'Aceptar'|translate}}</button>
            </div>
            <div class="col-sm-6">
                <button type="button" (click)="cancelacion()" class="btn">{{'Cancelar'|translate}}</button>
            </div>
        </div>
       
    </div>
</div>