import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { Direccion } from 'src/app/models/direccion.model';
import { Entity } from 'src/app/models/entity.model';
import { EntityService } from 'src/app/services/entity.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-direcciones-entidad',
  templateUrl: './direcciones-entidad.component.html',
  styleUrls: ['./direcciones-entidad.component.scss']
})
export class DireccionesEntidadComponent implements OnInit {

  @Output() address = new EventEmitter<Direccion>();
  @Input() tipoDireccion: String;
  @Input() entity: Entity;
  total: number;
  pageActual: number = 1;
  sizePage: number = 20;
  listaDirecciones: Direccion[];
  listaDireccionesAux: Direccion[];
  direccion: Direccion;
  currentRow: any;
  searchText: string;

  constructor(private configService: ShipmentService, private entityService: EntityService) {
    this.entityService.getEntity$().subscribe(e => {
      this.entity = e;
    });
   }

  ngOnInit(): void {
    this.currentRow = -1;
    this.listaDirecciones = [];

    this.peticionesDirecciones();
  }

  ngOnChanges(changes: SimpleChange){
  }

  existeCadenaDireccion(dir: Direccion): boolean{
    return (dir.NombreEntidad.toUpperCase().includes(this.searchText.toUpperCase()) || dir.Direccion.toUpperCase().includes(this.searchText.toUpperCase()) 
    || (dir.NombrePais != null && dir.NombrePais.includes(this.searchText.toUpperCase())) 
    || (dir.NombreLocalidad != null && dir.NombreLocalidad.includes(this.searchText.toUpperCase())) 
    || (dir.NombrePoblacion != null && dir.NombrePoblacion.includes(this.searchText.toUpperCase()))
    || (dir.CodigoPostal != null && dir.CodigoPostal.includes(this.searchText.toUpperCase())))
  }

  changeSearch(){
    this.listaDirecciones = this.listaDireccionesAux;
    if(this.searchText != "")
      this.listaDirecciones = this.listaDirecciones.filter(dir => this.existeCadenaDireccion(dir));
    this.total = this.listaDirecciones.length;
  }

  peticionesDirecciones() {
    if(this.tipoDireccion == 'shipper')
      this.configService.getDireccionesShipper().subscribe(data => {
        this.listaDireccionesAux = data;
        this.listaDirecciones = data;
        this.total = this.listaDirecciones.length;
      });
    else if(this.tipoDireccion == 'consignee')
      this.configService.getDireccionesConsignee().subscribe(data => {
        this.listaDireccionesAux = this.listaDirecciones;
        this.listaDirecciones = data;
        this.total = this.listaDirecciones.length;
      });
    else if(this.tipoDireccion == 'consigneeEntity' || this.tipoDireccion == 'shipperEntity')
      this.configService.getDireccionesEntity(this.entity).subscribe(data => {
        this.listaDireccionesAux = this.listaDirecciones;
        this.listaDirecciones = data;
        this.total = this.listaDirecciones.length;
      });
  }

  safeEntity(address) {
    this.direccion = address;
    this.currentRow = null;
  }

  enviarDireccion(cerrar?: string) {
    if(cerrar == 'cerrar')
    {
      this.address.emit(null)
    }
    else
    {
      this.address.emit(this.direccion);
    }
  }

  selectRow(event: any, direccion: Direccion) {
    this.currentRow = direccion.IdDireccion;
  }

}
