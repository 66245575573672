<p>
    {{texto}}.
</p>
<p>
    {{'¿Estás seguro que deseas hacerlo?' | translate}}
</p>
<div class="row">
    <div class="col-sm-6 col-6">
        <button type="button" class="btn" (click)="enviarConfirmacion(true)">Aceptar</button>
    </div>
    <div class="col-sm-6 col-6">
        <button type="button" class="btn" (click)="enviarConfirmacion(false)">Rechazar</button>
    </div>
</div>
