import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Archivo } from 'src/app/models/archivo.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FileShipService } from 'src/app/services/file-ship.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { TranslateService } from '@ngx-translate/core';
import { TipoArchivo } from 'src/app/models/tipo-archivo.model';
import { Shipment } from 'src/app/models/shipment.model';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  files: any = [];
  nombreArchivo: String[] = [];
  listArchivo: String[] = ["MSDS", "ALBARAN", "FACTURA", "CARTA DE PORTES", "DGD", "PACKING LIST", "OTROS"];
  public listaTipoArchivos: TipoArchivo[];
  listExtensionFile: String[] = ["bmp", "csv", "dif", "doc", "docm", "docx", "gif", "htm", "html", "jpg", "jpeg", "pdf", "png", "pot", "potm", "potx", "ppsx", "ppt", "pptx", "rar", "pst", "txt", "xls", "xlsx", "zip"];
  dataFile: Archivo[] = [];
  @Output() listFiles = new EventEmitter<Archivo[]>();
  @Input() listaArchivos: Archivo[];
  @Input() envio: Shipment;
  indiceEdit: number;
  modalRef: BsModalRef | null;
  @Input() documentacion: Boolean;
  formUploadFile = new FormControl({
    FileName: null,
    IdTipoArchivo: null,
    TipoArchivo: null,
    Extension: null,
    Base64image: null,
    Tamanyo: null,
    Path: null
  });

  //Declaracion del decorador para poder cerrar el modal si y solo sí estan todos los campos correctos.
  @ViewChild('modal') modal: ElementRef;

  constructor(private modalService: BsModalService, private fileService: FileShipService, private toastr: ToastrService,
    private translateService: TranslateService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    this.listaTipoArchivos = [];
    this.usuarioService.getLenguaje$().subscribe(async d => {
      // NO SE PARA QUE SIRVE PERO FUNCIONA, SI NO, NO TRADUCE A LA PRIMERA SI NO A LA SEGUNDA VEZ QUE SELECCIONAS EL IDIOMA - ENRIQUE 15/12/2021
      await this.translateService.get("DGD").toPromise().then();
      var lta: TipoArchivo[] = [];
      this.listaTipoArchivos.forEach(t => {
        lta.push({
          NombreTipoArchivo: this.translateService.instant(t.NombreTipoArchivo),
          IdTipoArchivo: t.IdTipoArchivo
        });
      });
      this.listaTipoArchivos = lta;
    });
    this.fileService.getListaTipoDocumentos().subscribe(data => {
      this.listaTipoArchivos = data;
    });
  }

  uploadFile(fileList) {
    if (fileList.length > 0) {
      let me = this;
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (file.size > 0 && file.size < 26214400) {
          var array = file.name.split('.');
          var extension = array[array.length - 1];
          var nombre = file.name.split('.' + extension)[0].replaceAll('.', '');
          me.formUploadFile.value.Base64image = reader.result
          me.formUploadFile.value.Tamanyo = file.size;
          me.formUploadFile.value.FileName = nombre;
          let archivo = new Archivo();
          archivo.Base64image = reader.result;
          archivo.FileName = nombre;
          archivo.Tamanyo = file.size;
          archivo.Extension = extension;
          archivo.IdTipoArchivo = me.formUploadFile.value.IdTipoArchivo;
          me.listaTipoArchivos.forEach(element => {
            if (archivo.IdTipoArchivo == element.IdTipoArchivo) {
              archivo.TipoArchivo = element.NombreTipoArchivo;
              archivo.FileName = element.NombreTipoArchivo + "_" + nombre;
              me.formUploadFile.value.FileName = archivo.FileName;
            }
          });
          me.dataFile.push(archivo)
        } else {
          me.toastr.error("El peso del archivo subido no puede ser mayor a 25 MB.", "Peso mayor a 25 MB");
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  formatBytes(bytes) {
    if (bytes === 0) {
      return ' 0 Bytes';
    }
    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onDelete(i: number) {
    this.dataFile.splice(i, 1);
  }

  //Funcion que extrae la extension del archivo
  extraerExtension(nombreArchivo: String) {
    let extension = '';
    let obtenido = false;
    for (let i = 0; i < nombreArchivo.length && !obtenido; i++) {
      if (nombreArchivo[i] == '.') {
        let position = i;
        extension = nombreArchivo.substring(position + 1, nombreArchivo.length)
        obtenido = true;
      }
    }
    return extension;
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }

  anyadirFicheros() {
    this.listFiles.emit(this.dataFile);
    this.modal.nativeElement.click();
    //this.dataFile = [];
    this.formUploadFile.value.IdTipoArchivo = null;
  }

  ExisteTipoArchivo() {
    if (this.formUploadFile.value.IdTipoArchivo != null && this.formUploadFile.value.IdTipoArchivo != '') {
      return true;
    }
    else {
      return false;
    }
  }

  openAlert(template: TemplateRef<any>, i: number) {
    this.indiceEdit = i;
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 2
      } as Partial<Object>
    };
    //this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg' });
    this.modalRef = this.modalService.show(template, config);
  }

  obtenerConfirmacion(confirmacion: boolean) {
    if (confirmacion == true) {
      this.onDelete(this.indiceEdit);
    }
    this.modalRef.hide();
  }

  guardarArchivos(dataFile: Archivo[]) {
    var log: LogModel = new LogModel();
    log.Click = "Botón Aceptar Archivos Adjuntos";
    log.Pantalla = "upload-file.component.ts - Documentación";
    if (this.envio.IdEnvio != null) {
      this.fileService.postDocumentosEnvio(dataFile, this.envio.IdEnvio, log).subscribe(data => {
        var body = this.translateService.instant("ArchivosAñadidosCorrectamente");
        var title = this.translateService.instant("ArchivosAñadidos");
        this.toastr.success(body, title)
      },
        error => {
          var body = this.translateService.instant("ArchivosNoAñadidos");
          var title = this.translateService.instant("ErrorArchivos");
          this.toastr.error(body, title)
        })
    }
    else if (this.envio.Partida != null) {
      this.fileService.postDocumentosPartida(dataFile, this.envio.Partida, log).subscribe(data => {
        var body = this.translateService.instant("ArchivosAñadidosCorrectamente");
        var title = this.translateService.instant("ArchivosAñadidos");
        this.toastr.success(body, title)
      },
        error => {
          var body = this.translateService.instant("ArchivosNoAñadidos");
          var title = this.translateService.instant("ErrorArchivos");
          this.toastr.error(body, title)
        })
    }
    else if (this.envio.Expedicion != null) {
      this.fileService.postDocumentosExpedicion(dataFile, this.envio.Expedicion, log).subscribe(data => {
        var body = this.translateService.instant("ArchivosAñadidosCorrectamente");
        var title = this.translateService.instant("ArchivosAñadidos");
        this.toastr.success(body, title)
      },
        error => {
          var body = this.translateService.instant("ArchivosNoAñadidos");
          var title = this.translateService.instant("ErrorArchivos");
          this.toastr.error(body, title)
        })
    }
  }
}
