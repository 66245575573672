import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';
import { TarifaPost } from 'src/app/models/tarifaPost';
import { TarifaService } from 'src/app/services/tarifaservice.service';

@Component({
  selector: 'app-resumen-tarifa',
  templateUrl: './resumen-tarifa.component.html',
  styleUrls: ['./resumen-tarifa.component.scss']
})
export class ResumenTarifaComponent implements OnInit {

  @Input() tarifaSeleccionada: TarifaPost;
  @Output() confirmEmit = new EventEmitter<Boolean>();
  modalRef: BsModalRef | null;
  tieneLineasAdicionales: boolean;
  tarifa: TarifaEnvio;
  aceptaCondiciones: boolean = false;
  tarifaFiltrada: TarifaPost

  constructor(private modalService: BsModalService, private tarifaService: TarifaService, private router: Router, private toastr: ToastrService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.tarifa = this.tarifaService.tarifa;
  }

  TieneLineasAdicionales() {
    this.tieneLineasAdicionales = this.tarifaSeleccionada.ListaLineas.some(element => element.Adicional === true);
  }

  ObtenerTarifaFiltrada() {
    this.tarifaFiltrada = Object.assign({}, this.tarifaSeleccionada);
    this.tarifaFiltrada.ListaLineas = this.tarifaFiltrada.ListaLineas.filter(linea => linea.Adicional === true || linea.Adicional === null);
    this.tarifaService.tarifaFiltrada = this.tarifaFiltrada;
  }

  mostrarError(bodyInstant: string, titleInstant: string) {
    var body: string = this.translateService.instant(bodyInstant);
    var title: string = this.translateService.instant(titleInstant);
    this.toastr.error(body, title);
  }

  setRow() {
    if (this.aceptaCondiciones || this.tarifaSeleccionada.Condiciones == null) {
      if (this.tarifa.IdArea == 2) {
        this.modalService.hide();
        this.router.navigate(['customer/shipment/maritimo']);
        this.tarifaService.setTarifa$(this.tarifa);
      }
      else if (this.tarifa.IdArea == 3) {
        this.modalService.hide();
        this.router.navigate(['customer/shipment/aereo']);
        this.tarifaService.setTarifa$(this.tarifa);
      }

    }
    else {
      this.mostrarError("BodyErrorCond", "TitleErrorCond");
    }
  }

  cancelacion() {
    this.modalService.hide();
  }

}
