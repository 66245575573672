<table *ngIf="contenedoresCotizacion != undefined && contenedoresCotizacion != null && contenedoresCotizacion.length > 0" class="table table-sm table-bordered table-editable table-row-action edit-action">
    <thead>
        <tr>
            <th>{{ "TipoContenedor" | translate }}</th>
            <!-- <th>{{ "Bultos" | translate }}</th>
            <th>{{ "Mercancia" | translate }}</th>
            <th>{{ "Peso Bruto" | translate }}</th>
            <th>{{ "D.G." | translate }}</th>
            <th>{{ "Alimentación" | translate }}</th>
            <th>{{ "GDP" | translate }}</th>-->
            <th *ngIf="existeMercancia()"></th> 
        </tr>
    </thead>
    <!--class="border-discontinuo"-->
    <tbody  *ngFor="let contenedor of contenedoresCotizacion; let i = index;">
        <tr (click)="marcarContenedor(i)">
            <td>{{ contenedor.NombreContenedor }}</td>
            <!-- <td>{{ contenedor.Bultos }}</td>
            <td>{{ contenedor.NombreTipoMercanciaContenedor }}</td>
            <td>{{ contenedor.PesoBruto }}</td>
            <td *ngIf="contenedor.Peligrosa">{{ 'Si' | translate }}</td>
            <td *ngIf="!contenedor.Peligrosa">{{ 'No' | translate }}</td>
            <td *ngIf="contenedor.Alimentacion">{{ 'Si' | translate }}</td>
            <td *ngIf="!contenedor.Alimentacion">{{ 'No' | translate }}</td>
            <td *ngIf="contenedor.GDP">{{ 'Si' | translate }}</td>
            <td *ngIf="!contenedor.GDP">{{ 'No' | translate }}</td> -->
            <td *ngIf="hayMercancia(contenedor)">
              <mat-icon *ngIf="!estaSeleccionada(i)">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="estaSeleccionada(i)">keyboard_arrow_up</mat-icon>
            </td>
        </tr>
        <!-- <tr *ngIf="hayMercanciaPeligrosa(contenedor) && estaSeleccionada(i)">
             <div class="contenedor"> 
                <td  [ngClass]="{'animate__animated animate__bounceInDown': hayMercanciaPeligrosa(contenedor) && estaSeleccionada(i)}" [attr.colspan]="8">
                     <h5>Mercancía peligrosa contenedor</h5>
                    <app-detalles-mercancia-peligrosa style="margin-left: 30%;" [mercanciasPeligrosas]="contenedor.MercanciaPeligrosa"></app-detalles-mercancia-peligrosa>
                    
                </td>
             </div> 
        </tr> -->
        <tr *ngIf="hayMercancia(contenedor) && estaSeleccionada(i)">
            <!-- <div class="contenedor"> -->
                <td [ngClass]="{'animate__animated animate__bounceInDown': hayMercancia(contenedor) && estaSeleccionada(i)}" [attr.colspan]="8">
                    <!-- <h5>Mercancía contenedor</h5> -->
                    <app-detalles-mercancia [mercanciasCotizacion]="contenedor.Mercancia"></app-detalles-mercancia>
                </td>
            <!-- </div> -->
            </tr>
    </tbody>
    <!-- <tfoot>
        <tr>
          <td><strong>{{'Totales' | translate }}</strong></td>
          <td><strong>{{totalBultos}}</strong></td>
          <td></td>
          <td><strong>{{totalPesoBruto}}</strong></td>
          <td></td>
          <td></td>
          <td></td>
          <td *ngIf="existeMercancia()"></td>
        </tr>
      </tfoot> -->
</table>