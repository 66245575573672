import { TarifaPost } from "./tarifaPost";

export class ContenedorTarifa {
    Codigo: number;
    Concepto: string;
    EsAdicional: boolean;
    Gasto: number;
    Divisa: string;
    Adicional: boolean;
    Porcentaje: number;
    IdConcepto: number;
    IdImpuesto: number;
    Cambio: number;
    OnRequest: boolean;
}