import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { TarifaPost } from '../models/tarifaPost';
import { Naviera } from '../models/naviera.model';
import { TarifaEnvio } from '../models/tarifaEnvio';
import { Mercancia } from '../models/mercancia.model';
import { Aerolinea } from '../models/aerolinea.model';
import { Localidades } from '../models/localidades.model';

@Injectable({
  providedIn: 'root'
})
export class TarifaService {

  readonly apiUrlService = environment.apiUrl;
  idCotizacion: number;
  ip: string = "";
  localizacion: string = "";
  public posicionArea: number = 1;
  public posicionModoEnvio: number = 0;
  tarifa$: Subject<TarifaEnvio> = new Subject();
  tarifa: TarifaEnvio
  tarifaFiltrada: TarifaPost
  listaTarifas: TarifaPost[];
  tarifaCotizacion: TarifaPost;




  constructor(private http: HttpClient, private authenticacion: AuthenticationService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
      this.ip = data['ip'];
      this.http.get('https://ipapi.co/' + this.ip + '/json').subscribe((d: any) => {
        //   this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
      })
    })
  }

  setTarifa$(tarifa: TarifaEnvio) {
    return this.tarifa$.next(tarifa);
  }

  getTarifa$(): Observable<TarifaEnvio> {
    return this.tarifa$.asObservable();
  }

  postTarifa(tarifa, logModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken(),
      "Content-Type": "application/json"
    });
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "fa_tarifas";
    console.log(JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(tarifa).toString(),
    }).toString())
    return this.http.post<TarifaPost[]>(url_api, JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(tarifa).toString(),
    }), { headers: headersToken }).pipe(map(data => data as TarifaPost[]));
  }

  postRevovación(idTarifa, logModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken(),
      "Content-Type": "application/json"
    });
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "fa_tarifas/" + idTarifa + "/renovar";
    return this.http.post<TarifaPost>(url_api, JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(idTarifa).toString(),
    }), { headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data as TarifaPost));
  }

  getNavieras(): Observable<Naviera[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cfg_navieras";
    // this.logService.postLogCompleto("GET", "ma_puertosController.cs", "Log obtener todas las navieras.",
    //  "Getma_puertos", "ma_puertos", url_api);
    return this.http.get<Naviera[]>(url_api, { headers: headersToken });
  }

  getAerolineas(): Observable<Aerolinea[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    })
    const url_api = this.apiUrlService + "cfg_aerolineas";
    // this.logService.postLogCompleto("GET", "ma_puertosController.cs", "Log obtener todas las navieras.",
    //  "Getma_puertos", "ma_puertos", url_api);
    return this.http.get<Aerolinea[]>(url_api, { headers: headersToken });
  }

  getLocalidades(area): Observable<Localidades[]> {
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_tarifas/" + area + "/1/1";
    console.log(url_api);
    return this.http.get<Localidades[]>(url_api, { headers: headersToken });
  }

}


