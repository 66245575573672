import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entity } from 'src/app/models/entity.model';
import { FormControl, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/services/entity.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Paises } from 'src/app/models/paises.model';
import { Localidades } from 'src/app/models/localidades.model';
import { TipoIdentificacion } from 'src/app/models/tipo-identificacion.model';
import { TranslateService } from '@ngx-translate/core';
import { LogModel } from 'src/app/models/log-model.model';

@Component({
  selector: 'app-entity-address',
  templateUrl: './entity-address.component.html',
  styleUrls: ['./entity-address.component.scss']
})
export class EntityAddressComponent implements OnInit {

  clase: string = "app-entity-address";
  @Output() emitUpdate = new EventEmitter<Boolean>();
  @Output() emitEntity = new EventEmitter<Entity>();
  @Input() tipoEntidad: string; 
  @Input() area: number;
  actualizado: Boolean = false;
  Entity: Entity;
  listTipoIdentificacion: TipoIdentificacion[];
  listPais: Paises[];
  listLocalidadTotal: Localidades[];
  listLocalidad: Localidades[];
  formModelEntidad = new FormControl({
    NombreFiscal: '',
    NombreComercial: '',
    TipoIdentificacion: null,
    CifNif: '',
    Direccion: '',
    Pais: '',
    Localidad: '',
    CodigoPostal: '',
    AptoCorreos: '',
    Telefono1: '',
    Telefono2: null,
    Email: '',
    Contacto: null,
    HoraInicio1: null,
    HoraFin1: null,
    HoraInicio2: null,
    HoraFin2: null,
  })

  loadingLocalidad: boolean = true;
  loadingPais: boolean = true;

  errorNombreFiscal: boolean = false;
  errorCIFNIF: boolean = false;
  errorDireccion: boolean = false;
  errorPais: boolean = false;
  errorEmail: boolean = false;
  errorTelefono1: boolean = false;
  errorCodigoPostal: boolean = false;
  errorLocalidad: boolean = false;
  cargandoLabel: string = "";

  constructor(private toastr: ToastrService, private entityService: EntityService,
    private shipmentService: ShipmentService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.shipmentService.getPaises().subscribe(data => {
      this.loadingPais = false;
      this.listPais = data;
    });

    this.cargandoLabel = this.translateService.instant("CargandoLabel");

    this.shipmentService.getLocalidades().subscribe(listaLocalidades => {
      this.loadingLocalidad = false;
      this.listLocalidadTotal = listaLocalidades;
      this.listLocalidad = listaLocalidades;
    });

    this.entityService.getTipoIdentificacion().subscribe(data => {
      this.listTipoIdentificacion = data;
    });
  }

  asignaValores(): Entity {
    const entidad = new Entity();
    entidad.NombreFiscal = this.formModelEntidad.value.NombreFiscal;
    entidad.Direccion = this.formModelEntidad.value.Direccion;
    entidad.CifNif = this.formModelEntidad.value.CifNif;
    entidad.IdPais = this.formModelEntidad.value.Pais;
    entidad.IdLocalidad = this.formModelEntidad.value.Localidad;
    entidad.CodigoPostal = this.formModelEntidad.value.CodigoPostal;
    entidad.AptoCorreos = this.formModelEntidad.value.ApdoCorreos;
    entidad.Telefono1 = this.formModelEntidad.value.Telefono1;
    entidad.Telefono2 = this.formModelEntidad.value.Telefono2;
    entidad.Email = this.formModelEntidad.value.Email;
    entidad.Contacto = this.formModelEntidad.value.Contacto;
    entidad.DesdePrimeraHora = this.formModelEntidad.value.HoraInicio1;
    entidad.HastaPrimeraHora = this.formModelEntidad.value.HoraFin1;
    entidad.DesdeSegundaHora = this.formModelEntidad.value.HoraInicio2;
    entidad.HastaSegundaHora = this.formModelEntidad.value.HoraFin2;
    switch(this.tipoEntidad)
    {
      case "shipper":
        entidad.IdTipoEntidad = 1;
      break;
      case "consignee":
        entidad.IdTipoEntidad = 2;
      break;
      case "notify":
        entidad.IdTipoEntidad = 3;
      break;
      default:
      break;
    }

    return entidad;
  }

  listadoLocalidades(){
    this.formModelEntidad.value.Localidad = null;
    if(this.formModelEntidad.value.Pais != undefined && this.formModelEntidad.value.Pais != null)
      this.listLocalidad = this.listLocalidadTotal.filter(l => l.IdPais == this.formModelEntidad.value.Pais);
    else
      this.listLocalidad = this.listLocalidadTotal;
  }

  setCodigoPostalWhenChangeLocalidad(){
    if(this.formModelEntidad.value.Localidad != undefined && this.formModelEntidad.value.Localidad != null)
      this.formModelEntidad.value.CodigoPostal = this.listLocalidadTotal.filter(l => l.IdLocalidad == this.formModelEntidad.value.Localidad)[0].CodigoPostal;
  }

  anyadirEntidad(form: NgForm, click: string) {
    const entidad = this.asignaValores();
    if(entidad.NombreFiscal != '' && entidad.IdPais != null && entidad.IdPais != undefined )
    {
      var log: LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      this.entityService.postEntidad(entidad, log).subscribe(data => {
        form.resetForm();
        var Body = this.translateService.instant('Entidad añadida correctamente');
        var Titulo = this.translateService.instant('Entidad correcta');
        this.toastr.success(Body, Titulo)
        this.actualizado = true;
        this.emitUpdate.emit(this.actualizado);
        this.emitEntity.emit(entidad);        
        this.actualizado = false;
      }, err => {
        var errorBody = this.translateService.instant('Error en la inserción de la entidad, póngase en contacto con el departamento de soporte.');
        var errorTitulo = this.translateService.instant('Error en el envío');
        this.toastr.error(errorBody, errorTitulo)
      });
    }
    else
    {
      var errorBody = this.translateService.instant('Faltan campos por rellenar');
        var errorTitulo = this.translateService.instant('Error en el envío');
        this.toastr.error(errorBody, errorTitulo)
    }
  }

  cerrarModal() {
    this.emitUpdate.emit(null);
    this.emitEntity.emit(null);
  }

  compruebaCIF() {
    if(this.formModelEntidad.value.Pais == 71 && (this.formModelEntidad.value.CifNif != '' 
    && this.formModelEntidad.value.CifNif != null && this.formModelEntidad.value.CifNif != undefined))
    {
      if(this.formModelEntidad.value.TipoIdentificacion == 3)
        this.verificacionCIF();
      else if(this.formModelEntidad.value.TipoIdentificacion == 2)
        this.verificacionNIF();
      else if(this.formModelEntidad.value.TipoIdentificacion == 1)
        this.verificacionNIE();
    }
  }

  //Funcion que verifica que el cif introducido es válido
  verificacionCIF() {
    let correcto = true;

    let letrasCodigo: string[] = [ 'J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I' ];
    let cif: string = this.formModelEntidad.value.CifNif;
    let digitoControl = cif.substr(8,1);
    let letra: string = cif.substr(0,1);

    let digitosControl: string = cif.substring(1, 8)
    let primerDigitoPar: number = Number(digitosControl.substr(1,1));
    let segundoDigitoPar: number = Number(digitosControl.substr(3,1));
    let tercerDigitoPar: number = Number(digitosControl.substr(5,1));
    let sumaCifrasPares: number = primerDigitoPar + segundoDigitoPar + tercerDigitoPar;
    let primerDigitoImpar: number = Number(digitosControl.substr(0,1));
    let segundoDigitoImpar: number = Number(digitosControl.substr(2,1));
    let tercerDigitoImpar: number = Number(digitosControl.substr(4,1));
    let cuartoDigitoImpar: number = Number(digitosControl.substr(6,1));
    let sumaMult1 = primerDigitoImpar*2;
    let sumaMult2 = segundoDigitoImpar*2;
    let sumaMult3 = tercerDigitoImpar*2;
    let sumaMult4 = cuartoDigitoImpar*2;
    let sumaCifrasImpares: number = (Number(sumaMult1.toString().substr(0,1)) + Number(sumaMult1.toString().substr(1,1))) +
                                    (Number(sumaMult2.toString().substr(0,1)) + Number(sumaMult2.toString().substr(1,1))) +
                                    (Number(sumaMult3.toString().substr(0,1)) + Number(sumaMult3.toString().substr(1,1))) +
                                    (Number(sumaMult4.toString().substr(0,1)) + Number(sumaMult4.toString().substr(1,1)));
    let sumaTotal = sumaCifrasPares + sumaCifrasImpares;
    let unidad = Number(sumaTotal.toString().substr(1,1));
    let numero = 10 - unidad;
    switch(letra)
    {
      // Sólo numeros
      case 'A':
      case 'B':
      case 'E':
      case 'H':
        //Se hace algo
        correcto = digitoControl == numero.toString();
        break;
      case 'K':
      case 'P':
      case 'Q':
      case 'S':
        //Se hace algo
        correcto = digitoControl == letrasCodigo[numero];
        break;
      default:
        correcto = (digitoControl == numero.toString()) ||
                    (digitoControl == letrasCodigo[numero]);
        break;
    }
    if(!correcto)
    {
      var errorBody = this.translateService.instant('CIF no válido');
      var errorTitulo = this.translateService.instant('Error CIF');
      this.toastr.error(errorBody, errorTitulo)
      this.errorCIFNIF = true;
      correcto = false;
    }
    return correcto;
  }

  verificacionNIF(): Boolean {
    let correcto = true;
    let letrasNif: String[] = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']
    let nif: string = this.formModelEntidad.value.CifNif;
    let letra: string = nif.substr(8, 1);
    let numNif: number = Number(nif.substr(0, 8));
    let resto = numNif % 23;
    if(letrasNif[resto] != letra)
    {
      var errorBody = this.translateService.instant('DNI no válido.');
      var errorTitulo = this.translateService.instant('Error DNI');
      this.toastr.error(errorBody, errorTitulo)
      correcto = false;
      this.errorCIFNIF = true;
    }
    return correcto;
  }

  verificacionNIE(): Boolean {
    let correcto = true;
    let letrasNie: String[] = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E']
    let nie: string = this.formModelEntidad.value.CifNif;
    let numNie: string = nie.substr(1, 7);
    let letra: string = nie.substr(8, 1);

    if(nie.substr(0,1) == 'X')
      nie = '0' + numNie.substr(0,8);
    else if(nie.substr(0,1) == 'Y')
      nie = '1' + numNie.substr(0,8);
    else if(nie.substr(0,1) == 'Z')
      nie = '2' + numNie.substr(0,8);

    let numNif: number = Number(nie.substr(0, 8));
    let resto = numNif % 23;
    if(letrasNie[resto] != letra)
    {
      var errorBody = this.translateService.instant('NIE no válido.');
      var errorTitulo = this.translateService.instant('Error NIE');
      this.toastr.error(errorBody, errorTitulo)
      correcto = false;
      this.errorCIFNIF = true;
    }
    return correcto;
  }

  compruebaNombreFiscal(): boolean {
    if(this.formModelEntidad.value.NombreFiscal == '')
      this.errorNombreFiscal = true;
    else
      this.errorNombreFiscal = false;

    return this.errorNombreFiscal;
  }

  compruebaCifNif(): boolean {
    if(this.formModelEntidad.value.CifNif == '')
      this.errorCIFNIF = true;
    else
      this.errorCIFNIF = false;

    return this.errorCIFNIF;
  }

  compruebaDireccion(): boolean {
    if(this.formModelEntidad.value.Direccion == '')
      this.errorDireccion = true;
    else
      this.errorDireccion = false;

    return this.errorDireccion;
  }

  compruebaCodigoPostal(): boolean {
    if(this.formModelEntidad.value.CodigoPostal == '')
      this.errorCodigoPostal = true;
    else
      this.errorCodigoPostal = false;

    return this.errorCodigoPostal;
  }

  compruebaPais(): boolean {
    if(this.formModelEntidad.value.Pais == '')
      this.errorPais = true;
    else
      this.errorPais = false;

    return this.errorPais;
  }

  compruebaLocalidad(): boolean {
    if(this.formModelEntidad.value.Localidad == '')
      this.errorLocalidad = true;
    else
      this.errorLocalidad = false;

    return this.errorLocalidad;
  }

  compruebaEmail(): boolean {
    if(this.formModelEntidad.value.Email == '')
      this.errorEmail = true;
    else
      this.errorEmail = false;

    return this.errorEmail;
  }

  compruebaTelefono1(): boolean {
    if(this.formModelEntidad.value.Telefono1 == '')
      this.errorTelefono1 = true;
    else
      this.errorTelefono1 = false;
      
    return this.errorTelefono1;
  }

  codPostalRequired(): Boolean {
    if(this.area == 1)
      return true;
    else
      return false;
  }

}
