import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AddressBook } from 'src/app/models/addressBook.model';
import { localidadGoogleMaps } from 'src/app/models/localidadGoogleMaps.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Paises } from 'src/app/models/paises.model';
import { AddressBookService } from 'src/app/services/addressBookService.service';
import { MapaService } from 'src/app/services/mapa.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-formulario-address-book',
  templateUrl: './formulario-address-book.component.html',
  styleUrls: ['./formulario-address-book.component.scss']
})
export class FormularioAddressBookComponent implements OnInit {
  
  @ViewChild('SearchField') searchField: ElementRef;
  private clase: string = "app-formulario-address-book";
  public formAddressBook: FormGroup;
  @Input() addressBook: AddressBook;
  @Output() limpiar = new EventEmitter<any>();
  @Output() actualizarAddressBooks = new EventEmitter<any>();
  @Output() emitCreateAddressBook = new EventEmitter<boolean>();
  textoLocalidad: string = "";
  obtenerPosicionInicial: boolean = true;
  inicializarComponente: boolean = false;
  latitude: number;
  longitude: number;
  latitudeMapa: number;
  longitudeMapa: number;
  zoom: number;
  autocomplete;
  existMarker: boolean = false;
  listaPaises: Paises[] = [];
  listaPaisesAux: Paises[] = [];
  localidadSelected: boolean = false;
  tamanyoMapa = { height: 150, width: 1000 };
  cargaPaises: boolean = true;
  

  constructor(private formBuilder: FormBuilder, private addresBookService: AddressBookService, private mapaService: MapaService, private envioService: ShipmentService,
     private changeDetectorRef: ChangeDetectorRef, private toastr: ToastrService, private translate: TranslateService) { }

  public get codigo() { return this.formAddressBook.get('codigo'); }
  public get address() { return this.formAddressBook.get('address'); }
  public get nombreFiscal() { return this.formAddressBook.get('nombreFiscal'); }
  //public get nombreComercial() { return this.formAddressBook.get('nombreComercial') }
  public get CIF() { return this.formAddressBook.get('CIF'); }
  public get telefono1() { return this.formAddressBook.get('telefono1'); }
  //public get telefono2() { return this.formAddressBook.get('telefono2') }
  //public get telefono3() { return this.formAddressBook.get('telefono3') }
  public get email() { return this.formAddressBook.get('email'); }
  public get nombreContacto() { return this.formAddressBook.get('nombreContacto'); }
  public get activa() { return this.formAddressBook.get('activa'); }
  public get localidad() { return this.formAddressBook.get('localidad'); }
  public get pais() { return this.formAddressBook.get('pais'); }

  ngOnInit(): void {
    this.inicializar();
  }

  inicializar(){
    this.formAddressBook = this.formBuilder.group(
      {
        codigo: [''],
        address: ['', Validators.required],
        nombreFiscal: ['', [Validators.required]],
        //nombreComercial: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)/*, nifValidator()*/]],
        CIF: [''],
        telefono1: [''],
        /*telefono2: ['', [Validators.email, Validators.required]],
        telefono3: [''],*/
        email: ['', [Validators.email]],
        activa: [true],
        nombreContacto: [''],
        localidad: ['', Validators.required],
        pais: [null, Validators.required]
      });
      this.addresBookService.getAddressBook$().subscribe(ab => {
        this.addressBook = ab;
        this.inicializarComponente = true;
        this.setAddressBook();
        this.getLocalidadOrigenEmit(this.addressBook.localidad);
      });
      this.cargarPaises();
  }

  cargarPaises(){
    this.envioService.getPaises().subscribe(paises => {
      this.cargaPaises = false;
      this.listaPaisesAux = paises;
      this.listaPaises = paises;
    });
  }

  ngOnChanges(changes: SimpleChange){
    
  }

  getLocalidadOrigenEmit(localidad: localidadGoogleMaps){
    this.changeDetectorRef.detectChanges();
    var paises = this.listaPaises.filter(p => p.IsoAlfa2 == localidad.IsoAlfa2);
    console.log(paises);
    if(paises != null && paises != undefined && paises.length > 0 && paises[0].IdPais == this.pais.value.IdPais)
    {
      this.autocompleteOrigenInicializar();
      // if(this.localidadSelected)
      // {
        localidad.IdPais = paises[0].IdPais;
        this.localidad.setValue(localidad);
      //}
      if(localidad.Ciudad != undefined && localidad.Ciudad != null)
      {
        this.searchField.nativeElement.value = localidad.Ciudad;
        if(localidad.Administrative_area_level_2 != undefined && localidad.Administrative_area_level_2 != null && localidad.Administrative_area_level_2 != "")
          this.searchField.nativeElement.value += ", " + localidad.Administrative_area_level_2;
        if(localidad.Administrative_area_level_1 != undefined && localidad.Administrative_area_level_1 != null && localidad.Administrative_area_level_1 != "")
          this.searchField.nativeElement.value += ", " + localidad.Administrative_area_level_1;
      }
      this.existMarker = true;
      this.latitude = localidad.Lat;
      this.longitude = localidad.Lng;
      this.latitudeMapa = this.latitude;
      this.longitudeMapa = this.longitude;
      this.zoom = this.getBoundsZoomLevel(new google.maps.LatLngBounds(new google.maps.LatLng(this.latitude-0.005, this.longitude-0.005), new google.maps.LatLng(this.latitude+0.005, this.longitude+0.005)), this.tamanyoMapa);
      
      if(this.inicializarComponente)
        this.mapaService.setTextoLocalidadOrigen$(localidad.Ciudad);
      this.inicializarComponente = false;
    }else
      this.localidad.setValue(null);
    
    if(paises.length > 0)
    {
      this.pais.setValue(paises[0]);
      this.autocomplete.setComponentRestrictions({country: paises[0].IsoAlfa2});
    }
  }

  getBandera(a: Paises) {
    return 'assets/banderas/' + a.IsoAlfa2.toLowerCase() + '.png';
  }

  changePais(){
    this.abrirMapa();
    this.pais.setValue(this.listaPaises.find(p => p.IdPais == this.pais.value.IdPais));
    this.autocompleteOrigenInicializar();
  }
  
  abrirMapa(){
    if(this.pais.valid){
      this.localidadSelected = true;
      this.existMarker = false;
      this.latitude = this.longitude = null;
      this.pais.setValue(this.listaPaises.find(p => p.IdPais == this.pais.value.IdPais));
      var geocoder = new google.maps.Geocoder;
      var me = this;
      console.log(this.pais.value);
      geocoder.geocode({'address': this.pais.value.Nombre}, function(results, status){
        if(status === google.maps.GeocoderStatus.OK){
          me.existMarker = false;
          me.latitudeMapa = results[0].geometry.location.lat();
          me.longitudeMapa = results[0].geometry.location.lng();
          me.localidad.setValue(null);
          me.searchField.nativeElement.value = "";
          me.zoom = me.getBoundsZoomLevel(results[0].geometry.viewport, me.tamanyoMapa);
        }
      });
    }else
      this.toastr.error("Tienes que seleccionar el pais origen", "Error pais origen");
    
  }

  onKey(value) { 
    this.listaPaises = this.search(value);
    }
    
    search(value: string) { 
      let filter = value.toLowerCase();
      return this.listaPaisesAux.filter(option => option.Nombre.toLowerCase().startsWith(filter));
    }

  autocompleteOrigenInicializar(){
    if(this.autocomplete != undefined && this.autocomplete != undefined)
      this.autocomplete.setComponentRestrictions({country: this.pais.value.IsoAlfa2});
    else
    {
      var options = {
        strictBounds: false,
        componentRestrictions: {country: this.pais.value.IsoAlfa2}
      };
      var me = this;
      this.autocomplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, options);
      this.autocomplete.addListener("place_changed", () => {
        let place: google.maps.places.PlaceResult = 
        this.autocomplete.getPlace();
        //verify result
        if (place === undefined || place === null || place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        me.existMarker = true;
        me.latitude = place.geometry.location.lat();
        me.longitude = place.geometry.location.lng();
        me.latitudeMapa = me.latitude;
        me.longitudeMapa = me.longitude;
        me.mapaService.setTextoLocalidadOrigen$(place.name + " " + place.formatted_address + " " + this.pais.value.Nombre);
        me.zoom = me.getBoundsZoomLevel(place.geometry.viewport, me.tamanyoMapa);
      });
    }
  }

  getBoundsZoomLevel(bounds, mapDim) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    
    return Math.min(latZoom+1, lngZoom+1, ZOOM_MAX);
  }

  setAddressBook(){
    if(this.isUpdate()){
      this.codigo.setValue(this.addressBook.codigo);
      this.address.setValue(this.addressBook.address);
      this.nombreFiscal.setValue(this.addressBook.nombreFiscal);
      //this.nombreComercial.setValue(this.addressBook.nombreComercial);
      this.CIF.setValue(this.addressBook.CIF);
      this.telefono1.setValue(this.addressBook.telefono1);
      //this.telefono2.setValue(this.addressBook.telefono2);
      //this.telefono3.setValue(this.addressBook.telefono3);
      this.email.setValue(this.addressBook.email);
      this.nombreContacto.setValue(this.addressBook.nombreContacto);
      this.activa.setValue(this.addressBook.activa);
      // this.localidad.setValue(this.lista)
      this.pais.setValue(this.listaPaises.find(p => p.IdPais == this.addressBook.pais));
      
      console.log(this.listaPaises.find(p => p.IdPais == this.addressBook.pais));
      console.log(this.pais.value);
    }
  }

  resetear(){
    this.addressBook = undefined;
    this.inicializar();
  }

  obtenerAddressBook(){
    var addressBook = new AddressBook();
    addressBook.codigo = this.codigo.value == '' || this.codigo.value == null || this.codigo.value == undefined ? 0 : this.codigo.value;
    addressBook.address = this.address.value;
    //addressBook.nombreComercial = this.nombreComercial.value;
    addressBook.nombreFiscal = this.nombreFiscal.value;
    //addressBook.nombreComercial = this.nombreComercial.value;
    addressBook.CIF = this.CIF.value;
    addressBook.email = this.email.value;
    addressBook.telefono1 = this.telefono1.value;
    //addressBook.telefono2 = this.telefono2.value;
    //addressBook.telefono3 = this.telefono3.value;
    addressBook.nombreContacto = this.nombreContacto.value;
    addressBook.activa = this.activa.value;
    addressBook.pais = this.pais.value.IdPais;
    addressBook.localidad = this.localidad.value;
    return addressBook;
  }

  isUpdate(){
    return this.addressBook != null && this.addressBook != undefined && this.addressBook.codigo != null && this.addressBook.codigo != undefined;
  }

  postAddressBook(click: string){
    if(this.formAddressBook.valid){
      var addressBook = this.obtenerAddressBook();
      var logModel: LogModel = new LogModel();
      logModel.Click = click;
      logModel.Pantalla = this.clase;
      this.addresBookService.postAddressBook(addressBook, logModel).subscribe(data => {
        this.formAddressBook.reset();
        this.searchField.nativeElement.value = '';
        this.addressBook = undefined;
        this.addresBookService.getAllAddressBook();
        var body: string = this.translate.instant("BodyAddressBookCreadoOk");
        var title: string = this.translate.instant("TitleAddressBookCreadoOk");
        this.toastr.success(body, title);
        this.emitCreateAddressBook.emit(true);
      }, err => {
        if(err.status == 450){
          var title: string = this.translate.instant("YaExisteAddressBook");
          this.toastr.warning("", title);
        }
        else{
          var body: string = this.translate.instant("BodyAddressBookCreadoError");
          var title: string = this.translate.instant("TitleAddressBookCreadoError");
          this.toastr.error(body, title);
        }
      });
    }else{
      var title: string = this.translate.instant("Campos faltan por rellenar");
      var body: string = this.translate.instant("Se ha detectado algún campo erróneo o falta por introducirse, por favor revíselo.");
      this.toastr.error(body, title);
    }
  }

  updateAddressBook(click: string){
    if(this.formAddressBook.valid){
      var addressBook = this.obtenerAddressBook();
      var logModel: LogModel = new LogModel();
      logModel.Click = click;
      logModel.Pantalla = this.clase;
      this.addresBookService.putAddressBook(addressBook, logModel).subscribe(data => {
        var title: string = this.translate.instant("TitleAddressBookActualizarOk");
        var body: string = this.translate.instant("BodyAddressBookActualizarOk");
        this.formAddressBook.reset();
        this.addressBook = undefined;
        this.addresBookService.getAllAddressBook();
        this.toastr.success(body, title);
        this.emitCreateAddressBook.emit(false);
      }, err => {
        var body: string = this.translate.instant("BodyAddressBookActualizarError");
        var title: string = this.translate.instant("TitleAddressBookActualizarError");
        this.toastr.error(body, title);
      });
    }else{
      var title: string = this.translate.instant("Campos faltan por rellenar");
        var body: string = this.translate.instant("Se ha detectado algún campo erróneo o falta por introducirse, por favor revíselo.");
        this.toastr.error(body, title);
    }
  }

  comprobarErrorPaisSeleccionado(){
    if(!this.pais.valid){
      var title: string = this.translate.instant("TitlePaisNotSelected");
      var body: string = this.translate.instant("BodyPaisNotSelected");
      this.toastr.error(body, title);
    }
  }
}
