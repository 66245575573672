<mat-form-field appearance="standard" class="width-100">
    <mat-label>{{'Búsqueda' | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
    <button mat-button matPrefix mat-icon-button>
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
<table mat-table [dataSource]="conductores" class="mat-elevation-z0">

    <ng-container matColumnDef="usuario">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.NumSocio}} </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> {{'Nombre'| translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.Nombre}} </td>
    </ng-container>

    <ng-container matColumnDef="apellidos">
        <th mat-header-cell *matHeaderCellDef> {{'Apellidos'| translate}} </th>
        <td mat-cell *matCellDef="let element">
            {{element.Apellidos}}
        </td>
    </ng-container>

    <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-icon class="cursor" (click)="setRow(element);">create</mat-icon>
            <mat-icon class="delete-icon cursor"
                (click)="conductor = element; openModal(templateDesactivarConductor);">delete</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="nif">
        <th mat-header-cell *matHeaderCellDef> NIF </th>
        <td mat-cell *matCellDef="let element"> {{element.NIF}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
</table>

<mat-paginator [length]="lengthTable" [pageSizeOptions]="sizeOptions" [pageSize]="itemsPorPage"
    showFirstLastButtons></mat-paginator>

<ng-template #templateDesactivarConductor>
    <div class="modal-header fondo">
        <p class="h4 modal-title pull-left">{{'Borrar conductor'|translate}}</p>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body fondo">
        <app-borrar-conductor [conductorSelected]="conductor" (cerrarModal)="cerrar($event)"></app-borrar-conductor>
    </div>
</ng-template>