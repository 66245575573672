import { Component, OnInit, Input, SimpleChanges, TemplateRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Incidencia } from 'src/app/models/incidencia.model';
import { FormControl } from '@angular/forms';
import { IncidenciasService } from 'src/app/services/incidencias.service';
import { TipoIncidenciaConductor } from 'src/app/models/tipo-incidencia.model';
import { ToastrService } from 'ngx-toastr';
import { Archivo } from 'src/app/models/archivo.model';
import { GaleriaImagenesComponent } from './galeria-imagenes/galeria-imagenes.component';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { TranslateService } from '@ngx-translate/core';
import { LogModel } from 'src/app/models/log-model.model';

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss']
})
export class IncidenciasComponent implements OnInit {
  clase: string = "app-incidencias";
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;
  @Output() abrir = new EventEmitter<Boolean>();
  @Output() cerrarModal = new EventEmitter<number>();
  nueva: Boolean;
  listado: Boolean;
  isMovil: Boolean;
  @Input() historico: Boolean;
  @Input() expedicion: string;
  @Input() idExpedicion: number;
  @Input() Incidencia: Incidencia;
  listaIncidencias: Incidencia[];
  tipoincidencia: number;
  descripcion: string;
  listaTipoIncidencias: TipoIncidenciaConductor[];
  photo: string[];
  photoServer: string[];
  photoAux: String[];

  formIncidencias = new FormControl({
    IdIncidenciaExpedicion: 0,
    OrigenIncidencia: null,
    FechaCreacion: '',
    FechaCierre: '',
    Descripcion: '',
    IdTipoIncidencia: null,
    Expedicion: '',
    IdTrackingExpedicion: null
  })

  resetFormIncidencias(form?: any){
    this.formIncidencias.value.IdIncidenciaExpedicion = 0;
    this.formIncidencias.value.OrigenIncidencia = null;
    this.formIncidencias.value.FechaCreacion = '';
    this.formIncidencias.value.FechaCierre = '';
    this.formIncidencias.value.Descripcion = '';
    this.formIncidencias.value.IdTipoIncidencia = null;
    this.formIncidencias.value.Expedicion = '';
    this.formIncidencias.value.IdTrackingExpedicion = null;
    this.photo = [];
  }


  constructor(public dialog: MatDialog, private incidenciaService: IncidenciasService, 
    private toastr: ToastrService, private modalService: BsModalService, private translateService: TranslateService) { 
    this.photo = [];
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 2 
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cerrar(){
    this.cerrarModal.emit(1);
  }

  closeFirstModal() {
    if (!this.modalRef) {
      return;
    }
 
    this.modalRef.hide();
    this.modalRef = null;
  }
  
  closeModal(modalId?: number){
    this.modalService.hide(modalId);
  }

  getIncidencias(){
    if(this.idExpedicion != undefined){
      this.incidenciaService.obtenerListadoIncidencias(this.idExpedicion).subscribe(data => {
        this.listaIncidencias = data;
      },
        error => {
          var errorBody = this.translateService.instant('Error en la conexión.');
          var errorTitulo = this.translateService.instant('Error');
          this.toastr.error(errorBody, errorTitulo)
        });
    }
  }

  ngOnInit(): void {

    this.incidenciaService.obtenerTipoIncidencia().subscribe(data => {
      this.listaTipoIncidencias = data as TipoIncidenciaConductor[];
    },
    error => {
      var errorBody = this.translateService.instant('Error en la conexión.');
      var errorTitulo = this.translateService.instant('Error');
      this.toastr.error(errorBody, errorTitulo)
    })
    this.getIncidencias();
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }


  hidden() {
    return this.formIncidencias.value.IdIncidenciaExpedicion == null && this.photo.length == 0
  }

  isHidden(): Boolean {
    return (this.historico || (this.formIncidencias.value.FechaCierre != undefined && this.formIncidencias.value.FechaCierre != ''))
  }

  //Funcion que pasa el modelo incidencia al form
  getIncidencia(incidencia: Incidencia) {
    this.Incidencia = incidencia;
    this.formIncidencias.value.IdIncidenciaExpedicion = incidencia.IdIncidenciaExpedicion;
    this.formIncidencias.value.OrigenIncidencia = incidencia.OrigenIncidencia;
    this.formIncidencias.value.FechaCreacion = incidencia.FechaCreacion;
    this.formIncidencias.value.FechaCierre = incidencia.FechaCierre;
    this.formIncidencias.value.Descripcion = incidencia.Descripcion;
    this.descripcion = incidencia.Descripcion;
    this.formIncidencias.value.IdTipoIncidencia = incidencia.IdTipoIncidencia;
    this.tipoincidencia = incidencia.IdTipoIncidencia;
    this.formIncidencias.value.Expedicion = incidencia.Expedicion;
    this.formIncidencias.value.IdTrackingExpedicion = incidencia.IdTrackingExpedicion;
    if(incidencia.Archivos != [] && incidencia.Archivos != null)
      incidencia.Archivos.forEach(element => {
        this.photoServer.push(element.Base64image.toString());
      });
    else
      this.photoServer = [];
  }

  setTipoIncidencia(tipoincidencia:TipoIncidenciaConductor)
  {
    this.formIncidencias.value.IdTipoIncidencia = tipoincidencia.IdTipoIncidenciaConductor;
  }

  UnirArrayImagenes(){
    this.abrir.emit(true);
    if(this.photoServer == undefined)
      this.photoAux = this.photo;
    else if(this.photo == undefined)
      this.photoAux = this.photoServer;
    else
      this.photoAux = this.photoServer.concat(this.photo);

    //Se utiliza para que detecte cambio despues del push, en un push no se detecta cambios porque no compara el contenido del mismo
    //con el slice parece ser que cambia algo que hace que funcione ngOnChanges
    this.photoAux = this.photoAux.slice();
  }

  //Obtenemos los datos de la descripcion en el formulario
  setDescripcion(descripcion: String)
  {
    this.formIncidencias.value.Descripcion = this.descripcion;
  }

  getIdIncidencia() {
    return this.formIncidencias.value.IdIncidenciaExpedicion;
  }

  //Funcion que envia la incidencia a la bbdd y la añada.
  enviarIncidencia(form, click: string){    
    let incidencia = this.convertFormToModel(form);
    var log : LogModel = new LogModel();
      log.Pantalla = this.clase;
      log.Click = click;
    if(incidencia.IdIncidenciaExpedicion != undefined && incidencia.IdIncidenciaExpedicion != 0)
    {
      const dialogRef = this.dialog.open(WaitingComponent, {
        width: '250px',
        panelClass: 'my-panel',
        disableClose: true
      });
      dialogRef.afterOpened().subscribe(result => {});
      this.incidenciaService.modificarIncidencia(incidencia, log).subscribe(data => {
        var Body = this.translateService.instant('La incidencia se ha enviado correctamente.');
        var Titulo = this.translateService.instant('Incidencia enviada');
        this.toastr.success(Body, Titulo)
        this.getIncidencias();
        form.resetForm();
        this.resetFormIncidencias();
        dialogRef.close()
      },
      error => {
        var errorBody = this.translateService.instant('No se ha podido enviar la incidencia, póngase en contacto con el dep. terrestre.');
        var errorTitulo = this.translateService.instant('Error');
        this.toastr.error(errorBody, errorTitulo)
        dialogRef.close();
      })
    }
    else
    {
      if(incidencia.Descripcion != '' && incidencia.Descripcion != null && incidencia.Descripcion != undefined && incidencia.IdTipoIncidencia != null 
      && incidencia.IdTipoIncidencia != undefined)
      {
        const dialogRef = this.dialog.open(WaitingComponent, {
          width: '250px',
          panelClass: 'my-panel',
          disableClose: true
        });
        dialogRef.afterOpened().subscribe(result => {});
        this.incidenciaService.enviarIncidencia(incidencia, log).subscribe(data => {
          var Body = this.translateService.instant('La incidencia se ha enviado correctamente.');
          var Titulo = this.translateService.instant('Incidencia enviada');
          this.toastr.success(Body, Titulo)
          form.resetForm();
          this.getIncidencias();
          this.resetFormIncidencias();
          dialogRef.close();
        },
        error => {
          var errorBody = this.translateService.instant('No se ha podido enviar la incidencia, póngase en contacto con el dep. terrestre.');
          var errorTitulo = this.translateService.instant('Error');
          this.toastr.error(errorBody, errorTitulo)
          dialogRef.close();
        });
      }
      else
      {
        var errorBody = this.translateService.instant('No se puede enviar una incidencia sin un tipo de incidencia y una descripción.');
        var errorTitulo = this.translateService.instant('Error');
        this.toastr.error(errorBody, errorTitulo)
      }
    }
  }

  //Funcion que pasa los datos del formulario al modelo
  convertFormToModel(form): Incidencia
  {
    let incidencia = new Incidencia();
    if(this.formIncidencias.value.IdIncidenciaExpedicion != undefined)
      incidencia.IdIncidenciaExpedicion = this.formIncidencias.value.IdIncidenciaExpedicion;
    else
      incidencia.IdIncidenciaExpedicion = 0;
      
    incidencia.IdTrackingExpedicion = this.formIncidencias.value.IdTrackingExpedicion;
    incidencia.IdTipoIncidencia = this.formIncidencias.value.IdTipoIncidencia;
    incidencia.OrigenIncidencia = this.formIncidencias.value.OrigenIncidencia;
    incidencia.Descripcion = this.formIncidencias.value.Descripcion;
    incidencia.FechaCreacion = this.formIncidencias.value.FechaCreacion;
    incidencia.FechaCierre = this.formIncidencias.value.FechaCierre;
    incidencia.Expedicion = this.expedicion;
    incidencia.Archivos = this.photoFromStringToArchivo();
    return incidencia;
  }

  //Funcion que pasa el array de string a un array de archivos
  photoFromStringToArchivo(): Archivo[]{
    let listArchivos: Archivo[] = [];
    if(this.photo.length > 0)
    {
      this.photo.forEach(element => {
        let archivo: Archivo = new Archivo();
        archivo.Base64image = element
        archivo.IdDocumento = 0;
        archivo.FileName = "";
        archivo.Path = "";
        archivo.Tamanyo = 0;
        archivo.TipoArchivo = "Incidencia";
        listArchivos.push(archivo);
      });
    }
    return listArchivos;
  }


  //Funcion que añade las fotos realizadas por movil o seleccionadas desde el pc a un array, solo se puede de 1 en 1
  fileChanges(fileList){
    if(fileList.length > 0){
      let me = this;
      let file = fileList[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if(reader.result.toString().includes("data:image/"))
        {
          me.photo.push(reader.result.toString());
        }
        else
        {
          var errorBody = me.translateService.instant('El archivo seleccionado no es una imagen.');
          var errorTitulo = me.translateService.instant('Error');
          me.toastr.error(errorBody, errorTitulo)
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }else{
      this.photo = [];
    }
  }
  
  close() {
    //window.document.getElementById('Galeria').style.display = 'none';
    window.document.getElementById('Galeria').click();
  }

}
