<div class="row">
    <div class="col-sm-12">
        <p>{{'¿Estás seguro que desea borrar el address book?'|translate}}</p>
    </div>
</div>
<div class="row">
    <div class="col-sm-6">
        <button class="btn" type="button" (click)="putAddressBook('boton-borrado-address-book')">{{'Borrar'|translate}}</button>
    </div>
    <div class="col-sm-6">
        <button class="btn" type="button" (click)="cancelar();">{{'Cancelar'|translate}}</button>
    </div>
</div>
