export class Vehiculo {
    IdVehiculo: string;
    Matricula: string;
    IdTipoVehiculo: string;
    Tipo: string;
    Peso: string;
    Volumen: string;
    Largo: number;
    Ancho: number;
    Alto: number;
    Adr: string;
    Activo: boolean;
    IdEspecialidadVehiculo: string;
    Especialidad: string;
    Remolque: string;
}
