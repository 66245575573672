import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Angular2SignaturepadModule } from 'angular2-signaturepad';
import { NgbPaginationModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from './../environments/environment';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

//--------------- Material ------------------
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';

//-------------------------------------------

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/users/customers/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFound404Component } from './components/errors/not-found404/not-found404.component';
import { LoginComponent } from './components/login/login.component';
import { LoginService } from '../app/services/login.service';
import { CargoComponent } from './components/users/customers/cargo/cargo.component';
import { ListShipmentComponent } from './components/users/customers/list-shipment/list-shipment.component';
import { AereoShipmentComponent } from './components/users/customers/aereo-shipment/aereo-shipment.component';
import { TerrestreShipmentComponent } from './components/users/customers/terrestre-shipment/terrestre-shipment.component';
import { MaritimoShipmentComponent } from './components/users/customers/maritimo-shipment/maritimo-shipment.component';
import { DndDirectiveDirective } from './components/componentesAuxiliares/dnd-directive.directive';
import { UploadFileComponent } from './components/componentesAuxiliares/upload-file/upload-file.component';
import { UploadModalComponent } from './components/componentesAuxiliares/upload-modal/upload-modal.component';
import { ListEntitiesComponent } from './components/users/customers/entity/list-entities/list-entities.component';
import { EntityAddressComponent } from './components/users/customers/entity/entity-address/entity-address.component';
import { CustomerIndexComponent } from './components/users/customers/customer-index/customer-index.component';
import { ShippingFilesComponent } from './components/users/customers/shipping-files/shipping-files.component';
import { ShippingTrackingComponent } from './components/users/customers/shipping-tracking/shipping-tracking.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConductorComponent } from './components/users/conductor/conductor.component';
import { ModelEstadoComponent } from './components/users/conductor/model-estado/model-estado.component';
import { HistorialComponent } from './components/users/conductor/historial/historial.component';
import { PreviewDownloadComponent } from './components/users/conductor/historial/preview-download/preview-download.component';
import { ModalDownloadComponent } from './components/users/conductor/historial/modal-download/modal-download.component';
import { DetallesComponent } from './components/users/conductor/detalles/detalles.component';
import { LoginConductorComponent } from './components/login/login-conductor/login-conductor.component';
import { HeaderComponent as HeaderConductor } from './components/users/conductor/header/header.component';
import { HeaderComponent as HeaderNoLogin } from './components/header/header.component';
import { IncidenciasComponent } from './components/users/conductor/incidencias/incidencias.component';
import { ListIncidenciasComponent } from './components/users/conductor/list-incidencias/list-incidencias.component';
import { GaleriaImagenesComponent } from './components/users/conductor/incidencias/galeria-imagenes/galeria-imagenes.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { PlantillasComponent } from './components/users/customers/plantillas/plantillas.component';
import { MercanciaPeligrosaComponent } from './components/users/customers/cargo/mercancia-peligrosa/mercancia-peligrosa.component';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { DireccionesEntidadComponent } from './components/users/customers/entity/direcciones-entidad/direcciones-entidad.component';
import { AnyadirDireccionComponent } from './components/users/customers/entity/anyadir-direccion/anyadir-direccion.component';
import { ConfirmacionSeguroComponent } from './components/users/customers/confirmacion-seguro/confirmacion-seguro.component';
import { ConfirmacionBorradoComponent } from './components/componentesAuxiliares/confirmacion-borrado/confirmacion-borrado.component';
import { HscodesComponent } from './components/users/customers/hscodes/hscodes.component';
import { FirmaAlbaranComponent } from './components/users/conductor/model-estado/firma-albaran/firma-albaran.component';
import { MatSortModule } from '@angular/material/sort';

import { ConfirmacionPlantillaComponent } from './components/componentesAuxiliares/confirmacion-plantilla/confirmacion-plantilla.component';
import { WaitingComponent } from './components/waiting/waiting.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SubirFacturaComponent } from './components/users/conductor/historial/subir-factura/subir-factura.component';
import { ActualizarFacturaComponent } from './components/componentesAuxiliares/actualizar-factura/actualizar-factura.component';
import { AvisoSeguroComponent } from './components/componentesAuxiliares/aviso-seguro/aviso-seguro.component';
import { SubirPendientesComponent } from './components/users/conductor/model-estado/subir-pendientes/subir-pendientes.component';
import { TerminosYCondicionesComponent } from './components/terminos-y-condiciones/terminos-y-condiciones.component';
import { HeaderFijoComponent } from './components/header/header-fijo/header-fijo.component';
import { RouterModule } from '@angular/router';
import { SubirPendientesHojarutaComponent } from './components/users/conductor/model-estado/subir-pendientes-hojaruta/subir-pendientes-hojaruta.component';
import { MiPerfilComponent } from './components/users/mi-perfil/mi-perfil.component';
import { MiPerfilAjustesComponent } from './components/users/ajustes/mi-perfil/mi-perfil.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { HeaderLanguageComponent } from './components/header/header-language/header-language.component';
import { ResumenEnvioComponent } from './components/users/customers/resumen-envio/resumen-envio.component';
import { OlvidoPasswordComponent } from './components/login/olvido-password/olvido-password.component';
import { HomeComponent } from './components/users/proveedor/home/home.component';
import { HeaderProveedorComponent } from './components/users/proveedor/header-proveedor/header-proveedor.component';
import { ExpedicionesComponent } from './components/users/proveedor/expediciones/expediciones.component';
import { ConductoresComponent } from './components/users/proveedor/conductores/conductores.component';
import { HistorialProveedorComponent } from './components/users/proveedor/historial-proveedor/historial-proveedor.component';
import { MatriculasComponent } from './components/users/proveedor/matriculas/matriculas.component';
import { RutasComponent } from './components/users/proveedor/rutas/rutas.component';
import { DocumentacionProveedorComponent } from './components/users/proveedor/documentacion-proveedor/documentacion-proveedor.component';
import { AsignarConductorComponent } from './components/users/proveedor/asignar-conductor/asignar-conductor.component';
import { DetallesExpedicionComponent } from './components/users/proveedor/detalles-expedicion/detalles-expedicion.component';
import { AsignarMatriculaComponent } from './components/users/proveedor/asignar-matricula/asignar-matricula.component';
import { FormConductorComponent } from './components/users/proveedor/form-conductor/form-conductor.component';
import { ListaConductoresComponent } from './components/users/proveedor/lista-conductores/lista-conductores.component';
import { BorrarConductorComponent } from './components/users/proveedor/borrar-conductor/borrar-conductor.component';
import { nifValidator } from './validators/nif-validator.validator';
import { MultipleExpedicionConductorComponent } from './components/users/proveedor/multiple-expedicion-conductor/multiple-expedicion-conductor.component';
import { arrayValidator } from './validators/array-validator.validator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ListaVehiculosComponent } from './components/users/proveedor/lista-vehiculos/lista-vehiculos.component';
import { FormVehiculoComponent } from './components/users/proveedor/form-vehiculo/form-vehiculo.component';
import { TablePendientesComponent } from './components/users/proveedor/expediciones/table-pendientes/table-pendientes.component';
import { TableRecogidasComponent } from './components/users/proveedor/expediciones/table-recogidas/table-recogidas.component';
import { TableHojasRutaComponent } from './components/users/proveedor/expediciones/table-hojas-ruta/table-hojas-ruta.component';
//import { BorrarVehiculoComponent } from './components/users/proveedor/borrar-vehiculo/borrar-vehiculo.component';
import { AjustesComponent } from './components/users/ajustes/ajustes.component';
import { ConfiguracionComponent } from './components/users/ajustes/configuracion/configuracion.component';
import { EmbalajesComponent } from './components/users/ajustes/configuracion/embalajes/embalajes.component';
import { ListaEmbalajesComponent } from './components/users/ajustes/configuracion/lista-embalajes/lista-embalajes.component';
import { HorarioComponent } from './components/users/ajustes/configuracion/horario/horario.component';
import { RegistroUsuarioComponent } from './components/login/registro-usuario/registro-usuario.component';
import { cifValidator } from './validators/cif-validator.validator';
import { BorrarVehiculoComponent } from './components/users/proveedor/borrar-vehiculo/borrar-vehiculo.component';
import { MultipleExpedicionMatriculaComponent } from './components/users/proveedor/multiple-expedicion-matricula/multiple-expedicion-matricula.component';
import { CambiarPasswordPrimeraVezComponent } from './components/users/customers/cambiar-password-primera-vez/cambiar-password-primera-vez.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ChartsModule } from 'ng2-charts';
import { SubirFicheroEntidadComponent } from './components/users/proveedor/subir-fichero-entidad/subir-fichero-entidad.component';
import { ResumenTrackingComponent } from './components/users/customers/resumen-tracking/resumen-tracking.component';
import { AvisoSiNoComponent } from './components/componentesAuxiliares/aviso-si-no/aviso-si-no.component';
import { GeneralComponent } from './components/users/ajustes/configuracion/general/general.component';
import { FacturacionComponent } from './components/users/customers/facturacion/facturacion.component';
import { ListadoComponent } from './components/users/customers/facturacion/listado/listado.component';
import { PreviewPdfComponent } from './components/users/customers/facturacion/listado/preview-pdf/preview-pdf.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ListCotizacionComponent } from './components/users/customers/cotizaciones/list-cotizacion/list-cotizacion.component';
import { DetallesCotizacionComponent } from './components/users/customers/cotizaciones/detalles-cotizacion/detalles-cotizacion.component';
import { DocumentosCotizacionComponent } from './components/users/customers/cotizaciones/documentos-cotizacion/documentos-cotizacion.component';
import { ShareTrackingComponent } from './components/users/customers/share-tracking/share-tracking.component';
import { TrackingDetailsComponent } from './components/users/customers/tracking-details/tracking-details.component';
import { ShippingTrackingShareComponent } from './components/users/customers/shipping-tracking-share/shipping-tracking-share.component';
import { DetallesMercanciaComponent } from './components/users/customers/cotizaciones/detalles-cotizacion/detalles-mercancia/detalles-mercancia.component';
import { DetallesMercanciaPeligrosaComponent } from './components/users/customers/cotizaciones/detalles-cotizacion/detalles-mercancia/detalles-mercancia-peligrosa/detalles-mercancia-peligrosa.component';
import { CotizacionAereaComponent } from './components/users/customers/cotizaciones/cotizacion-aerea/cotizacion-aerea.component';
import { CotizacionMaritimoComponent } from './components/users/customers/cotizaciones/cotizacion-maritimo/cotizacion-maritimo.component';
import { CotizacionTerrestreComponent } from './components/users/customers/cotizaciones/cotizacion-terrestre/cotizacion-terrestre.component';
import { TableEntregaSinAlbaranComponent } from './components/users/proveedor/expediciones/table-entrega-sin-albaran/table-entrega-sin-albaran.component';
import { ResumenCotizacionComponent } from './components/users/customers/cotizaciones/resumen-cotizacion/resumen-cotizacion.component';
import { ListadoCotizacionesComponent } from './components/users/customers/cotizaciones/list-cotizacion/listado-cotizaciones/listado-cotizaciones.component';
import { MapaComponent } from './components/componentesAuxiliares/mapa/mapa.component';
import { DetallesContenedorComponent } from './components/users/customers/cotizaciones/detalles-cotizacion/detalles-contenedor/detalles-contenedor.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { AceptarRechazarComponent } from './components/users/customers/cotizaciones/list-cotizacion/aceptar-rechazar/aceptar-rechazar.component';
import { GradosComponent } from './components/users/customers/cargo/grados/grados.component';
import { CostesShippingComponent } from './components/users/customers/costes-shipping/costes-shipping.component';
import { DetallesGradosComponent } from './components/users/customers/cotizaciones/detalles-cotizacion/detalles-mercancia/detalles-grados/detalles-grados.component';
import { ListadoShipmentComponent } from './components/users/customers/list-shipment/listado-shipment/listado-shipment.component';
import { IncidenciasPartidaComponent } from './components/users/customers/incidencias-partida/incidencias-partida.component';
import { AddressBookComponent } from './components/users/ajustes/address-book/address-book.component';
import { ListaAddressBookComponent } from './components/users/ajustes/address-book/lista-address-book/lista-address-book.component';
import { FormularioAddressBookComponent } from './components/users/ajustes/address-book/formulario-address-book/formulario-address-book.component';
import { BorrarAddressBookComponent } from './components/users/ajustes/address-book/borrar-address-book/borrar-address-book.component';
import { TarifaAereoComponent } from './components/users/customers/tarifas/tarifa-aereo/tarifa-aereo.component';
import { TarifaMaritimoComponent } from './components/users/customers/tarifas/tarifa-maritimo/tarifa-maritimo.component';
import { TarifaTerrestreComponent } from './components/users/customers/tarifas/tarifa-terrestre/tarifa-terrestre.component';
import { TarifaCargoComponent } from './components/users/customers/tarifa-cargo/tarifa-cargo.component';
import { ListadoTarifasComponent } from './components/users/customers/tarifas/listado-tarifas/listado-tarifas.component';
import { ResumenTarifaComponent } from './components/users/customers/tarifas/resumen-tarifa/resumen-tarifa.component';
import { ListTarifaComponent } from './components/users/customers/tarifas/list-tarifa/list-tarifa.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    NotFound404Component,
    CargoComponent,
    ListShipmentComponent,
    AereoShipmentComponent,
    TerrestreShipmentComponent,
    MaritimoShipmentComponent,
    DndDirectiveDirective,
    UploadFileComponent,
    UploadModalComponent,
    ListEntitiesComponent,
    EntityAddressComponent,
    CustomerIndexComponent,
    ShippingFilesComponent,
    ShippingTrackingComponent,
    ConductorComponent,
    ModelEstadoComponent,
    HistorialComponent,
    PreviewDownloadComponent,
    ModalDownloadComponent,
    DetallesComponent,
    LoginConductorComponent,
    HeaderConductor,
    IncidenciasComponent,
    ListIncidenciasComponent,
    GaleriaImagenesComponent,
    PlantillasComponent,
    MercanciaPeligrosaComponent,
    DireccionesEntidadComponent,
    AnyadirDireccionComponent,
    ConfirmacionSeguroComponent,
    ConfirmacionBorradoComponent,
    HscodesComponent,
    FirmaAlbaranComponent,
    ConfirmacionPlantillaComponent,
    WaitingComponent,
    SubirFacturaComponent,
    ActualizarFacturaComponent,
    AvisoSeguroComponent,
    SubirPendientesComponent,
    TerminosYCondicionesComponent,
    HeaderNoLogin,
    HeaderFijoComponent,
    SubirPendientesHojarutaComponent,
    MiPerfilComponent,
    MiPerfilAjustesComponent,
    HeaderLanguageComponent,
    ResumenEnvioComponent,
    OlvidoPasswordComponent,
    HomeComponent,
    HeaderProveedorComponent,
    ExpedicionesComponent,
    ConductoresComponent,
    HistorialProveedorComponent,
    MatriculasComponent,
    RutasComponent,
    DocumentacionProveedorComponent,
    AsignarConductorComponent,
    DetallesExpedicionComponent,
    AsignarMatriculaComponent,
    FormConductorComponent,
    ListaConductoresComponent,
    BorrarConductorComponent,
    MultipleExpedicionConductorComponent,
    ListaVehiculosComponent,
    FormVehiculoComponent,
    TablePendientesComponent,
    TableRecogidasComponent,
    TableHojasRutaComponent,
    //BorrarVehiculoComponent
    AjustesComponent,
    ConfiguracionComponent,
    EmbalajesComponent,
    ListaEmbalajesComponent,
    HorarioComponent,
    RegistroUsuarioComponent,
    BorrarVehiculoComponent,
    MultipleExpedicionMatriculaComponent,
    CambiarPasswordPrimeraVezComponent,
    SubirFicheroEntidadComponent,
    ResumenTrackingComponent,
    AvisoSiNoComponent,
    GeneralComponent,
    FacturacionComponent,
    ListadoComponent,
    PreviewPdfComponent,
    ListCotizacionComponent,
    DetallesCotizacionComponent,
    DocumentosCotizacionComponent,
    ShareTrackingComponent,
    TrackingDetailsComponent,
    ShippingTrackingShareComponent,
    DetallesMercanciaComponent,
    DetallesMercanciaPeligrosaComponent,
    CotizacionAereaComponent,
    CotizacionMaritimoComponent,
    TableEntregaSinAlbaranComponent,
    CotizacionTerrestreComponent,
    ResumenCotizacionComponent,
    ListadoCotizacionesComponent,
    MapaComponent,
    DetallesContenedorComponent,
    AceptarRechazarComponent,
    GradosComponent,
    CostesShippingComponent,
    DetallesGradosComponent,
    ListadoShipmentComponent,
    IncidenciasPartidaComponent,
    AddressBookComponent,
    ListaAddressBookComponent,
    FormularioAddressBookComponent,
    BorrarAddressBookComponent,
    TarifaAereoComponent,
    TarifaMaritimoComponent,
    TarifaTerrestreComponent,
    TarifaCargoComponent,
    ListadoTarifasComponent,
    ResumenTarifaComponent,
    ListTarifaComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      progressAnimation: 'increasing',
      progressBar: true,
      preventDuplicates: true
    }),
    ReactiveFormsModule,
    FormsModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MatSidenavModule,
    Ng2OrderModule,
    MatSortModule,
    NgxDocViewerModule,
    NgxExtendedPdfViewerModule,
    PdfJsViewerModule,
    NgSelectModule,
    MatDialogModule,
    Angular2SignaturepadModule,
    NgbPaginationModule,
    NgbModule,
    NgxGalleryModule,
    ModalModule.forRoot(),
    MatRadioModule,
    NgbPopoverModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatExpansionModule,
    MatIconModule,
    MatTabsModule,
    MatBadgeModule,
    MatTableModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatListModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    NgxPicaModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ChartsModule,
    MatStepperModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD21qg2Wj9wgL8s6Dod6PdJsAu3g-FeAIQ',
      libraries: ['places']
    })
  ],
  exports: [MatProgressSpinnerModule],
  providers: [LoginService, NgxImageCompressService, { provide: NG_VALIDATORS, useExisting: nifValidator, multi: true }, { provide: NG_VALIDATORS, useExisting: arrayValidator, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    RecaptchaFormsModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [WaitingComponent, EntityAddressComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.traduccion);
}
