<agm-map
  #mapaAGM
  [latitude]="latitudeMapa"
  [longitude]="longitudeMapa"
  [zoom]="zoom"
  [fitBounds]="true"
>
  <agm-marker
    *ngIf="existMarkerOrigen || existMarkerDestino"
    [latitude]="latitude"
    [longitude]="longitude"
    [markerDraggable]="!isTarifa"
    (dragEnd)="markerDragEnd($event)"
  ></agm-marker>
</agm-map>
