<div class="container" *ngIf="listadoFacturas != undefined && listadoFacturas != null">
    <div class="row">
        <div class="col-sm-12">
            <div class="row" style="margin-top: 5%;">
                <div class="col-sm-12">
                    <div class="row">
                        <div [ngClass]="{'col-sm-12': isLoad && tienePrivilegioFacturacionInforme(), 'col-sm-8': isLoad && !tienePrivilegioFacturacionInforme()}"><h3>{{'Búsqueda general'|translate}}</h3></div>
                    </div>
                    <div class="row">
                        <div [ngClass]="{'col-sm-12': tienePrivilegioFacturacionInforme(), 'col-sm-8': !tienePrivilegioFacturacionInforme()}">
                            <input  type="text" name="search" class="form-control" placeholder="{{'Búsqueda general'|translate}}" [(ngModel)]="searchText" (ngModelChange)="changeSearch()">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="table-responsive-sm scrollable-element">
                        <table
                            class="table table-sm table-bordered table-hover table-editable table-row-action edit-action">
                            <thead>
                                <th><mat-checkbox (click)="seleccionarTodos()"></mat-checkbox></th>
                                <th>{{'FechaFactura'|translate}}</th>
                                <th>{{'NumFactura'|translate}}</th>
                                <th>{{'ReferenciasCliente'|translate}}</th>
                                <th>{{'FechaVencimiento'|translate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let factura of listadoFacturas | slice: (pageActual-1)*sizePage: (pageActual-1) * sizePage + sizePage">
                                    <td id="checkbox"><mat-checkbox (click)="setFacturasToDownload(factura.NumFactura)" [(ngModel)]="factura.Checked"></mat-checkbox></td>
                                    <td (click)="numFacturaSelected = factura.NumFactura; openModal(detailFacturaTemplate)">{{factura.FechaFactura}}</td>
                                    <td (click)="numFacturaSelected = factura.NumFactura; openModal(detailFacturaTemplate)">{{factura.NumFactura}}</td>
                                    <td *ngIf="factura.Agrupada" (click)="numFacturaSelected = factura.NumFactura; openModal(detailFacturaTemplate)">{{'Agrupada' | translate}}</td>
                                    <td *ngIf="!factura.Agrupada" (click)="numFacturaSelected = factura.NumFactura; openModal(detailFacturaTemplate)">{{factura.ReferenciasCliente}}</td>
                                    <td (click)="numFacturaSelected = factura.NumFactura; openModal(detailFacturaTemplate)">{{factura.FechaVencimiento}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="d-flex justify-content-between p-2" style="text-align: center;">
                                <ngb-pagination
                                    [(page)]="pageActual"
                                    [pageSize]="sizePage"
                                    [collectionSize]="total"
                                    [maxSize]="3"
                                    [rotate]="true">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- #region InformeYDescargas -->
                <div class="col-sm-4" *ngIf="isLoad">
                    <!-- #region Informe -->
                    <div class="row">
                        <div id="Recogida" *ngIf="tienePrivilegioFacturacionInforme()" class="contenedor">
                            <h5>{{getTitleExportar()}}</h5>
                            <div class="col-sm-12">
                                <form [formGroup]="formInforme">
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <mat-form-field appearance="fill">
                                                    <mat-label class="label" style="color:black;">{{'FechaInicioLabel' | translate}}</mat-label>
                                                    <input matInput id="fechaInicio" name="fechaInicio" [formControl]="fechaInicio" required [matDatepicker]="pickerInicio">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerInicio"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerInicio format="'dd/mm/yyyy'"></mat-datepicker>
                                                    <mat-error *ngIf="!fechaInicio.valid || fechaInicio.untouched">
                                                        {{'FechaRequired'|translate}}
                                                    </mat-error>
                                                    </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <mat-form-field appearance="fill">
                                                    <mat-label class="label" style="color:black;">{{'FechaFinalLabel' | translate}}</mat-label>
                                                    <input matInput id="fechaFinal" name="fechaFinal" [formControl]="fechaFinal" required [min]="fechaInicio.value" [matDatepicker]="pickerFinal">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerFinal format="'dd/mm/yyyy'"></mat-datepicker>
                                                    <mat-error *ngIf="(!fechaFinal.valid || fechaFinal.untouched) && (fechaFinal.value == null || fechaFinal == undefined)">
                                                        {{'FechaRequired'|translate}}
                                                    </mat-error>
                                                    <mat-error *ngIf="(!fechaFinal.valid || fechaFinal.untouched) && fechaFinal.value != null && fechaFinal != undefined">
                                                        {{'FechaFinalMenor'|translate}}
                                                    </mat-error>
                                                    </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 marginBottom">
                                        <button class="btn btn-primary" type="submit" (click)="getInforme('SubmitFormInformeFacturacion')">{{'ExportarButton'| translate}}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion -->
                    <!-- #region Descargas -->
                    <div class="row">
                        <div id="Recogida" class="contenedor">
                            <h5>{{getSearchDownloadExportar()}}</h5>
                            <div class="col-sm-12">
                                <form [formGroup]="formSearchDescargas">
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <mat-form-field appearance="fill">
                                                    <mat-label class="label" style="color:black;">{{'FechaInicioLabel' | translate}}</mat-label>
                                                    <input matInput id="fechaInicioSearchDescargas" name="fechaInicioSearchDescargas" [formControl]="fechaInicioSearchDescargas" required [matDatepicker]="pickerInicioDescargas">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerInicioDescargas"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerInicioDescargas format="'dd/mm/yyyy'"></mat-datepicker>
                                                    <mat-error *ngIf="!fechaInicioSearchDescargas.valid || fechaInicioSearchDescargas.untouched">
                                                        {{'FechaRequired'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <mat-form-field appearance="fill">
                                                    <mat-label class="label" style="color:black;">{{'FechaFinalLabel' | translate}}</mat-label>
                                                    <input matInput id="fechaFinalSearchDescargas" name="fechaFinalSearchDescargas" [formControl]="fechaFinalSearchDescargas" required [min]="fechaInicioSearchDescargas.value" [matDatepicker]="pickerFinalDescargas">
                                                    <mat-datepicker-toggle matSuffix [for]="pickerFinalDescargas"></mat-datepicker-toggle>
                                                    <mat-datepicker #pickerFinalDescargas format="'dd/mm/yyyy'"></mat-datepicker>
                                                    <mat-error *ngIf="(!fechaFinalSearchDescargas.valid || fechaFinalSearchDescargas.untouched) && (fechaFinalSearchDescargas.value == null || fechaFinalSearchDescargas == undefined)">
                                                        {{'FechaRequired'|translate}}
                                                    </mat-error>
                                                    <mat-error *ngIf="(!fechaFinalSearchDescargas.valid || fechaFinalSearchDescargas.untouched) && fechaFinalSearchDescargas.value != null && fechaFinalSearchDescargas != undefined">
                                                        {{'FechaFinalMenor'|translate}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6 marginBottom">
                                            <button class="btn btn-primary" type="submit" (click)="facturasSearch()">{{'SearchInvoiceButton'| translate}}</button>
                                        </div>
                                        <div class="col-6 col-sm-6 marginBottom">
                                            <button class="btn btn-primary" type="submit" (click)="resetListado()"><mat-icon class="icon-display">autorenew</mat-icon></button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 marginBottom" *ngIf="facturasDescargar.length == 0">
                                            <button class="btn btn-primary" type="submit" (click)="descargarBusqueda()">{{'DownloadInvoicesButton' | translate }}</button>
                                        </div>
                                        <div class="col-12 col-sm-12 marginBottom" *ngIf="facturasDescargar.length > 0">
                                            <button class="btn btn-primary" type="button" (click)="descargarBusquedaSeleccionado()">{{'DownloadSelectedInvoicesButton' | translate }}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>                
                    </div>
                    <!-- #endregion -->
                </div>
                <!-- #endregion -->
            </div>
        </div>
    </div>
    
</div>

<ng-template #detailFacturaTemplate>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
        <h2 class="modal-title pull-left">{{'FacturaPDFTitle'|translate}} {{numFacturaSelected}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body fondo">
            <app-preview-pdf (close)="cerrar($event)" [numFactura]="numFacturaSelected"></app-preview-pdf>
        </div>
    </div>
</ng-template>