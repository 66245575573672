<div class="container">
  <!-- Modal header -->
  <div class="row modal-header" style="background: #FCCF61;">
    <p class="h4 modal-title pull-left">{{'Cambiar estado de'|translate}} {{idExpedicion}}</p>
    <button type="button" class="close" (click)="inicializar();" data-dismiss="modal">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- Modal body -->
  <div class="row modal-body fondo">
    <div class="col-12 col-sm-12">
      <div class="row">
        <mat-dialog-content class="mat-typography">
          <div class="col col-sm-12">
            <p style="font-size: 20px;">{{'Tu estado'|translate}} <b>{{estado}}</b> {{'va a cambiar a'|translate}} <b>{{siguienteEstado()}}</b></p>
          </div>
        </mat-dialog-content>
      </div>
      <div class="row">
        <mat-dialog-actions>
          <div class="col">
            <form #formCambiarEstadoHTML="ngForm" (ngSubmit)="cambiarEstado('cambioEstado');">
              <div class="form-group">
                <div class="row" *ngIf="isEntregaAlbaran() || isEntrega()">
                  <div class="col col-sm-12">
                    <div class="row">
                      <div class="col col-sm-12">
                          <label for="Foto" class="btn btn-primary" style="margin-bottom: 0px;">{{'Hacer foto del albarán'|translate}}<sup *ngIf="isEntrega()">*</sup>
                            <input class="btn" #foto type="file" required id="Foto" name="Foto" (change)="fileChange($event.target.files)" autofocus
                              style="visibility: hidden;width: 0px; height: 0px;" accept="image/*;capture=camera" capture="camera"></label>
                        <img *ngIf="fotoRealizada" style="margin-left: 2%;" [style.width.px]="width"
                          src="assets/iconos/check-verde.png">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-sm-12">
                         <p>{{'Tienes'|translate}} {{photo[0].length}} {{'imagenes y'|translate}} {{pdfs[0].length}} pdfs</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-sm-12">
                         <button class="btn" type="button" id="galeria" (click)="openModal(templateNested)">{{'Mostrar imagen/es'|translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="isEntrega()">
                  <div class="col col-sm-12">
                    <div class="row">
                      <div class="col col-sm-12">
                        <label style="font-size: 20px; margin-top: 20px;">{{'CIF/NIF del receptor'|translate}}:<sup>*</sup></label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-sm-12">
                        <!--(change)="comprobarDNI();"-->
                        <input [(ngModel)]="formCambiarEstado.value.dni" name="Dni" id="Dni"
                          class="form-control form-control-sm" #Dni="ngModel" required type="text"
                           placeholder="DNI">
                      </div>
                    </div>
                    <!--<div class="row">
                      <div class="col col-sm-12">
                        <div *ngIf="formCambiarEstadoHTML.submitted && errorDni" class="text-danger"
                          style="font-size: small;">
                          {{'CIF/NIFErrorModal'|translate}}.
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>
                <div class="row" *ngIf="isEntrega()">
                  <div class="col col-sm-12">
                    <div class="row">
                      <div class="col col-sm-12">
                        <label style="font-size: 20px;margin-top: 20px;">{{'Pulse en la ventana inferior para firmar'|translate}}:<sup>*</sup></label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-sm-12">
                        <img class="firma" src="assets/imagenes/AlbaranInterno.png" (click)="openModalFirma(templateFirma)">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-sm-12">
                        <div *ngIf="formCambiarEstadoHTML.submitted && errorFirma" class="text-danger"
                          style="font-size: small;">
                          {{'La firma no puede estar en blanco'|translate}}.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 2%;">
                  <div class="col-6 col-sm-6">
                    <button class="btn" [disabled]="disabled" id="cambioEstado" style="margin-right: 2%;" type="submit" mat-button cdkFocusInitial>{{'Confirmar'|translate}}</button>
                  </div>
                  <div class="col-6 col-sm-6">
                    <button class="btn buttonCancelar" [disabled]="disabled" data-dismiss="modal" #btnClose mat-button type="button"
                      (click)="inicializar();formCambiarEstadoHTML.resetForm();">{{'Cancelar'|translate}}</button>
                  </div>
                </div>
                <div class="row" *ngIf="isEntrega()">
                  <div class="col-12 col-sm-12">
                    Los campos con * <strong>NO</strong> son obligatorios
                  </div>
                </div>
                <div class="row" >
                  <div class="col-sm-12"  *ngIf="disabled">
                    <mat-spinner style="margin: auto;"></mat-spinner>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12" *ngIf="disabled">
                    <p style="text-align: center;" class="h3" >{{'Procesando'|translate}}...</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>

<ng-template #templateNested>
  <div class="modal-header fondo">
    <p class="h4 modal-title pull-left">{{'Galeria'|translate}}</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
    <app-galeria-imagenes [photos]="photo[0]" [idIncidencia]="null"></app-galeria-imagenes>
  </div>
</ng-template>

<ng-template #templateFirma>
  <div class="modal-header fondo">
    <p class="h4 modal-title pull-left">{{'Firma para el albarán'|translate}}</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body fondo">
      <app-firma-albaran [dni]="formCambiarEstado.value.dni" (emitirFirma)="obtenerFirma($event)" [firma]="formCambiarEstado.value.firma"></app-firma-albaran>
  </div>
</ng-template>

<ng-template #templateWaiting>
  <div class="modal-body fondo">
    <app-waiting></app-waiting>
  </div>
</ng-template>