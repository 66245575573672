<div class="row">
    <div class="col-12 col-sm-12">
        <form [formGroup]="formUploadFile">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="fill">
                        <mat-label>{{'FechaValidezLabel'|translate}}</mat-label>
                        <input [formControl]="FechaFinalArchivo" matInput [min]="minDate" [matDatepicker]="picker" >
                        <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker  #picker format="'dd/mm/yyyy'"></mat-datepicker>
                      </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="uploadfilecontainer" (click)="fileInput.click()" appDndDirective (onFileDropped)="uploadFile($event)">
                    <input hidden type="file" #fileInput data-max-size="100000000" (change)="uploadFile($event.target.files)">
                </div>
    
                <!--
*ngFor="let file of dataFile; let i = index"

                -->
                <div class="files-list" *ngIf="dataFile != null">
                    <img src="assets/iconos/file_16.png" width="20px" height="20px" alt="file">
                    <p>{{TipoArchivo.tipo}}.{{dataFile.Extension}}</p>
                    <p class="size">{{formatBytes(dataFile.Tamanyo)}}</p>
                        <img src="assets/iconos/trash_16.png" alt="delete" style="cursor: pointer;width: 20px;height: 20px;" (click)="openModal(templateDelete);">
                </div>
            </div>
            <div class="modal-footer mt-3">
                <button id="EnviarArchivosProveedor" type="button" class="btn" (click)="guardarArchivos('EnviarArchivosProveedor')">{{'EnviarArchivosLabelButton'|translate}}</button>
            </div>
        </form>
    </div>
</div>

<ng-template #templateDelete>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
        <h2 class="modal-title pull-left">{{'Confirmación de borrado'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body fondo">
            <app-confirmacion-borrado (confirmEmit)="obtenerConfirmacion($event)"></app-confirmacion-borrado>
        </div>
    </div>
</ng-template>