<div class="container">
    <form #formEnvioAereo="ngForm" (ngSubmit)="anyadirFicheros()">
        <div class="row">
            <div class="col-sm-3">
                <label>{{'Tipo Archivo'|translate}}</label>
                <ng-select name="TipoArchivo" id="TipoArchivo" class="select-small" #TipoArchivo="ngModel" [items]="listaTipoArchivos" style="margin-bottom: 3px;"
                [(ngModel)]="formUploadFile.value.IdTipoArchivo" bindLabel="NombreTipoArchivo" bindValue="IdTipoArchivo">
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div *ngIf="ExisteTipoArchivo()" class="uploadfilecontainer" (click)="fileInput.click()" appDndDirective (onFileDropped)="uploadFile($event)">
                <input hidden type="file" #fileInput data-max-size="100000000" (change)="uploadFile($event.target.files)">
            </div>

            <div class="files-list" *ngFor="let file of dataFile; let i = index">
                <img src="assets/iconos/file_16.png" width="20px" height="20px" alt="file">
                <p>{{file.FileName}}.{{file.Extension}}</p>
                <p class="size">{{formatBytes(file.Tamanyo)}}</p>
                    <img src="assets/iconos/trash_16.png" alt="delete" style="cursor: pointer;width: 20px;height: 20px;" (click)="openAlert(templateDelete, i);">
            </div>
        </div>
        <div class="modal-footer mt-3">
            <button *ngIf="documentacion" type="submit" class="btn" (click)="guardarArchivos(dataFile)">{{'Aceptar'|translate}}</button>
            <button *ngIf="!documentacion" type="submit" class="btn">{{'Aceptar'|translate}}</button>
            <button #modal type="button" class="btn" data-dismiss="modal">{{'Cerrar'|translate}}</button>
        </div>
    </form>
</div>

<ng-template #templateDelete>
    <div class="modal-content fondo">
        <div class="modal-header fondo">
        <h2 class="modal-title pull-left">{{'Confirmación de borrado'|translate}}</h2>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body fondo">
            <app-confirmacion-borrado (confirmEmit)="obtenerConfirmacion($event)"></app-confirmacion-borrado>
        </div>
    </div>
</ng-template>