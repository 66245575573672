import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { Totales } from 'src/app/models/totales.model';

@Component({
  selector: 'app-detalles-cotizacion',
  templateUrl: './detalles-cotizacion.component.html',
  styleUrls: ['./detalles-cotizacion.component.scss']
})
export class DetallesCotizacionComponent implements OnInit {

  @Input() cotizacion: Cotizacion = null;
  @Input() conModel: boolean = true;

  @Output() close = new EventEmitter<boolean>();
  totales: Totales[] = [];
  visible:boolean=true

  constructor() { }

  ngOnInit(): void {
    this.getTotalesIngresos();
  }

  getTotalesIngresos(){
    this.cotizacion.lineasIngreso.forEach(li => {
      var divisaTotalIndex = this.totales.findIndex(t => t.divisa == li.divisa);
      if(divisaTotalIndex == -1){ // No existe
        var totalNuevo = new Totales();
        totalNuevo.divisa = li.divisa;
        totalNuevo.descripcion = "Total " + li.divisa;
        totalNuevo.total = Number(li.importe.replace(',', '.'));
        this.totales.push(totalNuevo);
      }else
        this.totales[divisaTotalIndex].total += Number(li.importe.replace(',', '.'));
    });
  }

  getTexto(texto: string){
    return texto.replace('\n', '<br />');
  }

  cerrar(close){
    if(close)
      this.close.emit(true);
  }

}
