import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Conductor } from '../models/conductor.model';
import { Expedition } from '../models/expedition.model';
import { LogModel } from '../models/log-model.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ConductorService {

  readonly apiUrlService = environment.apiUrl;
  conductores$: Subject<Conductor[]> = new Subject();
  conductor$: Subject<Conductor> = new Subject();

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getConductores$(): Observable<Conductor[]>{
    return this.conductores$.asObservable();
  }

  getConductor$(): Observable<Conductor>{
    return this.conductor$.asObservable();
  }

  setConductor$(conductor: Conductor){
    this.conductor$.next(conductor);
  }

  getAllConductores(){
    const url_api = this.apiUrlService + "cfg_conductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.http.get(url_api, { headers: headersToken })
    .pipe(map(data => data as Conductor[]))
    .subscribe(cs => {
      this.conductores$.next(cs);
    });
  }

  getConductor(idConductor: string): Observable<Conductor>{
    const url_api = this.apiUrlService + "cfg_conductor/" + idConductor;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Conductor));
  }

  postConductor(conductor: Conductor, log: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "cfg_conductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(conductor)}), { headers: headersToken })
    .pipe(map(data => data as boolean));
  }

  putConductor(conductor: Conductor, log: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "cfg_conductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.put(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(conductor)}), { headers: headersToken })
    .pipe(map(data => data as boolean));
  }

  /**
   * readonly apiUrlService = environment.apiUrl;
  conductores$: Subject<Conductor[]> = new Subject();

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getConductores$(): Observable<Conductor[]>{
    return this.conductores$.asObservable();
  }

  getAllConductores(){
    const url_api = this.apiUrlService + "cfg_conductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.http.get(url_api, { headers: headersToken })
    .pipe(map(data => data as Conductor[]))
    .subscribe(cs => {
      this.conductores$.next(cs);
    });
  }

  postConductor(conductor: Conductor, log: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "cfg_conductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.post(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(conductor)}), { headers: headersToken })
    .pipe(map(data => data as boolean));
  }

  putConductor(conductor: Conductor, log: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "cfg_conductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken(),
      "Content-Type": "application/json"
    })
    return this.http.put(url_api, JSON.stringify({"Log": log, "Body": JSON.stringify(conductor)}), { headers: headersToken })
    .pipe(map(data => data as boolean));
  }
   * 
   */
}
