import { DatePipe } from '@angular/common';
import { Component, Input, LOCALE_ID, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { Archivo } from 'src/app/models/archivo.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ShipmentExport } from 'src/app/models/shipment-export.model';
import { Shipment } from 'src/app/models/shipment.model';
import { ExcelServiceService } from 'src/app/services/excel-service.service';
import { FileShipService } from 'src/app/services/file-ship.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-listado-shipment',
  templateUrl: './listado-shipment.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' }, DatePipe],
  styleUrls: ['./listado-shipment.component.scss']
})
export class ListadoShipmentComponent implements OnInit {

  @Input() listado: Shipment[] = [];
  @Input() modoEnvioSelected: number;
  clase: string = "app-listado-shipment";
  @ViewChildren("tableShipments") tableShipments;

  @Input() collectionSize: number;

  key: string = 'IdShipment';
  @Input() activa: boolean = true;
  @Input() idArea: number;

  pipe = new DatePipe("es-ES")
  isVisible: boolean;
  idEnvio;
  partida;
  expedicion;
  aceptar: boolean = false;
  reverse: boolean = false;
  pageActual: number = 1;
  list: Shipment[];
  listAux: Shipment[];
  dataExport: ShipmentExport[] = [];
  sizePage: number = 10;
  @Input() total: number = 0;
  modalRef: BsModalRef | null;
  @ViewChild('aceptarRechazarTemplate', { read: TemplateRef }) detallesCotizacion: TemplateRef<any>;
  selectedAereo: boolean = false;
  selectedMaritimoFCL: boolean = false;
  listaArchivos: Archivo[] = [];
  selectedMaritimoLCL: boolean = false;
  selectedTerrestre: boolean = false;
  selectedTodos: boolean = false;
  nombrePDF: String;
  public base64Cotizacion: string = "";
  dialogRef;
  modoEnvioSelectedFCL: number = 0;
  modoEnvioSelectedLCL: number = 1;
  estado: string = "";
  visible: boolean = true;
  referencia: string = "";
  today: Date = new Date();


  constructor(public shipmentService: ShipmentService, private toastr: ToastrService, private docsService: FileShipService, private translateService: TranslateService, private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private modalService: BsModalService) { }

  ngOnInit(): void {

    // const dialogRef = this.dialog.open(WaitingComponent, {
    //   width: '250px',
    //   panelClass: 'my-panel',
    //   disableClose: true
    // });

    this.idArea = Number(this.route.snapshot.paramMap.get('idArea'));
    this.modoEnvioSelectedLCL = this.shipmentService.posicionModoEnvioLCL;
    this.listAux = this.listado;
    this.total = this.listado?.length;

    // this.shipmentService.getEnvios(this.idArea.toString(), this.modoEnvioSelected.toString()).subscribe(data => { 
    //   dialogRef.close(); 
    //     this.list = data;
    //     this.listAux = data;
    //     this.total = this.list.length;
    // });

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.total);
    this.total = this.listado?.length;
    // if(this.tableShipments == undefined || this.tableShipments == null)
    //   this.total = 1;
    // else
    //   this.total = this.tableShipments.toArray().length + 1;

    // console.log("---------");
    // console.log(this.tableShipments);
    // console.log(this.modoEnvioSelected);
    // console.log(this.total);
    // console.log("---------");
  }


  cerrar(event) {
    if (event)
      this.modalRef.hide();
  }

  puedeSubirDocumentacion(envio: Shipment): boolean {
    return moment(envio.FechaLimiteSubidaDocumentacion).isSameOrAfter(moment(this.today));
  }

  openModal(template: TemplateRef<any>, shipment: Shipment) {
    this.shipmentService.envio = shipment;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    if (shipment.Partida != undefined && shipment.Partida != null)
      this.partida = shipment.Partida;
    else
      this.expedicion = shipment.Expedicion;
    this.modalRef = this.modalService.show(template, config);
  }

  getArea(idArea: number) {
    if (idArea == 1)
      return "TERRESTRE";
    else if (idArea == 2)
      return "MARITIMO";
    else if (idArea == 3)
      return "AEREO";
    else
      return "";
  }

  isMaritimo() {
    return this.idArea == 2;
  }

  isTerrestre() {
    return this.idArea == 1;
  }

  isAereo() {
    return this.idArea == 3;
  }

  isTodos() {
    return this.idArea == 0;
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  buscarEnvio(envio: Shipment) {
    if (envio.IdEnvio != undefined && envio.IdEnvio != null && envio.IdEnvio != 0) {
      this.idEnvio = envio.IdEnvio;
    } else if (envio.Partida != undefined && envio.Partida != null) {
      this.partida = envio.Partida;
      this.shipmentService.getIdShipment(envio.Partida).subscribe(data => {
        this.idEnvio = data;
      }, err => { });
    } else if (envio.Expedicion != undefined && envio.Expedicion != null) {
      if (this.expedicion.includes("/"))
        this.expedicion = envio.Expedicion.split("/")[0];
      this.shipmentService.getIdShipment(this.expedicion).subscribe(data => {
        this.idEnvio = data;
      }, err => { });
    } else {
      this.idEnvio = 0;
    }
  }

  copiarEnvio(partida: string, expedicion: string, tipoEnvio: string) {
    switch (tipoEnvio) {
      case 'AEREO':
        this.shipmentService.setDuplicar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setEditar(false);
        this.shipmentService.setPartida(partida);
        this.router.navigate(['/customer/shipment/aereo']);
        break;
      case 'FCL':
      case 'LCL':
      case 'MARITIMO':
        this.shipmentService.setDuplicar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setEditar(false);
        this.shipmentService.setPartida(partida);
        this.router.navigate(['/customer/shipment/maritimo']);
        break;
      case 'TERRESTRE':
        this.shipmentService.setDuplicar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setEditar(false);
        this.shipmentService.setExpedicion(expedicion.split('/')[0]);
        this.router.navigate(['/customer/shipment/terrestre']);
        break;
    }
  }

  editarEnvio(id: number, tipoEnvio: string) {
    this.shipmentService.setIdEnvio(id);
    switch (tipoEnvio) {
      case 'AEREO':
        this.shipmentService.setEditar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setDuplicar(false);
        this.router.navigate(['/customer/shipment/aereo']);
        break;
      case 'FCL':
      case 'LCL':
      case 'MARITIMO':
        this.shipmentService.setEditar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setDuplicar(false);
        this.router.navigate(['/customer/shipment/maritimo']);
        break;
      case 'TERRESTRE':
        this.shipmentService.setEditar(true);
        this.shipmentService.setVisor(false);
        this.shipmentService.setDuplicar(false);
        this.router.navigate(['/customer/shipment/terrestre']);
        break;
    }
  }

  esAdelanto(diasRetraso) {
    return diasRetraso > 0;
  }

  esAtraso(diasRetraso) {
    return diasRetraso < 0;
  }

  receiveFiles(files) {
    this.listaArchivos = files;
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    var log: LogModel = new LogModel();
    log.Click = "subirDocumentación";
    log.Pantalla = this.clase;
    dialogRef.afterOpened().subscribe(result => { });
    var total = 0;
    var enviarArchivos = [];
    this.listaArchivos.forEach(a => {
      a.Partida = this.partida;
      a.Expedicion = this.expedicion;
      a.IdEnvio = this.idEnvio;
      if (total + a.Tamanyo <= 25000000) { // 25 MB
        total += a.Tamanyo;
        enviarArchivos.push(a);
      } else {
        this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
          // REINTENTAMOS PARA VOLVER A SUBIRLOS.
          this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
            var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con nosotros.');
            var Titulo = this.translateService.instant('Error en los documentos');
            this.toastr.error(Body, Titulo);
          })
        });
        enviarArchivos = [];
        enviarArchivos.push(a);
        total = 0;
        total += a.Tamanyo;
      }
    });
    if (enviarArchivos.length > 0) {
      this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
        dialogRef.close();
        enviarArchivos = [];
        total = 0;
        var Body = this.translateService.instant('BodyArchivosOk');
        var Titulo = this.translateService.instant('TitleArchivosOk');
        this.toastr.success(Body, Titulo);
      }, err => {
        this.docsService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
          dialogRef.close();
          enviarArchivos = [];
          total = 0;
          var Body = this.translateService.instant('BodyArchivosOk');
          var Titulo = this.translateService.instant('TitleArchivosOk');
          this.toastr.success(Body, Titulo);
        }, err => {
          dialogRef.close();
          enviarArchivos = [];
          total = 0;
          var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con XGL.');
          var Titulo = this.translateService.instant('Error en los documentos');
          this.toastr.error(Body, Titulo);
        })
      })
    }
  }


}
