<mat-form-field appearance="standard" class="width-100">
    <mat-label>{{'Búsqueda' | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
    <button mat-button matPrefix mat-icon-button>
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
<table mat-table [dataSource]="vehiculos" class="mat-elevation-z0">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let element"> {{element.IdVehiculo}} </td>
    </ng-container>

    <ng-container matColumnDef="matricula">
        <th mat-header-cell *matHeaderCellDef> {{'Matricula'| translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.Matricula}} </td>
    </ng-container>

    <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef> {{'Tipo'| translate}} </th>
        <td mat-cell *matCellDef="let element">
            {{element.Tipo}}
        </td>
    </ng-container>

    <ng-container matColumnDef="especialidad">
        <th mat-header-cell *matHeaderCellDef>{{'Especialidad' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.Especialidad}} </td>
    </ng-container>

    <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <mat-icon class="cursor" (click)="setRow(element);">create</mat-icon>
            <mat-icon class="delete-icon cursor" (click)="vehiculo = element; openModal(templateDesactivarVehiculo);">delete</mat-icon>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [length]="lengthTable" [pageSizeOptions]="sizeOptions" [pageSize]="itemsPorPage" showFirstLastButtons></mat-paginator>

<ng-template #templateDesactivarVehiculo>
    <div class="modal-header fondo">
      <p class="h4 modal-title pull-left">{{'Borrar vehiculo'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-borrar-vehiculo [vehiculoSelected]="vehiculo" (cerrarModal)="cerrar($event)"></app-borrar-vehiculo>
    </div>
  </ng-template>
