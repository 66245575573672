import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Direccion } from 'src/app/models/direccion.model';
import { Entity } from 'src/app/models/entity.model';
import { Localidades } from 'src/app/models/localidades.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Paises } from 'src/app/models/paises.model';
import { EntityService } from 'src/app/services/entity.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-anyadir-direccion',
  templateUrl: './anyadir-direccion.component.html',
  styleUrls: ['./anyadir-direccion.component.scss']
})
export class AnyadirDireccionComponent implements OnInit {

  @Output() cerrar = new EventEmitter<Boolean>();
  @Output() emitDireccionEntidad = new EventEmitter<Direccion>();
  @Input() tipoDireccion: string;
  @Input() area: number;
  entity: Entity;
  direccion: Direccion;
  listaEntidades: Entity[];
  listaPaises: Paises[];
  submitted: boolean = false;

  public get IdEntidad() { return this.formDireccion.get('IdEntidad') }
  public get Direccion() { return this.formDireccion.get('Direccion') }
  public get Pais() { return this.formDireccion.get('Pais') }
  public get Ciudad() { return this.formDireccion.get('Ciudad') }
  public get CodigoPostal() { return this.formDireccion.get('CodigoPostal') }

  public formDireccion: FormGroup;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private configService: ShipmentService, private entityService: EntityService,
    private translateService: TranslateService) {
      this.entityService.getEntity$().subscribe(e => {
        this.entity = e;
      });
     }

  ngOnInit(): void {
    
    this.formDireccion = this.formBuilder.group({
      IdEntidad: [this.isEntity() ? this.entity.IdEntidad : '', [Validators.required]],
      Direccion: ['', [Validators.required]],
      Pais: [null, [Validators.required]],
      Ciudad: [null],
      CodigoPostal: [null]
    });

    this.direccion = new Direccion();
    this.configService.getPaises().subscribe(data => {
      this.listaPaises = data;
    })
    
    this.entityService.getEntidad().subscribe(data => {
      this.listaEntidades = data;
    })
    //todas las entidades de un cliente tanto remitente como destinatario
  }

  close(){
    this.cerrar.emit(true);
  }

  anyadirDireccion() {
    this.submitted = true;
    if(this.formDireccion.valid)
    {
      this.convertFormToModel();
      var log: LogModel = new LogModel();
      log.Click = "PostDireccion";
      log.Pantalla = "app-anyadir-direccion";
      this.entityService.postDireccion(this.direccion, log).subscribe(data => {
        var Body = this.translateService.instant('Direccion añadida de forma correcta');
        var Titulo = this.translateService.instant('Direccion añadida');
        this.toastr.success(Body, Titulo)
        this.emitDireccionEntidad.emit(this.direccion);
      },
      error => {
        var errorBody = this.translateService.instant('La dirección no se ha podido añadir');
        var errorTitulo = this.translateService.instant('Error de inserción');
        this.toastr.error(errorBody, errorTitulo)
      });
    }
    else
    {
      var Body = this.translateService.instant('CamposObligatoriosVacios');
      var Titulo = this.translateService.instant("Error");
      this.toastr.error(Body, Titulo)
    }
  }

  cerrarModal(){
    this.emitDireccionEntidad.emit(null);
  }

  isEntity(): boolean{
    return (this.entity != null && this.entity != undefined);
  }

  convertFormToModel(){
    this.direccion.IdDireccion = 0;
    this.direccion.IdEntidad = this.isEntity() ? this.entity.IdEntidad : this.IdEntidad.value;
    this.direccion.Direccion = this.Direccion.value;
    this.direccion.IdPais = this.Pais.value;
    this.direccion.Localidad = this.Ciudad.value;
    this.direccion.CodigoPostal = this.CodigoPostal.value;
  }
}
