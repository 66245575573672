<!--
<app-header></app-header>
-->

<div class="container">
    <div class="block">
        <h1>{{'DOCUMENTOS'|translate}}</h1>
    </div>
    <!--
    <div class="row">
        <div class="col-sm-2">
            <app-upload-modal [documentacion]="true" [envio]="envio"></app-upload-modal>
        </div>
    </div>
    -->
    <div class="row">
        <div class="col-sm-12">
            <div class="row">
                <div *ngFor="let file of listaArchivos" class="col-sm-2">
                    <img *ngIf="isPdf(file)" src="assets/imagenes/pdf.png" class="pdf" alt="" (click)="descargarPDF(file)"/>
                    <img *ngIf="isPowerPoint(file)" src="assets/imagenes/imagenArchivoPowerPoint.png" class="pdf" alt="" (click)="descargarPDF(file)"/>
                    <img *ngIf="isWord(file)" src="assets/imagenes/imagenArchivoWord.png" class="pdf" alt="" (click)="descargarPDF(file)"/>
                    <img *ngIf="isOutlook(file)" src="assets/imagenes/imagenArchivoOutlook.png" class="pdf" alt="" (click)="descargarPDF(file)"/>
                    <img *ngIf="isExcel(file)" src="assets/imagenes/imagenArchivoExcel.png" class="pdf" width="20px" height="20px" alt="file" (click)="descargarPDF(file)">  
                    <img *ngIf="isImagen(file)" src="assets/imagenes/imagenImagen.png" class="pdf" width="20px" height="20px" alt="file" (click)="descargarPDF(file)">
                    <img *ngIf="isComprimido(file)" src="assets/imagenes/imagenArchivoWinrar.png" class="pdf" width="20px" height="20px" alt="file" (click)="descargarPDF(file)">
                    <img *ngIf="isGenerico(file)" src="assets/imagenes/imagenGenerico.png" class="pdf" width="20px" height="20px" alt="file" (click)="descargarPDF(file)">
                    <label class="subrayado nomfile" (click)="descargarPDF(file)">{{getNombre(file.FileName) | slice:0:15}}</label> 
                </div>
            </div>
        </div>
    </div>
</div>