<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="input-group md-form form-sm form-1 pl-0">
                <div class="input-group-prepend">
                    <span class="input-group-text purple lighten-3" id="basic-text1">
                        <img src="assets/iconos/search_16.png" alt=""/>
                    </span>
                </div>
                <input title="Buscador" class="form-control my-0 py-1 search-input" type="search" placeholder="Búsqueda" aria-label="Search"  [(ngModel)]="searchText" (ngModelChange)="changeSearch()">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive-sm scrollable-element">
                <table class="table table-sm table-hover table-editable table-row-action edit-action">
                    <thead>
                        <th class="fit" scope="col">{{'Nombre Fiscal'|translate}}</th>
                        <th class="fit" scope="col">CIF/NIF</th>
                        <th class="fit" scope="col">{{'Direccion'|translate}}</th>
                        <th class="fit" scope="col">{{'Pais'|translate}}</th>
                        <th class="fit" scope="col">{{'Localidad'|translate}}</th>
                        <th class="fit" scope="col">{{'Cod. Postal'|translate}}</th>
                        <th class="fit" scope="col">{{'Tlf'|translate}}. 1</th>
                        <th class="fit" scope="col">{{'Email'|translate}}</th>
                    </thead>
                    <tbody class="search-results">
                        <tr *ngFor="let entity of listEntities | slice: (pageActual-1)*sizePage: (pageActual-1) * sizePage + sizePage | filter:searchText" style="cursor: pointer;" 
                        (click)="safeEntity(entity); selectRow($event, entity)" [class.highlighted]="entity.NombreFiscal === currentRow">
                            <td class="fit" title="Nombre Comercial">{{entity.NombreFiscal}}</td>
                            <td class="fit" title="Cif/Nif">{{entity.CifNif}}</td>
                            <td class="fit" title="Dirección">{{entity.Direccion}}</td>
                            <td class="fit" title="Pais">{{entity.NombrePais}}</td>
                            <td class="fit" title="Localidad">{{entity.Poblacion}}</td>
                            <td class="fit" title="Código Postal">{{entity.CodigoPostal}}</td>
                            <td class="fit" title="Teléfono">{{entity.Telefono1}}</td>
                            <td class="fit" title="Email">{{entity.Email}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <div class="d-flex justify-content-between p-2" style="text-align: center;">
                <ngb-pagination
                    [(page)]="pageActual"
                    [pageSize]="sizePage"
                    [collectionSize]="total"
                    [maxSize]="3"
                    [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-8"></div>
        <div class="col-sm-2">
            <button style="text-align: right;" type="button" class="btn btn-right" (click)="enviarEntity()">{{'Seleccionar'|translate}}</button>
        </div>
        <div class="col-sm-2">
            <button type="button" class="btn" (click)="cerrar()">{{'Cerrar'|translate}}</button>
        </div>
    </div>
</div>

<!-- Habría que crear un listado con scroll, un filtro de búsqueda -->