import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Shipment } from 'src/app/models/shipment.model';

@Component({
  selector: 'app-confirmacion-seguro',
  templateUrl: './confirmacion-seguro.component.html',
  styleUrls: ['./confirmacion-seguro.component.scss']
})
export class ConfirmacionSeguroComponent implements OnInit {

  @Input() shipment: Shipment;
  @Input() asegurado: boolean;
  @Input() direccionRecogida: string;
  @Input() direccionEntrega: string;
  @Output() confirmEmit = new EventEmitter<Boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChange) {

  }

  confirmacion(){
    this.confirmEmit.emit(true)
  }

  cancelacion() {
    this.confirmEmit.emit(false)
  }

  
}
