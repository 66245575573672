import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ExpeditionApiService } from 'src/app/services/expedition-api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-preview-download',
  templateUrl: './preview-download.component.html',
  styleUrls: ['./preview-download.component.scss']
})
export class PreviewDownloadComponent implements OnInit {
  public pdfSrc = ""
  pdfParam: string;
  constructor(private http: HttpClient, private translateService: TranslateService, private toast: ToastrService, private route: ActivatedRoute, private expeditionService: ExpeditionApiService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.pdfParam = params.get('file')
      this.expeditionService.getDocumento(this.pdfParam).subscribe(data => {
        this.pdfSrc = data.Base64image.toString();
      }, err => {
        var Body = this.translateService.instant("Ha habido un error al cargar el fichero.");
        var Titulo = this.translateService.instant("Error");
        this.toast.error(Body, Titulo)
      })
    })
  }

}
