import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { FacturasListado } from 'src/app/models/facturasListado.model';
import { FacturacionService } from 'src/app/services/facturacion.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { LogModel } from 'src/app/models/log-model.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Toastr } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Archivo } from 'src/app/models/archivo.model';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ListadoComponent implements OnInit {

  pantalla: string = "app-listado-component";
  @Input() listadoFacturas: FacturasListado[] = [];
  @Input() listadoFacturasTotal: FacturasListado[] = [];
  @Input() areasSelected: string[] = [];
  facturasSeleccionadas: string[] = [];
  searchText: string = "";
  pageActual = 1;
  sizePage = 10;
  selectedAll: boolean = false;
  @Input() total = 0;
  modalRef: BsModalRef | null;
  formInforme: FormGroup;
  formSearchDescargas: FormGroup;
  numFacturaSelected: string = "";
  areasList: string[] = ['Aereo', 'Maritimo', 'Terrestre'];
  facturasDescargar: string[] = [];
  privilegios;
  privilegioTodo;
  privilegioFacturacionInforme;
  isLoad: boolean = false;

  constructor(private modalService: BsModalService, public dialog: MatDialog, private facturacionService: FacturacionService, private toastr: ToastrService,
     public translateService: TranslateService, private _adapter: DateAdapter<any>, private formBuilder: FormBuilder, private authenticationService: AuthenticationService) { 
    this._adapter.setLocale('es');
  }

  async ngOnInit() {
    this.privilegios = await this.authenticationService.getPrivilegiosUsuario().toPromise();
    this.privilegioTodo = await (await this.authenticationService.getPrivilegio("Todo").toPromise()).toString();
    this.privilegioFacturacionInforme = await (await this.authenticationService.getPrivilegio("FacturacionInforme").toPromise()).toString();
    this.isLoad = true;
    this.formInforme = this.formBuilder.group({
      fechaInicio: [null, [Validators.required]],
      fechaFinal: [null, [Validators.required]],
      areas: [this.areasSelected]
    });
    this.formSearchDescargas = this.formBuilder.group({
      fechaInicioSearchDescargas: [null, [Validators.required]],
      fechaFinalSearchDescargas: [null, [Validators.required]]
    });
  }

  public get fechaInicio() { return this.formInforme.get('fechaInicio') }
  public get fechaFinal() { return this.formInforme.get('fechaFinal') }
  public get areas() { return this.formInforme.get('areas') }
  public get fechaInicioSearchDescargas() { return this.formSearchDescargas.get('fechaInicioSearchDescargas') }
  public get fechaFinalSearchDescargas() { return this.formSearchDescargas.get('fechaFinalSearchDescargas') }

  changeSearch(){
    this.listadoFacturas = this.listadoFacturasTotal;
    if(this.searchText != "")
      this.listadoFacturas = this.listadoFacturas.filter(fac => this.existeCadenaEnvio(fac));
    this.total = this.listadoFacturas.length;
  }

  existeCadenaEnvio(factura: FacturasListado): boolean{
    return ((factura.FechaFactura != null && factura.FechaFactura.toString().includes(this.searchText.toUpperCase()))
    || (factura.NumFactura != null && factura.NumFactura.includes(this.searchText.toUpperCase()))
    || (factura.ReferenciasCliente != null && factura.ReferenciasCliente.toString().includes(this.searchText.toUpperCase()))
    || (factura.FechaVencimiento != null && factura.FechaVencimiento.toString().includes(this.searchText.toUpperCase())))
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cerrar(event){
    if(event)
      this.modalRef.hide();
  }

  getInforme(click: string){
    if(this.tienePrivilegioFacturacionInforme()){
      if(this.formInforme.valid){
        let lang: string = this.translateService.getDefaultLang();
        var log: LogModel = new LogModel();
        log.Click = click;
        log.Pantalla = this.pantalla;
        const dialogRef = this.dialog.open(WaitingComponent, {
          width: '250px',
          panelClass: 'my-panel',
          disableClose: true
        });
        this.facturacionService.getFacturasClienteExcel(moment(this.fechaInicio.value).format('DD-MM-yyyy'), moment(this.fechaFinal.value).format('DD-MM-yyyy'), lang, log, this.areas.value).subscribe(facturasExcel => {
          dialogRef.close();
          var data = atob(facturasExcel.Base64image.toString())
          const bytenumbers = new Array(data.length);
          for (let i = 0; i < data.length; i++)
            bytenumbers[i]= data.charCodeAt(i);
          const byteArray = new Uint8Array(bytenumbers);
          const blob = new Blob([byteArray], {type: 'contentType'})
          saveAs(blob, facturasExcel.FileName);
        }, err => {
          dialogRef.close();
          if(err.status == 404){
            var errorTitulo = this.translateService.instant('TitleNotFoundApiExportarExcel');
            var errorBody = this.translateService.instant('BodyNotFoundApiExportarExcel');
            this.toastr.error(errorBody, errorTitulo);
          }else{
            var errorTitulo = this.translateService.instant('TitleErrorApiExportarExcel');
            var errorBody = this.translateService.instant('BodyErrorApiExportarExcel');
            this.toastr.error(errorBody, errorTitulo);
          }
        });
      }else{
        var errorTitulo = this.translateService.instant('TitleErrorFormularioExportar');
        var errorBody = this.translateService.instant('BodyErrorFormularioExportar');
        this.toastr.error(errorBody, errorTitulo);
      }
    }else
      this.toastr.error("No tienes privilegios para sacar el informe.", "No tienes privilegios")
  }

  
  tienePrivilegioFacturacionInforme(){
    return (this.privilegios != undefined && this.privilegios != null && (this.privilegios.length == 0 || this.privilegios.includes(this.privilegioTodo) || this.privilegios.includes(this.privilegioFacturacionInforme)))
  }

  getTitleExportar(){
    var title = this.translateService.instant("ExportarLabel");
    if(this.areasSelected.length == 3){
      title += " " + this.translateService.instant("Aereo") + ", " + this.translateService.instant("Maritimo") + ", " + this.translateService.instant("Terrestre");
    }else{
      if(this.areasSelected.find(a => a == "Aereo"))
        title += " " + this.translateService.instant("Aereo");
      else if(this.areasSelected.find(a => a == "Maritimo"))
          title += " " + this.translateService.instant("Maritimo");
      else if(this.areasSelected.find(a => a == "Terrestre"))
          title += " " + this.translateService.instant("Terrestre");
    }

    return title;
  }

  comprobarTodosSelected(): boolean{
    var resultado : boolean = true;
    this.listadoFacturas.forEach(f => {
      if(f.Checked == false || f.Checked == undefined)
        resultado = false;
      });
      return resultado;
  }

  seleccionarTodos(){
    var cambio: boolean = !this.selectedAll;
    this.selectedAll = !this.selectedAll;
    this.listadoFacturas.forEach(f => {
      if(f.Checked == undefined || f.Checked != cambio){
        f.Checked = cambio;
        if(this.facturasDescargar.includes(f.NumFactura) && f.Checked == false){
          var index: number = this.facturasDescargar.findIndex(fac => fac == f.NumFactura);
          this.facturasDescargar.splice(index, 1);
        }else if(f.Checked == true)
          this.facturasDescargar.push(f.NumFactura);
      }
    });
  }

  setFacturasToDownload(factura: string){
    if(this.facturasDescargar.includes(factura)){
      var index: number = this.facturasDescargar.findIndex(f => f == factura);
      this.facturasDescargar.splice(index, 1);
      this.selectedAll = false;
    }else{
      this.facturasDescargar.push(factura);
      this.listadoFacturas.find(f => f.NumFactura == factura).Checked = true;
      this.selectedAll = this.comprobarTodosSelected();
      this.listadoFacturas.find(f => f.NumFactura == factura).Checked = false;
    }
  }

  getSearchDownloadExportar(){
    var title = this.translateService.instant("DownloadSearchLabel");
    if(this.areasSelected.length == 3){
      title += " " + this.translateService.instant("Aereo") + ", " + this.translateService.instant("Maritimo") + ", " + this.translateService.instant("Terrestre");
    }else{
      if(this.areasSelected.find(a => a == "Aereo"))
        title += " " + this.translateService.instant("Aereo");
      else if(this.areasSelected.find(a => a == "Maritimo"))
          title += " " + this.translateService.instant("Maritimo");
      else if(this.areasSelected.find(a => a == "Terrestre"))
          title += " " + this.translateService.instant("Terrestre");
    }

    return title;
  }

  facturasSearch(){
    if(this.formSearchDescargas.valid){
      this.listadoFacturas = this.listadoFacturasTotal.filter(lf => moment(lf.FechaFactura, 'DD/MM/yyyy').isSameOrAfter(moment(this.fechaInicioSearchDescargas.value.toString())) && 
                                                      moment(lf.FechaFactura, 'DD/MM/yyyy').isSameOrBefore(moment(this.fechaFinalSearchDescargas.value.toString())));
      this.total = this.listadoFacturas.length;
      this.selectedAll = true;
      this.seleccionarTodos();
      this.facturasDescargar = [];
    }else{
      var body: string = this.translateService.instant("BodyErrorSearchDescargas");
      var title: string = this.translateService.instant("TitleErrorSearchDescargas");
      this.toastr.error(body, title);
    }
  }

  descargarBusquedaSeleccionado(){
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => {});
    if(this.facturasDescargar.length > 1){
      var log: LogModel = new LogModel();
      log.Click = "Botón descargar seleccionado";
      log.Pantalla = "listado.component.ts - Listado";
      var contador: number = 1;
      var listadoFacturaString: string[] = [];
      this.facturasDescargar.forEach(f => {
        listadoFacturaString.push(f);
        if(listadoFacturaString.length > 50){
          this.facturacionService.getZipFacturas(listadoFacturaString, contador, log).subscribe(a => {
            this.descargarPDF(a);
            dialogRef.close();
          }, err => {
            console.error("error")
            dialogRef.close();
          });
          contador++;
          listadoFacturaString = [];
        }
      });
      if(listadoFacturaString.length > 0){
        this.facturacionService.getZipFacturas(listadoFacturaString, contador, log).subscribe(a => {
          this.descargarPDF(a);
          dialogRef.close();
        }, err => {
          dialogRef.close();
        });
        contador++;
        listadoFacturaString = [];
      }
    }else{
      this.facturasDescargar.forEach(f => {
        this.facturacionService.getFactura(f).subscribe(archivos => {
          archivos.forEach(a => {
            this.descargarPDF(a);
            this.selectedAll = true;
            this.seleccionarTodos();
            this.facturasDescargar = [];
          });
          dialogRef.close();
        });
      }, _ => {
        console.error("error")
        dialogRef.close();
      });
    }
  }

  descargarBusqueda(){
    if(this.formSearchDescargas.valid){
      const dialogRef = this.dialog.open(WaitingComponent, {
        width: '250px',
        panelClass: 'my-panel',
        disableClose: true
      });
      dialogRef.afterOpened().subscribe(result => {});
      this.facturasSearch();
      var log: LogModel = new LogModel();
      log.Click = "Botón descargar todo";
      log.Pantalla = "listado.component.ts - Listado";
      if(this.listadoFacturas.length > 1){
        var listadoFacturaString: string[] = [];
        var contador = 1;
        this.listadoFacturas.forEach(f => {
          listadoFacturaString.push(f.NumFactura);
          if(listadoFacturaString.length > 50){
            this.facturacionService.getZipFacturas(listadoFacturaString, contador, log).subscribe(a => {
              this.descargarPDF(a);
              dialogRef.close();
            }, err => {
              console.error("error")
              dialogRef.close();
            });
            contador++;
            listadoFacturaString = [];
          }
        });
        if(listadoFacturaString.length > 0){
          this.facturacionService.getZipFacturas(listadoFacturaString, contador, log).subscribe(a => {
            this.descargarPDF(a);
            dialogRef.close();
          }, err => {
            console.error("error")
            dialogRef.close();
          });
        }
      }else{
        this.facturacionService.getFactura(this.listadoFacturas[0].NumFactura).subscribe(archivos => {
          archivos.forEach(a => {
            this.descargarPDF(a);
          });
          dialogRef.close();
        });
      }
    }else{
      var body: string = this.translateService.instant("BodyErrorDescargas");
      var title: string = this.translateService.instant("TitleErrorDescargas");
      this.toastr.error(body, title);
    }
  }

  descargarPDF(file: Archivo) {
    var data = atob(file.Base64image.toString())
    const bytenumbers = new Array(data.length);
    for (let i = 0; i < data.length; i++)
    {
      bytenumbers[i]= data.charCodeAt(i);
    }
    const byteArray = new Uint8Array(bytenumbers);
    const blob = new Blob([byteArray], {type: 'contentType'})
    
    if(file.FileName.includes(".")){
      saveAs(blob, file.FileName);
    }else{
      var extension: String = "";
      if(file.Extension.includes("."))
        extension = file.Extension;
      else
        extension = "." + file.Extension;
      saveAs(blob, file.FileName.concat(extension.toString()));
    }
    
    
  }

  resetListado(){
    this.listadoFacturas = this.listadoFacturasTotal;
    this.total = this.listadoFacturas.length;
    this.facturasDescargar = [];
    this.selectedAll = true;
    this.seleccionarTodos();
  }

}
