import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { localidadGoogleMaps } from 'src/app/models/localidadGoogleMaps.model';
import { Paises } from 'src/app/models/paises.model';
import { MapaService } from 'src/app/services/mapa.service';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})
export class MapaComponent implements OnInit {

  @Input() search: ElementRef;
  @Output() localidadOrigenEmit = new EventEmitter<localidadGoogleMaps>();
  @Output() localidadDestinoEmit = new EventEmitter<localidadGoogleMaps>();
  @Input() isTarifa: boolean = false;
  @Input() paisOrigen: Paises;
  @Input() paisDestino: Paises;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() localidadOrigen: localidadGoogleMaps;
  @Input() localidadDestino: localidadGoogleMaps;
  @Input() latitudeMapa: number;
  @Input() longitudeMapa: number;
  @Input() obtenerPosicionInicialOrigen: boolean;
  @Input() obtenerPosicionInicialDestino: boolean;
  @ViewChild("mapaAGM") map: google.maps.Map;
  @Input() origen: boolean = false;
  @Input() destino: boolean = false;
  @Input() existMarkerOrigen: boolean = false;
  @Input() existMarkerDestino: boolean = false;
  @Input() zoom: number = 6;
  textoOrigen: string = "";
  textoDestino: string = "";
  private geoCoder = new google.maps.Geocoder;
  postalCode;

  @ViewChild('mapSearchField') searchElementRef: ElementRef;

  camposGoogleMapsLocalidad: string[] = ["administrative_area_level_1", "administrative_area_level_2", "administrative_area_level_3", "administrative_area_level_4", "administrative_area_level_5",
    "administrative_area_level_6", "administrative_area_level_7", "sublocality_level_1", "sublocality_level_2", "sublocality_level_3", "sublocality_level_4", "sublocality_level_5", "plus_code", "locality", "postal_code",
    "country"];
  localidadResultado: string[] = [];
  localidad: localidadGoogleMaps = new localidadGoogleMaps();

  constructor(private mapaService: MapaService) { }

  ngOnInit(): void {
    if (this.obtenerPosicionInicialOrigen || this.obtenerPosicionInicialDestino) {
      this.getPosition().then(pos => {
        this.getAddress(pos.lat, pos.lng, true);
      });
    }
    if (this.origen)
      this.mapaService.getTextoLocalidadOrigen$().subscribe(texto => {
        this.textoOrigen = texto;
        this.getAddressText(false);
      });
    else if (this.destino)
      this.mapaService.getTextoLocalidadDestino$().subscribe(texto => {
        this.textoDestino = texto;
        this.getAddressText(false);
      });

  }

  markerDragEnd($event) {
    if (this.existMarkerOrigen || this.existMarkerDestino) {
      this.latitude = $event.latLng.lat();
      this.longitude = $event.latLng.lng();
      this.getAddress(this.latitude, this.longitude, false);
    }
  }

  markerDragEndPosition() {
    if (((this.existMarkerOrigen || this.existMarkerDestino) /*&& this.latitude != undefined && this.longitude != undefined*/))
      this.getAddress(this.latitude, this.longitude, false);
  }

  setResultado() {
    for (var i = 0; i < this.camposGoogleMapsLocalidad.length - 1; i++) {
      if (this.localidadResultado[i] != undefined && this.localidadResultado[i] != null) {
        if (i == 0)
          this.localidad.Administrative_area_level_1 = this.localidadResultado[i];
        else if (i == 1)
          this.localidad.Administrative_area_level_2 = this.localidadResultado[i];
        else if (i == 2)
          this.localidad.Administrative_area_level_3 = this.localidadResultado[i];
        else if (i == 3)
          this.localidad.Administrative_area_level_4 = this.localidadResultado[i];
        else if (i == 4)
          this.localidad.Administrative_area_level_5 = this.localidadResultado[i];
        else if (i == 5)
          this.localidad.Administrative_area_level_6 = this.localidadResultado[i];
        else if (i == 6)
          this.localidad.Administrative_area_level_7 = this.localidadResultado[i];
        else if (i == 7)
          this.localidad.Sublocality_level_1 = this.localidadResultado[i];
        else if (i == 8)
          this.localidad.Sublocality_level_2 = this.localidadResultado[i];
        else if (i == 9)
          this.localidad.Sublocality_level_3 = this.localidadResultado[i];
        else if (i == 10)
          this.localidad.Sublocality_level_4 = this.localidadResultado[i];
        else if (i == 11)
          this.localidad.Sublocality_level_5 = this.localidadResultado[i];
        else if (i == 12)
          this.localidad.Plus_code = this.localidadResultado[i];
        else if (i == 13)
          this.localidad.Ciudad = this.localidadResultado[i];
        else if (i == 14)
          this.localidad.CodigoPostal = this.localidadResultado[i];
        else if (i == 15)
          this.localidad.Pais = this.localidadResultado[i];
      }
      if ((this.localidad.Ciudad == undefined || this.localidad.Ciudad == null) && this.localidad.Administrative_area_level_1 != undefined && this.localidad.Administrative_area_level_1 != null && this.localidad.Administrative_area_level_1 != "")
        this.localidad.Ciudad = this.localidad.Administrative_area_level_1;
    }
  }

  getAddress(latitude, longitude, isPosicionOriginal) {
    var me = this;
    var texto;
    if (latitude != null && latitude != undefined && longitude != null && longitude != undefined)
      texto = { 'location': { lat: latitude, lng: longitude } };

    this.geoCoder.geocode(texto, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          me.localidadResultado = [];
          me.localidad = new localidadGoogleMaps();
          console.log(results);
          me.localidad.Lat = results[0].geometry.location.lat();
          me.localidad.Lng = results[0].geometry.location.lng();
          results[0].address_components.forEach(ac => {
            var index = me.camposGoogleMapsLocalidad.findIndex(cgml => ac.types.includes(cgml));
            if (index != -1)
              me.localidadResultado[index] = ac.long_name;
            if (ac.types.includes("country")) {
              me.localidad.IsoAlfa2 = ac.short_name;
              me.localidad.Pais = ac.long_name;
            }
          });
          if (isPosicionOriginal && (me.paisOrigen?.IsoAlfa2 == me.localidad.IsoAlfa2 || me.paisDestino?.IsoAlfa2 == me.localidad.IsoAlfa2)) {
            me.latitude = latitude;
            me.longitude = longitude;
            me.setResultado();
            if (me.origen)
              me.localidadOrigenEmit.emit(me.localidad);
            else if (me.destino)
              me.localidadDestinoEmit.emit(me.localidad);
          } else if (!isPosicionOriginal) {
            me.setResultado();
            if (me.origen)
              me.localidadOrigenEmit.emit(me.localidad);
            else if (me.destino)
              me.localidadDestinoEmit.emit(me.localidad);
          }
        }
      }
    });

  }

  getAddressText(isPosicionOriginal) {
    var me = this;
    var texto;

    if (this.origen)
      texto = { 'address': this.textoOrigen };
    else if (this.destino)
      texto = { 'address': this.textoDestino };

    this.geoCoder.geocode(texto, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          me.localidadResultado = [];
          me.localidad = new localidadGoogleMaps();
          console.log(results);
          me.localidad.Lat = results[0].geometry.location.lat();
          me.localidad.Lng = results[0].geometry.location.lng();
          results[0].address_components.forEach(ac => {
            var index = me.camposGoogleMapsLocalidad.findIndex(cgml => ac.types.includes(cgml));
            if (index != -1)
              me.localidadResultado[index] = ac.long_name;
            if (ac.types.includes("country")) {
              me.localidad.IsoAlfa2 = ac.short_name;
              me.localidad.Pais = ac.long_name;
            }
          });
          if (isPosicionOriginal && (me.paisOrigen?.IsoAlfa2 == me.localidad.IsoAlfa2 || me.paisDestino?.IsoAlfa2 == me.localidad.IsoAlfa2)) {
            me.latitude = results[0].geometry.location.lat();
            me.longitude = results[0].geometry.location.lng();
            me.setResultado();
            console.log(me.localidad);
            if (me.origen)
              me.localidadOrigenEmit.emit(me.localidad);
            else if (me.destino)
              me.localidadDestinoEmit.emit(me.localidad);
          } else if (!isPosicionOriginal) {
            me.setResultado();
            if (me.origen)
              me.localidadOrigenEmit.emit(me.localidad);
            else if (me.destino)
              me.localidadDestinoEmit.emit(me.localidad);
          }
        }
      }
    });

  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    //if((changes.localidadOrigen == null || changes.localidadOrigen == undefined))
    //this.markerDragEndPosition();
  }

  ngAfterViewInit(): void {
    //this.mapaService.mapa = new google.maps.Map(this.map);
    // this.map.setOptions({
    //   maxZoom: 10,
    //   minZoom: 5
    // });
  }
}
