<span *ngIf="tipoUser == 7"><app-header-conductor></app-header-conductor></span>
<span *ngIf="tipoUser == 6 || tipoUser == 11"><app-header></app-header></span>
<span *ngIf="tipoUser == 10 || tipoUser == 12"><app-header-proveedor></app-header-proveedor></span>
<mat-sidenav-container class="example-container" autosize>
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
		<mat-nav-list>
            <mat-list-item (click)="isExpanded = !isExpanded">
                <span class="full-width" *ngIf="isExpanded">{{'Menú' | translate}}</span>
                <mat-icon mat-list-icon>menu</mat-icon>
            </mat-list-item>
            <mat-list-item [ngClass]="{'is-active': isSelection(0), '': !isSelection(0)}" (click)="changeSelection(0)">
                <span class="full-width" *ngIf="isExpanded">{{'Mi Perfil' | translate}}</span>
                <mat-icon mat-list-icon>perm_identity</mat-icon>
            </mat-list-item>
            <mat-list-item [ngClass]="{'is-active': isSelection(1), '': !isSelection(1)}" (click)="changeSelection(1)">
                <span class="full-width" *ngIf="isExpanded">{{'Configuración' | translate}}</span>
                <mat-icon mat-list-icon>settings</mat-icon>
            </mat-list-item>
            <mat-list-item [ngClass]="{'is-active': isSelection(2), '': !isSelection(2)}" (click)="changeSelection(2)">
                <span class="full-width" *ngIf="isExpanded">{{'MyAddressBook' | translate}}</span>
                <mat-icon mat-list-icon>contacts</mat-icon>
            </mat-list-item>
		</mat-nav-list>
	</mat-sidenav>

	<div class="contenedor">
		<div *ngIf="isSelection(0)">
            <app-mi-perfil-ajustes></app-mi-perfil-ajustes>
        </div>
        <div *ngIf="isSelection(1)">
            <app-configuracion></app-configuracion>
        </div>
        <div *ngIf="isSelection(2)">
            <app-address-book></app-address-book>
        </div>
        <div *ngIf="isSelection(3)">
        </div>
        <div *ngIf="isSelection(4)">
        </div>
        <div *ngIf="isSelection(5)">
        </div>
	</div>

</mat-sidenav-container>

<!--
<mat-sidenav-container class="example-container" autosize>
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true">
		<mat-nav-list>
            <mat-list-item (click)="isExpanded = !isExpanded">
                <span class="full-width" *ngIf="isExpanded">Menú</span>
                <mat-icon mat-list-icon>menu</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="selection = 1">
                <span class="full-width" *ngIf="isExpanded">Mi Perfil</span>
                <mat-icon mat-list-icon>perm_identity</mat-icon>
            </mat-list-item>
            
            <mat-list-item (click)="selection = 1">
                <span class="full-width" *ngIf="isExpanded">Mi Configuracion</span>
                <mat-icon mat-list-icon>image_aspect_ratio</mat-icon>
            </mat-list-item>
            
            <mat-list-item (click)="selection = 2">
                <span class="full-width" *ngIf="isExpanded">General</span>
                <mat-icon mat-list-icon>image_aspect_ratio</mat-icon>
            </mat-list-item>
		</mat-nav-list>
		<mat-nav-list>
		</mat-nav-list>
	</mat-sidenav>

	<div class="contenedor">
		<div *ngIf="selection == 0">
            <app-mi-perfil></app-mi-perfil>
        </div>
        <div *ngIf="selection == 1">
        </div>
        <div *ngIf="selection == 2">
        </div>
        <div *ngIf="selection == 3">
        </div>
        <div *ngIf="selection == 4">
        </div>
        <div *ngIf="selection == 5">
        </div>
	</div>

</mat-sidenav-container>
-->