import { Directive, Output, EventEmitter, HostBinding, HostListener, Host } from '@angular/core';

@Directive({
  selector: '[appDndDirective]'
})
export class DndDirectiveDirective { 

  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding("style.background-color") public background = '#f5fcff'
  @HostBinding("style.opacity") public opacity = '1'

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff'
    this.opacity = '1'
  }

  //Drop Listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff'
    this.opacity = '1'
    let files = evt.dataTransfer.files;
    if(files.length > 0) {
      this.onFileDropped.emit(files)
    }

  }

  constructor() { }

}
