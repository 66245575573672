<app-header-proveedor></app-header-proveedor>
<div class="col-12">
    <div class="container">
        <div class="block"></div>
        <div class="container">
            <!-- Mis archivos -->
            <div class="row">
                <div class="col-12 col-sm-8">
                    <div id="MisArchivos" class="contenedor">
                        <h5>{{'MisArchivosLabel'|translate}}</h5>
                        <table mat-table [dataSource]="dataSource">

                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                          

                            <!-- IconoArchivo Column -->
                            <!--<ng-container matColumnDef="IconoArchivo">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element"> 
                                    <img *ngIf="isPdf(element)" src="assets/imagenes/pdficono.ico" class="pdf" alt="" (click)="descargarPDF(file)"/>
                                    <img *ngIf="isPowerPoint(element)" src="assets/imagenes/imagenArchivoPowerPoint.png" class="pdf" alt="" (click)="descargarPDF(file)"/>
                                    <img *ngIf="isWord(element)" src="assets/imagenes/imagenArchivoWord.png" class="pdf" alt="" (click)="descargarPDF(file)"/>
                                    <img *ngIf="isExcel(element)" src="assets/imagenes/imagenArchivoExcel.png" class="pdf" width="20px" height="20px" alt="file">  
                                    <img *ngIf="isComprimido(element)" src="assets/imagenes/imagenArchivoWinrar.png" class="pdf" width="20px" height="20px" alt="file">
                                    <img *ngIf="isOutlook(element)" src="assets/imagenes/imagenArchivoOutlook.png" class="pdf" width="20px" height="20px" alt="file">
                                    <img *ngIf="isGenerico(element)" src="assets/imagenes/imagenGenerico.png" class="pdf" width="20px" height="20px" alt="file">
                                 </td>
                            </ng-container>-->

                            <!-- Nombre Column -->
                            <ng-container matColumnDef="Nombre">
                              <th mat-header-cell *matHeaderCellDef> {{'NombreColumn' | translate}} </th>
                              <td mat-cell *matCellDef="let element"> {{element.tipo | translate}} </td>
                            </ng-container>

                            <!-- Estado Column -->
                            <ng-container matColumnDef="Estado">
                                <th mat-header-cell *matHeaderCellDef> {{'EstadoColumn' | translate}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="estado">
                                        <div [ngClass]="{'interior-estado-error': isError(element), 'interior-estado-verificando': (this.isVerificando(element) && !this.isCaducadoTipoArchivo(element))
                                    , 'interior-estado-sinSubir': isSinSubir(element), 'interior-estado-ok': (this.isSubido(element) && !this.isCaducadoTipoArchivo(element)),
                                'interior-estado-caducado': isCaducado(element)}" class="interior-estado"></div>
                                    </div>
                                </td>
                              </ng-container>
                          
                            <!-- Fecha Final Validez Column -->
                            <ng-container matColumnDef="Obligatorio">
                              <th mat-header-cell *matHeaderCellDef> {{'ObligatorioColumn' | translate}} </th>
                                <td mat-cell *matCellDef="let element">
                                    <i *ngIf="element.obligatorio" class="material-icons verde cursorNomal">check</i>
                                    <i *ngIf="!element.obligatorio" class="material-icons rojo cursorNomal">close</i>
                                </td>
                            </ng-container>
                          
                            <!-- DescargarSubir Column -->
                            <ng-container matColumnDef="DescargarSubir">
                              <th mat-header-cell *matHeaderCellDef></th>
                              <td mat-cell *matCellDef="let element"> 
                                <i class="material-icons cursorSeleccionar" *ngIf="!isUpload(element) || isError(element) || isCaducado(element)" (click)="tipoArchivoSelected = element; openModal(templateSubidaFicheroEntidad)">cloud_upload</i>  
                                <i class="material-icons cursorSeleccionar" *ngIf="isUpload(element) && !isError(element) && !isCaducado(element)" (click)="descargarPDF(element)">file_download</i> </td>
                            </ng-container>
                          
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                    </div>
                </div>
                <div class="col-12 col-sm-4">
                    <div class="row contenedorAux">
                        <div class="col-12 col-sm-12 contenedor">
                            <h5>{{'LeyendaTitle'|translate}}</h5>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons cursorNomal">cloud_upload</i>  
                                </div>
                                <div class="col-2">
                                    <i class="material-icons cursorNomal">arrow_right_alt</i>
                                </div>
                                <div class="col-8">
                                    {{'SubirArchivoLabel' | translate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons cursorNomal">file_download</i>
                                </div>
                                <div class="col-2">
                                    <i class="material-icons cursorNomal">arrow_right_alt</i>
                                </div>
                                <div class="col-8">
                                    {{'DescargarArchivoLabel' | translate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons verde cursorNomal">check</i>
                                </div>
                                <div class="col-2">
                                    <i class="material-icons cursorNomal">arrow_right_alt</i>
                                </div>
                                <div class="col-8">
                                    {{'ArchivoObligatorioLabel' | translate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <i class="material-icons rojo cursorNomal">close</i>
                                </div>
                                <div class="col-2">
                                    <i class="material-icons cursorNomal">arrow_right_alt</i>
                                </div>
                                <div class="col-8">
                                    {{'ArchivoNoObligatorioLabel' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12 contenedor">
                            <h5>{{'ResumenTitle'|translate}}</h5>
                            <div class="marginBottom">
                                <canvas baseChart [colors]="colorsEstados" [data]="contadorEstados" [labels]="estados" [chartType]="doughnutChartType"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #templateSubidaFicheroEntidad>
            <div class="modal-content fondo">
                <div class="modal-header fondo">
                <h2 class="modal-title pull-left">{{'SubidaFicheroTitle'|translate}}</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body fondo">
                    <!--(confirmEmit)="obtenerConfirmacion($event)"-->
                    <app-subir-fichero-entidad 
                    (close)="cerrarModal($event)"
                    [TipoArchivo]="tipoArchivoSelected"></app-subir-fichero-entidad>
                </div>
            </div>
        </ng-template>
    </div>
</div>