<table class="table table-hover text-center">
    <thead>
        <td class="font-medium"></td>
        <!--<td class="font-medium">Estado actual</td>-->
    </thead>
    <ng-container *ngFor="let hr of hojasRuta.filteredData">
        <tr>
            <td>
                <mat-accordion >
                    <mat-expansion-panel class="changeColor"  
                    (opened)="panelOpenState = true;" 
                    (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="changeColor2">
                            <mat-panel-title >
                                <span matBadge="{{hr.Expediciones.length}}" matBadgeOverlap="false">{{hr.IdHojaRuta}}</span>
                                <!--<mat-icon  *ngIf="!panelOpenState">keyboard_arrow_left</mat-icon>
                                <mat-icon *ngIf="panelOpenState">keyboard_arrow_down</mat-icon>-->
                            </mat-panel-title>
                            <!--<mat-panel-description style="float:right;">
                                <button style="font-size: small;" (click)="hrSelected = hr; openModal(templateSubirPendientesHojaRuta);" class="btn">{{'Multiple recogida'|translate}}</button>
                            </mat-panel-description>-->
                        </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>
                        <app-detalles [isHistorial]="false" [indice]="null" [hr]="hr" [listaExpediciones]="hr.Expediciones" (verificacionConductor)="openToast($event)"></app-detalles>
                      </ng-template>
                    </mat-expansion-panel>
                  </mat-accordion>
            </td>       
        </tr>
    </ng-container>
</table>
