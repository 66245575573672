import { Archivo } from './archivo.model';

export class Incidencia {
    IdIncidenciaExpedicion?: number;
    OrigenIncidencia: number;
    FechaCreacion: string;
    FechaCierre: string;
    Descripcion: string;
    IdTipoIncidencia: number;
    Tratamiento: string;
    Expedicion: string;
    IdTrackingExpedicion?: number;
    Archivos: Archivo[];
}
