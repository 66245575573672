import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfiguracionGeneral } from 'src/app/models/configuracionGeneral.model';
import { Incoterms } from 'src/app/models/incoterms.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  pantalla: string = "app-general";
  formGeneral: FormGroup;
  listaIncotermsAereo: Incoterms[] = [];
  listaIncotermsMaritimo: Incoterms[] = [];
  listaIncotermsTerrestre: Incoterms[] = [];
  configuracionGeneralModel: ConfiguracionGeneral = new ConfiguracionGeneral();
  constructor(private formBuilder: FormBuilder, private envioService: ShipmentService, private configuracionService: ConfigurationService,
    public translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.formGeneral = this.formBuilder.group(
      {
          incotermAereo: [null, Validators.required],
          incotermMaritimo: [null, Validators.required],
          incotermTerrestre: [null, Validators.required],
          mercanciaPeligrosa: [false, Validators.required],
          remontable: [false, Validators.required]
      });
      
      this.getIncoterms();
      this.getConfigGeneral();
  }

  public get incotermAereo() { return this.formGeneral.get('incotermAereo') }
  public get incotermMaritimo() { return this.formGeneral.get('incotermMaritimo') }
  public get incotermTerrestre() { return this.formGeneral.get('incotermTerrestre') }
  public get mercanciaPeligrosa() { return this.formGeneral.get('mercanciaPeligrosa') }
  public get remontable() { return this.formGeneral.get('remontable') }

  getConfigGeneral(){
    this.configuracionService.getGeneral();
    this.configuracionService.getGeneral$().subscribe(conf => {
      this.incotermAereo.setValue(conf.incotermAereo);
      this.incotermMaritimo.setValue(conf.incotermMaritimo);
      this.incotermTerrestre.setValue(conf.incotermTerrestre);
      this.mercanciaPeligrosa.setValue(conf.mercanciaPeligrosa);
      this.remontable.setValue(conf.remontable);
    });
  }

  getIncoterms(){
    this.envioService.getIncoterms(3).subscribe(listIncotermsAereo => {
      this.listaIncotermsAereo = listIncotermsAereo;
    });
    this.envioService.getIncoterms(2).subscribe(listIncotermsMaritimo => {
      this.listaIncotermsMaritimo = listIncotermsMaritimo;
    });
    this.envioService.getIncoterms(1).subscribe(listIncotermsTerrestre => {
      this.listaIncotermsTerrestre = listIncotermsTerrestre;
    });
  }

  convertFormToModel(){
    this.configuracionGeneralModel.incotermAereo = this.incotermAereo.value;
    this.configuracionGeneralModel.incotermMaritimo = this.incotermMaritimo.value;
    this.configuracionGeneralModel.incotermTerrestre = this.incotermTerrestre.value;
    this.configuracionGeneralModel.mercanciaPeligrosa = this.mercanciaPeligrosa.value;
    this.configuracionGeneralModel.remontable = this.remontable.value;
  }

  postConfGeneral(click: string){
    this.convertFormToModel();
    var logModel = new LogModel();
    logModel.Click = click;
    logModel.Pantalla = this.pantalla;
    this.configuracionService.putGeneral(this.configuracionGeneralModel, logModel).subscribe(d => {
      var body = this.translate.instant("ConfiguracionGeneralActualizado");
      var title = this.translate.instant("Actualizado");
      this.toastr.success(body, title);
    });
  }

}
