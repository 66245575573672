import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Conductor } from 'src/app/models/conductor.model';
import { ConductorService } from 'src/app/services/conductor.service';

@Component({
  selector: 'app-lista-conductores',
  templateUrl: './lista-conductores.component.html',
  styleUrls: ['./lista-conductores.component.scss']
})
export class ListaConductoresComponent implements OnInit {

  clase: string = "app-asignar-conductor";
  conductores;
  conductor: Conductor;
  @Output() conductorSeleccionado = new EventEmitter<Conductor>();
  displayedColumns: string[] = ['usuario', 'nombre', 'apellidos', 'nif', 'update'];
  @Output() cerrarModal = new EventEmitter<number>();
  selection = new SelectionModel<Conductor>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRef: BsModalRef | null;
  itemsPorPage: number = 0;
  sizeOptions: number[] = [];
  lengthTable: number = 0;

  constructor(private conductorService: ConductorService, private translate: TranslateService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.conductorService.getAllConductores();
    this.conductorService.getConductores$().subscribe(data => {
      this.sizeOptions = [];
      this.conductores = new MatTableDataSource<Conductor>(data);
      this.lengthTable = data.length;
      this.conductores.paginator = this.paginator;
      for(var i = 1; i <= (this.lengthTable/10)+1; i++){
          this.sizeOptions = [...this.sizeOptions,i*10];
          this.itemsPorPage = i*10;
      }
    }, err =>{})
  }

  ngOnChanges(changes: SimpleChange){
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.conductores.filter = filterValue.trim().toLowerCase();
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      class: 'modal-sm',
      ignoreBackdropClick: true,
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  cerrar(evento){
    this.modalRef.hide();
  }

  setDelete(element: Conductor){
    this.conductor = element;
  }

  setRow(row: Conductor){
    this.conductorService.setConductor$(row);
  }

  cancelar(){
    this.cerrarModal.emit(1);
  }

}
