import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { Direccion } from 'src/app/models/direccion.model';
import { Plantilla } from 'src/app/models/plantilla.modal';
import { Shipment } from 'src/app/models/shipment.model';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.scss']
})
export class PlantillasComponent implements OnInit {

  @Output() send = new EventEmitter<Shipment>();
  @Input() idArea: number;
  modalRef: BsModalRef | null;
  listaPlantillas: Plantilla[];
  listaPlantillasAux: Plantilla[];
  envioPredeterminado: Shipment;
  pageActual: number;
  sizePage: number;
  total: number;
  indiceBorrar: number;
  currentRow: any;
  searchText: string;

  constructor(private shipmentService: ShipmentService, private toastr: ToastrService,
    private modalService: BsModalService, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.currentRow = -1;
    this.pageActual = 1;
    this.sizePage = 10;
    this.listaPlantillas = [];
    this.refresh();   
  }

  selectRow(event: any, plantilla: Plantilla) {
    this.currentRow = plantilla.IdPlantilla;
  }

  close() {
    this.send.emit(null);
  }

  envioPlantilla(){
    this.shipmentService.getPlantilla(this.currentRow).subscribe(data => {
      this.envioPredeterminado = data;
      this.send.emit(this.envioPredeterminado);
    },
    error => {
      var errorBody = this.translateService.instant('Error al cargar la plantilla.');
      var errorTitulo = this.translateService.instant('Error plantilla');
      this.toastr.error(errorBody, errorTitulo)
    })
  }

  deletePlantilla(i: number) {
    let plantilla: Plantilla = this.listaPlantillas[i];
    this.shipmentService.deletePlantilla(plantilla.IdPlantilla).subscribe(data => {
      var errorBody = this.translateService.instant('Plantilla eliminada correctamente.');
      var errorTitulo = this.translateService.instant('Plantilla eliminada');
      this.toastr.success(errorBody, errorTitulo)
      this.refresh();
    },
    error => {
      var errorBody = this.translateService.instant('No se ha podido eliminar la plantilla');
      var errorTitulo = this.translateService.instant('Error plantilla');
      this.toastr.error(errorBody, errorTitulo)
    })
  }

  refresh() {
    this.shipmentService.getPlantillas(this.idArea).subscribe(data => {
      this.listaPlantillas = data;
      this.listaPlantillasAux = data;
      this.total = this.listaPlantillas.length;
    }) 
  }

  openAlert(template: TemplateRef<any>, i: number) {

    this.indiceBorrar = i;
    const config: ModalOptions = {
      id: 2,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    
    this.modalRef = this.modalService.show(template, config);
  }

  obtenerConfirmacion(confirmacion: boolean)
  {
    this.modalRef.hide()
    if(confirmacion == true)
    {
      this.deletePlantilla(this.indiceBorrar)
    }
    this.indiceBorrar = -1;
  }

  
  changeSearch(){
    this.listaPlantillasAux = this.listaPlantillas;
    if(this.searchText != "")
    {
      this.listaPlantillasAux = this.listaPlantillas.filter(dir => this.existeCadenaDireccion(dir));
    }
    else
    {
      this.listaPlantillasAux = this.listaPlantillas;
    }
    this.total = this.listaPlantillasAux.length;
  }

  existeCadenaDireccion(plantilla: Plantilla): boolean{
    return (plantilla.NombreDestinatario.includes(this.searchText.toUpperCase()) 
    || (plantilla.TipoEnvio != null && plantilla.TipoEnvio.includes(this.searchText.toUpperCase())) 
    || (plantilla.ModoEnvio != null && plantilla.ModoEnvio.includes(this.searchText.toUpperCase())))
  }

}
