import { Component, OnInit, SimpleChange } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-header-language',
  templateUrl: './header-language.component.html',
  styleUrls: ['./header-language.component.scss']
})
export class HeaderLanguageComponent implements OnInit {

  idiomas = [{value: 'es',
              idioma: 'Español'},
              {value: 'en',
              idioma: 'English'}];
  idiomaSelected: string = this.idiomas[0].value;  

  constructor(private translateService: TranslateService, private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    let lang: string = this.translateService.getDefaultLang();
    if(lang == 'es')
      this.idiomaSelected = this.idiomas[0].value;
    else
      this.idiomaSelected = this.idiomas[1].value;
      
    this.usuarioService.setLenguaje$(this.idiomaSelected);
  }

  public useLanguage(lang): void {
    this.translateService.setDefaultLang(lang);
    this.usuarioService.setLenguaje$(lang);
  }

  ngOnChanges(changes: SimpleChange){
    let lang: string = this.translateService.getDefaultLang();
    if(lang == 'es'){
      this.idiomaSelected = this.idiomas[0].value;
    }else{
      this.idiomaSelected = this.idiomas[1].value;
    }
  }

}
