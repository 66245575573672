<div class="row">
    <div class="col-6 col-sm-6">
        <label class="btn" style="margin-bottom: 20px;margin-right: 20px;">{{'Añadir imagen o PDFs'|translate}}
            <input #foto type="file" required id="Foto" name="Foto" (change)="fileChanges($event.target.files)"
                    style="visibility: hidden;width: 0px; height: 0px;" accept="image/*;capture=camera" capture="camera">
        </label>
    </div>
    <div class="col-6 col-sm-6">
        <button type="button" id="showFoto" class="btn" 
                style="margin-bottom: 20px;" (click)="openModal(templateNested)">{{'Mostrar imagen/es'|translate}}</button>
    </div>
</div>
<div class="row">
    <div class="col-6 col-sm-6">
        <label style="margin-left: 2%; margin-right: 2%;margin-bottom: 20px;">{{'Se ha cargado'|translate}} {{photo.length}} fotos</label>
    </div>
    <div class="col-6 col-sm-6">
        <label style="margin-left: 2%; margin-right: 2%;margin-bottom: 20px;">{{'Se ha cargado'|translate}} {{pdfs.length}} PDF's</label>
    </div>
</div>
<div class="row" style="margin-top: 2%;">
    <div class="col-6 col-sm-6"> 
        <button class="btn" (click)="enviarFactura(templateActualizarFacturas);" type="submit" mat-button cdkFocusInitial>{{'Enviar'|translate}}</button>
      </div>
    <div class="col-6 col-sm-6">
        <button class="btn buttonCancelar" data-dismiss="modal" #btnClose mat-button
        (click)="photo = []; pdfs = [];close();">{{'Cancelar'|translate}}</button>
    </div>
</div>

<ng-template #templateNested>
    <div class="modal-header fondo">
      <p class="h4 modal-title pull-left">{{'Galeria'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-galeria-imagenes [photos]="photo" [idIncidencia]="null"></app-galeria-imagenes>
    </div>
</ng-template>

<ng-template #templateActualizarFacturas>
    <div class="modal-header fondo">
        <p class="h4 modal-title pull-left">
            {{'Actualización de facturas'|translate}}
        </p>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body fondo">
        <app-actualizar-factura [texto]="textoModal" (confirmacionActualizarFactura)="enviarActualizarFactura($event)"></app-actualizar-factura>
    </div>
</ng-template>