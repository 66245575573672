import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from './authentication.service';
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from 'rxjs';
import { Expedition } from '../models/expedition.model';
// Para poner la url de la API sin tener que cambiar todos
import { environment } from '../../environments/environment';
import { Archivo } from '../models/archivo.model';
import { HojaRuta } from '../models/hojaRuta.model';
import { LogModel } from '../models/log-model.model';
import { ActualizarConductor } from '../models/actualizarConductor.model';
import { LogService } from './log.service';


@Injectable({
  providedIn: 'root'
})
export class ExpeditionApiService {

  readonly apiUrlService = environment.apiUrl;
  listExpedition: Expedition[];
  base64: string = "";
  ip: string = ""; 
  localizacion: string = "";

  constructor(private http: HttpClient, private authService: AuthenticationService, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
  }

  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });
  

  // Obtener todas las expediciones
  getExpediciones(): Observable<Expedition[]> {
    const url_api = this.apiUrlService + "pa_expedicion";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_expedicionController.cs", "Log obtener todas las expediciones del conductor que ha iniciado sesión.",
     "Getpa_expedicion", "pa_expedicion", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Expedition[]));
  }

  // Actualizar conductor
  actualizarConductor(actualizarConductor: ActualizarConductor, log: LogModel){
    const url_api = this.apiUrlService + "pa_expedicion/actualizarConductor"; //encodeURIComponent(idExpedicion);
    return this.http.put(url_api, {"Log": log, "Body": JSON.stringify(actualizarConductor).toString()} ,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data))
  }

  // Cambiar el estado (falta que se cambie en la BBDD)
  cambiarEstado(nextEstado, idExpedicion, albaranYFirmaViewModel, log: LogModel){
    const url_api = this.apiUrlService + "pa_expedicion/" + idExpedicion; //encodeURIComponent(idExpedicion);
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    return this.http.put(url_api, {"Log": log, "Body": JSON.stringify({
      pa_expedicion: {
        Expedicion: idExpedicion,
        Estado: nextEstado
      },
      albaranYFirmaViewModel: albaranYFirmaViewModel}).toString()} ,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data))
    
  }

  // Cambiar el estado (falta que se cambie en la BBDD)
  cambiarEstadoConjunto(seleccionadosString: string[], log: LogModel){
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "pa_expedicion/conjunta";
    return this.http.put(url_api, {"Log": log, "Body": JSON.stringify(seleccionadosString).toString()} ,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data))
    
  }

  getProveedor(){
    const url_api = this.apiUrlService + "cfg_conductor/proveedor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.logService.postLogCompleto("GET", "cfg_conductorController.cs", "Log obtener si el usuario que ha iniciado sesión, es proveedor.",
     "Get_cfg_conductor_proveedor", "cfg_conductor", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as boolean));
  }

  // Obtener las expediciones para la lista de pendientes
  getExpedicionesPedientes(){
    const url_api = this.apiUrlService + "pa_expedicion/conductor/N";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_expedicionController.cs", "Log obtener las expediciones pendientes.",
     "Get_pa_expedicionesConductorAsync", "pa_expedicion", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Expedition[]));
  }

  // Obtener las expediciones para el historial
  getExpedicionesHistorico(){
    const url_api = this.apiUrlService + "pa_expedicion/conductor/S";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_expedicionController.cs", "Log obtener las expediciones terminadas.",
     "Get_pa_expedicionesConductorAsync", "pa_expedicion", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Expedition[]));
  }

  // Obtener las expediciones para el historial
  getExpedicionesHistoricoProveedor(){
    const url_api = this.apiUrlService + "pa_expedicion/proveedor/historial";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_expedicionController.cs", "Log obtener las expediciones terminadas del proveedor.",
     "Get_pa_expedicionesProveedorHistorialAsync", "pa_expedicion", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Expedition[]));
  }

  // Obtener las hojas de ruta del conductor login
  getHojasDeRuta(){
    const url_api = this.apiUrlService + "pa_hojaruta";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_hojarutaController.cs", "Log obtener las hojas de ruta del conductor.",
     "Getpa_hojaruta", "pa_hojaruta", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as HojaRuta[]));
  }

  // Obtener la información de la expedición de idExpedicion que se le pasa (verDetalles)
  getExpedicion(idExpedicion): Observable<Expedition>{
    let headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "pa_expedicion/ordendecarga/" + encodeURIComponent(idExpedicion);
    this.logService.postLogCompleto("GET", "pa_expedicionController.cs", "Log obtener la orden de carga de la expedicion " + idExpedicion + ".",
     "Get_OrdenDeCargaInfoAsync", "pa_expedicion", url_api);
    return this.http.get(url_api, { headers: headersToken }).pipe(map(data => data as Expedition));
  }

  getDocumentos(idExpedicion){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "pa_firmaalbaran/documentos/" + encodeURIComponent(idExpedicion);
    this.logService.postLogCompleto("GET", "pa_firmaalbaranController.cs", "Log obtener los documentos de la expedición " + idExpedicion + ".",
     "Get_pa_firmaalbaranPorIdExpedicionYVisible", "pa_firmaalbaran", url_api);
    return this.http.get(url_api, {headers: headersToken}).pipe(map(data => data as Observable<Archivo[]>))
  }

  getDocumento(idDocumento): Observable<Archivo>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authService.getToken()
    })
    const url_api = this.apiUrlService + "cfg_archivosenvio/" + idDocumento;
    this.logService.postLogCompleto("GET", "cfg_archivosenvioController.cs", "Log obtener el documento " + idDocumento + ".",
     "Get_cfg_archivosenvioPorId", "cfg_archivosenvio", url_api);
    return this.http.get(url_api, {headers: headersToken}).pipe(map(data => data as Archivo))
  }

  postFacturas(photos: string[], pdfs: string[], idExpedicion: number, logmodel: LogModel){
    const url_api = this.apiUrlService + "cfg_archivosenvio/expedicion/" + idExpedicion + "/facturas";
    logmodel.Ip = this.ip;
      logmodel.Localizacion = this.localizacion;
    var json = JSON.stringify({
      Log: logmodel,
      Body: JSON.stringify({
        facturasPDF: pdfs,
        facturasImagenes: photos}).toString()
    })
    return this.http.post(url_api, json ,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data))
  }

  postFacturasConjuntas(photos: string[], pdfs: string[], expediciones: string[], logmodel: LogModel){
    const url_api = this.apiUrlService + "cfg_archivosenvio/expedicion/facturas";
    logmodel.Ip = this.ip;
      logmodel.Localizacion = this.localizacion;
    var json = JSON.stringify({
      Log: logmodel,
      Body: JSON.stringify({
        facturasPDF: pdfs,
        facturasImagenes: photos,
        Expediciones: expediciones}).toString()
    })
    return this.http.post(url_api, 
       json,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data))
  }

  getExisteFactura(idExpedicion: number){
    const url_api = this.apiUrlService + "cfg_archivosenvio/expedicion/"+ idExpedicion +"/facturas";
    this.logService.postLogCompleto("GET", "cfg_archivosenvioController.cs", "Log obtener si existe facturas de la expedición " + idExpedicion + ".",
     "Get_cfg_archivosenvioFacturasExistentes", "cfg_archivosenvio", url_api);
    return this.http.get(url_api,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data))
  }

  getExisteFacturas(expediciones: string[], logmodel: LogModel): Observable<string[]>{
    const url_api = this.apiUrlService + "cfg_archivosenvio/expedicion/facturas/existentes";
    logmodel.Ip = this.ip;
      logmodel.Localizacion = this.localizacion;
    var json = JSON.stringify({
      Log: logmodel,
      Body: JSON.stringify(
        expediciones).toString()
    })
    return this.http.post(url_api,json,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data as string[]))
  }

  putExpedicionConductorConjunto(expediciones: string[], idConductor: number, logmodel: LogModel): Observable<boolean>{
    const url_api = this.apiUrlService + "pa_expedicion/actualizarConductor/conjunta/" + idConductor;
    logmodel.Ip = this.ip;
    logmodel.Localizacion = this.localizacion;
    var json = JSON.stringify({
      Log: logmodel,
      Body: JSON.stringify(expediciones).toString()
    })
    return this.http.put(url_api,json,{headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data as boolean))
  }

  putExpedicionVehiculoYConductorConjunto(expediciones: string[], idConductor: number, idVehiculo: number, logmodel: LogModel){
    const url_api = this.apiUrlService + "pa_expedicion/actualizarConjunta";
    logmodel.Ip = this.ip;
    logmodel.Localizacion = this.localizacion;
    var json = JSON.stringify({
      Log: logmodel,
      Body: JSON.stringify({
        expediciones: expediciones,
        idVehiculo: idVehiculo,
        idConductor: idConductor
      })
    });
    return this.http.put(url_api, json, {headers: this.headers.append("Authorization", "Bearer " + this.authService.getToken())}).pipe(map(data => data as boolean));
  }

}
