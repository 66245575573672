import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-header-proveedor',
  templateUrl: './header-proveedor.component.html',
  styleUrls: ['./header-proveedor.component.scss']
})
export class HeaderProveedorComponent implements OnInit {

  nomUser: string;
  token: string;
  tipoUser: string;
  path: string;
  imagePath: SafeResourceUrl;
  mostrar: boolean;
  logo: string = "";
  clase: string = "app-header-proveedor";

  constructor(private envioService: ShipmentService, private router: Router,
    private toastr: ToastrService, private authenticationService: AuthenticationService,
    private _sanitizer: DomSanitizer, private translateService: TranslateService)  { }

  ngOnInit(): void {
    this.nomUser = this.authenticationService.getNombre();
  }

  getLogo(){
    this.authenticationService.getLogo().subscribe(logo =>{
      this.logo = logo as string;
      this.mostrar = true;
    }, error => {
      this.mostrar = false;
    })
  }
 
  getLogoHTML(){
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + this.logo)
  }


onLogout(click: string) {
  var log: LogModel = new LogModel();
  log.Click = click;
  log.Pantalla = this.clase;
  //Añadir en la bbdd en el diario de conexiones, la desconexion
  this.authenticationService.logOut(log).subscribe((data: any) => {
    localStorage.clear();
    this.router.navigate(['login']).then(() => {});
  },
  error => {
    var errorBody = this.translateService.instant('Problemas con el servidor');
    var errorTitulo = this.translateService.instant('Error');
    this.toastr.error(errorBody, errorTitulo)
  });
}

}
