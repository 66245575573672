import { localidadGoogleMaps } from "./localidadGoogleMaps.model";

export class AddressBook {
    codigo: number;
    address: string;
    nombreFiscal: string;
    nombreComercial: string;
    CIF: string;
    telefono1: string;
    telefono2: string;
    telefono3: string;
    email: string;
    nombreContacto: string;
    activa: boolean;
    idLocalidad: number;
    localidad: localidadGoogleMaps;
    pais: number;
    nombreLocalidad: string;
}
