import { MercanciaPeligrosa } from './mercancia-peligrosa.model';

export class Mercancia {
    Alto: number;
    Ancho: number;
    Bultos: number;
    IdContenedor?: number;
    IdMercancia?: number;
    IdTipoMercancia: number;
    Largo: number;
    MercanciaPeligrosa: MercanciaPeligrosa[];
    MP: number;
    NA: number;
    NombreContenedor?: string;
    NombreEmbalaje?: string;
    PesoBruto: number;
    PesoNeto: number;
    Remontable: boolean;
    NoRemontable: boolean
    TipoEmbalaje?: number;
    TipoMercancia: string;
    Volumen: number;
    Peligrosa: boolean;
    Alimentacion: boolean;
    GDP: boolean;
    General: boolean;
    NombreMercancia: string;
    GradosMin: number;
    GradosMax: number;
    TemperaturaControlada: boolean;
    HSCode: string;
}