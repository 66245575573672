<table *ngIf="gradoMinimo != undefined && gradoMinimo != null && gradoMaximo != undefined && gradoMaximo != null" 
    style="border-top-width: 1px !important;"
    class="table table-sm table-bordered table-editable table-row-action edit-action">
      <thead>
        <th *ngIf="esIgualGradoMaximoQueMinimo()">{{ "Grados" | translate }}</th>
        <th *ngIf="!esIgualGradoMaximoQueMinimo()">{{ "GradoMinimo" | translate }}</th>
        <th *ngIf="!esIgualGradoMaximoQueMinimo()">{{ "GradoMaximo" | translate }}</th>
      </thead>
      <tbody>
        <td *ngIf="esIgualGradoMaximoQueMinimo()">{{ gradoMaximo }}</td>
        <td *ngIf="!esIgualGradoMaximoQueMinimo()">{{ gradoMinimo }}</td>
        <td *ngIf="!esIgualGradoMaximoQueMinimo()">{{ gradoMaximo }}</td>
      </tbody>
    </table>
    
  