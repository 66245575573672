import { Component, OnInit } from '@angular/core';
import { Embalaje } from 'src/app/models/embalaje.model';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})
export class ConfiguracionComponent implements OnInit {
  checked = false;
  disabled = false;
  actualizar: Boolean;
  embalaje: any;
  constructor() { }

  ngOnInit(): void {
  }

  getEmbalaje(embalaje) {
    this.embalaje = embalaje;
  }
}
