import { Component, Input, OnInit } from '@angular/core';
import { incidenciaPartida } from 'src/app/models/incidenciaPartida.model';
import { IncidenciasService } from 'src/app/services/incidencias.service';

@Component({
  selector: 'app-incidencias-partida',
  templateUrl: './incidencias-partida.component.html',
  styleUrls: ['./incidencias-partida.component.scss']
})
export class IncidenciasPartidaComponent implements OnInit {

  key: string;
  reverse: boolean = false;
  @Input() referencia: string = "";
  listIncidencias: incidenciaPartida[] = [];

  constructor(private incidenciasService: IncidenciasService) { }

  ngOnInit(): void {
    this.incidenciasService.getIncidenciasPartida(this.referencia).subscribe(data => {
      this.listIncidencias = data;
      console.log(data);
    });
  }

  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

}
