<app-header-conductor></app-header-conductor>
<div class="container">
    <div class="row">
        <div class="col-sm">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab>
                <ng-template matTabLabel>
                    <span matBadge="{{pendientesRecogida.length}}" matBadgeOverlap="false">{{'recogida'|translate}}</span>
                </ng-template>
                <table class="table table-hover text-center">
                    <thead>
                        <td class="font-medium"></td>
                    </thead>
                    <ng-container *ngFor="let rem of destinatarioRemitentePendientesRecogida | slice: (pageActual[0]-1) * sizePage[0] : (pageActual[0]-1) * sizePage[0] + sizePage[0];  let i = index;">
                        <tr>
                            <td>
                                <mat-accordion>
                                    <mat-expansion-panel class="changeColor"  
                                    (opened)="openStateRecogida[i] = true;" 
                                    (closed)="openStateRecogida[i] = false;"
                                    [expanded]="openStateRecogida[i]">
                                        <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px" class="changeColor2">
                                            <mat-panel-title style="align-items: center;">
                                                <span matBadge="{{rem.Expediciones.length}}"   matBadgeOverlap="false"><p style="font-size: small;">{{rem.DestinatarioRemitente}}</p></span>
                                            </mat-panel-title>
                                            <mat-panel-description style="float:right;">
                                                <button style="font-size: small;" (click)="remitenteSelected = rem; openModal(templateSubirPendientes);" class="btn">{{'Multiple recogida'|translate}}</button>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                      <ng-template matExpansionPanelContent>
                                        <app-detalles [isHistorial]="false" [indice]="null" [hr]="null" [listaExpediciones]="rem.Expediciones" (verificacionConductor)="openToast($event)"></app-detalles>
                                      </ng-template>
                                    </mat-expansion-panel>
                                  </mat-accordion>
                            </td>
                            
                        </tr>
                    </ng-container>
                </table>
                <div class="d-flex justify-content-between p-2" style="text-align: center;">
                    <ngb-pagination
                        [(page)]="pageActual[0]"
                        [pageSize]="sizePage[0]"
                        [collectionSize]="total[0]"
                        [rotate]="true"></ngb-pagination>
                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <span matBadge="{{pendientesEntrega.length}}" matBadgeOverlap="false">{{'entrega'|translate}}</span>
                </ng-template>
                <table class="table table-hover text-center">
                    <thead>
                        <td class="font-medium"></td>
                        <!--<td class="font-medium">Estado actual</td>-->
                    </thead>
                    <ng-container *ngFor="let des of destinatarioRemitentePendientesEntrega | slice: (pageActual[1]-1) * sizePage[1] : (pageActual[1]-1) * sizePage[1] + sizePage[1]; let i = index;">
                        <tr>
                            <td>
                                <mat-accordion >
                                    <mat-expansion-panel class="changeColor"  
                                    (opened)="openStateEntrega[i] = true;" 
                                    (closed)="openStateEntrega[i] = false"
                                    [expanded]="openStateEntrega[i]">
                                        <mat-expansion-panel-header class="changeColor2">
                                            <mat-panel-title>
                                                <span matBadge="{{des.Expediciones.length}}" matBadgeOverlap="false">{{des.DestinatarioRemitente}}</span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                      <ng-template matExpansionPanelContent>
                                        <app-detalles [isHistorial]="false" [indice]="null" [hr]="null" [listaExpediciones]="des.Expediciones" (verificacionConductor)="openToast($event)"></app-detalles>
                                      </ng-template>
                                    </mat-expansion-panel>
                                  </mat-accordion>
                            </td>
                            
                        </tr>
                    </ng-container>
                </table>
                <div class="d-flex justify-content-between p-2" style="text-align: center;">
                    <ngb-pagination
                        [(page)]="pageActual[1]"
                        [pageSize]="sizePage[1]"
                        [collectionSize]="total[1]"
                        [rotate]="true"></ngb-pagination>
                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <span matBadge="{{pendientesAlbaran.length}}" matBadgeOverlap="false">{{'entregaSinAlbaran'|translate}}</span>
                </ng-template>
                <table class="table table-hover text-center">
                    <thead>
                        <td class="font-medium"></td>
                        <!--<td class="font-medium">Estado actual</td>-->
                    </thead>
                    <ng-container *ngFor="let des of destinatarioRemitentePendientesEntregaAlbaran | slice: (pageActual[3]-1) * sizePage[3] : (pageActual[3]-1) * sizePage[3] + sizePage[3]; let i = index;">
                        <tr>
                            <td>
                                <mat-accordion >
                                    <mat-expansion-panel class="changeColor"  
                                    (opened)="openStateEntrega[i] = true;" 
                                    (closed)="openStateEntrega[i] = false"
                                    [expanded]="openStateEntrega[i]">
                                        <mat-expansion-panel-header class="changeColor2">
                                            <mat-panel-title>
                                                <span matBadge="{{des.Expediciones.length}}" matBadgeOverlap="false">{{des.DestinatarioRemitente}}</span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                      <ng-template matExpansionPanelContent>
                                        <app-detalles [isHistorial]="false" [indice]="null" [hr]="null" [listaExpediciones]="des.Expediciones" (verificacionConductor)="openToast($event)"></app-detalles>
                                      </ng-template>
                                    </mat-expansion-panel>
                                  </mat-accordion>
                            </td>
                            
                        </tr>
                    </ng-container>
                </table>
                <div class="d-flex justify-content-between p-2" style="text-align: center;">
                    <ngb-pagination
                        [(page)]="pageActual[3]"
                        [pageSize]="sizePage[3]"
                        [collectionSize]="total[3]"
                        [rotate]="true"></ngb-pagination>
                  </div>
            </mat-tab>
            <mat-tab>
                <ng-template matTabLabel>
                    <span matBadge="{{hojasRuta.length}}" matBadgeOverlap="false">{{'Hojas de ruta'|translate}}</span>
                </ng-template>
                <table class="table table-hover text-center">
                    <thead>
                        <td class="font-medium"></td>
                        <!--<td class="font-medium">Estado actual</td>-->
                    </thead>
                    <ng-container *ngFor="let hr of hojasRuta | slice: (pageActual[2]-1) * sizePage[2] : (pageActual[2]-1) * sizePage[2] + sizePage[2]">
                        <tr>
                            <td>
                                <mat-accordion >
                                    <mat-expansion-panel class="changeColor"  
                                    (opened)="panelOpenState = true;" 
                                    (closed)="panelOpenState = false">
                                        <mat-expansion-panel-header class="changeColor2">
                                            <mat-panel-title>
                                                <span matBadge="{{hr.Expediciones.length}}" matBadgeOverlap="false">{{hr.IdHojaRuta}}</span>
                                            </mat-panel-title>
                                            <mat-panel-description style="float:right;">
                                                <button style="font-size: small;" (click)="hrSelected = hr; openModal(templateSubirPendientesHojaRuta);" class="btn">{{'Multiple recogida'|translate}}</button>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                      <ng-template matExpansionPanelContent>
                                        <app-detalles [isHistorial]="false" [indice]="null" [hr]="hr" [listaExpediciones]="hr.Expediciones" (verificacionConductor)="openToast($event)"></app-detalles>
                                      </ng-template>
                                    </mat-expansion-panel>
                                  </mat-accordion>
                            </td>
                            
                        </tr>
                    </ng-container>
                </table>
                <div class="d-flex justify-content-between p-2" style="text-align: center;">
                    <ngb-pagination
                        [(page)]="pageActual[2]"
                        [pageSize]="sizePage[2]"
                        [collectionSize]="total[2]"
                        [rotate]="true"></ngb-pagination>
                  </div>
            </mat-tab>
          </mat-tab-group>
        </div>
    </div>
</div>

<!-- The Modal Ver Detalles -->
<div class="modal fade" id="verDetalles">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <!-- Modal header -->
            <div class="modal-header fondo" style="border-color: black;">
                <p class="h3 modal-title">{{'Detalles de'|translate}} {{expedicion}}</p>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body fondo">
                <app-detalles [listExp]="listExp"></app-detalles>
            </div>
        </div>
    </div>
</div>

<!-- The Modal Subir Pendientes -->
<ng-template #templateSubirPendientes>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir expediciones pendientes conjuntas'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-pendientes (verificacionConjunta)="openToastConjunto($event)" [expedicionesPendiente]="pendientesRecogida" [remitente]="remitenteSelected"></app-subir-pendientes>
    </div>
</ng-template>

<!-- The Modal Subir Pendientes Hoja Ruta -->
<ng-template #templateSubirPendientesHojaRuta>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir expediciones pendientes conjuntas'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-pendientes-hojaruta (verificacionConjunta)="openToastConjunto($event)" [expedicionesPendiente]="hrSelected.Expediciones"></app-subir-pendientes-hojaruta>
    </div>
</ng-template>

<!-- The Modal Cambiar Estado -->
<div class="modal fade" id="cambiarEstado">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <app-model-estado [errorDni]="false" (verificacion)="openToast($event)" [submited]="false" [error]="false" [showWebcam]="true" [fotoRealizada]="false" [estado]="estadoString"
                [idExpedicion]="expedicion" [errorFirma]="false"></app-model-estado>
        </div>
    </div>
</div>


<ng-template #template>
    <div class="modal-header fondo" style="border-color: black;">
      <h4 class="modal-title pull-left">{{'Incidencias de'|translate}} {{expedicion}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-incidencias [historico]="false" [expedicion]="expedicion" [idExpedicion]="idExpedicion"></app-incidencias>
    </div>
  </ng-template>

  <ng-template #templateCambiarPasswordPrimeraVez id="CambiarPasswordPrimeraVez">
    <div class="modal-content fondo">
        <div class="modal-header fondo">
            <h2 class="modal-title pull-left">{{'PrimeraVezLabel' | translate}}</h2>
        </div>
        <div class="modal-body fondo">
            <app-cambiar-password-primera-vez (cerrarModal)="cerrarModal($event)"></app-cambiar-password-primera-vez>
        </div>
    </div>
</ng-template>

<ng-template #templateCambiarPasswordEsCaducada id="CambiarPasswordEsCaducada">
    <div class="modal-content fondo">
        <div class="modal-header fondo">
            <h2 class="modal-title pull-left">{{'EsCaducadaLabel' | translate}}</h2>
        </div>
        <div class="modal-body fondo">
            <app-cambiar-password-primera-vez (cerrarModal)="cerrarModal($event)"></app-cambiar-password-primera-vez>
        </div>
    </div>
</ng-template>