<form [formGroup]="formGeneral">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{'IncotermAereo'|translate}}</mat-label>
                    <mat-select formControlName="incotermAereo">
                      <mat-option *ngFor="let incoterm of listaIncotermsAereo" [value]="incoterm.IdIncoterm">{{incoterm.IdIncoterm}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{'IncotermMaritimo'|translate}}</mat-label>
                    <mat-select formControlName="incotermMaritimo">
                      <mat-option *ngFor="let incoterm of listaIncotermsMaritimo" [value]="incoterm.IdIncoterm">{{incoterm.IdIncoterm}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>{{'IncotermTerrestre'|translate}}</mat-label>
                    <mat-select formControlName="incotermTerrestre">
                      <mat-option *ngFor="let incoterm of listaIncotermsTerrestre" [value]="incoterm.IdIncoterm">{{incoterm.IdIncoterm}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <mat-label>{{'MercanciaPeligrosa'|translate}}</mat-label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <mat-radio-group name="mercanciaPeligrosa" formControlName="mercanciaPeligrosa">
                            <mat-label>{{'Si'|translate}}</mat-label>
                            <mat-radio-button [value]="true"></mat-radio-button>
                            <mat-label>{{'No'|translate}}</mat-label>
                            <mat-radio-button [value]="false"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <mat-label>{{'Remontable'|translate}}</mat-label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <mat-radio-group name="remotanble" formControlName="remontable">
                            <mat-label>{{'Si'|translate}}</mat-label>
                            <mat-radio-button [value]="true"></mat-radio-button>
                            <mat-label>{{'No'|translate}}</mat-label>
                            <mat-radio-button [value]="false"></mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <!--<div class="col-sm-4">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Ancho'|translate}}(cm)</mat-label>
                    <input class="inputMaterial" formControlName="ancho" matInput placeholder="Ex. Box" type="text">
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Alto'|translate}}(cm)</mat-label>
                    <input class="inputMaterial" formControlName="alto" matInput placeholder="Ex. Box" type="text">
                </mat-form-field>
            </div>-->
        </div>
        <div class="row">
            <div class="col-12 col-sm-12">
                <button class="btn margenBottom" type="submit" id="ModificarConfGeneral" (click)="postConfGeneral('ModificarConfGeneral')" mat-raised-button>{{'Guardar'|translate}}</button>
            </div>
            <!--<div class="col-sm-6 margin-bot"></div>
            <div class="col-sm-6 margin-bot">
                <div class="row">
                    <div class="col-sm-6" style="text-align: start;">
                        <button type="button" (click)="limpiarForm()" mat-raised-button>Limpiar</button>
                    </div>
                    <div class="col-sm-6" style="text-align: end;">
                        <button *ngIf="embalaje != null" type="submit" (click)="addOrUpdateEmbalaje()" mat-raised-button>{{'Modificar'|translate}}</button>
                        <button *ngIf="embalaje == null" type="submit" (click)="addOrUpdateEmbalaje()" mat-raised-button>{{'Añadir'|translate}}</button>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</form>
