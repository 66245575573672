import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Mercancia } from 'src/app/models/mercancia.model';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Archivo } from 'src/app/models/archivo.model';
import { ToastrService } from 'ngx-toastr';
import { Incoterms } from 'src/app/models/incoterms.model';
import { Localidades } from 'src/app/models/localidades.model';
import { Router } from '@angular/router';
import { TipoEnvio } from 'src/app/models/tipo-envio.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from 'src/app/models/entity.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { DatosContacto } from 'src/app/models/datos-contacto.model';
import { EntityService } from 'src/app/services/entity.service';
import { ConfigEntidad } from 'src/app/models/config-entidad.model';
import { MatDialog } from '@angular/material/dialog';
import { FileShipService } from 'src/app/services/file-ship.service';
import { Corresponsal } from '../../../../../models/corresponsal.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CotizacionEnvio } from 'src/app/models/cotizacionEnvio.model';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { LogModel } from 'src/app/models/log-model.model';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { CargoComponent } from '../../cargo/cargo.component';
import { Modoenvio } from 'src/app/models/modoenvio.model';
import jsPDF from 'jspdf';
import { Paises } from 'src/app/models/paises.model';
import html2canvas from 'html2canvas';
import { MapaService } from 'src/app/services/mapa.service';
import { localidadGoogleMaps } from 'src/app/models/localidadGoogleMaps.model';

@Component({
  selector: 'app-cotizacion-terrestre',
  templateUrl: './cotizacion-terrestre.component.html',
  styleUrls: ['./cotizacion-terrestre.component.scss']
})
export class CotizacionTerrestreComponent implements OnInit {

  @ViewChild('TipoEnvio') tipoEnvio: ElementRef;
  @ViewChild('Referencia') referencia: ElementRef;
  @ViewChild('Incoterm') incoterm: ElementRef;
  @ViewChild('FechaRecogida') fechaRecogida: ElementRef;
  @ViewChild('resumenCotizacion') buttonResumenCotizacion: ElementRef;
  @ViewChild('SearchFieldOrigen') searchFieldOrigen: ElementRef;
  @ViewChild('SearchFieldDestino') searchFieldDestino: ElementRef;
  camposGoogleMapsLocalidad: string[] = ["administrative_area_level_1", "administrative_area_level_2", "administrative_area_level_3", "administrative_area_level_4", "administrative_area_level_5",
    "administrative_area_level_6", "administrative_area_level_7", "sublocality_level_1", "sublocality_level_2", "sublocality_level_3", "sublocality_level_4", "sublocality_level_5", "plus_code", "locality", "postal_code",
    "country"];
  mapa: google.maps.Map;
  @ViewChild(CargoComponent) appCargo: CargoComponent;

  clase: string = "app-terrestre-cotizacion";
  texto: string = "";
  visor: boolean;
  editar: boolean;
  duplicar: boolean;
  form: NgForm;
  error: boolean = false;
  maxlengthObservaciones: number = 500;
  esFCL: boolean = false;
  formModelCotizacion: FormGroup;
  archivoBooking: Archivo = new Archivo();
  obtenerPosicionInicialOrigen: boolean = true;
  obtenerPosicionInicialDestino: boolean = true;
  tamanyoMapa = { height: 575, width: 1000 };
  today: Date = new Date();
  existMarkerOrigen: boolean = false;
  existMarkerDestino: boolean = false;
  errorPesoBruto: boolean = false;
  errorPesoNeto: boolean = false;
  errorFechaReadyForShipment: boolean = false;
  autocompleteOrigen;
  autocompleteDestino;

  bultos: number = 0;
  pesoNeto: number = 0;
  pesoBruto: number = 0;
  volumen: number = 0;

  localidadResultado: string[] = [];
  localidad: localidadGoogleMaps = new localidadGoogleMaps();
  inicializarComponenteOrigen: boolean = true;
  inicializarComponenteDestino: boolean = true;

  listTipoEnvio: TipoEnvio[] = [];

  listIncoterm: Incoterms[];
  listaMercancia: Mercancia[] = []; //pasado por parametro a cargo
  listaArchivos: Archivo[] = []; //pasarlo en post
  cargo: Mercancia = new Mercancia(); //pasarlo en post
  auxVol: number;
  modalRef: BsModalRef | null;
  searchText: string;

  listModoEnvio: Modoenvio[];

  cargaModoEnvio: boolean = true;
  cargaTipoEnvio: boolean = true;
  idArea: number = 1;
  cargaIncoterm: boolean = true;

  cargaAeropuerto: boolean = true;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  entidad: Entity;
  entidadCliente: number;
  datosContacto: DatosContacto;
  configEntidad: ConfigEntidad;
  refCliDuplicado: Boolean = false;
  corresponsal: Corresponsal;

  /** NUEVO PARA LA COTIZACIÓN */
  clienteEntidad: Entity;
  cargaLocalidad: boolean = true;
  Cotizacion: CotizacionEnvio = new CotizacionEnvio();
  submit: boolean = false;
  idCotizacion: number;
  localidadOrigenSelected: boolean = false;
  localidadDestinoSelected: boolean = false;
  listaPaises: Paises[] = [];
  cargaPaises: boolean = true;
  paisOrigen: Paises;
  paisDestino: Paises;

  localidadConfigGoogle: localidadGoogleMaps;

  latitudeOrigen: number;
  longitudeOrigen: number;
  latitudeMapaOrigen: number;
  longitudeMapaOrigen: number;
  zoomOrigen: number;

  latitudeDestino: number;
  longitudeDestino: number;
  latitudeMapaDestino: number;
  longitudeMapaDestino: number;
  zoomDestino: number;

  constructor(private envioService: ShipmentService, private toastr: ToastrService, private changeDetectorRef: ChangeDetectorRef, private router: Router, private entityService: EntityService, private mapaService: MapaService,
    private modalService: BsModalService, private translateService: TranslateService, private usuarioService: UsuarioService, private ngZone: NgZone,
    private cotizacionService: CotizacionService, private entidadService: EntityService, public dialog: MatDialog, private fileService: FileShipService, private configurationService: ConfigurationService) {
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarCotizacionTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
  }

  //Funcion que se ejecuta al iniciar el componente cada vez que se abre
  ngOnInit(): void {
    this.inicializar();
    //this.funcionMapa();
  }

  ngAfterViewInit() {
    this.inicializar();
  }

  public get IdCotizacion() { return this.formModelCotizacion.get('IdCotizacion'); }
  public get IdTipoEnvio() { return this.formModelCotizacion.get('IdTipoEnvio'); }
  public get IdModoEnvio() { return this.formModelCotizacion.get('IdModoEnvio'); }
  public get Cliente() { return this.formModelCotizacion.get('Cliente'); }
  public get ReferenciaCliente() { return this.formModelCotizacion.get('ReferenciaCliente'); }
  public get IdLocalidadOrigen() { return this.formModelCotizacion.get('IdLocalidadOrigen'); }
  public get IdLocalidadDestino() { return this.formModelCotizacion.get('IdLocalidadDestino'); }
  public get Incoterm() { return this.formModelCotizacion.get('Incoterm'); }
  public get FechaReadyForShipment() { return this.formModelCotizacion.get('FechaReadyForShipment'); }
  public get MercanciaPeligrosa() { return this.formModelCotizacion.get('MercanciaPeligrosa'); }
  public get NoRemontable() { return this.formModelCotizacion.get('NoRemontable'); }
  public get IdArea() { return this.formModelCotizacion.get('IdArea'); }
  public get PesoNetoTotalDGR() { return this.formModelCotizacion.get('PesoNetoTotalDGR'); }
  public get PesoBrutoTotalDGR() { return this.formModelCotizacion.get('PesoBrutoTotalDGR'); }
  public get BultosTotales() { return this.formModelCotizacion.get('BultosTotales'); }
  public get PesoNetoTotal() { return this.formModelCotizacion.get('PesoNetoTotal'); }
  public get PesoBrutoTotal() { return this.formModelCotizacion.get('PesoBrutoTotal'); }
  public get PesoTasable() { return this.formModelCotizacion.get('PesoTasable'); }
  public get VolumenTotal() { return this.formModelCotizacion.get('VolumenTotal'); }
  public get Observaciones() { return this.formModelCotizacion.get('Observaciones'); }
  public get Mercancia() { return this.formModelCotizacion.get('Mercancia'); }
  public get Contacto() { return this.formModelCotizacion.get('Contacto'); }
  public get IdCliente() { return this.formModelCotizacion.get('IdCliente'); }
  public get ValorMercancia() { return this.formModelCotizacion.get('ValorMercancia'); }
  public get Asegurado() { return this.formModelCotizacion.get('Asegurado'); }
  public get IdPaisOrigen() { return this.formModelCotizacion.get('IdPaisOrigen'); }
  public get IdPaisDestino() { return this.formModelCotizacion.get('IdPaisDestino'); }
  public get PostalCodeOrigen() { return this.formModelCotizacion.get('PostalCodeOrigen'); }
  public get PostalCodeDestino() { return this.formModelCotizacion.get('PostalCodeDestino'); }
  public get LocalidadOrigen() { return this.formModelCotizacion.get('LocalidadOrigen'); }
  public get LocalidadDestino() { return this.formModelCotizacion.get('LocalidadDestino'); }

  inicializar() {
    this.formModelCotizacion = new FormGroup({
      IdCotizacion: new FormControl(0),
      Area: new FormControl('TERRESTRE'),
      IdPaisOrigen: new FormControl(null, Validators.required),
      IdPaisDestino: new FormControl(null, Validators.required),
      IdArea: new FormControl(1),
      IdTipoEnvio: new FormControl(7, Validators.required),
      IdModoEnvio: new FormControl(3, Validators.required),
      Cliente: new FormControl('', Validators.required),
      ReferenciaCliente: new FormControl(''),
      Incoterm: new FormControl('', Validators.required),
      IdCliente: new FormControl(0, Validators.required),
      PostalCodeOrigen: new FormControl(null),
      PostalCodeDestino: new FormControl(null),
      //IdRemitente: new FormControl(null),
      FechaReadyForShipment: new FormControl(null, Validators.required),
      IdLocalidadOrigen: new FormControl(null),
      IdLocalidadDestino: new FormControl(null),
      LocalidadOrigen: new FormControl(null, Validators.required),
      LocalidadDestino: new FormControl(null, Validators.required),
      MercanciaPeligrosa: new FormControl(false, Validators.required),
      NoRemontable: new FormControl(false, Validators.required),
      Mercancia: new FormControl([]), // Se comprueba a mano
      PesoNetoTotalDGR: new FormControl(0), // Se comprueba a mano
      PesoBrutoTotalDGR: new FormControl(0), // Se comprueba a mano
      BultosTotales: new FormControl(0, Validators.required),
      PesoNetoTotal: new FormControl(0/*, Validators.required*/),
      PesoBrutoTotal: new FormControl(0, Validators.required),
      PesoTasable: new FormControl(0, Validators.required),
      VolumenTotal: new FormControl(0, Validators.required),
      Observaciones: new FormControl(''),
      Contacto: new FormControl(''),
      ValorMercancia: new FormControl(null),
      Asegurado: new FormControl(false)
    });

    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.texto = this.translateService.instant("CancelarCotizacionTexto");
    this.listaMercancia.length = 0;

    this.cargarPaises();
    this.cargarTipoEnvio();
    this.cargarModoEnvio();
    this.getEntidad();
    this.cargarIdioma();
    this.cargarIncoterms();
    this.cargarConfigEntidad();
    this.cargarDatosContactoUsuario();
    this.getCorresponsal();
    if (this.appCargo != undefined && this.appCargo != null)
      this.appCargo.ngOnInit();
    this.cargarCotizacion();
  }

  incotermError() {
    return this.Incoterm.invalid && (this.Incoterm.touched || this.submit);
  }

  fechaReadyForShipmentError() {
    return this.FechaReadyForShipment.invalid && (this.FechaReadyForShipment.touched || this.submit);
  }
  fechaReadyForShipmentMenorActual() {
    return this.errorFechaReadyForShipment || (this.FechaReadyForShipment.touched && this.validarFechaRecogida() && !this.fechaReadyForShipmentError())
  }

  PesoNetoTotalError() {
    return this.PesoNetoTotal.invalid && (this.PesoNetoTotal.touched || this.submit);
  }

  BultosTotalesError() {
    return this.BultosTotales.invalid && (this.BultosTotales.touched || this.submit);
  }

  PesoBrutoTotalError() {
    return this.PesoBrutoTotal.invalid && (this.PesoBrutoTotal.touched || this.submit);
  }

  VolumenTotalError() {
    return this.VolumenTotal.invalid && (this.VolumenTotal.touched || this.submit);
  }

  PesoNetoTotalDGRError() {
    return this.MercanciaPeligrosa.value && this.PesoNetoTotalDGR.invalid && (this.PesoNetoTotalDGR.touched || this.submit);
  }

  PesoBrutoTotalDGRError() {
    return this.MercanciaPeligrosa.value && this.PesoBrutoTotalDGR.invalid && (this.PesoBrutoTotalDGR.touched || this.submit);
  }

  localidadDestinoError() {
    return (this.IdPaisDestino.value == undefined || this.IdPaisDestino.value == null || this.IdPaisDestino.value == "") && this.LocalidadDestino.invalid && (this.IdPaisDestino.touched || this.submit)
  }

  localidadOrigenError() {
    return (this.IdPaisOrigen.value == undefined || this.IdPaisOrigen.value == null || this.IdPaisOrigen.value == "") && this.LocalidadOrigen.invalid && (this.IdPaisOrigen.touched || this.submit)
  }

  ValorMercanciaError() {
    return this.ValorMercancia.invalid && (this.ValorMercancia.touched || this.submit) && this.Asegurado.value;
  }

  validarFechaRecogida(): Boolean {
    var fechaForm: Date = new Date(this.FechaReadyForShipment.value)
    var fechaHoy: Date = new Date();
    if ((fechaForm.getTime() + 86400000) < fechaHoy.getTime()) {
      return true;
    }
    else {
      return false;
    }
  }

  mercanciaError() {
    return (this.listaMercancia.length == 0) && this.submit;
  }

  cargarPaises() {
    this.envioService.getPaises().subscribe(paises => {
      this.cargaPaises = false;
      this.listaPaises = paises;
    });
  }

  getLocalidadOrigenEmit(localidad: localidadGoogleMaps) {
    this.changeDetectorRef.detectChanges();
    var paises = this.listaPaises.filter(p => p.IsoAlfa2 == localidad.IsoAlfa2);

    if (paises[0].IdPais == this.paisOrigen.IdPais) {
      this.autocompleteOrigenInicializar();
      this.PostalCodeOrigen.setValue(localidad.CodigoPostal);
      if (this.localidadOrigenSelected) {
        localidad.IdPais = paises[0].IdPais;
        this.LocalidadOrigen.setValue(localidad);
      }
      if (localidad.Ciudad != undefined && localidad.Ciudad != null) {
        this.searchFieldOrigen.nativeElement.value = localidad.Ciudad;
        if (localidad.Administrative_area_level_2 != undefined && localidad.Administrative_area_level_2 != null && localidad.Administrative_area_level_2 != "")
          this.searchFieldOrigen.nativeElement.value += ", " + localidad.Administrative_area_level_2;
        if (localidad.Administrative_area_level_1 != undefined && localidad.Administrative_area_level_1 != null && localidad.Administrative_area_level_1 != "")
          this.searchFieldOrigen.nativeElement.value += ", " + localidad.Administrative_area_level_1;
      }
      this.existMarkerOrigen = true;
      this.latitudeOrigen = localidad.Lat;
      this.longitudeOrigen = localidad.Lng;
      this.latitudeMapaOrigen = this.latitudeOrigen;
      this.longitudeMapaOrigen = this.longitudeOrigen;
      if (this.inicializarComponenteOrigen)
        this.mapaService.setTextoLocalidadOrigen$(this.montarTextoOrigen(localidad));
      this.inicializarComponenteOrigen = false;
      this.zoomOrigen = this.getBoundsZoomLevel(new google.maps.LatLngBounds(new google.maps.LatLng(this.latitudeOrigen - 0.005, this.longitudeOrigen - 0.005), new google.maps.LatLng(this.latitudeOrigen + 0.005, this.longitudeOrigen + 0.005)), this.tamanyoMapa);
    } else {
      this.LocalidadOrigen.setValue(null);
      this.PostalCodeOrigen.setValue(null);
    }
    if (paises.length > 0) {
      this.IdPaisOrigen.setValue(paises[0].IdPais);
      this.paisOrigen = paises[0];
      this.autocompleteOrigen.setComponentRestrictions({ country: paises[0].IsoAlfa2 });

    }
    else
      this.IdPaisOrigen.setValue(null);
  }

  setResultado() {
    for (var i = 0; i < this.camposGoogleMapsLocalidad.length - 1; i++) {
      if (this.localidadResultado[i] != undefined && this.localidadResultado[i] != null) {
        if (i == 0)
          this.localidad.Administrative_area_level_1 = this.localidadResultado[i];
        else if (i == 1)
          this.localidad.Administrative_area_level_2 = this.localidadResultado[i];
        else if (i == 2)
          this.localidad.Administrative_area_level_3 = this.localidadResultado[i];
        else if (i == 3)
          this.localidad.Administrative_area_level_4 = this.localidadResultado[i];
        else if (i == 4)
          this.localidad.Administrative_area_level_5 = this.localidadResultado[i];
        else if (i == 5)
          this.localidad.Administrative_area_level_6 = this.localidadResultado[i];
        else if (i == 6)
          this.localidad.Administrative_area_level_7 = this.localidadResultado[i];
        else if (i == 7)
          this.localidad.Sublocality_level_1 = this.localidadResultado[i];
        else if (i == 8)
          this.localidad.Sublocality_level_2 = this.localidadResultado[i];
        else if (i == 9)
          this.localidad.Sublocality_level_3 = this.localidadResultado[i];
        else if (i == 10)
          this.localidad.Sublocality_level_4 = this.localidadResultado[i];
        else if (i == 11)
          this.localidad.Sublocality_level_5 = this.localidadResultado[i];
        else if (i == 12)
          this.localidad.Plus_code = this.localidadResultado[i];
        else if (i == 13)
          this.localidad.Ciudad = this.localidadResultado[i];
        else if (i == 14)
          this.localidad.CodigoPostal = this.localidadResultado[i];
        else if (i == 15)
          this.localidad.Pais = this.localidadResultado[i];
      }
      if ((this.localidad.Ciudad == undefined || this.localidad.Ciudad == null) && this.localidad.Administrative_area_level_1 != undefined && this.localidad.Administrative_area_level_1 != null && this.localidad.Administrative_area_level_1 != "")
        this.localidad.Ciudad = this.localidad.Administrative_area_level_1;
    }
  }

  getLocalidadDestinoEmit(localidad: localidadGoogleMaps) {
    this.changeDetectorRef.detectChanges();
    // if(this.inicializarComponenteDestino){
    //   var me = this;
    //   var geoCoder = new google.maps.Geocoder;
    //   geoCoder.geocode({'address': localidad.CodigoPostal + ", " + localidad.Ciudad + ", " + localidad.Administrative_area_level_1 + ", " + localidad.Administrative_area_level_2}, (results, status) => {        
    //     me.localidad.Lat = results[0].geometry.location.lat();
    //     me.localidad.Lng = results[0].geometry.location.lng(); 
    //     results[0].address_components.forEach(ac => {
    //       var index = me.camposGoogleMapsLocalidad.findIndex(cgml => ac.types.includes(cgml));
    //       if (index != -1)
    //         me.localidadResultado[index] = ac.long_name;
    //       if(ac.types.includes("country"))
    //       {
    //         me.localidad.IsoAlfa2 = ac.short_name;
    //         me.localidad.Pais = ac.long_name;
    //       }
    //     });
    //     me.setResultado();
    //     me.LocalidadDestino.setValue(me.localidad);
    //     if(me.localidad.Ciudad != undefined && me.localidad.Ciudad != null)
    //     {
    //       this.searchFieldDestino.nativeElement.value = me.localidad.Ciudad;
    //       if(me.localidad.Administrative_area_level_2 != undefined && me.localidad.Administrative_area_level_2 != null && localidad.Administrative_area_level_2 != "")
    //         me.searchFieldDestino.nativeElement.value += ", " + me.localidad.Administrative_area_level_2;
    //       if(me.localidad.Administrative_area_level_1 != undefined && me.localidad.Administrative_area_level_1 != null && localidad.Administrative_area_level_1 != "")
    //         me.searchFieldDestino.nativeElement.value += ", " + me.localidad.Administrative_area_level_1;
    //     }
    //     me.mapaService.setTextoLocalidadDestino$(me.PostalCodeDestino.value + " " + this.paisDestino.Nombre + " " + me.localidad.Ciudad);
    //     me.inicializarComponenteDestino = false;
    //   });
    // }else{//this.changeDetectorRef.detectChanges();
    var paises = this.listaPaises.filter(p => p.IsoAlfa2 == localidad.IsoAlfa2);
    if (paises[0].IdPais == this.paisDestino.IdPais) {
      this.latitudeDestino = this.latitudeMapaDestino = localidad.Lat;
      this.longitudeDestino = this.longitudeMapaDestino = localidad.Lng;

      this.autocompleteDestinoInicializar();
      this.PostalCodeDestino.setValue(localidad.CodigoPostal);
      if (this.localidadDestinoSelected) {
        localidad.IdPais = paises[0].IdPais;
        this.LocalidadDestino.setValue(localidad);
      }
      if (localidad.Ciudad != undefined && localidad.Ciudad != null) {
        this.searchFieldDestino.nativeElement.value = localidad.Ciudad;
        if (localidad.Administrative_area_level_2 != undefined && localidad.Administrative_area_level_2 != null && localidad.Administrative_area_level_2 != "")
          this.searchFieldDestino.nativeElement.value += ", " + localidad.Administrative_area_level_2;
        if (localidad.Administrative_area_level_1 != undefined && localidad.Administrative_area_level_1 != null && localidad.Administrative_area_level_1 != "")
          this.searchFieldDestino.nativeElement.value += ", " + localidad.Administrative_area_level_1;

        if (this.inicializarComponenteDestino)
          this.mapaService.setTextoLocalidadDestino$(this.montarTextoDestino(localidad));
        this.inicializarComponenteDestino = false;
        this.zoomDestino = this.getBoundsZoomLevel(new google.maps.LatLngBounds(new google.maps.LatLng(this.latitudeDestino - 0.005, this.longitudeDestino - 0.005), new google.maps.LatLng(this.latitudeDestino + 0.005, this.longitudeDestino + 0.005)), this.tamanyoMapa);
      }
    }

    if (paises.length > 0) {
      this.IdPaisDestino.setValue(paises[0].IdPais);
      this.autocompleteDestino.setComponentRestrictions({ country: paises[0].IsoAlfa2 });
      this.paisDestino = paises[0];
    }
    else
      this.IdPaisDestino.setValue(null);
    //}

  }

  autocompleteOrigenInicializar() {
    if (this.autocompleteOrigen != undefined && this.autocompleteOrigen != undefined)
      this.autocompleteOrigen.setComponentRestrictions({ country: this.paisOrigen.IsoAlfa2 });
    else {
      var options = {
        strictBounds: false,
        componentRestrictions: { country: this.paisOrigen.IsoAlfa2 }
      };
      var me = this;
      this.autocompleteOrigen = new google.maps.places.Autocomplete(this.searchFieldOrigen.nativeElement, options);
      this.autocompleteOrigen.addListener("place_changed", () => {
        let place: google.maps.places.PlaceResult =
          this.autocompleteOrigen.getPlace();
        //verify result
        if (place === undefined || place === null || place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        me.existMarkerOrigen = true;
        me.latitudeOrigen = place.geometry.location.lat();
        me.longitudeOrigen = place.geometry.location.lng();
        me.latitudeMapaOrigen = me.latitudeOrigen;
        me.longitudeMapaOrigen = me.longitudeOrigen;
        me.mapaService.setTextoLocalidadOrigen$(place.name + " " + place.formatted_address + " " + this.paisOrigen.Nombre);
        me.zoomOrigen = me.getBoundsZoomLevel(place.geometry.viewport, me.tamanyoMapa);
      });
    }
  }

  autocompleteDestinoInicializar() {
    if (this.autocompleteDestino != undefined && this.autocompleteDestino != undefined) {
      this.autocompleteDestino.setComponentRestrictions({ country: this.paisDestino.IsoAlfa2 });
    }
    else {
      var options = {
        strictBounds: false,
        componentRestrictions: { country: this.paisDestino.IsoAlfa2 }
      };
      var me = this;
      this.autocompleteDestino = new google.maps.places.Autocomplete(this.searchFieldDestino.nativeElement, options);
      this.autocompleteDestino.addListener("place_changed", () => {
        let place: google.maps.places.PlaceResult = this.autocompleteDestino.getPlace();
        //verify result
        if (place === undefined || place === null || place.geometry === undefined || place.geometry === null) {
          return;
        }

        //set latitude, longitude and zoom
        me.existMarkerDestino = true;
        me.latitudeDestino = place.geometry.location.lat();
        me.longitudeDestino = place.geometry.location.lng();
        me.latitudeMapaDestino = me.latitudeDestino;
        me.longitudeMapaDestino = me.longitudeDestino;
        me.mapaService.setTextoLocalidadDestino$(place.name + " " + place.formatted_address);
        me.zoomDestino = me.getBoundsZoomLevel(place.geometry.viewport, me.tamanyoMapa);
      });
    }
  }

  changePaisOrigen() {
    this.abrirMapaOrigen();
    this.paisOrigen = this.listaPaises.find(p => p.IdPais == this.IdPaisOrigen.value);
    this.autocompleteOrigenInicializar();
  }

  changePaisDestino() {
    this.abrirMapaDestino();
    this.paisDestino = this.listaPaises.find(p => p.IdPais == this.IdPaisDestino.value);
    this.autocompleteDestinoInicializar();
  }

  changeIncoterm() {
    // if(this.incotermStartWithD() && this.Incoterm.value == "DDP")
    //   this.ValorMercancia.setValidators(Validators.required);
    // else if(this.incotermStartWithD()){
    //   this.LocalidadDestino.setValidators(Validators.required);
    //   this.IdPaisDestino.setValidators(Validators.required);
    // }else
    // {
    //   this.ValorMercancia.clearValidators();
    //   this.LocalidadDestino.clearValidators();
    //   this.IdPaisDestino.clearValidators();
    // }
  }

  getPostalCode(event) {
    this.PostalCodeOrigen.setValue(event);
  }

  getBoundsZoomLevel(bounds, mapDim) {
    var WORLD_DIM = { height: 256, width: 256 };
    var ZOOM_MAX = 21;

    function latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom + 1, lngZoom + 1, ZOOM_MAX);
  }

  abrirMapaOrigen() {
    if (this.IdPaisOrigen.valid) {
      this.localidadOrigenSelected = true;
      this.localidadDestinoSelected = false;
      this.existMarkerOrigen = false;
      this.paisOrigen = this.listaPaises.find(p => p.IdPais == this.IdPaisOrigen.value);
      var geocoder = new google.maps.Geocoder;
      var me = this;
      geocoder.geocode({ 'address': this.paisOrigen.Nombre }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          me.existMarkerOrigen = false;
          me.latitudeMapaOrigen = results[0].geometry.location.lat();
          me.longitudeMapaOrigen = results[0].geometry.location.lng();
          me.LocalidadOrigen.setValue(null);
          me.searchFieldOrigen.nativeElement.value = "";
          me.PostalCodeOrigen.setValue(null);
          me.zoomOrigen = me.getBoundsZoomLevel(results[0].geometry.viewport, me.tamanyoMapa);
        }
      });
    } else {
      this.toastr.error("Tienes que seleccionar el pais origen", "Error pais origen");
    }
  }



  esImport() {
    return this.IdTipoEnvio.value == 8;
  }

  mostrarEntrega() {
    return this.incotermStartWithD() || this.esImport();
  }

  cambioTipoEnvio() {
    if (this.esImport()) {
      this.paisOrigen = new Paises();
      this.LocalidadOrigen.setValue(null);
      this.IdPaisOrigen.setValue(null);
      this.PostalCodeOrigen.setValue(null);
      this.paisDestino = new Paises();
      this.paisDestino.IsoAlfa2 = this.localidadConfigGoogle.IsoAlfa2;
      this.paisDestino.IdPais = this.localidadConfigGoogle.IdPais;
      this.obtenerPosicionInicialDestino = false;
      this.localidadOrigenSelected = false;
      this.inicializarComponenteDestino = true;
      this.localidadDestinoSelected = this.existMarkerDestino = true;
      this.searchFieldOrigen.nativeElement.value = '';
      this.getLocalidadDestinoEmit(this.localidadConfigGoogle);
    } else {
      this.paisDestino = new Paises();
      this.LocalidadDestino.setValue(null);
      this.IdPaisDestino.setValue(null);
      this.PostalCodeDestino.setValue(null);
      this.paisOrigen = new Paises();
      this.paisOrigen.IsoAlfa2 = this.localidadConfigGoogle.IsoAlfa2;
      this.paisOrigen.IdPais = this.localidadConfigGoogle.IdPais;
      this.obtenerPosicionInicialOrigen = false;
      this.localidadDestinoSelected = false;
      this.inicializarComponenteOrigen = true;
      this.localidadOrigenSelected = this.existMarkerOrigen = true;
      this.searchFieldDestino.nativeElement.value = '';
      this.getLocalidadOrigenEmit(this.localidadConfigGoogle);
    }
  }

  cambioLocalidadDestino(cambio) {
    var texto = cambio.target.value;
    if (texto == undefined || texto == null || texto == "") {
      this.LocalidadDestino.setValue(null);
      this.PostalCodeDestino.setValue(null);
    }
  }

  cambioLocalidadOrigen(cambio) {
    var texto = cambio.target.value;
    if (texto == undefined || texto == null || texto == "") {
      this.LocalidadOrigen.setValue(null);
      this.PostalCodeOrigen.setValue(null);
    }
  }

  abrirMapaDestino() {
    if (this.IdPaisDestino.valid) {
      this.localidadOrigenSelected = false;
      this.localidadDestinoSelected = true;
      this.existMarkerDestino = false;
      this.paisDestino = this.listaPaises.find(p => p.IdPais == this.IdPaisDestino.value);
      var geocoder = new google.maps.Geocoder;
      var me = this;
      geocoder.geocode({ 'address': this.paisDestino.Nombre }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          // me.mapaService.existMarker = false;
          me.existMarkerDestino = false;
          me.latitudeMapaDestino = results[0].geometry.location.lat();
          me.longitudeMapaDestino = results[0].geometry.location.lng();
          me.LocalidadDestino.setValue(null);
          me.searchFieldDestino.nativeElement.value = "";
          me.PostalCodeDestino.setValue(null);
          me.zoomDestino = me.getBoundsZoomLevel(results[0].geometry.viewport, me.tamanyoMapa);
        }
      });
    } else {
      this.toastr.error("Tienes que seleccionar el pais origen", "Error pais origen");
    }
  }

  incotermStartWithD() {
    return this.Incoterm.value != null && this.Incoterm.value != undefined && this.Incoterm.value.startsWith('D');
  }

  getBandera(a: Paises) {
    return 'assets/banderas/' + a.IsoAlfa2.toLowerCase() + '.png';
  }

  mostrarError(bodyInstant: string, titleInstant: string) {
    var body: string = this.translateService.instant(bodyInstant);
    var title: string = this.translateService.instant(titleInstant);
    this.toastr.error(body, title);
  }

  contadorErrores() {
    var contadorErrores = 0;
    if (this.fechaReadyForShipmentError() || this.fechaReadyForShipmentMenorActual())
      contadorErrores++;
    if (this.incotermError())
      contadorErrores++;
    if (this.BultosTotalesError())
      contadorErrores++;
    if (this.PesoNetoTotalError())
      contadorErrores++;
    if (this.PesoBrutoTotalError())
      contadorErrores++;
    if (this.VolumenTotalError())
      contadorErrores++;
    if (this.PesoNetoTotalDGRError())
      contadorErrores++;
    if (this.PesoBrutoTotalDGRError())
      contadorErrores++;
    if (this.localidadDestinoError())
      contadorErrores++;
    if (this.localidadOrigenError())
      contadorErrores++;
    if (this.ValorMercanciaError())
      contadorErrores++;
    if (this.mercanciaError())
      contadorErrores++;
    return contadorErrores;
  }

  mostrarErrores() {
    var contadorErrores: number = this.contadorErrores();
    if (contadorErrores > 1) {
      var body: string = this.translateService.instant("BodyMasDeUnError") + contadorErrores + " " + this.translateService.instant("Errores");
      var title: string = this.translateService.instant("TitleMasDeUnError") + contadorErrores + " " + this.translateService.instant("Errores");
      this.toastr.error(body, title);
    }
    else {
      if (this.fechaReadyForShipmentError())
        this.mostrarError("BodyFechaReadyForShipmentError", "TitleFechaReadyForShipmentError");
      else if (this.fechaReadyForShipmentMenorActual())
        this.mostrarError("BodyfechaReadyForShipmentMenorActual", "TitlefechaReadyForShipmentMenorActual");
      else if (this.incotermError())
        this.mostrarError("BodyIncotermError", "TitleIncotermError");
      else if (this.BultosTotalesError())
        this.mostrarError("BodyBultosTotalesError", "TitleBultosTotalesError");
      else if (this.PesoNetoTotalError())
        this.mostrarError("BodyPesoNetoTotalError", "TitlePesoNetoTotalError");
      else if (this.PesoBrutoTotalError())
        this.mostrarError("BodyPesoBrutoTotalError", "TitlePesoBrutoTotalError");
      else if (this.VolumenTotalError())
        this.mostrarError("BodyVolumenTotalError", "TitleVolumenTotalError");
      else if (this.PesoNetoTotalDGRError())
        this.mostrarError("BodyPesoNetoTotalDGRError", "TitlePesoNetoTotalDGRError");
      else if (this.PesoBrutoTotalDGRError())
        this.mostrarError("BodyPesoBrutoTotalDGRError", "TitlePesoBrutoTotalDGRError");
      else if (this.localidadDestinoError())
        this.mostrarError("BodyLocalidadDestinoError", "TitleLocalidadDestinoError");
      else if (this.localidadOrigenError())
        this.mostrarError("BodyLocalidadDestinoError", "TitleLocalidadDestinoError");
      else if (this.ValorMercanciaError())
        this.mostrarError("BodyValorMercanciaError", "TitleValorMercanciaError");
      else if (this.mercanciaError())
        this.mostrarError("BodyLineaMercanciaError", "TitleLineaMercanciaError");

    }

  }

  crearCotizacion() {
    this.submit = true;
    if (this.formModelCotizacion.valid && (!this.comprobarErrores() && !this.mercanciaError())) {
      if (this.compruebaMercanciaPeligrosa()) {
        if (this.compruebaLocalidadOrigen()) {
          if (this.compruebaLocalidadDestino()) {
            this.convertFormToModel();
            this.buttonResumenCotizacion.nativeElement.click();
          } else
            this.mostrarError("FaltaLocalidadDestino", "TituloFaltaLocalidadDestino");
        } else
          this.mostrarError("FaltaLocalidadOrigen", "TituloFaltaLocalidadOrigen");
      } else
        this.mostrarError("BodyMercanciaPeligrosaError", "TitleMercanciaPeligrosaError");
    } else
      this.mostrarErrores();
  }

  postCotizacion(event) {
    if (event) {
      this.convertFormToModel();
      var logModel: LogModel = new LogModel();
      logModel.Pantalla = this.clase;
      logModel.Click = "enviarCotizaciónTerrestre";
      const dialogRef = this.dialog.open(WaitingComponent, {
        width: '250px',
        panelClass: 'my-panel',
        disableClose: true
      });
      dialogRef.afterOpened().subscribe(result => { });
      this.cotizacionService.postCotizacion(this.Cotizacion, logModel).subscribe(codigo => {
        var total = 0;
        var enviarArchivos = [];
        if (this.archivoBooking.Base64image != null && this.archivoBooking.Base64image != undefined && this.archivoBooking.Base64image != '')
          enviarArchivos.push(this.archivoBooking);
        this.listaArchivos.forEach(a => {
          if (Number(total) + Number(a.Tamanyo) <= 25000000 && enviarArchivos.length <= 3) { // 25 MB
            total += a.Tamanyo;
            enviarArchivos.push(a);
          } else {
            this.fileService.postDocumentosCotizacion(enviarArchivos, codigo, logModel).subscribe(data => { }, err => {
              // REINTENTAMOS PARA VOLVER A SUBIRLOS.
              this.fileService.postDocumentosCotizacion(enviarArchivos, codigo, logModel).subscribe(data => { }, err => {
                dialogRef.close();
                this.submit = false;
                var Body = this.translateService.instant('ErrorBodySubidaDocumentosCotizacion');
                var Titulo = this.translateService.instant('Error en los documentos');
                this.toastr.error(Body, Titulo);
              })
            })
            enviarArchivos = [];
            enviarArchivos.push(a);
            total = 0;
            total += a.Tamanyo;
          }
        })
        if (enviarArchivos.length > 0) {
          this.fileService.postDocumentosCotizacion(enviarArchivos, codigo, logModel).subscribe(data => {
            this.submit = false;
            dialogRef.close();
            var Body = this.translateService.instant('OkBodyCotizacion');
            var Titulo = this.translateService.instant('OkTitleCotizacion');
            this.toastr.success(Body, Titulo)
            this.Cotizacion = new CotizacionEnvio();
            this.listaArchivos = [];
            this.formModelCotizacion.reset();
            this.inicializar();
          }, err => {
            this.fileService.postDocumentosCotizacion(enviarArchivos, codigo, logModel).subscribe(data => {
              this.submit = false;
              dialogRef.close();
              var Body = this.translateService.instant('OkBodyCotizacion');
              var Titulo = this.translateService.instant('OkTitleCotizacion');
              this.toastr.success(Body, Titulo);
              this.Cotizacion = new CotizacionEnvio();
              this.listaArchivos = [];
              this.formModelCotizacion.reset();
              this.inicializar();
            }, err => {
              dialogRef.close();
              var Body = this.translateService.instant('ErrorBodySubidaDocumentosCotizacion');
              var Titulo = this.translateService.instant('Error en los documentos');
              this.toastr.error(Body, Titulo)
            })
          })
        } else {
          this.submit = false;
          dialogRef.close();
          var Body = this.translateService.instant('OkBodyCotizacion');
          var Titulo = this.translateService.instant('OkTitleCotizacion');
          this.toastr.success(Body, Titulo)
          this.Cotizacion = new CotizacionEnvio();
          this.listaArchivos = [];
          this.formModelCotizacion.reset();
          this.inicializar();
        }
      }, err => {
        this.submit = false;
        dialogRef.close();
      });
    }
    this.modalRef.hide();
  }

  getFechaFormatoEspanyol(fecha: string) {
    return fecha.split('-')[2] + '-' + fecha.split('-')[1] + '-' + fecha.split('-')[0];
  }

  compruebaLocalidadOrigen(): boolean {
    if (this.LocalidadOrigen.value != null && this.LocalidadOrigen.value != undefined && this.LocalidadOrigen.value.Ciudad != "" && this.LocalidadOrigen.value.Ciudad != undefined && this.LocalidadOrigen.value.Ciudad != null)
      return true;

    return false;
  }

  compruebaLocalidadDestino(): boolean {
    if (!this.incotermStartWithD() ||
      (this.incotermStartWithD() && this.LocalidadDestino.value != undefined && this.LocalidadDestino.value != null && this.LocalidadDestino.value.Ciudad != "" && this.LocalidadDestino.value.Ciudad != undefined && this.LocalidadDestino.value.Ciudad != null))
      return true;

    return false;
  }

  convertFormToModel() {
    this.Cotizacion.Bultos = this.BultosTotales.value;
    this.Cotizacion.FechaReadyForShipment = this.getFechaFormatoEspanyol(this.FechaReadyForShipment.value);
    this.Cotizacion.IdArea = this.IdArea.value;
    this.Cotizacion.IdCliente = this.IdCliente.value;
    this.Cotizacion.Cliente = this.Cliente.value;
    this.Cotizacion.IdCotizacion = this.IdCotizacion.value;
    this.Cotizacion.IdLocalidadDestino = this.IdLocalidadDestino.value;
    this.Cotizacion.IdLocalidadOrigen = this.IdLocalidadOrigen.value;
    this.Cotizacion.IdModoEnvio = this.IdModoEnvio.value;
    this.Cotizacion.LocalidadOrigen = this.LocalidadOrigen.value;
    this.Cotizacion.LocalidadDestino = this.LocalidadDestino.value;
    // this.Cotizacion.IdPuertoDestino = this.IdPuertoDestino.value;
    // this.Cotizacion.IdPuertoOrigen = this.IdPuertoOrigen.value;
    this.Cotizacion.IdTipoEnvio = this.IdTipoEnvio.value;
    this.Cotizacion.Incoterm = this.Incoterm.value;
    this.Cotizacion.Mercancia = this.Mercancia.value;
    this.Cotizacion.MercanciaPeligrosa = this.MercanciaPeligrosa.value;
    // this.Cotizacion.Naviera = this.Naviera.value;
    this.Cotizacion.NoRemontable = this.NoRemontable.value;
    this.Cotizacion.Observaciones = this.Observaciones.value;
    this.Cotizacion.PesoBrutoTotal = this.PesoBrutoTotal.value;
    this.Cotizacion.PesoBrutoTotalDGR = this.PesoBrutoTotalDGR.value;
    this.Cotizacion.PesoNetoTotal = this.PesoNetoTotal.value;
    this.Cotizacion.PesoNetoTotalDGR = this.PesoNetoTotalDGR.value;
    this.Cotizacion.PesoTasable = this.PesoTasable.value;
    this.Cotizacion.ReferenciaCliente = this.ReferenciaCliente.value;
    this.Cotizacion.VolumenTotal = this.VolumenTotal.value;
    this.Cotizacion.ValorMercancia = this.ValorMercancia.value;
    this.Cotizacion.Asegurado = this.Asegurado.value;
  }

  getCorresponsal() {
    this.entidadService.getCorresponsal().subscribe(data => {
      this.corresponsal = data;
    })
  }

  cargarConfigEntidad() {
    this.entidadService.getConfigEntidad().subscribe(data => {
      this.configEntidad = data;
      if (this.listIncoterm.find(i => i.IdIncoterm == this.configEntidad.IncotermTerrestre))
        this.Incoterm.setValue(this.configEntidad.IncotermTerrestre);
    });
  }

  cargarDatosContactoUsuario() {
    this.usuarioService.getDatosContacto().subscribe(data => {
      this.datosContacto = data;
      this.Contacto.setValue(data.NombreUsuario);
    }, error => {
      //No tiene todos los datos de contacto
    })
  }

  getEntidad() {
    this.envioService.getEntidadUsuario().subscribe(idEntidad => {
      this.entityService.getEntidadById(idEntidad).subscribe(cliente => {
        this.clienteEntidad = cliente;
        this.localidadConfigGoogle = new localidadGoogleMaps();
        this.inicializarComponenteOrigen = true;
        this.localidadConfigGoogle.Ciudad = this.clienteEntidad.NombreFiscal;
        this.localidadConfigGoogle.IdPais = this.clienteEntidad.IdPais;
        this.localidadConfigGoogle.IsoAlfa2 = this.listaPaises.find(p => p.IdPais == this.clienteEntidad.IdPais).IsoAlfa2;
        this.localidadConfigGoogle.CodigoPostal = this.clienteEntidad.CodigoPostal;
        if (!this.esImport()) { // Es import en aereo
          this.paisOrigen = new Paises();
          this.paisOrigen.IsoAlfa2 = this.listaPaises.find(p => p.IdPais == this.clienteEntidad.IdPais).IsoAlfa2;
          this.paisOrigen.IdPais = this.clienteEntidad.IdPais;
          this.paisOrigen.Nombre = this.clienteEntidad.NombrePais;
          this.PostalCodeOrigen.setValue(this.clienteEntidad.CodigoPostal);
          this.obtenerPosicionInicialOrigen = false;
          this.localidadDestinoSelected = false;
          this.localidadOrigenSelected = this.existMarkerOrigen = true;
          this.getLocalidadOrigenEmit(this.localidadConfigGoogle);
        }
        this.Cliente.setValue(cliente.NombreFiscal);
        this.IdCliente.setValue(cliente.IdEntidad);
      });
    });
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if (this.langs.includes(lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarCotizacion() {
    this.idCotizacion = this.cotizacionService.getIdCotizacion();
    if (this.idCotizacion != -1 && this.idCotizacion != undefined && this.idCotizacion != null) {
      this.cotizacionService.getDuplicidadCotizacion(this.idCotizacion, null).subscribe(data => {
        this.Cotizacion = data;
        this.convertModelToForm();
        this.listaMercancia = this.Cotizacion.Mercancia;
        this.listaMerc(this.listaMercancia);
        this.listaArchivos = [];
      });
    } else
      this.inicializarComponenteOrigen = this.inicializarComponenteDestino = false;

  }

  asignarValores(cotizacion: CotizacionEnvio) {
    if (cotizacion != null && cotizacion != undefined) {
      this.formModelCotizacion.value.tipoEnvio = cotizacion.IdTipoEnvio;
      this.formModelCotizacion.value.refCliente = cotizacion.ReferenciaCliente;
    }
  }

  cargarModoEnvio() {
    this.envioService.getModoEnvioByArea(1).subscribe(data => {
      this.cargaModoEnvio = true;
      this.listModoEnvio = data;
      this.cargaModoEnvio = false;
    });
  }

  cargarTipoEnvio() {
    this.envioService.getTiposEnvio(this.idArea).subscribe(data => {
      this.listTipoEnvio = data;
      this.cargaTipoEnvio = false;
    });
  }

  cargarIncoterms() {
    this.envioService.getIncoterms(this.idArea).subscribe(data => {
      this.cargaIncoterm = true;
      this.listIncoterm = data;
      this.cargaIncoterm = false;
    });
  }

  //Funcion que comprueba que si se marca la casilla de peligrosa, tenga mercancia peligrosa
  compruebaMercanciaPeligrosa(): Boolean {
    let count = 0;
    if (this.MercanciaPeligrosa.value == true)
      this.Mercancia.value.forEach(mercancia => {
        if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length > 0)
          count++;
      });
    else
      count = 1;

    if (count == 0)
      return false;

    return true;
  }

  inicializarDatos() {
    this.IdTipoEnvio.setValue(3);
    this.NoRemontable.setValue(false);
  }

  //Funcion que comprueba los pesos y si el bruto es mayor que el neto
  comprobarPesos(elem: Mercancia) {
    if (elem.PesoBruto === undefined || elem.PesoBruto === null || elem.PesoBruto.toString() == '')
      return true
    if (elem.PesoNeto !== undefined && elem.PesoNeto !== null && elem.PesoNeto.toString() != '')
      return elem.PesoBruto !== undefined && elem.PesoNeto !== undefined && elem.PesoBruto !== null && elem.PesoNeto !== null && Number(elem.PesoNeto) <= Number(elem.PesoBruto)
    else
      return elem.PesoBruto !== undefined && elem.PesoBruto !== null && elem.PesoBruto.toString() != ''
  }

  //Comprueba si hay una mercancía vacía o algún campo de la mercancía vacía
  comprobarVacio(merc: Mercancia) {
    return (merc.Bultos === null || merc.Bultos === undefined || merc.Volumen === null || merc.Volumen === undefined
      || merc.TipoEmbalaje === undefined || merc.TipoEmbalaje === null
      || merc.PesoBruto === null || merc.PesoBruto === undefined || merc.PesoBruto.toString() === '' || merc.Volumen.toString() === ''
      || merc.Bultos.toString() === '' || merc.TipoEmbalaje.toString() === '' || merc.NombreMercancia === null || merc.NombreMercancia === undefined || merc.NombreMercancia.toString() === '');
  }

  comprobarPesosBP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoBruto
      sum = sum + pb
    })
    if (elem.PesoBruto >= sum) {
      return true
    }
    return false
  }

  comprobarPesosNP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoNeto
      sum = sum + pb
    })
    if (elem.PesoNeto >= sum) {
      return true
    }
    return false
  }

  comprobarGrados(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return merc.GradosMax === null || merc.GradosMax === undefined || merc.GradosMin === null || merc.GradosMin === undefined;
    return false;
  }

  comprobraGradosDispares(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return this.comprobarGrados(merc) || merc.GradosMax < merc.GradosMin;
    return false;
  }

  comprobarMercanciaPeligrosa(merc: Mercancia): boolean {
    return merc.Peligrosa && merc.MercanciaPeligrosa != null && merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0;
  }

  //Funcion que comprueba si hay errores en una linea de mercancía
  comprobarErrores() {
    var camposVacios = false, pesos = false, grados = false, index = 1, indiceMP = -1, indiceP = -1, noEsVacio = true, pesosDispares = true, indicePB = -1, pesosBrutosDispares = true, indicePN = -1, indicePesos = 0, pesosNetosDispares = true;
    var indicesMP = [], indiceNoExisteMercanciaPeligrosa = [], indiceTC = [], pesosDisparesArray = [], pesosGradosArray = [], pesosGradosDisparesArray = [], mercanciaspeligrosasAux = [], mercanciastemperaturacontroladaAux = [], pesosBrutosArray = [], pesosNetosArray = [];
    this.listaMercancia.forEach(merc => {
      if (merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0 && merc.MercanciaPeligrosa != null) {
        mercanciaspeligrosasAux.push(merc.MercanciaPeligrosa);
        // var compruebaPesosBrutosMP = this.comprobarPesosBP(merc);
        // var compruebaPesosNetoMP = this.comprobarPesosNP(merc);
        // if (!compruebaPesosBrutosMP) {
        //   indicePB = index;
        //   pesosBrutosArray.push(index);
        // } else 
        //   pesosBrutosDispares = false;

        // if (!compruebaPesosNetoMP) {
        //   indicePN = index;
        //   pesosNetosArray.push(index);
        // } else 
        //   pesosNetosDispares = false;

      }
      if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
        mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
        indiceTC.push(index);
      }
      //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);
      var compruebaMP = this.comprobarVacio(merc);
      var compruebaPesos = this.comprobarPesos(merc);
      var compruebaGrados = this.comprobarGrados(merc);
      var compruebaGradosDispares = this.comprobraGradosDispares(merc);
      if (merc.Peligrosa && (merc.MercanciaPeligrosa == undefined || merc.MercanciaPeligrosa == null || merc.MercanciaPeligrosa.length == 0))
        indiceNoExisteMercanciaPeligrosa.push(index);
      if (compruebaMP) {
        indiceMP = index;
        indicesMP.push(index);
      } else
        noEsVacio = false;

      if (!compruebaPesos)
        pesosDisparesArray.push(index);
      else
        pesosDispares = false;


      if (compruebaGrados)
        pesosGradosArray.push(index);
      if (!compruebaGrados && compruebaGradosDispares)
        pesosGradosDisparesArray.push(index);

      index++;
      indicePesos++;
    });
    if (this.MercanciaPeligrosa.value && mercanciaspeligrosasAux.length == 0) {
      camposVacios = true
      var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
      var Titulo = this.translateService.instant('Error mercancia peligrosa');
      this.toastr.error(Body, Titulo);
    }
    index = 1;
    indicesMP.forEach(imp => {
      camposVacios = true
      var Body = this.translateService.instant('Necesitas rellenar todos los campos de la línea ') + imp
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Campos vacíos en mercancía');
      this.toastr.error(Body, Titulo);
    });
    indiceNoExisteMercanciaPeligrosa.forEach(inem => {
      camposVacios = true
      var Body = this.translateService.instant('Falta mercancía peligrosa en la línea ') + inem
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Falta mercancía peligrosa');
      this.toastr.error(Body, Titulo);
    })
    pesosDisparesArray.forEach(e => {
      var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e;
      this.toastr.error(Body, Titulo)
      pesos = true;
    });
    pesosGradosArray.forEach(tc => {
      var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc;
      this.toastr.error(Body, Titulo)
      grados = true;
    });
    pesosBrutosArray.forEach(e => {
      var Body = this.translateService.instant('El peso bruto de la linea ') + e + this.translateService.instant(' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
      var Titulo = this.translateService.instant('Error pesos brutos MP linea ') + e;
      this.toastr.error(Body, Titulo);
      pesos = true
    })
    pesosNetosArray.forEach(e => {
      var Body = this.translateService.instant('El peso neto de la linea ') + e + this.translateService.instant(' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
      var Titulo = this.translateService.instant('Error pesos netos MP linea ') + e;
      this.toastr.error(Body, Titulo);
      pesos = true
    })
    pesosGradosDisparesArray.forEach(tc => {
      var Body = this.translateService.instant('El grado máximo es inferior al mínimo en la línea ') + tc
        + this.translateService.instant(' de la tabla de mercancía');
      var Titulo = this.translateService.instant('Grados máximo menor que los grados mínimas en la línea ') + tc;
      this.toastr.error(Body, Titulo)
      grados = true;
    });
    if (!this.comprobarPesoBrutoTotalDGR() || !this.comprobarPesoNetoTotalDGR() || grados || pesos || camposVacios || !this.comprobarPesosTotales()) // Existe algún error
      return true

    return false
  }

  //Funcion que comprueba si el peso neto total es menor que el bruto total
  comprobarPesosTotales() {
    if (this.PesoBrutoTotal.value !== null && this.PesoBrutoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoNetoTotal.value !== ""
      && this.PesoNetoTotal.value !== null && this.PesoNetoTotal.value !== undefined
      && parseInt(this.PesoBrutoTotal.value) < parseInt(this.PesoNetoTotal.value)) {
      var Body = this.translateService.instant("Peso Bruto Total es menor que Peso Neto Total");
      var Titulo = this.translateService.instant("Peso Bruto Total menor que Peso Neto Total");
      this.toastr.error(Body, Titulo);
      return false
    }
    return true
  }

  //Funcion que comprueba si los pesos brutos totales son mayores que los pesos brutos totales DGR 
  comprobarPesoBrutoTotalDGR() {
    if (this.PesoBrutoTotal.value !== null && this.PesoBrutoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoBrutoTotalDGR.value !== ""
      && this.PesoBrutoTotalDGR.value !== null && this.PesoBrutoTotalDGR.value !== undefined
      && parseInt(this.PesoBrutoTotal.value) < parseInt(this.PesoBrutoTotalDGR.value)) {
      var Body = this.translateService.instant("El Peso Bruto Total debe ser mayor o igual que el Peso Bruto Total DGR ");
      var Titulo = this.translateService.instant("Error peso bruto total DGR");
      this.errorPesoBruto = true;
      this.toastr.error(Body, Titulo);
      return false;
    }
    this.errorPesoBruto = false;
    return true;
  }

  tieneErrorPesoBrutoTotalDGR() {
    return this.PesoBrutoTotal.value !== null && this.PesoBrutoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoBrutoTotalDGR.value !== ""
      && this.PesoBrutoTotalDGR.value !== null && this.PesoBrutoTotalDGR.value !== undefined
      && parseInt(this.PesoBrutoTotal.value) < parseInt(this.PesoBrutoTotalDGR.value);
  }

  tieneErrorPesoNetoTotalDGR() {
    return this.PesoNetoTotal.value !== null && this.PesoNetoTotal !== undefined
      && this.PesoBrutoTotal.value !== "" && this.PesoNetoTotalDGR.value !== ""
      && this.PesoNetoTotalDGR.value !== null && this.PesoNetoTotalDGR.value !== undefined
      && parseInt(this.PesoNetoTotal.value) < parseInt(this.PesoNetoTotalDGR.value);
  }

  //Funcion que comprueba si los pesos Netos totales son mayores que los pesos Netos totales DGR 
  comprobarPesoNetoTotalDGR() {
    if (this.PesoNetoTotal.value !== null && this.PesoNetoTotal !== undefined
      && this.PesoNetoTotal.value !== "" && this.PesoNetoTotalDGR.value !== ""
      && this.PesoNetoTotalDGR.value !== null && this.PesoNetoTotalDGR.value !== undefined
      && parseInt(this.PesoNetoTotal.value) < parseInt(this.PesoNetoTotalDGR.value)) {
      var Body = this.translateService.instant("El Peso Neto Total debe ser mayor o igual que el Peso Neto Total DGR.");
      var Titulo = this.translateService.instant("Error peso Neto total DGR");
      this.toastr.error(Body, Titulo);
      this.errorPesoNeto = true;
      return false
    }
    this.errorPesoNeto = false;
    return true
  }


  //Funcion que obtiene la lista de mercancias del componente Cargo y que recalcula los totales
  listaMerc(lista: Mercancia[]) {
    this.listaMercancia = lista;
    var anteriorPesoBrutoTotal = this.PesoBrutoTotal.value;
    this.BultosTotales.setValue(0);
    this.PesoBrutoTotal.setValue(0);
    this.PesoNetoTotal.setValue(0);
    this.VolumenTotal.setValue(0);
    this.PesoTasable.setValue(0);
    this.PesoNetoTotalDGR.setValue(0);
    this.PesoBrutoTotalDGR.setValue(0);
    if (this.listaMercancia != null) {
      if (this.listaMercancia.length == 0) {
        this.BultosTotales.setValue(0);
        this.PesoBrutoTotal.setValue(0);
        this.PesoNetoTotal.setValue(0);
        this.VolumenTotal.setValue(0);
        this.PesoTasable.setValue(0);
        this.PesoNetoTotalDGR.setValue(0);
        this.PesoBrutoTotalDGR.setValue(0);
      }
      else {
        this.VolumenTotal.setValue(0);
        this.PesoTasable.setValue(0);
        if (this.listaMercancia.find(m => m.Peligrosa == true) != undefined)
          this.MercanciaPeligrosa.setValue(true);
        else
          this.MercanciaPeligrosa.setValue(false);

        if (this.listaMercancia != undefined && this.listaMercancia.length > 0) {
          this.listaMercancia.forEach(element => {
            this.BultosTotales.setValue(Number((Number(this.BultosTotales.value) + Number(element.Bultos ? element.Bultos : 0)).toFixed(3)));

            this.PesoBrutoTotal.setValue(Number((Number(this.PesoBrutoTotal.value ? this.PesoBrutoTotal.value : 0) +
              Number((element.PesoBruto ? element.PesoBruto : 0))).toFixed(3)));

            this.PesoNetoTotal.setValue(Number((Number(this.PesoNetoTotal.value ? this.PesoNetoTotal.value : 0) +
              Number(element.PesoNeto ? element.PesoNeto : 0)).toFixed(3)));

            this.VolumenTotal.setValue(Number((Number(this.VolumenTotal.value) + Number(element.Volumen ? element.Volumen : 0)).toFixed(3)));

            this.MercanciaPeligrosa.setValue(this.hayMercanciaPeligrosa());

            if (element.MercanciaPeligrosa != undefined && element.MercanciaPeligrosa.length >= 0 && element.Peligrosa) {
              element.MercanciaPeligrosa.forEach(element2 => {
                if (element2.PesoNeto != undefined)
                  this.PesoNetoTotalDGR.setValue(Number(this.PesoNetoTotalDGR.value.toString().replace(',', '.')) + Number(element2.PesoNeto.toString().replace(',', '.')));
                if (element2.PesoBruto != undefined)
                  this.PesoBrutoTotalDGR.setValue(Number(this.PesoBrutoTotalDGR.value.toString().replace(',', '.')) + Number(element2.PesoBruto.toString().replace(',', '.')));
              });
            }
          });
          this.calcularPesoTasable();
          this.Mercancia.setValue(this.listaMercancia);
        }
        if (anteriorPesoBrutoTotal !== this.PesoBrutoTotal)
          this.comprobarPesosTotales();

      }
    }
  }

  hayMercanciaPeligrosa(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercancia.forEach(element => {
      if ((element.Peligrosa || element.MP) && !yaHayPeligrosa)
        yaHayPeligrosa = true;
    });
    return yaHayPeligrosa;
  }

  formatoValorMercancia() {
    if (this.ValorMercancia.value != undefined && this.ValorMercancia.value != null && this.ValorMercancia.value != "")
      this.ValorMercancia.setValue(Number(this.ValorMercancia.value.toString().replace(',', '.')));
  }

  //Funcion que recibe los archivos
  receiveFiles(files) {
    this.listaArchivos = files;
  }

  openModalSM(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalLG(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalXL(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  //Funcion que calcula el peso tasable (formula particular de aereo)
  calcularPesoTasable() {
    let valor: number = 0;
    if (this.PesoBrutoTotal.value != null && this.VolumenTotal.value != null) {
      valor = this.VolumenTotal.value * 167;

      if (this.PesoBrutoTotal.value > valor)
        this.PesoTasable.setValue(Number(this.PesoBrutoTotal.value.toString()).toFixed(3));
      else
        this.PesoTasable.setValue(Number(valor.toFixed(3)));
    }
  }

  esEspanyaItalia() {
    return ((this.corresponsal != undefined && this.corresponsal != null) && (this.corresponsal.IdDelegacion == 1 || this.corresponsal.IdDelegacion == 4));
  }

  getFecha(fecha) {
    if (fecha != undefined && fecha != null) {
      var f = fecha.split(" ")[0];
      return f.split("/")[2] + "-" + f.split("/")[1] + "-" + f.split("/")[0];
    }
    return undefined;
  }

  convertModelToForm() {
    this.formModelCotizacion = new FormGroup({
      IdCotizacion: new FormControl(0),
      Area: new FormControl('TERRESTRE'),
      IdArea: new FormControl(1),
      IdTipoEnvio: new FormControl(this.Cotizacion.IdTipoEnvio, Validators.required),
      IdModoEnvio: new FormControl(this.Cotizacion.IdModoEnvio, Validators.required),
      Cliente: new FormControl('', Validators.required),
      ReferenciaCliente: new FormControl(this.Cotizacion.ReferenciaCliente),
      Incoterm: new FormControl(this.Cotizacion.Incoterm, Validators.required),
      IdCliente: new FormControl(0, Validators.required),
      FechaReadyForShipment: new FormControl(this.getFecha(this.Cotizacion.FechaReadyForShipment), Validators.required),
      IdLocalidadOrigen: new FormControl(this.Cotizacion.IdLocalidadOrigen),
      IdLocalidadDestino: new FormControl(this.Cotizacion.IdLocalidadDestino),
      MercanciaPeligrosa: new FormControl(this.Cotizacion.MercanciaPeligrosa, Validators.required),
      NoRemontable: new FormControl(this.Cotizacion.NoRemontable, Validators.required),
      Mercancia: new FormControl(this.Cotizacion.Mercancia), // Se comprueba a mano
      PesoNetoTotalDGR: new FormControl(this.Cotizacion.PesoNetoTotalDGR), // Se comprueba a mano
      PesoBrutoTotalDGR: new FormControl(this.Cotizacion.PesoBrutoTotalDGR), // Se comprueba a mano
      BultosTotales: new FormControl(this.Cotizacion.Bultos, Validators.required),
      PesoNetoTotal: new FormControl(this.Cotizacion.PesoNetoTotal/*, Validators.required*/),
      PesoBrutoTotal: new FormControl(this.Cotizacion.PesoBrutoTotal, Validators.required),
      PesoTasable: new FormControl(this.Cotizacion.PesoTasable, Validators.required),
      VolumenTotal: new FormControl(this.Cotizacion.VolumenTotal, Validators.required),
      Observaciones: new FormControl(this.Cotizacion.Observaciones),
      Contacto: new FormControl(''),
      ValorMercancia: new FormControl(this.Cotizacion.ValorMercancia),
      Asegurado: new FormControl(this.Cotizacion.Asegurado),
      IdPaisOrigen: new FormControl(this.Cotizacion.LocalidadOrigen?.IdPais),
      IdPaisDestino: new FormControl(this.Cotizacion.LocalidadDestino?.IdPais),
      LocalidadOrigen: new FormControl(this.Cotizacion.LocalidadOrigen),
      LocalidadDestino: new FormControl(this.Cotizacion.LocalidadDestino),
      PostalCodeOrigen: new FormControl(this.Cotizacion.LocalidadOrigen?.CodigoPostal),
      PostalCodeDestino: new FormControl(this.Cotizacion.LocalidadDestino?.CodigoPostal),
    });
    if (this.paisOrigen == null || this.paisOrigen == undefined)
      this.paisOrigen = this.listaPaises.filter(p => p.IdPais == this.IdPaisOrigen.value)[0];
    if (this.paisDestino == null || this.paisDestino == undefined)
      this.paisDestino = this.listaPaises.filter(p => p.IdPais == this.IdPaisDestino.value)[0];
    this.Incoterm.setValue(this.Cotizacion.Incoterm);
    if (this.Cotizacion.LocalidadOrigen != null && this.Cotizacion.LocalidadOrigen != undefined) {
      this.getLocalidadOrigenEmit(this.Cotizacion.LocalidadOrigen);
      this.obtenerPosicionInicialOrigen = false;
      this.localidadDestinoSelected = false;
      this.localidadOrigenSelected = this.existMarkerOrigen = true;
    }
    if (this.Cotizacion.LocalidadDestino != null && this.Cotizacion.LocalidadDestino != undefined && this.incotermStartWithD()) {
      this.getLocalidadDestinoEmit(this.Cotizacion.LocalidadDestino);
      this.localidadDestinoSelected = true;
      this.localidadOrigenSelected = false;
      this.obtenerPosicionInicialDestino = false;
      this.existMarkerDestino = true;
    }
    this.localidadDestinoSelected = false;
    this.localidadOrigenSelected = true;
    this.existMarkerDestino = false;
    this.listaMerc(this.Cotizacion.Mercancia);
  }

  obtenerRespuesta(respuesta: boolean) {
    if (respuesta)
      this.router.navigateByUrl("/customer");
    this.modalRef.hide();
  }


  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('-');
  }

  downloadPDF() {
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    // Asignamos en DATA el contenido del html que tiene como id 'tracking'
    const DATA = document.getElementById('resumen');
    // Damos formato al documento: p (portrait = vertical), pt (la unidad de media), a4 (tamaño del pdf)
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
      //pagesplit: true
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('application/pdf');
      //const img = canvas.toDataURL('image/png');
      //Add image Canvas to PDF
      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      return doc;
    }).then((docResult) => {
      var nombre = this.getNombre();
      docResult.save(nombre);
      var archivo = new Archivo();
      archivo.Base64image = docResult.output('datauristring');
      archivo.FileName = nombre;
      archivo.Extension = '.pdf';
      archivo.TipoArchivo = '19';
      this.archivoBooking = archivo;
      dialogRef.close();
      var body = this.translateService.instant("BodyInfoSubidaEbooking");
      var title = this.translateService.instant("TitleInfoSubirEbooking");
      this.toastr.info(body, title);
    });
  }

  getNombre() {
    if (this.formModelCotizacion.value.Referencia != undefined && this.formModelCotizacion.value.Referencia != null && this.formModelCotizacion.value.Referencia != "")
      return 'ecotizacion_' + this.formModelCotizacion.value.Referencia + '_' + this.formatDate(new Date()) + '.pdf';
    return 'ecotizacion_' + this.formatDate(new Date()) + '.pdf';
  }

  montarTextoOrigen(localidad: localidadGoogleMaps) {
    var texto = "";
    if (localidad.NombreEmpresa != null && localidad.NombreEmpresa != undefined && localidad.NombreEmpresa != "")
      texto += localidad.NombreEmpresa + ", ";
    if (localidad.Ciudad != null && localidad.Ciudad != undefined && localidad.Ciudad != "")
      texto += localidad.Ciudad + ", ";
    if (this.PostalCodeOrigen.value != null && this.PostalCodeOrigen.value != undefined)
      texto += this.PostalCodeOrigen.value + ", ";
    if (this.paisOrigen.Nombre != null && this.paisOrigen.Nombre != undefined && this.paisOrigen.Nombre != "")
      texto += this.paisOrigen.Nombre;
    return texto;
  }

  montarTextoDestino(localidad: localidadGoogleMaps) {
    var texto = "";
    if (localidad.NombreEmpresa != null && localidad.NombreEmpresa != undefined && localidad.NombreEmpresa != "")
      texto += localidad.NombreEmpresa + ", ";
    if (localidad.Ciudad != null && localidad.Ciudad != undefined && localidad.Ciudad != "")
      texto += localidad.Ciudad + ", ";
    if (this.PostalCodeDestino.value != null && this.PostalCodeDestino.value != undefined)
      texto += this.PostalCodeDestino.value + ", ";
    if (this.paisDestino.Nombre != null && this.paisDestino.Nombre != undefined && this.paisDestino.Nombre != "")
      texto += this.paisDestino.Nombre;
    return texto;
  }

}
