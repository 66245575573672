import { Component, Input, OnInit } from '@angular/core';
import { LineasIngreso } from 'src/app/models/lineasIngreso.model';
import { Totales } from 'src/app/models/totales.model';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-costes-shipping',
  templateUrl: './costes-shipping.component.html',
  styleUrls: ['./costes-shipping.component.scss']
})
export class CostesShippingComponent implements OnInit {

  @Input() lineasIngreso: LineasIngreso[] = [];
  @Input() totales: Totales[] = [];

  constructor(private envioService: ShipmentService) { }

  ngOnInit(): void {
  }


}
