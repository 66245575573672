import { Injectable } from '@angular/core';
import { EnvioAereo } from '../models/envio-aereo.model';
import { EnvioMaritimo } from '../models/envio-maritimo.model';
import { EnvioTerrestre } from '../models/envio-terrestre.model';
import { Archivo } from '../models/archivo.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogModel } from '../models/log-model.model';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { TipoArchivo } from '../models/tipo-archivo.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class FileShipService {

  readonly apiUrlService = environment.apiUrl;
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  referenciaEnvio: String;
  listFiles : Archivo[];
  
  ip: string = ""; 
  localizacion: string = "";

  constructor(private http: HttpClient, private cookies: AuthenticationService, private logService: LogService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
   }

  getDocumentos(idEnvio: number): Observable<Archivo[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/envio/" + idEnvio;
    this.logService.postLogCompleto("GET", "cfg_archivosenvioController.cs", "Log obtener los documentos del envío " + idEnvio + ".",
     "Get_cfg_archivosenvioPorIdEnvio", "cfg_archivosenvio", apiUrl);
    return this.http.get<Archivo[]>(apiUrl, {headers: headersToken.append("Content-Type", "application/json")});
  }

  getDocumentosExpedicion(expedicion: string): Observable<Archivo[]>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/envio/expedicion/" + encodeURIComponent(expedicion);
    this.logService.postLogCompleto("GET", "cfg_archivosenvioController.cs", "Log obtener los documentos de la expedición " + expedicion + ".",
     "Get_cfg_archivosenvioPorIdExpedicion", "cfg_archivosenvio", apiUrl);
    return this.http.get<Archivo[]>(apiUrl, {headers: headersToken.append("Content-Type", "application/json")});
  }

  getDocumentosPartida(partida: string): Observable<Archivo[]>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/envio/partida/" + partida;
    this.logService.postLogCompleto("GET", "cfg_archivosenvioController.cs", "Log obtener los documentos de la partida " + partida + ".",
     "Get_cfg_archivosenvioPorIdPartida", "cfg_archivosenvio", apiUrl);
    return this.http.get<Archivo[]>(apiUrl, {headers: headersToken.append("Content-Type", "application/json")});
  }

  setDocumentosEnvio(listaArchivos: Archivo[], log: LogModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const apiUrl = this.apiUrlService + "cfg_archivosenvio";
    return this.http.post(apiUrl, {"Log": log, "Body": JSON.stringify(listaArchivos).toString()}, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  getListaTipoDocumentos():Observable<TipoArchivo[]>{
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    const apiUrl = this.apiUrlService + "cfg_tipoarchivo";
    this.logService.postLogCompleto("GET", "cfg_tipoarchivoController.cs", "Log obtener todos los tipos de docuentos.",
     "Get_All_TipoArchivo", "cfg_tipoarchivo", apiUrl);
    return this.http.get<TipoArchivo[]>(apiUrl, {headers: headersToken.append("Content-Type", "application/json")});
  }

  postDocumentosPartida(listaArchivos: Archivo[], idPartida: String, log: LogModel)
  {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/partida/" + idPartida;
    return this.http.post(apiUrl, {"Log": log, "Body": JSON.stringify(listaArchivos).toString()}, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  postDocumentosExpedicion(listaArchivos: Archivo[], idExpedicion: String, log: LogModel)
  {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/expedicion/" + idExpedicion;
    return this.http.post(apiUrl, {"Log": log, "Body": JSON.stringify(listaArchivos).toString()}, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  postDocumentosCotizacion(listaArchivos: Archivo[], idCotizacion: number, log: LogModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    });
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/cotizacion/" + idCotizacion;
    return this.http.post(apiUrl, {"Log": log, "Body": JSON.stringify(listaArchivos).toString()}, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }

  postDocumentosEnvio(listaArchivos: Archivo[], idEnvio: Number, log: LogModel)
  {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.cookies.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const apiUrl = this.apiUrlService + "cfg_archivosenvio/envio/" + idEnvio;
    return this.http.post(apiUrl, {"Log": log, "Body": JSON.stringify(listaArchivos).toString()}, {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data));
  }
}
