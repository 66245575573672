<div class="sticky-top fixed-top">
  <app-header-language></app-header-language>
  <nav class="navbar navbar-expand-lg navbar-light colorBlanco navbar-hover">
    <img class="navbar-brand" class="logoheader" href="/" routerLink=""
      src="assets/logos/MYXGL.png" /><!--style="height: 10%; width: 10%;"-->
    <span *ngIf="mostrar">
      <img [src]="getLogoHTML()" class="logo" alt="">
    </span>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <label (click)="irAInicio();">{{'Inicio' | translate }}</label>
        </li>
        <li class="dropdown" *ngIf="isLoad && tienePrivilegioTarifa()">
          <a class="dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false">
            {{'Tarifa'|translate}}
          </a>
          <ul class="dropdown-menu">
            <li class="label-dropdown-menu"><label (click)="irATarifaAereo()">{{'Aereo' | translate}}</label></li>
            <li class="label-dropdown-menu"><label (click)="irATarifaMaritimo();">{{'Marítimo' | translate}}</label>
            </li>
            <!-- <li class="label-dropdown-menu"><label>{{'Terrestre' | translate}}</label></li> -->
          </ul>
        </li>
        <li class="dropdown" *ngIf="isLoad && tienePrivilegioCotizacion()">
          <a class="dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false">
            {{'Cotizaciones'|translate}}
          </a>
          <ul class="dropdown-menu">
            <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(1,0);">{{'Aereo' | translate}}</label>
            </li>
            <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(2,0);">{{'Marítimo' |
                translate}}</label></li>
            <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(3,0);">{{'Terrestre' |
                translate}}</label></li>
            <!-- <li><a class="dropdown-item dropdown-toggle" role="button">Creación</a>
                  <ul class="dropdown-menu">
                    <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(1,0);">{{'Aereo' | translate}}</label></li>
                    <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(2,0);">{{'Marítimo' | translate}}</label></li>
                    <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(3,0);">{{'Terrestre' | translate}}</label></li>
                  </ul>
              </li>
              <li><a class="dropdown-item dropdown-toggle" role="button">Listado</a>
                  <ul class="dropdown-menu">
                    <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(1,0);">{{'Aereo' | translate}}</label></li>
                    <li class="label-dropdown-menu">
                      <a class="dropdown-item dropdown-toggle" role="button">
                        {{'Marítimo' | translate}}
                      </a>
                      <ul class="dropdown-menu">
                          <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(2,0);">{{'FCL' | translate}}</label></li>
                          <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(2,1);">{{'LCL' | translate}}</label></li>
                      </ul>
                    </li>
                    <li class="label-dropdown-menu"><label (click)="irListadoCotizacion(3,0)">{{'Terrestre' | translate}}</label></li>
                  </ul>
              </li> -->
          </ul>
        </li>
        <li *ngIf="isLoad && tienePrivilegioBooking()" class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="true"> <span class="nav-label">{{'Envío' | translate}}</span> <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li class="label-dropdown-menu"><label (click)="irAEnvioAereo();">{{'Aereo' | translate}}</label></li>
            <li class="label-dropdown-menu"><label (click)="irAEnvioMaritimo();">{{'Marítimo' | translate}}</label></li>
            <li class="label-dropdown-menu"><label (click)="irAEnvioTerrestre()">{{'Terrestre' | translate}}</label>
            </li>
          </ul>
        </li>
        <li *ngIf="isLoad && tienePrivilegioTracking()" class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
            aria-expanded="false">
            <span class="nav-label">{{'Consultar Envíos' | translate}}</span> <span class="caret"></span>
          </a>
          <ul class="dropdown-menu">
            <li class="label-dropdown-menu">
              <label (click)="irAConsultarEnvios(3);">{{'Aereo' | translate}}</label>
            </li>
            <li class="label-dropdown-menu">
              <label (click)="irAConsultarEnvios(2);">{{'Marítimo' | translate}}</label>
            </li>
            <li class="label-dropdown-menu">
              <label (click)="irAConsultarEnvios(1)">{{'Terrestre' | translate}}</label>
            </li>
          </ul>
        </li>
        <li class="nav-item" *ngIf="mostrarFacturacion && isLoad && tienePrivilegioFacturacion() && !esMexicoOPuebla()">
          <label [routerLink]="['/customer/facturacion']">{{'FacturacionHeader'|translate}}</label>
        </li>
        <li class="nav-item">
          <label [routerLink]="['/customer/miPerfil']">{{'Perfil'|translate}}</label>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto" style="display:table-row;"
        style="padding-right: 12%; padding-top: 0px; font-size: medium;">
        <li class="navbar-text" style="vertical-align: middle;">
          <label>
            <a style="cursor: pointer;" [routerLink]="['/customer/miPerfil']">{{nomUser}},</a>
          </label>
          <label>
            <a (click)="onLogout('CerrarSesion')" style="cursor: pointer;">{{'Cerrar Sesion' | translate}}</a>
          </label>
        </li>
      </ul>
    </div>
  </nav>

</div>