import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Embalaje } from 'src/app/models/embalaje.model';
import { LogModel } from 'src/app/models/log-model.model';
import { TipoEmbalaje } from 'src/app/models/tipo-embalaje.model';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { TipoembalajeService } from 'src/app/services/tipoembalaje.service';

@Component({
  selector: 'app-embalajes',
  templateUrl: './embalajes.component.html',
  styleUrls: ['./embalajes.component.scss']
})
export class EmbalajesComponent implements OnInit {

  formEmbalaje: FormGroup;
  logModel: LogModel;
  idEmbalaje: number;
  listaTipoMercancias: TipoEmbalaje[]; 
  @Input() embalaje: Embalaje;
  constructor(private formBuilder: FormBuilder, private embalajesServices: TipoembalajeService, private configuracionService: ConfiguracionService,
    private translateService: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.obtenerTipoMercancias();
    this.logModel = new LogModel();
    this.formEmbalaje = this.formBuilder.group(
    {
        idTipoEmbalaje: [null, Validators.required],
        descripcion: [''],
        largo: [''],
        ancho: [''],
        alto: ['']
    });
  }

  ngOnChanges(changes: SimpleChange) {
    if(this.embalaje != undefined)
    {
      this.setSelectedRow();
    }
  }

  setSelectedRow() {
    this.formEmbalaje.get('idTipoEmbalaje').setValue(this.embalaje.IdEmbalaje)
    this.formEmbalaje.get('descripcion').setValue(this.embalaje.TipoEmbalaje);
    this.formEmbalaje.get('largo').setValue(this.embalaje.Largo);
    this.formEmbalaje.get('ancho').setValue(this.embalaje.Ancho);
    this.formEmbalaje.get('alto').setValue(this.embalaje.Alto);
  }

  actualizarEmbalaje(tipoEmbalaje) {
    this.embalajesServices.putEmbalaje(tipoEmbalaje, this.logModel).subscribe(data => {
      if(data != null)
      {
        this.formEmbalaje.markAsUntouched()
        this.formEmbalaje.markAsPristine();
        this.embalajesServices.getListaEmbalajes(this.configuracionService.embalajePredeterminado);
        var body = this.translateService.instant("EmbalajeActualizado")
        var title = this.translateService.instant("Actualizado")
        this.toastr.success(body, title)
      }
      else
      {
        var body = this.translateService.instant("EmbalajeNoActualizadoPredefinido")
        var title = this.translateService.instant("Info")
        this.toastr.info(body, title)
      }
      this.limpiarForm()
    }, error => {
      var body = this.translateService.instant("EmbalajeNoActualizado")
      var title = this.translateService.instant("Info")
      this.toastr.error(body, title)
    })
  }

  anyadirEmbalaje(tipoEmbalaje) {
    this.embalajesServices.postEmbalaje(tipoEmbalaje, this.logModel).subscribe(data => {
      this.formEmbalaje.markAsUntouched()
      this.formEmbalaje.markAsPristine();
      this.embalajesServices.getListaEmbalajes(this.configuracionService.embalajePredeterminado);
      var body = this.translateService.instant("EmbalajeAñadido")
      var title = this.translateService.instant("Añadido")
      this.toastr.success(body, title)
      this.limpiarForm();
    }, error => {
      var body = this.translateService.instant("EmbalajeNoAñadido")
      var title = this.translateService.instant("Error")
      this.toastr.error(body, title)
    })
  }
  
  addOrUpdateEmbalaje() {
    let tipoEmbalaje = this.formToModel();
    this.logModel.Pantalla = "embalajes.component.ts";
    if(tipoEmbalaje.IdTipoEmbalaje != undefined && tipoEmbalaje.IdTipoEmbalaje != null && tipoEmbalaje.IdTipoEmbalaje > 1000000)
    {    
      this.logModel.Click = "Actualizar";
      this.actualizarEmbalaje(tipoEmbalaje);
    }
    else
    {
      this.logModel.Click = "Añadir";
      this.anyadirEmbalaje(tipoEmbalaje)
    }
  }

  formToModel(): Embalaje {
    let embalaje: Embalaje = new Embalaje();
    embalaje.IdTipoEmbalaje = 0;
    if(this.embalaje != undefined){
      embalaje.IdTipoEmbalaje = this.embalaje.IdTipoEmbalaje;
    }
    embalaje.Largo = this.formEmbalaje.value.largo;
    embalaje.Ancho = this.formEmbalaje.value.ancho;
    embalaje.Alto = this.formEmbalaje.value.alto;
    if(this.formEmbalaje.get('descripcion').value == '' || this.formEmbalaje.get('descripcion').value == undefined)
    {
      embalaje.TipoEmbalaje = this.getNameTipo(this.formEmbalaje.value.idTipoEmbalaje);
    }
    else
    {
      embalaje.TipoEmbalaje = this.formEmbalaje.value.descripcion;
    }
    embalaje.IdEmbalaje = this.formEmbalaje.value.idTipoEmbalaje;
    return embalaje;
  }

  getNameTipo(id: number): String {
    this.listaTipoMercancias.forEach(element => {
      if(element.IdTipoEmbalaje == id)
      {
        return element.Descripcion;
      }
    })
    return '';
  }

  limpiarForm() {
    this.formEmbalaje.reset();
    this.embalaje = null;
  }

  obtenerTipoMercancias() {
    this.embalajesServices.getTipoEmbalajes(this.logModel).subscribe(data => {
      this.listaTipoMercancias = data;
    })
  }

}
