import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { CambiarPassword } from 'src/app/models/cambiarPassword.model';
import { LogModel } from 'src/app/models/log-model.model';
import { Login } from 'src/app/models/login.model';
import { Perfil } from 'src/app/models/perfil.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProveedorService } from 'src/app/services/proveedor.service';

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss']
})
export class MiPerfilComponent implements OnInit {
  clase: string = "app-mi-perfil";
  public formCambiarPassword: FormGroup;
  public isConductor: boolean;
  public tipoUser: number;
  perfil: Perfil;
  public stringRepetirContraNueva: string = "password";
  public stringContraNueva: string = "password";
  public stringContraActual: string = "password";
  displayedColumns: string[] = ["Descripcion", "Origen", "Destino"];

  public get recaptcha() { return this.formCambiarPassword.get('recaptcha') }
  public get passwordActual() { return this.formCambiarPassword.get('passwordActual') }
  public get passwordNueva() { return this.formCambiarPassword.get('passwordNueva') }
  public get passwordRepetirNueva() { return this.formCambiarPassword.get('passwordRepetirNueva') }

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService
    ,private authService: AuthenticationService, private router: Router, private proveedorService: ProveedorService) { }

  ngOnInit(): void {
    this.proveedorService.getPerfil().subscribe(data => {
      this.perfil = data;
    });

    this.authService.getTipoUsuario().subscribe(data => {
      this.tipoUser = data;
    });
    
    this.formCambiarPassword = this.formBuilder.group(
      {
        recaptcha: [null, Validators.required],
      passwordActual: ['', [Validators.required, Validators.minLength(6)]],
      passwordNueva: ['', [Validators.required, Validators.minLength(6)]],
      passwordRepetirNueva: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  getDireccion(){
    var direccion = "";
    if(this.perfil.tipoDireccion != null && this.perfil.tipoDireccion != "" && this.perfil.tipoDireccion != undefined)
      direccion += this.perfil.tipoDireccion + " " + this.perfil.direccion;
    else
      direccion += this.perfil.direccion;
    if(this.perfil.codigoPostal != null && this.perfil.codigoPostal != "" && this.perfil.codigoPostal != undefined)
      direccion += " " + this.perfil.codigoPostal;
    if(this.perfil.localidad != null && this.perfil.localidad != undefined && this.perfil.localidad != "")
      direccion += ", " + this.perfil.localidad;
    if(this.perfil.pais != null && this.perfil.pais != undefined && this.perfil.pais != "")
      direccion += ", " + this.perfil.pais;
    return direccion;
  }

  changePasswordActual(){
    if(this.stringContraActual == "password")
      this.stringContraActual = "text";
    else
      this.stringContraActual = "password";
  }

  changePasswordNueva(){
    if(this.stringContraNueva == "password")
      this.stringContraNueva = "text";
    else
      this.stringContraNueva = "password";
  }

  changePasswordRepNueva(){
    if(this.stringRepetirContraNueva == "password")
      this.stringRepetirContraNueva = "text";
    else
      this.stringRepetirContraNueva = "password";
  }


  getTiposVehiculos(){
    var tiposVehiculos = "";
    this.perfil.tiposVehiculos.forEach(tv => {
      if(tv == this.perfil.tiposVehiculos[0]) // Primero
        tiposVehiculos += tv;
      else // Los demás
        tiposVehiculos += ", " + tv;
    });
    return tiposVehiculos;
  }

  public resolved(captchaResponse: string): void {
    this.formCambiarPassword.value.recaptcha = captchaResponse; 
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.formCambiarPassword.value.recaptcha = null; 
  }

  /*public findInvalidControls() {
    const invalid = [];
    const controls = this.formCambiarPassword.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
  }*/

  public cambiarPassword(click: string){
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.clase;
    if(this.formCambiarPassword.valid){
      if(this.passwordNueva.value == this.passwordRepetirNueva.value){
        var cambiarP : CambiarPassword = new CambiarPassword();
        cambiarP.ActualPassword = this.passwordActual.value;
        cambiarP.NuevaPassword = this.passwordNueva.value;
        this.authService.cambioPassword(cambiarP, log).subscribe(data => {
          this.authService.logOut(log).subscribe(data => {
            localStorage.clear();
            this.router.navigate(['/login'])
          });
          this.toastr.success("La contraseña se ha cambiado correctamente, por favor, vuelve a iniciar sesión.", "Contraseña cambiada correctamente")
        }, err => {
          if(err.status == 415){
            this.toastr.error("La contraseña actual no coincide con la contraseña que tenemos en la base de datos.", "Las contaseñas no coinciden");
          }
        })
      }else{
        this.toastr.error("La contraseña nueva como la repetición de esta misma no coinciden.", "Las contaseñas no coinciden");
      }
    }else{
      this.toastr.error("Algún campo no ha sido rellenado correctamente.", "Algún campo ha faltado por rellenar");
    }
  }

}
