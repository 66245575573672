import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Toast, ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { Cotizacion } from 'src/app/models/cotizacion.model';
import { ListadoCotizacion } from 'src/app/models/listadoCotizacion.model';
import { LogModel } from 'src/app/models/log-model.model';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-listado-cotizaciones',
  templateUrl: './listado-cotizaciones.component.html',
  styleUrls: ['./listado-cotizaciones.component.scss']
})
export class ListadoCotizacionesComponent implements OnInit, OnChanges {

  @Input() listadoCotizaciones: ListadoCotizacion[] = [];
  key: string = 'IdShipment';
  @Input() activa: boolean = true;
  @Input() term;
  @Input() idArea: number;
  isVisible: boolean;
  @Output() recargarList = new EventEmitter<boolean>();
  aceptar: boolean = false;
  reverse: boolean = false;
  pageActual: number = 1;
  sizePage: number = 10;
  total: number;
  cotizacion: Cotizacion = null;
  modalRef: BsModalRef | null;
  @ViewChild('aceptarRechazarTemplate', {read: TemplateRef}) detallesCotizacion: TemplateRef<any>;
  selectedAereo: boolean = false;
  selectedMaritimoFCL: boolean = false;
  selectedMaritimoLCL: boolean = false;
  selectedTerrestre: boolean = false;
  selectedTodos: boolean = false;
  nombrePDF: String;
  public base64Cotizacion: string = "";
  dialogRef;
  estado: string = "";
  visible:boolean= true;
  checked = false;
  

  constructor(private cotizacionService: CotizacionService, private toastr: ToastrService, private envioService: ShipmentService, private router: Router, private modalService: BsModalService, public dialog: MatDialog) { }

  ngOnInit(): void {
    
    this.cotizacionService.getCotizacion$().subscribe(cotizacion => {
      this.cotizacion = cotizacion;
      if(this.dialogRef){
        this.dialogRef.close();
        this.dialogRef.afterClosed().pipe(
          finalize(() => this.dialogRef = undefined)
        );
      }
      if(this.isVisible)
        this.openModal(this.detallesCotizacion);
    }, _ => {
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.total = this.listadoCotizaciones?.length;
  }

  hacerBooking(realizarBooking){
    var logModel: LogModel = new LogModel();
    logModel.Click = "aceptado-con-booking";
    logModel.Pantalla = "app-aceptar-rechazar";
    this.cotizacionService.cambiarEstado(this.cotizacion.referencia, logModel).subscribe(data => {
      if(data)
      {
        this.toastr.success("Realizado correctamente", "Realizado correctamente");
        //this.close.emit(true);
        this.realizarBooking(realizarBooking);
      }
    });
  }

  esAceptar(){
    this.isVisible = this.aceptar = true;
  }

  esRechazar(){
    this.isVisible = this.aceptar = false;
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  asignarEstado(estado: string){
    this.estado = estado;
  }

  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  obtenerDetalles(cotizacion){
    this.dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    this.asignarEstado(cotizacion.estado);
    this.dialogRef.afterOpened().subscribe(result => {});
    this.cotizacionService.getCotizacion(cotizacion.identificador);
  }
  
  esCompletedEstado(){
    return this.estado == 'COMPLETED';
  }

  cerrar(isClose){
    if(isClose){
      this.modalRef.hide();
      this.cotizacion = null;
    }
  }
  mostrarCols(e:boolean){
    this.visible=e;
  }

  obtenerCotizacionPDF(idCotizacion){
    this.nombrePDF = idCotizacion;
    this.cotizacionService.obtenerPDF(idCotizacion).subscribe(data => {
      this.base64Cotizacion = data.Base64image.toString();
      this.nombrePDF = data.FileName;
    });
  }

  realizarBooking(isBooking: boolean){
    if(isBooking)
    {
      this.modalRef.hide();
      this.getBooking(this.listadoCotizaciones.find(lc => lc.codigo.toString() == this.cotizacion.referencia));
    }
  }

  getBooking(cotizacion: ListadoCotizacion){
    this.envioService.setCotizacion(cotizacion.identificador.toString());
    if(cotizacion.area == 0)
      this.router.navigateByUrl("/customer/shipment/terrestre").then(() => {});
    else if(cotizacion.area == 1)
      this.router.navigateByUrl("/customer/shipment/maritimo").then(() => {});
    else if(cotizacion.area == 2)
      this.router.navigateByUrl("/customer/shipment/aereo").then(() => {});
  }

  duplicarCotizacion(cotizacion: ListadoCotizacion){
    this.cotizacionService.setIdCotizacion(cotizacion.identificador.toString());
    if(cotizacion.area == 0)
      this.router.navigateByUrl("/customer/cotizaciones/terrestre").then(() => {});
    else if(cotizacion.area == 1)
      this.router.navigateByUrl("/customer/cotizaciones/maritimo").then(() => {});
    else if(cotizacion.area == 2)
      this.router.navigateByUrl("/customer/cotizaciones/aereo").then(() => {});
  }

  esMaritimo(){
    return this.idArea == 2;
  }

  esTerrestre(){
    return this.idArea == 1;
  }

  esAereo(){
    return this.idArea == 3;
  }

  esTodos(){
    return this.idArea == 0;
  }

  recargarLista(recargar: boolean)
  {
    if(recargar)
      this.recargarList.emit(true);
  }

  esAccepted(estado: string){
    return estado == 'ACCEPTED';
  }

  esCompleted(estado: string){
    return estado == 'COMPLETED';
  }

  contienePDF(tienePDF: number){
    return tienePDF >= 1;
  }

}
