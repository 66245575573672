import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Login } from 'src/app/models/login.model';
import { TranslateService } from '@ngx-translate/core';
import { LogModel } from 'src/app/models/log-model.model';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistroUsuario } from 'src/app/models/registroUsuario.model';
import { cifValidator } from 'src/app/validators/cif-validator.validator';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  tipoPassword: string = "password";
  clase: string = "app-login"
  errorUsuario: Boolean;
  errorPassword: Boolean;
  formModel = {
    UserName: '',
    Password: ''
  }
  modalRef: BsModalRef | null;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  formRegistroUsuario: FormGroup;
  delegaciones = [];
  registerUser: RegistroUsuario;

  constructor(private modalService: BsModalService, private authenticationService: AuthenticationService, private formBuilder: FormBuilder,
    private router: Router, private translateService: TranslateService, private toastr: ToastrService, private translate: TranslateService) {

    this.delegaciones = [{
      idDelegacion: 0,
      delegacion: this.translate.instant("Worldwide")
    },{
      idDelegacion: 1,
      delegacion: this.translate.instant("España")
    },{
      idDelegacion: 2,
      delegacion: this.translate.instant("Mexico")
    },/*{
      idDelegacion: 3,
      delegacion: this.translate.instant("Dubai")
    },*/{
      idDelegacion: 4,
      delegacion: this.translate.instant("Italia")
    }];
   }

  ngOnInit(): void {
    this.formRegistroUsuario = this.formBuilder.group({
      nombreEntidad: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      nombre: ['', Validators.required],
      cif: ['', [Validators.required, cifValidator()]],
      idDelegacion: ['', Validators.required]
    });
    this.errorUsuario = false;
    this.errorPassword = false;
    this.cargarIdioma();
  }

  public get nombreEntidad() { return this.formRegistroUsuario.get('nombreEntidad'); }
  public get email() { return this.formRegistroUsuario.get('email'); }
  public get telefono() { return this.formRegistroUsuario.get('telefono'); }
  public get nombre() { return this.formRegistroUsuario.get('nombre'); }
  public get cif() { return this.formRegistroUsuario.get('cif'); }
  public get idDelegacion() { return this.formRegistroUsuario.get('idDelegacion'); }


  changePassword(){
    if(this.tipoPassword == "password"){
      this.tipoPassword = "text";
    }else{
      this.tipoPassword = "password";
    }
  }

  convertFormToModel(){
    this.registerUser = new RegistroUsuario();
    this.registerUser.CIF = this.cif.value;
    this.registerUser.Email = this.email.value;
    this.registerUser.IdDelegacion = this.idDelegacion.value;
    this.registerUser.Nombre = this.nombre.value;
    this.registerUser.NombreEntidad = this.nombreEntidad.value;
    this.registerUser.Telefono = this.telefono.value;
  }
  
  registroUsuario(click: string){
    if(this.formRegistroUsuario.valid){
      var log : LogModel = new LogModel();
      log.Click = click;
      log.Pantalla = this.clase;
      this.convertFormToModel();
      this.authenticationService.registro(this.registerUser, log).subscribe(d => {
        var body = this.translate.instant("OkBodyRegisterUser");
        var title = this.translate.instant("OkTitleRegisterUser");
        this.toastr.success(body, title);
        //this.cerrarModal.emit(true);
      });
    }else{
      var body = this.translate.instant("ErrorBodyRegisterUser");
      var title = this.translate.instant("ErrorTitleRegisterUser");
      this.toastr.error(body, title);
    }
  }

  cerrarModal(evento){
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  onRightClick()
  {
    return false;
  } 

  logeado(click: string)
  {
    var log: LogModel = new LogModel();
    log.Pantalla = this.clase;
    log.Click = click;
    this.authenticationService.login(this.formModel.UserName, this.formModel.Password, log).subscribe(data => {
      var login = data as Login;
        this.authenticationService.setToken(login.Token);
        this.authenticationService.setNombre(login.NombreUser);
        this.authenticationService.getTipoUsuario().subscribe(d => {
          if(d == "6"){
            this.router.navigate(['/customer']);
          }else if(d == "7"){
            this.router.navigate(['/conductor/exp-pendientes']);
          }else if(d == "10"){
            this.router.navigate(['/proveedor']);
          }
          this.errorPassword = false;
          this.errorUsuario = false;
        });
        
      }, 
      () => {
        var errorLogin = this.translateService.instant('El usuario o la contraseña es incorrecto, vuelva a intentarlo.');
        this.toastr.error(errorLogin, "Error Login")
        this.errorUsuario = true;
        this.errorPassword = true;
      })
  }

  compruebaUsuario() {
    if(this.formModel.UserName != '' && this.formModel.UserName != undefined && this.formModel.UserName != null)
    {
      this.errorUsuario = false;
    }
    else
    {
      this.errorUsuario = true;
    }
  }

  compruebaPassword() {
    if(this.formModel.Password != '' && this.formModel.Password != undefined && this.formModel.Password != null)
    {
      this.errorPassword = false;
    }
    else
    {
      this.errorPassword = true;
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if(this.langs.includes(lang))
    {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else
    {
     this.translateService.setDefaultLang('es');
    }
  }

  irALoginConductor(){
    this.router.navigate(['/login/conductor']);
  }
}
