import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { rejects } from 'assert';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PrivilegiosCustomer implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router, private toastr: ToastrService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return from(
      new Promise<boolean>(async (resolve) => {
        let privilegio = route.data["privilegio"] as string;
        const privilegios = await this.authenticationService.getPrivilegiosUsuario().toPromise();
        let privilegioTodo = null;
        if (privilegio != "Tarifa")
          privilegioTodo = await (await this.authenticationService.getPrivilegio("Todo").toPromise()).toString();
        const privilegioRuta = await (await this.authenticationService.getPrivilegio(privilegio).toPromise()).toString();
        // No tiene ninguna restricción, tiene todos los privilegios o tiene el privilegio que se le pasa.
        if (privilegios.length == 0 || privilegios.includes(privilegioTodo) || privilegios.includes(privilegioRuta))
          resolve(true);
        else {
          this.toastr.error("No tienes permisos para acceder a " + privilegio, "Sin permiso");
          resolve(false);
          this.router.navigateByUrl('customer');
        }
      })
    )
  }

}
