import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { LogModel } from 'src/app/models/log-model.model';

@Component({
  selector: 'app-header-conductor',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clase: string = "app-header-conductor";
  nomUser: string;
  token: string;
  isMovil: Boolean;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];

  constructor(private authService: AuthenticationService, 
    private router: Router, private toastr: ToastrService,
    private deviceService: DeviceDetectorService, private translateService: TranslateService) { }

  ngOnInit() {
    this.isMovil =  this.deviceService.isMobile();
    this.nomUser = localStorage.getItem("nombre");
    this.token = localStorage.getItem("userToken");
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();
    if(this.langs.includes(lang))
    {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else
    {
     this.translateService.setDefaultLang('es');
    }
  }

  onLogout(click: string) {
    var log: LogModel = new LogModel();
    log.Click = click;
    log.Pantalla = this.clase;
    this.authService.logOut(log).subscribe((data: any) => {
      localStorage.clear();
      this.router.navigate(['login']);
    },
    error => {
      var errorBody = this.translateService.instant('Problemas con el servidor');
      var errorTitulo = this.translateService.instant('Error');
      this.toastr.error(errorBody, errorTitulo)
    });
  }

  

}
