<app-header-conductor></app-header-conductor>
<div class="container fondo">
    <div class="row">
        <div class="col-sm">
            <table class="table table-hover text-center">
                <thead>
                    <td class="font-medium"></td>
                </thead>
                <ng-container *ngFor="let expH of histo | slice: (pageActual-1) * sizePage : (pageActual-1) * sizePage + sizePage; let i = index">
                    <tr>
                        <td>
                            <mat-accordion (opened)="panelOpenState[i] = true;" (closed)="panelOpenState[i] = false; inicializar();">
                                <mat-expansion-panel class="changeColor">
                                    <mat-expansion-panel-header class="changeColor2" (click)="panelOpenState[i] = !panelOpenState[i];" >
                                        <mat-panel-title style="align-items: center;" >
                                            <span matBadge="{{expH.Expediciones.length}}" matBadgeOverlap="false">{{expH.Fecha}}</span>
                                        </mat-panel-title>
                                        <mat-panel-description *ngIf="proveedor" style="float:right;">
                                            <!-- expedicionesFacturar[i].length == 0 && -->
                                            <button style="font-size: small;" *ngIf="!panelOpenState[i]" class="btn"
                                            (click)="setIndice(i);">{{'Pulsar para subir Factura'|translate}}</button>
                                            <button style="font-size: small;" *ngIf="expedicionesFacturar[i].length == 0 && panelOpenState[i]" class="btn"
                                            (click)="setIndice(i); openModal(templateSubirFactura);"
                                            [disabled]="true">{{'Selecciona una Expedición para Facturar'|translate}}</button>
                                            <button style="font-size: small;" *ngIf="expedicionesFacturar[i].length == 1 && panelOpenState[i]" class="btn"
                                            (click)="setIndice(i); openModal(templateSubirFactura);">{{'Subir Factura de la Expedición seleccionada'|translate}}</button>
                                            <button style="font-size: small;" *ngIf="expedicionesFacturar[i].length > 1 && panelOpenState[i]" class="btn" 
                                            (click)="setIndice(i); openModal(templateSubirFacturaConjunta);">{{'Subir Facturas agrupadas'|translate}}</button>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <ng-template matExpansionPanelContent >
                                        <app-detalles [isHistorial]="proveedor" [indice]="i" [hr]="null" [listaExpediciones]="expH.Expediciones" 
                                        (verificacionConductor)="openToast($event)" (subirFacturas)="adjuntarExpediciones($event)"
                                        (pasarIdExpedicion)="setIdExpedicion($event)"></app-detalles>
                                    </ng-template>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </td>
                    </tr>
                </ng-container>
            </table>
            <div class="d-flex justify-content-between p-2" style="text-align: center;">
                <ngb-pagination
                    [(page)]="pageActual"
                    [pageSize]="sizePage"
                    [collectionSize]="total"
                    [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>

<!-- The Modal Subir Factura-->
<ng-template #templateSubirFactura>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir facturas para'|translate}} {{expedicionesFacturar[indice][0]}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-factura (limpiarHistorial)="limpiar($event)" (cerrarModal)="cerrar($event)" [Expedicion]="expedicionesFacturar[indice][0]" [idExpedicion]="idExpedicion"></app-subir-factura>
    </div>
</ng-template>

<!-- The Modal Subir Factura Conjunta-->
<ng-template #templateSubirFacturaConjunta>
    <div class="modal-header fondo" style="border-color: black;">
      <p class="h4 modal-title pull-left">{{'Subir facturas conjuntas'|translate}}</p>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
        <app-subir-factura (limpiarHistorial)="limpiar($event)" (cerrarModal)="cerrar($event)" [idExpedicion]="null" [Expediciones]="expedicionesFacturar[indice]"></app-subir-factura>
    </div>
</ng-template>