<div class="container">
  <div id="General" class="contenedor row">
    <h5>{{'General'|translate}}</h5>
    <div class="col-12">
      <div class="row">
        <div class="col-sm-3">
          <mat-form-field class="example-full-width">
            <mat-label>{{'Tipo Envio' | translate}}</mat-label>
            <input matInput name="tipoEnvio" [(ngModel)]="tipoEnvio" readonly>
          </mat-form-field>
        </div>
        <div class="col-sm-3" *ngIf="modoEnvio != null && modoEnvio != undefined && modoEnvio != ''">
          <mat-form-field class="example-full-width">
            <mat-label>{{'Modo Envio' | translate}}</mat-label>
            <input matInput name="modoEnvio" [(ngModel)]="modoEnvio" readonly>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="example-full-width">
            <mat-label>{{'Cliente' | translate}}</mat-label>
            <input matInput name="cliente" [(ngModel)]="cliente" readonly>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="example-full-width">
            <mat-label>{{'Ref. Cliente' | translate }}</mat-label>
            <input matInput name="referenciaCliente" [(ngModel)]="resumen.ReferenciaCliente" readonly>
          </mat-form-field>
        </div>
      </div>
      <div class="row mb2">
        <div class="col-12">
          <agm-map [latitude]="mapaLatitude" [longitude]="mapaLongitude" [zoom]="zoom">
            <agm-marker *ngIf="esTerrestre()" [latitude]="localidadOrigen.lat" [longitude]="localidadOrigen.lng"
              [label]="{color: 'black', text: '1'}"></agm-marker>
            <agm-marker *ngIf="esTerrestre()" [latitude]="localidadDestino.lat" [longitude]="localidadDestino.lng"
              [label]="{color: 'black', text: '2'}"></agm-marker>
            <agm-marker *ngIf="(esAereo() || esMaritimo()) && tieneLocalidadOrigen()" [latitude]="localidadOrigen.lat"
              [longitude]="localidadOrigen.lng"
              [label]="{color: 'black', text: getTextoOrigenAereoMaritimo(false)}"></agm-marker>
            <agm-marker *ngIf="esAereo() || esMaritimo()" [latitude]="aeropuertoPuertoOrigen.lat"
              [longitude]="aeropuertoPuertoOrigen.lng"
              [label]="{color: 'black', text: getTextoOrigenAereoMaritimo(true)}"></agm-marker>
            <agm-polyline [editable]="false" *ngIf="esAereo() || esMaritimo()">
              <!-- 19.4366124100665, -99.07439739206875 -->
              <agm-polyline-point [latitude]="aeropuertoPuertoOrigen.lat" [longitude]="aeropuertoPuertoOrigen.lng">
              </agm-polyline-point>
              <agm-polyline-point [latitude]="aeropuertoPuertoDestino.lat" [longitude]="aeropuertoPuertoDestino.lng">
              </agm-polyline-point>
              <agm-icon-sequence [strokeColor]="'black'" [repeat]="'10px'" [offset]="0" [strokeWeight]="3" [scale]="2"
                [strokeOpacity]="100" [path]="'M 0,-1 0,1'">
              </agm-icon-sequence>
            </agm-polyline>
            <!-- <agm-direction *ngIf="(esAereo() ||esMaritimo()) && tieneLocalidadDestino()"
          [origin]="aeropuertoPuertoDestino" [destination]="localidadDestino">
          </agm-direction> -->
            <agm-marker *ngIf="esAereo() || esMaritimo()" [latitude]="aeropuertoPuertoDestino.lat"
              [longitude]="aeropuertoPuertoDestino.lng"
              [label]="{color: 'black', text: getTextoDestinoAereoMaritimo(true)}"></agm-marker>
            <agm-marker *ngIf="(esAereo() || esMaritimo()) && tieneLocalidadDestino()" [latitude]="localidadDestino.lat"
              [longitude]="localidadDestino.lng"
              [label]="{color: 'black', text: getTextoDestinoAereoMaritimo(false)}"></agm-marker>
            <!-- [waypoints]="[
              {location: { lat: 39.0921167, lng: -94.8559005 }},
              {location: { lat: 41.8339037, lng: -87.8720468 }}
           ]" -->
          </agm-map>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>{{'Incoterm'|translate}}</mat-label>
            <input matInput name="incoterm" [(ngModel)]="resumen.Incoterm" readonly>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <!-- Puerto y Aeropuerto con *ngIf -->
          <mat-form-field class="example-full-width" *ngIf="esAereo()">
            <mat-label>{{'Aerolinea'|translate}}</mat-label>
            <input matInput name="aerolinea" [(ngModel)]="resumen.Aerolinea" readonly>
          </mat-form-field>
          <mat-form-field class="example-full-width" *ngIf="esMaritimo()">
            <mat-label>{{'Naviera'|translate}}</mat-label>
            <input matInput name="naviera" [(ngModel)]="resumen.Naviera" readonly>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>{{'Mercancia disponible desde'|translate}}</mat-label>
            <input matInput name="fechaRecogida" [(ngModel)]="resumen.FechaReadyForShipment" readonly>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>
  <div id="Mercancia" class="contenedor row">
    <h5>{{'Mercancia'|translate}}</h5>
    <div class="col-12">
      <span *ngIf="resumen.Mercancia != undefined && resumen.Mercancia != null && resumen.Mercancia.length > 0">
        <td class="saltoLinea">
          <!--style="margin-left: 3%;" -->
          <app-detalles-mercancia [mercanciasCotizacion]="resumen.Mercancia"></app-detalles-mercancia>
        </td>
      </span>
      <!--animate__bounceInDown-->
      <span
        *ngIf="resumen.MercanciaContenedor != undefined && resumen.MercanciaContenedor != null && resumen.MercanciaContenedor.length > 0">
        <td class="saltoLinea">
          <app-detalles-contenedor [contenedoresCotizacion]="resumen.MercanciaContenedor"></app-detalles-contenedor>
        </td>
      </span>
    </div>
  </div>
  <div class="row">
    <div id="InfoAdicional" class="contenedor">
      <h5>{{'Informacion Adicional' | translate}}</h5>
      <div class="col-12">
        <div class="row espaciadocolsm12">
          <div class="col-sm-12">
            <mat-form-field class="example-full-width">
              <mat-label>{{'Observaciones'|translate}}</mat-label>
              <textarea matInput name="Instrucciones" [(ngModel)]="resumen.Observaciones" readonly></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12" style="text-align: right; margin-left: 8.9%;">
      <button type="button" class="btn" style="margin: 5px;" (click)="confirmacion()">{{'Aceptar'|translate}}</button>
      <button type="button" class="btn" style="margin: 5px;" (click)="cancelacion()">{{'Cancelar'|translate}}</button>
    </div>
  </div>
</div>