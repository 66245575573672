import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddressBook } from 'src/app/models/addressBook.model';
import { AddressBookService } from 'src/app/services/addressBookService.service';

@Component({
  selector: 'app-lista-address-book',
  templateUrl: './lista-address-book.component.html',
  styleUrls: ['./lista-address-book.component.scss'],
  providers: [BsModalService]
})
export class ListaAddressBookComponent implements OnInit {

  listAddressBook;
  itemsPorPage: number = 0;
  sizeOptions: number[] = [];
  lengthTable: number = 0;
  addressBook: MatTableDataSource<AddressBook>;;
  displayedColumns: string[] = ['nombreFiscal', 'direccion', 'update'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  modalRef: BsModalRef | null;

  constructor(private addressBookService: AddressBookService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.addressBookService.getAllAddressBook();
    this.addressBookService.getAddressBooks$().subscribe(data => {
      this.sizeOptions = [];
      this.listAddressBook = new MatTableDataSource<AddressBook>(data);
      this.lengthTable = data.length;
      this.listAddressBook.paginator = this.paginator;
      this.listAddressBook.filterPredicate = function(data: AddressBook, filter: string): boolean {
        return data.nombreFiscal?.toLowerCase().includes(filter) || data.address?.toLowerCase().includes(filter); 
      }
      for(var i = 1; i <= (this.lengthTable/10)+1; i++){
          this.sizeOptions = [...this.sizeOptions,i*10];
          this.itemsPorPage = i*10;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    const config: ModalOptions = {
      id: 1,
      backdrop: 'static',
      keyboard: false,
      animated: true,
      class: 'modal-sm',
      ignoreBackdropClick: true,
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listAddressBook.filter = filterValue.trim().toLowerCase();
  }

  setRow(row: AddressBook){
    this.addressBookService.setAddressBook$(row);
  }

  cerrar(evento){
    this.modalRef.hide();
  }

}
