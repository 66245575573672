import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Archivo } from '../models/archivo.model';
import { Cotizacion } from '../models/cotizacion.model';
import { CotizacionEnvio } from '../models/cotizacionEnvio.model';
import { Envio } from '../models/envio.model';
import {  ListadoCotizacion } from '../models/listadoCotizacion.model';
import { LogModel } from '../models/log-model.model';
import { Shipment } from '../models/shipment.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  cotizacionesActivas$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesActivasAereo$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesActivasMaritimo$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesActivasTerrestre$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesCaducadas$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesCaducadasAereo$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesCaducadasMaritimo$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacionesCaducadasTerrestre$: Subject<ListadoCotizacion[]> = new Subject();
  cotizacion$: Subject<Cotizacion> = new Subject();
  archivosCotizacion$: Subject<Archivo[]> = new Subject();
  readonly apiUrlService = environment.apiUrl;
  idCotizacion: number;
  ip: string = ""; 
  localizacion: string = "";
  public posicionArea: number = 1;
  public posicionModoEnvio: number = 0;

  constructor(private http: HttpClient, private authenticacion: AuthenticationService) {
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
  }

  public getIdCotizacion(){
    return this.idCotizacion;
  }

  getCotizacionesValidas$(){
    return this.cotizacionesActivas$.asObservable();
  }

  getCotizacionesValidasAereo$(){
    return this.cotizacionesActivasAereo$.asObservable();
  }

  getCotizacionesValidasMaritimo$(){
    return this.cotizacionesActivasMaritimo$.asObservable();
  }

  getCotizacionesValidasTerrestre$(){
    return this.cotizacionesActivasTerrestre$.asObservable();
  }

  getCotizacion$(){
    return this.cotizacion$.asObservable();
  }

  getCotizacionesCaducadas$(){
    return this.cotizacionesCaducadas$.asObservable();
  }

  getCotizacionesCaducadasAereo$(){
    return this.cotizacionesCaducadasAereo$.asObservable();
  }

  getCotizacionesCaducadasMaritimo$(){
    return this.cotizacionesCaducadasMaritimo$.asObservable();
  }

  getCotizacionesCaducadasTerrestre$(){
    return this.cotizacionesCaducadasTerrestre$.asObservable();
  }

  getArchivosCotizacion$(){
    return this.archivosCotizacion$.asObservable();
  }

  getCotizacionesActivas(idArea: string){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/activas/" + idArea;
    this.http.get<ListadoCotizacion[]>(url_api, {headers: headersToken}).pipe(map(data => data as ListadoCotizacion[]))
     .subscribe(data => {
      if(idArea == '-1')
        this.cotizacionesActivas$.next(data);
      else if(idArea == '0') // Es terrestre
        this.cotizacionesActivasTerrestre$.next(data);
      else if(idArea == '1') // Es maritimo
        this.cotizacionesActivasMaritimo$.next(data);
      else if(idArea == '2') // Es aereo
        this.cotizacionesActivasAereo$.next(data);
     });
  }

  getCotizacionesCaducadas(idArea: string){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/caducadas/" + idArea;
    this.http.get<ListadoCotizacion[]>(url_api, {headers: headersToken}).pipe(map(data => data as ListadoCotizacion[]))
     .subscribe(data => {
      if(idArea == '-1')
        this.cotizacionesCaducadas$.next(data);
      else if(idArea == '0') // Es terrestre
        this.cotizacionesCaducadasTerrestre$.next(data);
      else if(idArea == '1') // Es maritimo
        this.cotizacionesCaducadasMaritimo$.next(data);
      else if(idArea == '2') // Es aereo
        this.cotizacionesCaducadasAereo$.next(data);
     });
  }

  getCotizacion(idCotizacion: number){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/" + idCotizacion;
    this.http.get<Cotizacion>(url_api, {headers: headersToken}).pipe(map(data => data as Cotizacion))
     .subscribe(data => {
      this.cotizacion$.next(data);
     });
  }

  getArchivosCotizacion(idCotizacion: number){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/" + idCotizacion + "/archivos";
    this.http.get<Archivo[]>(url_api, {headers: headersToken}).pipe(map(data => data as Archivo[]))
     .subscribe(data => {
      this.archivosCotizacion$.next(data);
     });
  }

  getDuplicidadReferencia(referenciaCliente: string){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/duplicidadReferencia/" + referenciaCliente;
    return this.http.get<boolean>(url_api, {headers: headersToken}).pipe(map(data => data as boolean));
  }

  getBooking(idCotizacion, logModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/" + idCotizacion + "/booking";
    return this.http.get<Shipment>(url_api, {headers: headersToken}).pipe(map(data => data as Shipment));
  }

  postCotizacion(cotizacion, logModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    logModel.Ip = this.ip;
    logModel.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "fa_cotizaciones";
    return this.http.post<number>(url_api,JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify(cotizacion).toString(),
    }), {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data as number));
  }

  getDuplicidadCotizacion(idCotizacion, logModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/duplicarCotizacion/" + idCotizacion;

    return this.http.get<CotizacionEnvio>(url_api, {headers: headersToken}).pipe(map(data => data as CotizacionEnvio));
  }

  cambiarEstado(referenciaCotizacion, logModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/cambiarEstado/" + referenciaCotizacion + "/accepted";

    return this.http.post<boolean>(url_api, JSON.stringify({
      "Log": logModel,
      "Body": ""
    }),
    {headers: headersToken}).pipe(map(data => data as boolean));
  }

  cambiarEstadoDeclined(referenciaCotizacion, idCausa, textoOtros, logModel){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/cambiarEstado/" + referenciaCotizacion + "/loss/" + idCausa;
    if(textoOtros == undefined || textoOtros == null)
      textoOtros = "";

    return this.http.post<boolean>(url_api, JSON.stringify({
      "Log": logModel,
      "Body": JSON.stringify({
        "textoOtros": textoOtros
      }).toString()
    }),
    {headers: headersToken.append("Content-Type", "application/json")}).pipe(map(data => data as boolean));
  }

  obtenerPDF(idCotizacion){
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.authenticacion.getToken()
    });
    const url_api = this.apiUrlService + "fa_cotizaciones/" + idCotizacion + "/pdf";
    return this.http.get<Archivo>(url_api, {headers: headersToken}).pipe(map(data => data as Archivo));
  }

  public setIdCotizacion(idCotizacion){
    this.idCotizacion = idCotizacion;
  }
}
