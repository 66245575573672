<app-header></app-header>
<mat-tab-group mat-align-tabs="center">
    <mat-tab label="{{'Todos' | translate}}">
        <app-listado [listadoFacturas]="listadoTodos" [total]="totalTodos" [listadoFacturasTotal]="listadoTodos" [areasSelected]="areasListTodos"></app-listado>
    </mat-tab>
    <mat-tab label="{{'Aereo' | translate}}">
        <app-listado [listadoFacturas]="listadoAereo" [total]="totalAereo" [listadoFacturasTotal]="listadoAereo" [areasSelected]="areasListAereo"></app-listado>
    </mat-tab>
    <mat-tab label="{{'Maritimo' | translate}}">
        <app-listado [listadoFacturas]="listadoMaritimo" [total]="totalMaritimo" [listadoFacturasTotal]="listadoMaritimo" [areasSelected]="areasListMaritimo"></app-listado>
    </mat-tab>
    <mat-tab label="{{'Terrestre' | translate}}">
        <app-listado [listadoFacturas]="listadoTerrestre" [total]="totalTerrestre" [listadoFacturasTotal]="listadoTerrestre" [areasSelected]="areasListTerrestre"></app-listado>
    </mat-tab>
  </mat-tab-group>