import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Incidencia } from '../models/incidencia.model';
import { map } from 'rxjs/operators';
import { Archivo } from '../models/archivo.model';
import { TipoIncidenciaConductor } from '../models/tipo-incidencia.model';
import { LogModel } from '../models/log-model.model';
import { LogService } from './log.service';
import { incidenciaPartida } from '../models/incidenciaPartida.model';

@Injectable({
  providedIn: 'root'
})
export class IncidenciasService {

  readonly apiUrlService = environment.apiUrl;

  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  });

  ip: string = ""; 
  localizacion: string = "";
  constructor(private http: HttpClient, private logService: LogService) { 
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
  }

  getToken()
  {
    return localStorage.getItem("userToken");
  }

  obtenerListadoIncidencias(idExpedicion: number):Observable<Incidencia[]> {
    const url_api = this.apiUrlService + "pa_incidenciasexpedicion/expedicion/" + idExpedicion;
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_incidenciasexpedicionController.cs", "Log obtener listado de incidencias de la expedición " + idExpedicion + ".",
     "Getpa_incidenciasexpedicion", "pa_incidenciasexpedicion", url_api);
    return this.http.get<Incidencia[]>(url_api, {headers: headersToken.append("Content-Type", "application/json") });
  }

  obtenerTipoIncidencia():Observable<TipoIncidenciaConductor[]> {
    const url_api = this.apiUrlService + "pa_tipoincidenciaconductor";
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    this.logService.postLogCompleto("GET", "pa_tipoincidenciaconductorController.cs", "Log obtener listado de tipo incidencias para el conductor.",
     "Getpa_tipoincidenciaconductor", "pa_tipoincidenciaconductor", url_api);
    return this.http.get<TipoIncidenciaConductor[]>(url_api, {headers: headersToken.append("Content-Type", "application/json") });
  }

  enviarIncidencia(incidencia: Incidencia, log: LogModel) {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "pa_incidenciasexpedicion";
    return this.http.post(url_api,JSON.stringify({"Log": log, "Body": JSON.stringify(incidencia).toString()}), {headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  modificarIncidencia(incidencia: Incidencia, log: LogModel)
  {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    log.Ip = this.ip;
      log.Localizacion = this.localizacion;
    const url_api = this.apiUrlService + "pa_incidenciasexpedicion";
    return this.http.put(url_api,JSON.stringify({"Log": log, "Body": JSON.stringify(incidencia).toString()}), {headers: headersToken.append("Content-Type", "application/json") }).pipe(map(data => data));
  }

  obtenerArchivos(idIncidencia: number):Observable<Archivo[]> {
    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    const url_api = this.apiUrlService + "pa_incidenciasexpedicion/" + idIncidencia + "/fotos";
    this.logService.postLogCompleto("GET", "pa_incidenciasexpedicionController.cs", "Log obtener listado de las fotos de la incidencia " + idIncidencia + ".",
     "Getpa_incidenciasImagenes", "pa_incidenciasexpedicion", url_api);
    return this.http.get<Archivo[]>(url_api, {headers: headersToken.append("Content-Type", "application/json")});
  }

  getIncidenciasPartida(referencia: string):Observable<incidenciaPartida[]> {
    var url_api = this.apiUrlService + "em_incidencias/";
    if(referencia.includes("/"))
      url_api += "expedicion/" + encodeURIComponent(referencia);
    else
      url_api += "partida/" + referencia;

    var headersToken: HttpHeaders = new HttpHeaders({
      "Authorization": "Bearer " + this.getToken()
    })
    this.logService.postLogCompleto("GET", "em_incidenciasControlller.cs", "Log obtener listado de las incidencias para la partida o expedicion " + referencia + ".",
     "Get_em_incidenciasByIdPartida/Get_em_incidenciasByIdExpedicion", "incidencias", url_api);
    return this.http.get<incidenciaPartida[]>(url_api, {headers: headersToken.append("Content-Type", "application/json") });
  }

}
