import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { EspecialidadVehiculo } from 'src/app/models/especialidadVehiculo.model';
import { LogModel } from 'src/app/models/log-model.model';
import { TipoVehiculo } from 'src/app/models/tipoVehiculo.model';
import { Vehiculo } from 'src/app/models/vehiculo.model';
import { VehiculoService } from 'src/app/services/vehiculo.service';

@Component({
  selector: 'app-form-vehiculo',
  templateUrl: './form-vehiculo.component.html',
  styleUrls: ['./form-vehiculo.component.scss']
})
export class FormVehiculoComponent implements OnInit {

  private clase: string = "app-form-vehiculo";
  public formVehiculo: FormGroup;
  listaTipoVehiculoFilter: TipoVehiculo[];
  listaTipoVehiculo: TipoVehiculo[];
  listaEspecialidadVehiculos: EspecialidadVehiculo[] = [];
  tieneRemolque: boolean = false;
  @Input() vehiculo: Vehiculo;
  @Output() limpiar = new EventEmitter<any>();
  @Output() actualizarConductores = new EventEmitter<any>();
  @Output() emitCreateVehiculo = new EventEmitter<boolean>()
  filteredTipoVehiculo: Observable<TipoVehiculo[]>;
  filteredEspecialidadVehiculo: Observable<EspecialidadVehiculo[]>;

  constructor(private formBuilder: FormBuilder, private vehiculoService: VehiculoService, private toastr: ToastrService, private translate: TranslateService) { }

  public get id() { return this.formVehiculo.get('idVehiculo') }
  public get matricula() { return this.formVehiculo.get('matricula') }
  public get tipo() { return this.formVehiculo.get('tipo') }
  public get peso() { return this.formVehiculo.get('peso') }
  public get volumen() { return this.formVehiculo.get('volumen') }
  public get largo() { return this.formVehiculo.get('largo') }
  public get ancho() { return this.formVehiculo.get('ancho') }
  public get alto() { return this.formVehiculo.get('alto') }
  public get adr() { return this.formVehiculo.get('adr') }
  public get especialidad() { return this.formVehiculo.get('especialidad') }
  public get remolque() { return this.formVehiculo.get('remolque') }

  ngOnInit(): void {
    this.inicializar();
  }

  inicializar(){
    this.formVehiculo = this.formBuilder.group(
      {
        idVehiculo: ['0'],
        matricula: ['', Validators.required],
        tipo: ['', Validators.required],
        peso: [''],
        volumen: [''],
        largo: [''],
        ancho: [''],
        alto: [''],
        adr: [''],
        especialidad: ['', Validators.required],
        remolque: ['']
      });
      this.vehiculoService.getTipoVehiculos().subscribe(tipoVehiculos => {
        this.listaTipoVehiculo = tipoVehiculos;
        this.filteredTipoVehiculo = this.tipo.valueChanges.pipe(
          startWith(''),
          map(value => this._filterTipoVehiculo(value || '')),
        );
      });
      this.vehiculoService.getVehiculo$().subscribe(vehiculo => {
        this.vehiculo = vehiculo;
        this.setVehiculo();
      });
      this.vehiculoService.getEspecialidadVehiculos().subscribe(especialidadVehiculos => {
        this.listaEspecialidadVehiculos = especialidadVehiculos;
        this.filteredEspecialidadVehiculo = this.especialidad.valueChanges.pipe(
          startWith(''),
          map(value => this._filterEspecialidadVehiculo(value || '')),
        );
      });
  }

  private _filterTipoVehiculo(value: string): TipoVehiculo[] {
    if(value && this.chartactersOnly(value))
      return this.listaTipoVehiculo.filter(option => option.Nombre.toLowerCase().includes(value.toLowerCase()));

    return this.listaTipoVehiculo;
  }

  public displayEspecialidad = (idEspecialidad) => {
    if(idEspecialidad && typeof idEspecialidad === 'number')
      return this.listaEspecialidadVehiculos.filter(tv => tv.IdEspecialidadVehiculo == idEspecialidad)[0].Nombre;
    else if(idEspecialidad != undefined && idEspecialidad != null)
      return idEspecialidad.Nombre;
  }

  private _filterEspecialidadVehiculo(value: string): EspecialidadVehiculo[] {
    if(value && this.chartactersOnly(value))
      return this.listaEspecialidadVehiculos.filter(option => option.Nombre.toLowerCase().includes(value.toLowerCase()));
    
    return this.listaEspecialidadVehiculos;
  }

  public displayTipo = (idTipo) => {
    if(typeof idTipo === 'number')
      return this.listaTipoVehiculo.filter(tv => tv.IdTipoVehiculo == idTipo.toString())[0].Nombre;
    else if(idTipo != undefined && idTipo != null)
      return idTipo.Nombre;
  }

  public onBlurTipo(event){
    if(event.target.value && this.chartactersOnly(event.target.value))
      this.tipo.setValue(this.listaTipoVehiculo.filter(tv => tv.Nombre.toLowerCase().startsWith(event.target.value.toLowerCase()))[0].IdTipoVehiculo);
  }
  
  public onBlurEspecialidad(event){
    if(event.target.value && this.chartactersOnly(event.target.value))
      this.especialidad.setValue(this.listaEspecialidadVehiculos.filter(te => te.Nombre.toLowerCase().startsWith(event.target.value.toLowerCase()))[0].IdEspecialidadVehiculo);
  }

  chartactersOnly (e) {
    var regex = new RegExp("^[a-zA-ZáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ ]*$");
    if (regex.test(e))
        return true;
    
    return false;
  }

  

  ngOnChanges(changes: SimpleChange){
    
  }

  setVehiculo(){
    if(this.isUpdate()){
      this.id.setValue(this.vehiculo.IdVehiculo);
      this.matricula.setValue(this.vehiculo.Matricula);
      this.especialidad.setValue(this.vehiculo.IdEspecialidadVehiculo);
      this.tipo.setValue(this.vehiculo.IdTipoVehiculo);
      this.peso.setValue(Number(this.vehiculo.Peso.replace(",", ".")));
      this.volumen.setValue(Number(this.vehiculo.Volumen.replace(",", ".")));
      this.largo.setValue(this.vehiculo.Largo.toString().replace(",", "."));
      this.ancho.setValue(this.vehiculo.Ancho.toString().replace(",", "."));
      this.alto.setValue(this.vehiculo.Alto.toString().replace(",", "."));
      this.adr.setValue(this.vehiculo.Adr);
      this.remolque.setValue(this.vehiculo.Remolque);
      if(this.vehiculo.Remolque != null && this.vehiculo.Remolque != "")
        this.tieneRemolque = true;
      else
        this.tieneRemolque = false;
    }
  }

  resetear(){
    this.vehiculo = undefined;
    this.tieneRemolque = false;
    this.inicializar();
  }

  obtenerVehiculo(){
    var vehiculo = new Vehiculo();
    vehiculo.IdVehiculo = this.id.value == '' || this.id.value == null || this.id.value == undefined ? '0' : this.id.value;
    vehiculo.Matricula = this.matricula.value;
    vehiculo.IdTipoVehiculo = this.tipo.value;
    vehiculo.Peso = this.peso.value;
    vehiculo.Volumen = this.volumen.value;
    vehiculo.Largo = this.largo.value;
    vehiculo.Ancho = this.ancho.value;
    vehiculo.Alto = this.alto.value;
    vehiculo.Adr = this.adr.value;
    vehiculo.IdEspecialidadVehiculo = this.especialidad.value;
    vehiculo.Remolque = this.remolque.value;
    vehiculo.Activo = this.isUpdate() ? this.vehiculo.Activo : true;
    return vehiculo;
  }

  isUpdate(){
    return this.vehiculo != null && this.vehiculo != undefined && this.vehiculo.IdVehiculo != null && this.vehiculo.IdVehiculo != undefined;
  }

  postVehiculo(click: string){
    if(this.formVehiculo.valid){
      var vehiculo = this.obtenerVehiculo();
      var logModel: LogModel = new LogModel();
      logModel.Click = click;
      logModel.Pantalla = this.clase;
      this.vehiculoService.postVehiculo(vehiculo, logModel).subscribe(data => {
        this.formVehiculo.reset();
        this.vehiculo = undefined;
        this.tieneRemolque = false;
        this.vehiculoService.getAllVehiculos();
        var body: string = this.translate.instant("BodyVehiculoCreadoOk");
        var title: string = this.translate.instant("TitleVehiculoCreadoOk");
        this.toastr.success(body, title);
        this.emitCreateVehiculo.emit(true);
      }, err => {
        this.mostrarError(err, vehiculo);
      })
    }else{
      var title: string = this.translate.instant("Campos faltan por rellenar");
      var body: string = this.translate.instant("Se ha detectado algún campo erróneo o falta por introducirse, por favor revíselo.");
      this.toastr.error(body, title);
    }
  }

  mostrarError(err, vehiculo){
    if(err.status == 451){
      var body: string = this.translate.instant("BodyVehiculoCoincideMatriculaErrorParte1") + " " + vehiculo.Matricula + " " + this.translate.instant("BodyVehiculoCoincideMatriculaErrorParte2");
      var title: string = this.translate.instant("TitleVehiculoCoincideMatriculaError");
      this.toastr.error(body, title);
    }else if(err.status == 452){
      var body: string = this.translate.instant("BodyVehiculoCoincideMatriculaErrorParte1") + " " + vehiculo.Matricula + " " + this.translate.instant("BodyVehiculoCoincideMatriculaYRemolqueErrorParte1") + " " + vehiculo.Remolque + " " + this.translate.instant("BodyVehiculoCoincideMatriculaYRemolqueErrorParte2");
      var title: string = this.translate.instant("TitleVehiculoCoincideMatriculaYRemolqueError");
      this.toastr.error(body, title);
    }else{
      var body: string = this.translate.instant("BodyVehiculoCreadoError");
      var title: string = this.translate.instant("TitleVehiculoCreadoError");
      this.toastr.error(body, title);
    }
  }

  updateVehiculo(click: string){
    if(this.formVehiculo.valid){
      var vehiculo = this.obtenerVehiculo();
      var logModel: LogModel = new LogModel();
      logModel.Click = click;
      logModel.Pantalla = this.clase;
      this.vehiculoService.putVehiculo(vehiculo, logModel).subscribe(data => {
        var title: string = this.translate.instant("TitleVehiculoActualizarOk");
        var body: string = this.translate.instant("BodyVehiculoActualizarOk");
        this.formVehiculo.reset();
        this.vehiculo = undefined;
        this.vehiculoService.getAllVehiculos();
        this.toastr.success(body, title);
        this.emitCreateVehiculo.emit(false);
      }, err => {
        this.mostrarError(err, vehiculo);
      });
    }else{
      var title: string = this.translate.instant("Campos faltan por rellenar");
      var body: string = this.translate.instant("Se ha detectado algún campo erróneo o falta por introducirse, por favor revíselo.");
      this.toastr.error(body, title);
    }
  }

}
