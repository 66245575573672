import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Conductor } from 'src/app/models/conductor.model';
import { LogModel } from 'src/app/models/log-model.model';
import { ConductorService } from 'src/app/services/conductor.service';

@Component({
  selector: 'app-form-conductor',
  templateUrl: './form-conductor.component.html',
  styleUrls: ['./form-conductor.component.scss']
})
export class FormConductorComponent implements OnInit {

  private clase: string = "app-form-conductor";
  public formConductor: FormGroup;
  @Input() conductor: Conductor;
  @Output() limpiar = new EventEmitter<any>();
  @Output() actualizarConductores = new EventEmitter<any>();
  @Output() emitCreateConductor = new EventEmitter<boolean>();
  

  constructor(private formBuilder: FormBuilder, private conductorService: ConductorService, private toastr: ToastrService, private translate: TranslateService) { }

  public get id() { return this.formConductor.get('id') }
  public get apellidos() { return this.formConductor.get('apellidos') }
  public get nombre() { return this.formConductor.get('nombre') }
  public get nif() { return this.formConductor.get('nif') }
  public get movil() { return this.formConductor.get('movil') }
  public get ext() { return this.formConductor.get('ext') }
  public get email() { return this.formConductor.get('email') }
  public get contactoAlternativo() { return this.formConductor.get('contactoAlternativo') }

  ngOnInit(): void {
    this.inicializar();
  }

  inicializar(){
    this.formConductor = this.formBuilder.group(
      {
        id: [''],
        apellidos: ['', Validators.required],
        nombre: ['', [Validators.required]],
        nif: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)/*, nifValidator()*/]],
        movil: ['', [Validators.required, Validators.minLength(9)]],
        ext: [''],
        email: ['', [Validators.email, Validators.required]],
        contactoAlternativo: ['']
      });
      this.conductorService.getConductor$().subscribe(conductor => {
        this.conductor = conductor;
        this.setConductor();
      })
  }

  ngOnChanges(changes: SimpleChange){
    
  }

  setConductor(){
    if(this.isUpdate()){
      this.formConductor.get('id').setValue(this.conductor.IdConductor);
      this.formConductor.get('apellidos').setValue(this.conductor.Apellidos);
      this.formConductor.get('nombre').setValue(this.conductor.Nombre);
      this.formConductor.get('nif').setValue(this.conductor.NIF);
      this.formConductor.get('movil').setValue(this.conductor.Movil);
      this.formConductor.get('ext').setValue(this.conductor.Extension);
      this.formConductor.get('email').setValue(this.conductor.Email);
      this.formConductor.get('contactoAlternativo').setValue(this.conductor.Email1Tlf1);
    }
  }

  resetear(){
    this.conductor = undefined;
    this.inicializar();
  }

  obtenerConductor(){
    var conductor = new Conductor();
    conductor.IdConductor = this.id.value == '' || this.id.value == null || this.id.value == undefined ? 0 : this.id.value;
    conductor.Apellidos = this.apellidos.value;
    conductor.Nombre = this.nombre.value;
    conductor.NIF = this.nif.value;
    conductor.Movil = this.movil.value;
    conductor.Extension = this.ext.value;
    conductor.Email = this.email.value;
    conductor.Email1Tlf1 = this.contactoAlternativo.value;
    if(this.conductor != null && this.conductor != undefined){
      conductor.IdOrigen = this.conductor.IdOrigen;
      conductor.NumSocio = this.conductor.NumSocio;
      conductor.Entidad = this.conductor.Entidad;
      conductor.Estado = this.conductor.Estado;
    }
    return conductor;
  }

  isUpdate(){
    return this.conductor != null && this.conductor != undefined && this.conductor.IdConductor != null && this.conductor.IdConductor != undefined;
  }

  postConductor(click: string){
    if(this.formConductor.valid){
      var conductor = this.obtenerConductor();
      var logModel: LogModel = new LogModel();
      logModel.Click = click;
      logModel.Pantalla = this.clase;
      this.conductorService.postConductor(conductor, logModel).subscribe(data => {
        this.formConductor.reset();
        this.conductor = undefined;
        this.conductorService.getAllConductores();
        var body: string = this.translate.instant("BodyConductorCreadoOk");
        var title: string = this.translate.instant("TitleConductorCreadoOk");
        this.toastr.success(body, title);
        this.emitCreateConductor.emit(true);
      }, err => {
        if(err.status == 450){
          var title: string = this.translate.instant("YaExisteConductor");
          this.toastr.warning("", title);
        }
        else{
          var body: string = this.translate.instant("BodyConductorCreadoError");
          var title: string = this.translate.instant("TitleConductorCreadoError");
          this.toastr.error(body, title);
        }
      });
    }else{
      var title: string = this.translate.instant("Campos faltan por rellenar");
      var body: string = this.translate.instant("Se ha detectado algún campo erróneo o falta por introducirse, por favor revíselo.");
      this.toastr.error(body, title);
    }
  }

  updateConductor(click: string){
    if(this.formConductor.valid){
      var conductor = this.obtenerConductor();
      var logModel: LogModel = new LogModel();
      logModel.Click = click;
      logModel.Pantalla = this.clase;
      this.conductorService.putConductor(conductor, logModel).subscribe(data => {
        var title: string = this.translate.instant("Actualizado correcto");
        var body: string = this.translate.instant("Se ha actualizado correctamente el conductor.");
        this.formConductor.reset();
        this.conductor = undefined;
        this.conductorService.getAllConductores();
        this.toastr.success(body, title);
        this.emitCreateConductor.emit(false);
      }, err => {
        var body: string = this.translate.instant("BodyConductorActualizarError");
        var title: string = this.translate.instant("TitleConductorActualizarError");
        this.toastr.error(body, title);
      });
    }else{
      var title: string = this.translate.instant("Campos faltan por rellenar");
        var body: string = this.translate.instant("Se ha detectado algún campo erróneo o falta por introducirse, por favor revíselo.");
        this.toastr.error(body, title);
    }
  }
}
