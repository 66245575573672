export class Archivo {
    IdDocumento?: number;
    FileName: String;
    TipoArchivo: String;
    Extension: String;
    Base64image: string | ArrayBuffer;
    Tamanyo: number;
    Path: String;
    IdTipoArchivo?: number;
    IdEnvio?: number;
    FechaFinalValidez: string;
    Obligatorio?: Boolean;
    Estado?: String;
    Verificado?: Boolean;
    Partida?: String;
    Expedicion?: String;
}
