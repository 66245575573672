<div id="container" class="container">
  <div class="row">
    <div class="col-sm-12">
      <h4>{{ "Indicar total neto de todos los bultos" | translate }}</h4>
    </div>
  </div>
  <div class="row" *ngFor="let un of mp; let i = index">
    <div class="col-sm-2"></div>
    <div class="col-sm-2" *ngIf="tarifa">
      <div class="row">
        <div class="col mr45">
          <label>{{ "Clase" | translate }}</label>
        </div>
      </div>
      <input
        type="text"
        name="clase"
        #clase="ngModel"
        [ngModel]="mp[i].Clase"
        (change)="setClase(clase.value, i)"
        class="form-control"
        autocomplete="off"
        [readonly]="visor || isCotizacion"
        (focusout)="setClase(clase.value, i)"
      />
      <!--  <div class="text-danger"
                *ngIf="clase.touched && (clase.value == '' || clase.value == undefined || clase.value == null)">{{'Campo obligatorio' | translate}} </div> -->
    </div>
    <div class="col-sm-1" *ngIf="!tarifa">
      <label class="col-sm-2 col-form-label">UN*</label>
    </div>
    <div class="col-sm-2" *ngIf="!tarifa">
      <input
        id="NumUn"
        name="numUn"
        [ngModel]="mp[i].Un"
        #un="ngModel"
        (change)="setUn(un.value, i)"
        type="text"
        (focusout)="setUn(un.value, i)"
        [ngClass]="{
          'form-control-custom-error':
            (un.touched &&
              (un.value == '' || un.value == undefined || un.value == null)) ||
            errorUn[i],
          'form-control':
            !(
              un.touched &&
              (un.value == '' || un.value == undefined || un.value == null)
            ) || !errorUn[i]
        }"
        autocomplete="off"
        [readonly]="visor || isCotizacion || (esTarifa && esRellenoUn)"
        maxlength="4"
      />
      <div
        class="text-danger"
        *ngIf="
          un.touched &&
          (un.value == '' || un.value == undefined || un.value == null)
        "
      >
        {{
          "Campo
                obligatorio" | translate
        }}
      </div>
      <div
        class="text-danger"
        *ngIf="
          errorUn[i] &&
          un.value != '' &&
          un.value != undefined &&
          un.value != null
        "
      >
        {{ "CodigoUnNoExisteError" | translate }}
      </div>
    </div>
    <div class="col-sm-2" *ngIf="tarifa">
      <div class="row">
        <div class="col mr45">
          <label>{{ "UN" | translate }}*</label>
        </div>
      </div>
      <input
        id="NumUn"
        name="numUn"
        [ngModel]="mp[i].Un"
        #un="ngModel"
        (change)="setUn(un.value, i)"
        type="text"
        (focusout)="setUn(un.value, i)"
        [ngClass]="{
          'form-control-custom-error':
            (un.touched &&
              (un.value == '' || un.value == undefined || un.value == null)) ||
            errorUn[i],
          'form-control':
            !(
              un.touched &&
              (un.value == '' || un.value == undefined || un.value == null)
            ) || !errorUn[i]
        }"
        autocomplete="off"
        [readonly]="visor || isCotizacion"
        maxlength="4"
      />
      <div
        class="text-danger"
        *ngIf="
          un.touched &&
          (un.value == '' || un.value == undefined || un.value == null)
        "
      >
        {{
          "Campo
                obligatorio" | translate
        }}
      </div>
      <div
        class="text-danger"
        *ngIf="
          errorUn[i] &&
          un.value != '' &&
          un.value != undefined &&
          un.value != null
        "
      >
        {{ "CodigoUnNoExisteError" | translate }}
      </div>
    </div>
    <div class="col-sm-1" *ngIf="!tarifa">
      <label class="col-sm-2 col-form-label">{{ "Bruto" | translate }}</label>
    </div>
    <div class="col-sm-2" *ngIf="!tarifa">
      <input
        type="text"
        name="pesobruto"
        #pb="ngModel"
        [ngModel]="mp[i].PesoBruto"
        (change)="setPb(pb.value, i)"
        class="form-control"
        autocomplete="off"
        [readonly]="visor || isCotizacion"
        (focusout)="setPb(pb.value, i)"
      />
    </div>
    <div class="col-sm-2" *ngIf="tarifa">
      <div class="row">
        <div class="col mr50">
          <label *ngIf="area != 3">{{ "Pg" | translate }}</label>
          <label *ngIf="area == 3">{{ "Inst" | translate }}</label>
        </div>
      </div>
      <ng-select
        name="pg"
        id="pg"
        [ngModel]="mp[i].Pg"
        (change)="setPg(pg.value, i)"
        #pg="ngModel"
        [items]="listPackingGroup"
        bindLabel="pg"
        bindValue="pg"
        [readonly]="visor"
        [ngModelOptions]="{ standalone: true }"
        aria-required="true"
      >
      </ng-select>
    </div>
    <div class="col-sm-1" *ngIf="!tarifa">
      <label class="col-sm-2 col-form-label">{{ "Neto" | translate }}*</label>
    </div>
    <div class="col-sm-2" *ngIf="!tarifa">
      <input
        type="text"
        name="pesoneto"
        #pn="ngModel"
        [ngModel]="mp[i].PesoNeto"
        (change)="setPn(pn.value, i)"
        [ngClass]="{
          'form-control-custom-error':
            pn.touched &&
            (pn.value == '' || pn.value == undefined || pn.value == null),
          'form-control': !(
            pn.touched &&
            (pn.value == '' || pn.value == undefined || pn.value == null)
          )
        }"
        autocomplete="off"
        [readonly]="visor || isCotizacion || (esTarifa && esRellenoPn)"
        (focusout)="setPn(pn.value, i)"
        class="form-control"
      />
      <div
        class="text-danger"
        *ngIf="
          area == 1 &&
          pn.touched &&
          (pn.value == '' || pn.value == undefined || pn.value == null)
        "
      >
        {{ "Campo obligatorio" | translate }}
      </div>
    </div>
    <div class="col-sm-2" *ngIf="tarifa">
      <div class="row">
        <div class="col mr45">
          <label>{{ "Neto" | translate }}*</label>
        </div>
      </div>
      <input
        type="text"
        name="pesoneto"
        #pn="ngModel"
        [ngModel]="mp[i].PesoNeto"
        (change)="setPn(pn.value, i)"
        [ngClass]="{
          'form-control-custom-error':
            pn.touched &&
            (pn.value == '' || pn.value == undefined || pn.value == null),
          'form-control': !(
            pn.touched &&
            (pn.value == '' || pn.value == undefined || pn.value == null)
          )
        }"
        autocomplete="off"
        [readonly]="visor || isCotizacion"
        (focusout)="setPn(pn.value, i)"
        class="form-control"
      />
      <div
        class="text-danger"
        *ngIf="
          area == 1 &&
          pn.touched &&
          (pn.value == '' || pn.value == undefined || pn.value == null)
        "
      >
        {{ "Campo obligatorio" | translate }}
      </div>
    </div>

    <div class="col-sm-1" *ngIf="!visor && !isCotizacion && tarifa">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
      <img
        src="assets/iconos/trash_16.png"
        alt=""
        (click)="openAlert(templateDelete, i)"
        style="cursor: pointer"
      />
    </div>
    <div class="col-sm-1" *ngIf="!visor && !isCotizacion && !tarifa">
      <img
        src="assets/iconos/trash_16.png"
        alt=""
        (click)="openAlert(templateDelete, i)"
        style="cursor: pointer"
      />
    </div>
    <div class="col-sm-1" *ngIf="(visor || isCotizacion) && !tarifa">
      <!-- <img src="assets/iconos/trash_16.png" alt="" style="cursor: pointer;"> -->
    </div>
    <div class="col-sm-1" *ngIf="(visor || isCotizacion) && tarifa">
      <div class="row">
        <div class="col"></div>
      </div>
      <!-- <img src="assets/iconos/trash_16.png" alt="" style="cursor: pointer;"> -->
    </div>
  </div>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-3">
      <button type="button" class="btn" (click)="addRow()" [disabled]="visor">
        <img src="assets/iconos/plusAdd_16.png" alt="" />
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8"></div>
    <div class="col-sm-2">
      <button
        style="float: right; margin: 10px"
        type="button"
        class="btn"
        (click)="enviarUn()"
        [disabled]="visor || isCotizacion"
      >
        {{ "Aceptar" | translate }}
      </button>
    </div>
    <div class="col-sm-2">
      <button
        style="float: right; margin: 10px"
        type="button"
        class="btn"
        (click)="cerrar()"
      >
        {{ "Cerrar" | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #templateDelete>
  <div class="modal-content fondo">
    <div class="modal-header fondo">
      <h2 class="modal-title pull-left">
        {{ "Confirmación de borrado" | translate }}
      </h2>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body fondo">
      <app-confirmacion-borrado
        (confirmEmit)="obtenerConfirmacion($event)"
      ></app-confirmacion-borrado>
    </div>
  </div>
</ng-template>
