import { Component, ElementRef, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Mercancia } from 'src/app/models/mercancia.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Archivo } from 'src/app/models/archivo.model';
import { ToastrService } from 'ngx-toastr';
import { Incoterms } from 'src/app/models/incoterms.model';
import { Puerto } from 'src/app/models/puerto.model';
import { Naviera } from 'src/app/models/naviera.model';
import { Direccion } from 'src/app/models/direccion.model';
import { Paises } from 'src/app/models/paises.model';
import { Localidades } from 'src/app/models/localidades.model';
import { Modoenvio } from 'src/app/models/modoenvio.model';
import { Shipment } from 'src/app/models/shipment.model';
import { Router } from '@angular/router';
import { Contenedor } from 'src/app/models/contenedor.model';
import { TipoEnvio } from 'src/app/models/tipo-envio.model';
import { TipoDivisa } from 'src/app/models/tipo-divisa.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from 'src/app/models/entity.model';
import { LogModel } from 'src/app/models/log-model.model';
import { DatosContacto } from 'src/app/models/datos-contacto.model';
import { UsuarioService } from 'src/app/services/usuario.service';
import { EntityService } from 'src/app/services/entity.service';
import { ConfigEntidad } from 'src/app/models/config-entidad.model';
import { MatDialog } from '@angular/material/dialog';
import { WaitingComponent } from 'src/app/components/waiting/waiting.component';
import { FileShipService } from 'src/app/services/file-ship.service';
import { Corresponsal } from 'src/app/models/corresponsal.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { MercanciaService } from 'src/app/services/mercancia.service';
import { Codigosun } from 'src/app/models/codigosun.model';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ContenedorMercancia } from 'src/app/models/contenedorMercancia.model';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { CargoComponent } from '../cargo/cargo.component';
import { TarifaService } from 'src/app/services/tarifaservice.service';
import { TarifaPost } from 'src/app/models/tarifaPost';
import { TarifaEnvio } from 'src/app/models/tarifaEnvio';
import { MercanciaPeligrosa } from 'src/app/models/mercancia-peligrosa.model';

@Component({
  selector: 'app-maritimo-shipment',
  templateUrl: './maritimo-shipment.component.html',
  styleUrls: ['./maritimo-shipment.component.scss']
})
export class MaritimoShipmentComponent implements OnInit {

  @ViewChild('TipoEnvio') tipoEnvio: ElementRef;
  @ViewChild('Referencia') referencia: ElementRef;
  @ViewChild('ListShipper') listShipperElement: ElementRef;
  @ViewChild('ListConsignee') listConsigneeElement: ElementRef;
  @ViewChild('Incoterm') incoterm: ElementRef;
  @ViewChild('ValorMercancia') valorMercancia: ElementRef;
  @ViewChild('EntidadRecogida') entidadRecogida: ElementRef;
  @ViewChild('FechaRecogida') fechaRecogida: ElementRef;
  @ViewChild('HoraRecogidaInicio1') horaRecogidaInicio1: ElementRef;
  @ViewChild('HoraRecogidaFinal1') horaRecogidaFinal1: ElementRef;
  @ViewChild('PersonaContactoRecogida') personaContactoRecogida: ElementRef;
  @ViewChild('TelefonoContactoRecogida') telefonoContactoRecogida: ElementRef;
  @ViewChild('EmailRecogida') emailRecogida: ElementRef;
  @ViewChild('EntidadEntrega') entidadEntrega: ElementRef;
  @ViewChild(CargoComponent) appCargo: CargoComponent;
  @Input() esTarifa: boolean;

  tarifa: TarifaEnvio;
  tarifaFiltrada: TarifaPost;
  clase: string = "app-maritimo-shipment";
  visor: boolean;
  texto: string = "";
  editar: boolean;
  isCotizacion: boolean = false;
  duplicar: boolean;
  shipmentMaritimo: Shipment;
  form: NgForm;
  error: boolean = false;
  errorPesoBruto: boolean = false;
  errorPesoNeto: boolean = false;
  errorPesoNetoVacio: boolean = false;
  errorPesoBrutoVacio: boolean = false;
  errorPesoNetoDGR: boolean = false;
  errorPesoNetoTotalDGRdistinto: boolean = false
  haypuertoOrigen: boolean

  partida: string;
  expedicion: string;
  errorInstruccionesBLAWB: boolean = false;
  archivoBooking: Archivo = new Archivo();
  errorFechaRecogidaMenorActual: Boolean = false;
  today: Date = new Date();
  totalNetoDgrLineas: number

  formModelMaritimo = new FormControl({
    Id: null,
    Envio: 'MARITIMO',
    ModoEnvio: 1,
    TipoEnvio: 1,
    Cliente: '',
    IdArea: 2,
    Referencia: '',
    Shipper: '',
    DireccionShipper: '',
    Consignee: '',
    DireccionConsignee: '',
    Notify: '',
    DireccionNotify: '',
    Incoterm: '',
    ValorMercancia: '',
    TipoDivisa: '',
    SeguroMercancia: '',
    Asegurado: false,
    PuertoOrigen: '',
    PuertoDestino: '',
    Naviera: '',
    Refrigerada: '',
    Perecedero: '',
    //NoRemontable: false,
    Fumigado: '',
    EntidadRecogida: null, //Recogida
    ReferenciaRecogida: '',
    DireccionRecogida: null,
    FechaRecogida: '',
    InstruccionesRecogida: '',
    HoraRecogidaInicio1: '',
    HoraRecogidaFinal1: '',
    HoraRecogidaInicio2: '',
    HoraRecogidaFinal2: '',
    PersonaContactoRecogida: '',
    TelefonoRecogida: '',
    EmailRecogida: '',
    DespachoOrigen: '', //Despacho Aduanas
    DespachoDestino: '',
    EntidadEntrega: null, //Entrega
    DireccionEntrega: null,
    ReferenciaEntrega: '',
    InstruccionesEntrega: '',
    PersonaContactoEntrega: '',
    TelefonoEntrega: '',
    EmailEntrega: '',
    MercanciaPeligrosa: false,
    Mercancia: [],
    PesoNetoTotalDGR: null,
    PesoBrutoTotalDGR: null,
    BultosTotales: null,
    PesoNetoTotal: null,
    PesoBrutoTotal: null,
    PesoTasable: null,
    VolumenTotal: null,
    InstruccionesBLAWB: '',
    Marcas: '',
    Instrucciones: '',
    Archivo: [],
    Cotizacion: 0,
    MercanciaContenedor: null,
    ContenedoresTotales: 0,
    PesoBrutoContenedorTotal: 0,
    PesoNetoContenedorTotalDGR: 0,
    PesoBrutoContenedorTotalDGR: 0,
    Tarifa: null
  });

  bultos: number = 0;
  pesoNeto: number = 0;
  pesoBruto: number = 0;
  volumen: number = 0;
  segundos = 5000;
  listTipoEnvio: TipoEnvio[] = [];
  listShipper: Entity[] = [];
  listConsignee: Entity[] = [];
  listNotify: Entity[] = [];
  readonlyDireccionShipper: boolean = false;
  readonlyDireccionConsignee: boolean = false;
  readonlyDireccionNotify: boolean = false;
  /*
  ShipperInformation: String = '';
  ConsigneeInformation: String = '';
  NotifyInformation: String = '';
  */
  resumenDireccionRecogida: String = '';
  resumenDireccionEntrega: String = '';
  tipoDireccion: string = '';

  listModoEnvio: Modoenvio[];
  listIncoterm: Incoterms[];
  listPuertoOrigen: Puerto[];
  listPuertoOrigenFiltrado: Puerto[];
  listPuertoDestino: Puerto[];
  listPuertoDestinoFiltrado: Puerto[];
  listNaviera: Naviera[];
  listContenedor: Contenedor[];
  listaMercanciaContenedor: ContenedorMercancia[] = [];
  listaMercancia: Mercancia[] = []; //pasado por parametro a cargo
  listaArchivos: Archivo[] = []; //pasarlo en post
  cargo: Mercancia = new Mercancia(); //pasarlo en post
  auxVol: number;
  errorBrutoTotal = false;
  paises: Paises[] = [];
  localidades: Localidades[] = [];
  idEnvio: number;
  envio: Shipment; //pasarlo en post
  naviera: String;
  contenedor: String;
  direccionRecogida: Direccion;
  direccionEntrega: Direccion;
  EntidadRecogidaInformation: String;
  listDivisas: TipoDivisa[];
  mostrarEntrega: Boolean;
  modalRef: BsModalRef | null;
  referenciaClienteAnterior: string;
  searchText: string;
  tipoEntidad: string;
  esFCL: Boolean;
  esLCL: Boolean;
  errorTipoEnvio: Boolean = false;
  errorReferencia: Boolean = false;
  errorListShipper: Boolean = false;
  errorListConsignee: Boolean = false;
  errorIncoterm: Boolean = false;
  errorBultosTotales: Boolean = false;
  errorPersonaContactoRecogida: Boolean = false;
  errorEmailRecogida: Boolean = false;
  errorPesoNetoTotal: Boolean = false;
  errorPesoBrutoTotal: Boolean = false;
  errorPesoBrutoTotalDGR: Boolean = false;
  errorPesoNetoTotalDGR: Boolean = false;
  errorContenedoresTotales: Boolean = false;
  errorPesoBrutoContenedorTotal: Boolean = false;
  errorPesoNetoContenedorTotalDGR: Boolean = false;
  muestraErrorNetoContenedorDGR: Boolean = false;
  //errorDiferenciaPesosDGR: Boolean = false;
  muestraErrorBrutoDGR: Boolean = false;
  muestraErrorNetoDGR: Boolean = false;
  errorVolumenTotal: Boolean = false;
  errorTelefonoRecogida: Boolean = false;
  errorFechaRecogida: Boolean = false;
  errorHoraRecogidaInicio1: Boolean = false;
  errorHoraRecogidaFinal1: Boolean = false;
  errorMercancia: Boolean = false;
  errorPersonaContactoEntrega: Boolean = false;
  errorEmailEntrega: Boolean = false;
  errorTelefonoEntrega: Boolean = false;
  errorEntidadRecogida: Boolean = false;
  errorEntidadEntrega: Boolean = false;
  errorValorMercancia: Boolean = false;
  errorReferenciaEntrega: Boolean = false;
  errorReferenciaRecogida: Boolean = false;
  errorModoEnvio: Boolean = false;
  confirmSeguro: Boolean = false;
  cargaTipoEnvio: boolean = true;
  cargaRemitente: boolean = true;
  cargaDestinatario: boolean = true;
  cargaNotify: boolean = false;
  cargaIncoterm: boolean = true;
  cargaTipoDivisa: boolean = true;
  cargaAeropuerto: boolean = true;
  cargaEntidadRecogida: boolean = true;
  cargaEntidadEntrega: boolean = true;
  cargaNavieras: boolean = true;
  cargaContenedor: boolean = true;
  cargaModoEnvio: boolean = true;
  title = 'angular-9-i18n';
  langs = ['es', 'en'];
  entidad: Entity;
  entidadCliente: number;
  datosContacto: DatosContacto;
  configEntidad: ConfigEntidad;
  refCliDuplicado: Boolean = false;
  chShipper: Boolean = false;
  chConsignee: Boolean = false;
  chNotify: Boolean = false;
  corresponsal: Corresponsal;
  cotizacion: string;
  maxlengthInstrucctions: number = 255;
  maxlengthObservaciones: number = 500;
  maxlengthMarcas: number = 255;
  maxlengthInstruccionesBLAWB: number = 500;
  codigosUn: Codigosun[] = [];
  esBooking: boolean = true;

  constructor(private envioService: ShipmentService, private router: Router, private toastr: ToastrService, private mercanciaService: MercanciaService,
    private modalService: BsModalService, private translateService: TranslateService, private usuarioService: UsuarioService, private cotizacionService: CotizacionService,
    private entidadService: EntityService, public dialog: MatDialog, private fileService: FileShipService, private configurationService: ConfigurationService, private tarifaService: TarifaService) {
    this.confirmSeguro = false;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.isCotizacion = this.envioService.getCotizacionBoolean();
  }

  //Funcion que se ejecuta al iniciar el componente cada vez que se abre
  ngOnInit(): void {
    this.inicializar();

    this.tarifa = this.tarifaService.tarifa
    if (this.tarifa != null && this.tarifa != undefined) {
      this.formModelMaritimo.value.Tarifa = this.tarifaService.tarifaFiltrada;
      this.setTarifa();
    }
    else
      this.formModelMaritimo.value.Tarifa = null;
  }

  ngOnDestroy() {
    this.tarifaService.tarifa = this.tarifaService.tarifaCotizacion = this.tarifaService.tarifaFiltrada = null;
  }

  inicializar() {
    this.formModelMaritimo = new FormControl({
      Id: null,
      Envio: 'MARITIMO',
      ModoEnvio: 1,
      TipoEnvio: 1,
      Cliente: '',
      IdArea: 2,
      Referencia: '',
      Shipper: '',
      DireccionShipper: '',
      Consignee: '',
      DireccionConsignee: '',
      Notify: '',
      DireccionNotify: '',
      Incoterm: '',
      ValorMercancia: '',
      TipoDivisa: '',
      SeguroMercancia: '',
      Asegurado: false,
      PuertoOrigen: '',
      PuertoDestino: '',
      Naviera: '',
      Refrigerada: '',
      Perecedero: '',
      //NoRemontable: false,
      Fumigado: '',
      EntidadRecogida: null, //Recogida
      ReferenciaRecogida: '',
      DireccionRecogida: null,
      FechaRecogida: '',
      InstruccionesRecogida: '',
      HoraRecogidaInicio1: '',
      HoraRecogidaFinal1: '',
      HoraRecogidaInicio2: '',
      HoraRecogidaFinal2: '',
      PersonaContactoRecogida: '',
      TelefonoRecogida: '',
      EmailRecogida: '',
      DespachoOrigen: '', //Despacho Aduanas
      DespachoDestino: '',
      EntidadEntrega: null, //Entrega
      DireccionEntrega: null,
      ReferenciaEntrega: '',
      InstruccionesEntrega: '',
      PersonaContactoEntrega: '',
      TelefonoEntrega: '',
      EmailEntrega: '',
      MercanciaPeligrosa: false,
      Mercancia: [],
      PesoNetoTotalDGR: null,
      PesoBrutoTotalDGR: null,
      BultosTotales: null,
      PesoNetoTotal: null,
      PesoBrutoTotal: null,
      PesoTasable: null,
      VolumenTotal: null,
      InstruccionesBLAWB: '',
      Marcas: '',
      Instrucciones: '',
      Archivo: [],
      Cotizacion: 0,
      VGMPesaje: null,
      VGMTransmision: null,
      RefCotizacion: null
    });

    this.confirmSeguro = false;
    this.visor = false;
    this.editar = false;
    this.duplicar = false;
    this.error = false;
    this.cargarIdioma();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.error = false;
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.entidad = null;
    this.datosContacto = new DatosContacto();

    this.texto = this.translateService.instant("CancelarShipmentTexto");
    this.entidad = null;
    this.datosContacto = new DatosContacto();

    document.getElementById("Referencia").focus();
    this.tipoEntidad = '';
    this.referenciaClienteAnterior = '';
    this.visor = this.envioService.getVisor();
    this.editar = this.envioService.getEditar();
    this.duplicar = this.envioService.getDuplicar();
    this.inicializarDatos();
    this.cargarEnvio();
    this.cargarIdioma();
    this.compruebaFcl();
    this.cargarTipoDivisa();
    this.getEntidad();
    this.getShipper();
    this.cargarListaConsignee();
    this.cargarModoEnvio();
    this.cargarTipoContenedor();
    this.cargarPaises();
    this.cargarTipoEnvio();
    this.cargarIncoterms();
    this.cargarPuertos();
    this.cargarDatosContactoUsuario();
    this.obtenerCodigosUN();
    //this.cargarNavieras();
    this.cargarHorario();
    this.cargarConfigEntidad();
    this.getCorresponsal();
    if (this.appCargo != undefined && this.appCargo != null)
      this.appCargo.ngOnInit();
  }

  cargarHorario() {
    this.configurationService.getHorarios$().subscribe(horarios => {
      this.formModelMaritimo.value.HoraRecogidaInicio1 = horarios.he1;

      this.formModelMaritimo.value.HoraRecogidaInicio2 = horarios.he2;
      this.formModelMaritimo.value.HoraRecogidaFinal1 = horarios.he3;
      this.formModelMaritimo.value.HoraRecogidaFinal2 = horarios.he4;
      this.formModelMaritimo.value.HoraEntregaInicio1 = horarios.hr1;
      this.formModelMaritimo.value.HoraEntregaInicio2 = horarios.hr2;
      this.formModelMaritimo.value.HoraEntregaFinal1 = horarios.hr3;
      this.formModelMaritimo.value.HoraEntregaFinal2 = horarios.hr4;
    });
    this.configurationService.getHorarios();
  }

  isUpdate() {
    return this.tarifa != null && this.tarifa != undefined && (this.tarifa.IdPuertoDestino != undefined || this.tarifa.IdPuertoOrigen != undefined);
  }

  setTarifa() {
    if (this.isUpdate()) {
      this.esTarifa = true;
      this.formModelMaritimo.value.TipoEnvio = this.tarifa.IdTipoEnvio;
      this.formModelMaritimo.value.ModoEnvio = this.tarifa.IdModoEnvio;
      this.formModelMaritimo.value.Naviera = this.tarifa.IdNaviera;
      if (this.formModelMaritimo.value.PuertoOrigen != '') {
        this.formModelMaritimo.value.PuertoOrigen = this.tarifa.IdPuertoOrigen;
        this.haypuertoOrigen = true;

      }
      else
        this.haypuertoOrigen = false;
      this.formModelMaritimo.value.PuertoDestino = this.tarifa.IdPuertoDestino;
      if (this.tarifa.IdModoEnvio == 1) {
        this.listaMercContenedor(this.tarifa.MercanciaContenedor);
        this.esFCL = true;
        this.esLCL = false;
      }
      else {
        this.esFCL = false;
        this.esLCL = true;
        this.listaMerc(this.tarifa.Mercancia);
      }
      this.formModelMaritimo.value.PesoBrutoTotal = this.tarifa.PesoBrutoTotal;
      this.formModelMaritimo.value.PesoNetoTotal = this.tarifa.PesoNetoTotal;
      this.formModelMaritimo.value.VolumenTotal = this.tarifa.VolumenTotal;
      this.formModelMaritimo.value.PesoTasable = this.tarifa.PesoTasable;
      this.formModelMaritimo.value.BultosTotales = this.tarifa.Bultos;
      this.formModelMaritimo.value.PesoBrutoTotalDGR = this.tarifa.PesoBrutoTotalDGR;
      this.formModelMaritimo.value.PesoNetoTotalDGR = this.tarifa.PesoNetoTotalDGR;
    }

  }

  obtenerCodigosUN() {
    this.mercanciaService.getCodigosUN().subscribe(data => this.codigosUn = data);
  }

  getCorresponsal() {
    this.entidadService.getCorresponsal().subscribe(data => {
      this.corresponsal = data;
    });
  }

  cargarConfigEntidad() {
    this.entidadService.getConfigEntidad().subscribe(data => {
      this.configEntidad = data;
      if (this.listIncoterm.find(i => i.IdIncoterm == this.configEntidad.IncotermMaritimo))
        this.formModelMaritimo.value.Incoterm = this.configEntidad.IncotermMaritimo;

      //this.formModelMaritimo.value.MercanciaPeligrosa = this.configEntidad.MercanciaPeligrosa;
    },
      error => {

      })
  }

  cargarDatosContactoUsuario() {
    this.usuarioService.getDatosContacto().subscribe(data => {
      this.datosContacto = data;
      this.formModelMaritimo.value.PersonaContactoRecogida = this.datosContacto.NombreUsuario;
      this.formModelMaritimo.value.TelefonoRecogida = this.datosContacto.Telefono;
      this.formModelMaritimo.value.EmailRecogida = this.datosContacto.Email;
    }, error => {
      //No tiene todos los datos de contacto
    })
  }

  getEntidad() {
    this.envioService.getEntidadUsuario().subscribe(entidad => {
      this.entidadCliente = entidad;
    })
  }

  cargarIdioma() {
    let browserlang = this.translateService.getBrowserLang();
    let lang: string = this.translateService.getDefaultLang();

    if (this.langs.find(e => e == lang)) {
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    }
    else {
      this.translateService.setDefaultLang('es');
    }
  }

  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
  }

  compruebaFcl() {
    if (this.formModelMaritimo.value.ModoEnvio == 1) {
      this.esFCL = true;
    }
    else {
      this.esFCL = false;
    }
  }


  cargarEnvio() {
    this.partida = this.envioService.getPartida();
    this.expedicion = this.envioService.getExpedicion();
    this.cotizacion = this.envioService.getCotizacion();

    if (this.partida != null && this.partida != undefined && this.partida != "") {
      this.envioService.getShipmentByPartidaExpedicion(this.partida).subscribe(data => {
        this.envio = data;
        this.asignarValores(this.envio);
        this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    } else if (this.expedicion != null && this.expedicion != undefined && this.expedicion != "") {
      this.envioService.getShipmentByPartidaExpedicion(this.expedicion).subscribe(data => {
        this.envio = data;
        this.asignarValores(this.envio);
        this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    } else if (this.tarifa != null && this.tarifa != undefined) {
      // this.asignarValores(this.tarifa);
      this.listaMercanciaContenedor = this.tarifa.MercanciaContenedor;
      this.listaMercancia = this.tarifa.Mercancia;

      this.listaArchivos = [];
    } else if (this.cotizacion != null && this.cotizacion != undefined && this.cotizacion != "") {
      this.cotizacionService.getBooking(this.cotizacion, null).subscribe(data => {
        this.envio = data;
        this.envio.IdCotizacion = this.cotizacion;
        this.envioService.setCotizacionBoolean(true);
        this.asignarValores(this.envio);
        //this.listaMercancia = this.envio.Mercancia;
        this.listaArchivos = [];
      });
    }
    // this.idEnvio = this.envioService.getIdEnvio();
    // if(this.idEnvio != -1)
    // {
    //   this.envioService.getEnvio(this.idEnvio).subscribe(data => {
    //     this.envio = data;
    //     this.listaMercancia = this.envio.Mercancia;
    //     this.listaArchivos = [];
    //     this.asignarValores(this.envio)
    //   });
    // }
  }

  cargarTipoDivisa() {
    this.envioService.getTiposDivisa().subscribe(data => {
      this.cargaTipoDivisa = true;
      this.listDivisas = data;
      this.cargaTipoDivisa = false;
      this.formModelMaritimo.value.TipoDivisa = this.listDivisas[0].IdTipoDivisa;
    })
  }

  cargarListaConsignee() {
    // this.envioService.getConsignee(this.esExport()).subscribe(data => {
    //   this.cargaDestinatario = true;
    //   this.cargaEntidadEntrega = true;
    //   this.listConsignee = data;
    //   this.cargaDestinatario = false;
    //   this.cargaEntidadEntrega = false;

    //   if(this.idEnvio != 1) {
    //     this.visor = this.envioService.getVisor();
    //     if(this.envio != undefined)
    //     {
    //       this.asignarValores(this.envio);
    //     }
    //   }

    // });
  }

  cargarListaNotify() {
    if (this.formModelMaritimo.value.Consignee != undefined && this.formModelMaritimo.value.Consignee != null) {
      this.envioService.getNotify(this.formModelMaritimo.value.Consignee.IdEntidad).subscribe(data => {
        this.cargaNotify = true;
        this.listNotify = data;
        this.cargaNotify = false;
      });
    }
  }

  cargarModoEnvio() {
    this.envioService.getModoEnvioByArea(2).subscribe(data => {
      this.cargaModoEnvio = true;
      this.listModoEnvio = data;
      this.cargaModoEnvio = false;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio)
        }
      }

    });
  }

  cargarTipoContenedor() {
    this.envioService.getTipoContenedor().subscribe(data => {
      this.cargaContenedor = true;
      this.listContenedor = data;
      this.cargaContenedor = false;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    });
  }

  cargarPaises() {
    this.envioService.getPaises().subscribe(data => {
      this.paises = data;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    });
  }

  cargarLocalidades() {
    this.envioService.getLocalidades().subscribe(data => {
      this.localidades = data;
    });
  }

  cargarTipoEnvio() {
    this.envioService.getTiposEnvio(2).subscribe(data => {
      this.cargaTipoEnvio = true;
      this.listTipoEnvio = data;
      this.cargaTipoEnvio = false;
    });
  }

  cargarIncoterms() {
    this.envioService.getIncoterms(this.formModelMaritimo.value.IdArea).subscribe(data => {
      this.cargaIncoterm = true;
      this.listIncoterm = data;
      this.cargaIncoterm = false;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    })
  }

  cargarPuertos() {
    this.envioService.getPuertos().subscribe(data => {
      this.cargaAeropuerto = true;
      this.listPuertoDestino = data;
      this.listPuertoOrigen = data;
      this.cargaAeropuerto = true;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    })
  }

  cargarNavieras() {
    this.envioService.getNavieras().subscribe(data => {
      this.cargaNavieras = true;
      this.listNaviera = data;
      this.cargaNavieras = false;

      if (this.idEnvio != 1) {
        this.visor = this.envioService.getVisor();
        if (this.envio != undefined) {
          this.asignarValores(this.envio);
        }
      }

    });
  }

  getShipper() {
    this.envioService.getEntidadesAddressBook().subscribe(data => {
      this.entidadService.getEntidadById(this.entidadCliente).subscribe(entidadCliente => {
        this.listShipper = data;
        this.listConsignee = data;
        if (this.esExport()) {
          this.listShipper.push(entidadCliente);
          // this.listShipper.forEach(shipper => {
          this.formModelMaritimo.value.Shipper = entidadCliente;
          this.formModelMaritimo.value.EntidadRecogida = entidadCliente;
          this.formModelMaritimo.value.Cliente = entidadCliente;
          this.setShipperInformation();
          // });
        }
        else {
          this.listConsignee.push(entidadCliente);
          // this.listConsignee.forEach(consignee => {
          this.formModelMaritimo.value.Consignee = entidadCliente;
          this.formModelMaritimo.value.EntidadEntrega = entidadCliente;
          this.formModelMaritimo.value.Cliente = entidadCliente;
          this.setConsigneeInformation();
          // });
        }
        if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
          this.visor = this.envioService.getVisor();
          if (this.envio != undefined) {
            this.asignarValores(this.envio);
          }
        } else if (this.partida != null && this.partida != undefined) {
          this.asignarValores(this.envio);
        }
        this.cargaRemitente = this.cargaDestinatario = false;
        this.cargaEntidadRecogida = this.cargaEntidadEntrega = false;

        this.inicializarDatos();
      });
    });
  }


  //Funcion que comprueba que si se marca la casilla de peligrosa, tenga mercancia peligrosa
  compruebaMercanciaPeligrosa(): Boolean {
    let count = 0;
    if (this.shipmentMaritimo.MercanciaPeligrosa == 1) {
      if (!this.esFCL) {
        this.shipmentMaritimo.Mercancia.forEach(mercancia => {
          if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length >= 0)
            count++;
        });
      } else {
        this.shipmentMaritimo.MercanciaContenedor.forEach(contenedor => {
          contenedor.Mercancia.forEach(mercancia => {
            if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length >= 0)
              count++;
          });
        });
      }

    } else
      count = 1;

    return count != 0;
  }

  compruebaIncotermEntrega() {
    let correcto = true;
    if (this.formModelMaritimo.value.Incoterm.startsWith('D')) {
      if (this.formModelMaritimo.value.IdEntidadEntrega != null && this.formModelMaritimo.value.IdEntidadEntrega != undefined && this.formModelMaritimo.value.IdEntidadEntrega != ''
        && this.formModelMaritimo.value.IdDireccionEntrega != null && this.formModelMaritimo.value.IdDireccionEntrega != undefined && this.formModelMaritimo.value.IdDireccionEntrega != '') {
        correcto = false;
      }
    }
    return correcto;
  }

  compruebaErrores(form: NgForm) {
    this.compruebaTipoEnvio(form); this.compruebaReferencia(form); this.compruebaListShipper(form); this.compruebaListConsignee(form);
    this.compruebaIncoterm(form); this.compruebaBultosTotales(form); this.compruebaEmailEntrega(form); this.compruebaEmailRecogida(form);
    this.compruebaMercancia(); this.compruebaPersonaContactoEntrega(form); this.compruebaPersonaContactoRecogida(form); this.compruebaInstruccionesBLAWB(form);
    this.compruebaTelefonoEntrega(form); this.compruebaTelefonoRecogida(form); this.compruebaPesoBrutoTotal(form);
    this.compruebaPesoNetoTotal(form); this.compruebaFechaRecogida(form); this.compruebaEntidadEntrega(form); this.compruebaPesoNetoTotalDGR(form);
    this.compruebaEntidadRecogida(form); this.compruebaValorMercancia(form); this.compruebaReferenciaEntrega(form); this.compruebaReferenciaRecogida(form);
    /*this.compruebaPesoBrutoTotalDGR(form);*/
    if (!this.esFCL) {
      this.compruebaVolumenTotal(form);
    }
  }

  envioMaritimoOk(form: NgForm) {
    if (!this.esFCL) {
      return (!this.compruebaTipoEnvio(form) && !this.compruebaReferencia(form) && !this.compruebaListShipper(form) && !this.compruebaListConsignee(form)
        && !this.compruebaIncoterm(form) && !this.compruebaBultosTotales(form) && !this.compruebaEmailRecogida(form) && !this.compruebaMercancia()
        && !this.compruebaPersonaContactoRecogida(form) && !this.compruebaTelefonoRecogida(form) && !this.compruebaInstruccionesBLAWB(form)
        && !this.compruebaEntidadRecogida(form) && !this.compruebaPesoNetoTotal(form) && !this.compruebaVolumenTotal(form)
        && !this.compruebaFechaRecogida(form) && !this.compruebaValorMercancia(form));
    }
    else {
      return (!this.compruebaTipoEnvio(form) && !this.compruebaReferencia(form) && !this.compruebaListShipper(form) && !this.compruebaListConsignee(form)
        && !this.compruebaIncoterm(form) && !this.compruebaInstruccionesBLAWB(form) && /*!this.compruebaBultosTotales(form) &&*/ !this.compruebaEmailRecogida(form) && !this.compruebaMercancia()
        && !this.compruebaPersonaContactoRecogida(form) && !this.compruebaTelefonoRecogida(form)
        && !this.compruebaEntidadRecogida(form) && !this.compruebaPesoNetoTotal(form) && !this.compruebaFechaRecogida(form) && !this.compruebaValorMercancia(form));
    }
  }

  inicializarDatos() {
    //this.formModelMaritimo.value.TipoEnvio = 1;
    if (this.listDivisas != null && this.listDivisas != undefined && this.listDivisas.length != 0)
      this.formModelMaritimo.value.TipoDivisa = this.listDivisas[0].IdTipoDivisa;
    this.formModelMaritimo.value.Asegurado = false;
    //this.formModelMaritimo.value.NoRemontable = false;
  }

  vaciarForm(form: NgForm) {
    this.formModelMaritimo.value.DireccionShipper = '';
    this.EntidadRecogidaInformation = '';
    this.formModelMaritimo.value.BultosTotales = '';
    this.formModelMaritimo.value.PesoNetoTotal = '';
    this.formModelMaritimo.value.PesoBrutoTotal = '';
    this.formModelMaritimo.value.VolumenTotal = '';
    this.formModelMaritimo.value.PesoNetoTotalDGR = '';
    this.formModelMaritimo.value.PesoBrutoTotalDGR = '';
    this.formModelMaritimo.value.DireccionConsignee = '';
    this.shipmentMaritimo = new Shipment();
    this.formModelMaritimo.value.DireccionNotity = '';
    this.listaMercancia = [];
    this.formModelMaritimo.value.Mercancia = [];
    this.formModelMaritimo.value.EntidadEntrega = null;
    this.formModelMaritimo.value.EntidadRecogida = null;
    this.formModelMaritimo.value.ReferenciaEntrega = '';
    this.formModelMaritimo.value.MercanciaPeligrosa = false;
    this.formModelMaritimo.value.chShipper = false;
    this.formModelMaritimo.value.chConsignee = false;
    this.formModelMaritimo.value.chNotify = false;
    this.formModelMaritimo = new FormControl({
      Id: null,
      Envio: 'MARITIMO',
      ModoEnvio: 1,
      TipoEnvio: 1,
      Cliente: '',
      IdArea: 2,
      Referencia: '',
      Shipper: '',
      DireccionShipper: '',
      Consignee: '',
      DireccionConsignee: '',
      Notify: '',
      DireccionNotify: '',
      Incoterm: '',
      ValorMercancia: '',
      TipoDivisa: '',
      SeguroMercancia: '',
      Asegurado: false,
      PuertoOrigen: '',
      PuertoDestino: '',
      Naviera: '',
      Refrigerada: '',
      Perecedero: '',
      //NoRemontable: false,
      Fumigado: '',
      EntidadRecogida: null, //Recogida
      ReferenciaRecogida: '',
      DireccionRecogida: null,
      FechaRecogida: '',
      InstruccionesRecogida: '',
      HoraRecogidaInicio1: '',
      HoraRecogidaFinal1: '',
      HoraRecogidaInicio2: '',
      HoraRecogidaFinal2: '',
      PersonaContactoRecogida: '',
      TelefonoRecogida: '',
      EmailRecogida: '',
      DespachoOrigen: '', //Despacho Aduanas
      DespachoDestino: '',
      EntidadEntrega: null, //Entrega
      DireccionEntrega: null,
      ReferenciaEntrega: '',
      InstruccionesEntrega: '',
      PersonaContactoEntrega: '',
      TelefonoEntrega: '',
      EmailEntrega: '',
      MercanciaPeligrosa: false,
      Mercancia: [],
      PesoNetoTotalDGR: null,
      PesoBrutoTotalDGR: null,
      BultosTotales: null,
      PesoNetoTotal: null,
      PesoBrutoTotal: null,
      PesoTasable: null,
      VolumenTotal: null,
      InstruccionesBLAWB: '',
      Marcas: '',
      Instrucciones: '',
      Archivo: [],
      RefCotizacion: null
    });
    this.cargarListaConsignee();
    this.getShipper();
    this.cargarDatosContactoUsuario();
    this.cargarConfigEntidad();
  }

  peticionEnvio(form: NgForm) {
    if (!this.comprobarErrores()) {
      if (this.compruebaIncotermEntrega()) {
        this.convertFormToModel(form);
        if (this.compruebaMercanciaPeligrosa() && this.compruebaPesoNetoTotalDGRIgual()) {
          if (this.editar) {
            this.shipmentMaritimo.IdEnvio = this.idEnvio;
            var log: LogModel = new LogModel();
            log.Click = "modificarShipment";
            log.Pantalla = this.clase;
            const dialogRef = this.dialog.open(WaitingComponent, {
              width: '250px',
              panelClass: 'my-panel',
              disableClose: true
            });
            dialogRef.afterOpened().subscribe(result => { });
            this.envioService.putEnvioMaritimo(this.shipmentMaritimo, this.idEnvio, log).subscribe(data => {
              dialogRef.close();
              var Body = this.translateService.instant('El envío se ha modificado correctamente');
              var Titulo = this.translateService.instant('Envío correcto');
              this.toastr.success(Body, Titulo)
              this.envio = null;
              this.envioService.setIdEnvio(-1);
              this.idEnvio = -1;
              form.resetForm();
              this.inicializar();
            },
              error => {
                dialogRef.close();
                var Body = this.translateService.instant('Ha habido un problema al actualizar el envío.');
                var Titulo = this.translateService.instant('Error en el envío');
                this.toastr.error(Body, Titulo)
              });
          }
          else {
            var log: LogModel = new LogModel();
            log.Click = "enviarShipment";
            log.Pantalla = this.clase;
            const dialogRef = this.dialog.open(WaitingComponent, {
              width: '250px',
              panelClass: 'my-panel',
              disableClose: true
            });
            dialogRef.afterOpened().subscribe(result => { });
            var archivos = this.shipmentMaritimo.Archivos;
            if (this.archivoBooking.Base64image != null && this.archivoBooking.Base64image != undefined && this.archivoBooking.Base64image != '')
              archivos.push(this.archivoBooking);
            this.shipmentMaritimo.Archivos = [];
            this.envioService.postEnvioMaritimo(this.shipmentMaritimo, log, this.translateService.getDefaultLang().toString()).subscribe(data => {
              var total = 0;
              var enviarArchivos = [];
              archivos.forEach(a => {
                var envioDevuelto = data as Shipment;
                a.IdEnvio = envioDevuelto.IdEnvio;
                if (total + a.Tamanyo <= 25000000) { // 25 MB
                  total += a.Tamanyo;
                  enviarArchivos.push(a);
                } else {
                  this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
                    // REINTENTAMOS PARA VOLVER A SUBIRLOS.
                    this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => { }, err => {
                      var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con nosotros.');
                      var Titulo = this.translateService.instant('Error en los documentos');
                      this.toastr.error(Body, Titulo)
                    })
                  })
                  enviarArchivos = [];
                  enviarArchivos.push(a);
                  total = 0;
                  total += a.Tamanyo;
                }
              })
              if (enviarArchivos.length > 0) {
                this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
                  dialogRef.close();
                  var Body = this.translateService.instant('El envío se ha realizado correctamente');
                  var Titulo = this.translateService.instant('Envío correcto');
                  this.toastr.success(Body, Titulo)
                  this.envio = null;
                  this.envioService.setIdEnvio(-1);
                  this.idEnvio = -1;
                  setTimeout(function () { window.location.reload() }, this.segundos);
                  form.resetForm();
                  this.inicializar();
                }, err => {
                  this.fileService.setDocumentosEnvio(enviarArchivos, log).subscribe(data => {
                    dialogRef.close();
                    var Body = this.translateService.instant('El envío se ha realizado correctamente');
                    var Titulo = this.translateService.instant('Envío correcto');
                    this.toastr.success(Body, Titulo)
                    this.envio = null;
                    this.envioService.setIdEnvio(-1);
                    this.idEnvio = -1;
                    setTimeout(function () { window.location.reload() }, this.segundos);
                    form.resetForm();
                    this.inicializar();
                  }, err => {
                    dialogRef.close();
                    /*var Body = this.translateService.instant('El envío se ha realizado correctamente');
                    var Titulo = this.translateService.instant('Envío correcto');
                    this.toastr.success(Body, Titulo)
                    this.envio = null;
                    this.envioService.setIdEnvio(-1);
                    this.idEnvio = -1;
                    this.vaciarForm(form);*/
                    var Body = this.translateService.instant('Ha producido un error al enviar los documentos asociados al envio, contacte con XGL.');
                    var Titulo = this.translateService.instant('Error en los documentos');
                    this.toastr.error(Body, Titulo)
                  })
                })
              } else {
                dialogRef.close();
                var Body = this.translateService.instant('El envío se ha realizado correctamente');
                var Titulo = this.translateService.instant('Envío correcto');
                this.toastr.success(Body, Titulo)
                this.envio = null;
                this.envioService.setIdEnvio(-1);
                this.idEnvio = -1;
                setTimeout(function () { window.location.reload() }, this.segundos);
                form.resetForm();
                this.inicializar();
              }

            },
              error => {
                dialogRef.close();
                var Body = this.translateService.instant('Ha habido un problema al crear el envío.');
                var Titulo = this.translateService.instant('Error en el envío');
                this.toastr.error(Body, Titulo)
              });
          }
        }
        else {
          var Body = this.translateService.instant('Se ha marcado la casilla de mercancia peligrosa y no hay mercancia peligrosa.');
          var Titulo = this.translateService.instant('Error mercancia peligrosa');
          this.toastr.error(Body, Titulo)
        }
      }
      else {
        var Body = this.translateService.instant('Falta la entidad de entrega y/o la direccion');
        var Titulo = this.translateService.instant('Error recogida');
        this.toastr.error(Body, Titulo)
      }
    }
  }

  getTituloRecogida() {
    if (this.corresponsal != null && this.corresponsal != undefined && (this.corresponsal.IdDelegacion == 2 || this.corresponsal.IdDelegacion == 5))
      return this.translateService.instant("RecogidaMexico");
    return this.translateService.instant("Recogida");
  }

  //Funcion que se usa para enviar el formulario al servidor
  envioMaritimo(form: NgForm) {
    if (this.envioMaritimoOk(form)) {
      if (!this.refCliDuplicado) {
        if (this.incotermStartWith()) {
          if (!this.compruebaPersonaContactoEntrega(form) && !this.compruebaTelefonoEntrega(form) && !this.compruebaEmailEntrega(form)) {
            this.peticionEnvio(form);
          }
          else {
            this.compruebaPersonaContactoEntrega(form);
            this.compruebaTelefonoEntrega(form);
            this.compruebaEmailEntrega(form);
            var Body = this.translateService.instant('Los datos de entrega son obligatorios.');
            var Titulo = this.translateService.instant('Error datos entrega');
            this.toastr.error(Body, Titulo)
          }
        }
        else {
          this.peticionEnvio(form);
        }
      }
      else {
        var Body = this.translateService.instant('La referencia ya ha sido introducida en el sistema');
        var Titulo = this.translateService.instant('Error duplicidad');
        this.toastr.error(Body, Titulo);
      }
    }
    else {
      this.compruebaErrores(form);
      this.error = true;
      var Body = this.translateService.instant('Faltan campos por completar.');
      var Titulo = this.translateService.instant('Error en el envío');
      this.toastr.error(Body, Titulo)
    }
  }

  getFormatDireccion(entidad) {
    var formatDireccion: string = "";
    if (entidad != null && entidad != undefined) {
      // Tipo direccion
      if (entidad.TipoDireccion != undefined && entidad.TipoDireccion != null && entidad.TipoDireccion != ""
        && entidad.TipoDireccion != ".")
        formatDireccion += entidad.TipoDireccion + " ";

      // Direccion
      if (entidad.Direccion != undefined && entidad.Direccion != null && entidad.Direccion != ""
        && entidad.Direccion != ".")
        formatDireccion += entidad.Direccion + ", ";

      // Numero
      if (entidad.Numero != undefined && entidad.Numero != null && entidad.Numero != ""
        && entidad.Numero != ".")
        formatDireccion += entidad.Numero + "\n";

      // Localidad
      if (entidad.Poblacion != undefined && entidad.Poblacion != null && entidad.Poblacion != ""
        && entidad.Poblacion != ".")
        formatDireccion += entidad.Poblacion;
    }
    return formatDireccion;
  }

  //Funcion que pone la direccion y la localidad de donde se va a recoger
  setRecogidaInformation() {
    this.resumenDireccionRecogida = '';
    this.resumenDireccionRecogida = this.getFormatDireccion(this.formModelMaritimo.value.EntidadRecogida);
  }

  //Funcion que pone la direccion y la localidad de donde se va a entregar
  setEntregaInformation() {
    this.resumenDireccionEntrega = '';
    this.resumenDireccionEntrega = this.getFormatDireccion(this.formModelMaritimo.value.EntidadEntrega);
  }

  comprobarModoEnvioLCL(): boolean {
    return this.formModelMaritimo.value.ModoEnvio == 2
  }

  setModoEnvio(modoEnvio: Modoenvio) {
    if (modoEnvio != undefined) {
      if (modoEnvio.ModoEnvio == 'FCL') {
        this.esFCL = true;
        this.esLCL = false;
      }
      else {
        this.esFCL = false;
        this.esLCL = true;
      }
      this.formModelMaritimo.value.ModoEnvio = modoEnvio.IdModoEnvio;
    }
  }

  //Funcion que obtiene el tipo de envio
  setTipoEnvio(tipoEnvio: TipoEnvio) {
    this.envioService.getEntidadUsuario().subscribe(entidad => {
      if (tipoEnvio != undefined) {
        this.formModelMaritimo.value.TipoEnvio = tipoEnvio.IdTipoEnvio;
        this.cargaRemitente = true;
        this.formModelMaritimo.value.Shipper = null;
        this.formModelMaritimo.value.DireccionShipper = '';
        this.formModelMaritimo.value.EntidadRecogida = '';
        this.resumenDireccionRecogida = '';
        this.formModelMaritimo.value.EntidadRecogida = null;
        this.cargaRemitente = false;
        this.formModelMaritimo.value.PersonaContactoRecogida = '';
        this.formModelMaritimo.value.TelefonoRecogida = '';
        this.formModelMaritimo.value.EmailRecogida = '';
        if (this.esExport()) {
          var lista = this.listConsignee;
          this.listConsignee = this.listShipper;
          this.listShipper = lista;
          this.listShipper.forEach(shipper => {
            if (shipper.IdEntidad == entidad) {
              this.formModelMaritimo.value.Consignee = null;
              this.formModelMaritimo.value.EntidadEntrega = null;
              this.setConsigneeInformation();
              this.formModelMaritimo.value.Shipper = shipper;
              this.formModelMaritimo.value.EntidadRecogida = shipper;
              this.formModelMaritimo.value.Cliente = shipper;
              this.setShipperInformation();
            }
          });
        }
        this.cargaDestinatario = true;
        if (!this.esExport()) {
          var lista = this.listShipper;
          this.listShipper = this.listConsignee;
          this.listConsignee = lista;
          this.listConsignee.forEach(consignee => {
            if (consignee.IdEntidad == entidad) {
              this.formModelMaritimo.value.Shipper = null;
              this.formModelMaritimo.value.EntidadRecogida = null;
              this.setShipperInformation();
              this.formModelMaritimo.value.Consignee = consignee;
              this.formModelMaritimo.value.EntidadEntrega = consignee;
              this.formModelMaritimo.value.Cliente = consignee;
              this.setConsigneeInformation();
            }
          });
        }
        this.cargaDestinatario = false;
        if (this.datosContacto != null && this.datosContacto != undefined && this.esExport()) {
          this.formModelMaritimo.value.PersonaContactoEntrega = this.datosContacto.NombreUsuario;
          this.formModelMaritimo.value.TelefonoEntrega = this.datosContacto.Telefono;
          this.formModelMaritimo.value.EmailEntrega = this.datosContacto.Email;
        }
      }
    })
  }

  esExport() {
    return this.formModelMaritimo.value.TipoEnvio == 1;
  }

  asignarModoEnvio(envioMaritimo: Shipment) {
    this.formModelMaritimo.value.ModoEnvio = envioMaritimo.IdModoEnvio;
    if (envioMaritimo.IdModoEnvio == 1) {
      this.esFCL = true;
      this.esLCL = false;
    }
    else if (envioMaritimo.IdModoEnvio == 2) {
      this.esFCL = false;
      this.esLCL = true;
    }
  }

  asignarTipoEnvio(envioMaritimo: Shipment) {
    this.formModelMaritimo.value.TipoEnvio = envioMaritimo.IdTipoEnvio;
  }

  asignarShipper(envioMaritimo: Shipment) {
    if (this.listShipper.length >= 0) {
      this.listShipper.forEach(shipper => {
        if (shipper.IdEntidad == envioMaritimo.IdRemitente) {
          this.formModelMaritimo.value.Shipper = shipper;
          this.setShipperInformation();
        }
      });
    }
  }

  asignarConsignee(envioMaritimo: Shipment) {
    if (this.listConsignee.length >= 0) {
      this.listConsignee.forEach(consignee => {
        if (consignee.IdEntidad == envioMaritimo.IdDestinatario) {
          this.formModelMaritimo.value.Consignee = consignee;
          this.setConsigneeInformation();
          this.asignarNotify(envioMaritimo);
        }
      });
    }
  }

  //Funcion que asigna el notify
  asignarNotify(envioMaritimo: Shipment) {
    if (this.listNotify.length >= 0) {
      if (envioMaritimo.IdNotify == envioMaritimo.IdDestinatario || envioMaritimo.IdNotify == -1 || envioMaritimo.IdNotify == 0) {
        this.formModelMaritimo.value.Notify = "THE SAME";
      }
      else {
        this.listNotify.forEach(notify => {
          if (notify.IdEntidad == envioMaritimo.IdNotify) {
            this.formModelMaritimo.value.Notify = notify;
            this.setNotifyInformation();
          }
        })
      }
    }
  }

  asignarIncoterm(envioMaritimo: Shipment) {
    if (this.listIncoterm.length >= 0 && this.formModelMaritimo.value.Incoterm == '') {
      this.formModelMaritimo.value.Incoterm = envioMaritimo.Incoterm;
    }
  }

  //asignamos la fecha de recogida
  asignarFechaRecogida(envioMaritimo: Shipment) {
    if (envioMaritimo.FechaRecogida != undefined && envioMaritimo.FechaRecogida != null) {
      let fecha = envioMaritimo.FechaRecogida.toString().split('T')[0];
      this.formModelMaritimo.value.FechaRecogida = fecha;
    }
  }

  //Funcion que trae los valores de la clase al form
  asignarValores(envioMaritimo: Shipment) {
    if (envioMaritimo != undefined) {
      this.editar = this.envioService.getEditar();
      this.duplicar = this.envioService.getDuplicar();
      this.isCotizacion = this.envioService.getCotizacionBoolean();

      if (this.editar || this.visor)
        this.formModelMaritimo.value.Id = this.idEnvio;
      else
        this.formModelMaritimo.value.Id = 0;

      if (this.isCotizacion)
        this.formModelMaritimo.value.Cotizacion = this.envioService.getCotizacion();

      if (envioMaritimo.IdArea != undefined && envioMaritimo.IdArea != null)
        this.formModelMaritimo.value.Envio = envioMaritimo.IdArea;

      if (!this.tarifa)
        this.asignarModoEnvio(envioMaritimo);

      if (!this.tarifa)
        this.asignarTipoEnvio(envioMaritimo);

      this.formModelMaritimo.value.IdTipoEnvio = envioMaritimo.IdTipoEnvio;
      this.formModelMaritimo.value.RefCotizacion = envioMaritimo.RefCotizacion;
      // var tipoEnvio = new TipoEnvio();
      // tipoEnvio.IdTipoEnvio = envioMaritimo.IdTipoEnvio;

      //this.setTipoEnvio(tipoEnvio);
      if (this.visor || this.editar || this.isCotizacion) {
        this.formModelMaritimo.value.Referencia = envioMaritimo.RefCliente;
        this.asignarFechaRecogida(envioMaritimo);
      }
      // this.envioService.getEntidadesAddressBook().subscribe(listadoRemitentes => {
      //   this.cargaDestinatario = true;
      //   this.listConsignee = listadoRemitentes;
      //   this.cargaDestinatario = false;
      //   this.cargaRemitente = true;
      //   this.listShipper = listadoRemitentes;
      //   this.cargaRemitente = false;
      //   //this.asignarShipper(envioMaritimo);
      //   if (this.formModelMaritimo.value.DireccionShipper != envioMaritimo.DireccionShipper && envioMaritimo.DireccionShipper != null && envioMaritimo.DireccionShipper != '')
      //     this.formModelMaritimo.value.DireccionShipper = envioMaritimo.DireccionShipper

      //   //this.asignarConsignee(envioMaritimo);
      //   if (this.formModelMaritimo.value.DireccionConsignee != envioMaritimo.DireccionConsignee && envioMaritimo.DireccionConsignee != null && envioMaritimo.DireccionConsignee != '')
      //     this.formModelMaritimo.value.DireccionConsignee = envioMaritimo.DireccionConsignee

      //   //this.asignarNotify(envioMaritimo);
      //   if (this.formModelMaritimo.value.DireccionNotify != envioMaritimo.DireccionNotify && envioMaritimo.DireccionNotify != null && envioMaritimo.DireccionNotify != '')
      //     this.formModelMaritimo.value.DireccionNotify = envioMaritimo.DireccionNotify


      //   this.asignarTipoEnvio(envioMaritimo);
      // });
      this.asignarShipper(envioMaritimo);
      this.asignarConsignee(envioMaritimo);
      this.asignarNotify(envioMaritimo);
      //this.asignarTipoEnvio(envioMaritimo);

      this.formModelMaritimo.value.Incoterm = envioMaritimo.Incoterm;

      this.formModelMaritimo.value.VGMPesaje = envioMaritimo.VGMPesaje;
      this.formModelMaritimo.value.VGMTransmision = envioMaritimo.VGMTransmision;

      if (!this.tarifa) {
        this.formModelMaritimo.value.PuertoOrigen = envioMaritimo.IdPuertoOrigen;
        this.formModelMaritimo.value.PuertoDestino = envioMaritimo.IdPuertoDestino;
      }

      this.formModelMaritimo.value.Naviera = envioMaritimo.Naviera;

      this.formModelMaritimo.value.ValorMercancia = envioMaritimo.ValorMercancia;
      this.formModelMaritimo.value.TipoDivisa = envioMaritimo.IdTipoDivisa;
      this.formModelMaritimo.value.SeguroMercancia = envioMaritimo.CosteSeguro;

      if (envioMaritimo.Asegurado == 1)
        this.formModelMaritimo.value.Asegurado = true;
      else
        this.formModelMaritimo.value.Asegurado = false;

      if (!this.tarifa) {
        this.formModelMaritimo.value.PesoNetoTotalDGR = envioMaritimo.PesoNetoTotalDGR;
        this.formModelMaritimo.value.PesoBrutoTotalDGR = envioMaritimo.PesoBrutoTotalDGR;
        this.formModelMaritimo.value.BultosTotales = envioMaritimo.BultosTotales;
        this.formModelMaritimo.value.PesoNetoTotal = envioMaritimo.PesoNetoTotal;
        this.formModelMaritimo.value.PesoBrutoTotal = envioMaritimo.PesoBrutoTotal;
        this.formModelMaritimo.value.VolumenTotal = envioMaritimo.VolumenTotal;
        //this.formModelMaritimo.value.PesoTasable = envioMaritimo.PesoTasable;
        this.calcularPesoTasable();
      }

      this.formModelMaritimo.value.Refrigerada = envioMaritimo.Refrigerada;
      this.formModelMaritimo.value.Perecedero = envioMaritimo.Perecedero;
      if (!this.tarifa) {
        this.formModelMaritimo.value.MercanciaPeligrosa = envioMaritimo.MercanciaPeligrosa;
      }
      //this.formModelMaritimo.value.NoRemontable = envioMaritimo.NoRemontable;
      this.formModelMaritimo.value.Fumigado = envioMaritimo.Fumigado;

      //Recogida
      if (envioMaritimo.FechaRecogida != null && (this.visor || this.editar))
        this.asignarFechaRecogida(envioMaritimo);

      if (this.listShipper.length > 0)
        this.listShipper.forEach(element => {
          if (element.IdEntidad == envioMaritimo.IdEntidadRecogida)
            this.formModelMaritimo.value.EntidadRecogida = element;
        });

      this.formModelMaritimo.value.DireccionRecogida = envioMaritimo.IdDireccionRecogida;
      if (envioMaritimo.InstruccionesRecogida != null && envioMaritimo.InstruccionesRecogida != undefined)
        this.formModelMaritimo.value.InstruccionesRecogida = envioMaritimo.InstruccionesRecogida;
      else
        this.formModelMaritimo.value.InstruccionesRecogida = "";

      if (envioMaritimo.ReferenciaRecogida == null || envioMaritimo.ReferenciaRecogida == undefined)
        this.formModelMaritimo.value.ReferenciaRecogida = '';
      else {
        if (this.visor || this.editar)
          this.formModelMaritimo.value.ReferenciaRecogida = envioMaritimo.ReferenciaRecogida;
        else
          this.formModelMaritimo.value.ReferenciaRecogida = envioMaritimo.ReferenciaRecogida;
      }

      this.formModelMaritimo.value.HoraRecogidaInicio1 = envioMaritimo.PrimeraHoraRecogidaInicio;
      this.formModelMaritimo.value.HoraRecogidaFinal1 = envioMaritimo.PrimeraHoraRecogidaFinal;
      this.formModelMaritimo.value.HoraRecogidaInicio2 = envioMaritimo.SegundaHoraRecogidaInicio;
      this.formModelMaritimo.value.HoraRecogidaFinal2 = envioMaritimo.SegundaHoraRecogidaFinal;

      if (envioMaritimo.TelefonoRecogida != undefined && envioMaritimo.TelefonoRecogida != null && envioMaritimo.TelefonoRecogida != "")
        this.formModelMaritimo.value.TelefonoRecogida = envioMaritimo.TelefonoRecogida;
      if (envioMaritimo.PersonaContactoRecogida != undefined && envioMaritimo.PersonaContactoRecogida != null && envioMaritimo.PersonaContactoRecogida != "")
        this.formModelMaritimo.value.PersonaContactoRecogida = envioMaritimo.PersonaContactoRecogida;
      if (envioMaritimo.EmailRecogida != undefined && envioMaritimo.EmailRecogida != null && envioMaritimo.EmailRecogida != "")
        this.formModelMaritimo.value.EmailRecogida = envioMaritimo.EmailRecogida;

      this.formModelMaritimo.value.DespachoOrigen = envioMaritimo.DespachoOrigen;
      this.formModelMaritimo.value.DespachoDestino = envioMaritimo.DespachoDestino;

      //Entrega
      if (this.incotermStartWith() || this.esImport()) {
        if (this.listConsignee.length > 0)
          this.listConsignee.forEach(element => {
            if (element.IdEntidad == envioMaritimo.IdEntidadEntrega)
              this.formModelMaritimo.value.EntidadEntrega = element;
          });
        this.formModelMaritimo.value.DireccionEntrega = envioMaritimo.IdDireccionEntrega;
        if (envioMaritimo.InstruccionesEntrega != null && envioMaritimo.InstruccionesEntrega != undefined)
          this.formModelMaritimo.value.InstruccionesEntrega = envioMaritimo.InstruccionesEntrega;
        else
          this.formModelMaritimo.value.InstruccionesEntrega = "";

        if (envioMaritimo.ReferenciaEntrega == null || envioMaritimo.ReferenciaEntrega == undefined)
          this.formModelMaritimo.value.ReferenciaEntrega = '';
        else
          this.formModelMaritimo.value.ReferenciaEntrega = envioMaritimo.ReferenciaEntrega;

        if (envioMaritimo.TelefonoEntrega != undefined && envioMaritimo.TelefonoEntrega != null && envioMaritimo.TelefonoEntrega != "")
          this.formModelMaritimo.value.TelefonoEntrega = envioMaritimo.TelefonoEntrega;
        if (envioMaritimo.PersonaContactoEntrega != undefined && envioMaritimo.PersonaContactoEntrega != null && envioMaritimo.PersonaContactoEntrega != "")
          this.formModelMaritimo.value.PersonaContactoEntrega = envioMaritimo.PersonaContactoEntrega;
        if (envioMaritimo.EmailEntrega != undefined && envioMaritimo.EmailEntrega != null && envioMaritimo.EmailEntrega != "")
          this.formModelMaritimo.value.EmailEntrega = envioMaritimo.EmailEntrega;
      }


      if (envioMaritimo.Instrucciones != null && envioMaritimo.Instrucciones != undefined)
        this.formModelMaritimo.value.Instrucciones = envioMaritimo.Instrucciones;
      else
        this.formModelMaritimo.value.Instrucciones = "";
      if (envioMaritimo.InstruccionesBLAWB != null && envioMaritimo.InstruccionesBLAWB != undefined)
        this.formModelMaritimo.value.InstruccionesBLAWB = envioMaritimo.InstruccionesBLAWB;
      else
        this.formModelMaritimo.value.InstruccionesBLAWB = "";
      if (envioMaritimo.Marcas != null && envioMaritimo.Marcas != undefined)
        this.formModelMaritimo.value.Marcas = envioMaritimo.Marcas;
      else
        this.formModelMaritimo.value.Marcas = "";

      this.formModelMaritimo.value.Mercancia = envioMaritimo.Mercancia;
      this.formModelMaritimo.value.MercanciaContenedor = envioMaritimo.MercanciaContenedor;
      this.asignarTipoEnvio(envioMaritimo);
      if (envioMaritimo.Mercancia != null && envioMaritimo.Mercancia != undefined && envioMaritimo.Mercancia.length > 0 && !this.esFCL)
        this.listaMerc(envioMaritimo.Mercancia);
      else if (envioMaritimo.MercanciaContenedor != null && envioMaritimo.MercanciaContenedor != undefined && envioMaritimo.MercanciaContenedor.length > 0 && this.esFCL)
        this.listaMercContenedor(envioMaritimo.MercanciaContenedor);
      this.envioService.setPartida(null);
      this.envioService.setExpedicion(null);
      this.envioService.setCotizacion(null);
    }
  }

  esImport() {
    return this.formModelMaritimo.value.IdTipoEnvio == 2;
  }

  //Funcion que trae los valores de la clase al form
  asignarValoresPlantilla(envioMaritimo: Shipment) {
    if (envioMaritimo != undefined && this.formModelMaritimo.value != null && this.formModelMaritimo.value != undefined) {
      this.editar = this.envioService.getEditar();
      this.duplicar = this.envioService.getDuplicar();
      this.isCotizacion = this.envioService.getCotizacionBoolean();

      if (this.isCotizacion) { // También viene de una cotización
        // if (this.editar || this.visor)
        //   this.formModelMaritimo.value.IdEnvio = this.idEnvio;
        // else
        //   this.formModelMaritimo.value.IdEnvio = 0;

        // if (this.isCotizacion)
        //   this.formModelMaritimo.value.Cotizacion = this.envioService.getCotizacion();

        if (envioMaritimo.IdArea != undefined && envioMaritimo.IdArea != null)
          this.formModelMaritimo.value.Envio = envioMaritimo.IdArea;

        this.asignarTipoEnvio(envioMaritimo);

        // if (this.visor || this.editar || this.isCotizacion) {
        //   this.asignarFechaRecogida(envioMaritimo);
        //   this.formModelMaritimo.value.Referencia = envioMaritimo.RefCliente;
        // }

        this.asignarShipper(envioMaritimo);
        if (this.formModelMaritimo.value.DireccionShipper != envioMaritimo.DireccionShipper && envioMaritimo.DireccionShipper != null && envioMaritimo.DireccionShipper != '')
          this.formModelMaritimo.value.DireccionShipper = envioMaritimo.DireccionShipper

        this.asignarConsignee(envioMaritimo);
        if (this.formModelMaritimo.value.DireccionConsignee != envioMaritimo.DireccionConsignee && envioMaritimo.DireccionConsignee != null && envioMaritimo.DireccionConsignee != '')
          this.formModelMaritimo.value.DireccionConsignee = envioMaritimo.DireccionConsignee

        if (this.formModelMaritimo.value.DireccionNotify != envioMaritimo.DireccionNotify && envioMaritimo.DireccionNotify != null && envioMaritimo.DireccionNotify != '')
          this.formModelMaritimo.value.DireccionNotify = envioMaritimo.DireccionNotify

        //this.asignarIncoterm(envioMaritimo);
        // this.formModelMaritimo.value.Incoterm = envioMaritimo.Incoterm;
        // this.formModelMaritimo.value.AeropuertoOrigen = envioMaritimo.IdAeropuertoOrigen;
        // this.formModelMaritimo.value.AeropuertoDestino = envioMaritimo.IdAeropuertoDestino;
        // this.formModelMaritimo.value.RefCotizacion = envioMaritimo.RefCotizacion;
        this.formModelMaritimo.value.Aerolinea = envioMaritimo.Aerolinea;

        // this.formModelMaritimo.value.ValorMercancia = envioMaritimo.ValorMercancia;
        // this.formModelMaritimo.value.TipoDivisa = envioMaritimo.IdTipoDivisa;

        // this.formModelMaritimo.value.SeguroMercancia = envioMaritimo.CosteSeguro;

        // this.formModelMaritimo.value.VGMPesaje = envioMaritimo.VGMPesaje;
        // this.formModelMaritimo.value.VGMTransmision = envioMaritimo.VGMTransmision;

        // if (envioMaritimo.Asegurado == 1)
        //   this.formModelMaritimo.value.Asegurado = true;
        // else
        //   this.formModelMaritimo.value.Asegurado = false;

        // this.formModelMaritimo.value.PesoNetoTotalDGR = envioMaritimo.PesoNetoTotalDGR;
        // this.formModelMaritimo.value.PesoBrutoTotalDGR = envioMaritimo.PesoBrutoTotalDGR;
        // this.formModelMaritimo.value.BultosTotales = envioMaritimo.BultosTotales;
        // this.formModelMaritimo.value.PesoNetoTotal = envioMaritimo.PesoNetoTotal;
        // this.formModelMaritimo.value.PesoBrutoTotal = envioMaritimo.PesoBrutoTotal;
        // this.formModelMaritimo.value.VolumenTotal = envioMaritimo.VolumenTotal;
        // this.formModelMaritimo.value.PesoTasable = envioMaritimo.PesoTasable;

        // this.formModelMaritimo.value.Refrigerada = envioMaritimo.Refrigerada;
        // this.formModelMaritimo.value.Perecedero = envioMaritimo.Perecedero;
        // this.formModelMaritimo.value.MercanciaPeligrosa = envioMaritimo.MercanciaPeligrosa;
        // this.formModelMaritimo.value.NoRemontable = envioMaritimo.NoRemontable;
        // this.formModelMaritimo.value.Fumigado = envioMaritimo.Fumigado;


        //Recogida
        // if (envioMaritimo.FechaRecogida != null && (this.visor || this.editar))
        //   this.asignarFechaRecogida(envioMaritimo);
        if (this.listShipper.length > 0)
          this.listShipper.forEach(element => {
            if (element.IdEntidad == envioMaritimo.IdEntidadRecogida)
              this.formModelMaritimo.value.EntidadRecogida = element;
          });
        this.formModelMaritimo.value.DireccionRecogida = envioMaritimo.IdDireccionRecogida;
        if (envioMaritimo.InstruccionesRecogida != null && envioMaritimo.InstruccionesRecogida != undefined)
          this.formModelMaritimo.value.InstruccionesRecogida = envioMaritimo.InstruccionesRecogida;
        else
          this.formModelMaritimo.value.InstruccionesRecogida = "";
        if (envioMaritimo.ReferenciaRecogida == null || envioMaritimo.ReferenciaRecogida == undefined)
          this.formModelMaritimo.value.ReferenciaRecogida = '';
        else {
          if (this.visor || this.editar)
            this.formModelMaritimo.value.ReferenciaRecogida = envioMaritimo.ReferenciaRecogida;
          else
            this.formModelMaritimo.value.ReferenciaRecogida = "";
        }

        this.formModelMaritimo.value.HoraRecogidaInicio1 = envioMaritimo.PrimeraHoraRecogidaInicio;
        this.formModelMaritimo.value.HoraRecogidaFinal1 = envioMaritimo.PrimeraHoraRecogidaFinal;
        this.formModelMaritimo.value.HoraRecogidaInicio2 = envioMaritimo.SegundaHoraRecogidaInicio;
        this.formModelMaritimo.value.HoraRecogidaFinal2 = envioMaritimo.SegundaHoraRecogidaFinal;

        if (envioMaritimo.TelefonoRecogida != undefined && envioMaritimo.TelefonoRecogida != null && envioMaritimo.TelefonoRecogida != "")
          this.formModelMaritimo.value.TelefonoRecogida = envioMaritimo.TelefonoRecogida;
        if (envioMaritimo.PersonaContactoRecogida != undefined && envioMaritimo.PersonaContactoRecogida != null && envioMaritimo.PersonaContactoRecogida != "")
          this.formModelMaritimo.value.PersonaContactoRecogida = envioMaritimo.PersonaContactoRecogida;
        if (envioMaritimo.EmailRecogida != undefined && envioMaritimo.EmailRecogida != null && envioMaritimo.EmailRecogida != "")
          this.formModelMaritimo.value.EmailRecogida = envioMaritimo.EmailRecogida;

        this.formModelMaritimo.value.DespachoOrigen = envioMaritimo.DespachoOrigen;
        this.formModelMaritimo.value.DespachoDestino = envioMaritimo.DespachoDestino;

        //Entrega
        if (this.incotermStartWith()) {
          if (this.listConsignee.length > 0)
            this.listConsignee.forEach(element => {
              if (element.IdEntidad == envioMaritimo.IdEntidadEntrega)
                this.formModelMaritimo.value.EntidadEntrega = element;
            });
          this.formModelMaritimo.value.DireccionEntrega = envioMaritimo.IdDireccionEntrega;
          if (envioMaritimo.InstruccionesEntrega != null && envioMaritimo.InstruccionesEntrega != undefined)
            this.formModelMaritimo.value.InstruccionesEntrega = envioMaritimo.InstruccionesEntrega;
          else
            this.formModelMaritimo.value.InstruccionesEntrega = "";
          if (envioMaritimo.ReferenciaEntrega == null || envioMaritimo.ReferenciaEntrega == undefined)
            this.formModelMaritimo.value.ReferenciaEntrega = '';
          else
            this.formModelMaritimo.value.ReferenciaEntrega = envioMaritimo.ReferenciaEntrega;

          if (envioMaritimo.TelefonoEntrega != undefined && envioMaritimo.TelefonoEntrega != null && envioMaritimo.TelefonoEntrega != "")
            this.formModelMaritimo.value.TelefonoEntrega = envioMaritimo.TelefonoEntrega;
          if (envioMaritimo.PersonaContactoEntrega != undefined && envioMaritimo.PersonaContactoEntrega != null && envioMaritimo.PersonaContactoEntrega != "")
            this.formModelMaritimo.value.PersonaContactoEntrega = envioMaritimo.PersonaContactoEntrega;
          if (envioMaritimo.EmailEntrega != undefined && envioMaritimo.EmailEntrega != null && envioMaritimo.EmailEntrega != "")
            this.formModelMaritimo.value.EmailEntrega = envioMaritimo.EmailEntrega;
        }

        // if (envioMaritimo.Instrucciones != null && envioMaritimo.Instrucciones != undefined)
        //   this.formModelMaritimo.value.Instrucciones = envioMaritimo.Instrucciones;
        // else
        //   this.formModelMaritimo.value.Instrucciones = "";
        if (envioMaritimo.InstruccionesBLAWB != null && envioMaritimo.InstruccionesBLAWB != undefined)
          this.formModelMaritimo.value.InstruccionesBLAWB = envioMaritimo.InstruccionesBLAWB;
        else
          this.formModelMaritimo.value.InstruccionesBLAWB = "";
        if (envioMaritimo.Marcas != null && envioMaritimo.Marcas != undefined)
          this.formModelMaritimo.value.Marcas = envioMaritimo.Marcas;
        else
          this.formModelMaritimo.value.Marcas = "";

        //this.formModelMaritimo.value.Mercancia = envioMaritimo.Mercancia;
        this.asignarTipoEnvio(envioMaritimo);
        //this.listaMerc(envioMaritimo.Mercancia);
        this.envioService.setPartida(null);
        this.envioService.setExpedicion(null);
        this.envioService.setCotizacion(null);
      } else
        this.asignarValores(envioMaritimo);

    }
  }

  //Funcion que recoge la informacion completa del shipper
  setShipperInformation() {
    this.formModelMaritimo.value.DireccionShipper = '';
    if (this.formModelMaritimo.value.Shipper != null && this.formModelMaritimo.value.Shipper != undefined)
      if (this.formModelMaritimo.value.Shipper.IdDireccion != null)
        this.formModelMaritimo.value.DireccionRecogida = this.formModelMaritimo.value.Shipper.IdDireccion;

    this.formModelMaritimo.value.EntidadRecogida = this.formModelMaritimo.value.Shipper;
    this.formModelMaritimo.value.DireccionShipper = '';

    this.formModelMaritimo.value.DireccionShipper = this.getFormatDireccion(this.formModelMaritimo.value.Shipper);
    this.resumenDireccionRecogida = this.formModelMaritimo.value.DireccionShipper;
  }

  //Funcion que recoge la informacion completa del consignee
  setConsigneeInformation() {
    this.cargarListaNotify();
    this.formModelMaritimo.value.DireccionConsignee = '';
    if (this.formModelMaritimo.value.Consignee != null && this.formModelMaritimo.value.Consignee != undefined)
      if (this.formModelMaritimo.value.Consignee.IdDireccion != null)
        this.formModelMaritimo.value.IdDireccionEntrega = this.formModelMaritimo.value.Consignee.IdDireccion;

    this.formModelMaritimo.value.EntidadEntrega = this.formModelMaritimo.value.Consignee;
    this.formModelMaritimo.value.DireccionConsignee = this.getFormatDireccion(this.formModelMaritimo.value.Consignee);
    this.resumenDireccionEntrega = this.formModelMaritimo.value.DireccionConsignee;
  }


  //Funcion que recoge la informacion completa del notify
  setNotifyInformation() {
    this.formModelMaritimo.value.DireccionNotify = this.getFormatDireccion(this.formModelMaritimo.value.Notify);
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el shipper
  recibirShipper(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelMaritimo.value.Shipper = entidad;
      this.setShipperInformation();
    }
    this.modalRef.hide();
    document.getElementById("Shipper").focus();
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el consignee
  recibirConsignee(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelMaritimo.value.Consignee = entidad;
      this.setConsigneeInformation();
    }
    this.modalRef.hide();
    document.getElementById("Consignee").focus();

  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirNotify(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelMaritimo.value.Notify = entidad;
      this.setNotifyInformation();
    }
    this.modalRef.hide();
    document.getElementById("Notify").focus()
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirEntidadRecogida(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelMaritimo.value.EntidadRecogida = entidad;
      this.setRecogidaInformation();
    }
    this.modalRef.hide();
    document.getElementById("EntidadRecogida").focus()
  }

  //Funcion que recoge la informacion del componente search sobre la entidad escogida y la setea en el notify
  recibirEntidadEntrega(entidad) {
    if (entidad != null && entidad != undefined) {
      this.searchText = '';
      this.formModelMaritimo.value.EntidadEntrega = entidad;
      this.setEntregaInformation();
    }
    this.modalRef.hide();
    document.getElementById("EntidadEntrega").focus()
  }


  //Funcion que comprueba los pesos y si el bruto es mayor que el neto
  comprobarPesos(elem: Mercancia) {
    if (elem.PesoBruto === undefined || elem.PesoBruto === null || elem.PesoBruto.toString() == '')
      return true
    if (elem.PesoNeto !== undefined && elem.PesoNeto !== null && elem.PesoNeto.toString() != '')
      return elem.PesoBruto !== undefined && elem.PesoNeto !== undefined && elem.PesoBruto !== null && elem.PesoNeto !== null && Number(elem.PesoNeto) <= Number(elem.PesoBruto)
    else
      return elem.PesoBruto !== undefined && elem.PesoBruto !== null && elem.PesoBruto.toString() != ''
  }

  //Comprueba si hay una mercancía vacía o algún campo de la mercancía vacía
  comprobarVacio(merc: Mercancia) {
    if (this.esFCL)
      return merc.PesoBruto === null || merc.PesoBruto === undefined || merc.PesoBruto.toString() === '' || merc.NombreMercancia === null || merc.NombreMercancia === undefined || merc.NombreMercancia.toString() === '';
    else
      return (merc.Bultos === null || merc.Bultos === undefined || merc.Volumen === null || merc.Volumen === undefined
        || merc.TipoEmbalaje === undefined || merc.TipoEmbalaje === null
        || merc.PesoBruto === null || merc.PesoBruto === undefined || merc.PesoBruto.toString() === '' || merc.Volumen.toString() === ''
        || merc.Bultos.toString() === '' || merc.TipoEmbalaje.toString() === '' || merc.NombreMercancia === null || merc.NombreMercancia === undefined || merc.NombreMercancia.toString() === '')
  }

  comprobarPesosBP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoBruto
      sum = sum + pb
    });
    return elem.PesoBruto >= sum
  }

  comprobarPesosNP(elem: Mercancia) {
    var sum = 0;
    elem.MercanciaPeligrosa.forEach(element => {
      var pb = element.PesoNeto
      sum = sum + pb
    });
    return elem.PesoNeto >= sum;
  }

  comprobarGrados(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return merc.GradosMax === null || merc.GradosMax === undefined || merc.GradosMin === null || merc.GradosMin === undefined;
    return false;
  }

  comprobraGradosDispares(merc: Mercancia): boolean {
    if (merc.TemperaturaControlada)
      return this.comprobarGrados(merc) || merc.GradosMax < merc.GradosMin;
    return false;
  }

  comprobarMercanciaPeligrosa(merc: Mercancia): boolean {
    return merc.Peligrosa && merc.MercanciaPeligrosa != null && merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0;
  }

  //Funcion que comprueba si hay errores en una linea de mercancía
  comprobarErrores() {
    var camposVacios = false, pesos = false, index = 1, grados = false, camposVaciosContenedor = false, unIndex = 1, mercpeligrosaIndex = 1, errorContenedores = false, contador = 0, indicePesos = 0, indicePB = -1, indicePN = -1, pesosBrutosDispares = true, pesosNetosDispares = true;
    var indicesMP = [], indiceNoExisteMercanciaPeligrosa = [], indiceTC = [], indiceNoHayUn = [], pesosDisparesArray = [], mercanciaspeligrosasAux = [], pesosGradosArray = [], pesosGradosDisparesArray = [], mercanciastemperaturacontroladaAux = [], pesosBrutosArray = [], pesosNetosArray = [];;
    if (this.esFCL) {
      if (this.listaMercanciaContenedor.length == 0) {
        camposVaciosContenedor = true;
        var Body = this.translateService.instant('Debes de añadir al menos un contenedor');
        var Titulo = this.translateService.instant('Necesitas añadir un contenedor');
        this.toastr.error(Body, Titulo);
      } else {
        contador++;
        this.listaMercanciaContenedor.forEach(e => {
          if (e.IdTipoContenedor == undefined || e.IdTipoContenedor == null) {
            var Body = this.translateService.instant('El contenedor ') + contador + this.translateService.instant(' le falta el tipo de contenedor');
            var Titulo = this.translateService.instant('Falta el tipo de contenedor');
            this.toastr.error(Body, Titulo);
            errorContenedores = true;
          }

          e.Mercancia.forEach(merc => {
            if (merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0 && merc.MercanciaPeligrosa != null) {
              mercanciaspeligrosasAux.push(merc.MercanciaPeligrosa);
              // var compruebaPesosBrutosMP = this.comprobarPesosBP(merc);
              // var compruebaPesosNetoMP = this.comprobarPesosNP(merc);
              // if (!compruebaPesosBrutosMP) {
              //   indicePB = index;
              //   pesosBrutosArray.push(index);
              // } else {
              //   pesosBrutosDispares = false;
              // }
              // if (!compruebaPesosNetoMP) {
              //   indicePN = index;
              //   pesosNetosArray.push(index);
              // } else {
              //   pesosNetosDispares = false;
              // }
            }

            if (merc.Peligrosa && (merc.MercanciaPeligrosa == undefined || merc.MercanciaPeligrosa == null || merc.MercanciaPeligrosa.length == 0)) {
              indiceNoExisteMercanciaPeligrosa.push(index);
              if (e.Mercancia.length == 1)
                mercpeligrosaIndex = 1;
              var Body = this.translateService.instant('Falta mercancía peligrosa en la línea ') + mercpeligrosaIndex + this.translateService.instant("delContenedor") + contador
                + this.translateService.instant(' de la tabla de mercancía');
              var Titulo = this.translateService.instant('Falta mercancía peligrosa');
              this.toastr.error(Body, Titulo);
              mercpeligrosaIndex++;
            }
            else
              mercpeligrosaIndex++;
            if (merc.MercanciaPeligrosa != undefined) {
              merc.MercanciaPeligrosa?.forEach(merc2 => {
                if (merc2.Un == null || merc2.Un == undefined || merc2.Un == '') {
                  indiceNoHayUn.push(index);
                  if (e.Mercancia.length == 1)
                    unIndex = 1;
                  var Body = this.translateService.instant('Falta indicar el UN en la línea ') + unIndex + this.translateService.instant("delContenedor") + contador
                    + this.translateService.instant(' de la tabla de mercancía');
                  var Titulo = this.translateService.instant('Falta mercancía peligrosa');
                  this.toastr.error(Body, Titulo);
                  unIndex++;
                }
                else
                  unIndex++;
              });
            }
            if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
              mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
              indiceTC.push(index);
            }
            //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);
            var compruebaMP = this.comprobarVacio(merc);
            var compruebaPesos = this.comprobarPesos(merc);
            var compruebaGrados = this.comprobarGrados(merc);
            var compruebaGradosDispares = this.comprobraGradosDispares(merc);
            if (merc.Peligrosa && merc.MercanciaPeligrosa.length == 0)
              indiceNoExisteMercanciaPeligrosa.push(index);
            if (compruebaMP)
              indicesMP.push(index);
            if (!compruebaPesos)
              pesosDisparesArray.push(index);
            if (compruebaGrados)
              pesosGradosArray.push(index);
            if (!compruebaGrados && compruebaGradosDispares)
              pesosGradosDisparesArray.push(index);
            index++;
          });
          contador++;
          mercpeligrosaIndex = 1;
          unIndex = 1;
        });
      }
    } else {
      this.listaMercancia.forEach(merc => {
        if (merc.MercanciaPeligrosa != undefined && merc.MercanciaPeligrosa.length > 0 && merc.MercanciaPeligrosa != null) {
          mercanciaspeligrosasAux.push(merc.MercanciaPeligrosa);
          // var compruebaPesosBrutosMP = this.comprobarPesosBP(merc);
          // var compruebaPesosNetoMP = this.comprobarPesosNP(merc);
          // if (!compruebaPesosBrutosMP) {
          //   indicePB = index;
          //   pesosBrutosArray.push(index);
          // } else {
          //   pesosBrutosDispares = false;
          // }
          // if (!compruebaPesosNetoMP) {
          //   indicePN = index;
          //   pesosNetosArray.push(index);
          // } else {
          //   pesosNetosDispares = false;
          // }
        }
        if (merc.TemperaturaControlada && merc.GradosMax != null && merc.GradosMax != undefined && merc.GradosMin != null && merc.GradosMin != undefined) {
          mercanciastemperaturacontroladaAux.push(merc.TemperaturaControlada);
          indiceTC.push(index);
        }
        //var compruebaExisteMercanciaPeligrosa = this.comprobarMercanciaPeligrosa(merc);
        var compruebaMP = this.comprobarVacio(merc);
        var compruebaPesos = this.comprobarPesos(merc);
        var compruebaGrados = this.comprobarGrados(merc);
        var compruebaGradosDispares = this.comprobraGradosDispares(merc);
        if (merc.Peligrosa && merc.MercanciaPeligrosa.length == 0)
          indiceNoExisteMercanciaPeligrosa.push(index);
        if (compruebaMP)
          indicesMP.push(index);
        if (!compruebaPesos)
          pesosDisparesArray.push(index);
        if (compruebaGrados)
          pesosGradosArray.push(index);
        if (!compruebaGrados && compruebaGradosDispares)
          pesosGradosDisparesArray.push(index);
        index++;
      });
    }
    if (this.esFCL) {
      for (let i = 1; i < contador; i++) {
        /* if (this.shipmentMaritimo.MercanciaPeligrosa == 1 && mercanciaspeligrosasAux.length == 0) {
           camposVacios = true;
           var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
           var Titulo = this.translateService.instant('Error mercancia peligrosa');
           this.toastr.error(Body, Titulo)
         }*/
        index = 1;
        indiceNoExisteMercanciaPeligrosa.forEach(imp => {
          camposVacios = true
          /*  var Body = this.translateService.instant('Falta mercancía peligrosa en la línea ') + i + this.translateService.instant("delContenedor") + imp
              + this.translateService.instant(' de la tabla de mercancía');
            var Titulo = this.translateService.instant('Falta mercancía peligrosa');
            this.toastr.error(Body, Titulo);*/
        })
        indiceNoHayUn.forEach(imp => {
          camposVacios = true
          /*var Body = this.translateService.instant('Falta indicar el UN en la línea ') + i + this.translateService.instant("delContenedor") + imp
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Falta mercancía peligrosa');
          this.toastr.error(Body, Titulo);*/
        })
        indicesMP.forEach(imp => {
          camposVacios = true
          var Body = this.translateService.instant('Necesitas rellenar todos los campos de la línea ') + imp + this.translateService.instant("delContenedor") + i
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Campos vacíos en mercancía') + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo)
        });
        pesosDisparesArray.forEach(e => {
          var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e + this.translateService.instant("delContenedor") + i
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo)
          pesos = true
        })
        pesosGradosArray.forEach(tc => {
          var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc + this.translateService.instant("delContenedor") + i
            + this.translateService.instant(' de la tabla de mercancía');
          var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc + this.translateService.instant("delContenedor") + i;
          this.toastr.error(Body, Titulo)
          grados = true;
        });
        // pesosBrutosArray.forEach(e => {
        //   var Body = this.translateService.instant('El peso bruto de la linea ') + e  + this.translateService.instant("delContenedor") + i 
        //   + (' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
        //   var Titulo = this.translateService.instant('Error peso bruto DGR linea ') + e + this.translateService.instant("delContenedor") + i;
        //   this.toastr.error(Body, Titulo);
        //   pesos = true
        // });
        // pesosNetosArray.forEach(e => {
        //   var Body = this.translateService.instant('El peso neto de la linea ') + e + this.translateService.instant("delContenedor") + i + this.translateService.instant(' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
        //   var Titulo = this.translateService.instant('Error peso neto DGR linea ') + e + this.translateService.instant("delContenedor") + i;
        //   this.toastr.error(Body, Titulo);
        //   pesos = true
        // });
      }

    } else {
      /* if (this.shipmentMaritimo.MercanciaPeligrosa == 1 && mercanciaspeligrosasAux.length == 0) {
         camposVacios = true
         var Body = this.translateService.instant('La casilla mercancia peligrosa está marcada pero no has añadido ninguna mercancia peligrosa, revise los datos.');
         var Titulo = this.translateService.instant('Error mercancia peligrosa');
         this.toastr.error(Body, Titulo)
       }*/
      index = 1;
      indiceNoExisteMercanciaPeligrosa.forEach(inem => {
        camposVacios = true
        var Body = this.translateService.instant('Falta Indicar UN en la línea ') + inem
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Falta mercancía peligrosa');
        this.toastr.error(Body, Titulo);
      })
      indicesMP.forEach(imp => {
        camposVacios = true
        var Body = this.translateService.instant('Necesitas rellenar todos los campos de la línea ') + imp
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Campos vacíos en mercancía');
        this.toastr.error(Body, Titulo)
      });
      pesosDisparesArray.forEach(e => {
        var Body = this.translateService.instant('No puedes añadir un peso bruto menor que peso neto en la línea ') + e
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Peso Bruto menor que Peso Neto línea ') + e;
        this.toastr.error(Body, Titulo)
        pesos = true
      })
      pesosGradosArray.forEach(tc => {
        var Body = this.translateService.instant('Debes de añadir grados en la línea ') + tc
          + this.translateService.instant(' de la tabla de mercancía');
        var Titulo = this.translateService.instant('Faltan los grados en la línea ') + tc;
        this.toastr.error(Body, Titulo)
        grados = true;
      });
      // pesosBrutosArray.forEach(e => {
      //   var Body = this.translateService.instant('El peso bruto de la linea ') + e + (' debe ser mayor o igual que el peso bruto de la mercancia peligrosa');
      //   var Titulo = this.translateService.instant('Error peso bruto DGR linea ') + e;
      //   this.toastr.error(Body, Titulo);
      //   pesos = true
      // });
      // pesosNetosArray.forEach(e => {
      //   var Body = this.translateService.instant('El peso neto de la linea ') + e + (' debe ser mayor o igual que el peso neto de la mercancia peligrosa');
      //   var Titulo = this.translateService.instant('Error peso neto DGR linea ') + e;
      //   this.toastr.error(Body, Titulo);
      //   pesos = true
      // });
    }

    if (pesos || !this.comprobarVacioPesoBruto() /*|| !this.comprobarVacioPesoNeto()*/ || !this.comprobarPesoBrutoTotalDGR() || !this.comprobarPesoNetoTotalDGR() || !this.comprobarVacioPesoNetoTotalDGR() || grados || camposVacios || !this.comprobarPesosTotales() || camposVaciosContenedor || errorContenedores || this.errorPesoNetoVacio || this.errorPesoBrutoVacio) { // Existe algún error
      return true
    }
    return false
  }

  isArrayOk(array: any[]) {
    return array != null && array != undefined && array.length > 0;
  }

  //Funcion que comprueba si el peso neto total es menor que el bruto total
  comprobarPesosTotales() {
    if (this.formModelMaritimo.value.PesoBrutoTotal !== null && this.formModelMaritimo.value.PesoBrutoTotal !== undefined
      && this.formModelMaritimo.value.PesoBrutoTotal !== "" && this.formModelMaritimo.value.PesoNetoTotal !== ""
      && this.formModelMaritimo.value.PesoNetoTotal !== null && this.formModelMaritimo.value.PesoNetoTotal !== undefined
      && parseInt(this.formModelMaritimo.value.PesoBrutoTotal) < parseInt(this.formModelMaritimo.value.PesoNetoTotal)) {
      var Body = this.translateService.instant("Peso Bruto Total es menor que Peso Neto Total");
      var Titulo = this.translateService.instant("Peso Bruto Total menor que Peso Neto Total");
      this.toastr.error(Body, Titulo)
      this.errorBrutoTotal = true;
      return false
    }
    this.errorBrutoTotal = false;
    return true
  }
  //Funcion que comprueba si los pesos brutos totales son mayores que los pesos brutos totales DGR 
  comprobarPesoBrutoTotalDGR() {
    if (this.formModelMaritimo.value.PesoBrutoTotal !== null && this.formModelMaritimo.value.PesoBrutoTotal !== undefined
      && this.formModelMaritimo.value.PesoBrutoTotal !== "" && this.formModelMaritimo.value.PesoBrutoTotalDGR !== ""
      && this.formModelMaritimo.value.PesoBrutoTotalDGR !== null && this.formModelMaritimo.value.PesoBrutoTotalDGR !== undefined
      && parseInt(this.formModelMaritimo.value.PesoBrutoTotal) < parseInt(this.formModelMaritimo.value.PesoBrutoTotalDGR)) {
      var Body = this.translateService.instant("El Peso Bruto Total debe ser mayor o igual que el Peso Bruto Total DGR ");
      var Titulo = this.translateService.instant("Error peso bruto total DGR");
      this.errorPesoBruto = true;
      this.toastr.error(Body, Titulo);
      return false;
    }
    this.errorPesoBruto = false;
    return true;
  }

  //Funcion que comprueba si los pesos Netos totales son mayores que los pesos Netos totales DGR 
  comprobarPesoNetoTotalDGR() {
    if (!this.esFCL) {
      if (this.formModelMaritimo.value.PesoNetoTotal !== null && this.formModelMaritimo.value.PesoNetoTotal !== undefined
        && this.formModelMaritimo.value.PesoNetoTotal !== "" && this.formModelMaritimo.value.PesoNetoTotalDGR !== ""
        && this.formModelMaritimo.value.PesoNetoTotalDGR !== null && this.formModelMaritimo.value.PesoNetoTotalDGR !== undefined
        && parseInt(this.formModelMaritimo.value.PesoNetoTotal) < parseInt(this.formModelMaritimo.value.PesoNetoTotalDGR)) {
        var Body = this.translateService.instant("El Peso Neto Total debe ser mayor o igual que el Peso Neto Total DGR.");
        var Titulo = this.translateService.instant("Error peso Neto total DGR");
        this.toastr.error(Body, Titulo);
        this.errorPesoNeto = true;
        return false
      }
      this.errorPesoNeto = false;
      return true
    }
    else
      return true;

  }



  //Funcion que obtiene la lista de mercancias del componente Cargo y que recalcula los totales
  listaMerc(lista: Mercancia[]) {
    this.totalNetoDgrLineas = 0;
    this.listaMercancia = lista;
    this.formModelMaritimo.value.Mercancia = lista;
    var anteriorPesoBrutoTotal = this.formModelMaritimo.value.PesoBrutoTotal;
    this.formModelMaritimo.value.BultosTotales = 0;
    this.formModelMaritimo.value.PesoBrutoTotal = 0;
    this.formModelMaritimo.value.PesoNetoTotal = 0;
    this.formModelMaritimo.value.VolumenTotal = 0;
    this.formModelMaritimo.value.PesoTasable = 0;
    if (!this.tarifa)
      this.formModelMaritimo.value.PesoNetoTotalDGR = 0;
    this.formModelMaritimo.value.PesoBrutoTotalDGR = 0;
    if (this.listaMercancia != null) {
      if (this.listaMercancia.length == 0) {
        this.formModelMaritimo.value.BultosTotales = 0;
        this.formModelMaritimo.value.PesoBrutoTotal = 0;
        this.formModelMaritimo.value.PesoNetoTotal = 0;
        this.formModelMaritimo.value.VolumenTotal = 0;
        this.formModelMaritimo.value.PesoTasable = 0;
        this.formModelMaritimo.value.PesoNetoTotalDGR = 0;
        this.formModelMaritimo.value.PesoBrutoTotalDGR = 0;
      }
      else {
        this.formModelMaritimo.value.VolumenTotal = 0;
        this.formModelMaritimo.value.PesoTasable = 0;
        if (this.listaMercancia != undefined && this.listaMercancia.length >= 0) {
          this.listaMercancia.forEach(element => {
            this.formModelMaritimo.value.BultosTotales = Number((Number(this.formModelMaritimo.value.BultosTotales) + Number(element.Bultos ? element.Bultos : 0)).toFixed(3));
            if (element.Bultos != 0 && element.Bultos != null && element.Bultos != undefined) {
              this.compruebaBultosTotales();
            }
            this.formModelMaritimo.value.PesoBrutoTotal = Number((Number(this.formModelMaritimo.value.PesoBrutoTotal ? this.formModelMaritimo.value.PesoBrutoTotal : 0) +
              Number((element.PesoBruto ? element.PesoBruto : 0))).toFixed(3));
            if (element.PesoBruto != 0 && element.PesoBruto != null && element.PesoBruto != undefined) {
              this.compruebaPesoBrutoTotal();
            }
            this.formModelMaritimo.value.PesoNetoTotal = Number((Number(this.formModelMaritimo.value.PesoNetoTotal ? this.formModelMaritimo.value.PesoNetoTotal : 0) +
              Number(element.PesoNeto ? element.PesoNeto : 0)).toFixed(3));
            if (element.PesoNeto != 0 && element.PesoNeto != null && element.PesoNeto != undefined) {
              this.compruebaPesoNetoTotal();
            }
            this.formModelMaritimo.value.VolumenTotal = Number((Number(this.formModelMaritimo.value.VolumenTotal) + Number(element.Volumen ? element.Volumen : 0)).toFixed(3));
            if (element.Volumen != 0 && element.Volumen != null && element.Volumen != undefined) {
              this.compruebaVolumenTotal();
            }
            this.formModelMaritimo.value.MercanciaPeligrosa = this.hayMercanciaPeligrosa();
            if (element.MercanciaPeligrosa != undefined && element.MercanciaPeligrosa.length >= 0) {
              if (element.MercanciaPeligrosa.length >= 0) {
                element.MercanciaPeligrosa.forEach(element2 => {
                  if (element2.PesoNeto != undefined && !this.tarifa) {
                    this.formModelMaritimo.value.PesoNetoTotalDGR = Number(this.formModelMaritimo.value.PesoNetoTotalDGR) + Number(element2.PesoNeto);
                    if (element2.PesoNeto != 0 && element2.PesoNeto != null && element2.PesoNeto != undefined) {
                      this.compruebaPesoNetoTotalDGR();
                    }
                  }
                  if (element2.PesoNeto != undefined && this.tarifa) {
                    this.totalNetoDgrLineas += element2.PesoNeto
                  }
                  if (element2.PesoBruto != undefined) {
                    this.formModelMaritimo.value.PesoBrutoTotalDGR = Number(this.formModelMaritimo.value.PesoBrutoTotalDGR) + Number(element2.PesoBruto);
                    if (element2.PesoBruto != 0 && element2.PesoBruto != null && element2.PesoBruto != undefined) {
                      this.compruebaPesoNetoTotalDGR();
                    }
                  }
                });
                if ((this.formModelMaritimo.value.PesoNetoTotalDGR != this.totalNetoDgrLineas) && this.tarifa && this.totalNetoDgrLineas != 0) {
                  this.errorPesoNetoTotalDGRdistinto = true;
                }
                else
                  this.errorPesoNetoTotalDGRdistinto = false;
              }

              if (this.formModelMaritimo.value.PesoBrutoTotalDGR == undefined || this.formModelMaritimo.value.PesoBrutoTotalDGR == null)
                this.formModelMaritimo.value.PesoBrutoTotalDGR = 0;

              if (this.formModelMaritimo.value.PesoNetoTotalDGR == undefined || this.formModelMaritimo.value.PesoNetoTotalDGR == null)
                this.formModelMaritimo.value.PesoNetoTotalDGR = 0;
            }
          });
          this.calcularPesoTasable();
        }
        if (anteriorPesoBrutoTotal !== this.formModelMaritimo.value.PesoBrutoTotal) {
          this.comprobarPesosTotales();
        }
      }
    }
  }

  hayMercanciaPeligrosa(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercancia.forEach(element => {
      if ((element.Peligrosa || element.MP) && !yaHayPeligrosa)
        yaHayPeligrosa = true;
    });
    return yaHayPeligrosa;
  }

  hayMercanciaPeligrosaMercanciaContenedor(): boolean {
    var yaHayPeligrosa: boolean = false;
    this.listaMercanciaContenedor.forEach(c => {
      c.Mercancia.forEach(element => {
        if ((element.Peligrosa || element.MP) && !yaHayPeligrosa)
          yaHayPeligrosa = true;
      });
    });
    return yaHayPeligrosa;
  }

  compruebaPesoNetoTotalDGRIgual() {
    if (this.errorPesoNetoTotalDGRdistinto) {
      var Body = this.translateService.instant("El Peso Neto Total DGR debe ser igual a la suma de los netos peligrosos de las lineas de la tabla de mercancia");
      var Titulo = this.translateService.instant("Error peso Neto total DGR");
      this.toastr.error(Body, Titulo);
      return false;
    }
    else
      return true;

  }

  listaMercContenedor(lista: ContenedorMercancia[]) {
    this.totalNetoDgrLineas = 0;
    this.listaMercanciaContenedor = lista;
    this.formModelMaritimo.value.MercanciaContenedor = lista;
    var anteriorPesoBrutoTotal = this.formModelMaritimo.value.PesoBrutoTotal;
    this.formModelMaritimo.value.BultosTotales = 0;
    this.formModelMaritimo.value.PesoBrutoTotal = 0;
    this.formModelMaritimo.value.PesoNetoTotal = 0;
    this.formModelMaritimo.value.VolumenTotal = 0;
    this.formModelMaritimo.value.PesoTasable = 0;
    if (!this.tarifa)
      this.formModelMaritimo.value.PesoNetoTotalDGR = 0;
    this.formModelMaritimo.value.PesoBrutoTotalDGR = 0;
    this.formModelMaritimo.value.PesoBrutoContenedorTotal = 0;
    this.formModelMaritimo.value.ContenedoresTotales = 0;
    this.formModelMaritimo.value.PesoNetoContenedorTotalDGR = 0;
    this.formModelMaritimo.value.PesoBrutoContenedorTotalDGR = 0;
    if (this.listaMercanciaContenedor != null) {
      if (this.listaMercanciaContenedor.length == 0) {
        this.formModelMaritimo.value.BultosTotales = 0;
        this.formModelMaritimo.value.PesoBrutoTotal = 0;
        this.formModelMaritimo.value.PesoNetoTotal = 0;
        this.formModelMaritimo.value.VolumenTotal = 0;
        this.formModelMaritimo.value.PesoTasable = 0;
        this.formModelMaritimo.value.PesoNetoTotalDGR = 0;
        this.formModelMaritimo.value.PesoBrutoTotalDGR = 0;
        this.formModelMaritimo.value.PesoBrutoContenedorTotal = 0;
        this.formModelMaritimo.value.ContenedoresTotales = 0;
        this.formModelMaritimo.value.PesoNetoContenedorTotalDGR = 0;
        this.formModelMaritimo.value.PesoBrutoContenedorTotalDGR = 0;
      }
      else {
        this.formModelMaritimo.value.VolumenTotal = 0;
        this.formModelMaritimo.value.PesoTasable = 0;
        if (this.listaMercanciaContenedor != undefined && this.listaMercanciaContenedor.length != 0) {
          this.listaMercanciaContenedor.forEach(element => {
            // if(element.Bultos != null && element.Bultos != undefined)
            //   this.formModelMaritimo.value.ContenedoresTotales = Number(this.formModelMaritimo.value.ContenedoresTotales) + Number(element.Bultos);
            // if(element.PesoBruto != null && element.PesoBruto != undefined)
            //   this.formModelMaritimo.value.PesoBrutoContenedorTotal += element.PesoBruto;
            // if(element.Peligrosa != null && element.Peligrosa != undefined && element.Peligrosa)
            //   element.MercanciaPeligrosa?.forEach(mp => {
            //     if(mp.PesoNeto != null && mp.PesoNeto != undefined)
            //       this.formModelMaritimo.value.PesoNetoContenedorTotalDGR += mp.PesoNeto;
            //     if(mp.PesoBruto != null && mp.PesoBruto != undefined)
            //       this.formModelMaritimo.value.PesoBrutoContenedorTotalDGR += mp.PesoBruto;
            //   })
            element.Mercancia.forEach(mercancia => {
              this.formModelMaritimo.value.BultosTotales = Number((Number(this.formModelMaritimo.value.BultosTotales) + Number(mercancia.Bultos ? mercancia.Bultos : 0)).toFixed(3));
              if (mercancia.Bultos != 0 && mercancia.Bultos != null && mercancia.Bultos != undefined) {
                this.compruebaBultosTotales();
              }
              this.formModelMaritimo.value.PesoBrutoTotal = Number((Number(this.formModelMaritimo.value.PesoBrutoTotal ? this.formModelMaritimo.value.PesoBrutoTotal : 0) +
                Number((mercancia.PesoBruto ? mercancia.PesoBruto : 0))).toFixed(3));
              if (mercancia.PesoBruto != 0 && mercancia.PesoBruto != null && mercancia.PesoBruto != undefined) {
                this.compruebaPesoBrutoTotal();
              }
              this.formModelMaritimo.value.PesoNetoTotal = Number((Number(this.formModelMaritimo.value.PesoNetoTotal ? this.formModelMaritimo.value.PesoNetoTotal : 0) +
                Number(mercancia.PesoNeto ? mercancia.PesoNeto : 0)).toFixed(3));
              if (mercancia.PesoNeto != 0 && mercancia.PesoNeto != null && mercancia.PesoNeto != undefined) {
                this.compruebaPesoNetoTotal();
              }
              this.formModelMaritimo.value.VolumenTotal = Number((Number(this.formModelMaritimo.value.VolumenTotal) + Number(mercancia.Volumen ? mercancia.Volumen : 0)).toFixed(3));
              if (mercancia.Volumen != 0 && mercancia.Volumen != null && mercancia.Volumen != undefined) {
                this.compruebaVolumenTotal();
              }
              this.formModelMaritimo.value.MercanciaPeligrosa = this.hayMercanciaPeligrosaMercanciaContenedor();
              if (mercancia.MercanciaPeligrosa != undefined && mercancia.MercanciaPeligrosa.length >= 0) {
                if (mercancia.MercanciaPeligrosa.length >= 0) {
                  mercancia.MercanciaPeligrosa.forEach(mercancia2 => {
                    if (mercancia2.PesoNeto != undefined && !this.tarifa) {
                      this.formModelMaritimo.value.PesoNetoTotalDGR = Number(this.formModelMaritimo.value.PesoNetoTotalDGR) + Number(mercancia2.PesoNeto);
                      if (mercancia2.PesoNeto != 0 && mercancia2.PesoNeto != null && mercancia2.PesoNeto != undefined) {
                        this.compruebaPesoNetoTotalDGR();
                      }
                    }
                    if (mercancia2.PesoNeto != undefined && this.tarifa) {
                      this.totalNetoDgrLineas += mercancia2.PesoNeto
                    }
                    if (mercancia2.PesoBruto != undefined) {
                      this.formModelMaritimo.value.PesoBrutoTotalDGR = Number(this.formModelMaritimo.value.PesoBrutoTotalDGR) + Number(mercancia2.PesoBruto);
                      if (mercancia2.PesoBruto != 0 && mercancia2.PesoBruto != null && mercancia2.PesoBruto != undefined) {
                        this.compruebaPesoNetoTotalDGR();
                      }
                    }
                  });
                  if ((this.formModelMaritimo.value.PesoNetoTotalDGR != this.totalNetoDgrLineas) && this.tarifa && this.totalNetoDgrLineas != 0) {
                    this.errorPesoNetoTotalDGRdistinto = true;
                  }
                  else
                    this.errorPesoNetoTotalDGRdistinto = false;

                }
                if (this.formModelMaritimo.value.PesoBrutoTotalDGR == undefined || this.formModelMaritimo.value.PesoBrutoTotalDGR == null)
                  this.formModelMaritimo.value.PesoBrutoTotalDGR = 0;

                if (this.formModelMaritimo.value.PesoNetoTotalDGR == undefined || this.formModelMaritimo.value.PesoNetoTotalDGR == null)
                  this.formModelMaritimo.value.PesoNetoTotalDGR = 0;

              }
            })

          });
          this.calcularPesoTasable();
        }
        if (anteriorPesoBrutoTotal !== this.formModelMaritimo.value.PesoBrutoTotal) {
          this.comprobarPesosTotales();
        }
      }
    }
  }

  //Funcion que recibe un listado de archivos
  receiveFiles(files) {
    this.listaArchivos = files;
  }

  convertFormToModel(form) {
    this.shipmentMaritimo = new Shipment();
    this.shipmentMaritimo.IdEnvio = 0;
    this.shipmentMaritimo.IdCotizacion = this.cotizacion;
    this.shipmentMaritimo.IdModoEnvio = this.formModelMaritimo.value.ModoEnvio;
    this.shipmentMaritimo.IdCliente = this.entidadCliente;
    this.shipmentMaritimo.IdArea = 2;
    this.shipmentMaritimo.IdTipoEnvio = this.formModelMaritimo.value.TipoEnvio;
    this.shipmentMaritimo.RefCliente = form.value.Referencia;
    this.shipmentMaritimo.IdRemitente = this.formModelMaritimo.value.Shipper.IdEntidad;
    this.shipmentMaritimo.DireccionShipper = this.formModelMaritimo.value.DireccionShipper;
    this.shipmentMaritimo.IdDestinatario = this.formModelMaritimo.value.Consignee.IdEntidad;
    this.shipmentMaritimo.DireccionConsignee = this.formModelMaritimo.value.DireccionConsignee;
    this.shipmentMaritimo.chShipper = this.chShipper;
    this.shipmentMaritimo.chConsignee = this.chConsignee;
    this.shipmentMaritimo.chNotify = this.chNotify;
    if (this.tarifa != undefined && this.tarifa != null)
      this.shipmentMaritimo.Tarifa = this.formModelMaritimo.value.Tarifa;
    else
      this.shipmentMaritimo.Tarifa = null;

    if (this.formModelMaritimo.value.Notify.IdEntidad == -1) {
      this.shipmentMaritimo.IdNotify = this.formModelMaritimo.value.Consignee.IdEntidad;
      this.shipmentMaritimo.DireccionNotify = this.formModelMaritimo.value.DireccionNotify;
    }
    else if (this.formModelMaritimo.value.Notify == "THE SAME") {
      this.shipmentMaritimo.IdNotify = -1;
    }
    else {
      this.shipmentMaritimo.IdNotify = this.formModelMaritimo.value.Notify.IdEntidad;
    }
    this.shipmentMaritimo.Incoterm = this.formModelMaritimo.value.Incoterm;
    this.shipmentMaritimo.ValorMercancia = form.value.ValorMercancia;
    this.shipmentMaritimo.IdTipoDivisa = form.value.TipoDivisa;

    this.shipmentMaritimo.CosteSeguro = this.formModelMaritimo.value.SeguroMercancia;

    if (this.formModelMaritimo.value.Asegurado == true) {
      this.shipmentMaritimo.Asegurado = 1;
    }
    else {
      this.shipmentMaritimo.Asegurado = 0;
    }

    this.shipmentMaritimo.IdPuertoOrigen = this.formModelMaritimo.value.PuertoOrigen;
    this.shipmentMaritimo.IdPuertoDestino = this.formModelMaritimo.value.PuertoDestino;
    this.shipmentMaritimo.Naviera = this.formModelMaritimo.value.Naviera;

    if (this.formModelMaritimo.value.MercanciaPeligrosa == true)
      this.shipmentMaritimo.MercanciaPeligrosa = 1;
    else
      this.shipmentMaritimo.MercanciaPeligrosa = 0;

    this.shipmentMaritimo.Refrigerada = form.value.Refrigerada;
    this.shipmentMaritimo.Perecedero = form.value.Perecedero;
    // if(this.formModelMaritimo.value.NoRemontable == true)
    //   this.shipmentMaritimo.NoRemontable = 1;
    // else
    //   this.shipmentMaritimo.NoRemontable = 0;

    this.shipmentMaritimo.Fumigado = form.value.Fumigado;

    //Recogida
    this.shipmentMaritimo.IdEntidadRecogida = form.value.EntidadRecogida.IdEntidad;
    this.shipmentMaritimo.IdDireccionRecogida = form.value.EntidadRecogida.IdDireccion;
    this.shipmentMaritimo.FechaRecogida = form.value.FechaRecogida;
    this.shipmentMaritimo.ReferenciaRecogida = form.value.ReferenciaRecogida;
    this.shipmentMaritimo.InstruccionesRecogida = form.value.InstruccionesRecogida;
    this.shipmentMaritimo.PrimeraHoraRecogidaInicio = this.formModelMaritimo.value.HoraRecogidaInicio1;
    this.shipmentMaritimo.PrimeraHoraRecogidaFinal = this.formModelMaritimo.value.HoraRecogidaFinal1;
    this.shipmentMaritimo.SegundaHoraRecogidaInicio = this.formModelMaritimo.value.HoraRecogidaInicio2;
    this.shipmentMaritimo.SegundaHoraRecogidaFinal = this.formModelMaritimo.value.HoraRecogidaFinal2;
    this.shipmentMaritimo.PersonaContactoRecogida = form.value.PersonaContactoRecogida;
    this.shipmentMaritimo.TelefonoRecogida = form.value.TelefonoRecogida;
    this.shipmentMaritimo.EmailRecogida = this.formModelMaritimo.value.EmailRecogida;

    //DespachoAduanas
    this.shipmentMaritimo.DespachoOrigen = this.formModelMaritimo.value.DespachoOrigen != undefined ? this.formModelMaritimo.value.DespachoOrigen : null;
    this.shipmentMaritimo.DespachoDestino = this.formModelMaritimo.value.DespachoDestino != undefined ? this.formModelMaritimo.value.DespachoDestino : null;

    //Entrega
    if (this.incotermStartWith()) {
      this.shipmentMaritimo.IdEntidadEntrega = form.value.EntidadEntrega.IdEntidad;
      this.shipmentMaritimo.IdDireccionEntrega = form.value.EntidadEntrega.IdDireccion;
      this.shipmentMaritimo.ReferenciaEntrega = form.value.ReferenciaEntrega;
      this.shipmentMaritimo.InstruccionesEntrega = form.value.InstruccionesEntrega;
      this.shipmentMaritimo.PersonaContactoEntrega = form.value.PersonaContactoEntrega;
      this.shipmentMaritimo.TelefonoEntrega = form.value.TelefonoEntrega;
      this.shipmentMaritimo.EmailEntrega = this.formModelMaritimo.value.EmailEntrega;
    }
    else {
      /*
      this.shipmentMaritimo.IdEntidadEntrega = null;
      this.shipmentMaritimo.IdDireccionEntrega = null;
      this.shipmentMaritimo.ReferenciaEntrega = null;
      this.shipmentMaritimo.InstruccionesEntrega = null;
      this.shipmentMaritimo.PersonaContactoEntrega = null;
      this.shipmentMaritimo.TelefonoEntrega = null;   
      this.shipmentMaritimo.EmailEntrega = null; 
      */
    }
    //Totales mercancia
    if (this.formModelMaritimo.value.PesoNetoTotalDGR == null || this.formModelMaritimo.value.PesoNetoTotalDGR == '') {
      this.shipmentMaritimo.PesoNetoTotalDGR = 0;
    }
    else {
      this.shipmentMaritimo.PesoNetoTotalDGR = Number(this.formModelMaritimo.value.PesoNetoTotalDGR.toString().replace(',', '.'));
    }
    if (this.formModelMaritimo.value.PesoBrutoTotalDGR == null || this.formModelMaritimo.value.PesoBrutoTotalDGR == '') {
      this.shipmentMaritimo.PesoBrutoTotalDGR = 0;
    }
    else {
      this.shipmentMaritimo.PesoBrutoTotalDGR = Number(this.formModelMaritimo.value.PesoBrutoTotalDGR.toString().replace(',', '.'));
    }

    if (this.formModelMaritimo.value.BultosTotales == null || this.formModelMaritimo.value.BultosTotales == '') {
      this.shipmentMaritimo.BultosTotales = 0;
    }
    else {
      this.shipmentMaritimo.BultosTotales = Number(this.formModelMaritimo.value.BultosTotales.toString().replace(',', '.'));
    }

    if (this.formModelMaritimo.value.PesoNetoTotal == null || this.formModelMaritimo.value.PesoNetoTotal == '') {
      this.shipmentMaritimo.PesoNetoTotal = 0;
    }
    else {
      this.shipmentMaritimo.PesoNetoTotal = Number(this.formModelMaritimo.value.PesoNetoTotal.toString().replace(',', '.'));
    }

    if (this.formModelMaritimo.value.PesoBrutoTotal == null || this.formModelMaritimo.value.PesoBrutoTotal == '') {
      this.shipmentMaritimo.PesoBrutoTotal = 0;
    }
    else {
      this.shipmentMaritimo.PesoBrutoTotal = Number(this.formModelMaritimo.value.PesoBrutoTotal.toString().replace(',', '.'));
    }

    if (form.value.VolumenTotal == '') {
      this.shipmentMaritimo.VolumenTotal = 0;
    }
    else {
      this.shipmentMaritimo.VolumenTotal = Number(form.value.VolumenTotal.toString().replace(',', '.'));
    }
    this.shipmentMaritimo.PesoTasable = Number(this.formModelMaritimo.value.PesoTasable.toString().replace(',', '.'));

    //Descripciones adicionales
    this.shipmentMaritimo.InstruccionesBLAWB = this.formModelMaritimo.value.InstruccionesBLAWB;
    this.shipmentMaritimo.Marcas = form.value.Marcas;
    this.shipmentMaritimo.Instrucciones = this.formModelMaritimo.value.Instrucciones;
    this.shipmentMaritimo.Archivos = this.listaArchivos;
    this.shipmentMaritimo.Mercancia = this.listaMercancia;
    this.shipmentMaritimo.MercanciaContenedor = this.listaMercanciaContenedor;
  }

  verDocumentos() {
    this.envioService.setIdEnvio(this.formModelMaritimo.value.Id);
    this.router.navigate(["/customer/shipment/documents"]);
  }

  setMercPeligrosa(form) {
    if (this.formModelMaritimo.value.MercanciaPeligrosa == true || this.formModelMaritimo.value.Mercancia == 1) {
      this.formModelMaritimo.value.MercanciaPeligrosa = false;
      if (this.listaMercancia != undefined) {
        this.listaMercancia.forEach(element => {
          element.MercanciaPeligrosa = [];
        })
      }
    }
    else {
      this.formModelMaritimo.value.MercanciaPeligrosa = true;
    }
  }

  openModalSM(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal(template: TemplateRef<any>) {
    this.texto = this.translateService.instant("CancelarShipmentTexto");
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModalLG(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openModal2(template: TemplateRef<any>, tipoEntidad: string) {
    this.tipoEntidad = tipoEntidad;
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  openAlert(template: TemplateRef<any>, form: NgForm) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-xl',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.form = form;
    this.modalRef = this.modalService.show(template, config);
  }

  openAlert2(template: TemplateRef<any>) {
    const config: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: 'modal-sm',
      initialState: {
        id: 1
      } as Partial<Object>
    };
    this.modalRef = this.modalService.show(template, config);
  }

  setDireccion(remitenteDestinatario: string) {
    this.tipoDireccion = remitenteDestinatario;
  }

  getDireccion(direccion: Direccion) {
    if (direccion != null) {
      if (this.tipoDireccion == 'shipper') {
        this.listShipper.forEach(shipper => {
          if (shipper.IdEntidad == direccion.IdEntidad) {
            this.formModelMaritimo.value.EntidadRecogida = shipper;
            this.setDireccionRecogidaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaRecogida').focus();
            }, 0);
          }
        })
      }
      else if (this.tipoDireccion == 'consignee') {
        this.listConsignee.forEach(consignee => {
          if (consignee.IdEntidad == direccion.IdEntidad) {
            this.formModelMaritimo.value.EntidadEntrega = consignee;
            this.setDireccionEntregaInformation(direccion);
            this.modalRef.hide();
            window.setTimeout(function () {
              document.getElementById('ReferenciaEntrega').focus();
            }, 0);
          }
        })
      }
      else {
        this.modalRef.hide();
      }
    }
    else {
      this.modalRef.hide();
    }
  }

  setDireccionRecogidaInformation(direccion: Direccion) {
    this.resumenDireccionRecogida = '';
    this.formModelMaritimo.value.DireccionRecogida = direccion.IdDireccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion) {
        this.resumenDireccionRecogida = direccion.Direccion;
      }
      if (direccion.CodigoPostal != null && direccion.CodigoPostal != undefined && direccion.CodigoPostal != '') {
        this.resumenDireccionRecogida += "\r\n" + direccion.CodigoPostal
      }
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined) {
        this.resumenDireccionRecogida += "\r\n" + direccion.NombreLocalidad;
      }
      if (this.resumenDireccionRecogida != "") {
        this.resumenDireccionRecogida += ", ";
      }
      if (direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '') {
        this.resumenDireccionRecogida += direccion.NombrePoblacion;
      }
    }
    else {
      this.resumenDireccionRecogida = "";
    }
  }

  setDireccionEntregaInformation(direccion: Direccion) {
    this.resumenDireccionEntrega = '';
    this.formModelMaritimo.value.DireccionEntrega = direccion.IdDireccion;
    if (direccion != null && direccion != undefined) {
      if (direccion.Direccion != null && direccion.Direccion) {
        this.resumenDireccionEntrega = direccion.Direccion;
      }
      if (direccion.CodigoPostal != null && direccion.CodigoPostal != undefined && direccion.CodigoPostal != '') {
        this.resumenDireccionEntrega += "\r\n" + direccion.CodigoPostal
      }
      if (direccion.NombreLocalidad != null && direccion.NombreLocalidad != undefined) {
        this.resumenDireccionEntrega += "\r\n" + direccion.NombreLocalidad;
      }
      if (this.resumenDireccionEntrega != "") {
        this.resumenDireccionEntrega += ", ";
      }
      if (direccion.NombrePoblacion != null && direccion.NombrePoblacion != undefined && direccion.NombrePoblacion != '') {
        this.resumenDireccionEntrega += direccion.NombrePoblacion;
      }
    }
    else {
      this.resumenDireccionEntrega = "";
    }
  }

  getPlantilla(envioPredeterminado: Shipment) {
    if (envioPredeterminado == null) {
      this.modalRef.hide();
    }
    else {
      envioPredeterminado.FechaRecogida = null;
      this.modalRef.hide();
      this.asignarValoresPlantilla(envioPredeterminado);
    }
  }

  incotermStartWith(): Boolean {
    if (this.formModelMaritimo.value.Incoterm != '' && this.formModelMaritimo.value.Incoterm != undefined)
      if (this.formModelMaritimo.value.Incoterm.startsWith('D') || this.formModelMaritimo.value.Incoterm.startsWith('FOB'))
        return true;
    return false;
  }

  calcularSeguro() {
    var value = 0;
    if (this.formModelMaritimo.value.ValorMercancia != '') {
      if (this.formModelMaritimo.value.MercanciaPeligrosa == true) {
        value = Number(this.formModelMaritimo.value.ValorMercancia) * 1.1 * 0.004;
        //document.getElementById("CerrarSeguroMercancia").focus();
      }
      else {
        value = Number(this.formModelMaritimo.value.ValorMercancia) * 1.1 * 0.003;
        //document.getElementById("CerrarSeguroMercancia").focus();
      }
      if (value > 30) {
        this.formModelMaritimo.value.SeguroMercancia = Number(value.toFixed(3));
      }
      else {
        this.formModelMaritimo.value.SeguroMercancia = 30;
      }
    }
  }

  //Funcion que calcula el peso tasable (formula particular de Maritimo)
  calcularPesoTasable() {
    let volumenCalculado: number = 0;
    this.formModelMaritimo.value.PesoTasable = 0;
    if (this.formModelMaritimo.value.PesoBrutoTotal != null && this.formModelMaritimo.value.VolumenTotal != null) {
      volumenCalculado = (this.formModelMaritimo.value.VolumenTotal * 1000);
      if (this.formModelMaritimo.value.PesoBrutoTotal > volumenCalculado) {
        this.formModelMaritimo.value.PesoTasable = Number(this.formModelMaritimo.value.PesoBrutoTotal).toFixed(3);
      }
      else {
        this.formModelMaritimo.value.PesoTasable = Number(volumenCalculado).toFixed(3);
      }
    }
  }

  //Funcion que replica el numero de referencia en las referencias de carga y entrega si no son iguales
  replicaReferencias(form: NgForm) {
    if (this.formModelMaritimo.value.ReferenciaRecogida == this.referenciaClienteAnterior || this.formModelMaritimo.value.ReferenciaRecogida == '') {
      this.formModelMaritimo.value.ReferenciaRecogida = this.formModelMaritimo.value.Referencia;
      form.value.ReferenciaRecogida = this.formModelMaritimo.value.Referencia;
      if (this.formModelMaritimo.value.ReferenciaRecogida != this.referenciaClienteAnterior) {
        this.compruebaReferenciaRecogida(form);
      }
    }
    if (this.formModelMaritimo.value.ReferenciaEntrega == this.referenciaClienteAnterior || this.formModelMaritimo.value.ReferenciaEntrega == '') {
      this.formModelMaritimo.value.ReferenciaEntrega = this.formModelMaritimo.value.Referencia;
      form.value.ReferenciaEntrega = this.formModelMaritimo.value.Referencia;
      if (this.formModelMaritimo.value.ReferenciaEntrega != this.referenciaClienteAnterior) {
        this.compruebaReferenciaEntrega(form);
      }
    }
    this.referenciaClienteAnterior = this.formModelMaritimo.value.Referencia;
  }

  //Funcion que se utiliza para obtener la respuesta del modal de confirmacion del seguro
  //esta respuesta si es afirmativa se realiza el envio Maritimo
  confirmacionSeguro(confirmacion: boolean) {
    this.modalRef.hide();
    if (confirmacion == true) {
      this.envioMaritimo(this.form);
    }
  }

  //Funcion que se utiliza para obtener la respuesta del modal de confirmacion de guardado de plantilla
  //si la respuesta es afirmativa guardamos el formulario como plantilla
  confirmacionPlantilla(confirmacion: boolean) {
    this.modalRef.hide();
    if (confirmacion == true) {
      //guardamos plantilla
      this.asignarFormAPlantilla();
      var log: LogModel = new LogModel();
      log.Click = "enviarPlantilla";
      log.Pantalla = this.clase;
      this.envioService.postPlantilla(this.shipmentMaritimo, log).subscribe(data => {
        var Body = this.translateService.instant("Se ha añadido la plantilla correctamente.");
        var Titulo = this.translateService.instant("Plantilla guardada");
        this.toastr.success(Body, Titulo)
      });
    }
    this.shipmentMaritimo = new Shipment();
  }

  asignarFormAPlantilla() {
    this.convertFormToModel(this.formModelMaritimo)
  }

  //Funciones que comprueban que los input sean validos para remarcarlos como error
  compruebaTipoEnvio(form: NgForm): Boolean {
    if (form.value.TipoEnvio == null) {
      this.errorTipoEnvio = true;
    }
    else {
      this.errorTipoEnvio = false;
    }
    return this.errorTipoEnvio;
  }

  //Funciones que comprueban que los input sean validos para remarcarlos como error
  compruebaModoEnvio(form: NgForm): Boolean {
    if (form.value.ModoEnvio == null) {
      this.errorModoEnvio = true;
    }
    else {
      this.errorModoEnvio = false;
    }
    return this.errorModoEnvio;
  }

  compruebaReferencia(form?: NgForm): Boolean {
    if (this.formModelMaritimo.value.Referencia == '') {
      this.errorReferencia = true;
    }
    else {
      this.errorReferencia = false;
    }
    return this.errorReferencia;
  }

  compruebaListShipper(form: NgForm): Boolean {
    if (form.value.ListShipper == '') {
      this.errorListShipper = true;
    }
    else {
      this.errorListShipper = false;
    }
    return this.errorListShipper;
  }

  compruebaListConsignee(form: NgForm): Boolean {
    if (form.value.ListConsignee == '') {
      this.errorListConsignee = true;
    }
    else {
      this.errorListConsignee = false;
    }
    return this.errorListConsignee;
  }

  compruebaIncoterm(form: NgForm): Boolean {
    if (form.value.Incoterm == '') {
      this.errorIncoterm = true;
    }
    else {
      this.errorIncoterm = false;
    }
    return this.errorIncoterm;
  }

  compruebaBultosTotales(form?: NgForm): Boolean {
    if (this.formModelMaritimo.value.BultosTotales == null || this.formModelMaritimo.value.BultosTotales == 0) {
      this.errorBultosTotales = true;
    }
    else {
      this.errorBultosTotales = false;
    }
    return this.errorBultosTotales;
  }

  compruebaPersonaContactoRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida != undefined) {
      if (form.value.EntidadRecogida.IdEntidad != 20) {
        if (form.value.PersonaContactoRecogida == '') {
          this.errorPersonaContactoRecogida = true;
        }
        else {
          this.errorPersonaContactoRecogida = false;
        }
        return this.errorPersonaContactoRecogida;
      }
      else {
        return this.errorPersonaContactoRecogida;
      }
    }
    else {
      return this.errorPersonaContactoRecogida;
    }
  }

  compruebaEmailRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida != undefined) {
      if (form.value.EntidadRecogida.IdEntidad != 20) {
        if (form.value.EmailRecogida == '') {
          this.errorEmailRecogida = true;
        }
        else {
          this.errorEmailRecogida = false;
        }
        return this.errorEmailRecogida;
      }
      else {
        return this.errorEmailRecogida;
      }
    }
    else {
      return this.errorEmailRecogida;
    }
  }

  compruebaPesoNetoTotal(form?: NgForm): Boolean {
    // this.formModelMaritimo.value.PesoNetoTotal = Number(this.formModelMaritimo.value.PesoNetoTotal.toString().replace(',', '.'));
    // if(this.formModelMaritimo.value.PesoNetoTotal == null || this.formModelMaritimo.value.PesoNetoTotal == 0)
    // {
    //   this.errorPesoNetoTotal = true;
    // }
    // else
    // {
    this.errorPesoNetoTotal = false;
    // }
    return this.errorPesoNetoTotal;
  }

  compruebaPesoBrutoTotal(form?: NgForm): Boolean {
    this.errorPesoBrutoTotal = true;
    if (this.formModelMaritimo.value.PesoBrutoTotal != null && this.formModelMaritimo.value.PesoBrutoTotal != undefined) {
      this.formModelMaritimo.value.PesoBrutoTotal = Number(this.formModelMaritimo.value.PesoBrutoTotal.toString().replace(',', '.'));
      if (this.formModelMaritimo.value.PesoBrutoTotal == null || this.formModelMaritimo.value.PesoBrutoTotal == 0)
        this.errorPesoBrutoTotal = true;
      else
        this.errorPesoBrutoTotal = false;
    }
    return this.errorPesoBrutoTotal;
  }

  /*
  compruebaPesoBrutoTotalDGR(form: NgForm): Boolean {
    this.errorPesoBrutoTotalDGR = false;
    this.errorDiferenciaPesosDGR = false;
    this.muestraErrorBrutoDGR = false;
    if(this.formModelMaritimo.value.PesoBrutoTotalDGR == 0 && this.formModelMaritimo.value.PesoNetoTotalDGR == 0)
    {
      this.errorPesoBrutoTotalDGR = false;
      this.errorDiferenciaPesosDGR = false;
      this.muestraErrorBrutoDGR = false;
    }
    else
    {
      if(this.formModelMaritimo.value.PesoBrutoTotalDGR == null || this.formModelMaritimo.value.PesoBrutoTotalDGR == 0)
      {
        this.errorPesoBrutoTotalDGR = true;
        this.errorDiferenciaPesosDGR = false;
        this.muestraErrorBrutoDGR = true;
      }
      else
      {
        if(this.formModelMaritimo.value.PesoNetoTotalDGR != null && this.formModelMaritimo.value.PesoNetoTotalDGR != 0)
        {
          if(this.formModelMaritimo.value.PesoBrutoTotalDGR > this.formModelMaritimo.value.PesoBrutoTotal 
            || this.formModelMaritimo.value.PesoBrutoTotalDGR < this.formModelMaritimo.value.PesoNetoTotalDGR)
          {
            this.errorDiferenciaPesosDGR = true;
            this.errorPesoBrutoTotalDGR = true;
            this.muestraErrorBrutoDGR = true;
          }
          else
          {
            this.errorPesoBrutoTotalDGR = false;
            this.errorDiferenciaPesosDGR = false;
            this.muestraErrorBrutoDGR = false;
          }
        }
      }
    }
    
    return this.errorPesoBrutoTotalDGR;
  }
  */

  compruebaPesoNetoTotalDGR(form?: NgForm): Boolean {
    this.errorPesoNetoTotalDGR = false;
    //this.errorDiferenciaPesosDGR = false;
    // this.muestraErrorNetoDGR = false;
    // if(this.formModelMaritimo.value.MercanciaPeligrosa == true)
    // {
    //   if(this.formModelMaritimo.value.PesoNetoTotalDGR == 0)
    //   {
    //     this.errorPesoNetoTotalDGR = true;
    //     this.muestraErrorNetoDGR = true;
    //   }
    //   else if(this.formModelMaritimo.value.PesoNetoTotalDGR == null)
    //   {
    //       this.errorPesoNetoTotalDGR = true;
    //       //this.errorDiferenciaPesosDGR = false;
    //       this.muestraErrorNetoDGR = true;
    //   }

    /*
    else
    {
      if(this.formModelMaritimo.value.PesoBrutoTotalDGR != null && this.formModelMaritimo.value.PesoBrutoTotalDGR != 0)
      {
        if(this.formModelMaritimo.value.PesoNetoTotalDGR > this.formModelMaritimo.value.PesoNetoTotal 
          || this.formModelMaritimo.value.PesoBrutoTotalDGR <= this.formModelMaritimo.value.PesoNetoTotalDGR)
        {
          //this.errorDiferenciaPesosDGR = true;
          this.errorPesoNetoTotalDGR = true;
          this.muestraErrorNetoDGR = true;
        }
        else
        {
          this.errorPesoNetoTotalDGR = false;
          //this.errorDiferenciaPesosDGR = false;
          this.muestraErrorNetoDGR = false;
        }
      }
    }
    */
    // }
    // else
    // {
    //   this.errorPesoNetoTotalDGR = false;
    //   this.muestraErrorNetoDGR = false;
    // }
    return this.errorPesoNetoTotalDGR;
  }

  compruebaVolumenTotal(form?: NgForm): Boolean {
    if (!this.esFCL) {
      if (this.formModelMaritimo.value.VolumenTotal == null || this.formModelMaritimo.value.VolumenTotal == 0) {
        this.errorVolumenTotal = true;
      }
      else {
        this.errorVolumenTotal = false;
      }
      return this.errorVolumenTotal;
    }
    else {
      this.errorVolumenTotal = false;
      return false;
    }
  }

  esEditableCotizacion() {
    return !(this.esFCL && (this.visor || this.isCotizacion));
  }

  compruebaTelefonoRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida != undefined) {
      if (form.value.EntidadRecogida.IdEntidad != 20) {
        if (form.value.TelefonoRecogida == '' || form.value.TelefonoRecogida == null) {
          this.errorTelefonoRecogida = true;
        }
        else {
          this.errorTelefonoRecogida = false;
        }
        return this.errorTelefonoRecogida;
      }
      else {
        return this.errorTelefonoRecogida;
      }
    }
    else {
      return this.errorTelefonoRecogida;
    }
  }

  comprobarVacioPesoNetoTotalDGR() {
    if (this.esFCL) {
      if (this.hayMercanciaPeligrosaMercanciaContenedor() && (this.formModelMaritimo.value.PesoNetoTotalDGR == 0 || this.formModelMaritimo.value.PesoNetoTotalDGR == null || this.formModelMaritimo.value.PesoNetoTotalDGR == "")) {
        var Body = this.translateService.instant("El Peso Neto Total DGR es obligatorio.");
        var Titulo = this.translateService.instant("Error Peso Neto Total DGR");
        this.toastr.error(Body, Titulo);
        this.errorPesoNetoDGR = true;
        return false
      }
      else
        this.errorPesoNetoDGR = false;
      return true;
    }
    else {
      if (this.hayMercanciaPeligrosa() && (this.formModelMaritimo.value.PesoNetoTotalDGR == 0 || this.formModelMaritimo.value.PesoNetoTotalDGR == null || this.formModelMaritimo.value.PesoNetoTotalDGR == "")) {
        var Body = this.translateService.instant("El Peso Neto Total DGR es obligatorio.");
        var Titulo = this.translateService.instant("Error Peso Neto Total DGR");
        this.toastr.error(Body, Titulo);
        this.errorPesoNetoDGR = true;
        return false
      }
      else
        this.errorPesoNetoDGR = false;
      return true;
    }
  }

  /*comprobarVacioPesoNeto() {
    if (!this.esFCL) {
      if (this.formModelMaritimo.value.PesoNetoTotal == 0 || this.formModelMaritimo.value.PesoNetoTotal == null || this.formModelMaritimo.value.PesoNetoTotal == "") {
        var Body = this.translateService.instant("El Peso Neto Total es obligatorio.");
        var Titulo = this.translateService.instant("Error peso Neto total");
        this.toastr.error(Body, Titulo);
        this.errorPesoNetoVacio = true;
        return false
      }
      else
        this.errorPesoNetoVacio = false;
      return true;
    }
    else
      return true
  }*/

  comprobarVacioPesoBruto() {
    if (this.formModelMaritimo.value.PesoBrutoTotal == 0 || this.formModelMaritimo.value.PesoBrutoTotal == null || this.formModelMaritimo.value.PesoBrutoTotal == "") {
      var Body = this.translateService.instant("El Peso Bruto Total es obligatorio.");
      var Titulo = this.translateService.instant("Error peso Bruto total");
      this.toastr.error(Body, Titulo);
      this.errorPesoBrutoVacio = true;
      return false
    }
    else
      this.errorPesoBrutoVacio = false;
    return true;
  }

  compruebaFechaRecogida(form: NgForm): Boolean {
    if (form.value.FechaRecogida == '')
      this.errorFechaRecogida = true;
    else
      this.errorFechaRecogida = false;
    if (this.validarFechaRecogida()) {
      this.errorFechaRecogidaMenorActual = true;
      var Body = this.translateService.instant("El campo fecha disponibilidad debe ser igual o superior a la fecha de hoy");
      var Titulo = this.translateService.instant("Error en fecha disponibilidad");
      this.toastr.error(Body, Titulo)
    }
    else {
      this.errorFechaRecogidaMenorActual = false;
    }
    return this.errorFechaRecogida;
  }

  esEditable() {
    return !(this.esFCL && (this.visor || this.isCotizacion)) || !(this.esFCL && !(this.visor || this.isCotizacion));
  }

  compruebaPersonaContactoEntrega(form: NgForm): Boolean {
    this.errorPersonaContactoEntrega = true;
    if (this.formModelMaritimo.value.TipoEnvio == 1) {
      if (form.value.EntidadEntrega != undefined) {
        if (form.value.EntidadEntrega.IdEntidad != 20) {
          if (form.value.PersonaContactoEntrega == '')
            this.errorPersonaContactoEntrega = true;
          else
            this.errorPersonaContactoEntrega = false;
        }
      } else {
        if (form.value.PersonaContactoEntrega == '')
          this.errorPersonaContactoEntrega = true;
        else
          this.errorPersonaContactoEntrega = false;
      }
    } else
      this.errorPersonaContactoEntrega = false;
    return this.errorPersonaContactoEntrega;
  }

  compruebaTelefonoEntrega(form: NgForm): Boolean {
    this.errorTelefonoEntrega = true;
    if (this.formModelMaritimo.value.TipoEnvio == 1) {
      if (form.value.EntidadEntrega != undefined) {
        if (form.value.EntidadEntrega.IdEntidad != 20) {
          if (form.value.TelefonoEntrega == '')
            this.errorTelefonoEntrega = true;
          else
            this.errorTelefonoEntrega = false;
        }
      } else {
        if (form.value.TelefonoEntrega == '')
          this.errorTelefonoEntrega = true;
        else
          this.errorTelefonoEntrega = false;
      }
    } else
      this.errorTelefonoEntrega = false;
    return this.errorTelefonoEntrega;
  }

  compruebaEmailEntrega(form: NgForm): Boolean {
    this.errorEmailEntrega = true;
    if (this.formModelMaritimo.value.TipoEnvio == 1) {
      if (form.value.EntidadEntrega != undefined) {
        if (form.value.EntidadEntrega.IdEntidad != 20) {
          if (form.value.EmailEntrega == '')
            this.errorEmailEntrega = true;
          else
            this.errorEmailEntrega = false;
        }
      } else {
        if (form.value.EmailEntrega == '')
          this.errorEmailEntrega = true;
        else
          this.errorEmailEntrega = false;
      }
    } else
      this.errorEmailEntrega = false;
    return this.errorEmailEntrega;

  }

  compruebaMercancia(): Boolean {

    if (!this.esFCL && (this.listaMercancia.length == 0 || this.listaMercancia == undefined))
      this.errorMercancia = true;
    else if (this.esFCL && (this.listaMercanciaContenedor.length == 0 || this.listaMercanciaContenedor == undefined))
      this.errorMercancia = true;
    else
      this.errorMercancia = false;

    return this.errorMercancia;
  }

  compruebaInstruccionesBLAWB(form: NgForm): Boolean {
    if (form.value.InstruccionesBLAWB == "" || form.value.InstruccionesBLAWB == undefined || form.value.InstruccionesBLAWB == null)
      this.errorInstruccionesBLAWB = true;
    else
      this.errorInstruccionesBLAWB = false;
    return this.errorInstruccionesBLAWB;
  }

  compruebaEntidadRecogida(form: NgForm): Boolean {
    if (form.value.EntidadRecogida == null) {
      this.errorEntidadRecogida = true;
    }
    else {
      this.errorEntidadRecogida = false;
    }
    return this.errorEntidadRecogida;
  }

  compruebaEntidadEntrega(form: NgForm): Boolean {
    if (form.value.EntidadEntrega == null) {
      this.errorEntidadEntrega = true;
    }
    else {
      this.errorEntidadEntrega = false;
    }
    return this.errorEntidadEntrega;
  }

  compruebaValorMercancia(form: NgForm) {
    this.formModelMaritimo.value.ValorMercancia = Number(this.formModelMaritimo.value.ValorMercancia.toString().replace(',', '.'));
    form.value.ValorMercancia = Number(this.formModelMaritimo.value.ValorMercancia.toString().replace(',', '.'));
    if (form.value.ValorMercancia == '' || form.value.ValorMercancia == null) {
      this.errorValorMercancia = true;
    }
    else {
      this.errorValorMercancia = false;
    }
    return this.errorValorMercancia;
  }

  compruebaReferenciaRecogida(form: NgForm) {
    if (form.value.ReferenciaRecogida == '') {
      this.errorReferenciaRecogida = true;
    }
    else {
      this.errorReferenciaRecogida = false;
    }
    return this.errorReferenciaRecogida;
  }

  compruebaReferenciaEntrega(form: NgForm) {

    if (form.value.ReferenciaEntrega == '') {
      this.errorReferenciaEntrega = true;
    }
    else {
      this.errorReferenciaEntrega = false;
    }
    return this.errorReferenciaEntrega;
  }

  recibirSearch(search) {
    this.searchText = search;
  }

  // setNoRemontable(noRemontable: boolean)
  // {
  //   if(noRemontable == true)
  //   {
  //     this.formModelMaritimo.value.NoRemontable = true;
  //   }
  // }

  cerrar() {
    this.modalRef.hide();

    window.setTimeout(function () {
      document.getElementById('ValorMercancia').focus();
    }, 0);
  }

  obtenerDireccionEntidad(direccion: Direccion) {
    if (direccion == null) {
      if (this.tipoDireccion == 'shipper') {
        window.setTimeout(function () {
          document.getElementById('ReferenciaRecogida').focus();
        }, 0);
      }
      else if (this.tipoDireccion == 'consignee') {
        window.setTimeout(function () {
          document.getElementById('ReferenciaEntrega').focus();
        }, 0);
      }
      this.modalRef.hide();
    }
    else {
      if (this.tipoDireccion == 'shipper') {
        //TODO: no me acuerdo
        window.setTimeout(function () {
          document.getElementById('ReferenciaRecogida').focus();
        }, 0);
      }
      else if (this.tipoDireccion == 'consignee') {
        //TODO: no me acuerdo
        window.setTimeout(function () {
          document.getElementById('ReferenciaEntrega').focus();
        }, 0);
      }
      this.modalRef.hide();
    }
  }

  recogidaRequired(form: NgForm): Boolean {
    if (form != undefined) {
      if (form.value.EntidadRecogida != undefined) {
        if (form.value.EntidadRecogida.IdEntidad == 20) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    return false;
  }

  entregaRequired(form: NgForm): Boolean {
    if (form != undefined) {
      if (form.value.EntidadEntrega != undefined) {
        if (form.value.EntidadEntrega.IdEntidad == 20) {
          return false;
        }
        else {
          return true;
        }
      }
    }
    return false;
  }

  obtenerEntidad(entidad: Entity) {
    this.entidad = entidad;
    this.modalRef.hide();
  }

  clientePermitido() {
    if (this.formModelMaritimo.value.Cliente.cliente == 20) {
      return false;
    }
    else {
      return true;
    }
  }

  esEspanyaItalia() {
    return ((this.corresponsal != undefined && this.corresponsal != null) && (this.corresponsal.IdDelegacion == 1 || this.corresponsal.IdDelegacion == 4));
  }

  refresEntidades(actualizado: boolean) {
    if (actualizado) {
      //Si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
      this.envioService.getEntidadesAddressBook().subscribe(data => {
        this.listShipper = data;
        this.listConsignee = data;
        // this.entidadService.getEntidadById(this.entidadCliente).subscribe(entidadCliente => {
        //   this.listShipper = data;
        //   this.listConsignee = data;
        //   if (this.esExport()) {
        //     this.listShipper.forEach(shipper => {
        //       if (shipper.IdEntidad == 20 && this.corresponsal.IdCliente == 20 && this.corresponsal.IdDelegacion == 1 && this.corresponsal.IsCorresponsal == false) {
        //         this.formModelMaritimo.value.Shipper = shipper;
        //         this.formModelMaritimo.value.EntidadRecogida = shipper;
        //         this.formModelMaritimo.value.Cliente = shipper;
        //         this.setShipperInformation();
        //       }
        //       if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
        //         this.visor = this.envioService.getVisor();
        //         if (this.envio != undefined) {
        //           this.asignarValores(this.envio);
        //         }
        //       }
        //     });
        //     if (this.tipoEntidad == 'shipper') {
        //       this.listShipper.forEach(element => {
        //         if (element.NombreFiscal == this.entidad.NombreFiscal) {
        //           this.formModelMaritimo.value.Shipper = element;
        //         }
        //       })
        //       this.setShipperInformation();
        //     }
        //   } else {
        //     this.listConsignee.push(entidadCliente);
        //     this.listConsignee.forEach(consignee => {
        //       this.formModelMaritimo.value.Shipper = consignee;
        //       this.formModelMaritimo.value.EntidadRecogida = consignee;
        //       this.formModelMaritimo.value.Cliente = consignee;
        //       this.setShipperInformation();

        //       if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
        //         this.visor = this.envioService.getVisor();
        //         if (this.envio != undefined) {
        //           this.asignarValores(this.envio);
        //         }
        //       }
        //     });
        //   }
        //   this.cargaRemitente = this.cargaDestinatario = false;
        //   this.cargaEntidadRecogida = this.cargaEntidadEntrega = false;
        // });
      });

      //si el usuario pertenece a una entidad, seleccionar por defecto en el shipper a esa entidad de entre las entidades cargadas.
      this.envioService.getConsignee(this.esExport()).subscribe(data => {
        this.listConsignee = data;
        if (this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
          this.visor = this.envioService.getVisor();
          if (this.envio != undefined) {
            this.asignarValores(this.envio);
          }
        }
        if (this.tipoEntidad == 'consignee') {
          this.listConsignee.forEach(element => {
            if (element.NombreFiscal == this.entidad.NombreFiscal) {
              this.formModelMaritimo.value.Consignee = element;
            }
          })
          this.setConsigneeInformation();
        }
      });
      this.cargarListaNotify();

      /*
      this.envioService.getNotify().subscribe(data => {
        this.listNotify = data;
        if(this.envioService.getIdEnvio() != null && this.envioService.getIdEnvio() != -1) {
          this.visor = this.envioService.getVisor();
          if(this.envio != undefined)
          {
            this.asignarValores(this.envio);
          }
        }
        if(this.tipoEntidad == 'notify')
        {
          this.listNotify.forEach(element => {
            if(element.NombreComercial == this.entidad.NombreComercial)
            {
              this.formModelMaritimo.value.Notify = element;
            }
          })
          this.setNotifyInformation();
        }
      });     
      */
    }
  }

  compruebaDuplicadoReferenciaCliente() {
    if (this.formModelMaritimo.value.Referencia != null && this.formModelMaritimo.value.Referencia != '' && this.formModelMaritimo.value.Referencia != undefined) {
      this.envioService.compruebaRefCliDuplicados(this.formModelMaritimo.value.Referencia).subscribe(data => {
        this.refCliDuplicado = data;

        return this.refCliDuplicado;
      })
    }
  }

  cambiarEstadoDireccion(checked: boolean, quien: string) {
    if (quien == 'shipper') {
      this.readonlyDireccionShipper = checked;
    }
    else if (quien == 'consignee') {
      this.readonlyDireccionConsignee = checked;
    }
    else if (quien == 'notify') {
      this.readonlyDireccionNotify = checked;
    }
  }

  getBandera(a: Puerto) {
    if (a && a.IsoAlfa2)
      return 'assets/banderas/' + a.IsoAlfa2.toLowerCase() + '.png';

    else
      return 'assets/banderas/' + a.IsoAlfa2 + '.png';
  }

  obtenerRespuesta(respuesta: boolean) {
    if (respuesta)
      this.router.navigateByUrl("/customer");
    this.modalRef.hide();
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('-');
  }

  validarFechaRecogida(): Boolean {
    var fechaForm: Date = new Date(this.formModelMaritimo.value.FechaRecogida)
    var fechaHoy: Date = new Date();
    if ((fechaForm.getTime() + 86400000) < fechaHoy.getTime()) {
      return true;
    }
    else {
      return false;
    }

  }

  // tslint:disable-next-line:typedef
  downloadPDF() {
    const dialogRef = this.dialog.open(WaitingComponent, {
      width: '250px',
      panelClass: 'my-panel',
      disableClose: true
    });
    dialogRef.afterOpened().subscribe(result => { });
    // Asignamos en DATA el contenido del html que tiene como id 'tracking'
    const DATA = document.getElementById('resumen');
    // Damos formato al documento: p (portrait = vertical), pt (la unidad de media), a4 (tamaño del pdf)
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
      //pagesplit: true
    };
    html2canvas(DATA, options).then((canvas) => {
      const img = canvas.toDataURL('application/pdf');
      //const img = canvas.toDataURL('image/png');
      //Add image Canvas to PDF
      /*
      Here are the numbers (paper width and height) that I found to work. 
      It still creates a little overlap part between the pages, but good enough for me.
      if you can find an official number from jsPDF, use them.
      */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      return doc;
    }).then((docResult) => {
      docResult.save('ebooking_' + this.formModelMaritimo.value.Referencia + '_' + this.formatDate(new Date()) + '.pdf');
      var archivo = new Archivo();
      archivo.Base64image = docResult.output('datauristring');
      archivo.FileName = 'ebooking_' + this.formModelMaritimo.value.Referencia + '_' + this.formatDate(new Date()) + '.pdf';
      archivo.Extension = '.pdf';
      archivo.TipoArchivo = '19';
      this.archivoBooking = archivo;
      dialogRef.close();
      var body = this.translateService.instant("BodyInfoSubidaEbooking");
      var title = this.translateService.instant("TitleInfoSubirEbooking");
      this.toastr.info(body, title);
    });
  }

}