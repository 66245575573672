import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogModel } from '../models/log-model.model';
import { LogCompleto } from '../models/logCompleto.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  ip: string = ""; 
  localizacion: string = "";
  readonly apiUrlService = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService) { 
    this.http.get('https://api.ipify.org?format=json').subscribe(data => {
        this.ip = data['ip'];
        this.http.get('https://ipapi.co/'+this.ip+'/json').subscribe((d: any) => {
          this.localizacion = d.country_name + " " + d.city + " " + d.postal + " " + d.org + " latitude: " + d.latitude + " longitude: " + d.longitude; 
        })
      })
  }

  //Obtenemos el token de la variable local
  getToken()
  {
    return localStorage.getItem("userToken");
  }

  postLogCompleto(accion: string, controlador: string, descripcion: string,
    metodo: string, tabla: string, url: string){
      var log: LogModel = new LogModel();
      log.Ip = this.ip;
      log.Localizacion = this.localizacion;
      var headersToken: HttpHeaders = new HttpHeaders({
        "Authorization": "Bearer " + this.getToken()
      })
    var logCompleto = new LogCompleto();
    logCompleto.Accion = accion;
    logCompleto.Controlador = controlador;
    logCompleto.Descripcion = descripcion;
    logCompleto.Metodo = metodo;
    logCompleto.Tabla = tabla;
    logCompleto.Url = url;
    const url_api = this.apiUrlService + "cli_log";
    this.http.post(url_api, {"Body": JSON.stringify(logCompleto),
                             "Log": log}, {headers: headersToken.append("Content-Type", "application/json")})
                             .subscribe(data => {})
  }
}
