<form [formGroup]="formVehiculo">
    <div class="container">
        <div class="row marginTop align-items-end">
            <div class="col-4">
                <mat-form-field class="example-full-width">
                  <mat-label>{{'Matricula'|translate}}</mat-label>
                  <input type="text" matInput placeholder="Matricula" [formControl]="matricula">
                  <mat-error *ngIf="!matricula.valid">
                    {{'Tienes que introducir la matrícula.'|translate}}
                  </mat-error>
                </mat-form-field>
            </div>
            <div class="col-3">
              <mat-checkbox class="example-margin" [checked]="tieneRemolque" (change)="tieneRemolque = !tieneRemolque">Remolque</mat-checkbox>
            </div>
            <div class="col-4" *ngIf="tieneRemolque">
              <mat-form-field class="example-full-width">
                <mat-label>{{'Remolque'|translate}}</mat-label>
                <input type="text" matInput placeholder="Remolque" [formControl]="remolque">
              </mat-form-field>
            </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="example-full-width marginTop">
                <mat-label class="marginTop">{{'Tipo'|translate}}</mat-label>
                <input type="text"
                        attr.placeholder="{{'EligeUno' | translate }}"
                        attr.aria-label="{{'Tipo' | translate }}"
                        matInput
                        [formControl]="tipo"
                        [matAutocomplete]="autoTipo"
                        (blur)="onBlurTipo($event)">
                <mat-autocomplete autoActiveFirstOption  #autoTipo="matAutocomplete" [displayWith]="displayTipo">
                  <mat-option  *ngFor="let tipoVehiculo of filteredTipoVehiculo | async" [value]="tipoVehiculo.IdTipoVehiculo">{{tipoVehiculo.Nombre}}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="!tipo.valid">
                  {{'Introduce el tipo.'|translate}}
                </mat-error>
              </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="example-full-width marginTop">
              <mat-label class="marginTop">{{'Especialidad'|translate}}</mat-label>
              <input type="text"
                        attr.placeholder="{{'EligeUno' | translate }}"
                        attr.aria-label="{{'Especialidad' | translate }}"
                        matInput
                        [formControl]="especialidad"
                        [matAutocomplete]="autoEspecialidad"
                        (blur)="onBlurEspecialidad($event)">
                <mat-autocomplete autoActiveFirstOption #autoEspecialidad="matAutocomplete" [displayWith]="displayEspecialidad">
                  <mat-option *ngFor="let especialidadVehiculo of filteredEspecialidadVehiculo | async" [value]="especialidadVehiculo.IdEspecialidadVehiculo">{{especialidadVehiculo.Nombre}}</mat-option>
                </mat-autocomplete>
              <mat-error *ngIf="!tipo.valid">
                {{'Introduce el tipo.'|translate}}
              </mat-error>
            </mat-form-field>
      </div>
        </div>
        <div class="row">
            <div class="col-5">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Peso'|translate}}</mat-label>
                    <input type="number" matInput placeholder="Peso" [formControl]="peso">
                    <mat-error *ngIf="!peso.valid">
                      {{'Peso es requerido.'|translate}}
                    </mat-error>
                  </mat-form-field>
            </div>
            <div class="col-1 center">
              <p>tn</p>
            </div>
            <div class="col-5">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Volumen'|translate}}</mat-label>
                    <input type="number" matInput placeholder="Volumen" [formControl]="volumen">
                    <mat-error *ngIf="!volumen.valid">
                      {{'Es necesario añadir el volumen.'|translate}}
                    </mat-error>
                  </mat-form-field>
            </div>
            <div class="col-1 center">
              <p>m3</p>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Largo'|translate}}</mat-label>
                    <input type="number" matInput placeholder="Largo" [formControl]="largo">
                  </mat-form-field>
            </div>
            <div class="col-1 center">
              <p>m</p>
            </div>
            <div class="col-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Ancho'|translate}}</mat-label>
                    <input type="number" matInput placeholder="Ext." [formControl]="ancho">
                  </mat-form-field>
            </div>
            <div class="col-1 center">
              <p>m</p>
            </div>
            <div class="col-3">
                <mat-form-field class="example-full-width">
                    <mat-label>{{'Alto'|translate}}</mat-label>
                    <input type="number" matInput placeholder="Alto" [formControl]="alto">
                  </mat-form-field>
            </div>
            <div class="col-1 center">
              <p>m</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <button *ngIf="!isUpdate()" type="button" class="btn margenBot" id="crear-vehiculo" (click)="postVehiculo('crear-vehiculo')" matButton>{{'Crear'|translate}}</button>
                <button *ngIf="isUpdate()" type="button" class="btn margenBot" id="actualizar-vehiculo" (click)="updateVehiculo('actualizar-vehiculo')" matButton>{{'Modificar'|translate}}</button>
            </div>
            <div class="col-sm-4">
                <button type="button" class="btn margenBot" (click)="resetear();" matButton>{{'Limpiar'|translate}}</button>
            </div>
        </div>
    </div>
</form>