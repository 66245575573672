import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogModel } from 'src/app/models/log-model.model';
import { TipoEmbalaje } from 'src/app/models/tipo-embalaje.model';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { TipoembalajeService } from 'src/app/services/tipoembalaje.service';

@Component({
  selector: 'app-lista-embalajes',
  templateUrl: './lista-embalajes.component.html',
  styleUrls: ['./lista-embalajes.component.scss']
})
export class ListaEmbalajesComponent implements OnInit {
  checked: boolean;
  disabled = false;
  dataSource;
  tipoEmbalaje: TipoEmbalaje[] = [];
  displayedColumns: string[] = ["tipoEmbalaje","largo","ancho","alto", "delete"];
  logModel: LogModel = new LogModel();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() embalaje = new EventEmitter<TipoEmbalaje>();

  constructor(private embalajesServices: TipoembalajeService, private configuracionService: ConfiguracionService, private toastr: ToastrService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.obtenerListaEmbalajePredefinido();
  }

  changeValue(event?: MatSlideToggleChange) {
    this.obtenerlistaEmbalaje(!this.checked);
    this.embalajesServices.predeterminado = !this.checked;
    this.configuracionService.embalajePredeterminado = !this.checked;
    this.logModel.Click = 'Toggle predeterminado'
    this.logModel.Pantalla = 'Perfil - ListaEmbalaje'

    //Se envia el cambio del check y devuele el listado de embalajes dependiendo de si el check es true o false
    this.configuracionService.putConfigEmbalaje(!this.checked,this.logModel).subscribe(data => {
      
    },
    error => {
      var Body = this.translateService.instant("No se ha podido añadir el embalaje, póngase en contacto con soporte.");
      var Titulo = this.translateService.instant("Error");
      this.toastr.success(Body, Titulo)
    })
  }

  obtenerListaEmbalajePredefinido() {
    this.configuracionService.getEmbalajePredeterminado().subscribe(data => {
      this.checked = data;
      this.configuracionService.embalajePredeterminado = this.checked;
      this.embalajesServices.predeterminado = this.checked;
      this.obtenerlistaEmbalaje(this.checked);
    })
  }

  obtenerlistaEmbalaje(predeterminado: boolean) {
    this.embalajesServices.getListaEmbalajes(predeterminado);
    this.embalajesServices.getListaEmbalajes$().subscribe(data => {
      this.tipoEmbalaje = data;
      this.dataSource = new MatTableDataSource<TipoEmbalaje>(data);
      this.dataSource.paginator = this.paginator;
    })
  }

  getElement(embalaje) {
    this.embalaje.emit(embalaje)
  }

  eliminarElemento(embalaje) {
    this.embalajesServices.deleteEmbalaje(embalaje, this.logModel).subscribe(data => {
      if(data == 0)
      {
        var body = this.translateService.instant("EmbalajePredeterminado")
        var title = this.translateService.instant("Info")
        this.toastr.info(body, title)
      }
      else
      {
        var body = this.translateService.instant("Eliminado")
        var title = this.translateService.instant("CorrectoEliminado")
        this.toastr.success(body, title)
        this.obtenerlistaEmbalaje(this.checked);
      }
    }, error => {
      var body = this.translateService.instant("NoEliminado")
      var title = this.translateService.instant("ErrorEliminado")
      this.toastr.error(body, title)
    })
  }
}
