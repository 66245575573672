<div class="container">
    <form #formEntidad="ngForm" (ngSubmit)="anyadirEntidad(formEntidad, 'formulario-entidad')">
        <div class="row">
            <div class="col-sm-3">
                <label>{{'Nombre Fiscal'|translate}}</label>
                <sup><span mat-raised-button matTooltip="{{'infoNombreFiscal' | translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <input name="NombreFiscal" title="Nombre Fiscal" type="text" (focusout)="compruebaNombreFiscal()" autocomplete="off"
                [ngClass]="{'form-control-custom-error': errorNombreFiscal, 'form-control': !errorNombreFiscal}" #NombreFiscal="ngModel" 
                [(ngModel)]="formModelEntidad.value.NombreFiscal" required>
                <div *ngIf="(errorNombreFiscal || (formEntidad.submitted && (NombreFiscal.value == null || NombreFiscal.value == '')) || 
                (NombreFiscal.touched && (NombreFiscal.value == null || NombreFiscal.value == '')))" class="text-danger" 
                style="font-size: small;">
                    {{'Nombre Fiscal obligatorio'|translate}}
                </div>
            </div>
            <!--
            <div class="col-sm-3">
                <label>{{'Nombre Comercial'|translate}}</label>
                <sup><span mat-raised-button matTooltip="{{'infoNombreComercial'|translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <input name="NombreComercial" title="Nombre Comercial" type="text" #NombreComercial="ngModel" [(ngModel)]="formModelEntidad.value.NombreComercial" 
                (focusout)="compruebaNombreComercial()" [ngClass]="{'form-control-custom-error': errorNombreComercial, 'form-control': !errorNombreComercial}"
                autocomplete="off" required>
                <div *ngIf="(errorNombreComercial || (formEntidad.submitted && (NombreComercial.value == null || NombreComercial.value == '')) || 
                (NombreComercial.touched && (NombreComercial.value == null || NombreComercial.value == '')))" class="text-danger" 
                style="font-size: small;">
                    {{'Nombre Comercial obligatorio'|translate}}
                </div>
            </div>
            -->
            <div class="col-sm-2">
                <label>{{'Tipo Identificación'|translate}}</label>
                <sup><span mat-raised-button matTooltip="{{'infoTipoIdentificacion' | translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <ng-select name="TipoIdentificacion" title="Tipo de Identificación" id="TipoIdentificacion" class="select-small" #TipoIdentificaicón="ngModel" 
                [items]="listTipoIdentificacion" bindLabel="TipoIdentificacion" bindValue="IdTipoIdentificacion" 
                [(ngModel)]="formModelEntidad.value.TipoIdentificacion" (focusout)="compruebaCIF()">
                </ng-select>
            </div>
            <div class="col-sm-3">
                <label>NIE/NIF/CIF/TAX ID</label>
                <sup><span mat-raised-button matTooltip="{{'infoIdentificacion' | translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <input name="CIFNIF" title="Cif/Nif" type="text" class="form-control" #CIFNIF="ngModel" [(ngModel)]="formModelEntidad.value.CifNif"
                (focusout)="compruebaCIF(); compruebaCifNif();" autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-11">
                <label>{{'Direccion'|translate}}</label>
                <input name="Direccion" type="text" title="Direccion" #Direccion="ngModel" [(ngModel)]="formModelEntidad.value.Direccion"  autocomplete="off"
                (focusout)="compruebaDireccion()" class="form-control">
                <!-- [ngClass]="{'form-control-custom-error': errorDireccion, 'form-control': !errorDireccion}">
                <div *ngIf="((formEntidad.submitted && (Direccion.value == null || Direccion.value == '')) || (Direccion.touched && 
                (Direccion.value == null || Direccion.value == '')))" class="text-danger" style="font-size: small;">
                    {{'Direccion obligatorio'|translate}}
                </div>
                -->
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>{{'Pais'|translate}}</label>
                <ng-select name="Pais" title="País" id="Pais" class="select-small" #Pais="ngModel" [items]="listPais" bindLabel="Nombre" bindValue="IdPais" 
                [(ngModel)]="formModelEntidad.value.Pais" (focusout)="compruebaPais();compruebaCIF();listadoLocalidades();" autocomplete="off" [loading]="loadingPais" loadingText="{{cargandoLabel}}"
                [ngClass]="{'error': errorPais}">
                </ng-select>
                <div *ngIf="((formEntidad.submitted && (Pais.value == null || Pais.value == '')) || (Pais.touched && (Pais.value == null || Pais.value == '')))" 
                class="text-danger" style="font-size: small;">
                    {{'Pais obligatorio'|translate}}
                </div>
            </div>
            <div class="col-sm-4">
                <label>{{'Localidad'|translate}}</label>
                <ng-select name="Localidad" title="Localidad" id="Localidad" class="select-large" [loading]="loadingLocalidad" loadingText="{{cargandoLabel}}" #Localidad="ngModel" [items]="listLocalidad" bindLabel="Localidad" bindValue="IdLocalidad" 
                [(ngModel)]="formModelEntidad.value.Localidad"  autocomplete="off" [searchable]="true" [virtualScroll]="true" (focusout)="setCodigoPostalWhenChangeLocalidad();"
                [ngClass]="{'error': errorLocalidad}">
                </ng-select>
                <div *ngIf="((formEntidad.submitted && (Localidad.value == null || Localidad.value == '')) || (Localidad.touched && (Localidad.value == null || Localidad.value == '')))" 
                class="text-danger" style="font-size: small;">
                    {{'ErrorLocalidadObligatoria'|translate}}
                </div>
                <!-- <input name="Localidad" title="Localidad" id="Localidad" type="text" autocomplete="off" class="form-control"
                #Localidad="ngModel" [(ngModel)]="formModelEntidad.value.Localidad"> (focusout)="compruebaPais();compruebaCIF()"-->
            </div>
            <div class="col-sm-3">
                <label>{{'Código Postal'|translate}}</label>
                <input name="CodPostal" title="Código postal" id="CodPostal" type="text" autocomplete="off"
                [ngClass]="{'form-control-custom-error': errorCodigoPostal, 'form-control': !errorCodigoPostal}" 
                #CodPostal="ngModel" [(ngModel)]="formModelEntidad.value.CodigoPostal">
                <!--
                #CodPostal="ngModel" [(ngModel)]="formModelEntidad.value.CodigoPostal" [required]="codPostalRequired()">
                <div *ngIf="(codPostalRequired() && ((formEntidad.submitted && (CodPostal.value == null || CodPostal.value == '')) || (CodPostal.touched && 
                (CodPostal.value == null || CodPostal.value == ''))))" class="text-danger" style="font-size: small;">
                    {{'CodigoPostalObligatorio'|translate}}
                </div>
                -->
            </div>

        </div>
        <div class="row">
            <div class="col-sm-2">
                <label>{{'Apdo. Correos'|translate}}</label>
                <input name="AptoCorreos" type="text" title="Apartado de correos" class="form-control" autocomplete="off"
                #AptoCorreos="ngModel" [(ngModel)]="formModelEntidad.value.ApdoCorreos">
            </div>
            <div class="col-sm-2">
                <label>{{'Telefono'|translate}} 1</label>
                <input name="Telefono1" type="text" title="Teléfono 1" class="form-control"
                #Telefono1="ngModel" [(ngModel)]="formModelEntidad.value.Telefono1" (focusout)="compruebaTelefono1()" autocomplete="off">
                <!--
                     [ngClass]="{'form-control-custom-error': errorTelefono1, 'form-control': !errorTelefono1}"
                <div *ngIf="((formEntidad.submitted && (Telefono1.value == null || Telefono1.value == '')) || (Telefono1.touched && 
                (Telefono1.value == null || Telefono1.value == '')))" class="text-danger" style="font-size: small;">
                    {{'Teléfono obligatorio'|translate}}
                </div>
                -->
            </div>
            <div class="col-sm-2">
                <label>{{'Telefono'|translate}} 2</label>
                <input name="Telefono2" type="text" title="Teléfono 2" class="form-control" autocomplete="off"
                #Telefono2="ngModel" [(ngModel)]="formModelEntidad.value.Telefono2">
            </div>
            <div class="col-sm-5">
                <label>{{'Email'|translate}}</label>
                <input name="Email" type="email" title="Email" class="form-control" 
                #Email="ngModel" [(ngModel)]="formModelEntidad.value.Email" (focusout)="compruebaEmail()" autocomplete="off">
                <!--
                     [ngClass]="{'form-control-custom-error': errorEmail, 'form-control': !errorEmail}"
                <div *ngIf="((formEntidad.submitted && (Email.value == null || Email.value == '')) || (Email.touched && (Email.value == null 
                || Email.value == '')))" class="text-danger" style="font-size: small;">
                    {{'Email obligatorio'|translate}}
                </div>
                -->
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>{{'Persona contacto'|translate}}</label>
                <input name="PersonaContacto" type="text" title="Persona de contacto" class="form-control" style="margin-bottom: 20px;" autocomplete="off"
                #PersonaContacto="ngModel" [(ngModel)]="formModelEntidad.value.Contacto">
            </div>
            <div class="col-sm-2">
                <label>{{'Desde'|translate}}</label>
                <sup><span mat-raised-button matTooltip="{{'infoDesdeEntidad' | translate}}" matTooltipClass="my-custom-tooltip"><img src="assets/iconos/info_64.png" alt="" style="width: 12px;height: 12px;"></span></sup>
                <input name="HoraInicio1" type="time" title="Hora inicio 1" class="form-control" style="margin-bottom: 20px;" autocomplete="off"
                #HoraInicio1="ngModel" [(ngModel)]="formModelEntidad.value.HoraInicio1">
            </div>
            <div class="col-sm-2">
                <label>{{'Hasta'|translate}}</label>
                <input name="HoraFin1" type="time" title="Hora fin 1" class="form-control" style="margin-bottom: 20px;" autocomplete="off"
                #HoraFin1="ngModel" [(ngModel)]="formModelEntidad.value.HoraFin1">
            </div>
            <div class="col-sm-2">
                <label>{{'Desde'|translate}}</label>
                <input name="HoraInicio2" type="time" title="Hora inicio 2" class="form-control" style="margin-bottom: 20px;" autocomplete="off"
                #HoraInicio2="ngModel" [(ngModel)]="formModelEntidad.value.HoraInicio2">
            </div>
            <div class="col-sm-2">
                <label>{{'Hasta'|translate}}</label>
                <input name="HoraFin2" type="time" title="Hora fin 2" class="form-control" style="margin-bottom: 20px;" autocomplete="off"
                #HoraFin2="ngModel" [(ngModel)]="formModelEntidad.value.HoraFin2">
            </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer mt-3">
            <button type="submit" title="Añadir dirección" class="btn float-right">{{'Añadir entidad'|translate}}</button>
            <button type="button" title="Cerrar" class="btn float-right" (click)="cerrarModal();">{{'Cerrar'|translate}}</button>
        </div>
    </form>
</div>