import { MercanciaPeligrosa } from "./mercancia-peligrosa.model";
import { Mercancia } from "./mercancia.model";

export class ContenedorMercancia {
    IdContenedor: number;
    IdCotizacion: number;
    IdTipoContenedor: number;
    // Bultos: number;
    // PesoBruto: number;
    Mercancia: Mercancia[];
    // MercanciaPeligrosa: MercanciaPeligrosa[];
    // Peligrosa: boolean;
    // Alimentacion: boolean;
    // GDP: boolean;
    NombreContenedor: string;
    // NombreTipoMercanciaContenedor: string;
    // NA: boolean;
    // General: boolean;
}