export class Expedition {
    IdExpedicion?: number;
    Expedicion?: string; // Mostrar detalles
    FechaSalida?: string; // Mostrar detalles
    FechaLlegada?: string; // Mostrar detalles
    Cliente?: string;
    Proveedor?: string;
    Remitente?: string; // Mostrar detalles
    Destinatario?: string; // Mostrar detalles
    DireccionRemitente?: string; // Mostrar detalles
    NumeroDireccionRemitente? : string;
    EscaleraDireccionRemitente?: string;
    PisoDireccionRemitente?: string;
    PuertaDireccionRemitente?: string;
    HorarioRemitente?: string;
    TelefonoRemitente?: string; // Mostrar detalles
    CodigoPostalRemitente?: string; // Mostrar detalles
    PoblacionRemitente?: string; // Mostrar detalles
    PaisRemitente?: string;
    DireccionDestinatario?: string; // Mostrar detalles
    NumeroDireccionDestinatario?: string;
    EscaleraDireccionDestinatario?: string;
    PisoDireccionDestinatario?: string;
    PuertaDireccionDestinatario?: string;
    ObservacionesDestinatario?: string;
    HorarioDestinatario?: string;
    TelefonoDestinatario?: string; // Mostrar detalles
    CodigoPostalDestinatario?: string; // Mostrar detalles
    PoblacionDestinatario?: string; // Mostrar detalles
    PaisDestinatario?: string;
    Comentario?: string; // Mostrar detalles
    IdConductor?: string;
    MatriculaRemolque?: string;
    IdSerieReferencia?: string;
    CifProveedor?: string;
    CodigoPostalProveedor?: string;
    PoblacionProveedor?: string;
    PaisProveedor?: string;
    DireccionProveedor?: string;
    NumeroDireccionProveedor?: string;
    EscaleraDireccionProveedor?: string;
    PisoDireccionProveedor?: string;
    PuertaDireccionProveedor?: string;
    TelefonoProveedor?: string;
    Bultos?: string; // Mostrar detalles
    Peso?: string; // Mostrar detalles
    Volumen?: string;
    Palets?: string;
    Embalaje?: string;
    Alto?: string;
    Ancho?: string;
    Largo?: string;
    EmailProveedor?: string;
    CamionCompleto?: string;
    MercanciaPeligrosa?: string;
    he1?: string;
    he2?: string;
    he3?: string;
    he4?: string;
    hr1?: string;
    hr2?: string;
    hr3?: string;
    hr4?: string;
     // No está pero lo dejamos por si acaso...    
     LineaTrafico?: string;
     TipoServicio?: string;
     Departamento?: string;
     TipoEmbalaje?: string;
     FechaCMR?: Date;
     TipoPortes?: string;
     Conductor?: string;
     Matricula?: string;
     Mercancia?: string;
     Numero?: number;
     Usuario?: string;
     Incoterm?: string;
     Cerrada?: boolean;
     NoFacturable?: boolean;
     DecAduanera?: string;
     HoraSalida?: string; // Mostrar detalles
     HoraLlegada?: string; // Mostrar detalles
     Status?: string;
     Estado?: string;
     Checked: boolean = true;
     EntregaFechaTracking?: string;
     RecogidaFechaTracking?: string;
     IsFacturado?: boolean;
}