import { Time } from '@angular/common';

export class Entity {
    IdEntidad: number;
    NombreFiscal: string;
    NombreComercial: string;
    CifNif?: string;
    Direccion?: string;
    IdPais?: number;
    IdLocalidad?: string;
    Localidad?: string;
    CodigoPostal?: string;
    AptoCorreos?: string;
    Telefono?: string;
    Telefono1?: string;
    Telefono2?: string;
    Email?: string;
    Contacto?: string;
    DesdePrimeraHora?: Time;
    HastaPrimeraHora?: Time;
    DesdeSegundaHora?: Time;
    HastaSegundaHora?: Time;
    Cliente?: number;
    IdDireccion?: number;
    Poblacion?: string;
    Numero?: string;
    Escalera?: string;
    Piso?: string;
    Puerta?: string;
    IdTipoDireccion?: number;
    NombrePais?: string;
    IdTipoEntidad?:number;
}
